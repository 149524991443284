import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

export const getDataView = (data) => {
  return {
    type: types.GET_DATA_VIEW,
    data,
  };
};

const successGetDataView = (data) => {
  return {
    type: types.SUCCESS_DATA_VIEW,
    data,
  };
};

const errorGetDataView = (data) => {
  return {
    type: types.ERROR_DATA_VIEW,
    data,
  };
};

export const openDataViewRange = (data) => {
  return {
    type: types.OPEN_DATA_VIEW_RANGE,
    data,
  };
};

export const closeDataViewRange = (data) => {
  return {
    type: types.CLOSE_DATA_VIEW_RANGE,
    data,
  };
};

export const openDataViewLocations = (data) => {
  return {
    type: types.OPEN_DATA_VIEW_LOCATIONS,
    data,
  };
};

export const closeDataViewLocations = (data) => {
  return {
    type: types.CLOSE_DATA_VIEW_LOCATIONS,
    data,
  };
};

/**
 * Get Location Data View
 *
 * @access public
 * @param {Object} data
 * @param {String} data.view
 * @param {Number} data.location_id
 * @param {String|Date} [data.start]
 * @param {String|Date} [data.end]
 * @returns {Promise}
 */
export function doGetLocationDataView(data) {
  data._request = data._request || requestID();
  data.topic = {
    model: 'location',
    id: data.location_id,
  };
  let qs = [];
  if (data.start) qs.push('start=' + data.start);
  if (data.end) qs.push('end=' + data.end);
  qs = qs.join('&');
  return (dispatch) => {
    dispatch(getDataView(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/data_views/${data.view}?${qs}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get location data view ' + data.view,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successGetDataView(
            Object.assign(
              {
                data: body,
                meta: {
                  total: body.length,
                  count: body.length,
                  limit: body.length,
                  offset: 0,
                },
              },
              data
            )
          )
        );
      })
      .catch((error) => {
        dispatch(errorGetDataView(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get Customer Data View
 *
 * @access public
 * @param {Object} data
 * @param {String} data.view
 * @param {Number} data.customer_id
 * @param {String|Date} [data.start]
 * @param {String|Date} [data.end]
 * @returns {Promise}
 */
export function doGetCustomerDataView(data) {
  data._request = data._request || requestID();
  data.topic = {
    model: 'customer',
    id: data.customer_id,
  };
  let qs = [];
  if (data.start) qs.push('start=' + data.start);
  if (data.end) qs.push('end=' + data.end);
  qs = qs.join('&');
  return (dispatch) => {
    dispatch(getDataView(data));
    return fetch(
      `${ADMIN_V1_URL}/customers/${data.customer_id}/data_views/${data.view}?${qs}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get customer data view ' + data.view,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successGetDataView(
            Object.assign(
              {
                data: body,
                meta: {
                  total: body.length,
                  count: body.length,
                  limit: body.length,
                  offset: 0,
                },
              },
              data
            )
          )
        );
      })
      .catch((error) => {
        dispatch(errorGetDataView(error));
        trackFetchError(error);
      });
  };
}
