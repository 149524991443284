import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BudgetCodeLink from './budget-code-link.js';
import moment from 'moment';
import { Menu } from 'semantic-ui-react';
import { openCustomerBudgetCodeCreateModal } from '../../actions/customer-budget-codes-actions.js';
import 'semantic-ui-css/components/menu.min.css';
import './budget-code-links.css';

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_budget_code_dates';

const reduceData = (acc, val) => {
  return acc.concat(val.data);
};

const reduceDisplayCodes = (acc, val) => {
  acc[val.id] = {
    id: val.id,
    name: val.name,
    code: val.code,
    active: val.active,
    total_spend: 0,
  };
  return acc;
};

const reduceBudgetCodeData = (acc, val) => {
  if (val.budget_code_id && acc[val.budget_code_id]) {
    acc[val.budget_code_id].total_spend +=
      val.customer_purchase_line_items_total_spend;
  }
  return acc;
};

const filterInactiveBudgetCodes = (c) => {
  return !c.active;
};

export class BudgetCodeLinks extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
      hasHidden:
        props.displayCodes.filter(filterInactiveBudgetCodes).length > 0,
      data: this._buildData(props.dataViews, props.displayCodes).sort(
        this._sortData
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        hasHidden:
          nextProps.displayCodes.filter(filterInactiveBudgetCodes).length > 0,
        data: this._buildData(nextProps.dataViews, nextProps.displayCodes).sort(
          this._sortData
        ),
      })
    );
  }

  _buildData = (dataViews, displayCodes) => {
    let locationsData = this._reduceDataViews(dataViews);
    let aggregateData = this._reducedBudgetCodeData(
      locationsData,
      displayCodes
    );
    return Object.values(aggregateData);
  };

  _reduceDataViews = (dataViews) => {
    return dataViews.reduce(reduceData, []);
  };

  _reducedBudgetCodeData = (data, displayCodes) => {
    let initial = displayCodes.reduce(reduceDisplayCodes, {});
    return data.reduce(reduceBudgetCodeData, initial);
  };

  _filterDisplayCodes = (displayCode) => {
    if (this.state.showHidden) {
      return true;
    }
    return displayCode.active;
  };

  _sortData(a, b) {
    if (a.active && !b.active) return -1;
    if (b.active && !a.active) return 1;
    return a.id > b.id ? 1 : -1;
  }

  onClickOpen = () => {
    if (!this.props.can_manage_billing) {
      return;
    }
    this.props.actions.openCustomerBudgetCodeCreateModal();
  };

  onClickShowHidden = () => {
    this.setState((prev) => {
      return { showHidden: !prev.showHidden };
    });
  };

  renderLink = (budgetCode, index) => {
    return <BudgetCodeLink budgetCode={budgetCode} key={index} />;
  };

  render() {
    return (
      <Menu text vertical className="budget-code-links hide-when-printing">
        <Menu.Item header as="h5">
          Category Reports
        </Menu.Item>
        {this.state.data.filter(this._filterDisplayCodes).map(this.renderLink)}
      </Menu>
    );
  }
}

BudgetCodeLinks.propTypes = {
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  displayCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      code: PropTypes.string,
      active: PropTypes.bool,
    })
  ).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          location_id: PropTypes.number,
          budget_code_id: PropTypes.number,
          submitted_date: PropTypes.string,
          customer_purchase_line_items_count: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  can_manage_billing: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    openCustomerBudgetCodeCreateModal: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  const locations = props.locations.map((l) => l.id);
  const start = props.startDate.toJSON();
  const end = props.endDate.toJSON();
  return {
    can_manage_billing: state.auth.role.can_manage_billing,
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === start &&
        d.end === end &&
        locations.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === start &&
          d.end === end &&
          locations.indexOf(d.topic.id) > -1
      ).length > 0 || state.customerBudgetCodes.requesting.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCustomerBudgetCodeCreateModal,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting &&
    prev.auth.role === next.auth.role &&
    prev.customerBudgetCodes.requesting ===
      next.customerBudgetCodes.requesting &&
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BudgetCodeLinks);
