import * as types from '../actions/action-types.js';

export const empty = {
  initialized: false,
  items: [],
  updating: [],
  creating: [],
  open: { id: 0 },
  requesting: [],
  errors: [],
};
export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_LOCATION_LIST_PRODUCTS:
      return Object.assign({}, state, {
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_LOCATION_LIST_PRODUCTS,
            },
            action.data
          )
        ),
      });
    case types.SUCCESS_LOCATION_LIST_PRODUCTS:
      items = action.data.map((r) => r.id);
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (llp) => llp._request !== types.GET_LOCATION_LIST_PRODUCTS
        ),
        items: state.items
          .filter((r) => items.indexOf(r.id) < 0)
          .concat(action.data),
      });
    case types.ERROR_LOCATION_LIST_PRODUCTS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (llp) => llp._request !== types.GET_LOCATION_LIST_PRODUCTS
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (llp) => llp._request !== action.data._request
        ),
        items: state.items
          .filter((llp) => llp.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (llp) => llp._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        creating: state.creating.concat(action.data),
      });
    case types.SUCCESS_CREATE_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (llp) => llp._request !== action.data._request
        ),
        items: state.items.concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_CREATE_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (llp) => llp._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.UPDATE_LOCATION_LIST_PRODUCT:
    case types.DELETE_LOCATION_LIST_PRODUCT:
    case types.CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.concat(action.data),
      });
    case types.SUCCESS_UPDATE_LOCATION_LIST_PRODUCT:
    case types.SUCCESS_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (llp) => llp._request !== action.data._request
        ),
        items: state.items
          .filter((llp) => llp.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_UPDATE_LOCATION_LIST_PRODUCT:
    case types.ERROR_DELETE_LOCATION_LIST_PRODUCT:
    case types.ERROR_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (llp) => llp._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.SUCCESS_DELETE_LOCATION_LIST_PRODUCT:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (llp) => llp._request !== action.data._request
        ),
        open: state.open.id === action.data.id ? empty.open : state.open,
        items: state.items.filter((llp) => llp.id !== action.data.id),
      });
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
    case types.SWITCH_LOCATION:
      return empty;
    default:
      return state;
  }
};
