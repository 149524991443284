export const filterApplied = (credits, requisition) =>
  (credits || []).filter(
    (credit) =>
      credit.requisition_id === requisition.id &&
      new Date(credit.applied_at_date) < new Date()
  );

export const filterType = (credits, type) =>
  (credits || []).filter((credit) => credit.credit_type.match(type));

export const totalAmount = (credits) =>
  (credits || []).reduce((acc, credit) => acc + credit.amount, 0);

export const filterUnapplied = (credits) =>
  (credits || []).filter(
    (credit) =>
      !credit.applied_at_date && new Date(credit.expiration_date) > new Date()
  );

export const summarizeCredits = (credits) => {
  return (credits || []).reduce(
    (acc, credit) => {
      acc.amount += credit.amount;
      acc.expiration_date =
        acc.expiration_date < credit.expiration_date
          ? acc.expiration_date
          : credit.expiration_date;
      return acc;
    },
    {
      amount: 0,
      expiration_date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    }
  );
};

export const availableSavingsFilter = (credit) => {
  return (
    !credit.requisition_id &&
    !credit.applied_at_date &&
    credit.credit_type.match(/savings/i) &&
    credit.expiration_date > new Date().toJSON()
  );
};

export const filterPromoCodeCredits = (credit) => {
  return credit.creditable_type === 'PromoCode';
};

export const filterNonPromoCodeCredits = (credit) => {
  return credit.creditable_type !== 'PromoCode';
};
