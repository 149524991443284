import React from 'react';
import { Icon, Accordion } from 'semantic-ui-react';
import './calendar-month-day.css';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import './calendar-legend.css';

const renderItem = (item) => {
  if (item.state === 'cancelled') {
    return (
      <Icon
        name="cancel"
        size="small"
        className="calendar-month-day__icon"
        style={helpers.agendaItemStyle(item)}
      />
    );
  }
  return (
    <div
      className="calendar-month-day__dot"
      style={{
        ...helpers.agendaItemStyle(item),
        display: 'inline-block',
      }}
    ></div>
  );
};

class CalendarLegend extends React.PureComponent {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          Legend
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <div>
            {renderItem({
              state: helpers.EVENT_STATES.confirmed,
              color: helpers.EVENT_STATE_COLOR,
            })}
            <span className="calendar-legend__label">
              Order is approved and will arrive by this date.
            </span>
          </div>
          <div>
            {renderItem({
              state: helpers.EVENT_STATES.pending,
              color: helpers.EVENT_STATE_COLOR,
            })}
            <span className="calendar-legend__label">
              Order will arrive by this date if approved.
            </span>
          </div>
          <div>
            {renderItem({
              state: helpers.EVENT_STATES.cancelled,
              color: helpers.EVENT_STATE_COLOR,
            })}
            <span className="calendar-legend__label">
              Cancelled order would have arrived by this date.
            </span>
          </div>
        </Accordion.Content>
      </Accordion>
    );
  }
}

CalendarLegend.propTypes = {};

export default CalendarLegend;
