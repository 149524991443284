import React from 'react';
import { connect } from 'react-redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import setTitle from '../decorators/set-title.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import RecurringRequisitions from '../components/scheduledRequisitions/recurring-requisitions.js';
import { GET_SCHEDULED_REQUISITIONS } from '../actions/action-types.js';
import './orders-recurring.css';

export class OrdersScheduledView extends React.PureComponent {
  render() {
    return (
      <div className="orders-recurring-route">
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <div className="orders-recurring">
          <div className="variable-width-column flex-wrapper">
            <div className="recurring-scheduled-requisitions-container flex-wrapper">
              <RecurringRequisitions isRequesting={this.props.isRequesting} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isRequesting:
      state.scheduledRequisitions.requesting.filter(
        (request) => request._request === GET_SCHEDULED_REQUISITIONS
      ).length > 0,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.requesting ===
    next.scheduledRequisitions.requesting
  );
}

const OrdersScheduled = setTitle(() => {
  return 'Your Recurring Orders';
})(OrdersScheduledView);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OrdersScheduled);
