import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { doCreateProductStockNotification } from '../../ducks/product-stock-notifications.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/loader.min.css';

const ProductStockNotify = (props) => {
  if (props.actor.type !== 'Employee') return null;

  if (props.isAlreadyNotifiable) {
    return (
      <span className="light-gray-text">
        You will be notified when this product is back in stock.
      </span>
    );
  }

  const handleNotify = () => {
    props.actions.doCreateProductStockNotification({
      location_id: props.locationId,
      employee_id: props.actor.id,
      product_id: props.product.id,
    });
  };

  return (
    <Button
      className="btn tertiary"
      style={{ fontSize: '.875rem', textAlign: 'left' }}
      disabled={props.isRequesting}
      onClick={handleNotify}
    >
      <Icon name="bell outline" />
      Notify me when this product is back in stock
      <Loader inline size="mini" active={props.isRequesting} />
    </Button>
  );
};

ProductStockNotify.propTypes = {
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['Employee', 'Staff']).isRequired,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  locationId: PropTypes.number.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isAlreadyNotifiable: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    locationId: state.locations.open.id,
    actor: state.auth.actor,
    isRequesting: !!state.productStockNotifications.requesting.length,
    isAlreadyNotifiable: !!Object.values(
      state.productStockNotifications.items
    ).filter(
      (item) =>
        item.product_id === ownProps.product.id &&
        item.location_id === state.locations.open.id &&
        !item.notification_sent_at
    ).length,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCreateProductStockNotification,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open.id === next.locations.open.id &&
    prev.auth.actor === next.auth.actor &&
    prev.productStockNotifications === next.productStockNotifications
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductStockNotify);
