import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { doUpdateStipend } from '../../ducks/stipends.js';
import { Form, Header } from 'semantic-ui-react';
import 'react-dates/lib/css/_datepicker.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/table.min.css';

const END_DATE_ID = 'stipendExpirationnDate';
const START_DATE_ID = 'stipendStartDate';

export class StipendEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editedStipend: {
        ...props.stipend,
        created_at: undefined,
        updated_at: undefined,
      },
      startDate: moment(props.stipend.starts_at).zone('-06:00') || moment(),
      endDate:
        moment(props.stipend.expires_at).zone('-06:00') ||
        moment().endOf('month'),
      focusedDateInput: null,
    };
  }

  handleSubmit = () => {
    this.props.actions.doUpdateStipend(this.state.editedStipend);
  };

  handleFieldChange = (event) => {
    let stipend = {};

    if (event.target.name === 'amount') {
      stipend[event.target.name] = parseInt(event.target.value, 10) * 100;
    } else {
      stipend[event.target.name] = event.target.value;
    }

    this.setState((state) => {
      return {
        editedStipend: {
          ...state.editedStipend,
          ...stipend,
        },
      };
    });
  };

  onFocusChanged = (focusedDateInput) => {
    this.setState((prev) => {
      return {
        focusedDateInput,
        endDate: focusedDateInput
          ? prev.endDate
          : prev.endDate || moment().endOf('month'),
        startDate: focusedDateInput
          ? prev.startDate
          : prev.startDate || moment(),
      };
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }

    this.setState((state) => {
      return {
        startDate: startDate,
        endDate: endDate,
        editedStipend: {
          ...state.editedStipend,
          starts_at: startDate.startOf('day').format('YYYY-MM-DD HH:mm'),
          expires_at: endDate.endOf('day').format('YYYY-MM-DD HH:mm'),
        },
      };
    });
  };

  isOutsideRange = (d) => {
    if (this.state.focusedDateInput === END_DATE_ID) {
      return d < this.state.startDate;
    }

    return false;
  };

  _centAsDollar = (val = 0) => val / 100;

  render() {
    return (
      <div>
        <Header as="h3">Edit stipend</Header>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Field
              required
              control="input"
              type="text"
              label="Name"
              name="name"
              value={this.state.editedStipend.name}
              onChange={this.handleFieldChange}
              width={8}
            />
            <Form.Field width={8}>
              <Form.Input
                required
                label="Amount"
                type="number"
                min={0}
                icon="dollar"
                iconPosition="left"
                name="amount"
                value={this._centAsDollar(this.state.editedStipend.amount)}
                onChange={this.handleFieldChange}
              />
            </Form.Field>
          </Form.Group>

          <DateRangePicker
            numberOfMonths={1}
            startDateId={START_DATE_ID}
            endDateId={END_DATE_ID}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={this.onDatesChange}
            focusedInput={this.state.focusedDateInput}
            isOutsideRange={this.isOutsideRange}
            onFocusChange={this.onFocusChanged}
          />

          <Form.Group style={{ paddingTop: '1rem', textAlign: 'center' }}>
            <Form.Button type="submit" width={16} primary>
              Edit stipend
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

StipendEditForm.propTypes = {
  stipend: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expires_at: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doUpdateStipend,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(StipendEditForm);
