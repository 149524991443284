import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ProductReq from './cart-product-requisition.js';
import { Progress } from 'semantic-ui-react';
import VendorMinimumIcon from '../products/vendor-minimum-icon.js';
import { minimumInWords, minimumDeficitInWords } from '../../lib/formatters.js';
import { VendorMinimumGroupPropType } from '../../helpers/cart-helpers.js';
import 'semantic-ui-css/components/progress.min.css';
import './cart-product-vendor-group.css';

export class CartProductVendorGroup extends React.PureComponent {
  componentDidMount() {
    this._setProgressColor();
  }

  componentDidUpdate(prevProps) {
    this._setProgressColor(prevProps.color);
  }

  _setProgressColor(prevPropColor = null) {
    if (this.refs.progressBar && prevPropColor !== this.props.color) {
      const progDOM = ReactDOM.findDOMNode(this.refs.progressBar);
      const bar = progDOM.parentNode.getElementsByClassName('bar')[0];
      if (bar) {
        bar.style.background = this.props.color;
      }
    }
  }

  _sortItems(a, b) {
    return a.created_at > b.created_at ? -1 : 1;
  }

  _getMinTypeColor() {
    const { minimum } = this.props.vendorGroup;
    switch (minimum.type) {
      case 'price_min': {
        return 'color_2';
      }
      case 'quantity_min': {
        return 'color_1';
      }
      default:
        return '';
    }
  }

  _getProgress() {
    const { portion_total } = this.props.vendorGroup.minimum;
    return Math.floor(portion_total * 100);
  }

  _getOrderMinium() {
    return minimumInWords(this.props.vendorGroup);
  }

  _getOrderDeficit() {
    return minimumDeficitInWords(this.props.vendorGroup);
  }

  renderItem = (item, i) => {
    return (
      <div key={item.id || i} className="cart-product no-padding">
        <ProductReq
          item={item}
          isEditing={this.props.isEditing}
          isEditingScheduled={this.props.isEditingScheduled}
          readOnly={this.props.readOnly}
          showReplacementOptions={this.props.showReplacementOptions}
        />
      </div>
    );
  };

  renderItems() {
    const prs = this.props.vendorGroup.vendor_product_requisitions.filter(
      (pr) => pr.quantity > 0
    );
    return prs.sort(this._sortItems).map(this.renderItem);
  }

  render() {
    if (!this.props.vendorGroup.vendor_product_requisitions[0]) return null;
    let vendor = this.props.vendorGroup.vendor_product_requisitions[0].vendor;
    return (
      <div className={`vendor-group ${this._getMinTypeColor()}`}>
        <div className={`vendor-group__min-value ${this._getMinTypeColor()}`}>
          <VendorMinimumIcon
            vendor={vendor}
            alwaysShowFullText={true}
            color={this.props.color}
          />
          <div className="vendor-group__min-text">
            {`At least ${this._getOrderMinium()} is needed for this group`}
          </div>
        </div>
        <div className="progress-wrap">
          <Progress percent={this._getProgress()} ref="progressBar" size="tiny">
            {`${this._getOrderDeficit()} to go`}
          </Progress>
        </div>
        {this.renderItems()}
      </div>
    );
  }
}

CartProductVendorGroup.propTypes = {
  vendorGroup: VendorMinimumGroupPropType.isRequired,
  color: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  showReplacementOptions: PropTypes.bool.isRequired,
  isEditingScheduled: PropTypes.bool.isRequired,
};

CartProductVendorGroup.defaultProps = {
  isEditing: false,
  isEditingScheduled: false,
  readOnly: false,
  showReplacementOptions: false,
};

export default CartProductVendorGroup;
