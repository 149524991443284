import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { trackFetchError } from '../lib/analytics.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';
export const ERROR_TIMEOUT = 5000;

// Actions
export const GET_PUNCHOUT_SESSION = 'GET_PUNCHOUT_SESSION';
export const SUCCESS_GET_PUNCHOUT_SESSION = 'SUCCESS_GET_PUNCHOUT_SESSION';
export const ERROR_GET_PUNCHOUT_SESSION = 'ERROR_GET_PUNCHOUT_SESSION';

export const UPDATE_PUNCHOUT_SESSION = 'UPDATE_PUNCHOUT_SESSION';
export const SUCCESS_UPDATE_PUNCHOUT_SESSION =
  'SUCCESS_UPDATE_PUNCHOUT_SESSION';
export const ERROR_UPDATE_PUNCHOUT_SESSION = 'ERROR_UPDATE_PUNCHOUT_SESSION';

export const UNSET_PUNCHOUT_SESSION_RESPONSE =
  'UNSET_PUNCHOUT_SESSION_RESPONSE';

// Action Creators
export const getPunchoutSession = (data) => ({
  type: GET_PUNCHOUT_SESSION,
  data,
});
export const successGetPunchoutSession = (data) => ({
  type: SUCCESS_GET_PUNCHOUT_SESSION,
  data,
});
export const errorGetPunchoutSession = (data) => ({
  type: ERROR_GET_PUNCHOUT_SESSION,
  data,
});
export const updatePunchoutSession = (data) => ({
  type: UPDATE_PUNCHOUT_SESSION,
  data,
});
export const successUpdatePunchoutSession = (data) => ({
  type: SUCCESS_UPDATE_PUNCHOUT_SESSION,
  data,
});
export const errorUpdatePunchoutSession = (data) => ({
  type: ERROR_UPDATE_PUNCHOUT_SESSION,
  data,
});
export const unsetPunchoutSessionResponse = (data) => ({
  type: UNSET_PUNCHOUT_SESSION_RESPONSE,
  data,
});
// Helpers
export const FILTER_IS_REQUESTING = (response) =>
  response.type === GET_PUNCHOUT_SESSION;

export const FILTER_IS_ERROR = (response) =>
  response.type === ERROR_GET_PUNCHOUT_SESSION;

// Reducer
export const empty = {
  open: { id: 0 },
  requesting: [],
  responses: [],
};

const accessTokenQuery = window.location.search.match(
  /session_access_token?=([\dA-Za-z]+)/
);

export const initial = {
  ...empty,
  open: {
    ...empty.open,
    access_token: accessTokenQuery ? accessTokenQuery[1] : null,
  },
};

export default (state = initial, action) => {
  switch (action.type) {
    case GET_PUNCHOUT_SESSION:
    case UPDATE_PUNCHOUT_SESSION:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_PUNCHOUT_SESSION:
    case SUCCESS_UPDATE_PUNCHOUT_SESSION:
      return {
        ...state,
        open: action.data.item,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case ERROR_GET_PUNCHOUT_SESSION:
    case ERROR_UPDATE_PUNCHOUT_SESSION:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case UNSET_PUNCHOUT_SESSION_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    default:
      return state;
  }
};

// Thunks
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetPunchoutSessionResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetPunchoutSession = (data, options = { bubble: false }) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getPunchoutSession(data));
    const employee_id = data.employee_id || getState().auth.actor.id;
    return fetch(
      `${ADMIN_V1_URL}/employees/${employee_id}/punchout_sessions?access_token=${data.access_token}`,
      {
        method: 'GET',
        headers: {
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get Punchout Session' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetPunchoutSession)({
            item: body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetPunchoutSession)({
            error,
            _request: data._request,
          })
        );
        dispatch(
          enqueueConfirmation({
            message: 'Failed to get punchout session',
            type: 'error',
          })
        );
        trackFetchError(error);
      });
  };
};

export const doUpdatePunchoutSession = (data, options = { bubble: false }) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(updatePunchoutSession(data));
    const employee_id = data.employee_id || getState().auth.actor.id;
    return fetch(
      `${ADMIN_V1_URL}/employees/${employee_id}/punchout_sessions/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          'X-Request-ID': data._request,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          location_id: data.location_id,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update Punchout Session',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdatePunchoutSession)({
            item: body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdatePunchoutSession)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
