import React from 'react';
import AuthCheck from '../components/auth-check.js';
import SettingsCompanyAttendant from '../components/customers/settings-company-attendant.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import NewLocationForm from '../components/customers/new-location-form.js';
import SettingsLocationPreferences from '../components/employees/settings-location-preferences.js';

export class SettingsCompanyAttendantRoute extends React.PureComponent {
  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <MountAtPageTop />
        <div className="settings-panel">
          <SettingsCompanyAttendant />
          <NewLocationForm />
          <SettingsLocationPreferences />
        </div>
      </div>
    );
  }
}

SettingsCompanyAttendantRoute = setTitle((props) => {
  return 'Company Details';
})(SettingsCompanyAttendantRoute);

export default SettingsCompanyAttendantRoute;
