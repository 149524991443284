import React from 'react';
import PropTypes from 'prop-types';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-attendant.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExternalVendorInvoice from '../components/insights/external-vendor-invoice.js';
import {
  doGetExternalVendorInvoice,
  openExternalVendorInvoice,
  closeExternalVendorInvoice,
} from '../actions/external-vendor-invoice-actions.js';
import './insights-external-invoice.css';

export class InsightsExternalInvoice extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openExternalVendorInvoice({
      id: parseInt(this.props.match.params.id, 10),
    });
    this.props.actions.doGetExternalVendorInvoice({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentWillUnmount() {
    this.props.actions.closeExternalVendorInvoice();
  }

  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <NavBar />
        <div className="fixed-width-column flex-wrapper">
          <div className="insights-external-invoice">
            <ExternalVendorInvoice invoice={this.props.invoice} />
          </div>
        </div>
      </div>
    );
  }
}

InsightsExternalInvoice.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  actions: PropTypes.shape({
    doGetExternalVendorInvoice: PropTypes.func.isRequired,
    openExternalVendorInvoice: PropTypes.func.isRequired,
    closeExternalVendorInvoice: PropTypes.func.isRequired,
  }),
};

export function mapStateToProps(state) {
  return {
    invoice: state.externalVendorInvoices.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetExternalVendorInvoice,
        closeExternalVendorInvoice,
        openExternalVendorInvoice,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return prev.externalVendorInvoices.open === next.externalVendorInvoices.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsExternalInvoice);
