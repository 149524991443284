import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Header, Popup, Segment } from 'semantic-ui-react';
import { doGetRequisition } from '../../actions/requisition-actions.js';
import ItemImage from '../products/item-image.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/popup.min.css';
import 'semantic-ui-css/components/segment.min.css';

const PRODUCT_DISPLAY_MAX = 10;

export class RequisitionChildrenDetails extends React.PureComponent {
  componentDidMount() {
    this.loadChildren();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requisition.child_ids !== this.props.requisition.child_ids) {
      this.loadChildren();
    }
  }

  loadChildren() {
    if (!this.props.requisition.child_ids) return;
    let hasLoadedChildren =
      this.props.children.filter((child) => {
        return this.props.requisition.child_ids.indexOf(child.id) > -1;
      }).length === this.props.requisition.child_ids.length;
    if (hasLoadedChildren) return;

    this.props.requisition.child_ids.map((id) => {
      return this.props.actions.doGetRequisition({
        id,
        location_id: this.props.requisition.location_id,
      });
    });
  }

  renderProductPreview = (ppo) => {
    return (
      <div className="product-image" key={ppo.id}>
        <ItemImage
          width={50}
          height={50}
          size={'thumb'}
          product={ppo.product}
          title={ppo.product.name}
          alt={ppo.product.name}
        />
      </div>
    );
  };

  renderChild = (child) => {
    let productReqs = child.product_requisitions.slice(0, PRODUCT_DISPLAY_MAX);
    return (
      <Segment
        key={child.id}
        attached="top"
        style={{
          marginBottom: '2rem',
        }}
        className="hide-when-printing"
        clearing>
        <Header
          as="h4"
          style={{
            width: 'calc(100% - 2rem)',
            display: 'inline-block',
          }}>
          Split into{' '}
          <Link
            to={`/supplies/orders/${child.id}`}
            style={{
              fontSize: 'inherit',
            }}>
            Order #{child.id}
          </Link>
        </Header>
        <Popup
          content="Orders are sometimes split to ensure speedy delivery"
          trigger={
            <Button
              circular
              style={{
                fontSize: '0.6rem',
              }}
              icon="info"
              size="mini"
            />
          }
        />
        <div
          style={{
            display: 'flex',
          }}>
          {productReqs.map(this.renderProductPreview)}
          {child.product_requisitions.length > PRODUCT_DISPLAY_MAX && (
            <div className="purchase-order-item-extra-product-count light-gray-text">
              {child.product_requisitions.length - PRODUCT_DISPLAY_MAX} More
              Items
            </div>
          )}
        </div>
      </Segment>
    );
  };

  render() {
    return this.props.children.map(this.renderChild);
  }
}

RequisitionChildrenDetails.propTypes = {
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    child_ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  actions: PropTypes.shape({
    doGetRequisition: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  let child_ids = props.requisition.child_ids || [];
  return {
    children: state.requisitions.items.filter(function (req) {
      return child_ids.indexOf(req.id) > -1;
    }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.requisitions.items === next.requisitions.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(RequisitionChildrenDetails);
