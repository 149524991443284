import * as types from '../actions/action-types.js';

const initialQuery = window.location.pathname.match(
  /\/shift-(reports|details)\/([0-9]+)/
);
export const empty = {
  initialized: false,
  items: [],
  creating: [],
  updating: [],
  open: { id: 0 },
  isRequesting: false,
  error: false,
  meta: {
    count: 0,
    total: 0,
    next: null,
    previous: null,
    limit: 0,
    offset: 0,
  },
};
const initialOpenId =
  (initialQuery && parseInt(initialQuery[2], 10)) || empty.open.id;
export const initial = Object.assign({}, empty, {
  open: empty.items.filter((t) => t.id === initialOpenId)[0] || {
    id: initialOpenId,
  },
});

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_CLEANINGS:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
        initialized: true,
      });
    case types.SUCCESS_CLEANINGS:
      items = action.data.map((r) => r.id);
      return Object.assign({}, state, {
        items: state.items
          .filter(
            (r) =>
              items.indexOf(r.id) < 0 &&
              r.location_id === action.data.location_id
          )
          .concat(action.data),
        isRequesting: false,
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        error: false,
        meta: action.meta || initial.meta,
      });
    case types.ERROR_GET_CLEANINGS:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.GET_CLEANING:
      return Object.assign({}, state, {
        error: false,
      });
    case types.ERROR_GET_CLEANING:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.SUCCESS_CREATE_CLEANING:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (t) => t._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
        items: [action.data].concat(state.items),
      });
    case types.SUCCESS_UPDATE_CLEANING:
      return Object.assign({}, state, {
        updating: state.updating.filter((t) => t.id !== action.data.id),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
        items: state.items.map((t) => {
          if (t.id !== action.data.id) return t;
          return Object.assign({}, t, action.data);
        }),
      });
    case types.CREATE_CLEANING_COMMENT:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
      });
    case types.SUCCESS_CREATE_CLEANING_COMMENT:
      if (!action.data.commentable_type.match(/cleaning/i)) return state;
      return Object.assign({}, state, {
        isRequesting: false,
        error: false,
        items: state.items.map((c) => {
          if (c.id !== action.data.commentable_id) return c;
          return Object.assign({}, c, {
            comments: c.comments
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.commentable_id
            ? Object.assign({}, state.open, {
                comments: state.open.comments
                  .filter((t) => t.id !== action.data.id)
                  .concat(action.data),
              })
            : state.open,
      });
    case types.ERROR_CREATE_CLEANING_COMMENT:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.SUCCESS_CREATE_TASK:
      return Object.assign({}, state, {
        items: state.items.map((c) => {
          if (c.id !== action.data.cleaning_id) return c;
          return Object.assign({}, c, {
            tasks: c.tasks
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.cleaning_id
            ? Object.assign({}, state.open, {
                tasks: state.open.tasks
                  .filter((t) => t.id !== action.data.id)
                  .concat(action.data),
              })
            : state.open,
      });
    case types.SUCCESS_UPDATE_TASK:
      return Object.assign({}, state, {
        items: state.items.map((c) => {
          if (c.id !== action.data.cleaning_id) return c;
          return Object.assign({}, c, {
            tasks: c.tasks
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.cleaning_id
            ? Object.assign({}, state.open, {
                tasks: state.open.tasks
                  .filter((t) => t.id !== action.data.id)
                  .concat(action.data),
              })
            : state.open,
      });
    case types.SUCCESS_DELETE_TASK:
      return Object.assign({}, state, {
        items: state.items.map((c) => {
          if (c.id !== action.data.cleaning_id) return c;
          return Object.assign({}, c, {
            tasks: c.tasks.filter((t) => t.id !== action.data.id),
          });
        }),
        open:
          state.open.id === action.data.cleaning_id
            ? Object.assign({}, state.open, {
                tasks: state.open.tasks.filter((t) => t.id !== action.data.id),
              })
            : state.open,
      });
    case types.SUCCESS_CREATE_REVIEW:
      return Object.assign({}, state, {
        items: state.items.map((c) => {
          if (c.id !== action.data.cleaning_id) return c;
          return Object.assign({}, c, {
            reviews: c.reviews
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.cleaning_id
            ? Object.assign({}, state.open, {
                reviews: state.open.reviews
                  .filter((t) => t.id !== action.data.id)
                  .concat(action.data),
              })
            : state.open,
      });
    case types.SUCCESS_UPDATE_REVIEW:
      return Object.assign({}, state, {
        items: state.items.map((c) => {
          if (c.id !== action.data.cleaning_id) return c;
          return Object.assign({}, c, {
            reviews: c.reviews
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.cleaning_id
            ? Object.assign({}, state.open, {
                reviews: state.open.reviews
                  .filter((t) => t.id !== action.data.id)
                  .concat(action.data),
              })
            : state.open,
      });
    case types.OPEN_CLEANING:
      return Object.assign({}, state, {
        error: empty.error,
        open: Object.assign(
          {},
          state.items.filter((t) => t.id === action.data.id)[0] || initial.open,
          { _focus: action.data.focus }
        ),
      });
    case types.CLOSE_CLEANING:
      return Object.assign({}, state, {
        error: empty.error,
        open: empty.open,
      });
    case types.SWITCH_LOCATION:
      if (!state.items.length || action.data.id === state.items[0].location_id)
        return state;
      return Object.assign({}, state, {
        items: empty.items,
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
