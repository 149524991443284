import * as types from '../actions/action-types.js';

export const empty = {
  queue: [],
};
var queudIdx = 0;
const ERROR_TYPE = 'error';

export default (state = empty, action) => {
  switch (action.type) {
    case types.SUCCESS_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        queue: state.queue.concat({
          message: 'Your password has been updated.',
          id: queudIdx++,
        }),
      });
    case types.ENQUEUE_CONFIRMATION:
      return Object.assign({}, state, {
        queue: state.queue
          .filter((item) => item.message !== action.data.message)
          .concat(
            Object.assign(
              {
                id: queudIdx++,
              },
              action.data
            )
          ),
      });
    case types.ERROR_REQUISITIONS:
    case types.ERROR_CREATE_REQUISITION:
    case types.ERROR_CREATE_REVIEW:
    case types.ERROR_CREATE_LOCATION_LIST_PRODUCT:
    case types.ERROR_CREATE_CUSTOMER_BUDGET_CODE:
    case types.ERROR_UPDATE_CUSTOMER_BUDGET_CODE:
    case types.ERROR_UPDATE_LOCATION:
    case types.ERROR_UPDATE_EMPLOYEE:
      if (action.data.name && action.data.name.match(/AbortError/)) {
        return state;
      }
      return {
        ...state,
        queue: state.queue.concat({
          id: queudIdx++,
          type: ERROR_TYPE,
          message: action.data.message,
        }),
      };
    case types.DISMISS_CONFIRMATION:
      return Object.assign({}, state, {
        queue: state.queue.slice(1),
      });
    default:
      return state;
  }
};
