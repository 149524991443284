import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { Button, Form, Message } from 'semantic-ui-react';
import { formatDate } from '../../lib/formatters.js';
import { sendEmailToBookAttendant } from '../../actions/email-actions.js';
import 'react-dates/lib/css/_datepicker.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';
import './schedule-attendant-form.css';

export class ScheduleAttendantForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date ? moment(props.date) : null,
      time: '',
      details: '',
      displayConfirmation: false,
      submission: false,
      focusedDateInput: false,
    };
  }

  handleChangeDate = (date) => {
    this.setState(Object.assign({}, this.state, { date }));
  };

  onDateFocusChanged = ({ focused }) => {
    this.setState(() => {
      return {
        focusedDateInput: focused,
      };
    });
  };

  isDateOutsideRange = (d) => {
    return !this.props.filterBookable(d);
  };

  handleChangeTime = (e, { value }) => {
    let time = value;
    this.setState(Object.assign({}, this.state, { time }));
  };

  handleChangeDetails = (e, { value }) => {
    let details = value;
    this.setState(Object.assign({}, this.state, { details }));
  };

  handleSubmitRequest = (e) => {
    e.preventDefault();
    this.props.actions.sendEmailToBookAttendant(
      {
        date: this.state.date.toJSON(),
        details: this.state.details,
        time: this.state.time,
      },
      this.props.user,
      this.props.location,
      this.props.customer
    );
    this.setState(
      Object.assign({}, this.state, {
        date: this.props.date ? moment(this.props.date) : null,
        time: '',
        details: '',
        displayConfirmation: true,
        submission: {
          date: this.state.date,
          time: this.state.time,
          details: this.state.details,
        },
      })
    );
  };

  render() {
    return (
      <div className="schedule-attendant-form-container">
        <Form
          className="schedule-attendant-form"
          onSubmit={this.handleSubmitRequest}
          success={this.state.displayConfirmation}>
          <label htmlFor="date" style={{ fontSize: '0.875rem' }}>
            When do you need an Attendant?
          </label>
          <SingleDatePicker
            block
            showDefaultInputIcon
            numberOfMonths={1}
            placeholder="Choose A Day"
            date={this.state.date}
            onDateChange={this.handleChangeDate}
            focused={this.state.focusedDateInput}
            isOutsideRange={this.isDateOutsideRange}
            onFocusChange={this.onDateFocusChanged}
          />
          <Form.Input
            label="For how long?"
            placeholder='e.g. "For 4 hours" or "From 8am-4pm"'
            value={this.state.time}
            onChange={this.handleChangeTime}
          />
          <Form.Input
            label="What do you need them to do?"
            placeholder="Help set up for an event."
            value={this.state.details}
            onChange={this.handleChangeDetails}
          />
          <Button primary type="submit" disabled={!this.state.date}>
            Book an Attendant
          </Button>
          <Message
            success
            header="Requested"
            content={`You've requested an Attendant for ${formatDate(
              this.state.submission.date
            )}. We'll be in touch with you shortly to confirm your attendant's details.`}
          />
        </Form>
      </div>
    );
  }
}

ScheduleAttendantForm.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  filterBookable: PropTypes.func,
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    address2: PropTypes.string,
    account_manager: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string.isRequired,
        thumb: PropTypes.string.isRequired,
        original: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    customer: state.customers.open,
    location: state.locations.open,
    user: state.employees.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendEmailToBookAttendant }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAttendantForm);
