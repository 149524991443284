import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';

const UnmetMinimumWarning = (props) => {
  if (!props.scheduledRequisition) return null;
  if (helpers.isApproved(props.scheduledRequisition)) return null;
  if (helpers.hasUnmetMinimums(props.scheduledRequisition)) {
    return (
      <Message
        warning
        content="Unmet minimums in your order may result in fulfillment issues."
      />
    );
  }
  return null;
};

UnmetMinimumWarning.propTypes = {
  scheduledRequisition: PropTypes.object.isRequired,
};

export default UnmetMinimumWarning;
