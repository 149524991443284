import React, { Fragment } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Slide, Image } from 'pure-react-carousel';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';
import './banner-items.css';

const BannerItem = (props) => {
  const handleClick = () => {
    if (!props.url) return;

    window.totango.track('Clicked Banner Item.', 'Marketplace');
    window.location.href = props.url;
  };

  return (
    <Slide
      index={props.index}
      classNameHidden="slide_hidden"
      classNameVisible="slide_visible">
      <Image
        src={props.imageSrc}
        alt="alt text"
        isBgImage
        tag="div"
        className="banner-items__image"
        onClick={handleClick}>
        <Grid padded stackable className="banner-items__grid">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                {props.header && <Header as="h1" content={props.header} />}
                {props.description && (
                  <Header as="h4" content={props.description} />
                )}
                {props.buttonText && (
                  <Fragment>
                    <Button primary className="desktop" onClick={handleClick}>
                      {props.buttonText}
                    </Button>
                    <Button
                      primary
                      size="mini"
                      className="mobile"
                      onClick={handleClick}>
                      {props.buttonText}
                    </Button>
                  </Fragment>
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </Image>
    </Slide>
  );
};

BannerItem.propTypes = {
  index: PropTypes.number.isRequired,
  imageSrc: PropTypes.string.isRequired,
  header: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  url: PropTypes.string,
};

export default BannerItem;
