// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import mousetrap from 'mousetrap';
import { HOTKEY_INFO } from '../../strings.js';
import './modal-common.css';
import './modal-hotkeys.css';

export default class ModalHotkeys extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      opened: !!props.open,
    };
  }

  componentDidMount() {
    mousetrap.bind(HOTKEY_INFO, () => {
      this.setState({ opened: !this.state.opened });
    });
  }

  componentWillUnmount() {
    mousetrap.unbind(HOTKEY_INFO);
  }

  onRequestClose = () => {
    this.setState({ opened: !this.state.opened });
  };

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.state.opened}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Hotkeys Modal"
      >
        <h1 className="modal-hotkeys-title">Keyboard shortcuts</h1>
        <table className="hotkeys-table">
          <thead>
            <tr>
              <th></th>
              <th>Site wide shortcuts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <kbd>s</kbd>/<kbd>/</kbd>
              </td>
              <td>Focus search</td>
            </tr>
            <tr>
              <td>
                <kbd>Cmd</kbd>+<kbd>z</kbd>
              </td>
              <td>Undo previous action</td>
            </tr>
          </tbody>
        </table>
      </Modal>
    );
  }
}
