import React from 'react';
import PropTypes from 'prop-types';
import './subnav-supplies-items-title.css';

export default class SubNavSuppliesItemsTitle extends React.PureComponent {
  render() {
    return (
      <div className="subnav-container-category">
        <div className="catalog-heading-wrapper flex-wrapper">
          <div className="subnav-category-title flex-item">
            <h2 className="catalog-title">{this.props.title}</h2>
          </div>
        </div>
      </div>
    );
  }
}

SubNavSuppliesItemsTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
