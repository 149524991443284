import * as types from '../actions/action-types.js';

export const empty = {
  isRequesting: false,
  initialized: false,
  error: false,
  credentials: {
    token: null,
  },
  employee: {},
  provided_departments: [],
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.SUCCESS_EMPLOYEE_SELF:
      return Object.assign({}, state, {
        employee: action.data,
      });
    case types.POST_USER_ROLE:
      return Object.assign({}, state, {
        error: false,
        isRequesting: true,
      });
    case types.SUCCESS_USER_ROLE:
      return Object.assign({}, state, {
        isRequesting: false,
        employee: action.data,
      });
    case types.ERROR_USER_ROLE:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.POST_USER_DETAILS:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
      });
    case types.SUCCESS_USER_DETAILS:
      return Object.assign({}, state, {
        isRequesting: false,
        error: false,
      });
    case types.ERROR_USER_DETAILS:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.GET_WITH_TOKEN:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
        initialized: true,
        credentials: { token: action.credentials.token },
      });
    case types.ERROR_WITH_TOKEN:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.SUCCESS_WITH_TOKEN:
      return Object.assign({}, state, {
        isRequesting: false,
        employee: action.data,
      });
    case types.ERROR_SIGN_IN:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
