import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { trackFetchError } from '../lib/analytics.js';
import { SWITCH_LOCATION } from '../actions/action-types.js';

export const RESPONSE_TIMEOUT = 5000;

// Actions
export const GET_REQUISITION_QUERY = 'GET_REQUISITION_QUERY';
export const SUCCESS_GET_REQUISITION_QUERY = 'SUCCESS_GET_REQUISITION_QUERY';
export const ERROR_GET_REQUISITION_QUERY = 'ERROR_GET_REQUISITION_QUERY';
export const RESET_REQUISITION_QUERY = 'RESET_REQUISITION_QUERY';
export const UNSET_REQUISITION_QUERY_RESPONSE =
  'UNSET_REQUISITION_QUERY_RESPONSE';

// Action Creators
export const getRequisitionQuery = (data) => ({
  data,
  type: GET_REQUISITION_QUERY,
});
export const successGetRequisitionQuery = (data) => ({
  data,
  type: SUCCESS_GET_REQUISITION_QUERY,
});
export const errorGetRequisitionQuery = (data) => ({
  data,
  type: ERROR_GET_REQUISITION_QUERY,
});
export const resetRequisitionQuery = () => ({ type: RESET_REQUISITION_QUERY });
export const unsetRequisitionQueryResponse = (data) => ({
  data,
  type: UNSET_REQUISITION_QUERY_RESPONSE,
});

// Reducer
export const empty = {
  initialized: false,
  requesting: [],
  responses: [],
  results: {
    data: [],
    meta: {
      cursor: null,
      limit: null,
      next_cursor: null,
      query: null,
      total: null,
    },
  },
};

export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  switch (action.type) {
    case GET_REQUISITION_QUERY:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_REQUISITION_QUERY:
      return {
        ...state,
        results: {
          data:
            action.data.meta.query === state.results.meta.query &&
            action.data.meta.cursor !== state.results.meta.cursor
              ? state.results.data.concat(action.data.data)
              : action.data.data,
          meta: action.data.meta,
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case ERROR_GET_REQUISITION_QUERY:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case UNSET_REQUISITION_QUERY_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (item) => item.data._request !== action.data._request
        ),
      };
    case RESET_REQUISITION_QUERY:
    case SWITCH_LOCATION:
      return empty;
    default:
      return state;
  }
};

// Thunks
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetRequisitionQueryResponse(data));
      }, RESPONSE_TIMEOUT);
    };
  };
};

export const doGetRequisitionQuery = (data, options = { bubble: false }) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getRequisitionQuery(data));
    const location_id = data.location_id || getState().locations.open.id;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location_id}/requisitions/search`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Requisition Query' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetRequisitionQuery)({
            ...body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetRequisitionQuery)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
