import * as types from '../actions/action-types';

export const empty = {
  items: [],
  creating: [],
  updating: [],
  open: { id: 0 },
  isRequesting: false,
  error: false,
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.SUCCESS_GET_CUSTOMERS:
      return Object.assign({}, state, {
        isRequesting: false,
        error: false,
        items: action.data,
        open: action.data[0],
      });
    case types.UPDATE_CUSTOMER:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((t) => t.id !== action.data.id)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_CUSTOMER:
      return Object.assign({}, state, {
        updating: state.updating.filter((t) => t.id !== action.data.id),
        items: state.items.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, action.data);
        }),
        open:
          action.data.id === state.open.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_UPDATE_CUSTOMER:
      return Object.assign({}, state, {
        updating: state.updating.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, { error: action.data });
        }),
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
