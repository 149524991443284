import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextWithAnchors from './text-with-anchors.js';
import './expandable-text.css';

const ExpandableText = (props) => {
  return (
    <div
      className={classnames('expandable-text', {
        expanded: props.expanded,
      })}
      style={{
        maxHeight: props.expanded ? '1000px' : props.height,
      }}
    >
      <TextWithAnchors text={props.text} />
      <div className="expandable-text__fade">
        <button
          onClick={props.onExpand}
          className="tertiary expandable-text__more"
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
};

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
};

ExpandableText.defaultProps = {
  buttonText: '+ Read more',
  onExpand: function () {},
};

export default ExpandableText;
