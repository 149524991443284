import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import InputText from '../forms/input-text.js';
import { submitLocationList } from '../../actions/location-list-actions.js';
import './location-list-form-new.css';
const EMPTY_STATE = {
  name: '',
  code: '',
};

export class NewLocationListForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = EMPTY_STATE;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const success =
      nextProps.locationLists.filter((b) => b.name === this.state.name).length >
      0;
    if (this.props.isRequesting && !nextProps.isRequesting && success) {
      this.setState(() => {
        return EMPTY_STATE;
      });
      this.props.onSuccess && this.props.onSuccess(this.state);
    }
  }

  handleChangeName = (name) => {
    this.setState(() => {
      return { name };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.props.actions.submitLocationList({
      name: this.state.name,
      location_id: this.props.location.id,
    });
  };

  isDisabled = () => {
    return this.state.name.length < 2;
  };

  render() {
    return (
      <div className="location-list-form-new">
        <form className="location-list-form-new-form" onSubmit={this.onSubmit}>
          <InputText
            onChange={this.handleChangeName}
            value={this.state.name}
            label="List Name"
            placeholder="Name"
            className="location-list-input-name"
          />
          <Button primary disabled={this.isDisabled()}>
            Create Supply List
          </Button>
        </form>
      </div>
    );
  }
}

NewLocationListForm.propTypes = {
  locationLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  onSuccess: PropTypes.func,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    submitLocationList: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    locationLists: state.locationLists.items,
    location: state.locations.open,
    isRequesting: state.locationLists.creating.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationList,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locationLists.items === next.locationLists.items &&
    prev.locations.open.id === next.locations.open.id &&
    prev.locationLists.creating === next.locationLists.creating
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(NewLocationListForm);
