import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar.js';
import { formatStaffName, formatDateTime } from '../../lib/formatters.js';
import './comment-staff.css';

export default class CommentStaff extends React.PureComponent {
  formatName = () => {
    return formatStaffName(this.props.comment.commenter);
  };

  formatDate = () => {
    return formatDateTime(this.props.comment.updated_at);
  };

  render() {
    return (
      <div className="cleaning-comment-container staff">
        <div className="cleaning-comment comment-details-container">
          <div className="cleaning-comment commenter-name microcopy">
            {this.formatName()}
          </div>
          <div className="cleaning-comment body brand-border-color-override">
            {this.props.comment.body}
          </div>
          <div className="cleaning-comment date microcopy">
            {this.formatDate()}
          </div>
        </div>
        <div className="cleaning-comment commenter-avatar staff-avatar">
          <Avatar item={this.props.comment.commenter} width={24} height={24} />
        </div>
      </div>
    );
  }
}

CommentStaff.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    commentable_id: PropTypes.number.isRequired,
    commentable_type: PropTypes.string.isRequired,
    commenter_id: PropTypes.number.isRequired,
    commenter_type: PropTypes.string.isRequired,
    commenter: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};
