import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Table } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import ItemActions from './../purchaseOrders/item-actions.js';
import TrackingCode from './../shipments/tracking-code.js';
import { openShipment, doUpdateShipment } from '../../ducks/shipments.js';
import { trackShipmentExternalTracking } from '../../lib/analytics.js';
import {
  formatStatus,
  formatDeliveryWindow,
  STATUS_COLOR_MAP,
} from '../../helpers/shipment-helpers.js';
import 'semantic-ui-css/components/table.min.css';
import './../purchaseOrders/item.css';
import './shipment-item.css';

const PRODUCT_DISPLAY_MAX = 10;

export class ShipmentItem extends React.PureComponent {
  constructor(props) {
    super(props);
    let productRequisitionIds = props.requisition.product_requisitions.map(
      (pr) => pr.id
    );
    let displayableShipmentItems = props.shipment.product_purchase_orders
      .filter(
        (ppo) => productRequisitionIds.indexOf(ppo.product_requisition_id) > -1
      )
      .slice(0, PRODUCT_DISPLAY_MAX);
    this.state = {
      displayableShipmentItems,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let productRequisitionIds = nextProps.requisition.product_requisitions.map(
      (pr) => pr.id
    );
    let displayableShipmentItems = nextProps.shipment.product_purchase_orders
      .filter(
        (ppo) => productRequisitionIds.indexOf(ppo.product_requisition_id) > -1
      )
      .slice(0, PRODUCT_DISPLAY_MAX);
    this.setState(Object.assign({}, this.state, { displayableShipmentItems }));
  }

  _handleClickSeePackingSlip = () => {
    this.props.actions.openShipment(this.props.shipment);
  };

  _handleClickMarkReceived = () => {
    const receivedPurchaseOrder = Object.assign({}, this.props.shipment, {
      received_at: new Date(),
    });
    this.props.actions.doUpdateShipment(receivedPurchaseOrder);
  };

  onClickTracking = () => {
    trackShipmentExternalTracking(this.props.shipment);
  };

  isReceived = () => {
    if (
      this.props.shipment.missed_at &&
      this.props.shipment.received_at < this.props.shipment.missed_at
    ) {
      return false;
    }
    if (
      !this.props.shipment.received_at &&
      this.props.shipment.expected_at_end
    ) {
      return false;
    }
    if (this.props.shipment.received_at) {
      return true;
    }
    return false;
  };

  renderTrackingText = () => {
    return this.props.shipment.tracking_code;
  };

  renderProductPreview = (ppo, index) => {
    return (
      <div className="product-image" key={index}>
        <ItemImage
          width={50}
          height={50}
          size={'thumb'}
          product={ppo.product}
          title={ppo.product.name}
          alt={ppo.product.name}
        />
      </div>
    );
  };

  render() {
    return (
      <div
        className={classnames('shipment-item purchase-order-item', {
          received: this.isReceived(),
        })}>
        <div className="purchase-order-info">
          <h5
            className={classnames('shipment-item-index', {
              received: this.isReceived(),
            })}>
            Shipment {this.props.shipmentIndex} of {this.props.shipmentCount}
          </h5>
          <Table
            basic="very"
            compact
            unstackable
            className="shipment-item-tracking">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={7}>Expected</Table.HeaderCell>
                <Table.HeaderCell width={3}>Via</Table.HeaderCell>
                <Table.HeaderCell width={3}>Tracking Code</Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {this.props.shipment.status && 'Status'}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {formatDeliveryWindow(this.props.shipment)}
                </Table.Cell>
                <Table.Cell>{this.props.shipment.carrier}</Table.Cell>
                <Table.Cell>
                  <TrackingCode item={this.props.shipment} />
                </Table.Cell>
                <Table.Cell>
                  {this.props.shipment.status && (
                    <span
                      className="shipment-status"
                      style={{
                        color:
                          STATUS_COLOR_MAP[this.props.shipment.status] ||
                          'grey',
                      }}>
                      {formatStatus(this.props.shipment)}
                    </span>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="purchase-order-item-product-data">
            <div className="purchase-order-item-product-images">
              {this.state.displayableShipmentItems.map(
                this.renderProductPreview
              )}
              {this.props.shipment.product_purchase_orders.length >
                PRODUCT_DISPLAY_MAX && (
                <div className="purchase-order-item-extra-product-count light-gray-text">
                  {this.props.shipment.product_purchase_orders.length -
                    PRODUCT_DISPLAY_MAX}{' '}
                  More Items
                </div>
              )}
            </div>
          </div>
        </div>
        <ItemActions item={this.props.shipment} />
      </div>
    );
  }
}

ShipmentItem.propTypes = {
  actions: PropTypes.shape({
    doUpdateShipment: PropTypes.func.isRequired,
    openShipment: PropTypes.func.isRequired,
  }).isRequired,
  shipmentCount: PropTypes.number.isRequired,
  shipmentIndex: PropTypes.number.isRequired,
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number.isRequired,
    tracking_code: PropTypes.string.isRequired,
    received_at: PropTypes.string,
    receiver_id: PropTypes.number,
    receiver_type: PropTypes.string,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_zip: PropTypes.string,
    tracking_url: PropTypes.string,
    product_purchase_orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        product_id: PropTypes.number.isRequired,
        product_requisition_id: PropTypes.number,
        purchase_order_id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        received_at: PropTypes.string,
        issue: PropTypes.string,
        issue_quantity: PropTypes.number,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    requisition: state.requisitions.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openShipment,
        doUpdateShipment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.requisitions.open === next.requisitions.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ShipmentItem);
