import React from 'react';
import { formatDate } from '../../lib/formatters.js';
import { FILTER_DATES_BOOKABLE } from '../../strings.js';
import ScheduleAttendantForm from './schedule-attendant-form.js';
import { Button } from 'semantic-ui-react';
import './cleaning-details-empty-state.css';

export default class CleaningDetailsEmptyState extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showScheduleAttendantForm: false,
    };
  }

  toggleScheduleAttendantForm = (e) => {
    e.preventDefault();
    this.setState(
      Object.assign({}, this.state, {
        showScheduleAttendantForm: !this.state.showScheduleAttendantForm,
      })
    );
  };

  render() {
    return (
      <div className="cleaning-details-empty-state">
        <div className="fixed-width-column">
          <div className="empty-state-container">
            <div className="empty-shift-illustration"></div>
            <h3 className="empty-state-header">
              You don't have an Attendant booked for {formatDate(new Date())}.
            </h3>
            <p className="empty-state-subheader">
              Need somebody to cover the everyday tasks while you focus on a
              special project or big event?
            </p>
            {!this.state.showScheduleAttendantForm && (
              <Button primary onClick={this.toggleScheduleAttendantForm}>
                Book an Attendant
              </Button>
            )}
            {this.state.showScheduleAttendantForm && (
              <div className="schedule-attendant-form-container">
                <ScheduleAttendantForm filterBookable={FILTER_DATES_BOOKABLE} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
