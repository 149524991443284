import React from 'react';
import PropTypes from 'prop-types';
import { formatCentAmount, formatDateTime } from '../../lib/formatters.js';
import './item-delivery-info-pdf.css';

export default class DeliveryInfoPdf extends React.PureComponent {
  render() {
    return (
      <div className="delivery-info-pdf">
        <h3 className="location-name">{this.props.location.name}</h3>
        <div className="order-data-row">
          <p className="header bold-text">Order</p>
          <p className="data">#{this.props.item.id}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Ordered</p>
          <p className="data">{formatDateTime(this.props.item.submitted_at)}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Ordered By</p>
          <p className="data">{this.props.orderedBy}</p>
        </div>
        {this.props.proposedBy && (
          <div className="order-data-row">
            <p className="header bold-text">Requested By</p>
            <p className="data">{this.props.proposedBy}</p>
          </div>
        )}
        <div className="order-data-row">
          <p className="header bold-text"># of Items</p>
          <p className="data">{this.props.cartQuantity}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Shipments</p>
          <p className="data">{this.props.shipmentCount}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Shipping</p>
          <p className="data">{this.props.shippingPrice}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Tax</p>
          <p className="data">{formatCentAmount(this.props.item.tax)}</p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Total Spend</p>
          <p className="data">
            {formatCentAmount(this.props.item.total_price)}
          </p>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Shipped To</p>
          <div className="data">
            <p className="shipping-name">{this.props.item.shipping_name}</p>
            <p className="shipping-business">
              {this.props.item.shipping_business}
            </p>
            <p className="shipping-care">{this.props.item.shipping_care}</p>
            <p className="shipping-address">
              {this.props.item.shipping_address || '__'}
            </p>
            <p className="shipping-address-number">
              {this.props.item.shipping_address_number}
            </p>
            <p className="shipping-locality">
              {this.props.item.shipping_city || '__'},{' '}
              {this.props.item.shipping_state || '__'}{' '}
              {this.props.item.shipping_country || '__'}{' '}
              {this.props.item.shipping_zip || '__'}
            </p>
          </div>
        </div>
        <div className="order-data-row">
          <p className="header bold-text">Instructions/Comments</p>
          <div className="data">
            <p className="shipping-instructions">
              {this.props.item.instructions
                ? this.props.item.instructions
                : 'None'}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

DeliveryInfoPdf.propTypes = {
  orderedBy: PropTypes.string,
  proposedBy: PropTypes.string,
  shipmentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cartQuantity: PropTypes.string.isRequired,
  shippingPrice: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    total_price: PropTypes.number.isRequired,
    tax: PropTypes.number,
  }).isRequired,
};
