import React from 'react';
import PropTypes from 'prop-types';
import ItemImage from '../products/item-image.js';
import { Table, Icon } from 'semantic-ui-react';
import './supply-item-editable.css';

export class SupplyItemEditable extends React.PureComponent {
  render() {
    return (
      <Table compact basic className="supply-item-editable unstackable">
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing textAlign="left" verticalAlign="middle">
              <ItemImage
                product={this.props.item.product}
                title={this.props.item.product.name}
                alt={this.props.item.product.name}
              />
            </Table.Cell>
            <Table.Cell textAlign="left" verticalAlign="middle">
              <div className="item-product-brand">
                {this.props.item.product.brand}
              </div>
              <div className="item-name bold-text">
                {this.props.item.product.name}
              </div>
            </Table.Cell>
            <Table.Cell></Table.Cell>
            {this.props.onDeleteItem && (
              <Table.Cell
                collapsing
                textAlign="right"
                verticalAlign="middle"
                width="1"
              >
                <button
                  type="button"
                  className="no-style"
                  onClick={() => this.props.onDeleteItem(this.props.item)}
                >
                  <Icon
                    name="trash alternate outline"
                    size="large"
                    className="move-to-top"
                    title="Remove from your list"
                  />
                </button>
              </Table.Cell>
            )}
            {this.props.onMoveToTop && (
              <Table.Cell
                collapsing
                textAlign="right"
                verticalAlign="middle"
                width="1"
              >
                <button
                  type="button"
                  className="no-style"
                  onClick={() => this.props.onMoveToTop(this.props.item)}
                >
                  <Icon
                    name="arrow alternate circle up"
                    size="large"
                    className="move-to-top"
                    title="Move to top"
                  />
                </button>
              </Table.Cell>
            )}
            <Table.Cell
              collapsing
              textAlign="right"
              verticalAlign="middle"
              width="1"
            >
              <button type="button" className="draggable-control-wrap no-style">
                <Icon
                  name="ellipsis vertical"
                  size="large"
                  className="draggable-control"
                  title="Drag to reorder"
                />
                <Icon
                  name="ellipsis vertical"
                  size="large"
                  className="draggable-control"
                  title="Drag to reorder"
                />
              </button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

SupplyItemEditable.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onMoveToTop: PropTypes.func,
};

export default SupplyItemEditable;
