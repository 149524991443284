import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InvoiceItemList from '../invoices/item-list.js';
import InvoicingPreferences from './invoicing-preferences.js';
import { calculateQuarterDates } from '../../lib/formatters.js';

const FILTER_INVOICES = (i) => {
  return new Date(i.created_at) > new Date(2017, 0, 1);
};

export class Invoicing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quarterRange: calculateQuarterDates(new Date()),
    };
  }

  render() {
    return (
      <div className="customer-invoicing">
        <InvoicingPreferences />
        <div className="invoice-table-container">
          <InvoiceItemList filter={FILTER_INVOICES} />
        </div>
      </div>
    );
  }
}

Invoicing.propTypes = {
  openCustomer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    minimum_spend_monthly: PropTypes.number,
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    minimum_spend_monthly: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    openLocation: state.locations.open,
    openCustomer: state.customers.open,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.customers.open === next.customers.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Invoicing);
