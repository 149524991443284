import React from 'react';
import PropTypes from 'prop-types';
import IconShipmentDollar from '../icons/icon-shipment-dollar.js';
import IconShipment from '../icons/icon-shipment.js';
import { minimumInWords, minimumDeficitInWords } from '../../lib/formatters.js';
import { VendorMinimumGroupPropType } from '../../helpers/cart-helpers.js';
import './product-filters.css';

const ProductVendorMinimumFilterItem = (props) => {
  const { checked, option, onClick } = props;
  const noMinLabel = 'No Minimum Required';
  return (
    <span>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex="-1"
        style={{ float: 'left', marginRight: '7px' }}
      />
      {option.label === noMinLabel && <span>{option.label}</span>}
      {option.label !== noMinLabel && (
        <span>
          <div className="product-filter__icon-wrap">
            {option.group.minimum.type === 'price_min' && (
              <IconShipmentDollar
                color={option.color}
                size={22}
                style={{ verticalAlign: 'middle', marginRight: '0.25rem' }}
              />
            )}
            {option.group.minimum.type === 'quantity_min' && (
              <IconShipment
                color={option.color}
                size={22}
                style={{ verticalAlign: 'middle', marginRight: '0.25rem' }}
              />
            )}
            <strong style={{ color: option.color || '' }}>
              {minimumInWords(option.group)}
            </strong>
            &nbsp;min.
          </div>
          {option.group.vendor_product_requisitions.length > 0 && (
            <div className="product-filters__deficit">
              ({minimumDeficitInWords(option.group)} to go)
            </div>
          )}
        </span>
      )}
    </span>
  );
};

ProductVendorMinimumFilterItem.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.shape({
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    group: VendorMinimumGroupPropType.isRequired,
    color: PropTypes.string,
  }).isRequired,
};

export default ProductVendorMinimumFilterItem;
