import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, Segment } from 'semantic-ui-react';
import GroupedSpendTable from './budget-code-grouped-spend-table.js';
import GroupedSpendPie from './grouped-spend-pie.js';
import ModalEditBudgetCode from '../modals/modal-edit-budget-code.js';
import {
  openCustomerBudgetCodeCreateModal,
  openCustomerBudgetCode,
} from '../../actions/customer-budget-codes-actions.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './spend-by-budget-code-pie-table.css';

export class SpendByBudgetCodePieTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spendByBudgetCode: this._buildSpend(
        props.dataViews,
        props.budgetCodes.filter((cbc) => cbc.active)
      ),
      editing: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState((prev) => {
      return {
        spendByBudgetCode: this._buildSpend(
          props.dataViews,
          props.budgetCodes.filter((cbc) => (prev.editing ? true : cbc.active))
        ),
      };
    });
  }

  _buildSpend(dataViews, budgetCodes) {
    const initial = budgetCodes.reduce((acc, val) => {
      acc[val.id] = {
        name: val.name,
        id: val.id,
        active: val.active,
        line_items_submitted_price: 0,
        line_items_submitted_count: 0,
      };
      return acc;
    }, {});
    const sums = dataViews.reduce((dacc, dv) => {
      return dv.data.reduce((acc, val) => {
        if (!val.budget_code_id || !acc[val.budget_code_id]) return acc;

        acc[val.budget_code_id].line_items_submitted_count +=
          +val.customer_purchase_line_items_count;
        acc[val.budget_code_id].line_items_submitted_price +=
          +val.customer_purchase_line_items_total_spend;
        return acc;
      }, dacc);
    }, initial);
    return Object.keys(sums)
      .map((k) => {
        return sums[k];
      })
      .filter((s) => !!s.name)
      .sort((a, b) => {
        if (a.active && !b.active) return -1;
        if (b.active && !a.active) return 1;
        return a.line_items_submitted_price > b.line_items_submitted_price
          ? -1
          : 1;
      });
  }

  handleClickEditBudgetCodes = () => {
    this.setState((prev) => {
      return {
        editing: !prev.editing,
        spendByBudgetCode: this._buildSpend(
          this.props.dataViews,
          this.props.budgetCodes.filter((cbc) =>
            prev.editing ? cbc.active : true
          )
        ),
      };
    });
  };

  handleClickEditBudgetCode = (cbc) => {
    this.props.actions.openCustomerBudgetCode(cbc);
  };

  customBudgetCodesAccess = () => {
    if (!this.props.can_manage_billing) return false;
    let matchingPreferences = this.props.locations.filter((loc) => {
      return loc.pref_enable_custom_budget_codes;
    });
    return matchingPreferences.length === this.props.locations.length;
  };

  render() {
    return (
      <div className="spend-by-budget-code-pie-table">
        <Header attached="top" as="h2" className="borderless">
          Spend by Category
        </Header>
        <Segment
          loading={this.props.isRequesting}
          attached="bottom"
          className="borderless">
          <div className="pie-wrapper">
            <GroupedSpendPie data={this.state.spendByBudgetCode} />
          </div>
          <div className="table-wrapper">
            <GroupedSpendTable
              data={this.state.spendByBudgetCode}
              editing={this.state.editing}
              onClickAddBudgetCode={
                this.props.actions.openCustomerBudgetCodeCreateModal
              }
              onClickEditBudgetCodes={this.handleClickEditBudgetCodes}
              onClickEditBudgetCode={this.handleClickEditBudgetCode}
              displayFooter={this.customBudgetCodesAccess()}
            />
          </div>
          <ModalEditBudgetCode />
        </Segment>
      </div>
    );
  }
}

SpendByBudgetCodePieTable.propTypes = {
  budgetCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          customer_purchase_line_items_total_spend: PropTypes.number,
          customer_purchase_line_items_count: PropTypes.number,
          budget_code_id: PropTypes.number,
          submitted_date: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  can_manage_billing: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    openCustomerBudgetCodeCreateModal: PropTypes.func.isRequired,
    openCustomerBudgetCode: PropTypes.func.isRequired,
  }).isRequired,
};

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_budget_code_dates';
function mapStateToProps(state) {
  const locations = state.dataViews.openLocations.length
    ? state.dataViews.openLocations
    : [state.locations.open];
  const locationIds = locations.map((l) => l.id);
  const startDate = state.dataViews.openRange.startDate;
  const endDate = state.dataViews.openRange.endDate;
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === startDate.toJSON() &&
        d.end === endDate.toJSON() &&
        locationIds.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === startDate.toJSON() &&
          d.end === endDate.toJSON() &&
          locationIds.indexOf(d.topic.id) > -1
      ).length > 0,
    budgetCodes: state.customerBudgetCodes.items,
    can_manage_billing: state.auth.role.can_manage_billing,
    locations,
    startDate,
    endDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCustomerBudgetCodeCreateModal,
        openCustomerBudgetCode,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.isRequesting === next.dataViews.isRequesting &&
    prev.locations.open === next.locations.open &&
    prev.auth.role === next.auth.role &&
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SpendByBudgetCodePieTable);
