import ReactGA from 'react-ga';
import cookies from '../lib/encrypted-cookies.js';
import * as storage from '../lib/localstorage.js';
import * as types from '../actions/action-types.js';
import {
  AUTH_COOKIE,
  TRANSIENT_AUTH_COOKIE,
  COMPANY_DOMAIN,
  LAST_APP_COOKIE,
  APP_DOMAIN,
  STORAGE_USER,
} from '../strings.js';

const initialCookie = Object.assign({}, cookies.getJSON(AUTH_COOKIE));
const transientCookie = Object.assign(
  {},
  cookies.getJSON(TRANSIENT_AUTH_COOKIE)
);
const transientCookieData = transientCookie.data || {};
const initialStorageUser = storage.getItem(STORAGE_USER) || {};
const initialQueryOTP = window.location.search.match(
  /[&|?]+one_time_password_token=([A-Za-z0-9-_]+)/
);
if (initialCookie.hmac) {
  cookies.set(LAST_APP_COOKIE, APP_DOMAIN, { domain: COMPANY_DOMAIN });
}
if (transientCookieData.hmac) {
  cookies.remove(TRANSIENT_AUTH_COOKIE, { domain: COMPANY_DOMAIN });
  cookies.set(AUTH_COOKIE, transientCookieData);
  cookies.set(LAST_APP_COOKIE, APP_DOMAIN, { domain: COMPANY_DOMAIN });
}

export const empty = {
  isRequesting: false,
  error: false,
  errorResetPassword: false,
  errorForgotPassword: false,
  errorChangePassword: false,
  hmac: false,
  record: { username: '', id: 0 },
  actor: { type: '', id: 0 },
  forgotPassword: false,
  credentials: false,
  resetToken: false,
  lastFetch: null,
  OTPToken: null,
  role: {
    id: 0,
    approval_required_after_location_spend: 0,
    approval_required_after_employee_spend: 0,
    approved_customer_budget_code_ids: [],
    can_manage_employees_locations: false,
    can_manage_employees_stipends: false,
    can_manage_location_catalog: false,
    can_manage_cleanings: false,
    can_manage_billing: false,
    can_manage_roles: false,
    can_manage_all_requisitions: true,
    can_manage_insights: false,
    can_manage_location_lists: false,
  },
};

function buildActor(consumer) {
  if (!consumer || !consumer.username) {
    return empty.actor;
  }
  let match = consumer.username.match(/(.*)-(\d+)/);
  return {
    id: parseInt(match[2], 10),
    type: match[1].charAt(0).toUpperCase() + match[1].slice(1),
  };
}

export const initial = Object.assign({}, empty, {
  OTPToken: initialQueryOTP ? initialQueryOTP[1] : empty.OTPToken,
  role: initialStorageUser.customer_role || empty.role,
  hmac: transientCookieData.hmac || initialCookie.hmac || empty.hmac,
  record: transientCookieData.record || initialCookie.record || empty.record,
  actor: buildActor(transientCookieData.consumer || initialCookie.consumer),
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.POST_SIGN_IN:
      return Object.assign({}, state, {
        isRequesting: true,
        credentials: { username: action.credentials.username },
      });
    case types.SUCCESS_SIGN_IN:
      ReactGA.set({ userId: action.data.consumer.username });
      return Object.assign({}, state, {
        isRequesting: false,
        error: false,
        hmac: action.data.hmac,
        actor: buildActor(action.data.consumer),
        record: action.data.record,
        forgotPassword: false,
      });
    case types.SUCCESS_EMPLOYEE_SELF:
      return Object.assign({}, state, {
        role: action.data.customer_role,
        lastFetch: new Date(),
      });
    case types.SUCCESS_STAFF_SELF:
      return Object.assign({}, state, {
        lastFetch: new Date(),
      });
    case types.SWITCH_LOCATION:
      return Object.assign({}, state, {
        role: Object.assign({}, state.role, {
          approval_required_after_employee_spend: state.actor.type.match(
            /staff/i
          )
            ? action.data.pref_staff_requisition_approval
              ? 0
              : null
            : state.role.approval_required_after_employee_spend,
          approval_required_after_location_spend: state.actor.type.match(
            /staff/i
          )
            ? null
            : state.role.approval_required_after_location_spend,
        }),
      });
    case types.ERROR_SIGN_IN:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.FORGOT_PASSWORD:
      return Object.assign({}, state, {
        forgotPassword: true,
        credentials: { username: action.credentials.username },
      });
    case types.POST_RESET_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: true,
        credentials: { username: action.credentials.email },
      });
    case types.POST_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: true,
      });
    case types.SUCCESS_RESET_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        errorResetPassword: false,
        resetPassword: { data: action.data },
      });
    case types.SUCCESS_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        errorChangePassword: false,
      });
    case types.ERROR_RESET_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        resetPassword: false,
        errorResetPassword: action.data,
      });
    case types.ERROR_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        errorChangePassword: action.data,
      });
    case types.POST_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: true,
        credentials: { username: action.credentials.email },
      });
    case types.SUCCESS_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        errorForgotPassword: false,
        forgotPassword: { data: action.data },
      });
    case types.ERROR_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        isRequesting: false,
        forgotPassword: false,
        errorForgotPassword: action.data,
      });
    case types.SIGN_OUT:
      return empty;
    case types.SET_PASSWORD_RESET_TOKEN:
      return Object.assign({}, state, initial, {
        resetToken: action.data.token,
      });
    default:
      return state;
  }
};
