import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doGetAllProductCategorySubsets } from '../../actions/product-category-actions.js';
import ProductItem from '../products/item.js';
import './officeluv-category-catalog.css';

export class CategoryCatalogSubset extends React.PureComponent {
  UNSAFE_componentWillMount() {
    if (this.shouldLoadProducts(this.props.category)) {
      this.props.actions.doGetAllProductCategorySubsets(
        this.props.category,
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category.id !== this.props.category.id) {
      if (this.shouldLoadProducts(this.props.category)) {
        this.props.actions.doGetAllProductCategorySubsets(
          this.props.category,
          true
        );
      }
    } else if (prevProps.location_id !== this.props.location_id) {
      this.props.actions.doGetAllProductCategorySubsets(
        this.props.category,
        true
      );
    }
  }

  shouldLoadProducts(category) {
    return !category.products || !category.products.length;
  }

  renderItem = (item) => {
    return <ProductItem key={item.id} item={item} canFavorite={true} />;
  };

  render() {
    return (
      <div className="officeluv-category-catalog">
        <h4>{this.props.title}</h4>
        <div className="flex-wrapper category-subset">
          {this.props.products.map(this.renderItem)}
        </div>
      </div>
    );
  }
}

CategoryCatalogSubset.propTypes = {
  actions: PropTypes.shape({
    doGetAllProductCategorySubsets: PropTypes.func.isRequired,
  }).isRequired,
  subset: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  location_id: PropTypes.number,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    products: PropTypes.array,
    popular_products: PropTypes.array,
    location_popular_products: PropTypes.array,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  let products = state.productCategories.open.products;
  let title = 'All ';
  switch (props.subset) {
    case 'location-popular':
      products = state.productCategories.open.location_popular_products;
      title = 'Your top ';
      break;
    case 'popular':
      products = state.productCategories.open.popular_products;
      title = 'Top ';
      break;
    case 'all':
    default:
      break;
  }
  return {
    category: state.productCategories.open,
    products: products || [],
    location_id: state.locations.open.id,
    title: title + state.productCategories.open.name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetAllProductCategorySubsets,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.open === next.productCategories.open &&
    prev.locations.open.id === next.locations.open.id
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CategoryCatalogSubset);
