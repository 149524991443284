import React from 'react';
import PropTypes from 'prop-types';
import './search-item-category.css';

export default class SearchItemCategory extends React.PureComponent {
  render() {
    const match = this.props.suggestion.name.match(
      new RegExp(this.props.query, 'i')
    ) || { 0: '', index: 0 };
    return (
      <div className="search-item-category">
        {this.props.suggestion.name.slice(0, match.index)}
        <strong>
          {this.props.suggestion.name.slice(
            match.index,
            match.index + match[0].length
          )}
        </strong>
        {this.props.suggestion.name.slice(match.index + match[0].length)}
      </div>
    );
  }
}

SearchItemCategory.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
