import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autosuggest from 'react-autosuggest';
import mousetrap from 'mousetrap';
import {
  submitCatalogSearch,
  doGetCatalogSearches,
  showProductModal,
} from '../../actions/product-actions.js';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import {
  formatSnakeCaseToCamelCase,
  escapeRegExp,
  formatAlgoliaFilters,
} from '../../lib/formatters.js';
import { trackInterfaceSelectedProductSearchSuggestion } from '../../lib/analytics.js';
import perf from '../../lib/perf.js';
import searchProducts from '../../lib/search-products.js';
import {
  mapLocationParentCategoriesIds,
  productCategoryAsSuggestion,
} from '../../helpers/product-category-helpers.js';
import SearchItemHistory from './search-item-history.js';
import SearchItemProduct from './search-item-product.js';
import SearchItemCategory from './search-item-category.js';
import {
  HOTKEY_SEARCH,
  MAX_NUMBER_PRODUCT_SEARCH_DISPLAY,
  MIN_CHAR_SEARCH_DISPLAY,
  PRODUCT_CATEGORY,
  PRODUCT_CATEGORY_SEE_ALL_RESULTS,
} from '../../strings.js';
import { filterInLimitedCatalog } from '../../helpers/product-helpers.js';
import IconMagnify from '../../imgs/icon-magnify.js';
import './search.css';
const PERF_NAME = 'product_search_suggest';
const SEE_ALL_RESULTS = {
  id: -1,
  model: PRODUCT_CATEGORY_SEE_ALL_RESULTS,
  name: 'See All Results',
};
const CATEGORY_PREFIX = 'All ';

export class ProductSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    let text_typed = '';
    if (props.text_searched.length) {
      text_typed = props.text_searched;
    }

    this.state = {
      items: [],
      suggestions: [],
      isDisabled: true,
      submitted: false,
      text_typed: text_typed,
      selected_suggestion: {},
    };
  }

  handleClearSearch = () => {
    this.setState(() => {
      return {
        items: [],
        suggestions: [],
        isDisabled: true,
        submitted: false,
        text_typed: '',
        selected_suggestion: {},
      };
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearSearch && !this.props.clearSearch) {
      this.handleClearSearch();
    }
    if (this.props.text_searched.length && !nextProps.text_searched.length) {
      this.setState({ text_typed: '' });
    }
  }

  componentDidMount() {
    if (!this.props.catalog_searches_initialized) {
      this.props.actions.doGetCatalogSearches();
    }
    mousetrap.bind(HOTKEY_SEARCH, this.focusInput);
  }

  componentWillUnmount() {
    if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout);
    mousetrap.unbind(HOTKEY_SEARCH);
  }

  focusInput = () => {
    const input = document.querySelector('.react-autosuggest__input');
    if (!input) return;
    input.focus();
    return false;
  };

  isDisabled(newValue) {
    const onSearchPage = window.location.pathname.match(/catalog-search/);
    if (onSearchPage) return false;
    return newValue.length < 3;
  }

  filterSuggestionsByCategory = (product) => {
    return product.product_category_ids?.some((id) =>
      this.props.locationParentCategories.includes(id)
    );
  };

  onSuggestChange = (e, { newValue }) => {
    e.preventDefault();
    newValue = newValue || '';
    if (newValue === SEE_ALL_RESULTS.name) {
      newValue = this.state.text_typed;
    }
    this.setState({
      isDisabled: this.isDisabled(newValue),
      text_typed: newValue,
    });
  };

  onFocusInput = () => {
    let suggestions = this.props.catalog_searches;
    this.setState({ suggestions });
  };

  moveToPageTop = () => {
    const parent = document.querySelector('.officeluv-sibling-category-list');
    if (!parent || window.innerWidth > 768) return;
    window.scrollTo(0, parent.offsetTop);
  };

  getSuggestionsCategories = (value) => {
    const inputRegex = new RegExp(escapeRegExp(value.trim()), 'i');

    return this.props.suggestions
      .filter((p) => p.name.match(inputRegex))
      .sort((a, b) =>
        a.name.match(inputRegex).index < b.name.match(inputRegex).index ? -1 : 1
      )
      .slice(0, MAX_NUMBER_PRODUCT_SEARCH_DISPLAY - 1)
      .map((hit) => {
        if (hit.name.match(CATEGORY_PREFIX)) {
          return hit;
        } else {
          hit.name = CATEGORY_PREFIX.concat(hit.name);
          return hit;
        }
      });
  };

  getFilters = () => {
    let vendors = this.props.openLocation.vendors || [];
    return formatAlgoliaFilters(vendors, {});
  };

  fetchSuggestions = (value) => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;

    if (inputLength < MIN_CHAR_SEARCH_DISPLAY) {
      return this.setState(() => {
        return {
          text_typed: value,
          suggestions: this.props.catalog_searches,
          selected_suggestion: {},
        };
      });
    }
    this.setState(() => {
      return { submitted: false };
    });
    if (!this.props.debounce) {
      return this.execFetchSuggestions(inputValue);
    }
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.debounceTimeout = null;
      this.execFetchSuggestions(inputValue);
    }, this.props.debounce);
  };

  execFetchSuggestions = (value) => {
    perf.start(PERF_NAME);
    this.props
      .searchProductsAtLocation(this.props.openLocation.id, {
        query: value,
        hitsPerPage: MAX_NUMBER_PRODUCT_SEARCH_DISPLAY,
        filters: this.getFilters(),
        clickAnalytics: true,
      })
      .catch(() => {
        return { hits: [] };
      })
      .then((resp) => {
        let hits = resp.hits;
        if (this.props.secondaryFilter) {
          hits = hits.filter(this.props.secondaryFilter);
        }
        if (this.props.inLimitedViewingMode) {
          hits = filterInLimitedCatalog(hits, this.props.productsInCatalog);
        }
        const suggestions = this.getSuggestionsCategories(value)
          .concat(hits)
          .slice(0, MAX_NUMBER_PRODUCT_SEARCH_DISPLAY)
          .filter(this.filterSuggestionsByCategory)
          .concat(SEE_ALL_RESULTS);
        perf.end(PERF_NAME);
        perf.log(PERF_NAME);
        if (this.state.submitted) return;
        this.setState(() => {
          return {
            suggestions: suggestions,
            selected_suggestion: {},
          };
        });
      });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'input-changed') {
      this.fetchSuggestions(value);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      selected_suggestion: {},
    });
  };

  setSuggestionType = (suggestedSelection) => {
    return suggestedSelection.searcher_id
      ? 'CatalogSearch'
      : formatSnakeCaseToCamelCase(suggestedSelection.model || 'Product');
  };

  submitSearchHistory = (suggestion) => {
    let suggestedSelection = suggestion || this.state.selected_suggestion;
    let search_data = {
      text_typed: this.state.text_typed || this.props.text_searched,
      text_searched: this.state.text_typed,
      suggestion_type: this.setSuggestionType(suggestedSelection),
    };
    if (suggestedSelection.id > 0) {
      search_data.text_searched =
        suggestedSelection.name || suggestedSelection.text_searched;
      search_data.suggestion_id = suggestedSelection.id;
    }
    this.props.onSearchSubmit(search_data);
    if (
      suggestion &&
      suggestion.suggestion_type &&
      suggestion.suggestion_type === PRODUCT_CATEGORY
    )
      return;
    if (suggestion && suggestion.model && suggestion.model === PRODUCT_CATEGORY)
      return;

    this.props.actions.submitCatalogSearch(search_data);
  };

  onSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.id && suggestion.name && !suggestion.model) {
      this.setState(
        Object.assign({}, this.state, {
          suggestions: [],
        })
      );
      this.props.actions.setProductQuery({
        filters: {
          text: '',
          brandId: null,
        },
      });
      trackInterfaceSelectedProductSearchSuggestion(suggestion);
      return this.props.actions.showProductModal(suggestion);
    }
    this.setState(
      Object.assign({}, this.state, {
        suggestions: [],
        selected_suggestion: suggestion,
      })
    );
    let text;
    if (suggestion.id > 0) {
      text = suggestion.name || suggestion.text_searched;
    } else {
      text = this.state.text_typed;
    }
    this.props.actions.setProductQuery({
      filters: {
        text: text,
        brandId: null,
      },
    });
    this.submitSearchHistory(suggestion);
    this.props.actions.doGetLocationProductQueryResults();
    this.setState({ text_typed: text });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.isDisabled) return;
    this.submitSearchHistory();
    this.props.actions.setProductQuery({
      filters: {
        text: this.state.text_typed,
        brandId: null,
        categoryIds: [],
        brandNames: [],
        vendorIds: [],
        maxShippingTimes: [],
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
    this.setState(
      Object.assign({}, this.state, {
        suggestions: [],
        selected_suggestion: {},
        submitted: true,
        isDisabled: true,
      })
    );
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  renderSuggestion = (suggestion, { query }) => {
    const escapedQuery = escapeRegExp(query);
    if (!suggestion) return null;
    if (suggestion.text_searched) {
      return <SearchItemHistory suggestion={suggestion} />;
    }

    if (!suggestion.model) {
      return <SearchItemProduct suggestion={suggestion} query={escapedQuery} />;
    }
    if (suggestion.model === PRODUCT_CATEGORY) {
      return (
        <SearchItemCategory suggestion={suggestion} query={escapedQuery} />
      );
    }
    if (suggestion.model === SEE_ALL_RESULTS.model) {
      return <div className="see-all-results">{suggestion.name}</div>;
    }
  };

  render() {
    return (
      <div className="product-search">
        <form className="product-search-form" onSubmit={this.onSubmit}>
          <button
            type="submit"
            className="product-search-submit button-with-icon"
            title="Submit Search"
            disabled={this.state.isDisabled}
            style={{
              backgroundImage: `url("${IconMagnify({
                color: this.props.openLocation.brand_color,
              })}")`,
            }}>
            Go
          </button>
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            alwaysRenderSuggestions={true}
            inputProps={{
              placeholder: this.props.placeholder,
              onChange: this.onSuggestChange,
              onFocus: this.onFocusInput,
              value: this.state.text_typed,
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'false',
            }}
          />
        </form>
      </div>
    );
  }
}

ProductSearch.propTypes = {
  clearSearch: PropTypes.bool,
  onSearchSubmit: PropTypes.func.isRequired,
  secondaryFilter: PropTypes.func,
  placeholder: PropTypes.string,
  actions: PropTypes.shape({
    submitCatalogSearch: PropTypes.func.isRequired,
    doGetCatalogSearches: PropTypes.func.isRequired,
    showProductModal: PropTypes.func.isRequired,
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  searchProductsAtLocation: PropTypes.func.isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      model: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  catalog_searches: PropTypes.arrayOf(
    PropTypes.shape({
      text_typed: PropTypes.string.isRequired,
      text_searched: PropTypes.string.isRequired,
    })
  ).isRequired,
  text_searched: PropTypes.string.isRequired,
  categoryFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
  debounce: PropTypes.number,
  inLimitedViewingMode: PropTypes.bool.isRequired,
  productsInCatalog: PropTypes.array.isRequired,
};

ProductSearch.defaultProps = {
  debounce: 150,
};

function mapStateToProps(state) {
  return {
    searchProductsAtLocation: searchProducts,
    openLocation: state.locations.open,
    suggestions: state.productCategories.items.map(productCategoryAsSuggestion),
    catalog_searches: state.products.catalog_searches.slice(
      0,
      MAX_NUMBER_PRODUCT_SEARCH_DISPLAY
    ),
    catalog_searches_initialized: state.products.catalog_searches_initialized,
    text_searched: state.productQuery.filters.text,
    categoryFilters: state.productQuery.filters.categoryIds,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    productsInCatalog: state.products.in_catalog,
    locationParentCategories: mapLocationParentCategoriesIds(
      state.locationParentProductCategories.items
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitCatalogSearch,
        doGetCatalogSearches,
        setProductQuery,
        showProductModal,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.items === next.productCategories.items &&
    prev.products.catalog_searches === next.products.catalog_searches &&
    prev.products.catalog_searches_initialized ===
      next.products.catalog_searches_initialized &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items &&
    prev.locations.open === next.locations.open &&
    prev.productQuery.filters.text === next.productQuery.filters.text &&
    prev.productQuery.filters.categoryIds ===
      next.productQuery.filters.categoryIds &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.products.in_catalog === next.products.in_catalog
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductSearch);
