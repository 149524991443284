import React from 'react';

const withWindowSizes = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        windowWidth: window.innerWidth,
      };
    }
    componentDidMount() {
      window.addEventListener('resize', this.resizeThrottler);
    }

    componentWillUnmount() {
      this.resizeTimeout && window.clearTimeout(this.resizeTimeout);
      window.removeEventListener('resize', this.resizeThrottler);
    }
    resizeThrottler = () => {
      if (this.resizeTimeout) {
        return;
      }
      this.resizeTimeout = window.setTimeout(this.resizeListener, 200);
    };

    resizeListener = () => {
      this.resizeTimeout = null;
      if (this.state.windowWidth === window.innerWidth) return;
      this.setState(
        Object.assign({}, this.state, {
          windowWidth: window.innerWidth,
        })
      );
    };
    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
};
export default withWindowSizes;
