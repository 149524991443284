import React from 'react'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';

const CustomerRoleAdd = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Button
      primary
      style={{
        width: '160px',
      }}
    >
      <Icon name="add circle" />
      <Link to="/customer-roles/new">Add Role</Link>
    </Button>
    <Label
      pointing="left"
      style={{
        width: '55%',
        lineHeight: '1.2',
      }}
    >
      Create custom permissions and order approval settings by adding a role
    </Label>
  </div>
);

CustomerRoleAdd.propTypes = {};

export default CustomerRoleAdd;
