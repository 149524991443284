import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header, Segment } from 'semantic-ui-react';
import Cart from './cart.js';
import CartPunchoutOrderMessageForm from './cart-punchout-order-message-form.js';
import ShippableForm from '../modules/shippable-form.js';
import { CartPropType } from '../../helpers/cart-helpers.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './checkout-page.css';
import './checkout-form.css';

export class CheckoutConfirmContainer extends React.PureComponent {
  render() {
    return (
      <div className="checkout-page">
        <div className="variable-width-column flex-wrapper centered">
          <Cart
            showTax={true}
            showTotal={true}
            readOnly={true}
            hideCheckout={true}
          />
          <Segment
            basic
            className="checkout-form-container"
            style={{ maxWidth: '34rem' }}
            loading={this.props.isLoading}>
            {!this.props.isPunchoutOrder && (
              <Header as="h2">Your Order is Awaiting Approval</Header>
            )}
            {this.props.isPunchoutOrder && (
              <Header as="h2">Preparing Your Punchout Order</Header>
            )}
            <ShippableForm
              canSubmit={false}
              isDisabled={false}
              shippable={this.props.cart}
            />
            {this.props.isPunchoutOrder && (
              <CartPunchoutOrderMessageForm
                submit={true}
                cart={this.props.cart}
              />
            )}
          </Segment>
        </div>
      </div>
    );
  }
}

CheckoutConfirmContainer.propTypes = {
  cart: CartPropType,
  isLoading: PropTypes.bool.isRequired,
  isPunchoutOrder: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    cart: state.carts.open,
    isLoading: state.carts.open.id === 0,
    isPunchoutOrder:
      !!state.carts.open.awaiting_approval_at &&
      !!state.carts.open.punchout_session_id,
  };
}

export function areStatesEqual(prev, next) {
  return prev.carts.open === next.carts.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CheckoutConfirmContainer
);
