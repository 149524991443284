import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { openLocationCatalogProduct } from '../../actions/location-catalog-product-actions.js';
import { productIsInLimitedCatalog } from '../../helpers/product-helpers.js';
import './item-catalogable.css';

export class ItemCatalogable extends React.PureComponent {
  handleCatalogProduct = (e) => {
    e.stopPropagation();
    this.props.actions.openLocationCatalogProduct(this.props.item);
  };

  render() {
    if (!this.props.canManageLocationCatalog) return null;
    if (this.props.inLimitedViewingMode) return null;

    return (
      <span
        className={classnames('item-catalogable', {
          included: this.props.isInCatalog,
          'brand-bg-color-override': this.props.isInCatalog,
        })}
        title={
          this.props.isInCatalog
            ? 'Remove this product from your catalog'
            : 'Add this product to your catalog'
        }
        onClick={this.handleCatalogProduct}
      >
        {!this.props.isInCatalog && (
          <span className="item-catalogable__item">&#43;</span>
        )}
        {this.props.isInCatalog && (
          <span
            className={classnames('item-catalogable__item', {
              included: this.props.isInCatalog,
            })}
          >
            &#10004;
          </span>
        )}
      </span>
    );
  }
}

ItemCatalogable.propTypes = {
  inLimitedViewingMode: PropTypes.bool.isRequired,
  canManageLocationCatalog: PropTypes.bool.isRequired,
  isInCatalog: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    catalog_product_id: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    openLocationCatalogProduct: PropTypes.func.isRequired,
  }).isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    canManageLocationCatalog:
      state.locations.open.pref_enable_limited_catalog &&
      state.auth.role.can_manage_location_catalog,
    isInCatalog: productIsInLimitedCatalog(state.products.in_catalog)(
      ownProps.item
    ),
    inLimitedViewingMode: state.application.inLimitedViewingMode,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openLocationCatalogProduct,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.products.in_catalog === next.products.in_catalog &&
    prev.auth.role === next.auth.role &&
    prev.locations.open.pref_enable_limited_catalog ===
      next.locations.open.pref_enable_limited_catalog &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ItemCatalogable);
