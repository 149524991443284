import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showProductModal } from '../../actions/product-actions.js';
import ItemImage from './item-image.js';
import ItemFavorite from './item-favorite.js';
import ItemCatalogable from './item-catalogable.js';
import ItemVendorIcons from './item-vendor-icons.js';
import ProductStockNotifyIcon from './product-stock-notify-icon.js';
import {
  formatUnitOfPurchase,
  formatCentAmount,
} from '../../lib/formatters.js';
import { trackInterfaceAddToCartFromProductItem } from '../../lib/analytics.js';
import { PRODUCT_IS_IN_STOCK } from '../../flags.js';
import {
  editAddProductRequistion,
  createOrUpdateProductRequisition,
} from '../../actions/requisition-actions.js';
import { updateStagedScheduledProductRequisition } from '../../actions/scheduled-product-requisition-actions.js';
import './item.css';

export class ProductItem extends React.PureComponent {
  handleProductModalTrigger = () => {
    this.props.actions.showProductModal(this.props.item);
  };

  addToOrder = (e) => {
    e.stopPropagation();
    let quantity = 1;
    let id;

    if (this.props.isEditingOrder) {
      this._handleEditProductRequisition(id, quantity);
    } else if (
      this.props.isEditingScheduledOrder ||
      this.props.isCreatingScheduledOrder
    ) {
      this._handleEditScheduledProductRequisition(quantity);
    } else {
      this._handleProductRequisition(id, quantity);
    }
    trackInterfaceAddToCartFromProductItem(this.props.item);
  };

  _handleProductRequisition = (id, quantity) => {
    if (this.props.inOrder) {
      const prodReq = this.props.product_requisitions[0];
      id = prodReq.id;
      quantity = prodReq.quantity + 1;
    }
    this.props.actions.createOrUpdateProductRequisition({
      id,
      product: this.props.item,
      product_id: this.props.item.id,
      quantity,
      requisition_id: this.props.openRequisition.id,
    });
  };

  _handleEditProductRequisition = (id, quantity) => {
    if (this.props.inOrder) {
      quantity = this.props.product_requisitions[0].quantity + 1;
    }
    this.props.actions.editAddProductRequistion({
      price: this.props.item.price,
      product: this.props.item,
      product_id: this.props.item.id,
      quantity,
      requisition_id: this.props.openRequisition.id,
    });
  };

  _handleEditScheduledProductRequisition = (quantity) => {
    if (this.props.inOrder) {
      const schedProdReq = this.props.scheduled_product_requisitions[0];
      quantity = schedProdReq.quantity + 1;
    }
    this.props.actions.updateStagedScheduledProductRequisition({
      product_id: this.props.item.id,
      price: this.props.item.price,
      product: this.props.item,
      quantity,
    });
  };

  reduceItems = (acc, val) => acc + val.quantity;

  cartQuantity = () => {
    let collection = this.props.product_requisitions;
    if (
      this.props.openScheduledRequisition.id ||
      this.props.isEditingScheduledOrder ||
      this.props.isCreatingScheduledOrder
    ) {
      collection = this.props.scheduled_product_requisitions;
    }
    return (collection || []).reduce(this.reduceItems, 0);
  };

  renderButtonText = () => {
    const { inOrder, inStock } = this.props;
    let orderName = 'Cart';
    if (!inStock) {
      return 'Out of Stock';
    }
    if (this.props.isEditingOrder) orderName = 'Order';
    if (this.props.isEditingScheduledOrder) orderName = 'Order';
    if (this.props.isCreatingScheduledOrder) orderName = 'Order';
    if (this.props.openScheduledRequisition.id) orderName = 'Order';
    if (inOrder) {
      return `${this.cartQuantity()} In ${orderName}`;
    } else {
      return `Add to ${orderName}`;
    }
  };

  render() {
    const { inStock } = this.props;
    return (
      <div className="product-item" onClick={this.handleProductModalTrigger}>
        <div className="item-details item-preview">
          <div className="item-image">
            <div
              className={classnames('', {
                'in-cart': !inStock,
              })}
            >
              <ItemImage product={this.props.item} />
            </div>
          </div>
          <ItemFavorite
            canFavorite={this.props.canFavorite}
            item={this.props.item}
          />
          <ItemCatalogable item={this.props.item} />
          <div className="item-text">
            <div className="item-details brand">{this.props.item.brand}</div>
            <div className="item-details name bold-text">
              {this.props.item.name}
            </div>
            <div className="item-details uop">
              {formatUnitOfPurchase(
                this.props.item.unit_purchase,
                this.props.item.stock_per_purchase,
                this.props.item.unit_stock
              )}
            </div>
            <div className="not-in-cart-state">
              <div className="item-details price">
                {formatCentAmount(this.props.item.price)}
              </div>
              <ItemVendorIcons item={this.props.item} />
            </div>
          </div>
          <div className="item-details-button-wrap">
            <button
              className="purchase button-with-icon brand-color-override"
              disabled={!inStock}
              type="button"
              onClick={this.addToOrder}
            >
              {this.renderButtonText()}
            </button>
            {!inStock && <ProductStockNotifyIcon product={this.props.item} />}
          </div>
        </div>
      </div>
    );
  }
}

ProductItem.propTypes = {
  actions: PropTypes.shape({
    showProductModal: PropTypes.func.isRequired,
    createOrUpdateProductRequisition: PropTypes.func.isRequired,
    editAddProductRequistion: PropTypes.func.isRequired,
    updateStagedScheduledProductRequisition: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    unit_purchase: PropTypes.string.isRequired,
    stock_per_purchase: PropTypes.number.isRequired,
    unit_stock: PropTypes.string.isRequired,
    last_submitted_date: PropTypes.string,
  }).isRequired,
  product_requisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product_id: PropTypes.number.isRequired,
    })
  ),
  scheduled_product_requisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      product_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  isEditingOrder: PropTypes.bool.isRequired,
  canFavorite: PropTypes.bool.isRequired,
  openRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  openScheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  isEditingScheduledOrder: PropTypes.bool.isRequired,
  isCreatingScheduledOrder: PropTypes.bool.isRequired,
};

ProductItem.defaultProps = {
  canFavorite: false,
  isInLocationList: false,
  isEditingScheduledOrder: false,
  isCreatingScheduledOrder: false,
};

function mapStateToProps(state, props) {
  let openRequisition = state.carts.open;
  const isEditingOrder = state.requisitions.editing.length > 0;
  if (isEditingOrder) {
    openRequisition = state.requisitions.editing[0];
  }

  const isEditingScheduledOrder =
    state.scheduledRequisitions.staged.length &&
    state.scheduledRequisitions.staged[0].id > 0;
  if (isEditingScheduledOrder) {
    openRequisition = state.scheduledRequisitions.staged[0];
  }
  const isCreatingScheduledOrder = !!window.location.pathname.match(
    'orders/scheduled/new'
  );
  const product_requisitions = (
    openRequisition.product_requisitions || []
  ).filter((pr) => pr.product_id === props.item.id);

  const openScheduledRequisition = state.scheduledRequisitions.open;
  let scheduled_product_requisitions = (
    openScheduledRequisition.scheduled_product_requisitions || []
  ).filter((spr) => spr.product_id === props.item.id);

  let inOrder = product_requisitions.length > 0;
  if (openScheduledRequisition.id)
    inOrder = scheduled_product_requisitions.length > 0;

  if (isEditingScheduledOrder || isCreatingScheduledOrder) {
    scheduled_product_requisitions =
      state.scheduledRequisitions.staged[0].scheduled_product_requisitions.filter(
        (spr) => spr.product_id === props.item.id
      );
    inOrder = scheduled_product_requisitions.length > 0;
  }

  const inStock = PRODUCT_IS_IN_STOCK(props.item);

  return {
    openRequisition,
    openScheduledRequisition,
    isEditingOrder,
    isEditingScheduledOrder,
    isCreatingScheduledOrder,
    product_requisitions,
    scheduled_product_requisitions,
    inOrder,
    inStock,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        editAddProductRequistion,
        createOrUpdateProductRequisition,
        updateStagedScheduledProductRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open.product_requisitions ===
      next.carts.open.product_requisitions &&
    prev.requisitions.editing === next.requisitions.editing &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.scheduledRequisitions.staged === next.scheduledRequisitions.staged
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductItem);
