import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';

export const ERROR_TIMEOUT = 5000;
// Actions
export const GET_FEATURE_TOUR_ATTEMPTS = 'GET_FEATURE_TOUR_ATTEMPTS';
export const SUCCESS_GET_FEATURE_TOUR_ATTEMPTS =
  'SUCCESS_GET_FEATURE_TOUR_ATTEMPTS';
export const ERROR_GET_FEATURE_TOUR_ATTEMPTS =
  'ERROR_GET_FEATURE_TOUR_ATTEMPTS';

export const CREATE_FEATURE_TOUR_ATTEMPT = 'CREATE_FEATURE_TOUR_ATTEMPT';
export const SUCCESS_CREATE_FEATURE_TOUR_ATTEMPT =
  'SUCCESS_CREATE_FEATURE_TOUR_ATTEMPT';
export const ERROR_CREATE_FEATURE_TOUR_ATTEMPT =
  'ERROR_CREATE_FEATURE_TOUR_ATTEMPT';

export const UPDATE_FEATURE_TOUR_ATTEMPT = 'UPDATE_FEATURE_TOUR_ATTEMPT';
export const SUCCESS_UPDATE_FEATURE_TOUR_ATTEMPT =
  'SUCCESS_UPDATE_FEATURE_TOUR_ATTEMPT';
export const ERROR_UPDATE_FEATURE_TOUR_ATTEMPT =
  'ERROR_UPDATE_FEATURE_TOUR_ATTEMPT';

export const UNSET_FEATURE_TOUR_ATTEMPT_RESPONSE =
  'UNSET_FEATURE_TOUR_ATTEMPT_RESPONSE';
export const STAGE_FEATURE_TOUR_ATTEMPT = 'STAGE_FEATURE_TOUR_ATTEMPT';
export const UNSTAGE_FEATURE_TOUR_ATTEMPT = 'UNSTAGE_FEATURE_TOUR_ATTEMPT';
export const UPDATE_STAGED_FEATURE_TOUR_ATTEMPT =
  'UPDATE_STAGED_FEATURE_TOUR_ATTEMPT';

// Helpers

// Helpers
export const FILTER_IS_REQUESTING = (response) =>
  response.type === GET_FEATURE_TOUR_ATTEMPTS;

// Reducer
export const empty = {
  items: {},
  open: {
    id: 0,
  },
  staged: {
    id: 0,
  },
  summary: {},
  meta: {
    cursor: 0,
    next_cursor: 0,
    prev_cursor: 0,
    total: 0,
  },
  requesting: [],
  responses: [],
};

export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  let items;
  switch (action.type) {
    case GET_FEATURE_TOUR_ATTEMPTS:
    case CREATE_FEATURE_TOUR_ATTEMPT:
    case UPDATE_FEATURE_TOUR_ATTEMPT:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_FEATURE_TOUR_ATTEMPTS:
      return {
        ...state,
        items: Object.assign(
          {},
          state.items,
          action.data.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {})
        ),
        open: {
          ...state.open,
          ...action.data.items.filter((s) => s.id === state.open.id)[0],
        },
        staged: {
          ...state.staged,
          ...action.data.items.filter((s) => s.id === state.staged.id)[0],
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        summary: action.data.items.reduce(reduceSummary, {}),
        responses: state.responses.concat(action),
        meta: action.data.meta,
      };
    case ERROR_GET_FEATURE_TOUR_ATTEMPTS:
    case ERROR_CREATE_FEATURE_TOUR_ATTEMPT:
    case ERROR_UPDATE_FEATURE_TOUR_ATTEMPT:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };

    case SUCCESS_CREATE_FEATURE_TOUR_ATTEMPT:
    case SUCCESS_UPDATE_FEATURE_TOUR_ATTEMPT:
      items = { ...state.items };
      items[action.data.item.id] = action.data.item;
      return {
        ...state,
        items: items,
        open:
          action.data.item.id === state.open.id ? action.data.item : state.open,
        summary: Object.values(items).reduce(reduceSummary, {}),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_FEATURE_TOUR_ATTEMPT_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case STAGE_FEATURE_TOUR_ATTEMPT:
      return {
        ...state,
        staged: state.items[action.data.id] || action.data,
      };
    case UPDATE_STAGED_FEATURE_TOUR_ATTEMPT:
      return {
        ...state,
        staged: {
          ...state.staged,
          ...action.data,
        },
      };
    case UNSTAGE_FEATURE_TOUR_ATTEMPT:
      return {
        ...state,
        staged: empty.staged,
      };
    default:
      return state;
  }
};

function reduceSummary(acc, val) {
  let tour = acc[val.feature_tour_id] || { id: val.feature_tour_id };
  if (tour.skipped_at) {
    tour.skipped_at =
      val.skipped_at > tour.skipped_at ? val.skipped_at : tour.skipped_at;
  } else {
    tour.skipped_at = val.skipped_at;
  }
  if (tour.completed_at) {
    tour.completed_at =
      val.completed_at > tour.completed_at
        ? val.completed_at
        : tour.completed_at;
  } else {
    tour.completed_at = val.completed_at;
  }
  tour.attempts = (tour.attempts || 0) + 1;
  acc[tour.id] = tour;
  return acc;
}

// Action Creators
export const getFeatureTourAttempts = (data) => ({
  data,
  type: GET_FEATURE_TOUR_ATTEMPTS,
});
export const successGetFeatureTourAttempts = (data) => ({
  data,
  type: SUCCESS_GET_FEATURE_TOUR_ATTEMPTS,
});
export const errorGetFeatureTourAttempts = (data) => ({
  data,
  type: ERROR_GET_FEATURE_TOUR_ATTEMPTS,
});
export const updateFeatureTourAttempt = (data) => ({
  data,
  type: UPDATE_FEATURE_TOUR_ATTEMPT,
});
export const successUpdateFeatureTourAttempt = (data) => ({
  data,
  type: SUCCESS_UPDATE_FEATURE_TOUR_ATTEMPT,
});
export const errorUpdateFeatureTour = (data) => ({
  data,
  type: ERROR_UPDATE_FEATURE_TOUR_ATTEMPT,
});
export const createFeatureTourAttempt = (data) => ({
  data,
  type: CREATE_FEATURE_TOUR_ATTEMPT,
});
export const successCreateFeatureTourAttempt = (data) => ({
  data,
  type: SUCCESS_CREATE_FEATURE_TOUR_ATTEMPT,
});
export const errorCreateFeatureTourAttempt = (data) => ({
  data,
  type: ERROR_CREATE_FEATURE_TOUR_ATTEMPT,
});
export const stageFeaureTourAttempt = (data) => ({
  data,
  type: STAGE_FEATURE_TOUR_ATTEMPT,
});
export const unstageFeatureTourAttempt = (data) => ({
  data,
  type: UNSTAGE_FEATURE_TOUR_ATTEMPT,
});
export const updateStagedFeatureTourAttempt = (data) => ({
  data,
  type: UPDATE_STAGED_FEATURE_TOUR_ATTEMPT,
});
export const unsetFeatureTourAttemptResponse = (data) => ({
  data,
  type: UNSET_FEATURE_TOUR_ATTEMPT_RESPONSE,
});

/// THUNKS
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetFeatureTourAttemptResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetFeatureTourAttempts = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getFeatureTourAttempts(data));
    return fetch(`${ADMIN_V1_URL}/employees/${data.id}/feature_tour_attempts`, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get employee feature tour attempts',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetFeatureTourAttempts)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetFeatureTourAttempts)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doUpdateFeatureTourAttempt = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(updateFeatureTourAttempt(data));
    return fetch(
      `${ADMIN_V1_URL}/employee/${data.employee_id}/feature_tour_attempts/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update Feature Tour Attempt',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateFeatureTourAttempt)({
            item: body,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateFeatureTour)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doCreateFeatureTourAttempt = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(createFeatureTourAttempt(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.employee_id}/feature_tour_attempts`,
      {
        method: 'POST',
        headers: {
          'X-Request-ID': data._request,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create Feature Tour Attempt',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successCreateFeatureTourAttempt)({
            item: body,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateFeatureTourAttempt)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doSubmitStagedFeatureTourAttempt = (data = {}) => {
  return (dispatch, getState) => {
    const {
      customerRoles: { staged },
    } = getState();
    if (staged.id > 0) {
      dispatch(
        doUpdateFeatureTourAttempt({
          ...staged,
          ...data,
        })
      );
    } else {
      dispatch(
        doCreateFeatureTourAttempt({
          ...staged,
          ...data,
        })
      );
    }
  };
};
