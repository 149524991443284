import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { formatCentAmount, formatShortDate } from '../../lib/formatters.js';
import './insights-pdf-locations-data.css';
const VIEW_NAME = 'customer_purchase_dates';

export class InsightsPdfLocationsData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalSpend: this._calculateTotalSpend(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        totalSpend: this._calculateTotalSpend(nextProps),
      })
    );
  }

  _calculateTotalSpend = (props) => {
    if (
      !props.requisitionsByDate ||
      !props.requisitionsByDate.data ||
      !props.requisitionsByDate.data.length > 0
    ) {
      return 0;
    }
    return props.requisitionsByDate.data.reduce(this._reduceSubmittedPrice, 0);
  };

  _reduceSubmittedPrice(acc, i) {
    return acc + i.customer_purchases_total_spend;
  }

  render() {
    return (
      <div className="insights-pdf-locations-data">
        <h3 className="location-name">{this.props.openLocation.name}</h3>
        <div className="insights-data-row">
          <p className="header bold-text">Date Range</p>
          <p className="data">
            {formatShortDate(this.props.startDate)} -{' '}
            {formatShortDate(this.props.endDate)}
          </p>
        </div>
        <div className="insights-data-row">
          <p className="header bold-text">Annual Budget</p>
          <p className="data">
            {formatCentAmount(this.props.openLocation.budget_annual || 0)}
          </p>
        </div>
        <div className="insights-data-row">
          <p className="header bold-text">Employee Count</p>
          <p className="data">
            {(
              this.props.openLocation.number_of_employees || 0
            ).toLocaleString()}
          </p>
        </div>
        <div className="insights-data-row">
          <p className="header bold-text">Total Office Spend</p>
          <p className="data">{formatCentAmount(this.state.totalSpend)}</p>
        </div>
        <div className="insights-data-row">
          <p className="header bold-text">Spend Per Employee</p>
          <p className="data">
            {formatCentAmount(
              this.state.totalSpend /
                this.props.openLocation.number_of_employees
            )}
          </p>
        </div>
      </div>
    );
  }
}

InsightsPdfLocationsData.propTypes = {
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    budget_annual: PropTypes.number,
    number_of_employees: PropTypes.number,
  }).isRequired,
  requisitionsByDate: PropTypes.shape({
    view: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        submitted_date: PropTypes.string,
        budget_annual: PropTypes.number,
        requisitions_submitted_total_price: PropTypes.number,
      })
    ).isRequired,
    topic: PropTypes.shape({
      id: PropTypes.number.isRequired,
      model: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

function mapStateToProps(state, props) {
  return {
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === VIEW_NAME &&
          d.topic.model === 'location' &&
          d.topic.id === props.openLocation.id
      ).length > 0,
    requisitionsByDate: state.dataViews.items.filter(
      (d) =>
        d.view === VIEW_NAME &&
        d.topic.model === 'location' &&
        d.topic.id === props.openLocation.id
    )[0],
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsPdfLocationsData);
