// BASIC INTRO PAGE
export const allFeatureTourSteps = {
  order_intro_enabled: function () {
    return [
      {
        element: '.foo',
        tooltipClass: 'tooltip-big',
        intro:
          "<h2>Congrats on your order!</h2><p>Let's take a look at your Insights page to learn how you can keep track of what you're spending.</p>",
      },
      {
        element: '.intro--budget-insights-view-link-in-app',
        intro: 'Click here to learn more',
        pushTo: '/insights/budgets',
      },
    ];
  },
  budget_insights_enabled: function () {
    return [
      {
        element: '.foo',
        tooltipClass: 'tooltip-big',
        intro:
          '<h2>Welcome to your Insights dashboard.</h2><p>This is where you can find a variety of ways to track your spending.</p>',
      },
      {
        element: '.office-budget-settings',
        intro:
          'First you’ll enter your employee count and budget (or estimated spend if you don’t have a budget).',
      },
      {
        element: '.insights-downloads',
        intro:
          'Here you can download a line-by-line report of items purchased within a designated date range.',
      },
      {
        element: '.intro--budget-insights-controls',
        intro:
          'This is where you can change the date range or location, if managing multiple offices.',
      },
      {
        element: '.spend-to-budget-progress-pie',
        intro:
          'Once you set a budget, this is where you’ll see your spend toward your budget so far.',
      },
      {
        element: '.spend-by-budget-code-pie-table',
        intro:
          'You can view your Spend by Category and add custom categories here.',
      },
      {
        element: '.marketplace-past-orders',
        intro:
          'You can reassign one-time purchases into specific categories in your Past Orders page, ie Holiday Party Items.',
        scrollToTop: true,
      },
      {
        element: '.intro--budget-insights-line-item',
        intro: 'Click into any category for a further breakdown of your spend.',
      },
      {
        element: '.intro--budget-insights',
        intro: 'You can always get back to this dashboard by clicking here.',
        scrollToTop: true,
      },
    ];
  },
  initial_intro_enabled: function (role) {
    const canEditOffice =
      role.can_manage_employees_locations ||
      role.can_manage_all_requisitions ||
      role.can_manage_billing ||
      role.can_manage_roles;
    return [
      {
        element: '.foo',
        tooltipClass: 'tooltip-big',
        intro:
          "<h2>You're All Set!</h2><p>Let's take a quick tour through OfficeLuv.</p>",
      },
      {
        element: '.intro--settings',
        intro: canEditOffice
          ? 'Update your Office and Personal Information here.'
          : 'Update your Personal Information here.',
      },
      {
        element: '.zEWidget-launcher',
        intro:
          'If you ever have a question, reach out using the Help button and we’ll be happy to assist.',
      },
    ];
  },
};

// SHARED FUNCTIONS
export function highlightNextStep(nextStep) {
  const element = document.querySelector(nextStep);

  if (element && element.children && element.children.length > 0) {
    return element.children[0].click();
  } else if (element) {
    return element.click();
  } else {
    console.log(element);
  }
}
