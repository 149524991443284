import {
  trackFetchError,
  trackEmailBookAttendant,
  trackEmailHelp,
  trackEmailACH,
  trackEmailServiceQuote,
} from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import fetch from '../lib/hmac-fetch.js';
import { formatDate } from '../lib/formatters.js';
import log from '../lib/log.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import {
  ENV,
  HEADERS_JSON,
  HELP_EMAIL,
  DEV_EMAIL,
  ADMIN_V1_URL,
} from '../strings.js';

export function sendEmailToBookAttendant(
  bookingDetails,
  user,
  location,
  customer
) {
  return (dispatch) => {
    const logger = log('[bookAttendantForm]');
    const BOOK_ATTENDANT_EMAIL =
      ENV === 'PROD' ? location.account_manager.email : HELP_EMAIL;

    let subject = `${location.name} has requested to book an attendant`;
    let date = formatDate(bookingDetails.date);
    let time = bookingDetails.time;
    let details = bookingDetails.details;
    let body = {
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: BOOK_ATTENDANT_EMAIL,
      subject,
      body: `
            message:              ${user.first_name} ${user.last_name} at ${location.name} has requested to book an attendant
            requester:            ${user.first_name} ${user.last_name}
            customer:             ${location.name} - ${location.address} ${location.address2} ${location.city}, ${location.state} ${location.zip}
            date:                 ${date}
            time:                 ${time}
            details:              ${details}
            category:             Additional service request
            additional_details:   Customer ID: ${customer.id} - Location ID: ${location.id} - Employee ID: ${user.id}
            `,
    };

    trackEmailBookAttendant(body);

    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Create request to book attendant',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: `Requested attendant for ${date}.`,
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
        logger.error('error', error);
      });
  };
}

export function doRequestHelp(data) {
  return (dispatch, getState) => {
    const logger = log('[helpButtonForm]');
    const { employees, locations, customers } = getState();
    const user = employees.user;
    const location = locations.open;
    const RECIPIENT_EMAIL =
      ENV === 'PROD' ? location.account_manager.email : HELP_EMAIL;
    const customer = customers.open;
    let subject =
      'Help message from: ' +
      customer.name +
      ' at ' +
      location.name +
      ' by ' +
      user.first_name +
      ' ' +
      user.last_name;
    let body = {
      subject,
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: RECIPIENT_EMAIL,
      helpRequest: data.text,
      body: `
            message:              ${data.text}
            requester:            ${user.first_name} ${user.last_name}
            customer:             ${location.name} - ${location.address} ${
        location.address2
      } ${location.city}, ${location.state} ${location.zip}
            date:                 ${new Date()}
            additional_details:   Customer ID: ${customer.id} - Location ID: ${
        location.id
      } - Employee ID: ${user.id}
            `,
    };

    trackEmailHelp(body);
    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Submit help request',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: 'Submitted help request.',
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
        logger.error('error', error);
      });
  };
}

export function doRequestACH() {
  return (dispatch, getState) => {
    const { employees, locations, customers } = getState();
    const user = employees.user;
    const location = locations.open;
    const RECIPIENT_EMAIL =
      ENV === 'PROD' ? 'billing@officeluv.com' : HELP_EMAIL;
    const customer = customers.open;
    let body = {
      subject: `ACH setup request from ${location.name}`,
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: RECIPIENT_EMAIL,
      body: `
            message:              Requesting ACH setup
            requester:            ${user.first_name} ${user.last_name} (${
        user.email
      })
            customer:             ${location.name} - ${location.address} ${
        location.address2
      } ${location.city}, ${location.state} ${location.zip}
            date:                 ${new Date()}
            additional_details:   Customer ID: ${customer.id} - Location ID: ${
        location.id
      } - Employee ID: ${user.id}
            `,
    };

    trackEmailACH(body);
    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Submit ACH setup request',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: 'Submitted ACH setup request.',
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
      });
  };
}

export function doRequestServiceQuote(formData) {
  return (dispatch, getState) => {
    const { auth, locations, customers } = getState();
    const user = auth.record;
    const location = locations.open;
    const RECIPIENT_EMAIL =
      ENV === 'PROD' ? location.account_manager.email : DEV_EMAIL;
    const customer = customers.open;
    let formDataBody = Object.keys(formData)
      .filter((key) => formData[key])
      .map((key) => {
        return `<li>${key}: ${formData[key]}</li>`;
      })
      .join('');
    let body = {
      subject: `Service Quote request from ${location.name}`,
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: RECIPIENT_EMAIL,
      body: `
            <p>Someone is requesting a service quote</p>
            <ul>
                <li>requester: ${user.first_name} ${user.last_name} (${
        user.email
      })</li>
                <li>customer: ${location.name} - ${location.address} ${
        location.address2
      } ${location.city}, ${location.state} ${location.zip}</li>
                <li>date: ${new Date()}</li>
                <li>Customer ID: ${customer.id}</li>
                <li>Location ID: ${location.id}</li>
                <li>Employee ID: ${user.id}</li>
                ${formDataBody}
            </ul>
            `,
    };

    trackEmailServiceQuote(body);
    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Submit Service Quote Request',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: 'Quote Requested!',
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
      });
  };
}

export function doRequestCategorySpendAlert() {
  return (dispatch, getState) => {
    const { employees, locations, customers } = getState();
    const user = employees.user;
    const location = locations.open;
    const RECIPIENT_EMAIL =
      ENV === 'PROD' ? 'support@officeluv.com' : HELP_EMAIL;
    const customer = customers.open;
    let body = {
      subject: `Category Spend Alert setup request from ${location.name}`,
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: RECIPIENT_EMAIL,
      body: `
            message:              Requesting Category Spend Alert setup
            requester:            ${user.first_name} ${user.last_name} (${user.email})
            customer:             ${location.name} - ${location.address} ${location.address2} ${location.city}, ${location.state} ${location.zip}
            additional_details:   Customer ID: ${customer.id} - Location ID: ${location.id} - Employee ID: ${user.id}
            `,
    };

    trackEmailACH(body);
    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Submit category spend alert setup request',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: 'Submitted category spend alert setup request.',
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
      });
  };
}

export function doRequestMoreCustomerRoles() {
  return (dispatch, getState) => {
    const { employees, locations, customers } = getState();
    const user = employees.user;
    const location = locations.open;
    const RECIPIENT_EMAIL =
      ENV === 'PROD' ? 'support@officeluv.com' : HELP_EMAIL;
    const customer = customers.open;
    let body = {
      subject: `Advanced Customer Roles setup request from ${location.name}`,
      replyTo: user.email,
      from: 'no-reply@officeluv.com',
      to: RECIPIENT_EMAIL,
      body: `
            message:              Advanced Customer Roles setup
            requester:            ${user.first_name} ${user.last_name} (${user.email})
            customer:             ${location.name} - ${location.address} ${location.address2} ${location.city}, ${location.state} ${location.zip}
            additional_details:   Customer ID: ${customer.id} - Location ID: ${location.id} - Employee ID: ${user.id}
            `,
    };

    trackEmailACH(body);
    return fetch(ADMIN_V1_URL + '/emails', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: body,
            message: 'Submit advanced customer roles setup request',
          });
        }
      })
      .then(() => {
        dispatch(
          enqueueConfirmation({
            message: 'Submitted advanced customer roles setup request.',
            type: 'success',
          })
        );
      })
      .catch((error) => {
        trackFetchError(error);
      });
  };
}
