import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Form, Message, Loader } from 'semantic-ui-react';
import TextWithAnchors from '../text/text-with-anchors.js';
import {
  trackInterfaceShowShippingInstructions,
  trackInterfaceShowShippingInputs,
} from '../../lib/analytics.js';
import { US_STATES_OPTIONS, COUNTRY_CODES } from '../../strings.js';
import {
  doValidateAddress,
  unsetAddressResponse,
  FILTER_IS_REQUESTING,
  FILTER_SHOW_ERROR,
} from '../../ducks/addresses.js';
import { requestID } from '../../actions/action-helpers.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';
import '../requisitions/checkout-form.css';

const DEBOUNCE_INTERVAL = 800;

export class ShippableForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestID: requestID(),
      inEditMode: props.isDisabled || props.editModeOverride,
      showInstructions: false,
      skipAddressValidation: false,
    };
  }

  componentDidMount() {
    // if (
    //   this.props.canSubmit &&
    //   this.props.shippable.shipping_address &&
    //   this.props.shippable.shipping_city &&
    //   this.props.shippable.shipping_state &&
    //   this.props.shippable.shipping_zip
    // ) {
    //   this.debounceValidateAddress();
    // }
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.shouldValidateAddress(prevProps)) {
      this.debounceValidateAddress();
    }
    if (this.props.addressValidationError && this.props.canSubmit) {
      this.setState({ inEditMode: true });
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetAddressResponse({
      _request: this.state.requestID,
    });

    if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout);
    this._isMounted = false;
  }

  shouldValidateAddress(prevProps) {
    // if (!this.props.canSubmit) return false;

    // if (this.props.shippable.shipping_country !== 'US') {
    //   return false;
    // }

    // if (
    //   !this.props.shippable.shipping_address ||
    //   !this.props.shippable.shipping_zip ||
    //   !this.props.shippable.shipping_city ||
    //   !this.props.shippable.shipping_state
    // ) {
    //   return false;
    // }
    // if (
    //   prevProps.shippable.shipping_address !==
    //   this.props.shippable.shipping_address
    // ) {
    //   return true;
    // }
    // if (
    //   prevProps.shippable.shipping_city !== this.props.shippable.shipping_city
    // ) {
    //   return true;
    // }
    // if (
    //   prevProps.shippable.shipping_state !== this.props.shippable.shipping_state
    // ) {
    //   return true;
    // }
    // if (
    //   prevProps.shippable.shipping_country !==
    //   this.props.shippable.shipping_country
    // ) {
    //   return true;
    // }
    // if (
    //   prevProps.shippable.shipping_zip !== this.props.shippable.shipping_zip
    // ) {
    //   return true;
    // }
    return false;
  }

  debounceValidateAddress = () => {
    if (this.debounceCheck) return;
    if (this.props.isRequesting) return;
    if (this.props.shippable.shipping_country !== 'US') return;

    this.debounceCheck = true;
    this.debounceTimeout = setTimeout(() => {
      this.debounceCheck = false;
      let payload = {
        address: this.props.shippable.shipping_address,
        city: this.props.shippable.shipping_city,
        state_code: this.props.shippable.shipping_state,
        postal_code: this.props.shippable.shipping_zip,
        _request: this.state.requestID,
      };
      this.props.actions
        .doValidateAddress(payload, { bubble: true })
        .then((body) => {
          if (!this._isMounted || !body) return;
          this.props.onValidAddress(payload);
          this.setState({ skipAddressValidation: false });
        });
    }, DEBOUNCE_INTERVAL);
  };

  _enableEditMode = () => {
    trackInterfaceShowShippingInputs();
    this.setState({ inEditMode: true, showInstructions: true });
  };

  _hasInstructions() {
    const { shippable, prevInstructions } = this.props;
    return !!shippable.instructions || !!prevInstructions;
  }

  _onChangeField = (field, e, { value }) => {
    this.props.onChangeField && this.props.onChangeField(field, value);
  };

  _openInstructions = () => {
    this.setState({ showInstructions: true });
    trackInterfaceShowShippingInstructions();
  };

  _applyAddressSuggestion = () => {
    if (!this.props.onChangeField) return;
    if (!this.props.addressValidationSuggestion) return;

    const suggestion = this.props.addressValidationSuggestion;

    this.props.onChangeField('shipping_address', suggestion.address);
    this.props.onChangeField('shipping_city', suggestion.city);
    this.props.onChangeField('shipping_state', suggestion.state_code);
    this.props.onChangeField('shipping_zip', suggestion.postal_code);
    this.debounceValidateAddress();
  };

  _skipAddressValidation = () => {
    this.setState({ skipAddressValidation: true });
  };

  renderAddressLine(line, lineBreak = true) {
    if (!line) return null;
    if (!line.trim().length) return null;

    return (
      <span>
        {line}
        {lineBreak && <br />}
      </span>
    );
  }

  renderInfo() {
    const { shippable } = this.props;
    return (
      <div className="checkout-details-info">
        <div>
          <p className="shipping">
            {this.renderAddressLine(shippable.shipping_name)}
            {this.renderAddressLine(shippable.shipping_business)}
            {this.renderAddressLine(shippable.shipping_care)}
            {this.renderAddressLine(
              `${shippable.shipping_address}, ${
                shippable.shipping_address_number || ''
              }`
            )}
            {this.renderAddressLine(
              `${shippable.shipping_city}, ${shippable.shipping_state}, ${shippable.shipping_country} ${shippable.shipping_zip}`,
              false
            )}
            {this.props.canSubmit && (
              <Button
                className="tertiary edit-shippable"
                onClick={this._enableEditMode}>
                Edit
              </Button>
            )}
          </p>
          {this.props.canSubmit && (
            <div className="checkout-details-info-delivery">
              <Form.TextArea
                className="checkout-form-input-text5 resize--vertical"
                label="Instructions/Comments"
                name="instructions"
                onChange={this._onChangeField.bind(this, 'instructions')}
                value={shippable.instructions}
              />
              {this._hasInstructions() && !shippable.instructions && (
                <Button
                  className="tertiary"
                  onClick={this.props.populateDelivery}>
                  Use instructions from last order
                </Button>
              )}
            </div>
          )}
          {!this.props.canSubmit && !!shippable.instructions && (
            <div className="checkout-details-info-delivery">
              <p>
                Instructions/Comments:{' '}
                <TextWithAnchors text={shippable.instructions} />
              </p>
            </div>
          )}
        </div>
        {this.props.children}
        {this.props.canSubmit && (
          <div className="checkout-details-info-submit">
            <Button
              className="checkout-form-submit hvr-buzz-out"
              primary
              loading={this.props.isLoading}
              disabled={this.props.isDisabled}
              onClick={this.props.onSubmit}>
              {this.props.submitButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderForm() {
    const { shippable } = this.props;
    return (
      <Form
        className="shippable-form"
        loading={this.props.isRequesting}
        onSubmit={this.props.onSubmit}>
        <Form.Input
          fluid
          required
          onChange={this._onChangeField.bind(this, 'shipping_name')}
          value={shippable.shipping_name || undefined}
          name="name"
          label="Name"
          placeholder="Name"
        />
        <Form.Group>
          <Form.Input
            width={10}
            onChange={this._onChangeField.bind(this, 'shipping_business')}
            value={shippable.shipping_business || undefined}
            name="shipping_business"
            label="Company Name"
            placeholder="Company Name"
          />
          <Form.Input
            width={6}
            onChange={this._onChangeField.bind(this, 'shipping_care')}
            value={shippable.shipping_care || undefined}
            name="shipping_care"
            label="C/O"
            placeholder="Care of:"
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width={12}
            required
            onChange={this._onChangeField.bind(this, 'shipping_address')}
            value={shippable.shipping_address || undefined}
            name="shipping_address"
            label="Address Line 1"
            placeholder="Street address"
          />
          <Form.Input
            width={4}
            name="shipping_address_number"
            label="Line 2"
            placeholder="Suite/apt"
            onChange={this._onChangeField.bind(this, 'shipping_address_number')}
            value={shippable.shipping_address_number || undefined}
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            search
            width={6}
            required
            name="shipping_country"
            label="Country"
            placeholder="Country"
            options={COUNTRY_CODES}
            onChange={this._onChangeField.bind(this, 'shipping_country')}
            value={shippable.shipping_country || undefined}
          />
          <Form.Input
            width={10}
            required
            name="shipping_city"
            label="City/Town"
            placeholder="Your city/town"
            onChange={this._onChangeField.bind(this, 'shipping_city')}
            value={shippable.shipping_city || undefined}
          />
        </Form.Group>
        <Form.Group>
          {shippable.shipping_country === 'US' ? (
            <Form.Select
              required
              search
              width={10}
              name="shipping_state"
              label="State"
              placeholder="State"
              options={US_STATES_OPTIONS}
              onChange={this._onChangeField.bind(this, 'shipping_state')}
              value={shippable.shipping_state}
            />
          ) : (
            <Form.Input
              width={10}
              required
              name="shipping_state"
              label="Region"
              placeholder="Region"
              onChange={this._onChangeField.bind(this, 'shipping_state')}
              value={shippable.shipping_state || undefined}
            />
          )}
          <Form.Input
            width={6}
            required
            name="shipping_zip"
            label="Postal code"
            placeholder="Postal code"
            onChange={this._onChangeField.bind(this, 'shipping_zip')}
            value={shippable.shipping_zip || undefined}
          />
        </Form.Group>
        {this.props.isValidatingAddress && (
          <Loader size="mini" inline="centered" style={{ display: 'block' }}>
            Validating address
          </Loader>
        )}
        {!this.props.isValidatingAddress &&
          this.props.addressValidationError &&
          this.props.addressValidationSuggestion &&
          this.props.shippable.shipping_country === 'US' &&
          !this.state.skipAddressValidation && (
            <Message negative>
              <Message.Header
                style={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                Address Invalid
              </Message.Header>
              <Message.Content style={{ marginBottom: '0.5rem' }}>
                Did you mean:
              </Message.Content>
              <Message.Content style={{ marginBottom: '0.5rem' }}>
                {this.props.addressValidationSuggestion.address}{' '}
                {this.props.addressValidationSuggestion.city},{' '}
                {this.props.addressValidationSuggestion.state}{' '}
                {this.props.addressValidationSuggestion.postal_code}
              </Message.Content>
              <Message.Content>
                <Button
                  primary
                  type="button"
                  size="mini"
                  onClick={this._applyAddressSuggestion}>
                  Use This Address
                </Button>
                <Button
                  secondary
                  className="white-background"
                  style={{ marginTop: '0.5rem' }}
                  type="button"
                  size="mini"
                  onClick={this._skipAddressValidation}>
                  Use My Address
                </Button>
              </Message.Content>
            </Message>
          )}
        {!this.props.isValidatingAddress &&
          this.props.addressValidationError &&
          this.props.shippable.shipping_country === 'US' &&
          !this.props.addressValidationSuggestion &&
          !this.state.skipAddressValidation && (
            <Message negative>
              <Message.Content>
                Address not found. Please enter a different address.
              </Message.Content>
            </Message>
          )}
        <div className="checkout-form-delivery-instructions">
          {!this.state.showInstructions && (
            <Button className="tertiary" onClick={this._openInstructions}>
              Add specific delivery instructions
            </Button>
          )}
          {this.state.showInstructions && (
            <Form.Group width="16">
              <Form.TextArea
                width="16"
                className="checkout-form-input-text5"
                label="Delivery Instructions"
                name="instructions"
                onChange={this._onChangeField.bind(this, 'instructions')}
                value={shippable.instructions}
                placeholder="Special Delivery Instructions..."
              />
            </Form.Group>
          )}
        </div>
        {this.props.children}
        {this.props.isAlcoholAware && (
          <div className="field">
            <div className="ui checkbox">
              <input
                type="checkbox"
                className="hidden"
                name="ageConfirmationCheckbox"
                id="ageConfirmationCheckbox"
                tabIndex="0"
                onChange={this.props.onAgeConfirmation}
                checked={shippable.age_confirmation}
              />
              <label
                htmlFor="ageConfirmationCheckbox"
                style={{
                  fontSize: '.875rem',
                }}>
                I confirm that I am 21 years of age or older and agree to&nbsp;
                <a
                  href="//www.officeluv.com/terms-of-service#alcohol"
                  rel="noopener noreferrer"
                  target="_blank">
                  OfficeLuv's Terms of Service
                </a>
                .
              </label>
            </div>
          </div>
        )}
        {this.props.canSubmit && (
          <Form.Group
            style={{
              marginTop: '2rem',
            }}>
            <Form.Button
              width={16}
              fluid
              primary
              disabled={
                this.props.isDisabled ||
                this.props.isValidatingAddress ||
                (!this.state.skipAddressValidation &&
                  !!this.props.addressValidationError &&
                  this.props.shippable.shipping_country === 'US')
              }
              loading={this.props.isLoading}>
              {this.props.submitButtonText}
            </Form.Button>
          </Form.Group>
        )}
      </Form>
    );
  }

  render() {
    if (!this.props.shippable) return null;
    return (
      <div>
        <div className="checkout-form-subheader">
          <h4>Deliver to</h4>
        </div>
        {!this.state.inEditMode && this.renderInfo()}
        {this.state.inEditMode && this.renderForm()}
      </div>
    );
  }
}

ShippableForm.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  isAlcoholAware: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  prevInstructions: PropTypes.string,
  onAgeConfirmation: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  populateDelivery: PropTypes.func.isRequired,
  onValidAddress: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shippable: PropTypes.shape({
    age_confirmation: PropTypes.bool,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_zip: PropTypes.string,
    instructions: PropTypes.string,
  }),
  editModeOverride: PropTypes.bool.isRequired,
  addressValidationError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  actions: PropTypes.shape({
    doValidateAddress: PropTypes.func.isRequired,
    unsetAddressResponse: PropTypes.func.isRequired,
  }).isRequired,
  isValidatingAddress: PropTypes.bool.isRequired,
  addressValidationSuggestion: PropTypes.oneOfType([
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state_code: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
};

ShippableForm.defaultProps = {
  canSubmit: true,
  isAlcoholAware: false,
  isDisabled: false,
  isRequesting: false,
  submitButtonText: 'Submit',
  isLoading: false,
  onAgeConfirmation: () => {},
  onChangeField: () => {},
  onSubmit: () => {},
  populateDelivery: () => {},
  onValidAddress: () => {},
  editModeOverride: false,
};

function mapStateToProps(state) {
  return {
    isValidatingAddress:
      state.addresses.requesting.filter(FILTER_IS_REQUESTING).length > 0,
    addressValidationError:
      state.addresses.responses.filter(FILTER_SHOW_ERROR).length > 0 &&
      state.addresses.responses.filter(FILTER_SHOW_ERROR)[0].data.error.error,
    addressValidationSuggestion:
      state.addresses.responses.filter(FILTER_SHOW_ERROR).length > 0 &&
      state.addresses.responses.filter(FILTER_SHOW_ERROR)[0].data.error.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doValidateAddress,
        unsetAddressResponse,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.addresses.requesting === next.addresses.requesting &&
    prev.addresses.responses === next.addresses.responses
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ShippableForm);
