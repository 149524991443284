import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import ItemActions from './../purchaseOrders/item-actions.js';
import { formatDeliveryWindow } from '../../helpers/shipment-helpers.js';
import 'semantic-ui-css/components/table.min.css';
import './../purchaseOrders/item.css';
import './todays-orders-section-capsule.css';

export default class TodaysOrdersSectionCapsule extends React.PureComponent {
  renderTrackingText = () => {
    return this.props.item.tracking_code;
  };

  render() {
    return (
      <div className="order-capsule purchase-order-item">
        <div className="purchase-order-info">
          <Table basic="very" compact singleLine unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={7}>Expected</Table.HeaderCell>
                <Table.HeaderCell width={3}>Via</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {this.props.item.expected_at_end &&
                    formatDeliveryWindow(this.props.item)}
                </Table.Cell>
                <Table.Cell>{this.props.item.carrier}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <ItemActions item={this.props.item} />
      </div>
    );
  }
}

TodaysOrdersSectionCapsule.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string.isRequired,
    tracking_code: PropTypes.string.isRequired,
    tracking_url: PropTypes.string,
  }).isRequired,
  onPackingSlipClick: PropTypes.func.isRequired,
  onMarkReceivedClick: PropTypes.func.isRequired,
};
