import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Dropdown, Input, Menu, Button, Icon } from 'semantic-ui-react';
import {
  openLocationListCreateModal,
  openLocationListEditModal,
  openLocationList,
} from '../../actions/location-list-actions.js';
import browserHistory from '../../lib/history.js';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/menu.min.css';

export class SupplyCategoryMenu extends React.PureComponent {
  isSearching = () => !!this.props.searchValue;

  handleListEditClick = () => {
    this.props.actions.openLocationListEditModal(this.props.openLocationList);
  };

  handleListMenuClick = (e, { id }) => {
    if (!id) return this.props.actions.openLocationListCreateModal();
    this.props.actions.openLocationList({ id });
    this.props.onListMenuClick();
  };

  handleClickAssign = () => {
    browserHistory.push(
      `/supplies/levels/${this.props.openLocationList.id}/budget-codes`
    );
  };

  isMultiLocationListAccess = () => {
    return this.props.openLocation.pref_enable_multiple_location_lists;
  };

  render() {
    let dropdownCategories = this.props.parentCategories.map((category) => {
      return (
        <Dropdown.Item
          id={category.id}
          key={category.id}
          content={`${category.name} (${
            this.props.sortedProducts[category.id].length
          })`}
          onClick={this.props.onCategoryMenuClick}
        />
      );
    });
    if (
      this.props.budgetCodesAccess &&
      this.props.role.can_manage_location_lists
    ) {
      dropdownCategories = dropdownCategories.concat(
        <Dropdown.Item
          id={0}
          icon="edit"
          key={0}
          content="Assign Categories"
          onClick={this.handleClickAssign}
        />
      );
    }

    let nullPriorityLists = this.props.locationLists.filter(
      (l) => l.priority === null
    );

    let priorityLists = this.props.locationLists.filter(
      (l) => l.priority !== null
    );

    let sortedLists = priorityLists
      .sort((a, b) => a.priority - b.priority)
      .concat(nullPriorityLists);

    let dropdownLists = sortedLists.map((l) => {
      return (
        <Dropdown.Item
          id={l.id}
          key={l.id}
          onClick={this.handleListMenuClick}
          content={l.name}
        />
      );
    });
    if (this.props.role.can_manage_location_lists) {
      dropdownLists = dropdownLists.concat(
        <Dropdown.Item
          id={0}
          key={0}
          icon="plus"
          onClick={this.handleListMenuClick}
          content={'Add New List'}
        />
      );
    }
    return (
      <Menu fluid stackable>
        {this.isMultiLocationListAccess() ? (
          <Dropdown
            item
            text={this.props.openLocationList.name}
            disabled={this.isSearching() || this.props.editMode}
            simple>
            <Dropdown.Menu>
              {this.props.role.can_manage_location_lists && (
                <Fragment>
                  <Dropdown.Item
                    id={-1}
                    icon="edit"
                    text="Edit List"
                    onClick={this.handleListEditClick}
                  />
                  <Dropdown.Divider />
                </Fragment>
              )}
              {dropdownLists}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Menu.Item>{this.props.openLocationList.name}</Menu.Item>
        )}
        <Menu.Menu position="right">
          <Dropdown
            item
            text={this.props.openCategory.name}
            disabled={
              this.isSearching() || this.props.editMode || this.props.isDisabled
            }
            simple>
            <Dropdown.Menu>
              <Dropdown.Header icon="filter" content="Filter by Category" />
              {dropdownCategories}
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item key="search-menu" style={{ flexShrink: '1' }}>
            <Input
              icon="search"
              onChange={this.props.onSearchChange}
              value={this.props.searchValue}
              disabled={this.props.editMode || this.props.isDisabled}
              placeholder="Search List..."
            />
          </Menu.Item>
          {!this.props.isDisabled &&
            this.props.role.can_manage_location_lists && (
              <Menu.Item
                key="edit-mode"
                position="right"
                style={{ paddingLeft: '0', paddingRight: '0.5rem' }}>
                <Button
                  onClick={this.props.onEditModeChange}
                  className={classnames('edit-mode tertiary', {
                    active: this.props.editMode,
                    'brand-bg-color-override': this.props.editMode,
                  })}>
                  <Icon name="pencil" size="large" />
                </Button>
              </Menu.Item>
            )}
        </Menu.Menu>
      </Menu>
    );
  }
}

SupplyCategoryMenu.propTypes = {
  actions: PropTypes.shape({
    openLocationListCreateModal: PropTypes.func.isRequired,
    openLocationListEditModal: PropTypes.func.isRequired,
    openLocationList: PropTypes.func.isRequired,
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  onEditModeChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  openCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  openLocation: PropTypes.shape({
    pref_enable_multiple_location_lists: PropTypes.bool,
  }).isRequired,
  onListMenuClick: PropTypes.func.isRequired,
  onCategoryMenuClick: PropTypes.func.isRequired,
  parentCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  sortedProducts: PropTypes.object.isRequired,
  openLocationList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  locationLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  role: PropTypes.shape({
    can_manage_location_lists: PropTypes.bool.isRequired,
  }),
};

SupplyCategoryMenu.defaultProps = {
  isDisabled: false,
};

export function mapStateToProps(state) {
  return {
    openLocation: state.locations.open,
    openLocationList: state.locationLists.open,
    locationLists: state.locationLists.items,
    role: state.auth.role,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openLocationListCreateModal,
        openLocationListEditModal,
        openLocationList,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.locationLists.open === next.locationLists.open &&
    prev.locationLists.items === next.locationLists.items &&
    prev.locations.open === next.locations.open &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SupplyCategoryMenu);
