import application from './application-reducer.js';
import auth from './auth-reducer.js';
import cleanings from './cleaning-reducer.js';
import confirmations from './confirmations-reducer.js';
import customerBudgetCodes from './customer-budget-codes-reducer.js';
import customers from './customer-reducer.js';
import dataViews from './data-view-reducer.js';
import employees from './employee-reducer.js';
import invoices from './invoices-reducer.js';
import locationLists from './location-list-reducer.js';
import locationListProducts from './location-list-product-reducer.js';
import locationParentProductCategories from './location-parent-product-category-reducer';
import locations from './location-reducer.js';
import onboarding from './onboard-reducer.js';
import products from './product-reducer.js';
import productCategories from './product-category-reducer.js';
import requisitions from './requisition-reducer.js';
import scheduledRequisitions from './scheduled-requisition-reducer.js';
import staffs from './staff-reducer.js';
import tasks from './task-reducer.js';
import productBrands from './product-brand-reducer.js';
import productQuery from './product-query-reducer.js';
import promotedBrands from './promoted-brand-reducer.js';
import locationCatalogProducts from './location-catalog-product-reducer.js';
import externalVendorInvoices from './external-vendor-invoices-reducer.js';
import credits from './credits-reducer.js';
import calendarView from './calendar-view-reducer.js';
import employeeStipends from './employee-stipend-reducer.js';
import customerRoles from '../ducks/customer-roles.js';
import carts from '../ducks/carts.js';
import addresses from '../ducks/addresses.js';
import featureTourAttempts from '../ducks/feature-tour-attempts.js';
import featureTours from '../ducks/feature-tours';
import punchoutSessions from '../ducks/punchout-sessions.js';
import promoCodes from '../ducks/promo-codes.js';
import shipments from '../ducks/shipments.js';
import requisitionQuery from '../ducks/requisition-query.js';
import productStockNotifications from '../ducks/product-stock-notifications.js';
import bannerItems from '../ducks/banner-items.js';
import stipends from '../ducks/stipends.js';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  addresses,
  application,
  auth,
  bannerItems,
  calendarView,
  carts,
  cleanings,
  confirmations,
  credits,
  customerBudgetCodes,
  customerRoles,
  customers,
  dataViews,
  employees,
  externalVendorInvoices,
  featureTourAttempts,
  featureTours,
  invoices,
  locationCatalogProducts,
  locationLists,
  locationListProducts,
  locationParentProductCategories,
  locations,
  onboarding,
  productBrands,
  productCategories,
  products,
  productStockNotifications,
  productQuery,
  promoCodes,
  promotedBrands,
  punchoutSessions,
  requisitions,
  requisitionQuery,
  scheduledRequisitions,
  shipments,
  staffs,
  tasks,
  stipends,
  employeeStipends,
});

export default rootReducer;
