import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayName } from '../helpers/employee-helpers.js';
import { formatDate } from '../lib/formatters.js';
import {
  openEmployee,
  closeEmployee,
  doGetEmployee,
} from '../actions/employee-actions.js';
import { Icon, Segment, Header, List } from 'semantic-ui-react';
import ModalCustomerRolesDesc from '../components/modals/modal-customer-roles-desc.js';
import Avatar from '../components/avatar.js';
import AuthCheck from '../components/auth-check.js';

export class EmployeeShow extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!props.match.params.id) return;

    props.actions.openEmployee({
      id: parseInt(props.match.params.id, 10),
    });
    props.actions.doGetEmployee({
      id: parseInt(props.match.params.id, 10),
    });
  }

  componentWillUnmount() {
    this.props.actions.closeEmployee();
  }

  render() {
    if (!this.props.employee.id) return null;
    const employee = this.props.employee;
    const viewableLocationIds = this.props.currentUser.locations.map(
      (location) => location.id
    );
    const employeeViewableLocations = (employee.locations || []).filter(
      (location) => viewableLocationIds.indexOf(location.id) > -1
    );

    return (
      <Segment
        basic
        padded="very"
        loading={this.props.isLoading}
        style={{ padding: '2em 3em' }}
      >
        <AuthCheck />
        <Avatar
          item={employee}
          size="medium"
          width={100}
          height={100}
          alt={employee.email}
        />
        <Header as="h1">{displayName(employee)}</Header>
        <List relaxed>
          {employee.customer_role && (
            <List.Item>
              <Icon name="user" />
              <List.Content>
                <List.Header>Role</List.Header>
                {employee.customer_role.name}
                <div style={{ display: 'inline-block', padding: '0 0 0 1rem' }}>
                  <ModalCustomerRolesDesc />
                </div>
              </List.Content>
            </List.Item>
          )}
          {employee.email && (
            <List.Item>
              <Icon name="mail" />
              <List.Content>
                <List.Header>Email</List.Header>
                {employee.email}
              </List.Content>
            </List.Item>
          )}
          {employee.title && (
            <List.Item>
              <Icon name="address card outline" />
              <List.Content>
                <List.Header>Job Title</List.Header>
                {employee.title}
              </List.Content>
            </List.Item>
          )}
          {employee.invitation_sent_at && !employee.invitation_accepted_at && (
            <List.Item>
              <Icon name="arrow right" />
              <List.Content>
                <List.Header>Invited</List.Header>
                {formatDate(employee.invitation_sent_at)}
              </List.Content>
            </List.Item>
          )}
          {employee.invitation_accepted_at && (
            <List.Item>
              <Icon name="arrow right" />
              <List.Content>
                <List.Header>Joined</List.Header>
                {formatDate(employee.invitation_accepted_at)}
              </List.Content>
            </List.Item>
          )}
        </List>
        <Header as="h4">Offices</Header>
        <List>
          {employeeViewableLocations.map((loc) => (
            <List.Item key={loc.id}>
              <List.Icon name="marker" />
              <List.Content>{loc.name}</List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>
    );
  }
}

EmployeeShow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
};

export function mapStateToProps(state, props) {
  return {
    isLoading:
      state.employees.items.filter(
        (e) => e.id === parseInt(props.match.params.id, 10)
      ).length === 0,
    employee: state.employees.open,
    currentUser: state.employees.user,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openEmployee,
        closeEmployee,
        doGetEmployee,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.employees.items === next.employees.items &&
    prev.employees.open === next.employees.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EmployeeShow);
