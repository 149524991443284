import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = (props) => (
  <label>
    {props.text !== undefined ? props.text : 'Input'}
    {props.required &&
      (props.inputValue || '').toString().trim().length === 0 && (
        <span className="required">&nbsp;&nbsp;*&nbsp;required</span>
      )}
    {props.children}
  </label>
);

InputLabel.propTypes = {
  text: PropTypes.string,
  required: PropTypes.bool,
  inputValue: PropTypes.any,
};

InputLabel.defaultProps = {
  required: false,
  inputValue: '',
};

export default InputLabel;
