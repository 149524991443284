import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doResetPassword } from '../actions/auth-actions.js';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import NavbarSignIn from './nav/navbar-sign-in.js';
import { PASSWORD_LENGTH } from './../strings.js';
import { trackUpdatePassword } from '../lib/analytics.js';
import './resetpassword.css';

export class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangePasswordOriginal = this.onChangePasswordOriginal.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.state = {
      passwordOriginal: '',
      passwordConfirm: '',
      passwordLength: null,
      passwordText: `New Password (minimum ${PASSWORD_LENGTH} characters)`,
    };
  }

  onChangePasswordOriginal(e) {
    let passwordOriginal = e.target.value;
    this.setState(
      Object.assign({}, this.state, {
        passwordOriginal,
        passwordLength: passwordOriginal.length,
        passwordText:
          this.passwordLengthGoal(passwordOriginal) > 0
            ? 'New Password (' +
              this.passwordLengthGoal(passwordOriginal) +
              ' more character' +
              (this.passwordLengthGoal(passwordOriginal) === 1
                ? ' is needed)'
                : 's are needed)')
            : 'New Password',
      })
    );
  }

  passwordLengthGoal = (password) => {
    return PASSWORD_LENGTH - password.length;
  };

  onChangePasswordConfirm(e) {
    this.setState(
      Object.assign({}, this.state, { passwordConfirm: e.target.value })
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.props.actions.doResetPassword({
      password: this.state.passwordOriginal,
    });
    trackUpdatePassword(this.state);
  }

  isDisabled() {
    return (
      this.props.auth.isRequesting ||
      !this.isSamePassword() ||
      this.state.passwordOriginal.length < PASSWORD_LENGTH ||
      !this.state.passwordOriginal ||
      !this.state.passwordConfirm
    );
  }

  isSamePassword = () => {
    return this.state.passwordOriginal === this.state.passwordConfirm;
  };

  render() {
    return (
      <div className="forgot-password">
        <NavbarSignIn />
        <div className="well-container hero-image">
          <div
            style={{
              maxWidth: '22rem',
              margin: '0 auto',
            }}
          >
            <Header attached="top" as="h2">
              Reset Password
            </Header>
            <Segment attached="bottom" loading={this.props.auth.isRequesting}>
              <Form
                error={
                  !this.props.auth.isRequesting &&
                  this.props.auth.errorResetPassword
                }
                onSubmit={this.handleFormSubmit}
              >
                <Form.Field>
                  <label>{this.state.passwordText}</label>
                  <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    ref="password"
                    value={this.state.passwordOriginal}
                    onChange={this.onChangePasswordOriginal}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Confirm New Password</label>
                  <input
                    placeholder="Password"
                    type="password"
                    name="password_confirm"
                    ref="password_confirm"
                    value={this.state.passwordConfirm}
                    onChange={this.onChangePasswordConfirm}
                  />
                </Form.Field>
                <Button
                  primary
                  type="submit"
                  disabled={this.isDisabled()}
                  style={{ minWidth: '50%' }}
                >
                  Set New Password
                </Button>
                <Message
                  error
                  content={(this.props.auth.errorResetPassword || {}).message}
                />
              </Form>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ doResetPassword }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
