import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippableForm from '../modules/shippable-form.js';
import PurchasableForm from '../modules/purchasable-form.js';
import { CONTAINS_ALCOHOL } from '../../flags.js';
import { submitRequisitionEdits } from '../../actions/requisition-actions.js';
import '../scheduledRequisitions/recurring-requisition-form.css';

export class EditRequisitionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.constructState(props);
  }

  constructState(props) {
    const req = props.requisition;
    return {
      id: req.id,
      shipping_name:
        req.shipping_name ||
        props.user.first_name + ' ' + props.user.last_name ||
        '',
      shipping_address: req.shipping_address || '',
      shipping_address_number: req.shipping_address_number || '',
      shipping_city: req.shipping_city || '',
      shipping_state: req.shipping_state || '',
      shipping_country: req.shipping_country || '',
      shipping_zip: req.shipping_zip || '',
      shipping_business: req.shipping_business || '',
      shipping_care: req.shipping_care || '',
      instructions: req.instructions || '',
      age_confirmation: req.age_confirmation || false,
      total_price: (req.product_requisitions || []).reduce(
        this.reduceTotalPrice,
        0
      ),
    };
  }

  reduceTotalPrice = (acc, val) => {
    return acc + val.price * val.quantity;
  };

  onChangeField = (field, value) => {
    let update = {};
    update[field] = value;
    this.setState(update);
  };

  isDisabled = () => {
    if (!this.props.requisition._optimized_product_requisitions) return true;
    return (
      !this.props.requisition.product_requisitions ||
      !this.props.requisition.product_requisitions.length ||
      !this.state.shipping_name ||
      !this.state.shipping_address ||
      !this.state.shipping_city ||
      !this.state.shipping_state ||
      !this.state.shipping_zip ||
      !this.state.shipping_name.trim().length ||
      !this.state.shipping_address.trim().length ||
      !this.state.shipping_city.trim().length ||
      !this.state.shipping_state.trim().length ||
      !this.state.shipping_zip.trim().length ||
      (this.isAlcoholCart() && !this.state.age_confirmation) ||
      this.props.requisition._optimized_product_requisitions.unmet_minimums
        .length > 0
    );
  };

  isAlcoholCart = () => {
    if (!this.props.requisition.product_requisitions) return false;
    return CONTAINS_ALCOHOL(this.props.requisition.product_requisitions);
  };

  handleClickAgeConfirmation = () => {
    this.setState((prev) => {
      return {
        age_confirmation: !prev.age_confirmation,
      };
    });
  };

  handleEditOrder = () => {
    const data = {
      id: this.props.requisition.id,
      submitted_at: new Date(),
      shipping_name: this.state.shipping_name,
      shipping_business: this.state.shipping_business || undefined,
      shipping_care: this.state.shipping_care || undefined,
      shipping_address: this.state.shipping_address,
      shipping_address_number: this.state.shipping_address_number || undefined,
      shipping_city: this.state.shipping_city,
      shipping_state: this.state.shipping_state,
      shipping_country: this.state.shipping_country,
      shipping_zip: this.state.shipping_zip,
      instructions: this.state.instructions || undefined,
      age_confirmation: this.state.age_confirmation,
    };
    this.props.actions.submitRequisitionEdits(data);
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.handleEditOrder();
  };

  renderButtonText() {
    return 'Update Order';
  }

  populateDeliveryField = () => {
    const { cart } = this.props;
    this.setState({
      instructions: cart.instructions,
    });
  };

  render() {
    return (
      <ShippableForm
        isDisabled={this.isDisabled()}
        onAgeConfirmation={this.handleClickAgeConfirmation}
        onChangeField={this.onChangeField}
        onSubmit={this.handleFormSubmit}
        populateDelivery={this.populateDeliveryField}
        shippable={this.state}
        submitButtonText={this.renderButtonText()}
        isAlcoholAware={this.isAlcoholCart()}
        isRequesting={this.props.isRequesting}>
        <PurchasableForm
          className="checkout-form-footer"
          requiresPayment={this.props.requisition.requester_purchase_price > 0}
        />
      </ShippableForm>
    );
  }
}

EditRequisitionForm.propTypes = {
  requisition: PropTypes.shape({
    age_confirmation: PropTypes.bool,
    awaiting_approval_at: PropTypes.string,
    id: PropTypes.number,
    instructions: PropTypes.string,
    requester_id: PropTypes.number,
    shipping_name: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_country: PropTypes.string,
    shipping_zip: PropTypes.string,
    status: PropTypes.string,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  openRequisition: PropTypes.shape({
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
      })
    ),
  }),
  orderMinimum: PropTypes.number.isRequired,
  shippingPrice: PropTypes.number.isRequired,
  alcoholConvenienceFee: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    submitRequisitionEdits: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  userType: PropTypes.oneOf(['employee', 'staff']).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    orderMinimum: state.application.appConfig.orderMinimum,
    shippingPrice: state.application.appConfig.shippingPrice,
    alcoholConvenienceFee: state.application.appConfig.alcoholConvenienceFee,
    userType: state.employees.user.id !== 0 ? 'employee' : 'staff',
    user: state.employees.user,
    openRequisition: state.requisitions.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitRequisitionEdits,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.application.appConfig.orderMinimum ===
      next.application.appConfig.orderMinimum &&
    prev.application.appConfig.shippingPrice ===
      next.application.appConfig.shippingPrice &&
    prev.application.appConfig.alcoholConvenienceFee ===
      next.application.appConfig.alcoholConvenienceFee &&
    prev.employees.user === next.employees.user &&
    prev.requisitions.open === next.requisitions.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EditRequisitionForm);
