import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import SignInForm from '../components/sign-in.js';
import setTitle from '../decorators/set-title.js';
import { trackSignInSuccess } from '../lib/analytics.js';

export class SignInRoute extends React.Component {
  constructor(props) {
    super(props);
    if (!props.auth.hmac) {
      return;
    }
    browserHistory.push('/');
  }

  componentDidUpdate() {
    if (!this.props.auth.hmac) {
      return;
    }
    let path = window.location.search.match(/path=(.[^&]+)/);
    trackSignInSuccess(this.props.auth);
    browserHistory.push((path && decodeURIComponent(path[1])) || '/');
  }

  render() {
    return <SignInForm />;
  }
}

SignInRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

SignInRoute = setTitle((props) => {
  return !props.auth.hmac ? 'Sign In' : null;
})(SignInRoute);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInRoute);
