import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import { openCleaningDetails } from '../../actions/cleaning-actions.js';
import { formatTime, formatDateTime } from '../../lib/formatters.js';
import { FILTER_CLEANINGS_IN_DAY_OF } from '../../strings.js';
import './details-list.css';

export class CleaningDetailsList extends React.PureComponent {
  _onClickItem = (item) => {
    this.props.actions.openCleaningDetails(item);
  };

  renderItem = (item) => {
    return (
      <div
        className={classnames('cleaning-details-list-item', {
          active: item.id === this.props.open.id,
        })}
        key={item.id}
      >
        <Button
          onClick={this._onClickItem.bind(this, item)}
          title={item.title || item.start_datetime}
          secondary
          className={classnames('', {
            active: item.id === this.props.open.id,
          })}
        >
          <span title={formatDateTime(item.start_datetime)}>
            {formatTime(item.start_datetime)}
          </span>
          &ndash;
          <span title={formatDateTime(item.end_datetime)}>
            {formatTime(item.end_datetime)}
          </span>
        </Button>
      </div>
    );
  };

  render() {
    if (this.props.cleanings.length < 2) return null;
    return (
      <div className="cleaning-details-list">
        <div className="variable-width-column">
          {this.props.cleanings.map(this.renderItem)}
        </div>
      </div>
    );
  }
}

CleaningDetailsList.propTypes = {
  actions: PropTypes.shape({
    openCleaningDetails: PropTypes.func.isRequired,
  }).isRequired,
  open: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  cleanings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_datetime: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  return {
    open: state.cleanings.open,
    cleanings: state.cleanings.items
      .filter(
        FILTER_CLEANINGS_IN_DAY_OF(
          state.cleanings.open.start_datetime || Date.now()
        )
      )
      .sort((a, b) => (a.start_datetime > b.start_datetime ? 1 : -1)),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningDetails,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CleaningDetailsList);
