import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from '../../lib/formatters.js';
import { List } from 'semantic-ui-react';
import { nextSourceOccurrences } from '../../helpers/scheduled-requisition-helpers.js';
import { GET_SCHEDULED_REQUISITIONS } from '../../actions/action-types.js';

const renderItem = (item) => (
  <List.Item>
    <List.Header>{item.name}</List.Header>
    <List.Description>
      <p>
        Next order:{' '}
        <Link to={`/orders/scheduled/${item.id}`}>
          {formatDate(item.expected_at)}
        </Link>
      </p>
      {item.end_at && <p>Ends on: {formatDate(item.end_at)}</p>}
    </List.Description>
  </List.Item>
);

const NextOccurrenceList = (props) => {
  if (props.isRequesting) return null;

  return <List relaxed>{props.nextSourceOccurrences.map(renderItem)}</List>;
};

NextOccurrenceList.propTypes = {
  nextSourceOccurrences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      location_id: PropTypes.number.isRequired,
      approve_automatically: PropTypes.bool.isRequired,
      instructions: PropTypes.string,
      shipping_address: PropTypes.string,
      shipping_address_number: PropTypes.string,
      shipping_business: PropTypes.string,
      shipping_care: PropTypes.string,
      shipping_city: PropTypes.string,
      shipping_name: PropTypes.string,
      shipping_state: PropTypes.string,
      shipping_zip: PropTypes.string,
      start_at: PropTypes.string,
      status: PropTypes.string,
      rrule: PropTypes.string,
    })
  ).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    nextSourceOccurrences: nextSourceOccurrences(
      state.scheduledRequisitions.items
    ),
    isRequesting:
      state.scheduledRequisitions.requesting.filter(
        (request) => request._request === GET_SCHEDULED_REQUISITIONS
      ).length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.requesting ===
      next.scheduledRequisitions.requesting &&
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  NextOccurrenceList
);
