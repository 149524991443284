import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import IconShipmentDollar from '../icons/icon-shipment-dollar.js';
import IconShipment from '../icons/icon-shipment.js';
import {
  hasNextDayShipping,
  hasMinimum,
  vendorMinimumType,
  formatVendorMinimum,
} from '../../helpers/vendor-helpers.js';
import { colorHash } from '../../lib/formatters.js';
import './vendor-minimum-icon.css';

const getText = (minType) => {
  switch (minType) {
    case 'quantity_minimum': {
      return 'Bulk';
    }
    case 'price_minimum': {
      return 'Minimum';
    }
    default:
      return '';
  }
};

export const VendorMinimumIcon = (props) => {
  const minType = vendorMinimumType(props.vendor);

  if (hasMinimum(props.vendor) && props.detailView) {
    return (
      <div
        className={classnames('product-shipping', {
          alt: minType === 'quantity_minimum',
        })}
        title="order minimum requirement"
      >
        <div className="big-box-icon">
          {minType === 'price_minimum' && (
            <IconShipmentDollar color={props.color} size={props.detailSize} />
          )}
          {minType === 'quantity_minimum' && (
            <IconShipment color={props.color} size={props.detailSize} />
          )}
        </div>
        <div className="product-shipping-detail">
          <span style={{ color: props.color }}>{getText(minType)}</span>
          <div className="gray-text">
            Requires a minimum of {formatVendorMinimum(props.vendor)}
          </div>
        </div>
      </div>
    );
  }

  if (hasMinimum(props.vendor)) {
    return (
      <Link
        to={`/supplies/catalog-search?cart_minimum=${props.vendor.id}`}
        title="Browse products with this minimum"
      >
        <span
          className={classnames('big-box-wrap', {
            alt: minType === 'quantity_minimum',
          })}
        >
          {(!hasNextDayShipping(props.vendor) || props.alwaysShowFullText) && (
            <span style={{ color: props.color }}>{getText(minType)}</span>
          )}
          <div className="big-box-icon">
            {minType === 'price_minimum' && (
              <IconShipmentDollar color={props.color} size={props.size} />
            )}
            {minType === 'quantity_minimum' && (
              <IconShipment color={props.color} size={props.size} />
            )}
          </div>
        </span>
      </Link>
    );
  }
  return null;
};

VendorMinimumIcon.propTypes = {
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity_minimum: PropTypes.number.isRequired,
    price_minimum: PropTypes.number.isRequired,
  }).isRequired,
  detailView: PropTypes.bool.isRequired,
  alwaysShowFullText: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  detailSize: PropTypes.number.isRequired,
};

VendorMinimumIcon.defaultProps = {
  detailView: false,
  alwaysShowFullText: false,
  color: '#00aa8d',
  size: 24,
  detailSize: 30,
};

const mapStateToProps = (state, props) => {
  const color =
    props.vendor && state.locations.open.vendorColorMap
      ? state.locations.open.vendorColorMap[props.vendor.id] ||
        colorHash.hex(props.vendor.id)
      : VendorMinimumIcon.defaultProps.color;
  return {
    color,
  };
};

function areStatesEqual(prev, next) {
  return (
    prev.locations.open.vendorColorMap === next.locations.open.vendorColorMap
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  VendorMinimumIcon
);
