import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import SupplyCategoryMenu from './supply-category-menu.js';
import SupplyItem from './supply-item.js';
import SupplyItemEditable from './supply-item-editable.js';
import ProductItem from '../products/item.js';
import CartFlex from '../requisitions/cart-flex.js';
import SearchResultsRequestItem from '../products/search-results-request-item.js';
import Draggable from '../draggable.js';
import Droppable from '../droppable.js';
import {
  trackInterfaceLocationListProductMoveToTop,
  trackInterfaceLocationListProductMovePosition,
  trackInterfaceLocationListCategoryChange,
  trackInterfaceLocationListToggleEditMode,
  trackInterfaceLocationListSearch,
} from '../../lib/analytics.js';
import {
  submitUpdateLocationListProduct,
  submitDeleteLocationListProduct,
} from '../../actions/location-list-product-actions.js';
import {
  GET_LOCATION_LIST_PRODUCTS,
  GET_LOCATION_LISTS,
} from '../../actions/action-types.js';
import {
  setInitialProductModalTab,
  unsetInitialProductModalTab,
} from '../../actions/product-actions.js';
import { PRODUCT_MODAL_TABS } from '../../strings.js';
import { mapLocationParentCategoriesIds } from '../../helpers/product-category-helpers.js';
import { filterProductsByParentCategories } from '../../helpers/supply-levels-helpers.js';
import './supply-levels.css';

const ALL_SUPPLIES = {
  id: -1,
  name: 'All Categories',
};

const DraggableDroppableSupplyItemEditable = Draggable(
  Droppable(SupplyItemEditable)
);

export function constructParentCategories(locationListProducts) {
  let parentCategory = {};
  let parentCategories = locationListProducts.reduce((acc, llp) => {
    parentCategory = llp.customer_budget_code || null;
    if (!parentCategory) {
      return acc;
    }
    if (!acc[parentCategory.id]) {
      acc[parentCategory.id] = parentCategory;
    }
    return acc;
  }, {});
  parentCategories = Object.values(parentCategories);
  parentCategories.unshift(ALL_SUPPLIES);
  return parentCategories;
}

export function constructSortedProducts(locationListProducts) {
  let sortedProducts = {};
  let parentCategory = {};
  locationListProducts
    .sort((a, b) => {
      return a.id > b.id ? -1 : 1;
    })
    .map((llp) => {
      parentCategory = llp.customer_budget_code || null;
      if (!parentCategory) {
        return sortedProducts.length;
      }
      if (sortedProducts[parentCategory.id]) {
        return sortedProducts[parentCategory.id].push(llp);
      } else {
        return (sortedProducts[parentCategory.id] = [llp]);
      }
    });
  sortedProducts[ALL_SUPPLIES.id] = locationListProducts;
  return sortedProducts;
}

export function constructCategoryOptions(parentCategories, sortedProducts) {
  return parentCategories.map((category) => {
    return {
      id: category.id,
      label: category.name + ` (${sortedProducts[category.id].length})`,
    };
  });
}

export function constructGroupedProducts(products, locationLists) {
  let grouped = products.reduce((acc, val) => {
    acc[val.location_list_id] = acc[val.location_list_id] || [];
    acc[val.location_list_id].push(val);
    return acc;
  }, {});
  return Object.keys(grouped)
    .map((location_list_id) => {
      return Object.assign(
        {
          locationListProducts: grouped[location_list_id],
        },
        locationLists.filter(
          (list) => list.id.toString() === location_list_id
        )[0] || {}
      );
    })
    .filter((locationList) => !!locationList.id);
}

const BY_SORT_ORDER_DESC = (a, b) => {
  if (parseFloat(a.sort_order) < parseFloat(b.sort_order)) return 1;
  if (parseFloat(a.sort_order) > parseFloat(b.sort_order)) return -1;
  return -1;
};

export class SupplyLevels extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this._buildState(props, {}),
      editMode: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState((state) => {
      return this._buildState(nextProps, state);
    });
    if (!this.props.role.can_manage_location_lists) return;

    if (!this.props.supplyLevelsAccess && nextProps.supplyLevelsAccess) {
      this.props.actions.setInitialProductModalTab(
        PRODUCT_MODAL_TABS.TAB_STOCK
      );
    }
    if (this.props.supplyLevelsAccess && !nextProps.supplyLevelsAccess) {
      this.props.actions.unsetInitialProductModalTab();
    }
  }

  componentDidMount() {
    this.filteredCategories(ALL_SUPPLIES, true);
    if (
      this.props.supplyLevelsAccess &&
      this.props.role.can_manage_location_lists
    ) {
      this.props.actions.setInitialProductModalTab(
        PRODUCT_MODAL_TABS.TAB_STOCK
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetInitialProductModalTab();
  }

  _buildState = (props, state) => {
    let parentCategories = constructParentCategories(
      props.openLocationListProducts
    );
    let sortedProducts = constructSortedProducts(
      props.openLocationListProducts
    );
    let categoryOptions = constructCategoryOptions(
      parentCategories,
      sortedProducts
    );
    let openCategory = state.openCategory || ALL_SUPPLIES;
    let searchValue = state.searchValue || '';
    let openProducts = this.filteredProducts(
      sortedProducts,
      searchValue,
      openCategory
    )
      .sort(BY_SORT_ORDER_DESC)
      .map((item, i) => ({
        ...item,
        sortIndex: i,
      }));
    let groupedOpenProducts = constructGroupedProducts(
      openProducts,
      props.locationLists
    );
    let editMode = state.editMode;
    return {
      searchValue,
      openCategory,
      openProducts,
      groupedOpenProducts,
      parentCategories,
      sortedProducts,
      categoryOptions,
      editMode,
      sortedProductsAll: constructSortedProducts(props.locationListProducts),
    };
  };

  handleCategoryMenuClick = (e, { id }) => {
    if (!id) return;
    const openCategory = this.state.parentCategories.filter(
      (pc) => pc.id === id
    )[0];
    const openProducts = this.filteredProducts(
      this.state.sortedProducts,
      this.state.searchValue,
      openCategory
    );
    this.setState(() => {
      return {
        openCategory,
        openProducts,
        hide_filter: id === ALL_SUPPLIES.id && openProducts.length === 0,
      };
    });
    trackInterfaceLocationListCategoryChange(openCategory);
  };

  handleListMenuClick = () => {
    this.setState(() => {
      return {
        openCategory: ALL_SUPPLIES,
      };
    });
  };

  handleItemSearchChange = (e, { value }) => {
    if (value && value.length > 0) {
      let openProducts = this.filteredProducts(
        this.state.sortedProductsAll,
        value,
        this.state.openCategory
      );
      let groupedOpenProducts = constructGroupedProducts(openProducts, [
        this.props.openLocationList,
      ]);
      this.setState(() => {
        return {
          searchValue: value,
          groupedOpenProducts,
          openProducts,
        };
      });
      trackInterfaceLocationListSearch({ value });
      return;
    }
    this.setState((prevState) => {
      return {
        searchValue: '',
        groupedOpenProducts: [],
        openProducts: prevState.sortedProducts[prevState.openCategory.id],
      };
    });
  };

  filteredCategories = (selectedCategory, mobile_filter) => {
    const openCategory = this.state.parentCategories.filter(
      (pc) => pc.id === selectedCategory.id
    )[0];
    const openProducts = this.filteredProducts(
      this.state.sortedProducts,
      this.state.searchValue,
      openCategory
    );
    this.setState((prevState) => {
      return {
        openCategory,
        openProducts,
        hide_filter:
          selectedCategory.id === ALL_SUPPLIES.id && openProducts.length === 0,
      };
    });
  };

  filteredProducts = (sortedProducts, searchValue, openCategory) => {
    if (!searchValue.trim()) {
      return sortedProducts[openCategory.id] || [];
    }
    return new Fuse(sortedProducts[ALL_SUPPLIES.id] || [], {
      keys: ['product.brand', 'product.name', 'product.description'],
      threshold: 0.4,
    })
      .search(searchValue)
      .map((match) => match.item);
  };

  isSearching = () => !!this.state.searchValue;

  reorderItems = (data) => {
    let openProducts = this.state.openProducts
      .slice()
      .filter((product) => product.id !== data.transferId);
    const itemToPlace = this.state.openProducts.filter(
      (product) => product.id === data.transferId
    )[0];
    let destinationItemIndex = this.state.openProducts.findIndex(
      (product) => product.id === data.destinationId
    );

    openProducts.splice(destinationItemIndex, 0, itemToPlace);
    openProducts = openProducts.map((item, i) => ({
      ...item,
      sortIndex: i,
    }));
    this.setState({ openProducts }, () => {
      this.updateSortOrder(itemToPlace, destinationItemIndex);
    });
    trackInterfaceLocationListProductMovePosition(itemToPlace);
  };

  updateSortOrder = (itemToPlace, destinationItemIndex) => {
    let higherSortOrder;
    let lowerSortOrder;
    let resultSortOrder;
    if (destinationItemIndex > 0) {
      higherSortOrder = parseFloat(
        this.state.openProducts[destinationItemIndex - 1].sort_order
      );
    }
    /* moving to first position */
    if (destinationItemIndex === 0) {
      /* take sort order of first item and add 1 */
      /* need the FORMER first item, so at index 1 (not 0) */
      higherSortOrder = parseFloat(this.state.openProducts[1].sort_order) + 1.0;
    }
    const nextItem = this.state.openProducts[destinationItemIndex + 1];
    if (nextItem) {
      lowerSortOrder = parseFloat(nextItem.sort_order);
    } else {
      /* moving to last position */
      /* take sort order of former last item and remove 1 */
      /* using -2 instead of -1 to find index bc last item has already been applied to state */
      resultSortOrder =
        parseFloat(
          this.state.openProducts[this.state.openProducts.length - 2].sort_order
        ) - 1.0;
    }
    // formula: ((higher - lower) / 2) + lower
    if (higherSortOrder && lowerSortOrder && !resultSortOrder) {
      resultSortOrder =
        (higherSortOrder - lowerSortOrder) / 2.0 + lowerSortOrder;
    }
    this.props.actions.submitUpdateLocationListProduct({
      id: itemToPlace.id,
      sort_order: resultSortOrder,
    });
  };

  moveToTop = (item) => {
    let openProducts = this.state.openProducts
      .slice()
      .filter((product) => product.id !== item.id);
    openProducts.splice(0, 0, item);
    openProducts = openProducts.map((product, i) => ({
      ...product,
      sortIndex: i,
    }));
    const resultSortOrder =
      parseFloat(this.state.openProducts[0].sort_order) + 1;
    const scrollY = window.pageYOffset;
    this.setState({ openProducts }, () => {
      window.scrollTo(0, scrollY);
      this.props.actions.submitUpdateLocationListProduct({
        id: item.id,
        sort_order: resultSortOrder,
      });
    });
    trackInterfaceLocationListProductMoveToTop(item);
  };

  deleteItem = (item) => {
    if (
      window.confirm(
        'Do you really want to delete this product from your list?'
      )
    ) {
      this.props.actions.submitDeleteLocationListProduct(item);
    }
  };

  toggleEditMode = () => {
    this.setState((prevState) => ({
      editMode: !prevState.editMode,
    }));
    trackInterfaceLocationListToggleEditMode(this.props.openLocationList);
  };

  renderItemGroup = (itemGroup) => {
    return (
      <div className="open-products-group" key={itemGroup.id}>
        <h4 className="open-products-group-name">{itemGroup.name}</h4>
        {itemGroup.locationListProducts.map(this.renderItem)}
      </div>
    );
  };

  renderItem = (item, i) => {
    if (this.state.editMode) {
      return (
        <DraggableDroppableSupplyItemEditable
          key={item.id}
          item={item}
          onDeleteItem={this.deleteItem}
          onMoveToTop={i !== 0 ? this.moveToTop : undefined}
          onDrop={this.reorderItems}
        />
      );
    }
    if (this.props.supplyLevelsAccess) {
      return <SupplyItem key={item.id} item={item} />;
    }
    return <ProductItem key={item.id} item={item.product} canFavorite={true} />;
  };

  render() {
    return (
      <div className="subnav-container-supply-levels supply-levels">
        <div className="supply-levels-wrap">
          <div className="officeluv-supplies-container content-desktop flex-wrapper">
            <div className="supply-levels-items flex-item flex-wrapper">
              <SearchResultsRequestItem />
              <SupplyCategoryMenu
                budgetCodesAccess={this.props.budgetCodesAccess}
                editMode={this.state.editMode}
                onEditModeChange={this.toggleEditMode}
                searchValue={this.state.searchValue}
                onSearchChange={this.handleItemSearchChange}
                parentCategories={this.state.parentCategories}
                openCategory={this.state.openCategory}
                onListMenuClick={this.handleListMenuClick}
                onCategoryMenuClick={this.handleCategoryMenuClick}
                sortedProducts={this.state.sortedProducts}
                isDisabled={this.props.isRequesting || this.props.isEmpty}
              />
              {!this.isSearching() && (
                <Segment
                  basic
                  loading={this.props.isRequesting}
                  placeholder={this.props.isEmpty}
                  className={classnames('open-products', {
                    favorites: !this.props.supplyLevelsAccess,
                  })}>
                  {this.state.openProducts.map(this.renderItem)}
                  {(this.props.isEmpty || this.props.listIsEmpty) && (
                    <Segment
                      placeholder
                      style={{ width: '100%', background: 'white' }}>
                      <Header icon>
                        You haven't saved any supplies
                        {!this.props.isEmpty && ' in this list'} yet.
                      </Header>
                      <Button primary>
                        <Link to="/supplies/catalog">Search Products</Link>
                      </Button>
                    </Segment>
                  )}
                </Segment>
              )}
              {this.isSearching() && (
                <Segment
                  basic
                  loading={this.props.isRequesting}
                  className={classnames('open-products', {
                    favorites: !this.props.supplyLevelsAccess,
                  })}>
                  {this.state.groupedOpenProducts.map(this.renderItemGroup)}
                </Segment>
              )}
              {this.state.groupedOpenProducts.length < 1 &&
                this.isSearching() &&
                this.props.locationListProducts.length > 1 && (
                  <Segment placeholder style={{ width: '100%', margin: 0 }}>
                    <Header icon>
                      <Icon name="search" style={{ fontSize: '2rem' }} />
                      No matches found for "{this.state.searchValue}".
                    </Header>
                    <Button primary>
                      <Link to="/supplies/catalog">Search our catalog</Link>
                    </Button>
                  </Segment>
                )}
            </div>
            <CartFlex canSwitchCarts={true} />
          </div>
        </div>
      </div>
    );
  }
}

SupplyLevels.propTypes = {
  isRequesting: PropTypes.bool,
  isEmpty: PropTypes.bool,
  listIsEmpty: PropTypes.bool,
  openLocationList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  locationLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  locationListProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      location_list_id: PropTypes.number.isRequired,
      product_id: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        sku_brand: PropTypes.string.isRequired,
        unit_purchase: PropTypes.string.isRequired,
        stock_per_purchase: PropTypes.number.isRequired,
        unit_stock: PropTypes.string.isRequired,
      }).isRequired,
      customer_budget_code: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  actions: PropTypes.shape({
    setInitialProductModalTab: PropTypes.func.isRequired,
    unsetInitialProductModalTab: PropTypes.func.isRequired,
    submitUpdateLocationListProduct: PropTypes.func.isRequired,
    submitDeleteLocationListProduct: PropTypes.func.isRequired,
  }).isRequired,
  budgetCodesAccess: PropTypes.bool.isRequired,
  supplyLevelsAccess: PropTypes.bool.isRequired,
  role: PropTypes.shape({
    can_manage_location_lists: PropTypes.bool.isRequired,
  }).isRequired,
};

SupplyLevels.defaultProps = {
  isRequesting: true,
};

export function sortItems(a, b) {
  return a.created_at > b.created_at ? -1 : 1;
}

function mapStateToProps(state) {
  const locationParentCategories = mapLocationParentCategoriesIds(
    state.locationParentProductCategories.items
  );
  const locationListProducts = filterProductsByParentCategories(
    state.locationListProducts.items,
    locationParentCategories
  );

  const isRequesting =
    (locationListProducts.length < 1 &&
      state.locationListProducts.requesting.filter(
        (r) => r._request === GET_LOCATION_LIST_PRODUCTS
      ).length > 0) ||
    (state.locationLists.items.length < 1 &&
      state.locationLists.requesting.filter(
        (r) => r._request === GET_LOCATION_LISTS
      ).length > 0) ||
    !state.locationListProducts.initialized ||
    !state.locationLists.initialized;

  const isEmpty =
    !isRequesting &&
    (locationListProducts.length < 1 || !state.locationLists.open.id);
  const listIsEmpty =
    !isRequesting &&
    locationListProducts.filter(
      (llp) => llp.location_list_id === state.locationLists.open.id
    ).length < 1;

  return {
    role: state.auth.role,
    budgetCodesAccess: state.locations.open.pref_enable_custom_budget_codes,
    supplyLevelsAccess:
      state.locations.open.pref_enable_location_list_stock_levels,
    openLocationList: state.locationLists.open,
    locationLists: state.locationLists.items,
    locationListProducts: locationListProducts,
    openLocationListProducts: locationListProducts.filter(
      (llp) => llp.location_list_id === state.locationLists.open.id
    ),
    isEmpty,
    listIsEmpty,
    isRequesting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setInitialProductModalTab,
        unsetInitialProductModalTab,
        submitUpdateLocationListProduct,
        submitDeleteLocationListProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locationListProducts.items === next.locationListProducts.items &&
    prev.locationListProducts.requesting ===
      next.locationListProducts.requesting &&
    prev.locationLists.open === next.locationLists.open &&
    prev.locationLists.items === next.locationLists.items &&
    prev.locationLists.requesting === next.locationLists.requesting &&
    prev.auth.role === next.auth.role &&
    prev.locationListProducts.initialized ===
      next.locationListProducts.initialized &&
    prev.locationLists.initialized === next.locationLists.initialized &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SupplyLevels);
