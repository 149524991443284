import log from '../lib/log.js';
const debug = log('[redux]');

const logger = (store) => (next) => (action) => {
  debug.log('dispatching', action);
  let result = next(action);
  debug.log('next state', store.getState());
  return result;
};

export default logger;
