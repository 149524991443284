import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { Form } from 'semantic-ui-react';

export class ArrivalForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  onChangeFocus = (focused) => {
    this.setState(() => {
      return focused;
    });
  };

  render() {
    return (
      <Form.Field className="required">
        <label>Arrival date</label>
        <div>
          <SingleDatePicker
            id="arrival-form"
            date={moment(this.props.date)}
            onDateChange={this.props.onDateChange}
            isDayBlocked={this.props.isDayBlocked}
            isOutsideRange={this.props.isOutsideRange}
            focused={this.state.focused}
            onFocusChange={this.onChangeFocus}
          />
        </div>
      </Form.Field>
    );
  }
}

ArrivalForm.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)])
    .isRequired,
  onDateChange: PropTypes.func.isRequired,
  isDayBlocked: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
};

ArrivalForm.defaultProps = {
  date: moment(),
  onDateChange: () => {},
  isDayBlocked: () => false,
  isOutsideRange: () => false,
};

export default ArrivalForm;
