import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextWithAnchors from '../text/text-with-anchors.js';
import { openShipment } from '../../ducks/shipments.js';
import { isEasyPost } from '../../helpers/shipment-helpers.js';
import { trackShipmentExternalTracking } from '../../lib/analytics.js';
import '../purchaseOrders/item.css';

export class TrackingCode extends React.PureComponent {
  onClickTracking = () => {
    trackShipmentExternalTracking(this.props.item);
  };

  openShipment = (e) => {
    e.preventDefault();
    this.props.actions.openShipment(this.props.item);
    this.onClickTracking();
  };

  renderTrackingText = () => {
    return this.props.item.tracking_code;
  };

  render() {
    const shipmentIsEasyPost = isEasyPost(this.props.item);
    if (this.props.item.tracking_url && !shipmentIsEasyPost) {
      return (
        <TextWithAnchors
          text={this.props.item.tracking_url}
          onClickAnchor={this.onClickTracking}
          renderAnchorText={this.renderTrackingText}
        />
      );
    }
    if (
      this.props.item.tracking_url &&
      shipmentIsEasyPost &&
      this.props.asLinkEasypost
    ) {
      return (
        <a
          href={`?shipment=${this.props.item.id}`}
          className="populated-anchor"
          onClick={this.openShipment}
        >
          {this.renderTrackingText()}
        </a>
      );
    }
    return <span>{this.props.item.tracking_code || null}</span>;
  }
}

TrackingCode.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tracking_code: PropTypes.string.isRequired,
    tracking_url: PropTypes.string,
  }).isRequired,
  asLinkEasypost: PropTypes.bool,
};

TrackingCode.defaultProps = {
  asLinkEasypost: true,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openShipment,
      },
      dispatch
    ),
  };
}

export function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  TrackingCode
);
