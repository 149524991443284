import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './modal-common.css';
import './modal-confirm.css';

export class ModalConfirm extends React.PureComponent {
  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        className={{
          base: 'modal modal-confirm',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Confirmation Modal"
      >
        <div className="modal-confirm__content">
          <button
            type="button"
            className="no-style modal-close close-icon"
            onClick={this.props.onRequestClose}
          ></button>
          {this.props.children}
        </div>
      </Modal>
    );
  }
}

ModalConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
};

export default ModalConfirm;
