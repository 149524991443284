import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import DeliveryInfo from '../components/requisitions/item-delivery-info.js';
import ScheduledInfo from '../components/requisitions/item-scheduled-info.js';
import RequisitionItemDetails from '../components/requisitions/item-details.js';
import RequisitionParentDetails from '../components/requisitions/parent-details.js';
import RequisitionChildrenDetails from '../components/requisitions/children-details.js';
import ShipmentItem from '../components/requisitions/shipment-item.js';
import PendingShipments from '../components/requisitions/pending-shipments';
import SubNavSuppliesOrder from '../components/nav/subnav-supplies-order.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import Loader from '../components/loader.js';
import setTitle from '../decorators/set-title.js';
import { lastMarkedAs } from '../helpers/shipment-helpers.js';
import { hasSeenFeatureTour } from '../helpers/feature-tour-helpers.js';
import {
  openRequisition,
  doGetRequisition,
  closeRequisition,
} from '../actions/requisition-actions.js';
import {
  openFeatureTour,
  FILTER_IS_REQUESTING,
} from '../ducks/feature-tours.js';
import { FILTER_IS_REQUESTING as FILTER_IS_REQUESTING_FTA } from '../ducks/feature-tour-attempts.js';
import { trackOrderPrint } from '../lib/analytics.js';
import * as requisitionHelpers from '../helpers/requisition-helpers';
import 'semantic-ui-css/components/button.min.css';
import './supplies-order.css';

export class SuppliesOrder extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
    this.props.actions.doGetRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.actions.openRequisition({
        id: parseInt(this.props.match.params.id, 10),
      });
      this.props.actions.doGetRequisition({
        id: parseInt(this.props.match.params.id, 10),
      });
      return;
    }
    if (this.shouldRedirectToCatalog(this.props)) {
      return browserHistory.push('/supplies/orders');
    }
    if (this.props.displayFeatureTour) {
      this.props.actions.openFeatureTour({ name: 'order_intro_enabled' });
    }
  }

  componentWillUnmount() {
    this.props.actions.closeRequisition();
  }

  shouldRedirectToCatalog = (props) => {
    if (props.isRequesting) return false;

    return (
      !props.openRequisition.submitted_at ||
      props.openRequisition.location_id !== props.openLocation.id
    );
  };

  printOrderPdf = () => {
    trackOrderPrint(this.props.openRequisition);
    window.print();
  };

  renderShipment = (shipment) => {
    return (
      <ShipmentItem
        key={shipment.id}
        shipment={shipment}
        shipmentIndex={this.props.totalShipmentsIds.indexOf(shipment.id) + 1}
        shipmentCount={this.props.totalShipmentsIds.length}
      />
    );
  };

  authorized = () => {
    if (!this.props.openRequisition.requester_type) return true;
    if (this.props.auth.role.can_manage_all_requisitions) return true;
    if (this.props.openRequisition.is_public) return true;
    if (
      this.props.openRequisition.requester_type ===
        this.props.auth.actor.type &&
      this.props.openRequisition.requester_id === this.props.auth.actor.id
    )
      return true;
    if (
      this.props.openRequisition.proposer_type === this.props.auth.actor.type &&
      this.props.openRequisition.proposer_id === this.props.auth.actor.id
    )
      return true;
    return false;
  };

  render() {
    return (
      <div className="supplies-order-route">
        <AuthCheck customAuthorized={this.authorized()} />
        <MountAtPageTop />
        <NavBarAttendant />
        {this.props.openRequisition.id > 0 &&
          this.props.openRequisition.submitted_at && (
            <SubNavSuppliesOrder item={this.props.openRequisition} />
          )}
        <div className="supplies-order-container fixed-width-column flex-wrapper">
          <div className="shipments-orders-container">
            <RequisitionParentDetails
              requisition={this.props.openRequisition}
            />
            <RequisitionChildrenDetails
              requisition={this.props.openRequisition}
            />
            {this.props.expectedShipments.length > 0 && (
              <div className="hide-when-printing shipments-wrap">
                <h3 className="section-header">Expected Shipments</h3>
                {this.props.expectedShipments.map(this.renderShipment)}
              </div>
            )}
            {this.props.openRequisition.id > 0 &&
              this.props.openRequisition.submitted_at && (
                <RequisitionItemDetails item={this.props.openRequisition} />
              )}
            {this.props.receivedShipments.length > 0 && (
              <div className="hide-when-printing">
                <h3 className="section-header">Received Shipments</h3>
                {this.props.receivedShipments.map(this.renderShipment)}
              </div>
            )}
          </div>
          <div className="delivery-info-container hide-when-printing">
            {this.props.openRequisition.submitted_at && (
              <div className="requisition-right-rail">
                {requisitionHelpers.isEditable(
                  this.props.openRequisition,
                  this.props.user,
                  this.props.userType
                ) && (
                  <Link
                    to={`/supplies/orders/${this.props.openRequisition.id}/edit`}
                    className="order-edit-link brand-bg-color-override">
                    Edit Order
                  </Link>
                )}
                {!requisitionHelpers.isEditable(
                  this.props.openRequisition,
                  this.props.user,
                  this.props.userType
                ) && (
                  <PendingShipments
                    openRequisition={this.props.openRequisition}
                  />
                )}
                {this.props.openLocation.pref_enable_scheduled_requisitions &&
                  this.props.scheduledRequisition && (
                    <ScheduledInfo
                      requisition={this.props.openRequisition}
                      scheduled_requisition={this.props.scheduledRequisition}
                      isRequesting={this.props.isRequesting}
                    />
                  )}
                <DeliveryInfo
                  item={this.props.openRequisition}
                  shipments={this.props.expectedShipments.concat(
                    this.props.receivedShipments
                  )}
                />
                <Button
                  primary
                  as="a"
                  href="#print"
                  size="tiny"
                  onClick={this.printOrderPdf}
                  className="order-download-pdf">
                  Print PDF
                </Button>
              </div>
            )}
          </div>
        </div>
        {this.props.isRequesting && <Loader />}
      </div>
    );
  }
}

SuppliesOrder = setTitle((props) => {
  return 'Order #' + props.match.params.id;
})(SuppliesOrder);

const purchaseOrderPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  location_id: PropTypes.number.isRequired,
  vendor_po_number: PropTypes.string.isRequired,
  received_at: PropTypes.string,
  receiver_id: PropTypes.number,
  receiver_type: PropTypes.string,
  expected_at_start: PropTypes.string,
  expected_at_end: PropTypes.string,
  shipping_address: PropTypes.string,
  shipping_address_number: PropTypes.string,
  shipping_business: PropTypes.string,
  shipping_care: PropTypes.string,
  shipping_city: PropTypes.string,
  shipping_name: PropTypes.string,
  shipping_state: PropTypes.string,
  shipping_country: PropTypes.string,
  shipping_zip: PropTypes.string,
  product_purchase_orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      product_id: PropTypes.number.isRequired,
      product_requisition_id: PropTypes.number,
      purchase_order_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      issue: PropTypes.string,
      issue_quantity: PropTypes.number,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
});

SuppliesOrder.propTypes = {
  actions: PropTypes.shape({
    openRequisition: PropTypes.func.isRequired,
    doGetRequisition: PropTypes.func.isRequired,
    closeRequisition: PropTypes.func.isRequired,
    openFeatureTour: PropTypes.func.isRequired,
  }).isRequired,
  displayFeatureTour: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  openRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number,
    scheduled_requisition_id: PropTypes.number,
    shipmentIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  totalShipmentsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  expectedShipments: PropTypes.arrayOf(purchaseOrderPropType),
  receivedShipments: PropTypes.arrayOf(purchaseOrderPropType),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  userType: PropTypes.string.isRequired,
  scheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  auth: PropTypes.shape({
    role: PropTypes.shape({
      can_manage_all_requisitions: PropTypes.bool,
    }).isRequired,
    actor: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  const openRequisition = state.requisitions.open;
  const shipmentIds = openRequisition.shipmentIds || [];
  const shipments = shipmentIds
    .map((sId) => state.shipments.items[sId])
    .filter((item) => item !== undefined)
    .sort((a, b) => (a.expected_at_end > b.expected_at_end ? -1 : 1));
  const user =
    state.employees.user.id > 0 ? state.employees.user : state.staffs.user;
  const scheduledRequisition = openRequisition.scheduled_requisition;
  let openRequisitionIsRecent = false;
  if (openRequisition.submitted_at) {
    openRequisitionIsRecent =
      new Date(openRequisition.submitted_at) >
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2);
  }
  const isRequesting =
    state.requisitions.requesting.filter((r) => r.id === openRequisition.id)
      .length > 0 ||
    state.requisitions.requesting.filter(
      (req) => req._request === 'GET_REQUISITIONS'
    ).length > 0 ||
    state.shipments.requesting.length > 0 ||
    !openRequisition.created_at;

  const isRequestingFeatureTours =
    !!state.featureTours.requesting.filter(FILTER_IS_REQUESTING).legth &&
    !!state.featureTourAttempts.requesting.filter(FILTER_IS_REQUESTING_FTA)
      .length;

  return {
    openRequisition,
    user,
    scheduledRequisition,
    userType: state.auth.actor.type,
    isRequesting,
    totalShipmentsIds: shipments.map((s) => s.id),
    expectedShipments: shipments.filter((s) => {
      return !lastMarkedAs(s) || lastMarkedAs(s) === 'missing';
    }),
    receivedShipments: shipments.filter((s) => {
      return lastMarkedAs(s) === 'received';
    }),
    openLocation: state.locations.open,
    displayFeatureTour:
      openRequisitionIsRecent &&
      state.auth.role.can_manage_insights &&
      !isRequesting &&
      !isRequestingFeatureTours &&
      !state.featureTours.open.id &&
      !hasSeenFeatureTour(
        'order_intro_enabled',
        state.featureTours.items,
        state.featureTourAttempts.summary
      ),
    auth: state.auth,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openRequisition,
        doGetRequisition,
        closeRequisition,
        openFeatureTour,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.open === next.requisitions.open &&
    prev.requisitions.requesting === next.requisitions.requesting &&
    prev.requisitions.open.product_requisitions ===
      next.requisitions.open.product_requisitions &&
    prev.shipments.requesting === next.shipments.requesting &&
    prev.shipments.items === next.shipments.items &&
    prev.staffs.user === next.staffs.user &&
    prev.employees.user === next.employees.user &&
    prev.locations.open === next.locations.open &&
    prev.featureTours.items === next.featureTours.items &&
    prev.featureTours.requesting === next.featureTours.requesting &&
    prev.featureTours.open === next.featureTours.open &&
    prev.featureTourAttempts.summary === next.featureTourAttempts.summary &&
    prev.featureTourAttempts.requesting ===
      next.featureTourAttempts.requesting &&
    prev.auth === next.auth
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SuppliesOrder);
