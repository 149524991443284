import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './product-sort.css';

export default class ProductSortItem extends React.PureComponent {
  render() {
    return (
      <span
        className={classnames('product-sort-item', {
          active: this.props.activeLabel === this.props.label,
          'brand-color-override': this.props.activeLabel === this.props.label,
        })}
        onClick={() => this.props.onSelect(this.props.label)}
      >
        <span>{this.props.label}</span>

        <span
          className={classnames('direction', {
            asc: !this.props.descending,
            desc: this.props.descending,
            hidden: this.props.activeLabel !== this.props.label,
          })}
        />
      </span>
    );
  }
}

ProductSortItem.propTypes = {
  label: PropTypes.string.isRequired,
  activeLabel: PropTypes.string.isRequired,
  descending: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ProductSortItem.defaultProps = {
  label: '',
  descending: true,
};
