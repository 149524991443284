import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Form, Table } from 'semantic-ui-react';
import EditBudgetCodeRow from './edit-budget-code-row.js';
import { trackInterfaceSelectAllForBudgetCodeAssignment } from '../../lib/analytics.js';
import { submitLocationListProductBudgetCode } from '../../actions/location-list-product-actions.js';
import { openCustomerBudgetCodeCreateModal } from '../../actions/customer-budget-codes-actions.js';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/table.min.css';
import './edit-budget-code-products.css';

export class EditLocationListProductsBudgetCodes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      selectedLocationListProducts: [],
      sortedLocationListProducts: (props.locationListProducts || []).sort(
        sorter
      ),
      selectOptions: props.customerBudgetCodes
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((c) => {
          return { value: c.id, text: c.name, key: c.id };
        }),
    };
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    this.setState(() => {
      return {
        sortedLocationListProducts: (props.locationListProducts || []).sort(
          sorter
        ),
        selectOptions: props.customerBudgetCodes
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((c) => {
            return { value: c.id, text: c.name, key: c.id };
          }),
      };
    });
  };

  componentWillUnmount() {
    this.successTimeout && window.clearTimeout(this.successTimeout);
  }

  unsetSuccess = () => {
    this.setState(() => {
      return {
        success: false,
      };
    });
  };

  onSuccess = () => {
    this.setState(() => {
      return {
        selectedLocationListProducts: [],
        success: true,
        selectAllChecked: false,
      };
    });
    this.successTimeout = window.setTimeout(this.unsetSuccess, 5000);
  };

  isSelectDisabled = () => {
    return this.state.selectedLocationListProducts.length < 1;
  };

  onChangeSelect = (e, { value }) => {
    if (!value) {
      return;
    }
    this.state.selectedLocationListProducts.map((l) => {
      return this.props.actions.submitLocationListProductBudgetCode({
        id: l.id,
        customer_budget_code_id: value,
      });
    });
    this.onSuccess();
  };

  onCheckLocationListProduct = (item, selected) => {
    if (selected) {
      this.setState(() => {
        return {
          selectedLocationListProducts:
            this.state.selectedLocationListProducts.concat(item),
        };
      });
      return;
    }
    this.setState(() => {
      return {
        selectedLocationListProducts:
          this.state.selectedLocationListProducts.filter(
            (l) => l.id !== item.id
          ),
      };
    });
  };

  handleCreateModal = () => {
    if (!this.props.can_manage_billing) {
      return;
    }
    this.props.actions.openCustomerBudgetCodeCreateModal();
  };

  onChangeRowCustomerBudgetCode = (row, cbc) => {
    this.props.actions.submitLocationListProductBudgetCode({
      id: row.id,
      customer_budget_code_id: cbc.id,
    });
  };

  renderLocationListProduct = (item) => {
    const isChecked =
      this.state.selectedLocationListProducts.filter((l) => l.id === item.id)
        .length > 0;
    return (
      <EditBudgetCodeRow
        key={item.id}
        item={item}
        isChecked={isChecked}
        onChangeCustomerBudgetCode={this.onChangeRowCustomerBudgetCode}
        onSelect={this.onCheckLocationListProduct}
      />
    );
  };

  selectAll = () => {
    this.setState((prev) => {
      return {
        selectedLocationListProducts:
          prev.sortedLocationListProducts.length ===
          prev.selectedLocationListProducts.length
            ? []
            : prev.sortedLocationListProducts,
      };
    });
    trackInterfaceSelectAllForBudgetCodeAssignment();
  };

  render() {
    return (
      <div className="edit-budget-code-products">
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <p className="instructions gray-text">
                Select products to bulk-assign them to a category.
              </p>
            </Form.Field>
            <Form.Dropdown
              placeholder="Select Category"
              width={7}
              selection
              clearable
              onChange={this.onChangeSelect}
              options={this.state.selectOptions}
            />
            {this.props.can_manage_billing && (
              <Form.Button
                secondary
                width={7}
                size="small"
                compact
                onClick={this.handleCreateModal}
              >
                Create New Category
              </Form.Button>
            )}
          </Form.Group>
        </Form>
        <Table basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <Checkbox
                  style={{ fontSize: '1em' }}
                  label="All Items"
                  checked={
                    this.state.sortedLocationListProducts.length ===
                    this.state.selectedLocationListProducts.length
                  }
                  name="select-all"
                  onChange={this.selectAll}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(this.state.sortedLocationListProducts || []).map(
              this.renderLocationListProduct
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const productProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});
const budgetCodeProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

function sorter(a, b) {
  if (a.customer_budget_code.id === b.customer_budget_code.id) {
    return a.created_at > b.created_at ? 1 : -1;
  }
  return a.customer_budget_code.id > b.customer_budget_code.id ? 1 : -1;
}
const filterActiveBudgetCodes = (c) => {
  return c.active;
};

EditLocationListProductsBudgetCodes.propTypes = {
  locationListProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      product: productProp.isRequired,
      customer_budget_code: budgetCodeProp,
    })
  ).isRequired,
  customerBudgetCodes: PropTypes.arrayOf(budgetCodeProp).isRequired,
  can_manage_billing: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    submitLocationListProductBudgetCode: PropTypes.func.isRequired,
    openCustomerBudgetCodeCreateModal: PropTypes.func.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    customerBudgetCodes: state.customerBudgetCodes.items.filter(
      filterActiveBudgetCodes
    ),
    can_manage_billing: state.auth.role.can_manage_billing,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationListProductBudgetCode,
        openCustomerBudgetCodeCreateModal,
      },
      dispatch
    ),
  };
}
function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EditLocationListProductsBudgetCodes);
