import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplyItemInStock from './supply-item-in-stock.js';
import SupplyItemAddToCart from './supply-item-add-to-cart.js';
import ItemImage from '../products/item-image.js';
import InputQuantity from '../forms/input-quantity.js';
import ShippingIcon from '../../imgs/truck-speed.svg';
import CartIcon from '../../imgs/icon-cart--black.png';
import VendorMinimumIcon from '../products/vendor-minimum-icon.js';
import ItemCatalogable from '../products/item-catalogable.js';
import {
  formatDate,
  formatUnitOfPurchase,
  formatStockStatus,
  formatCentAmount,
} from '../../lib/formatters.js';
import { showProductModal } from '../../actions/product-actions.js';
import { submitDeleteLocationListProduct } from '../../actions/location-list-product-actions.js';
import {
  hasMinimum,
  hasNextDayShipping,
  getProductVendorForMinimums,
} from '../../helpers/vendor-helpers.js';
import { PRODUCT_IS_IN_STOCK_AT, PRODUCT_IS_IN_STOCK } from '../../flags.js';
import './supply-item.css';
import './supply-item-in-cart.css';

export class SupplyItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      addToCartQuantity: this.calculateAddToCartQuantity(
        props.product_requisition,
        props.item.min,
        props.item.quantity
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => {
      return {
        addToCartQuantity: this.calculateAddToCartQuantity(
          nextProps.product_requisition,
          nextProps.item.min,
          nextProps.item.quantity
        ),
      };
    });
  }

  findLastOrderedDate = () => {
    if (!this.props.requisition) {
      return 'Not yet ordered';
    }
    const product_requisition =
      this.props.requisition.product_requisitions.filter(
        (f) => f.product_id === this.props.item.product.id
      )[0];
    if (!product_requisition) {
      if (this.props.item.product.last_submitted_date) {
        return formatDate(this.props.product.last_submitted_date);
      }
      return 'Not yet ordered';
    }
    const submitted_at = formatDate(this.props.requisition.submitted_at);
    return (
      '(' +
      product_requisition.quantity.toLocaleString() +
      ') ' +
      (submitted_at === 'Today' ? 'today' : `on ${submitted_at}`)
    );
  };

  calculateAddToCartQuantity = (product_requisition, min, quantity) => {
    const diff = min - quantity;
    if (product_requisition) {
      return Math.max(diff - product_requisition.quantity, 0);
    }
    if (diff > 0) {
      return diff;
    } else {
      return 0;
    }
  };

  handleRemoveLocationListProduct = () => {
    this.props.actions.submitDeleteLocationListProduct({
      id: this.props.item.id,
    });
  };

  handleProductModalTrigger = () => {
    this.props.actions.showProductModal(this.props.item.product);
  };

  handleChangeCartQuantity = (addToCartQuantity) => {
    addToCartQuantity = Math.max(addToCartQuantity, 0);
    this.setState(() => {
      return { addToCartQuantity };
    });
  };

  render() {
    const inStock = PRODUCT_IS_IN_STOCK(this.props.item.product);
    const inStockAt = PRODUCT_IS_IN_STOCK_AT(this.props.item.product);
    return (
      <div className="supply-item flex-wrapper">
        <div className="item-product-details-container flex-item">
          <div
            className="item-product-image"
            onClick={this.handleProductModalTrigger}>
            <ItemImage
              product={this.props.item.product}
              alt={this.props.item.product.name}
              title={this.props.item.product.name}
            />
            <ItemCatalogable item={this.props.item.product} />
          </div>
          <div className="item-product-details">
            <div className="item-product-brand">
              {this.props.item.product.brand}
            </div>
            <div
              className="item-name bold-text"
              onClick={this.handleProductModalTrigger}>
              {this.props.item.product.name}
            </div>
            <div className="flex-wrapper">
              <span className="item-product-manufacturing-number">
                #{this.props.item.product.sku_brand}
              </span>
              <span className="item-product-units">
                {formatUnitOfPurchase(
                  this.props.item.product.unit_purchase,
                  this.props.item.product.stock_per_purchase,
                  this.props.item.product.unit_stock
                )}
              </span>
            </div>
            <div className="price-shipping">
              <div className="product-price">
                {formatCentAmount(this.props.item.product.price)}
              </div>
              {hasNextDayShipping(this.props.vendor) && (
                <span
                  className="shipping-on-image"
                  title="Delivery in one business day">
                  {!hasMinimum(this.props.vendor) && <span>1-Day</span>}
                  <img
                    src={ShippingIcon}
                    height={20}
                    width={20}
                    className="shipping-time-icon"
                    title="Delivery in one business day"
                    alt="Delivery in one business day"
                  />
                </span>
              )}
              <VendorMinimumIcon
                vendor={this.props.vendor}
                size={22}
                color={this.props.color}
              />
            </div>
            {!inStock && (
              <div>
                <div className="light-gray-text">
                  {formatStockStatus(this.props.item.product)}
                </div>
                {inStockAt && (
                  <div className="light-gray-text">
                    (Available {formatDate(inStockAt)})
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="item-product-actions-container flex-item">
          <div className="item-product-in-stock-status">
            <SupplyItemInStock item={this.props.item} />
          </div>
          <div className="item-product-in-cart-status">
            <div className="supply-item-in-cart flex-wrapper">
              <div className="desktop-details">
                <div className="item-last-ordered">
                  <div className="site-h4 item-last-ordered-title">
                    <span className="light-gray-text">Last Ordered</span>
                  </div>
                  {this.findLastOrderedDate()}
                </div>
              </div>
              <div className="item-add-to-cart-form flex-wrapper">
                <p className="item-cart-subheader">Add to Cart</p>
                <div className="cart-icon">
                  <img src={CartIcon} alt="OfficeLuv Cart" />
                </div>
                <div className="cart-quantity-input">
                  <InputQuantity
                    className={
                      this.state.addToCartQuantity === 0 ? 'disabled' : ''
                    }
                    label=""
                    min={0}
                    value={this.state.addToCartQuantity}
                    onChange={this.handleChangeCartQuantity}
                  />
                </div>
              </div>
            </div>
            <div className="add-to-cart-container">
              <SupplyItemAddToCart
                addToCartQuantity={this.state.addToCartQuantity}
                item={this.props.item}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SupplyItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_list_id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      sku_brand: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        product_id: PropTypes.number.isRequired,
        requisition_id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  product_requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  color: PropTypes.string.isRequired,
};

export function sortSubmittedRequisitions(a, b) {
  return a.submitted_at > b.submitted_at ? -1 : 1;
}

function mapStateToProps(state, props) {
  const location = state.locations.open;
  const product = props.item.product;
  const vendor = getProductVendorForMinimums(product, state.carts.open);
  const color = location.vendorColorMap[vendor.id] || '#00aa8d';
  return {
    color,
    vendor,
    product_requisition: (state.carts.open.product_requisitions || []).filter(
      (pr) => pr.product_id === props.item.product_id
    )[0],
    requisition: state.requisitions.items
      .filter((r) => !!r.submitted_at)
      .filter((r) => {
        for (let i = r.product_requisitions.length - 1; i >= 0; i--) {
          if (r.product_requisitions[i].product_id === props.item.product_id) {
            return true;
          }
        }
        return false;
      })
      .sort(sortSubmittedRequisitions)[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        submitDeleteLocationListProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.items === next.requisitions.items &&
    prev.carts.open.product_requisitions ===
      next.carts.open.product_requisitions &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SupplyItem);
