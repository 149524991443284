import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Form } from 'semantic-ui-react';
import { submitUpdateCustomerBudgetCode } from '../../actions/customer-budget-codes-actions.js';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/form.min.css';
import { twoDecimalPlaces, parseNumberInput } from '../../lib/formatters.js';

export class BudgetCodeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: props.budgetCode.name,
      id: props.budgetCode.id,
      code: props.budgetCode.code,
      active: props.budgetCode.active,
      budget_annual: props.budgetCode.budget_annual,
    };
  }

  handleChangeActive = (e, { value }) => {
    this.setState(() => {
      return { active: !!value };
    });
  };

  handleChangeName = (e, { value }) => {
    this.setState(() => {
      return { name: value };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.props.actions.submitUpdateCustomerBudgetCode({
      id: this.props.budgetCode.id,
      name: this.state.name,
      code: this.state.code || this.state.name,
      active: this.state.active,
      budget_annual: this.state.budget_annual,
    });
    if (this.props.onSubmit) this.props.onSubmit(this.state);
  };

  isDisabled = () => {
    if (!this.state.name) return true;
    return this.state.name.length < 2;
  };

  handleChangeBudgetMonthly = (e, { value }) => {
    this.setState(
      Object.assign({}, this.state, {
        budget_annual: 12 * parseNumberInput(value) * 100 || '',
      })
    );
  };

  handleChangBudgetAnnual = (e, { value }) => {
    this.setState(
      Object.assign({}, this.state, {
        budget_annual: parseNumberInput(value) * 100 || '',
      })
    );
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Input
          fluid
          label="Name"
          placeholder="Category Name"
          value={this.state.name}
          onChange={this.handleChangeName}
        />
        <Form.Group widths="equal">
          <Form.Input
            icon="dollar"
            iconPosition="left"
            label="Annual Budget"
            placeholder="Annual Budget"
            value={twoDecimalPlaces(this.state.budget_annual)}
            onChange={this.handleChangBudgetAnnual}
          ></Form.Input>
          <Form.Input
            label="Monthly Budget"
            placeholder="Monthly Budget"
            icon="dollar"
            iconPosition="left"
            value={twoDecimalPlaces(this.state.budget_annual / 12)}
            onChange={this.handleChangeBudgetMonthly}
          ></Form.Input>
        </Form.Group>
        <Form.Field>
          Status: <b>{this.state.active ? 'active' : 'archived'}</b>
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label="Active"
            name="active"
            value={1}
            checked={this.state.active}
            onChange={this.handleChangeActive}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label="Archived"
            name="active"
            value={0}
            checked={!this.state.active}
            onChange={this.handleChangeActive}
          />
        </Form.Field>
        <Form.Button
          disabled={this.isDisabled()}
          primary
          style={{ backgroundColor: this.props.brandColor || '#00a98c' }}
        >
          Save
        </Form.Button>
      </Form>
    );
  }
}

BudgetCodeForm.propTypes = {
  budgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    code: PropTypes.string,
  }).isRequired,
  isRequesting: PropTypes.bool,
  onSubmit: PropTypes.func,
  brandColor: PropTypes.string,
  actions: PropTypes.shape({
    submitUpdateCustomerBudgetCode: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    brandColor: state.locations.open.brand_color,
    budgetCode: state.customerBudgetCodes.open,
    isRequesting: state.customerBudgetCodes.updating.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateCustomerBudgetCode,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.open === next.customerBudgetCodes.open &&
    prev.customerBudgetCodes.updating === next.customerBudgetCodes.updating &&
    prev.locations.open.brand_color === next.locations.open.brand_color
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BudgetCodeForm);
