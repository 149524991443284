import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'semantic-ui-react';
import { humanInterval } from '../../helpers/scheduled-requisition-helpers.js';
import { closeProductModal } from '../../actions/product-actions.js';
import 'semantic-ui-css/components/table.min.css';
import './modal-product-recurring-req-list.css';

export class ModalProductRecurringReqList extends React.PureComponent {
  scheduledProductRequisition = (scheduled_req) => {
    return scheduled_req.scheduled_product_requisitions.filter(
      (spr) => spr.product_id === this.props.product.id
    )[0];
  };

  handleClickReq = () => {
    this.props.actions.closeProductModal();
  };

  renderItem = (sr) => {
    return (
      <Table.Row key={sr.id}>
        <Table.Cell verticalAlign="top" width="3">
          <Link
            to={`/orders/scheduled/${sr.id}`}
            onClick={this.handleClickReq}
            className="primary"
          >
            {sr.name}, {humanInterval(sr)}
          </Link>
        </Table.Cell>
        <Table.Cell verticalAlign="top" width="1">
          Quantity: {this.scheduledProductRequisition(sr).quantity}
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    return (
      <div className="product-scheduled-orders">
        <h5 className="ui header">Already In:</h5>
        <Table basic="very">
          <Table.Body>
            {this.props.scheduled_requisitions.map(this.renderItem)}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

ModalProductRecurringReqList.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    closeProductModal: PropTypes.func.isRequired,
  }).isRequired,
  scheduled_requisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      rrule: PropTypes.string.isRequired,
      scheduled_product_requisitions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
          product_id: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeProductModal,
      },
      dispatch
    ),
  };
}

function areStatesEqual() {
  return true;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductRecurringReqList);
