import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import './input.css';
import './input-quantity.css';
let id = 0;

class InputQuantity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      inputValue: props.value + '',
      lastChangeAt: new Date(),
      timeOut: undefined,
      isChanging: false,
    };
  }

  _id = `quantity-${id++}`;

  componentDidUpdate = (prevState) => {
    if (
      this.state.value !== prevState.value &&
      this.state.value !== this.props.value &&
      !this.state.isChanging &&
      this.state.inputValue !== ''
    ) {
      this.props.onChange(this.state.value);
    }
  };

  onValueChange = (ev) => {
    if (ev.target.value === '') {
      this.setState((prevState) => {
        return { ...prevState, inputValue: '' };
      });
      return;
    }

    this._handleValue(ev.target.value);
  };

  _handleValue = (val) => {
    const temp = parseFloat(val || 0);
    const max = Math.min(
      this.props.max === undefined ? temp : this.props.max,
      temp
    );
    const value = Math.max(
      max,
      this.props.min === undefined ? temp : this.props.min
    );

    this.setState((prevState) => {
      return {
        ...prevState,
        value: value,
        inputValue: value + '',
        lastChangeAt: new Date(),
      };
    });
  };

  _handleChange = (handleFunction, event = undefined) => {
    let timeSinceLastChange = new Date() - this.state.lastChangeAt;

    if (timeSinceLastChange < 3000) {
      clearTimeout(this.state.timeOut);
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        isChanging: true,
        timeOut: setTimeout(() => {
          this.setState((prevState) => {
            return { ...prevState, isChanging: false };
          });
        }, 3000),
      };
    });

    handleFunction(event);
  };

  _handleIncrement = () => {
    const value = this.state.value + parseFloat(this.props.step);
    this.onValueChange({ target: { value } });
  };

  _handleDecrement = () => {
    const value = this.state.value - parseFloat(this.props.step);
    this.onValueChange({ target: { value } });
  };

  _removeItem = () => {
    this._handleValue(0);
  };

  render() {
    if (this.props.readOnly) {
      return (
        <div className="input-quantity-readonly-wrap">
          <div className="input-quantity-readonly-header gray-text">
            Quantity
          </div>
          <div className="input-quantity-readonly">{this.state.value}</div>
        </div>
      );
    }

    return (
      <div className="input-quantity-wrap">
        <label className="input-quantity-label" htmlFor={this._id}>
          {this.props.label !== undefined ? this.props.label : 'Quantity'}
        </label>
        <div className="input-quantity-controls">
          <button
            className="input-quantity-decrement brand-color-override"
            type="button"
            onClick={() => this._handleChange(this._handleDecrement)}>
            &ndash;
          </button>
          <input
            className={'input-quantity ' + (this.props.className || '')}
            ref="input"
            type="number"
            title="quantity"
            id={this._id}
            name={this.props.name}
            placeholder={this.props.placeholder}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            value={this.state.inputValue}
            onBlur={(event) => this.onValueChange(event)}
            onChange={(event) => this._handleChange(this.onValueChange, event)}
          />
          <button
            className="input-quantity-increment brand-color-override"
            type="button"
            onClick={() => this._handleChange(this._handleIncrement)}>
            +
          </button>
        </div>
        {this.props.canRemove && this.props.value > 0 && (
          <Button
            size="mini"
            className="tertiary input-quantity-remove"
            onClick={this._removeItem}>
            Remove
          </Button>
        )}
      </div>
    );
  }
}

InputQuantity.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  canRemove: PropTypes.bool,
  readOnly: PropTypes.bool,
};

InputQuantity.defaultProps = {
  canRemove: false,
  readOnly: false,
  step: 1,
};

export default InputQuantity;
