import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import {
  clearProductFilters,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import { SHIPPING_TIME_OPTIONS, STOCK_STATUS_OPTIONS } from '../../strings.js';
import 'semantic-ui-css/components/button.min.css';
import './filters-summary.css';

export class FiltersSummary extends React.PureComponent {
  clearFilters = () => {
    this.props.actions.clearProductFilters();
    this.props.actions.doGetLocationProductQueryResults(null, {
      autoFilter: false,
    });
  };

  renderCategories() {
    const { filters, productCategories } = this.props;
    if (filters.categoryIds.length && productCategories.length) {
      const categoryNames = filters.categoryIds.map((id) => {
        return productCategories.filter((cat) => cat.id === id)[0].name;
      });
      return this.renderLabels('in categories:', categoryNames, ', ');
    }
    return null;
  }

  renderBrands() {
    const { filters } = this.props;
    if (filters.brandNames.length) {
      return this.renderLabels('in brands:', filters.brandNames, ', ');
    }
    return null;
  }

  renderStockStatus() {
    const { filters } = this.props;
    if (filters.stockStatus) {
      const status = STOCK_STATUS_OPTIONS.filter((item) => {
        return item.value === filters.stockStatus;
      }).map((item) => item.label);
      return this.renderLabels('and', status, ' or ');
    }
    return null;
  }

  renderShippingTimes() {
    const { filters } = this.props;
    if (filters.maxShippingTimes.length) {
      const shippingTimeNames = filters.maxShippingTimes.map((time) => {
        return SHIPPING_TIME_OPTIONS.filter((obj) => obj.value === time)[0]
          .label;
      });
      return this.renderLabels('with', shippingTimeNames, ' or ');
    }
    return null;
  }

  renderVendorMinimums() {
    const { filters } = this.props;
    if (filters.vendorIds.length) {
      let collect = [];
      if (filters.vendorIds.filter((id) => id === 'no_min').length) {
        collect.push('no cart minimum required');
        if (filters.vendorIds.length > 1) {
          collect.push('for selected cart minimums');
        }
      } else {
        collect.push('selected cart minimums');
      }
      return this.renderLabels('for', collect, ' or ');
    }
    return null;
  }

  renderLabels(title, labels, separator) {
    return (
      <span className="filter-summary">
        <span className="filter-summary-title">{title}</span>
        {labels.map((label, i) => {
          return (
            <span key={i}>
              {i > 0 ? separator : ' '}
              <span className="filter-summary-label">{label}</span>
            </span>
          );
        })}
      </span>
    );
  }

  renderInLimitedViewingMode() {
    if (!this.props.can_manage_location_catalog) return null;
    if (!this.props.inLimitedViewingMode) return null;
    const diff =
      this.props.totalResults.length - this.props.filteredResults.length;
    return (
      <span className="filter-summary warning">
        <span className="filter-summary-title">Restricted Mode: </span>
        <span className="filter-summary-label">
          On ({diff} results filtered)
        </span>
      </span>
    );
  }

  renderRestrictedCatalog() {
    if (!this.props.can_manage_location_catalog) return null;
    if (!this.props.filters.restrictedCatalog) return null;
    if (this.props.inLimitedViewingMode) return null;

    return (
      <span className="filter-summary warning">
        <span className="filter-summary-title">
          Showing a restricted catalog.
        </span>
      </span>
    );
  }

  renderClearFilter() {
    if (
      !this.props.filters.categoryIds.length &&
      !this.props.filters.brandNames.length &&
      !this.props.filters.vendorIds.length &&
      !this.props.filters.maxShippingTimes.length &&
      !this.props.filters.stockStatus
    ) {
      return null;
    }
    return (
      <div>
        <Button size="mini" className="tertiary" onClick={this.clearFilters}>
          Clear all filters
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="filters-summary">
        {this.renderCategories()}
        {this.renderBrands()}
        {this.renderShippingTimes()}
        {this.renderVendorMinimums()}
        {this.renderInLimitedViewingMode()}
        {this.renderRestrictedCatalog()}
        {this.renderStockStatus()}
        {this.renderClearFilter()}
      </div>
    );
  }
}

FiltersSummary.propTypes = {
  filters: PropTypes.shape({
    text: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    brandNames: PropTypes.arrayOf(PropTypes.string),
    vendorIds: PropTypes.arrayOf(PropTypes.any),
    maxShippingTimes: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  actions: PropTypes.shape({
    clearProductFilters: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  productCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  inLimitedViewingMode: PropTypes.bool.isRequired,
  totalResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  filteredResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  can_manage_location_catalog: PropTypes.bool.isRequired,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearProductFilters,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function mapStateToProps(state) {
  return {
    filters: state.productQuery.filters,
    productCategories: state.productCategories.items,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    totalResults: state.productQuery.results,
    can_manage_location_catalog: state.auth.role.can_manage_location_catalog,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productQuery.filters === next.productQuery.filters &&
    prev.productCategories.items === next.productCategories.items &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.productQuery.results === next.productQuery.results &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(FiltersSummary);
