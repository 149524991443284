import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Header } from 'semantic-ui-react';
import {
  updateStagedStipend,
  doSubmitStagedStipend,
  FILTER_IS_SAVE_SUCCESS,
  FILTER_IS_SUBMITTING,
  FILTER_IS_ERROR,
} from '../../ducks/stipends.js';
import 'react-dates/lib/css/_datepicker.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/table.min.css';

const END_DATE_ID = 'stipendExpirationnDate';
const START_DATE_ID = 'stipendStartDate';

export class StipendCreateForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusedDateInput: null,
      startDate: moment(props.startDate).zone('-06:00') || moment(),
      endDate: moment(props.endDate).zone('-06:00') || moment().endOf('month'),
    };
  }

  handleSubmit = () => {
    this.props.actions.doSubmitStagedStipend({
      ...this.props.stipend,
      customer_id: this.props.customer,
    });
  };

  isDisabled = () => {
    return this.props.isRequesting;
  };

  handleFieldChange = (e, { value, name }) => {
    let obj = { ...this.props.stipend };
    obj[name] = value;
    if (name === 'amount') {
      obj[name] = parseInt(value, 10) * 100;
    }
    this.props.actions.updateStagedStipend(obj);
  };

  onFocusChanged = (focusedDateInput) => {
    this.setState((prev) => {
      return {
        focusedDateInput,
        endDate: focusedDateInput
          ? prev.endDate
          : prev.endDate || moment().endOf('month'),
        startDate: focusedDateInput
          ? prev.startDate
          : prev.startDate || moment(),
      };
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    let obj = { ...this.props.stipend };
    obj['starts_at'] = startDate.startOf('day').format('YYYY-MM-DD HH:mm');
    obj['expires_at'] = endDate.endOf('day').format('YYYY-MM-DD HH:mm');

    this.setState(
      Object.assign({}, this.state, {
        startDate,
        endDate,
      })
    );
    this.props.actions.updateStagedStipend(obj);
  };

  isOutsideRange = (d) => {
    if (this.state.focusedDateInput === END_DATE_ID) {
      return d < this.state.startDate;
    }
    return false;
  };

  _centAsDollar = (val = 0) => val / 100;

  render() {
    return (
      <div className="settings-team card">
        <div className="invitation-container">
          <Header as="h3">New Stipend</Header>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Field width={4} className="">
                <Form.Input
                  required
                  label="Name"
                  name="name"
                  value={this.props.stipend.name}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Form.Input
                  required
                  label="Amount"
                  type="number"
                  min={0}
                  icon="dollar"
                  iconPosition="left"
                  name="amount"
                  value={this._centAsDollar(this.props.stipend.amount)}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <DateRangePicker
                  numberOfMonths={1}
                  startDateId={START_DATE_ID}
                  endDateId={END_DATE_ID}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onDatesChange={this.onDatesChange}
                  focusedInput={this.state.focusedDateInput}
                  isOutsideRange={this.isOutsideRange}
                  onFocusChange={this.onFocusChanged}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Button
                type="Submit"
                width={4}
                fluid
                primary
                disabled={this.isDisabled()}
                loading={this.props.isRequesting}>
                Create Stipend
              </Form.Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

StipendCreateForm.propTypes = {
  stipend: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  customer: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaveSuccess: PropTypes.bool.isRequired,
  isSaveError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  stipends: PropTypes.objectOf(PropTypes.object).isRequired,
};

export function mapStateToProps(state) {
  const errorResponses = state.stipends.responses.filter(FILTER_IS_ERROR);
  return {
    customer: state.locations.open.customer_id,
    stipends: state.stipends.items,
    isSubmitting:
      state.stipends.requesting.filter(FILTER_IS_SUBMITTING).length > 0,
    isSaveSuccess:
      state.stipends.responses.filter(FILTER_IS_SAVE_SUCCESS).length > 0,
    isSaveError: errorResponses.length > 0,
    errorMessage: errorResponses.length ? errorResponses[0].message : null,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateStagedStipend,
        doSubmitStagedStipend,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.stipends.requesting === next.stipends.requesting &&
    prev.stipends.responses === next.stipends.responses &&
    prev.stipends.items === next.stipends.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(StipendCreateForm);
