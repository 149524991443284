export function findStaffLocation(staff) {
  if (!staff.current_checkins || !staff.current_checkins.length) return null;
  const checkin = staff.current_checkins.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  })[0];
  return checkin.cleaning.location;
}

export function findStaffCustomer(staff) {
  const location = findStaffLocation(staff);
  if (!location) return null;
  return location.customer;
}
