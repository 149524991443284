import React from 'react';
import PropTypes from 'prop-types';

export default class StepMarker extends React.PureComponent {
  render() {
    return (
      <div
        className="step-marker"
        style={{
          lineHeight: '2.5rem',
          textAlign: 'right',
        }}
      >
        {this.props.stepNumber > 1 && (
          <span className="brand-color-override" style={{ color: '#00a98c' }}>
            &lsaquo;&nbsp;
          </span>
        )}
        {this.props.stepNumber} of {this.props.stepTotal}
        {this.props.stepTotal > this.props.stepNumber && (
          <span className="brand-color-override" style={{ color: '#00a98c' }}>
            &nbsp;&rsaquo;
          </span>
        )}
      </div>
    );
  }
}

StepMarker.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
};

StepMarker.defaultProps = {
  stepTotal: 5,
  stepNumber: 1,
};
