export default function ({ color }) {
  color = color || '#00a98c';
  let svg = `
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="100pt" height="100pt" viewBox="725 900 100 700"
         preserveAspectRatio="xMidYMid meet">
          <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
          </metadata>
          <g transform="translate(0.000000,1539.000000) scale(0.100000,-0.100000)"
          fill="${color}" stroke="#efefef">
            <path stroke-width="150" d="M5950 5430 c-373 -25 -762 -156 -1033 -347 -125 -88 -291 -260 -375
            -387 -352 -534 -362 -1056 -35 -1710 94 -187 269 -461 381 -596 16 -19 65 -78
            108 -130 111 -133 373 -400 539 -548 454 -406 1005 -788 1690 -1172 121 -67
            247 -140 281 -161 69 -44 133 -69 175 -69 42 0 114 27 183 69 33 21 149 86
            256 146 1089 605 1873 1229 2400 1910 296 382 514 843 571 1210 21 130 16 371
            -10 494 -79 374 -338 741 -681 966 -305 200 -602 300 -971 326 -671 47 -1312
            -244 -1674 -760 -31 -44 -60 -83 -65 -86 -5 -3 -23 16 -41 42 -118 174 -317
            372 -489 488 -356 239 -763 345 -1210 315z"/>
          </g>
        </svg>
    `;
  let blob = new Blob([svg], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
}
