import React from 'react';
import PropTypes from 'prop-types';
import RequisitionItem from '../requisitions/item.js';
import { formatDate } from '../../lib/formatters.js';

const groupByDate = (acc, val) => {
  const d = new Date(val.submitted_at).toJSON().slice(0, 10);
  acc[d] = acc[d] || [];
  acc[d].push(val);
  return acc;
};

export class RequisitionsByDate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requisitionsByDate: props.requisitions.reduce(groupByDate, {}),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => {
      return {
        requisitionsByDate: props.requisitions.reduce(groupByDate, {}),
      };
    });
  }

  renderItem = (item) => {
    return <RequisitionItem key={item.id} item={item} />;
  };

  renderDate = (date) => {
    return (
      <div className="requisitions-list-date" id={date + '-orders'} key={date}>
        <h4 style={{ marginBottom: '1em' }}>{formatDate(date)}</h4>
        <div className="requisitions-list">
          {this.state.requisitionsByDate[date].map(this.renderItem)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="requisitions-list">
        {Object.keys(this.state.requisitionsByDate).map(this.renderDate)}
      </div>
    );
  }
}

RequisitionsByDate.propTypes = {
  requisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default RequisitionsByDate;
