import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Loader from '../loader.js';
import {
  openCleaningReport,
  doGetCleanings,
} from '../../actions/cleaning-actions.js';
import { formatDate, formatTime } from '../../lib/formatters.js';
import { FILTER_CLEANINGS_PAST } from '../../strings.js';
import './reports-list.css';

export class CleaningReportsList extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('scroll', this.debounceScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.debounceScroll);
    if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout);
  }

  debounceScroll = () => {
    if (this.debouncingScroll) return;
    this.debouncingScroll = true;
    this.debounceTimeout = setTimeout(() => {
      this.debouncingScroll = false;
      this.onScroll();
    }, 100);
  };

  onScroll = () => {
    let windowBottom = window.scrollY + window.innerHeight;
    let containerBottom =
      this.refs.container.offsetTop + this.refs.container.clientHeight;
    if (containerBottom - windowBottom > 100) return;
    if (
      !this.props.meta.next ||
      this.props.cleanings.length >= this.props.meta.total ||
      this.props.isRequesting
    )
      return;
    this.props.actions.doGetCleanings(this.props.meta.next);
  };

  _onClickItem = (item) => {
    this.props.actions.openCleaningReport(item);
  };

  renderItem = (item) => {
    return (
      <div className="cleaning-reports-list-item" key={item.id}>
        <Link
          to={`/office-attendant/shift-reports/${item.id}`}
          className={classnames('', {
            primary: item.id === this.props.open.id,
            'brand-border-color-override': item.id === this.props.open.id,
          })}>
          {formatDate(item.start_datetime)}
          <br />
          {formatTime(item.start_datetime)}
        </Link>
      </div>
    );
  };

  render() {
    return (
      <div className="cleaning-reports-list" ref="container">
        <div className="cleaning-reports-list-header">
          <h4>Shift Reports</h4>
        </div>
        {this.props.cleanings.map(this.renderItem)}
        {this.props.isRequesting && <Loader />}
      </div>
    );
  }
}

CleaningReportsList.propTypes = {
  open: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  cleanings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_datetime: PropTypes.string.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    openCleaningReport: PropTypes.func.isRequired,
    doGetCleanings: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    next: PropTypes.string,
  }),
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    open: state.cleanings.open,
    meta: state.cleanings.meta,
    isRequesting: state.cleanings.isRequesting,
    cleanings: state.cleanings.items
      .filter(FILTER_CLEANINGS_PAST)
      .sort((a, b) => (a.start_datetime < b.start_datetime ? 1 : -1)),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningReport,
        doGetCleanings,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.cleanings.isRequesting === next.cleanings.isRequesting &&
    prev.cleanings.items === next.cleanings.items &&
    prev.cleanings.meta === next.cleanings.meta &&
    prev.cleanings.open === next.cleanings.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CleaningReportsList);
