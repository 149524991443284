import React, { useState, useEffect } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Input, Segment } from 'semantic-ui-react';
import {
  doGetRequisitionQuery,
  resetRequisitionQuery,
} from '../../ducks/requisition-query.js';
import useDebounce from '../../hooks/use-debounce.js';

const RequisitionSearchBar = (props) => {
  const [queryText, setQueryText] = useState('');
  const debouncedSearchTerm = useDebounce(queryText, 500);
  const { actions, onClearSearch } = props;

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.trim().length > 2) {
      actions.doGetRequisitionQuery({
        query: debouncedSearchTerm,
      });
    }
    if (!debouncedSearchTerm.trim().length) {
      actions.resetRequisitionQuery();
      onClearSearch && onClearSearch();
    }
  }, [debouncedSearchTerm, actions, onClearSearch]);

  const onClear = () => {
    setQueryText('');
    onClearSearch && onClearSearch();
  };

  return (
    <Segment basic style={{ display: 'flex' }}>
      <Input
        style={{ flex: 1 }}
        icon="search"
        onChange={(e, { value }) => setQueryText(value)}
        value={queryText}
        placeholder="Search all past orders"
      />
      <Button
        style={{
          marginLeft: '1rem',
          marginRight: 0,
        }}
        secondary
        disabled={!queryText.length}
        onClick={onClear}
      >
        Clear <span className="hide-mobile">Search</span>
      </Button>
    </Segment>
  );
};

RequisitionSearchBar.propTypes = {
  debounce: PropTypes.number,
  onClearSearch: PropTypes.func,
};

RequisitionSearchBar.defaultProps = {
  debounce: 500,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetRequisitionQuery,
        resetRequisitionQuery,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(RequisitionSearchBar);
