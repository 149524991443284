import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import ModalProductSimilars from './modal-product-similars.js';
import InputQuantity from '../forms/input-quantity.js';
import SuggestedReplacements from '../products/suggested-replacements.js';
import { formatTitleCase } from '../../lib/formatters.js';
import {
  submitProductRequisition,
  submitUpdateProductRequisition,
  submitDeleteProductRequisition,
  editAddProductRequistion,
} from '../../actions/requisition-actions.js';
import {
  showProductModal,
  closeProductModal,
  doGetLocationCachedProduct,
} from '../../actions/product-actions.js';
import { trackInterfaceOpenProductComparable } from '../../lib/analytics.js';
import { PRODUCT_IS_IN_STOCK } from '../../flags.js';
import { nextSourceOccurrences } from '../../helpers/scheduled-requisition-helpers.js';
import './modal-product-req-form.css';

export class ModalProductReqForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.orderQuantity(props.product_requisition),
      hasAddedToCart: false,
    };
  }

  componentDidMount() {
    if (!this.props.product.comparables) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.openLocation.id,
        product: this.props.product.id,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let hasChangedProduct = nextProps.product.id !== this.props.product.id;
    this.setState((prevState) => {
      return {
        quantity: hasChangedProduct
          ? this.orderQuantity(nextProps.product_requisition)
          : prevState.quantity,
        hasAddedToCart: hasChangedProduct ? false : prevState.hasAddedToCart,
      };
    });
  }

  componentDidUpdate(prevProps) {
    const hasChangedProduct = prevProps.product.id !== this.props.product.id;
    if (hasChangedProduct && !this.props.product.comparables) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.openLocation.id,
        product: this.props.product.id,
      });
    }
  }

  isInCart = () => {
    return !!this.props.product_requisition;
  };

  orderQuantity = (product_requisition) => {
    return (product_requisition || { quantity: 1 }).quantity;
  };

  changedCartQuantity = () => {
    if (this.isInCart()) {
      return this.state.quantity !== this.props.product_requisition.quantity;
    } else {
      return this.state.quantity > 0;
    }
  };

  formatButtonText = () => {
    let collector = 'Cart';
    if (this.props.isEditingOrder) {
      collector = 'This Order';
    }
    const oos = formatTitleCase('Currently out of stock');
    if (this.isInCart() && this.state.quantity > 0) {
      if (this.props.outOfStock) {
        return oos;
      }
      if (this.changedCartQuantity()) {
        return `Update to ${this.state.quantity.toLocaleString()} in ${collector}`;
      }
      if (this.isInCart()) {
        return `Already in ${collector}`;
      }
    }
    if (this.isInCart() && this.state.quantity === 0) {
      if (this.props.outOfStock) {
        return oos;
      }
      if (this.changedCartQuantity()) {
        return `Remove From ${collector}`;
      }
    }
    if (this.props.outOfStock) {
      return oos;
    }
    return `Add to ${collector}`;
  };

  isDisabled = () => {
    if (this.changedCartQuantity()) {
      if (!PRODUCT_IS_IN_STOCK(this.props.product)) {
        return true;
      }
      return false;
    }
    return true;
  };

  handleChangeQuantity = (quantity) => {
    this.setState(() => {
      return {
        quantity: Math.max(quantity, 0),
      };
    });
  };

  shouldCreateProductRequisition = () => {
    return !this.isInCart() && this.state.quantity > 0;
  };

  shouldUpdateProductRequisition = () => {
    return this.isInCart() && this.changedCartQuantity();
  };

  shouldDeleteProductRequisition = () => {
    return this.isInCart() && this.state.quantity === 0;
  };

  handleAddToCart = () => {
    if (this.shouldDeleteProductRequisition()) {
      this.props.actions.submitDeleteProductRequisition(
        this.props.product_requisition
      );
      return;
    }
    if (this.shouldUpdateProductRequisition()) {
      this.props.actions.submitUpdateProductRequisition({
        quantity: this.state.quantity,
        id: this.props.product_requisition.id,
        product_id: this.props.product.id,
        requisition_id: this.props.requisition.id,
      });
      this.setState(() => {
        return { hasAddedToCart: true };
      });
      return;
    }
    if (this.shouldCreateProductRequisition()) {
      if (this.props.isEditingOrder) {
        this.props.actions.editAddProductRequistion({
          quantity: this.state.quantity,
          price: this.props.product.price,
          product: this.props.product,
          product_id: this.props.product.id,
          requisition_id: this.props.requisition.id,
          tax: 0,
        });
      } else {
        this.props.actions.submitProductRequisition({
          quantity: this.state.quantity,
          price: this.props.product.price,
          product_id: this.props.product.id,
          requisition_id: this.props.requisition.id,
        });
      }
      this.setState(() => {
        return { hasAddedToCart: true };
      });
      return;
    }
  };

  handleClickComparable = (comparable, e) => {
    e.preventDefault();
    this.props.actions.showProductModal(comparable);
    trackInterfaceOpenProductComparable(comparable);
  };

  render() {
    const scheduledCount = this.props.scheduled_requisition_sources.length;
    if (
      this.props.outOfStock &&
      this.props.product.comparables &&
      this.props.product.comparables.length
    ) {
      return (
        <SuggestedReplacements
          products={this.props.product.comparables}
          message="Here are some comparable products for substitution."
          isRequesting={this.props.isRequesting}
          horizontal={this.props.horizontal}
          handleClickComparable={this.handleClickComparable}
        />
      );
    }
    if (
      this.props.outOfStock &&
      (!this.props.product.comparables ||
        !this.props.product.comparables.length) &&
      this.props.product.size_variants &&
      this.props.product.size_variants.length
    ) {
      return (
        <SuggestedReplacements
          products={this.props.product.size_variants}
          message="Here are some different sizes of the same product."
          isRequesting={this.props.isRequesting}
          horizontal={this.props.horizontal}
          handleClickComparable={this.handleClickComparable}
        />
      );
    }

    return (
      <div className="product-cart-action">
        <div className="product-cart-action-inputs">
          <div className="product-cart-quantity">
            <InputQuantity
              min={0}
              value={this.state.quantity}
              label={
                this.props.isEditingOrder ? 'Add to This Order' : 'Add to Cart'
              }
              onChange={this.handleChangeQuantity}
            />
          </div>
        </div>
        <div className="product-cart-action-controls">
          <Button
            primary
            loading={
              this.props.isUpdating || this.props.isCreating ? true : false
            }
            disabled={this.isDisabled()}
            onClick={this.handleAddToCart}
          >
            {this.formatButtonText()}
          </Button>
        </div>
        {scheduledCount === 1 && (
          <div className="product-cart-action-legend gray-text">
            Currently in {scheduledCount.toLocaleString()} scheduled order.
          </div>
        )}
        {scheduledCount > 1 && (
          <div className="product-cart-action-legend gray-text">
            Currently in {scheduledCount.toLocaleString()} scheduled orders.
          </div>
        )}
        {this.state.hasAddedToCart && (
          <ModalProductSimilars
            limit={3}
            horizontal
            product={this.props.product}
          />
        )}
      </div>
    );
  }
}

ModalProductReqForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    unit_purchase: PropTypes.string,
    stock_per_purchase: PropTypes.number,
    unit_stock: PropTypes.string,
    brand: PropTypes.string,
    vendors: PropTypes.array,
  }).isRequired,
  product_requisition: PropTypes.shape({
    id: PropTypes.number,
    quantity: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    requisition_id: PropTypes.number.isRequired,
  }),
  scheduled_requisition_sources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      scheduled_product_requisitions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
          product_id: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    submitProductRequisition: PropTypes.func.isRequired,
    submitUpdateProductRequisition: PropTypes.func.isRequired,
    submitDeleteProductRequisition: PropTypes.func.isRequired,
    closeProductModal: PropTypes.func.isRequired,
    editAddProductRequistion: PropTypes.func.isRequired,
  }).isRequired,
  isEditingOrder: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  outOfStock: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  const isEditingOrder = state.requisitions.editing.length > 0;
  const requisition = isEditingOrder
    ? state.requisitions.editing[0]
    : state.carts.open;
  const outOfStock = !PRODUCT_IS_IN_STOCK(props.product);
  const isCreating = !!state.requisitions.creating.filter(
    (pr) => pr.product_id === props.product.id
  ).length;
  const isUpdating = !!state.requisitions.updating.filter(
    (pr) => pr.product_id === props.product.id
  ).length;
  return {
    requisition,
    isEditingOrder,
    isCreating,
    isUpdating,
    isRequesting:
      state.products.requesting.filter((p) => p.id === props.product.id)
        .length > 0 || state.scheduledRequisitions.requesting.length > 0,
    openLocation: state.locations.open,
    outOfStock,
    product_requisition: (requisition.product_requisitions || []).filter(
      (pr) => pr.product_id === props.product.id
    )[0],
    scheduled_requisition_sources: nextSourceOccurrences(
      state.scheduledRequisitions.items
    ).filter((sr) => {
      return (
        sr.scheduled_product_requisitions.filter(
          (spr) => spr.product_id === props.product.id
        ).length > 0
      );
    }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitProductRequisition,
        submitUpdateProductRequisition,
        submitDeleteProductRequisition,
        showProductModal,
        closeProductModal,
        editAddProductRequistion,
        doGetLocationCachedProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.editing === next.requisitions.editing &&
    prev.carts.open === next.carts.open &&
    prev.products.open === next.products.open &&
    prev.requisitions.editing === next.requisitions.editing &&
    prev.locations.open === next.locations.open &&
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items &&
    prev.requisitions.updating === next.requisitions.updating &&
    prev.requisitions.creating === next.requisitions.creating
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductReqForm);
