import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import { signOut } from '../actions/auth-actions.js';
import { trackSignOutSuccess } from '../lib/analytics.js';

export class SignOutRoute extends React.Component {
  componentDidMount() {
    this.props.actions.signOut();
    trackSignOutSuccess(this.props.auth);
    window.totango.track('User signed out.', 'Settings');
    browserHistory.push('/');
  }

  render() {
    return <div name="SignOutRoute" />;
  }
}

SignOutRoute.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ signOut }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOutRoute);
