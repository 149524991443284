import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-attendant.js';
import SubNavSuppliesItemsTitle from '../components/nav/subnav-supplies-items-title.js';
import SearchResults from '../components/products/search-results.js';
import CartFlex from '../components/requisitions/cart-flex.js';
import setTitle from '../decorators/set-title.js';
import ProductFilters from '../components/filters/product-filters.js';
import FiltersSummary from '../components/filters/filters-summary.js';
import ProductSort from '../components/sorting/product-sort.js';
import {
  setProductQuery,
  setProductSort,
  clearProductQueryResults,
  doGetLocationProductQueryResults,
} from '../actions/product-query-actions';
import { emptyFilters, emptySort } from '../reducers/product-query-reducer.js';
import { searchResultSummary } from '../lib/formatters.js';
import * as queryParser from '../lib/query-parser.js';
import { SORT_OPTIONS } from '../strings.js';
import browserHistory from '../lib/history.js';
import {
  filterInLimitedCatalog,
  filterProductsByParentCategories,
} from '../helpers/product-helpers.js';
import { mapLocationParentCategoriesIds } from '../helpers/product-category-helpers.js';

export class CatalogSearch extends React.PureComponent {
  componentDidMount() {
    this.parseQueryFromUrl('popstate');

    if (
      !this.props.isRequesting &&
      !this.props.query.results.length &&
      this.props.openLocation.vendors.length
    ) {
      this.props.actions.doGetLocationProductQueryResults();
    }

    this.backListener = browserHistory.listen((location) => {
      if (
        location.action === 'POP' &&
        location.pathname.match('catalog-search')
      ) {
        this.parseQueryFromUrl('popstate');
        this.props.actions.doGetLocationProductQueryResults();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.openLocation !== this.props.openLocation &&
      this.props.openLocation.vendors
    ) {
      return this.props.actions.doGetLocationProductQueryResults();
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProductQueryResults();
    this.backListener();
  }

  getTitle = () => {
    const { query, isRequesting } = this.props;
    let text = this.props.brandName || query.filters.text;
    return searchResultSummary(text, query, isRequesting);
  };

  parseQueryFromUrl = (__via = null) => {
    const query = queryParser.parse(this.props.location.search);

    let text = query.q || emptyFilters.text;
    let categoryIds = query.category
      ? query.category.split(',').map((cat) => parseInt(cat, 10)) || []
      : [];
    let brandNames = query.brand
      ? query.brand.split(',')
      : emptyFilters.brandNames;
    let maxShippingTimes = query.shipping
      ? query.shipping.split(',').map((id) => parseInt(id, 10)) ||
        emptyFilters.maxShippingTimes
      : emptyFilters.maxShippingTimes;
    let vendorIds = query.cart_minimum
      ? query.cart_minimum
          .split(',')
          .map((id) => parseInt(id, 10) || 'no_min') || emptyFilters.vendorIds
      : emptyFilters.vendorIds;
    let brandId = parseInt(query.brand_id, 10);
    let stockStatus = query.stock_status;

    this.props.actions.setProductQuery({
      filters: {
        text,
        categoryIds,
        brandNames,
        maxShippingTimes,
        vendorIds,
        brandId,
        stockStatus,
      },
      __via,
    });

    let sortBy = query.sortBy;
    let descending = query.sortDesc || emptySort.descending;
    if (descending === '1') {
      descending = true;
    } else if (descending === '0') {
      descending = false;
    } else if (!descending.length && sortBy && sortBy.length > 0) {
      const foundOptions = SORT_OPTIONS.filter(
        (option) => option.label === sortBy
      );
      if (foundOptions.length > 0) {
        descending = foundOptions[0].defaultDescending;
      }
    }
    this.props.actions.setProductSort({
      attribute: sortBy || emptySort.attribute,
      descending: descending,
      __via,
    });
  };

  render() {
    return (
      <div>
        <AuthCheck />
        <NavBar />
        <ProductFilters />
        <div className="variable-width-column flex-wrapper officeluv-category-container child-list-and-content-desktop">
          <div className="product-search-results flex-wrapper officeluv-category-container">
            <div className="catalog-box subset-box flex-item">
              <div style={{ marginBottom: '1.5rem' }}>
                <SubNavSuppliesItemsTitle title={this.getTitle()} />
                <FiltersSummary filteredResults={this.props.query.results} />
              </div>
              {this.props.query.results.length > 0 && <ProductSort />}
              <SearchResults paginate />
            </div>
          </div>
          <CartFlex />
        </div>
      </div>
    );
  }
}

const CatalogSearchRoute = setTitle((props) => {
  const queryParams = queryParser.parse(props.location.search);
  return (queryParams.q || 'All Products') + ', Catalog Results';
})(CatalogSearch);

CatalogSearchRoute.propTypes = {
  brandName: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  query: PropTypes.shape({
    isRequesting: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    setProductSort: PropTypes.func.isRequired,
    clearProductQueryResults: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const inLimitedViewingMode = state.application.inLimitedViewingMode;
  const in_catalog = state.products.in_catalog;

  const locationParentCategories = mapLocationParentCategoriesIds(
    state.locationParentProductCategories.items
  );

  const results = filterProductsByParentCategories(
    state.productQuery.results,
    locationParentCategories
  );

  const query = {
    ...state.productQuery,
    results: inLimitedViewingMode
      ? filterInLimitedCatalog(results, in_catalog)
      : results,
  };
  const promotedBrands = state.promotedBrands.items.filter(
    (brand) => brand.id === query.filters.brandId
  );
  const brandName = promotedBrands.length ? promotedBrands[0].name : null;
  return {
    brandName,
    openLocation: state.locations.open,
    query: query,
    isRequesting:
      state.productQuery.isRequesting || state.locations.requesting.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        setProductSort,
        clearProductQueryResults,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.locations.requesting === next.locations.requesting &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items &&
    prev.productQuery === next.productQuery &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.products.in_catalog === next.products.in_catalog &&
    prev.promotedBrands.items === next.promotedBrands.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CatalogSearchRoute);
