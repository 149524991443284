import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Segment, Checkbox } from 'semantic-ui-react';
import CreditCardInformation from '../credit-card-information.js';
import { submitLocationStripeExternalAccount } from '../../actions/locations-actions.js';
import {
  submitEmployeeStripeExternalAccount,
  doGetEmployeeStripeAccount,
} from '../../actions/employee-actions.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';

export class PurchasableForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      useShippingAddress: false,
      editing: false,
    };
  }

  componentDidMount() {
    if ((this.props.employee.external_accounts || {}).stripe) {
      return;
    }
    this.props.actions.doGetEmployeeStripeAccount(this.props.employee);
  }

  handleToken = (payment_method_id) => {
    let data = {
      id: this.props.employee.id,
      stripe: {
        payment_method_id,
      },
    };
    this.props.actions.submitEmployeeStripeExternalAccount(data, {
      method: this.props.employee.stripe_customer_id ? 'PATCH' : 'POST',
      confirm: this.state.editing,
    });
    this.setState({
      editing: false,
      useShippingAddress: false,
    });
  };

  handleClickUseShippingAddress = (val) => {
    this.setState(() => {
      return {
        useShippingAddress: val,
      };
    });
  };

  handleClickEdit = (e) => {
    e.preventDefault();
    this.setState((prev) => {
      return {
        editing: !prev.editing,
        useShippingAddress: false,
      };
    });
  };

  renderCardInfo() {
    if (!this.props.employee.external_accounts) return;
    if (!this.props.employee.external_accounts.stripe) return;
    if (!this.props.employee.external_accounts.stripe.payment_method) return;

    const attributes =
      this.props.employee.external_accounts.stripe.payment_method;
    return (
      <span>
        <Icon name={`cc ${attributes.card.brand.toLowerCase()}`} />
        {attributes.card.brand} ending in {attributes.card.last4}
      </span>
    );
  }

  render() {
    const { hasPaymentMethod, requiresPayment } = this.props;
    return (
      <div className={`purchasable-form ${this.props.className}`}>
        <Header as="h3">
          {this.props.header}
          <Header.Subheader>
            {hasPaymentMethod && this.props.subheader}
            {!hasPaymentMethod && requiresPayment && this.props.paymentPrompt}
          </Header.Subheader>
          {hasPaymentMethod && requiresPayment && (
            <Header.Subheader>
              {this.renderCardInfo()}
              {this.props.allowEdit &&
                !this.state.editing &&
                hasPaymentMethod &&
                requiresPayment && (
                  <Button
                    className="tertiary"
                    role="button"
                    onClick={this.handleClickEdit}
                    style={{ marginLeft: '1rem' }}>
                    Edit
                  </Button>
                )}
            </Header.Subheader>
          )}
          {this.props.allowUseShippingAddress &&
            (!hasPaymentMethod || this.state.editing) &&
            requiresPayment && (
              <Segment clearing basic style={{ padding: 0 }}>
                <Checkbox
                  className="tertiary"
                  onChange={(e, data) =>
                    this.handleClickUseShippingAddress(data.checked)
                  }
                  checked={this.state.useShippingAddress}
                  label={{ children: 'Use shipping address' }}
                />
              </Segment>
            )}
        </Header>
        {(!hasPaymentMethod || this.state.editing) && requiresPayment && (
          <CreditCardInformation
            key={this.state.useShippingAddress}
            renderCancelButton={this.state.editing}
            onCancel={this.handleClickEdit}
            firstName={
              this.state.useShippingAddress
                ? this.props.shippingAddress.firstName
                : undefined
            }
            lastName={
              this.state.useShippingAddress
                ? this.props.shippingAddress.lastName
                : undefined
            }
            address1={
              this.state.useShippingAddress
                ? this.props.shippingAddress.address1
                : undefined
            }
            address2={
              this.state.useShippingAddress
                ? this.props.shippingAddress.address2
                : undefined
            }
            postalCode={
              this.state.useShippingAddress
                ? this.props.shippingAddress.postalCode
                : undefined
            }
            city={
              this.state.useShippingAddress
                ? this.props.shippingAddress.city
                : undefined
            }
            state={
              this.state.useShippingAddress
                ? this.props.shippingAddress.state
                : undefined
            }
            country={
              this.state.useShippingAddress
                ? this.props.shippingAddress.country
                : undefined
            }
            onToken={this.handleToken}
            loading={this.props.isRequesting}
          />
        )}
      </div>
    );
  }
}

PurchasableForm.propTypes = {
  actions: PropTypes.shape({
    submitEmployeeStripeExternalAccount: PropTypes.func.isRequired,
    submitLocationStripeExternalAccount: PropTypes.func.isRequired,
    doGetEmployeeStripeAccount: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string.isRequired,
  isRequesting: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stripe_customer_id: PropTypes.string,
    quickbooks_customer_id: PropTypes.string,
  }).isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  paymentPrompt: PropTypes.string.isRequired,
  hasPaymentMethod: PropTypes.string.isRequired,
  requiresPayment: PropTypes.bool.isRequired,
  allowUseShippingAddress: PropTypes.bool.isRequired,
  shippingAddress: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

PurchasableForm.defaultProps = {
  className: '',
  isRequesting: false,
  header: 'Payment',
  subheader: 'Charged to office',
  paymentPrompt: 'Information required:',
  allowUseShippingAddress: false,
  employee: {
    id: 0,
  },
  requisition: {
    requester_purchase_price: 0,
  },
  requiresPayment: false,
  shippingAddress: {},
};

function mapStateToProps(state) {
  const employee = state.employees.user;
  return {
    employee,
    isRequesting:
      state.locations.requesting.length > 0 ||
      state.employees.updating.length > 0,
    hasPaymentMethod:
      employee.external_accounts &&
      employee.external_accounts.stripe &&
      employee.external_accounts.stripe.payment_method &&
      employee.external_accounts.stripe.payment_method.card.brand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationStripeExternalAccount,
        submitEmployeeStripeExternalAccount,
        doGetEmployeeStripeAccount,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.employees.user === next.employees.user &&
    prev.locations.requesting === next.locations.requesting &&
    prev.employees.updating === next.employees.updating
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(PurchasableForm);
