import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import 'semantic-ui-css/components/menu.min.css';
import './product-budget-code-link.css';

export default class ProductBudgetCodeLinks extends React.PureComponent {
  render() {
    return (
      <Menu.Item>
        <p className="budget-name bold-text">{this.props.budgetCode.name}</p>
        {this.props.budgetCode.itemCount && (
          <p className="budget-code">
            {this.props.budgetCode.itemCount.toLocaleString()} Items Ordered
          </p>
        )}
        <Link
          to={`/insights/budget-codes/${this.props.budgetCode.id}`}
          className="primary"
          onClick={this.props.handleRedirect.bind(this, this.props.budgetCode)}
        >
          See More &raquo;
        </Link>
      </Menu.Item>
    );
  }
}

ProductBudgetCodeLinks.propTypes = {
  budgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    itemCount: PropTypes.number,
  }).isRequired,
  handleRedirect: PropTypes.func.isRequired,
};
