import React from 'react';
import PropTypes from 'prop-types';
import { List, Loader, Message, Table } from 'semantic-ui-react';
import SimilarRow from '../products/similar-row.js';
import SimilarListItem from '../products/similar-list-item.js';

export class SuggestedReplacements extends React.PureComponent {
  render() {
    return (
      <div className="product-similars-action">
        <Message className="light">
          <Message.Header>
            This product is currently out of stock.
          </Message.Header>
          <p>{this.props.message}</p>
        </Message>
        {this.props.isRequesting && (
          <Loader active inline="centered">
            Loading Recommendations
          </Loader>
        )}
        {!this.props.horizontal && (
          <Table basic>
            <Table.Body>
              {this.props.products.slice(0, 10).map((product) => (
                <SimilarRow
                  product={product}
                  onClick={this.props.handleClickComparable.bind(this, product)}
                />
              ))}
            </Table.Body>
          </Table>
        )}
        {this.props.horizontal && (
          <List horizontal>
            {this.props.products.slice(0, 10).map((product) => (
              <SimilarListItem
                product={product}
                onClick={this.props.handleClickComparable.bind(this, product)}
              />
            ))}
          </List>
        )}
      </div>
    );
  }
}

SuggestedReplacements.propTypes = {
  isRequesting: PropTypes.bool.isRequired,
  horizontal: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  handleClickComparable: PropTypes.func.isRequired,
};

SuggestedReplacements.defaultProps = {
  message: 'Here are some products for substitution.',
};

export default SuggestedReplacements;
