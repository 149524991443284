import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import {
  submitProductRequisition,
  submitUpdateProductRequisition,
} from '../../actions/requisition-actions.js';
import { formatStockStatus, formatTitleCase } from '../../lib/formatters.js';
import { PRODUCT_IS_IN_STOCK } from '../../flags.js';
import { trackInterfaceAddToCart } from '../../lib/analytics.js';
import './supply-item.css';
import './supply-item-in-cart.css';

export class SupplyItemAddToCart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addToCartQuantity:
        props.addToCartQuantity ||
        this.calculateAddToCartQuantity(
          props.product_requisition,
          props.item.min,
          props.item.quantity
        ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        addToCartQuantity:
          nextProps.addToCartQuantity ||
          this.calculateAddToCartQuantity(
            nextProps.product_requisition,
            nextProps.item.min,
            nextProps.item.quantity
          ),
      })
    );
  }

  calculateAddToCartQuantity = (product_requisition, min, quantity) => {
    if (product_requisition) {
      return 0;
    }
    const diff = min - quantity;
    return Math.max(diff, 0);
  };

  handleAddToCart = () => {
    if (this.props.product_requisition) {
      let quantity =
        this.state.addToCartQuantity + this.props.product_requisition.quantity;
      this.props.actions.submitUpdateProductRequisition({
        id: this.props.product_requisition.id,
        requisition_id: this.props.product_requisition.requisition_id,
        quantity,
      });
    } else {
      this.props.actions.submitProductRequisition({
        requisition_id: this.props.cart.id,
        product_id: this.props.item.product_id,
        quantity: this.state.addToCartQuantity,
        price: this.props.item.product.price,
      });
    }
    trackInterfaceAddToCart({ source: 'supply levels item' });
  };

  renderButtonText = (oos) => {
    if (oos) {
      return formatTitleCase(formatStockStatus(this.props.item.product));
    }
    if (this.state.addToCartQuantity > 0) {
      return `Add ${this.state.addToCartQuantity.toLocaleString()} to Cart`;
    }
    return 'Add to Cart';
  };

  render() {
    const oos = !PRODUCT_IS_IN_STOCK(this.props.item.product);
    return (
      <div className="supply-item-in-cart flex-wrapper">
        <div className="item-add-to-cart-button">
          <Button
            primary={!oos}
            size="mini"
            className={'add-to-cart ' + (oos ? 'tertiary' : '')}
            disabled={oos}
            onClick={this.handleAddToCart}
          >
            <span className="add-to-cart-button-label">
              {this.renderButtonText(oos)}
            </span>
          </Button>
        </div>
        {this.props.product_requisition && (
          <div className="in-cart-quantity-container">
            <span className="in-cart-quantity bold-text">
              {this.props.product_requisition.quantity.toLocaleString()}{' '}
              currently in cart
            </span>
          </div>
        )}
      </div>
    );
  }
}

SupplyItemAddToCart.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_list_id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      sku_brand: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  addToCartQuantity: PropTypes.number,
  product_requsition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  actions: PropTypes.shape({
    submitProductRequisition: PropTypes.func.isRequired,
    submitUpdateProductRequisition: PropTypes.func.isRequired,
  }).isRequired,
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    product_requisition: (state.carts.open.product_requisitions || []).filter(
      (pr) => pr.product_id === props.item.product_id
    )[0],
    cart: state.carts.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitProductRequisition,
        submitUpdateProductRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.carts.open === next.carts.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SupplyItemAddToCart);
