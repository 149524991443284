import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import UnifiedScheduledRequisitionForm from '../components/scheduledRequisitions/unified-scheduled-requisition-form.js';
import {
  doFindScheduledRequisition,
  closeScheduledRequisition,
  stageScheduledRequisition,
} from '../actions/scheduled-requisition-actions.js';
import { PRODUCT_MODAL_TABS } from '../strings.js';
import {
  setInitialProductModalTab,
  unsetInitialProductModalTab,
} from '../actions/product-actions.js';
export class OrdersScheduledUnified extends React.PureComponent {
  componentDidMount() {
    if (window.location.pathname.match(/new/)) {
      this.props.actions.stageScheduledRequisition();
      return;
    }
    if (!this.props.match.params.id) return;

    this.props.actions.doFindScheduledRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
    this.props.actions.setInitialProductModalTab(
      PRODUCT_MODAL_TABS.TAB_SCHEDULED
    );
  }

  componentDidUpdate() {
    if (
      this.props.scheduledRequisition.location_id &&
      this.props.scheduledRequisition.location_id !== this.props.openLocation.id
    ) {
      browserHistory.push('/calendar');
    }
  }

  componentWillUnmount() {
    this.props.actions.unsetInitialProductModalTab();
    this.props.actions.closeScheduledRequisition();
  }

  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <UnifiedScheduledRequisitionForm {...this.props} />
      </div>
    );
  }
}

OrdersScheduledUnified.propTypes = {
  scheduledRequisition: PropTypes.shape({}),
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, props) {
  let scheduledRequisition = state.scheduledRequisitions.open;
  let isRequesting = false;
  if (parseInt(props.match.params.id, 10)) {
    isRequesting =
      !!state.scheduledRequisitions.requesting.filter(
        (sr) => sr.id === parseInt(props.match.params.id, 10)
      ).length > 0;
  }
  const openLocation = state.locations.open;
  const isNewRecord = !!window.location.pathname.match(/new/);
  return {
    scheduledRequisition,
    isRequesting,
    openLocation,
    isNewRecord,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doFindScheduledRequisition,
        closeScheduledRequisition,
        setInitialProductModalTab,
        unsetInitialProductModalTab,
        stageScheduledRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items &&
    prev.scheduledRequisitions.requesting ===
      next.scheduledRequisitions.requesting &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.locations.open === next.locations.open
  );
}

const OrdersScheduledUnifiedRoute = setTitle((props) => {
  if (!props.scheduledRequisition) return 'New Recurring Order';
  return `Your Recurring Order '${props.scheduledRequisition.name}' (#${props.match.params.id})`;
})(OrdersScheduledUnified);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OrdersScheduledUnifiedRoute);
