import * as types from './action-types.js';
import MD5 from 'crypto-js/md5';
import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

export const getExternalVendorInvoices = (data) => ({
  type: types.GET_EXTERNAL_VENDOR_INVOICES,
  data,
});

export const successGetExternalVendorInvoices = (data) => ({
  type: types.SUCCESS_GET_EXTERNAL_VENDOR_INVOICES,
  data,
});

export const errorGetExternalVendorInvoices = (data) => ({
  type: types.ERROR_GET_EXTERNAL_VENDOR_INVOICES,
  data,
});

export const getExternalVendorInvoice = (data) => ({
  type: types.GET_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const successGetExternalVendorInvoice = (data) => ({
  type: types.SUCCESS_GET_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const errorGetExternalVendorInvoice = (data) => ({
  type: types.ERROR_GET_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const createExternalVendorInvoice = (data) => ({
  type: types.CREATE_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const successCreateExternalVendorInvoice = (data) => ({
  type: types.SUCCESS_CREATE_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const errorCreateExternalVendorInvoice = (data) => ({
  type: types.ERROR_CREATE_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const openExternalVendorInvoice = (data) => ({
  type: types.OPEN_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const closeExternalVendorInvoice = (data) => ({
  type: types.CLOSE_EXTERNAL_VENDOR_INVOICE,
  data,
});

export const doGetExternalVendorInvoices = (data = {}, paged) => {
  return (dispatch, getState) => {
    const {
      locations: { open },
    } = getState();
    data._request = data._request || requestID();
    dispatch(getExternalVendorInvoices(data));
    data.location_id = data.location_id || open.id;
    const url =
      paged ||
      `/locations/${data.location_id}/external_vendor_invoices?limit=100`;
    return fetch(`${ADMIN_V1_URL}${url}`, {
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get external vendor invoices',
          });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = data.location_id;
        dispatch(successGetExternalVendorInvoices(body.data));
        if (body.meta && body.meta.next_cursor) {
          dispatch(
            doGetExternalVendorInvoices(
              data,
              `/locations/${data.location_id}/external_vendor_invoices?cursor=${body.meta.next_cursor}&limit=100`
            )
          );
        }
      })
      .catch((error) => {
        dispatch(errorGetExternalVendorInvoices(error));
        trackFetchError(error);
      });
  };
};

export const doGetExternalVendorInvoice = (data) => {
  return (dispatch, getState) => {
    const {
      locations: { open },
    } = getState();
    data.location_id = data.location_id || open.id;
    data._request = data._request || requestID();
    dispatch(getExternalVendorInvoice(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/external_vendor_invoices/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get external vendor invoice ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetExternalVendorInvoice(body));
      })
      .catch((error) => {
        dispatch(errorGetExternalVendorInvoice(error));
        trackFetchError(error);
      });
  };
};

/**
 * Create an attachment record on a ticket
 *
 * @access public
 * @param {Object} external_vendor_invoice with { location_id: Number, vendor_name: String, transaction_date: Date, invoice_number: String }
 * @param {File} file user-selected file
 * @returns {function} thunk
 */
export const doUploadExternalVendorInvoice = (data, file) => {
  let formData = new FormData();
  formData.append('attachments', file);
  Object.keys(data).map((key) => formData.append(key, data[key]));
  data.file = {
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  };
  data._request = data._request || MD5(JSON.stringify(data)).toString();
  return (dispatch, getState) => {
    dispatch(createExternalVendorInvoice(data));
    const {
      locations: { open },
    } = getState();
    return fetch(
      `${ADMIN_V1_URL}/locations/${open.id}/external_vendor_invoices`,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create external vendor invoice',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateExternalVendorInvoice(
            Object.assign(body, {
              _request: data._request,
            })
          )
        );
      })
      .catch((error) => {
        dispatch(errorCreateExternalVendorInvoice(error));
        trackFetchError(error);
      });
  };
};
