import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Form, Table, Checkbox } from 'semantic-ui-react';
import Avatar from '../avatar.js';
import MultiSelect from '@officeluv/react-multi-select';
import {
  doUninviteEmployee,
  submitUpdateEmployee,
  doInviteEmployee,
} from '../../actions/employee-actions.js';
import { formatDate } from '../../lib/formatters.js';
import { displayName } from '../../helpers/employee-helpers.js';
import { buildRoleOptions } from '../../helpers/customer-role-helpers.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/table.min.css';
import './settings-team-row.css';

export class SettingsTeamRow extends React.PureComponent {
  _buildLocationsOptions = () => {
    return this.props.user.locations.map((loc) => {
      return { value: loc.id, label: loc.name };
    });
  };

  isPendingEmployee = () => {
    return (
      !this.props.employee.invitation_accepted_at &&
      new Date(this.props.employee.invitation_sent_at).getTime() <
        new Date().getTime() - 1000 * 60 * 60
    );
  };

  handleChangeAdmin = (e, { value }) => {
    e.preventDefault();
    this.props.actions.submitUpdateEmployee({
      id: this.props.employee.id,
      customer_role_id: value,
    });
  };

  handleResendInvite = (e) => {
    e.preventDefault();
    this.props.actions.doInviteEmployee({
      id: this.props.employee.id,
      email: this.props.employee.email,
    });
  };

  _uninviteEmployee = (loc) => {
    let _isOpenLocation = this.props.location.id === loc.id;
    this.props.actions.doUninviteEmployee({
      id: this.props.employee.id,
      email: this.props.employee.email,
      location: loc,
      _isOpenLocation,
    });
    if (_isOpenLocation) {
      this.props.onRemove(this.props.employee);
    }
  };

  _inviteEmployee = (loc) => {
    this.props.actions.doInviteEmployee({
      id: this.props.employee.id,
      email: this.props.employee.email,
      locations: [loc.id],
      customer_id: loc.customer_id,
    });
  };

  handleAddLocations = (locs) => {
    locs.map((loc) => {
      return this._inviteEmployee(loc);
    });
  };

  handleRemoveLocations = (locs) => {
    locs.map((loc) => {
      return this._uninviteEmployee(loc);
    });
  };

  handleClickRemove = () => {
    if (
      !window.confirm(
        'Do you want to remove this user from all offices you control?'
      )
    ) {
      return;
    }
    let userLocations = this.props.user.locations.map((l) => l.id);
    let employeeLocations = this.props.employee.locations.filter(
      (l) => userLocations.indexOf(l.id) > -1
    );
    this.handleRemoveLocations(employeeLocations);
  };

  _onEmployeeLocationSelect = (selected) => {
    let employeeLocationIds = this.props.employee.locations.map((l) => l.id);
    let userLocationIds = this.props.user.locations.map((l) => l.id);

    let removedLocations = this.props.employee.locations.reduce((acc, loc) => {
      if (userLocationIds.indexOf(loc.id) < 0) {
        return acc;
      }
      if (selected.indexOf(loc.id) < 0) {
        acc.push(loc);
      }
      return acc;
    }, []);
    this.handleRemoveLocations(removedLocations);

    let newLocations = selected.reduce((acc, selectedId) => {
      if (employeeLocationIds.indexOf(selectedId) < 0) {
        acc.push(
          this.props.user.locations.filter((loc) => loc.id === selectedId)[0]
        );
      }
      return acc;
    }, []);
    this.handleAddLocations(newLocations);
  };

  _selectedLocations = () => {
    let employeeLocations = this.props.employee.locations.map((l) => l.id);
    let userLocations = this.props.user.locations.map((l) => l.id);
    return userLocations.filter((loc) => employeeLocations.indexOf(loc) > -1);
  };

  _locationValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return 'Give Access to an Office...';
    }

    if (selected.length === options.length) {
      return 'Has Access to All Offices';
    }

    return `Has Access to ${selected.length} ${
      selected.length > 1 ? 'Offices' : 'Office'
    }`;
  };

  _locationItemRenderer = (props) => {
    const { checked, option, onClick } = props;
    return (
      <span className="item-renderer">
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
        />
        <span>{option.label}</span>
      </span>
    );
  };

  displayAdminData = () => {
    if (!this.props.employee.invitation_accepted_at) {
      return `Invited ${formatDate(this.props.employee.invitation_sent_at)}`;
    }
    return this.props.employee.customer_role.name;
  };

  belongsToDifferentCustomer = () => {
    return !this.props.customerRoles[this.props.employee.customer_role_id];
  };

  userGreeting = () => {
    if (!this.props.employee.invitation_accepted_at) {
      return (
        <p className="italic-text">
          {' '}
          Invited {formatDate(this.props.employee.invitation_sent_at)}{' '}
        </p>
      );
    }
    return (
      <React.Fragment>
        <p className="italic-text"> {this.props.employee.title} </p>
      </React.Fragment>
    );
  };

  render() {
    const selectedLocations = this._selectedLocations();
    const { isEmployeeSelf } = this.props;
    return (
      <Table.Row className="settings-team-row">
        {this.props.can_manage_employees_locations &&
          this.props.can_manage_roles && (
            <Table.Cell width={1}>
              <Checkbox
                onChange={() =>
                  this.props.onCheckboxChange(this.props.employee)
                }
                disabled={this.props.isEmployeeSelf}
                checked={this.props.checkboxChecked}
              />
            </Table.Cell>
          )}
        <Table.Cell width={4}>
          <div className="employee-details-container">
            <div className="avatar-container">
              <Avatar
                item={this.props.employee}
                width={36}
                height={36}
                alt={this.props.employee.email}
              />
            </div>
            <div className="invited-employee-details">
              {this.props.enableDetails && (
                <Link
                  to={`/settings/team/${this.props.employee.id}`}
                  className="bold-text">
                  {displayName(this.props.employee)}
                </Link>
              )}
              {!this.props.enableDetails && (
                <p className="bold-text">{displayName(this.props.employee)}</p>
              )}
              {this.props.can_manage_employees_locations && this.userGreeting()}
              <div className="invited-employee-options">
                {this.props.can_manage_employees_locations &&
                  this.isPendingEmployee() && (
                    <div className="pending-invitation-options">
                      <Button
                        style={{ marginTop: '0.25rem' }}
                        secondary
                        size="mini"
                        compact
                        onClick={this.handleResendInvite}>
                        Resend&nbsp;Invitation
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell width={5}>
          {this.props.can_manage_employees_locations &&
            !isEmployeeSelf &&
            !this.belongsToDifferentCustomer() && (
              <Form.Dropdown
                selection
                fluid
                width={7}
                onChange={this.handleChangeAdmin}
                options={buildRoleOptions(this.props.customerRoles)}
                value={this.props.employee.customer_role.id}
              />
            )}
          {!this.props.can_manage_roles &&
            this.displayAdminData(this.props.employee)}
          {this.belongsToDifferentCustomer() && (
            <p>Belongs to different customer</p>
          )}
          {isEmployeeSelf && <p>{this.props.employee.customer_role.name}</p>}
        </Table.Cell>
        <Table.Cell width={5}>
          {this.props.can_manage_employees_locations && !isEmployeeSelf && (
            <MultiSelect
              valueRenderer={this._locationValueRenderer}
              ItemRenderer={this._locationItemRenderer}
              selected={selectedLocations}
              onSelectedChanged={this._onEmployeeLocationSelect}
              selectAllLabel="All Offices"
              options={this._buildLocationsOptions()}
            />
          )}
          {isEmployeeSelf && (
            <p>
              You have access to {selectedLocations.length} Office
              {selectedLocations.length > 1 ? 's' : ''}
            </p>
          )}
        </Table.Cell>
        <Table.Cell width={1}>
          {this.props.can_manage_employees_locations && !isEmployeeSelf && (
            <Button
              style={{ border: 'none', padding: '0.25rem', boxShadow: 'none' }}
              icon="trash alternate outline"
              compact
              size="tiny"
              basic
              onClick={this.handleClickRemove}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
}

SettingsTeamRow.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    title: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
    customer_role: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      can_manage_employees_locations: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  can_manage_employees_locations: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    doUninviteEmployee: PropTypes.func.isRequired,
    submitUpdateEmployee: PropTypes.func.isRequired,
    doInviteEmployee: PropTypes.func.isRequired,
  }).isRequired,
  customerRoles: PropTypes.object.isRequired,
  enableDetails: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func,
  checkboxChecked: PropTypes.bool,
};

SettingsTeamRow.defaultProps = {
  onRemove: function () {},
  enableDetails: true,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.employees.user,
    location: state.locations.open,
    customerRoles: state.customerRoles.items,
    can_manage_employees_locations:
      state.auth.role.can_manage_employees_locations,
    can_manage_roles: state.auth.role.can_manage_roles,
    isEmployeeSelf: state.employees.user.id === ownProps.employee.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doUninviteEmployee,
        submitUpdateEmployee,
        doInviteEmployee,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.locations.open === next.locations.open &&
    prev.customerRoles.items === next.customerRoles.items &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SettingsTeamRow);
