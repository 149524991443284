import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Button }               from 'semantic-ui-react';
// import RequestItemForm          from './request-item-form.js';
import SiblingCategoryList from './officeluv-sibling-category-list.js';
import CategoryCatalog from './officeluv-category-catalog.js';
import CategoryCatalogSubset from './category-catalog-subset.js';
import FullCatalog from './officeluv-catalog.js';
import CartFlex from '../requisitions/cart-flex.js';
import SearchResultsRequestItem from '../products/search-results-request-item.js';
import './officeluv-category-container.css';

export class OfficeluvCategoryContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRequestItem: false,
    };
  }

  _onClickRequest = () => {
    this.setState(
      Object.assign({}, this.state, {
        showRequestItem: !this.state.showRequestItem,
      })
    );
  };

  _onResetRequest = () => {
    this.setState(
      Object.assign({}, this.state, {
        showRequestItem: false,
      })
    );
  };

  render() {
    return (
      <div className="officeluv-category-container">
        <SiblingCategoryList />

        <div className="request-container">
          <SearchResultsRequestItem />
        </div>

        <div className="variable-width-column">
          {!this.props.openCategory.id && (
            <div className="flex-wrapper officeluv-category-container content-desktop">
              <div className="catalog-box flex-item">
                <FullCatalog />
              </div>
              <CartFlex canSwitchCarts={true} />
            </div>
          )}
          {!!this.props.openCategory.id && (
            <div className="flex-wrapper officeluv-category-container child-list-and-content-desktop">
              {/*!this.props.subset &&
                                <div className='child-categories-box flex-item'>
                                    {!this.state.showRequestItem &&
                                        <div className='closed-category-request'>
                                            <div className='closed-category-request-label'>
                                                Need something else?
                                            </div>
                                            <div className='closed-category-request-toggle'>
                                                <Button secondary size='mini'
                                                    onClick={this._onClickRequest}
                                                >
                                                    Request an Item
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.showRequestItem &&
                                        <RequestItemForm
                                            autoFocus={true}
                                            onReset={this._onResetRequest}/>
                                    }
                                </div>
                            */}
              {!this.props.subset && (
                <div className="catalog-box flex-item">
                  <CategoryCatalog />
                </div>
              )}
              {this.props.subset && (
                <div className="catalog-box subset-box flex-item">
                  <CategoryCatalogSubset subset={this.props.subset} />
                </div>
              )}
              <CartFlex />
            </div>
          )}
        </div>
      </div>
    );
  }
}

OfficeluvCategoryContainer.propTypes = {
  subset: PropTypes.string,
  isRequesting: PropTypes.bool,
  openCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    openCategory: state.productCategories.open,
    isRequesting: !!state.productCategories.requesting.length,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.open === next.productCategories.open &&
    prev.productCategories.requesting === next.productCategories.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeluvCategoryContainer);
