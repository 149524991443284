import { name, version } from '../package.json';
import { dateDayOf } from './lib/formatters.js';

export const ENV = window.location.hostname.match(/localhost|\.local|192\.168/)
  ? 'DEV'
  : window.location.hostname.match(/-staging/)
  ? 'STAG'
  : window.location.hostname.match(/-demo/)
  ? 'DEMO'
  : 'PROD';
export const ENV_STRING = ENV.match(/DEMO|STAG|DEV/)
  ? ENV.match(/STAG|DEV/)
    ? 'staging'
    : 'demo'
  : 'production';
const API_ROOT_URL = 'https://api.officeluv.com';
const API_SUFFIX = ENV.match(/DEMO|STAG|DEV/)
  ? ENV.match(/STAG|DEV/)
    ? '-staging'
    : '-demo'
  : '';
const API_SUBDOMAIN = ENV.match(/DEMO|STAG|DEV/)
  ? ENV.match(/STAG|DEV/)
    ? 'puppies'
    : 'kitties'
  : 'www';
export const AANG_URL = API_ROOT_URL + '/aang' + API_SUFFIX;
export const GOSSIP_PROXY_URL = API_ROOT_URL + '/gossip' + API_SUFFIX;
export const GOSSIP_URL = API_ROOT_URL.replace('api', 'gossip' + API_SUFFIX);
export const ADMIN_V1_URL =
  'https://' + API_SUBDOMAIN + '.officeluv.com/api/v1';
export const ADMIN_URL = 'https://' + API_SUBDOMAIN + '.officeluv.com/api';
export const ADMIN_VI_URL_PASSWORDS = ADMIN_V1_URL + '/passwords';
export const ADMIN_VI_URL_INVITES = ADMIN_V1_URL + '/invites';

export const DEV_EMAIL = 'development@officeluv.com';
export const HELP_EMAIL = ENV.match(/STAG|DEV|DEMO/)
  ? DEV_EMAIL
  : 'customersupport@officeluv.com';
export const LOGROCKET_ENABLED = !!(
  ENV.match(/PROD/) || window.location.search.match(/logrocket=true/)
);
if (ENV.match(/STAG|DEV/)) {
  window.ODEBUG = true;
} else if (!window.location.protocol.match(/https/)) {
  window.location.href =
    'https:' + window.location.href.substring(window.location.protocol.length);
}
export const COMPANY_DOMAIN = 'officeluv.com';
export const APP_DOMAIN = `dashboard.${COMPANY_DOMAIN}`;
export const CLIENT = `officeluv/${name}#v${version}`;

export const AUTH_COOKIE = 'auth';
export const TRANSIENT_AUTH_COOKIE = 'transient_auth';
export const LOCATION_COOKIE = 'location';
export const TIMELINE_VIEW_COOKIE = 'timeline_view';
export const CART_SHOW_COOKIE = 'cart_is_showing';
export const NAV_SHOW_COOKIE = 'nav_is_showing';
export const LAST_APP_COOKIE = 'ol_last_app';
export const STORAGE_USER = 'storage_employees_user';
export const STORAGE_STAFF_USER = 'storage_staffs_user';
export const STORAGE_EMPLOYEES = 'storage_employees';
export const STORAGE_LOCATION = 'storage_locations_open';
export const STORAGE_CUSTOMER = 'storage_customers_open';
export const STORAGE_CLEANINGS = 'storage_cleanings';
export const STORAGE_LOCATION_LIST = 'storage_location_lists_open';
export const STORAGE_REQUISITIONS = 'storage_requisitions';
export const STORAGE_LOCATION_LIST_PRODUCTS = 'storage_location_list_products';
export const PRODUCT_CATEGORY = 'ProductCategory';
export const PRODUCT_CATEGORY_SEE_ALL_RESULTS = 'see_all_results';
export const STORAGE_OPEN_LOCATION = 'storage_open_location';

export const ANALYTICS_ID = 'UA-59450894-1';

// numerical constants
export const MINUTE_MILLIS = 60000;
export const HALF_HOUR_MILLIS = 1800000;
export const HOUR_MILLIS = 3600000;
export const DAY_MILLIS = HOUR_MILLIS * 24;
export const WEEK_MILLIS = DAY_MILLIS * 7;
export const MONTH_MILLIS = DAY_MILLIS * 30;
export const YEAR_MILLIS = DAY_MILLIS * 365;
export const CONFIRMATION_TIMEOUT_MS = 4000;
export const HOVER_CONFIRMATION_TIMEOUT_MS = 1000;
export const AUTH_STATUS_CHECK_INTERVAL_MS = 1000;
export const PASSWORD_LENGTH = 9;
export const MINUTE_INCREMENT = 15; // not to be >=60, that would not make sense and would break the code
export const START_OF_DAY = () => {
  return new Date(Math.floor(new Date().getTime() / DAY_MILLIS) * DAY_MILLIS);
};
export const END_OF_DAY = () => {
  return new Date(
    (Math.floor(new Date().getTime() / DAY_MILLIS) + 1) * DAY_MILLIS
  );
};
export const PAST_LIMIT_DATE = new Date(2017, 8, 1);
export const SEE_ALL_CATEGORY_THRESHOLD = 7;
export const MAX_NUMBER_PRODUCT_SEARCH_DISPLAY = 5;
export const MIN_CHAR_SEARCH_DISPLAY = 2;

export const FILTER_TODAY_MY_SUPPLY_ARRIVALS = (t) => {
  const d = new Date(t.expected_at_end);
  return END_OF_DAY() >= d && d > START_OF_DAY();
};

export const REDUCE_EARLIEST_CREATED_AT = (acc, c) => {
  const time = new Date(c.created_at || new Date());
  return time < acc ? time : acc;
};

export const REDUCE_LATEST_EXPECTED_AT = (acc, c) => {
  const time = new Date(c.expected_at || new Date());
  return time > acc ? time : acc;
};

export const REDUCE_LATEST_CLOCKOUT = (acc, c) => {
  const checkout = new Date(c.checkout_time || new Date());
  return checkout > acc ? checkout : acc;
};

/**
 * Determine if booking request is allowed for date
 *
 * @access public
 * @param {Date|string} datelike
 * @returns {boolean}
 */
export const FILTER_DATES_BOOKABLE = (datelike) => {
  return (
    dateDayOf(datelike).getTime() - DAY_MILLIS * 2 >
    dateDayOf(Date.now()).getTime()
  );
};

export const FILTER_CLEANINGS_FUTURE = (cl) => {
  const now = new Date();
  const endTime = new Date(cl.end_datetime);
  const checkins = cl.checkins || [];
  if (!checkins.length && now > endTime) return false;
  return now < checkins.reduce(REDUCE_LATEST_CLOCKOUT, endTime);
};

export const FILTER_CLEANINGS_PAST = (cl) => {
  const now = new Date();
  const endTime = new Date(cl.end_datetime);
  if (now < endTime) return false;
  if (PAST_LIMIT_DATE > endTime) return false;
  return now > (cl.checkins || []).reduce(REDUCE_LATEST_CLOCKOUT, endTime);
};

export const FILTER_CLEANINGS_ON_DAY_OF = (d) => {
  const dateTime = dateDayOf(d).getTime();
  return (cl) => {
    return dateTime === dateDayOf(cl.start_datetime).getTime();
  };
};

export const FILTER_CLEANINGS_IN_DAY_OF = (d) => {
  const dateTime = dateDayOf(d).getTime();
  return (cl) => {
    return (
      dateTime === dateDayOf(cl.start_datetime).getTime() ||
      dateTime === dateDayOf(cl.end_datetime).getTime()
    );
  };
};

export const FILTER_PRODUCT_CHILD_CATEGORIES = (cats) => {
  return (cats || []).filter((cat) => !cat.parent_id);
};

export const SORT_BY_ID = (a, b) => {
  return a.id > b.id ? 1 : -1;
};

//COUNTRY CODES
export const COUNTRY_CODES = [
  'US',
  'GB',
  'IE',
  'PH',
  'CA',
  'AU',
  'SG',
  'PL',
].map((country) => {
  return {
    value: country,
    text: country,
    key: country,
  };
});

//US STATES
export const US_STATES_OPTIONS = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
].map((us_state) => {
  return {
    value: us_state,
    text: us_state,
    key: us_state,
  };
});

// NUMBER OF EMPLOYEES
export const NUMBER_OF_EMPLOYEES = [
  { label: '1-50', value: 50 },
  { label: '51-100', value: 100 },
  { label: '101-250', value: 250 },
  { label: '251-500', value: 500 },
  { label: '501-1000', value: 1000 },
  { label: '1000+', value: 5000 },
];

// PRIORITY NUMBERS
export const PRIORITY_NUMBERS = [
  { label: 'Critical', value: 1 },
  { label: 'High', value: 0.75 },
  { label: 'Normal', value: 0.5 },
  { label: 'Low', value: 0.25 },
];

// Google Analytics GA events
export const CATEGORY_FUNC = 'JS Functions';

export const VARIABLE_EXEC = 'execution';
export const ACTION_POTENTIAL_APP_UPDATE = 'Potential App Update Delivered';
export const ACTION_FAVORITE_PRODUCT = 'ACTION_FAVORITE_PRODUCT';

// hotkeys
export const HOTKEY_UNDO = 'command+z';
export const HOTKEY_INFO = '?';
export const HOTKEY_SEARCH = ['/', 's'];
export const HOTKEY_RETURN = 'return';

// utility
export const HEADERS_JSON = { 'Content-Type': 'application/json' };
export const REGEX_EMAIL =
  /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/;
export const REGEX_URL = new RegExp(
  // "^" +
  // protocol identifier
  '(?:(?:https?|ftp)://)' +
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host name
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    // domain name
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
    // TLD identifier
    '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
    // TLD may end with dot
    '\\.?' +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?',
  // + "$"
  'g',
  'i'
);

// CODES
export const MODAL_FOCUS_COMMENT = 1001;

// Filter Texts
export const ASSIGNEES_SEARCH_PLACEHOLDER = 'Search your team';
export const TAGS_SEARCH_PLACEHOLDER = 'Search labels';
export const EMPLOYEESS_SEARCH_PLACEHOLDER = 'Search employees';
export const DEPARTMENTS_SEARCH_PLACEHOLDER = 'Search departments';

// Strings
export const EDITED_TEXT = '(edited)';
export const HELP_BUTTON_PLACEHOLDER = 'I have a question...';
export const HELP_BUTTON_TITLE = 'How can we help?';
export const HELP_BUTTON_SUBTEXT = 'Have a question? Ask us here.';

// experiments
export const EXP_DEV_CHECKOUT = 'YNjpjOPuTb29iM71xkRZoQ';

// Navigation
export const OFFICE_DETAILS_TOP_LINK = {
  label: 'Settings',
  path: '/settings/team',
  permissions: ['can_manage_employees_locations'],
};

export const OFFICE_DETAILS_LINKS = {
  '/settings/team': {
    label: 'Users & Permissions',
    permissions: ['can_manage_employees_locations'],
  },
  '/settings/company': {
    label: 'Office Details',
    permissions: ['can_manage_all_requisitions'],
  },
  '/company/invoices': {
    label: 'Billing & Invoices',
    permissions: ['can_manage_billing'],
  },
  '/settings/user': {
    label: 'Profile & Notifications',
  },
  '/settings/stipends': {
    label: 'Stipends',
    permissions: ['can_manage_employees_stipends'],
  },
};

export const USER_LINKS = {
  '/sign-out': { label: 'Sign Out' },
};

export const USER_TOP_LINK = {
  label: 'Settings',
  path: '/settings/user',
};

export const SUPPLY_TOP_LEVEL_LINK = {
  label: 'Marketplace',
  mobileLabel: 'Marketplace',
  path: '/supplies/catalog',
};

export const CONTACT_TOP_LEVEL_LINK = {
  label: 'Contact Us',
  mobileLabel: 'Contact Us',
  path: '/contact-us',
};

export const BUDGET_TOP_LEVEL_LINK = {
  label: 'Reporting',
  mobileLabel: 'Insights',
  path: '/insights/budgets',
};

export const EVI_UPLOAD_LINK = {
  '/insights/vendor-invoices': { label: 'Uploaded Invoices' },
};

export const SUPPLY_LINKS_WITH_FAVORITES = {
  '/supplies/catalog': { label: 'Shop All Products' },
  '/supplies/levels': { label: 'Favorites' },
  '/calendar': { label: 'Recurring Orders' },
  '/supplies/orders': { label: 'Past Orders' },
};

export const SUPPLY_LINKS_WITH_LEVELS = {
  '/supplies/catalog': { label: 'Shop All Products' },
  '/supplies/levels': { label: 'Inventory Lists' },
  '/calendar': { label: 'Recurring Orders' },
  '/supplies/orders': { label: 'Past Orders' },
};

export const ATTENDANT_TOP_LEVEL_LINK = {
  label: 'On-site Service',
  mobileLabel: 'On-site',
  path: '/office-attendant/schedule',
};

export const ATTENDANT_LINKS = {
  '/office-attendant/schedule': { label: 'Schedule' },
  '/office-attendant/shift-details': { label: 'Daily Tasks' },
  '/office-attendant/shift-reports': { label: 'Past Shifts' },
};

export const BUDGET_LINKS = {
  '/insights/budgets': { label: 'Insights' },
  '/insights/vendor-invoices': { label: 'Uploaded Invoices' },
};

export const PROMOTED_BRANDS_LINK = {
  label: 'Trending Products',
  path: '/featured-products',
};

export const SHIPPING_TIME_OPTIONS = [
  {
    label: '1-Day Shipping',
    value: 86400,
  },
  {
    label: '2-Day Shipping',
    value: 172800,
  },
];

export const STOCK_STATUS_OPTIONS = [
  {
    label: 'In stock',
    value: 'in',
  },
  {
    label: 'Out of stock',
    value: 'out',
  },
];

export const SORT_OPTIONS = [
  {
    label: 'relevance',
    value: 'relevance',
    defaultDescending: true,
  },
  {
    label: 'price',
    value: 'price',
    defaultDescending: false,
  },
  {
    label: 'popularity',
    value: 'search_requisitions_count',
    defaultDescending: true,
  },
  {
    label: 'newest',
    value: 'created_at',
    defaultDescending: true,
  },
];

export const PRODUCT_MODAL_TABS = {
  TAB_CART: 'TAB_CART',
  TAB_SCHEDULED: 'TAB_SCHEDULED',
  TAB_STOCK: 'TAB_STOCK',
  TAB_SIMILARS: 'TAB_SIMILARS',
};
