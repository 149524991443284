import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import {
  doFindScheduledRequisition,
  closeScheduledRequisition,
} from '../actions/scheduled-requisition-actions.js';
import UnifiedScheduledRequisitionApprovalForm from '../components/scheduledRequisitions/unified-scheduled-requisition-approval-form.js';

export class OrdersScheduledUnifiedApproval extends React.PureComponent {
  componentDidMount() {
    if (!this.props.match.params.id) return;

    this.props.actions.doFindScheduledRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate() {
    if (
      !this.props.isRequesting &&
      this.props.scheduledRequisition.id > 0 &&
      this.props.scheduledRequisition.location_id !== this.props.openLocation.id
    ) {
      browserHistory.push('/calendar');
    }
  }

  componentWillUnmount() {
    this.props.actions.closeScheduledRequisition();
  }

  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <UnifiedScheduledRequisitionApprovalForm {...this.props} />
      </div>
    );
  }
}

OrdersScheduledUnifiedApproval.propTypes = {
  scheduledRequisition: PropTypes.shape({}),
  isRequesting: PropTypes.bool.isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  isNewRecord: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, props) {
  let scheduledRequisition = state.scheduledRequisitions.open;
  const isRequesting =
    !!state.scheduledRequisitions.requesting.filter(
      (sr) => sr.id === parseInt(props.match.params.id, 10)
    ).length || !state.scheduledRequisitions.initialized;
  const openLocation = state.locations.open;
  return {
    scheduledRequisition,
    isRequesting,
    openLocation,
    isNewRecord: false,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doFindScheduledRequisition,
        closeScheduledRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items &&
    prev.scheduledRequisitions.requesting ===
      next.scheduledRequisitions.requesting &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.locations.open === next.locations.open &&
    prev.scheduledRequisitions.initialized ===
      next.scheduledRequisitions.initialized
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OrdersScheduledUnifiedApproval);
