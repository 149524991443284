import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShippingIcon from '../../imgs/truck-speed.svg';
import VendorMinimumIcon from './vendor-minimum-icon.js';
import {
  hasMinimum,
  hasNextDayShipping,
  getProductVendorForMinimums,
} from '../../helpers/vendor-helpers.js';
import './item.css';

export class ItemVendorIcons extends React.PureComponent {
  render() {
    return (
      <div className="vendor-icons-wrap">
        {hasNextDayShipping(this.props.vendor) && (
          <span
            className="shipping-on-image"
            title="Delivery in one business day"
          >
            {!hasMinimum(this.props.vendor) && <span>1-Day</span>}
            <img
              src={ShippingIcon}
              height={20}
              width={20}
              className="shipping-time-icon"
              title="Delivery in one business day"
              alt="Delivery in one business day"
            />
          </span>
        )}
        <VendorMinimumIcon vendor={this.props.vendor} size={20} />
      </div>
    );
  }
}

ItemVendorIcons.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    unit_purchase: PropTypes.string.isRequired,
    stock_per_purchase: PropTypes.number.isRequired,
    unit_stock: PropTypes.string.isRequired,
  }).isRequired,
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  return {
    vendor: getProductVendorForMinimums(props.item, state.carts.open),
  };
}

export function areStatesEqual(prev, next) {
  return prev.carts.open === next.carts.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  ItemVendorIcons
);
