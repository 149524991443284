import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-sign-in.js';
import setTitle from '../decorators/set-title.js';
import { doGetStaffSelf } from '../actions/staff-actions.js';
import './staff-clocked-out.css';

export class StaffClockedOut extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.pollInterval = window.setInterval(this.poll, 10000);
  }

  componentDidUpdate() {
    if (
      this.props.staff.current_checkins &&
      this.props.staff.current_checkins.length
    ) {
      browserHistory.push('/');
    }
  }

  componentWillUnmount() {
    this.pollInterval && window.clearInterval(this.pollInterval);
  }

  poll = () => {
    this.props.actions.doGetStaffSelf();
  };

  render() {
    return (
      <div className="staff-clocked-out">
        <AuthCheck />
        <NavBar />
        <div className="fixed-width-column">
          <h2 className="staff-clocked-out-title">
            You are not currently clocked in.
          </h2>
        </div>
      </div>
    );
  }
}

StaffClockedOut = setTitle(() => {
  return 'Clocked Out';
})(StaffClockedOut);

StaffClockedOut.propTypes = {
  actions: PropTypes.shape({
    doGetStaffSelf: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    staff: state.staffs.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetStaffSelf,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffClockedOut);
