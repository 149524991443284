import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import BudgetCodeForm from '../insights/budget-code-form.js';
import { closeCustomerBudgetCode } from '../../actions/customer-budget-codes-actions.js';
import './modal-common.css';

export class ModalEditBudgetCode extends React.PureComponent {
  onRequestClose = () => {
    if (this.props.onClose) {
      return this.props.onClose();
    }
    this.props.actions.closeCustomerBudgetCode(this.props.budgetCode);
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.open}
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.onAfterOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Edit Category Modal"
      >
        <div className="modal-create-budget-code">
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
          <h3>Edit Category</h3>
          <div className="budget-code-form-new-toggle">
            <BudgetCodeForm onSubmit={this.onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  }
}

ModalEditBudgetCode.propTypes = {
  actions: PropTypes.shape({
    closeCustomerBudgetCode: PropTypes.func.isRequired,
  }).isRequired,
  budgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    open: !!state.customerBudgetCodes.open.id && !ownProps.control,
    budgetCode: state.customerBudgetCodes.open,
    toggleModal: ownProps.onClose,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeCustomerBudgetCode,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.customerBudgetCodes.open === next.customerBudgetCodes.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalEditBudgetCode);
