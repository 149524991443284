import React from 'react';
import { Button } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import Icon from '../../imgs/icon-my-orders--color-large.svg';
import './empty.css';

export default class RequisitionsEmpty extends React.PureComponent {
  handleClickCatalog = () => {
    browserHistory.push('/supplies/catalog');
  };

  render() {
    return (
      <div className="requisitions-empty">
        <img
          src={Icon}
          alt="Orders"
          width="200"
          height="200"
          className="requisitions-empty-icon"
        />
        <p className="requisitions-empty-title">
          You haven't placed any orders yet.
        </p>
        <Button
          secondary
          className="requisitions-empty-button"
          onClick={this.handleClickCatalog}
        >
          Shop our catalog to start
        </Button>
      </div>
    );
  }
}
