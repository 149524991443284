import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { CUSTOMER_ROLE_PROPTYPES } from '../../helpers/customer-role-helpers.js';
import './customer-role-summary.css';

export class CustomerRoleSummary extends React.PureComponent {
  renderRoleList = (roles, key) => {
    return (roles || []).map((r, i) => {
      const role = this.props.customerRoles[r[key]];
      if (!role) return null;
      if (i === 0) return <b>{role.name}s</b>;
      return (
        <Fragment>
          , <b>{role.name}s</b>
        </Fragment>
      );
    });
  };

  summaryApprovals = () => {
    if (
      !this.props.role.customer_role_approvals.length &&
      !this.props.role.customer_role_approvers.length
    ) {
      return null;
    }
    let collect = [];
    if (this.props.role.customer_role_approvals.length) {
      collect.push(
        <span>
          Approves orders from{' '}
          {this.renderRoleList(
            this.props.role.customer_role_approvals,
            'proposer_id'
          )}
        </span>
      );
    }
    if (this.props.role.customer_role_approvers.length) {
      collect.push(
        <span>
          Submits orders to{' '}
          {this.renderRoleList(
            this.props.role.customer_role_approvers,
            'approver_id'
          )}{' '}
          for approval
        </span>
      );
    }
    return collect.map((text, i) => (
      <span className="role-summary" key={i}>
        {text}
      </span>
    ));
  };

  summarySpend = () => {
    if (
      !this.props.role.approval_required_after_employee_spend ||
      this.props.role.approval_required_after_employee_spend === 0
    ) {
      return null;
    }
    return <span className="role-summary">Has spending limits</span>;
  };

  render() {
    return (
      <div
        className={classnames('role-summary-wrap', {
          compact: this.props.compact,
        })}
      >
        {this.summaryApprovals()}
        {this.summarySpend()}
        {this.props.showMoreLink && (
          <Link to={`/customer-roles#${this.props.role.id}`} target="_blank">
            Details
          </Link>
        )}
      </div>
    );
  }
}

CustomerRoleSummary.defaultProps = {
  showMoreLink: false,
};

CustomerRoleSummary.propTypes = {
  compact: PropTypes.bool.isRequired,
  role: CUSTOMER_ROLE_PROPTYPES,
  customerRoles: PropTypes.object,
};

export default CustomerRoleSummary;
