import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Button } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import {
  submitLocationCatalogProduct,
  submitDeleteLocationCatalogProduct,
  submitAllLocationCatalogProduct,
  submitDeleteAllLocationCatalogProduct,
  closeLocationCatalogProduct,
} from '../../actions/location-catalog-product-actions.js';
import { productIsInLimitedCatalog } from '../../helpers/product-helpers.js';
import './modal-common.css';
import './modal-shipment.css';
import '../products/item-catalogable.css';

export class ModalItemCatalogable extends React.PureComponent {
  addOne = () => {
    this.props.actions.submitLocationCatalogProduct({
      location_id: this.props.openLocation.id,
      product_id: this.props.openProduct.id,
    });
  };

  addAll = () => {
    this.props.actions.submitAllLocationCatalogProduct({
      product_id: this.props.openProduct.id,
    });
  };

  removeOne = () => {
    this.props.actions.submitDeleteLocationCatalogProduct({
      location_id: this.props.openLocation.id,
      product_id: this.props.openProduct.id,
      id: this.props.catalogProductId,
    });
  };

  /* This is not implemented */
  removeAll = () => {
    // this.props.actions.submitDeleteAllLocationCatalogProduct({
    //     location_id: this.props.openLocation.id,
    //     product_id: this.props.openProduct.id,
    // });
  };

  onRequestClose = () => {
    this.props.actions.closeLocationCatalogProduct();
  };

  renderText() {
    if (this.props.isInCatalog) {
      return 'This product is in your office restricted catalog.';
    }
    return 'This product is not in your office restricted catalog.';
  }

  renderActions() {
    if (this.props.isInCatalog) {
      return (
        <div className="item-catalogable__actions">
          <Button secondary onClick={this.removeOne}>
            Remove from this office's catalog
          </Button>
        </div>
      );
    }
    return (
      <div className="item-catalogable__actions">
        <Button primary onClick={this.addOne}>
          Add to this office's catalog
        </Button>
        <Button secondary onClick={this.addAll}>
          Add to all offices
        </Button>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="item-catalogable__modal">
        <div className="item-catalogable__product-desc">
          <div className="item-catalogable__product-image">
            <ItemImage
              product={this.props.openProduct}
              title={this.props.openProduct.name}
              alt={this.props.openProduct.name}
              size={'original'}
            />
          </div>
          <h4 className="item-catalogable__product-name">
            {this.props.openProduct.name}
          </h4>
          <p>
            {this.renderText()}
            {this.props.isInCatalog && (
              <span className="item-catalogable__included-checkmark brand-bg-color-override">
                &#10004;
              </span>
            )}
          </p>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.openModal}
        onRequestClose={this.onRequestClose}
        onAfterOpen={() => {}}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Catalog Modal">
        <div className="modal-shipment">
          <button className="close-icon" onClick={this.onRequestClose}>
            &times;
          </button>
          {this.renderContent()}
        </div>
      </Modal>
    );
  }
}

ModalItemCatalogable.propTypes = {
  actions: PropTypes.shape({
    submitLocationCatalogProduct: PropTypes.func.isRequired,
    submitDeleteLocationCatalogProduct: PropTypes.func.isRequired,
    submitAllLocationCatalogProduct: PropTypes.func.isRequired,
  }).isRequired,
  catalogProductId: PropTypes.number,
  isInCatalog: PropTypes.bool.isRequired,
  openProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    catalog_product_id: PropTypes.number,
  }),
  openModal: PropTypes.bool.isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export function mapStateToProps(state) {
  const openProduct = state.locationCatalogProducts.openProduct;
  return {
    catalogProductId:
      openProduct.catalog_product_id ||
      state.products.in_catalog[openProduct.id],
    isInCatalog: productIsInLimitedCatalog(state.products.in_catalog)(
      openProduct
    ),
    openLocation: state.locations.open,
    openModal: openProduct && !!openProduct.id,
    openProduct,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationCatalogProduct,
        submitDeleteLocationCatalogProduct,
        submitAllLocationCatalogProduct,
        submitDeleteAllLocationCatalogProduct,
        closeLocationCatalogProduct,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.locationCatalogProducts.openProduct ===
      next.locationCatalogProducts.openProduct &&
    prev.products.in_catalog[prev.locationCatalogProducts.openProduct.id] ===
      next.products.in_catalog[next.locationCatalogProducts.openProduct.id]
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalItemCatalogable);
