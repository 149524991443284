import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputQuantity from '../forms/input-quantity.js';
import CartSubstitutionPreferences from './cart-substitution-preferences.js';
import {
  formatUnitOfPurchase,
  formatCentAmount,
} from '../../lib/formatters.js';
import {
  submitDeleteProductRequisition,
  submitUpdateProductRequisition,
  updateEditProductRequisition,
} from '../../actions/requisition-actions.js';
import { updateStagedScheduledProductRequisition } from '../../actions/scheduled-product-requisition-actions.js';
import { openProduct } from '../../actions/product-actions.js';
import ItemImage from '../products/item-image.js';
import './cart-product-requisition.css';

export class CartProductRequisition extends React.PureComponent {
  _getPrice() {
    if (
      !(this.props.item.price || this.props.item.price === 0) &&
      this.props.item.product
    ) {
      return this.props.item.product.price;
    }
    return this.props.item.price;
  }

  _handleClickDetails = () => {
    this.props.actions.openProduct(this.props.item.product);
  };

  _handleChangeQuantity = (quantity) => {
    if (this.props.isEditingScheduled) {
      return this.props.actions.updateStagedScheduledProductRequisition({
        ...this.props.item,
        quantity: quantity,
      });
    }
    if (this.props.isEditing) {
      return this.props.actions.updateEditProductRequisition({
        ...this.props.item,
        quantity: quantity,
      });
    }
    if (quantity < 1) {
      return this.props.actions.submitDeleteProductRequisition(this.props.item);
    }
    this.props.actions.submitUpdateProductRequisition({
      quantity,
      id: this.props.item.id,
      requisition_id: this.props.item.requisition_id,
    });
  };

  render() {
    return (
      <div className="cart-product-requisition">
        <div className="item-image" onClick={this._handleClickDetails}>
          <ItemImage
            width={42}
            height={42}
            title={this.props.item.product.name}
            alt={this.props.item.product.name}
            size={'thumb'}
            product={this.props.item.product}
          />
        </div>
        <div className="item-details">
          <div className="name">
            <button
              onClick={this._handleClickDetails}
              className="cart-item-button-details tertiary"
            >
              {this.props.item.product.name}
            </button>
          </div>
          <div className="uop light-gray-text">
            {formatUnitOfPurchase(
              this.props.item.product.unit_purchase,
              this.props.item.product.stock_per_purchase,
              this.props.item.product.unit_stock
            )}
          </div>
          <div className="product-price">
            {formatCentAmount(this._getPrice())}
          </div>

          {this.props.showReplacementOptions && (
            <CartSubstitutionPreferences
              item={this.props.item}
              isEditing={this.props.isEditing}
              isEditingScheduled={this.props.isEditingScheduled}
            />
          )}
        </div>
        <div className="item-actions">
          <div className="item-quantity-input">
            <InputQuantity
              value={this.props.item.quantity}
              label=""
              min={0}
              onChange={this._handleChangeQuantity}
              canRemove={!this.props.isEditing}
              readOnly={this.props.readOnly}
            />
          </div>
        </div>
      </div>
    );
  }
}

CartProductRequisition.propTypes = {
  actions: PropTypes.shape({
    openProduct: PropTypes.func.isRequired,
    submitDeleteProductRequisition: PropTypes.func.isRequired,
    submitUpdateProductRequisition: PropTypes.func.isRequired,
    updateEditProductRequisition: PropTypes.func.isRequired,
    updateStagedScheduledProductRequisition: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    quantity: PropTypes.number.isRequired,
    requisition_id: PropTypes.number,
    scheduled_requisition_id: PropTypes.number,
    price: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
      price: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  showReplacementOptions: PropTypes.bool.isRequired,
  isEditingScheduled: PropTypes.bool.isRequired,
};

CartProductRequisition.defaultProps = {
  isEditing: false,
  readOnly: false,
  showReplacementOptions: false,
  isEditingScheduled: false,
};

function mapStateToProps() {
  return {};
}

function areStatesEqual() {
  return true;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openProduct,
        submitDeleteProductRequisition,
        submitUpdateProductRequisition,
        updateEditProductRequisition,
        updateStagedScheduledProductRequisition,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CartProductRequisition);
