import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShipmentItem from '../purchaseOrders/item.js';
import { IS_ACTIVE_SHIPMENT } from '../../flags.js';
import './subnav-supplies-orders.css';

export class SubNavSuppliesOrders extends React.PureComponent {
  title = () => {
    if (this.props.shipments.length === 1) {
      return `${this.props.shipments.length} Shipment Arriving Soon`;
    }
    return `${this.props.shipments.length} Shipments Arriving Soon`;
  };

  renderShipment = (shipment) => {
    return <ShipmentItem key={shipment.id} item={shipment} />;
  };

  render() {
    if (!this.props.shipments.length) {
      return <div className="subnav-container-shipments-empty"></div>;
    }
    return (
      <div className="subnav-container-shipments">
        <div className="variable-width-column">
          <h2 className="shipments-title">{this.title()}</h2>
          <div className="purchase-orders-list-horiz">
            {this.props.shipments.map(this.renderShipment)}
          </div>
        </div>
      </div>
    );
  }
}

SubNavSuppliesOrders.propTypes = {
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      expected_at_start: PropTypes.string,
      expected_at_end: PropTypes.string,
    })
  ).isRequired,
};

const sortExpectedAt = (a, b) => {
  return a.expected_at_end > b.expected_at_end ? 1 : -1;
};
const filterNotReceived = (p) => {
  return !p.received_at;
};
const filterNotDelivered = (s) => {
  return s.status !== 'delivered';
};

export function mapStateToProps(state) {
  return {
    shipments: Object.values(state.shipments.items)
      .filter((shipment) => IS_ACTIVE_SHIPMENT(shipment))
      .filter(filterNotDelivered)
      .filter(filterNotReceived)
      .sort(sortExpectedAt),
  };
}

export function mapDispatchToProps() {
  return {};
}

export function areStatesEqual(prev, next) {
  return prev.shipments.items === next.shipments.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SubNavSuppliesOrders);
