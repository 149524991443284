export const flattenArray = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
};

export const first = (arr) => {
  if (Array.isArray(arr)) {
    for (let i in arr) return arr[i];
  }
  return;
};
