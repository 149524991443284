import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showProductModal,
  closeProductModal,
} from '../actions/product-actions.js';
import { openShipment, closeShipment } from '../ducks/shipments.js';

export class PopState extends React.PureComponent {
  static propTypes = {
    openProduct: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      showProductModal: PropTypes.func.isRequired,
      closeProductModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    window.addEventListener('popstate', this._handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this._handlePopState);
  }

  _handlePopState = () => {
    this._handlePopStateProduct();
    this._handlePopStateShipment();
  };

  _handlePopStateProduct = () => {
    const productQuery = window.location.search.match(
      /[&|?]+product=([0-9]+)/i
    );
    const product = productQuery && parseInt(productQuery[1], 10);
    if (product && product !== this.props.openProduct.id) {
      return this.props.actions.showProductModal({
        id: product,
        __via: 'popstate',
      });
    } else if (this.props.openProduct.id) {
      return this.props.actions.closeProductModal();
    }
  };

  _handlePopStateShipment = () => {
    const shipmentQuery = window.location.search.match(
      /[&|?]+shipment=([0-9]+)/i
    );
    const shipment = shipmentQuery && parseInt(shipmentQuery[1], 10);
    if (shipment && shipment !== this.props.openShipment.id) {
      return this.props.actions.openShipment({
        id: shipment,
        __via: 'popstate',
      });
    } else if (this.props.openShipment.id) {
      return this.props.actions.closeShipment();
    }
  };

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    openProduct: state.products.open,
    openShipment: state.shipments.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        closeProductModal,
        openShipment,
        closeShipment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.products.open === next.products.open &&
    prev.shipments.open === next.shipments.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(PopState);
