import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import Loader from '../loader.js';
import {
  formatCentAmount,
  formatDate,
  groupTimeSeriesData,
  findBestSeriesInterval,
  zipArrays,
} from '../../lib/formatters.js';
import {
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryBar,
} from 'victory';
import VictoryChart from '../victory/ie-chart.js';
import InsightsPdf from './insights-pdf.js';
import './total-office-spend-line.css';

const VIEW_NAME = 'customer_purchase_dates';
const VIEW_TOPIC = 'location';
const MIN_DOLLAR_RANGE = 10000;
const FONT_FAMILY = 'Roboto, Helvetica, Verdana, sans-serif';
const reduceLocationsData = (acc, val) => {
  acc.customer_purchases_total_spend += val.customer_purchases_total_spend;
  acc.location_budget_annual += val.location_budget_annual || 0;
  acc.location_employee_count += val.location_employee_count || 0;
  return acc;
};

export class TotalOfficeSpendLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this._buildState(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => {
      return this._buildState(props);
    });
  }

  _resizeChart = () => {
    if (this.props.windowWidth > 650) {
      return this.props.windowWidth / 2;
    }
    return this.props.windowWidth;
  };

  _reduceDataViewsByDate = (dataViews) => {
    return zipArrays(
      dataViews.map((d) => d.data),
      (values) => {
        return values.reduce(reduceLocationsData, {
          customer_purchases_total_spend: 0,
          customer_purchases_count: 0,
          location_budget_annual: 0,
          location_employee_count: 0,
          submitted_date: values[0] ? values[0].submitted_date : null,
        });
      }
    );
  };

  _buildState = (props) => {
    if (props.purchasesByDate.length < 1) {
      return this._buildEmptyState(props);
    }
    const dataViewsByDate = this._reduceDataViewsByDate(props.purchasesByDate);
    const dataLength = dataViewsByDate.length;
    const interval = findBestSeriesInterval(
      new Date(dataViewsByDate[0].submitted_date),
      new Date(dataViewsByDate[dataLength - 1].submitted_date),
      3
    );
    const grouped = groupTimeSeriesData({
      data: dataViewsByDate,
      dateKey: 'submitted_date',
      sumKeys: ['customer_purchases_total_spend', 'customer_purchases_count'],
      interval,
    });

    let data = {
      totalSpend: this._calculateTotalSpend(grouped),
      spendData: this._buildSpendData(grouped),
      budgetData: this._buildBudgetData(grouped, interval),
      interval: interval,
      numberOfEmployees: props.locations.reduce((acc, val) => {
        return acc + (val.number_of_employees || 0);
      }, 0),
    };
    data.maxDollarRange = this._calculateMaxDollarRange([], data.spendData);
    return data;
  };

  _buildEmptyState = (props) => {
    let submittedDate = new Date();
    let defaultSpendData = {
      spendAmount: 0,
      tooltipLabel: 'Spend',
      submittedDate,
    };
    let defaultBudgetData = {
      dailyBudgetAmount: 0,
      tooltipLabel: 'Budget',
      submittedDate,
    };
    return {
      totalSpend: 0,
      spendData: [defaultSpendData],
      budgetData: [defaultBudgetData],
      maxDollarRange: MIN_DOLLAR_RANGE,
      interval: 'day',
      numberOfEmployees: props.locations.reduce((acc, val) => {
        return acc + (val.number_of_employees || 0);
      }, 0),
    };
  };

  _calculateTotalSpend = (series) => {
    return series.reduce((acc, i) => {
      return acc + i.customer_purchases_total_spend;
    }, 0);
  };

  _buildSpendData = (series) => {
    return series.reduce((acc, i) => {
      acc.push({
        tooltipLabel: 'Spend',
        submittedDate: i.submitted_date,
        spendAmount: i.customer_purchases_total_spend,
      });
      return acc;
    }, []);
  };

  _buildBudgetData = (series, interval) => {
    let currentBudgetAmount = 0;
    let divisor = 365;
    switch (interval) {
      case 'year':
        divisor = 1;
        break;
      case 'month':
        divisor = 12;
        break;
      case 'week':
        divisor = 52;
        break;
      default:
        break;
    }
    return series.reduce((acc, i) => {
      currentBudgetAmount =
        i.location_budget_annual !== null
          ? i.location_budget_annual / divisor
          : currentBudgetAmount;
      acc.push({
        tooltipLabel: 'Budget',
        submittedDate: i.submitted_date,
        dailyBudgetAmount: currentBudgetAmount,
      });
      return acc;
    }, []);
  };

  _calculateMaxDollarRange = (budgetData, spendData) => {
    let upperBudgetRange = Math.max.apply(
      Math,
      budgetData.map((d) => d.dailyBudgetAmount)
    );
    let upperSpendRange = Math.max.apply(
      Math,
      spendData.map((d) => d.spendAmount)
    );
    return Math.max(upperBudgetRange, upperSpendRange, MIN_DOLLAR_RANGE);
  };

  _intervalLabel = (labelType) => {
    switch (this.state.interval) {
      case 'year':
        return `Yearly ${labelType}`;
      case 'month':
        return `Monthly ${labelType}`;
      case 'week':
        return `Weekly ${labelType}`;
      case 'day':
        return `Daily ${labelType}`;
      default:
        return labelType;
    }
  };

  _labels = (d) => {
    let date = formatDate(d.datum.submittedDate);
    switch (this.state.interval) {
      case 'year':
        date = `${new Date(d.datum.submittedDate).getFullYear()}`;
        break;
      case 'month':
        date = `${new Date(d.datum.submittedDate).toUTCString().split(' ')[2]}`;
        break;
      case 'week':
        date = 'Week of ' + date.split(' ').slice(1).join(' ');
        break;
      case 'day':
      default:
        break;
    }
    if (d.datum.tooltipLabel && d.datum.tooltipLabel.match(/budget/i)) {
      return [
        `${date}`,
        `${this._intervalLabel(d.datum.tooltipLabel)}: ${formatCentAmount(
          d.datum._y
        )}`,
      ].join('\n');
    } else if (d.datum._y > 0) {
      return `${this._intervalLabel(d.datum.tooltipLabel)}: ${formatCentAmount(
        d.datum._y
      )}`;
    } else {
      return `${this._intervalLabel('Spend')}: ${formatCentAmount(0)}`;
    }
  };

  _formatXAxis = (x) => {
    const d = new Date(x);
    switch (this.state.interval) {
      case 'year':
        return `${d.getFullYear()}`;
      case 'month':
        return `${d.toUTCString().split(' ')[2]}`;
      case 'week':
      case 'day':
      default:
        return `${d.toUTCString().split(' ')[2]} ${d.getDate()}`;
    }
  };

  _formatYAxis = (y) => {
    if (y < 100000) {
      return `$${y / 100}`;
    }
    return `$${y / 100 / 1000}k`;
  };

  _orientTooltip = (d) => {
    if (this.state.spendData.slice(-2)[0].submittedDate <= d.datum._x) {
      return 'left';
    }
    return 'right';
  };

  _dxTooltip = () => {
    return 0;
  };

  tickValuesX = () => {
    return this.state.spendData.map((d) => d.submittedDate);
  };

  _timePad = () => {
    switch (this.state.interval) {
      case 'year':
        return [1, 'month'];
      case 'month':
        return [2, 'week'];
      case 'week':
        return [3, 'day'];
      case 'day':
        return [1, 'day'];
      default:
        return [3, 'day'];
    }
  };

  _getDomain = () => {
    if (this.state.spendData.length >= 2) {
      let timePad = this._timePad();
      return {
        x: [
          moment(this.state.spendData[0].submittedDate).subtract(...timePad),
          moment(
            this.state.spendData[this.state.spendData.length - 1].submittedDate
          ).add(...timePad),
        ],
      };
    }
    return 0;
  };

  _color = () => {
    return this.props.brandColor || '#00a98c';
  };

  _lineData = () => {
    let timePad = this._timePad();
    let first = this.state.budgetData[0];
    first.submittedDate = moment(first.submittedDate).subtract(...timePad);
    let last = this.state.budgetData[this.state.budgetData.length - 1];
    last.submittedDate = moment(last.submittedDate).add(...timePad);
    return [first].concat(this.state.budgetData).concat([last]);
  };

  render() {
    return (
      <div className="total-office-spend-line">
        <div
          className={classnames('aggregate-spend-data print-pdf', {
            multiple: this.props.locations.length > 1,
          })}
        >
          <InsightsPdf
            locations={this.props.locations}
            numberOfEmployees={this.state.numberOfEmployees}
            totalSpend={this.state.totalSpend}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
          />
        </div>
        <div className="total-office-spend-chart">
          <h5 className="total-spend-header total-office-spend">
            <span>Total Office Spend</span>
            <strong>{formatCentAmount(this.state.totalSpend)}</strong>
          </h5>
          <VictoryChart
            width={this._resizeChart()}
            height={300}
            domain={this._getDomain()}
            domainPadding={{ x: 1, y: 20 }}
            padding={{ top: 20, bottom: 30, left: 50, right: 20 }}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={this._labels}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={5}
                    pointerLength={5}
                    orientation={this._orientTooltip}
                    dx={this._dxTooltip}
                    horizontal={true}
                    flyoutStyle={{ fill: '#535662', stroke: 0 }}
                    groupComponent={<g className="total-spend-tooltip" />}
                    style={{
                      fontFamily: FONT_FAMILY,
                    }}
                  />
                }
              />
            }
          >
            <VictoryAxis
              tickFormat={this._formatXAxis}
              tickValues={this.tickValuesX()}
              style={{
                tickLabels: {
                  fill: '#535662',
                  fontSize: 12,
                  fontFamily: FONT_FAMILY,
                },
              }}
              tickLabelComponent={
                <VictoryLabel className={'total-spend-x-axis-label'} />
              }
            />
            <VictoryAxis
              dependentAxis
              tickFormat={this._formatYAxis}
              domain={{ y: [0, this.state.maxDollarRange] }}
              style={{
                tickLabels: {
                  fill: '#535662',
                  fontSize: 13,
                  fontFamily: FONT_FAMILY,
                },
              }}
            />
            <VictoryBar
              barRatio={0.5}
              style={{
                data: {
                  fill: this._color(),
                  fillOpacity: '0.8',
                },
                labels: { fill: 'white', fontWeight: 'bold' },
              }}
              animate={{
                duration: 300,
                onLoad: { duration: 300 },
              }}
              data={this.state.spendData}
              x={'submittedDate'}
              y={'spendAmount'}
              labels={this._labels}
              labelComponent={
                <VictoryLabel
                  renderInPortal
                  dy={-5}
                  className={'total-spend-line-label'}
                />
              }
            />
          </VictoryChart>
          <h5 className="total-spend-header hide-when-printing">
            <span>Spend Per Employee</span>
            <strong>
              {formatCentAmount(
                this.state.totalSpend / this.state.numberOfEmployees
              )}
            </strong>
          </h5>
          {this.props.isRequesting && (
            <div className="loader-wrapper">
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

TotalOfficeSpendLine.propTypes = {
  isRequesting: PropTypes.bool,
  windowWidth: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      number_of_employees: PropTypes.number,
    })
  ).isRequired,
  purchasesByDate: PropTypes.arrayOf(
    PropTypes.shape({
      view: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          submitted_date: PropTypes.string,
          location_budget_annual: PropTypes.number,
          customer_purchases_total_spend: PropTypes.number,
        })
      ).isRequired,
      topic: PropTypes.shape({
        id: PropTypes.number.isRequired,
        model: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  brandColor: PropTypes.string,
};

function mapStateToProps(state, props) {
  const locationIds = props.locations.map((l) => l.id);
  const start = props.startDate.toJSON();
  const end = props.endDate.toJSON();
  return {
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === VIEW_NAME &&
          d.start === start &&
          d.end === end &&
          d.topic.model === VIEW_TOPIC &&
          locationIds.indexOf(d.topic.id) > -1
      ).length > 0,
    purchasesByDate: state.dataViews.items.filter(
      (d) =>
        d.view === VIEW_NAME &&
        d.start === start &&
        d.end === end &&
        d.topic.model === VIEW_TOPIC &&
        locationIds.indexOf(d.topic.id) > -1
    ),
    brandColor: state.locations.open.brand_color,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting &&
    prev.locations.open.brand_color === next.locations.open.brand_color
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  TotalOfficeSpendLine
);
