import React from 'react';

import PropTypes from 'prop-types';
import './input-select.css';

class InputSelect extends React.PureComponent {
  onValueChange = (e) => {
    this.props.onChange(this.refs.input.value);
  };

  renderOption = (option, index) => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  );

  render() {
    return (
      <label className="select">
        {this.props.label !== undefined ? this.props.label : 'Choose one'}
        <select
          ref="input"
          disabled={this.props.disabled}
          value={this.props.value}
          defaultValue={
            this.props.value === null || this.props.value === undefined
              ? ''
              : undefined
          }
          onChange={this.onValueChange}
          name={this.props.name}
        >
          {!this.props.hidePrompt && (
            <option key="-1" value="">
              Select one
            </option>
          )}
          {this.props.options.map(this.renderOption)}
        </select>
      </label>
    );
  }
}

InputSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  hidePrompt: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default InputSelect;
