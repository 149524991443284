import React from 'react';
import { useState } from 'react';
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  addWeeks,
  subWeeks,
} from 'date-fns';
import { Icon } from 'semantic-ui-react';
import './week-calendar.css';

const WeekCalendar = ({ data }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [thisWeekView, setThisWeekView] = useState(true);

  const changeWeekHandle = (btnType) => {
    if (btnType === 'prev') {
      setThisWeekView(true);
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === 'next') {
      setThisWeekView(false);
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const renderHeader = () => {
    const dateFormat = 'MMM yyyy';
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          {!thisWeekView && (
            <Icon onClick={() => changeWeekHandle('prev')} name="angle left" />
          )}
        </div>
        <div className="col col-center">
          <span className="week">
            {thisWeekView ? 'This Week' : 'Next Week'}
          </span>
          <hr />
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {thisWeekView && (
            <Icon onClick={() => changeWeekHandle('next')} name="angle right" />
          )}
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = 'EEE';
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = 'M/d';
    const rows = [];
    let sortedData = data.sort(
      (a, b) =>
        new Date(a['StartTimeLocalized']).getTime() -
        new Date(b['StartTimeLocalized']).getTime()
    );
    let days = [];
    let day = startDate;
    let filteredData = '';
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        let formattedDate = format(day, dateFormat);
        filteredData = sortedData.filter(
          (item) =>
            format(new Date(item['StartTimeLocalized']), dateFormat) ===
            formattedDate
        );
        days.push(
          <div
            className={`col cell ${isSameDay(day, new Date()) ? 'today' : ''}`}
            key={day}>
            <span className="day-of-week">
              {format(addDays(startDate, i), 'EEE')}
            </span>
            <span className="number">{formattedDate}</span>
            {data &&
              filteredData.map(
                (item) =>
                  item['EmployeeObject'] && (
                    <div key={item['Id']} className="timeslot">
                      {/* <img
                    alt="Employee"
                    src={item['_DPMetaData']['EmployeeInfo']['Photo']}></img> */}
                      <div className="name">
                        {item['EmployeeObject']['FirstName']}{' '}
                        {item['EmployeeObject']['LastName']}
                      </div>
                      <div>
                        {format(new Date(item['StartTimeLocalized']), 'h:mm a')}
                        -{format(new Date(item['EndTimeLocalized']), 'h:mm a')}
                      </div>
                      <div className="title">
                        {item['OperationalUnitObject']['OperationalUnitName']}
                      </div>
                    </div>
                  )
              )}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const renderFooter = () => {
    return (
      <div className="header row flex-middle">
        <div className="col col-start"></div>
      </div>
    );
  };
  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
      {renderFooter()}
    </div>
  );
};

export default WeekCalendar;
