// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { doForgotPassword } from '../actions/auth-actions';
import NavbarSignIn from './nav/navbar-sign-in.js';
import { trackForgotPassword } from '../lib/analytics.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './forgotpassword.css';

export class ForgotPassword extends React.PureComponent {
  state = { email: '' };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  onChangeEmail = (e) => {
    let email = e.target.value;
    this.setState(Object.assign({}, this.state, { email }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.props.actions.doForgotPassword({
      email: this.state.email,
    });
    this.setState(Object.assign({}, this.state, { email: '' }));
    trackForgotPassword(this.state);
  };

  isDisabled() {
    return this.props.auth.isRequesting || !(this.state.email.length > 5);
  }

  render() {
    return (
      <div className="forgot-password">
        <NavbarSignIn />
        <div className="well-container hero-image">
          <div
            style={{
              maxWidth: '22rem',
              margin: '0 auto',
            }}>
            <Header attached="top" as="h2">
              Forgot Password
            </Header>
            <Segment attached="bottom" loading={this.props.auth.isRequesting}>
              <Form
                success={
                  !this.props.auth.isRequesting &&
                  this.props.auth.forgotPassword
                }
                onSubmit={this.handleFormSubmit}>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    ref="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                  />
                </Form.Field>
                <Button
                  primary
                  type="submit"
                  disabled={this.isDisabled()}
                  style={{ minWidth: '50%' }}>
                  Send Reset Instructions
                </Button>
                <Message
                  success
                  content="Check your inbox for reset instructions!"
                />
              </Form>
              <div className="sign-in-forgot-password">
                <Link to="/sign-in" className="tertiary-link">
                  Sign In
                </Link>
              </div>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ doForgotPassword }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
