import React from 'react'; // eslint-disable-line
import { RRule } from 'rrule';
import moment from 'moment';
import { default_vendor } from './vendor-helpers.js';
import { GET_ALCOHOL_PRS } from '../flags.js';
import { START_OF_DAY } from '../strings.js';
import { formatTime, formatDate } from '../lib/formatters.js';

export const isExpired = (scheduledRequisition) => {
  if (!scheduledRequisition) return false;

  if (!scheduledRequisition.expiration_at) return false;
  if (new Date(scheduledRequisition.expiration_at) < new Date()) {
    return true;
  }
  return false;
};

export const isApproved = (scheduledRequisition) => {
  if (!scheduledRequisition) return false;
  if (scheduledRequisition.approve_automatically) {
    return isExpired(scheduledRequisition);
  }
  if (!scheduledRequisition.approved_at) return false;

  return (
    scheduledRequisition.approved_at &&
    new Date(scheduledRequisition.approved_at) < new Date()
  );
};

export const isCancelled = (scheduledRequisition) => {
  if (!scheduledRequisition) return false;
  if (scheduledRequisition.requisition_id) return false;
  if (isApproved(scheduledRequisition)) return false;

  return isExpired(scheduledRequisition);
};

export const willBeApproved = (scheduledRequisition) => {
  if (scheduledRequisition.approve_automatically) return true;

  return (
    scheduledRequisition.approved_at &&
    new Date(scheduledRequisition.approved_at) > new Date()
  );
};

export const isApprovingUser = (scheduledRequisition, user) => {
  return user.id === scheduledRequisition.approver_id;
};

export const canEdit = (scheduledRequisition, user) => {
  if (!isExpired(scheduledRequisition)) return true;
  if (
    scheduledRequisition.approved_at &&
    !isApprovingUser(scheduledRequisition, user)
  )
    return false;
  return (
    !isExpired(scheduledRequisition) &&
    isApprovingUser(scheduledRequisition, user)
  );
};

export const hasItems = (scheduledRequisition) => {
  if (!scheduledRequisition.scheduled_product_requisitions) return false;
  return (scheduledRequisition.scheduled_product_requisitions.length || []) > 0;
};

export const hasAlcoholItems = (scheduledRequisition) => {
  if (!scheduledRequisition.scheduled_product_requisitions) return false;
  return (
    GET_ALCOHOL_PRS(scheduledRequisition.scheduled_product_requisitions)
      .length > 0
  );
};

export const isInvalid = (sr) => {
  return (
    !sr.name ||
    sr.approve_automatically === null ||
    !sr.shipping_name ||
    !sr.shipping_address ||
    !sr.shipping_city ||
    !sr.shipping_state ||
    !sr.shipping_zip ||
    !sr.name.trim().length ||
    !sr.shipping_name.trim().length ||
    !sr.shipping_address.trim().length ||
    !sr.shipping_city.trim().length ||
    !sr.shipping_state.trim().length ||
    !sr.shipping_zip.trim().length ||
    (hasAlcoholItems(sr) && !sr.age_confirmation) ||
    !hasItems(sr) ||
    isExpired(sr)
  );
};

export const getShippingMaxSeconds = (scheduledRequisition) => {
  return Math.max.apply(
    null,
    (scheduledRequisition.scheduled_product_requisitions || []).map(
      (spr) => (spr.product.vendors[0] || default_vendor).shipping.max_seconds
    )
  );
};

export const formDataHasChanged = (sr1, sr2) => {
  return (
    sr1.shipping_address !== sr2.shipping_address ||
    sr1.shipping_address_number !== sr2.shipping_address_number ||
    sr1.shipping_city !== sr2.shipping_city ||
    sr1.shipping_state !== sr2.shipping_state ||
    sr1.shipping_zip !== sr2.shipping_zip ||
    sr1.shipping_business !== sr2.shipping_business ||
    sr1.shipping_care !== sr2.shipping_care ||
    sr1.shipping_name !== sr2.shipping_name ||
    sr1.instructions !== sr2.instructions ||
    sr1.age_confirmation !== sr2.age_confirmation ||
    sr1.name !== sr2.name ||
    sr1.approve_automatically !== sr2.approve_automatically ||
    sr1.archived !== sr2.archived ||
    sr1.rrule !== sr2.rrule ||
    sr1.expiration_hour !== sr2.expiration_hour ||
    moment(sr1.expected_at).format('MM DD YY') !==
      moment(sr2.expected_at).format('MM DD YY')
  );
};

export const addressHasChanged = (sr1, sr2) => {
  return (
    sr1.shipping_address !== sr2.shipping_address ||
    sr1.shipping_address_number !== sr2.shipping_address_number ||
    sr1.shipping_city !== sr2.shipping_city ||
    sr1.shipping_state !== sr2.shipping_state ||
    sr1.shipping_zip !== sr2.shipping_zip
  );
};

const simplifySprList = (sprs) => {
  return sprs
    .map((spr) => ({
      product_id: spr.product_id,
      quantity: spr.quantity,
    }))
    .sort((a, b) => {
      if (a.product_id < b.product_id) return -1;
      if (b.product_id < a.product_id) return 1;
      return 0;
    });
};

export const productReqsHaveChanged = (original, updated) => {
  if (original.length !== updated.length) return true;
  return (
    JSON.stringify(simplifySprList(original)) !==
    JSON.stringify(simplifySprList(updated))
  );
};

export const FILTER_EXPECTED_BEFORE_TODAY = (sr) => {
  return new Date(sr.expected_at) >= START_OF_DAY();
};

export const FILTER_EXPIRE_AFTER_TODAY = (sr) => {
  return new Date(sr.expiration_at) >= START_OF_DAY();
};

export const FILTER_EXPIRED = (sr) => {
  if (sr.approved_at) {
    return FILTER_EXPECTED_BEFORE_TODAY(sr);
  }
  return FILTER_EXPIRE_AFTER_TODAY(sr);
};

export const FILTER_NON_ARCHIVED = (sr) => {
  return sr.archived === false;
};

export const extractHourFromRrule = (rrule) => {
  if (!rrule) return 10;
  const rruleObj = RRule.fromString(rrule);
  if (!rruleObj.origOptions.byhour) return 10;
  return rruleObj.origOptions.byhour;
};

export const insertHourIntoRrule = (rrule, hour) => {
  if (!hour) return rrule;
  if (rrule.match(/BYHOUR/) !== null) {
    return rrule.replace(/BYHOUR=\d+/g, `BYHOUR=${hour}`);
  }
  return `${rrule};BYHOUR=${hour}`;
};

export const extractFrequencyIntervalFromRrule = (rrule) => {
  const defaultResp = { interval: 1, frequency: RRule.WEEKLY };
  if (!rrule) return defaultResp;
  const rruleObj = RRule.fromString(rrule);
  if (!rruleObj.origOptions.freq || !rruleObj.options.interval)
    return defaultResp;

  let interval = rruleObj.options.interval;
  let freq;
  switch (rruleObj.origOptions.freq) {
    case RRule.YEARLY:
      freq = 'year';
      break;
    case RRule.MONTHLY:
      freq = 'month';
      break;
    case RRule.WEEKLY:
      freq = 'week';
      break;
    case RRule.DAILY:
      freq = 'day';
      break;
    case RRule.HOURLY:
      freq = 'hour';
      break;
    case RRule.MINUTELY:
      freq = 'minute';
      break;
    case RRule.SECONDLY:
      freq = 'second';
      break;
    default:
      freq = 'week';
      break;
  }
  return { interval: interval, frequency: freq };
};

export const constructSPRAttributes = (
  scheduledProductRequisitions,
  updateAll = '0'
) => {
  return scheduledProductRequisitions.map((spr) => {
    let base = {
      id: spr.id,
      product_id: spr.product_id,
      quantity: spr.quantity,
      scheduled_requisition_id: spr.scheduled_requisition_id,
      instructions: spr.instructions,
      _update_future_occurrences: updateAll,
    };
    if (spr.quantity < 1) base._destroy = '1';
    return base;
  });
};

export const hasUnmetMinimums = (scheduledRequisition) => {
  if (
    !scheduledRequisition ||
    !scheduledRequisition._optimized_product_requisitions
  )
    return false;

  return (
    scheduledRequisition._optimized_product_requisitions.unmet_minimums.length >
    0
  );
};

export const SORT_BY_EXPIRE_AT_ASC = (a, b) => {
  if (a.expiration_at < b.expiration_at) return -1;
  if (b.expiration_at < a.expiration_at) return 1;
  return -1;
};

export const SORT_BY_EXPECTED_AT_ASC = (a, b) => {
  if (a.expected_at < b.expected_at) return -1;
  if (b.expected_at < a.expected_at) return 1;
  return -1;
};

export const nextSourceOccurrences = (scheduledRequisitions) => {
  const sreqs = scheduledRequisitions
    .filter(FILTER_NON_ARCHIVED)
    .filter(FILTER_EXPIRED)
    .filter(FILTER_EXPECTED_BEFORE_TODAY)
    .sort(SORT_BY_EXPECTED_AT_ASC);

  const sourceCollect = [];
  return sreqs.reduce((acc, val) => {
    if (isApproved(val)) return acc;

    if (sourceCollect.filter((sourceId) => sourceId === val.source_id).length) {
      return acc;
    } else {
      acc.push(val);
      sourceCollect.push(val.source_id);
      return acc;
    }
  }, []);
};

export const humanInterval = (req) => {
  if (!req.rrule) return '';
  return RRule.fromString(req.rrule).toText();
};

export const APPROVAL_PREFERENCES = [
  {
    label: 'Confirm Manually',
    sublabel: 'Orders will need to be confirmed by you before being placed.',
    value: false,
  },
  {
    label: 'Confirm Automatically',
    sublabel:
      'Orders will automatically be placed in time to arrive by the desired delivery date.',
    value: true,
  },
];

export const EVENT_STATES = {
  actionNeeded: 'actionNeeded',
  pending: 'pending',
  confirmed: 'confirmed',
  cancelled: 'cancelled',
};

export const EVENT_STATE_COLOR = '#00a98c';

export const asAgendaItemApproval = (sr) => {
  if (
    sr.approve_automatically ||
    isExpired(sr) ||
    isApproved(sr) ||
    isCancelled(sr)
  )
    return null;

  return {
    title: (
      <p>
        Confirm <strong>{sr.name}</strong> for {formatDate(sr.expected_at)} by{' '}
        <strong>{formatTime(sr.expiration_at)}</strong>
      </p>
    ),
    color: EVENT_STATE_COLOR,
    state: EVENT_STATES.actionNeeded,
    link: {
      path: `/orders/scheduled/${sr.id}/approve?from_calendar=true`,
      text: 'Confirm Order',
    },
    resource: sr,
    eventDate: sr.expiration_at,
  };
};

export const asAgendaItemArrival = (sr) => {
  if (isCancelled(sr)) return null;

  const needsApproval = !sr.approved_at && !sr.approve_automatically;
  let text;
  let url;
  if (needsApproval) {
    text = 'Confirm Order';
    url = `/orders/scheduled/${sr.id}/approve?from_calendar=true`;
  } else if (sr.requisition_id) {
    text = 'View Order';
    url = `/supplies/orders/${sr.requisition_id}?from_calendar=true`;
  } else {
    text = 'View Order';
    url = `/orders/scheduled/${sr.id}/approve?from_calendar=true`;
  }
  return {
    title: (
      <p>
        <strong>{sr.name}</strong> will arrive by{' '}
        <strong>{formatTime(sr.expected_at)}</strong>&nbsp;
        {needsApproval && 'if confirmed'}
      </p>
    ),
    color: EVENT_STATE_COLOR,
    state: needsApproval ? EVENT_STATES.pending : EVENT_STATES.confirmed,
    link: {
      path: url,
      text: text,
    },
    resource: sr,
    eventDate: sr.expected_at,
  };
};

export const asAgendaItemCancelled = (sr) => {
  if (!isCancelled(sr)) return null;
  return {
    title: (
      <p>
        <strong>{sr.name}</strong> for {formatDate(sr.expected_at)} was{' '}
        <strong>cancelled</strong>
      </p>
    ),
    subtitle: 'Reason: order was not confirmed in time',
    color: EVENT_STATE_COLOR,
    state: EVENT_STATES.cancelled,
    link: {
      path: `/orders/scheduled/${sr.id}?from_calendar=true`,
      text: 'View Order',
    },
    resource: sr,
    eventDate: sr.expected_at,
  };
};

export const agendaItemsForDate = (scheduledRequisitions, selectedDate) => {
  let agendaItems = [];
  const srs = scheduledRequisitions
    .filter(FILTER_NON_ARCHIVED)
    .filter(
      (sr) =>
        moment(sr.expected_at).isSame(selectedDate, 'day') ||
        moment(sr.expiration_at).isSame(selectedDate, 'day')
    );
  srs.forEach((sr) => {
    agendaItems.push(asAgendaItemApproval(sr));
    agendaItems.push(asAgendaItemArrival(sr));
    agendaItems.push(asAgendaItemCancelled(sr));
  });
  agendaItems = agendaItems.filter(
    (item) =>
      item !== null && moment(item.eventDate).isSame(selectedDate, 'day')
  );
  agendaItems = agendaItems.sort((a, b) => {
    if (moment(a.eventDate).isBefore(b.eventDate)) return -1;
    if (moment(b.eventDate).isBefore(a.eventDate)) return 1;
    return -1;
  });
  agendaItems = agendaItems.sort((a, b) => {
    if (a.state === EVENT_STATES.actionNeeded) return -1;
    if (b.state === EVENT_STATES.actionNeeded) return 1;
    return -1;
  });
  return agendaItems;
};

export const agendaItemStyle = (item) => {
  const base = { borderColor: item.color };
  switch (item.state) {
    case EVENT_STATES.confirmed:
      base.backgroundColor = item.color;
      break;
    case EVENT_STATES.pending:
      base.backgroundColor = 'white';
      base.borderColor = item.color;
      base.borderWidth = '2px';
      break;
    case EVENT_STATES.cancelled:
      base.color = '#f44336';
      break;
    default:
      break;
  }
  return base;
};

export const getQueryParams = (date) => {
  return `?startDate=${moment(date).toISOString()}`;
};

export const isSource = (schedReq) => {
  return schedReq.id === schedReq.source_id;
};

export const scheduleHasChanged = (sr1, sr2) => {
  if (!sr1 || !sr1.id) return false;
  if (!sr2 || !sr2.id) return false;
  return sr1.rrule !== sr2.rrule;
};
