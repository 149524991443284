import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { linkProductCategories } from '../lib/formatters.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

const getProductCategories = (data) => {
  return {
    type: types.GET_PRODUCT_CATEGORIES,
    data,
  };
};

const errorGetProductCategories = (data) => {
  return {
    type: types.ERROR_GET_PRODUCT_CATEGORIES,
    data,
  };
};

const successGetProductCategories = (data) => {
  return {
    type: types.SUCCESS_GET_PRODUCT_CATEGORIES,
    data,
  };
};

const getProductCategory = (data) => {
  return {
    type: types.GET_PRODUCT_CATEGORY,
    data,
  };
};

const errorGetProductCategory = (data) => {
  return {
    type: types.ERROR_GET_PRODUCT_CATEGORY,
    data,
  };
};

const successGetProductCategory = (data) => {
  return {
    type: types.SUCCESS_GET_PRODUCT_CATEGORY,
    data,
  };
};

const getCategoryProducts = (data) => {
  return {
    type: types.GET_CATEGORY_PRODUCTS,
    data,
  };
};

const errorGetCategoryProducts = (data) => {
  return {
    type: types.ERROR_GET_CATEGORY_PRODUCTS,
    data,
  };
};

const successGetCategoryProducts = (data) => {
  return {
    type: types.SUCCESS_GET_CATEGORY_PRODUCTS,
    data,
  };
};

const getPopularCategoryProducts = (data) => {
  return {
    type: types.GET_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

const successGetPopularCategoryProducts = (data) => {
  return {
    type: types.SUCCESS_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

const errorGetPopularCategoryProducts = (data) => {
  return {
    type: types.ERROR_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

const getLocationPopularCategoryProducts = (data) => {
  return {
    type: types.GET_LOCATION_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

const successGetLocationPopularCategoryProducts = (data) => {
  return {
    type: types.SUCCESS_LOCATION_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

const errorGetLocationPopularCategoryProducts = (data) => {
  return {
    type: types.ERROR_LOCATION_POPULAR_CATEGORY_PRODUCTS,
    data,
  };
};

export const openProductCategory = (data) => {
  return {
    type: types.OPEN_PRODUCT_CATEGORY,
    data,
  };
};

export const closeProductCategory = () => {
  return {
    type: types.CLOSE_PRODUCT_CATEGORY,
  };
};

/**
 * Wrapper function to get category products,
 * popular products, and popular location products
 *
 * @access public
 * @returns {Promise}
 */

export function doGetAllProductCategorySubsets(
  productCategory,
  loadAll = false
) {
  return (dispatch) => {
    dispatch(doGetCategoryProducts(productCategory, '', loadAll));
    dispatch(doGetPopularCategoryProducts(productCategory));
    dispatch(doGetLocationPopularCategoryProducts(productCategory));
  };
}

/**
 * Get all product categories
 *
 * @access public
 * @returns {Promise}
 */
export function doGetProductCategories() {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_PRODUCT_CATEGORIES,
      location_id: location.id,
    };
    dispatch(getProductCategories(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_categories/?limit=1000`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get product categories',
          });
        }
        return response.json();
      })
      .then((body) => {
        const linked = linkProductCategories(body.data);
        dispatch(successGetProductCategories(linked));
      })
      .catch((error) => {
        dispatch(errorGetProductCategories(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get product category
 *
 * @access public
 * @returns {Promise}
 */
export function doGetProductCategory(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    data.location_id = location.id;
    dispatch(getProductCategory(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_categories/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get product category' });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successGetProductCategory(body));
      })
      .catch((error) => {
        dispatch(errorGetProductCategory(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get products under the category
 *
 * @access public
 * @returns {Promise}
 */
/**
 * Get all product tired to category
 *
 * @access public
 * @param {Object} productCategory
 * @param {Number} productCategory.id
 * @param {String} [url] next page
 * @returns {Promise} redux-thunk
 */
export function doGetCategoryProducts(
  productCategory,
  url,
  loadAllPages = true
) {
  return (dispatch, getState) => {
    const { locations, productCategories } = getState();
    const location = locations.open;
    url =
      url ||
      `/locations/${location.id}/product_categories/${productCategory.id}/all_products`;
    const data = Object.assign(
      {
        _request: requestID(),
      },
      productCategory
    );
    const extantRequest = productCategories.requesting.filter(
      (r) => r._request === data._request
    ).length;
    if (extantRequest) {
      return;
    }
    dispatch(getCategoryProducts(data));
    return fetch(`${ADMIN_V1_URL}${url}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get category products',
          });
        }
        return response.json();
      })
      .then((body) => {
        const { locations } = getState();
        if (locations.open.id !== location.id) {
          return body;
        }
        if (loadAllPages && body.meta && body.meta.next_cursor) {
          dispatch(
            doGetCategoryProducts(
              productCategory,
              `/locations/${location.id}/product_categories/${productCategory.id}/all_products?cursor=${body.meta.next_cursor}`
            )
          );
        }
        dispatch(
          successGetCategoryProducts({
            id: productCategory.id,
            products: body.data,
            location_id: location.id,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(errorGetCategoryProducts(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get populuar products in category
 *
 * @access public
 * @returns {Promise}
 */
export function doGetPopularCategoryProducts(productCategory) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = Object.assign(
      {
        _request: requestID(),
        location_id: location.id,
      },
      productCategory
    );
    dispatch(getPopularCategoryProducts(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_categories/${productCategory.id}/popular_products?limit=50`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get popular category products',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successGetPopularCategoryProducts(
            Object.assign({}, productCategory, {
              popular_products: body.data,
              _request: data._request,
            })
          )
        );
      })
      .catch((error) => {
        dispatch(errorGetPopularCategoryProducts(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get populuar products for a location in a given category
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationPopularCategoryProducts(productCategory) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = Object.assign(
      {
        _request: requestID(),
        location_id: location.id,
      },
      productCategory
    );
    dispatch(getLocationPopularCategoryProducts(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_categories/${productCategory.id}/location_popular_products?limit=50`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get location popular category products',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successGetLocationPopularCategoryProducts(
            Object.assign({}, productCategory, {
              location_popular_products: body.data,
              _request: data._request,
            })
          )
        );
      })
      .catch((error) => {
        dispatch(errorGetLocationPopularCategoryProducts(error));
        trackFetchError(error);
      });
  };
}
