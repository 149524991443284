import * as types from '../actions/action-types.js';

export const empty = {
  items: [],
  creating: [],
  updating: [],
  open: { id: 0 },
  isRequesting: false,
  error: false,
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.POST_CREATE_TASK:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
        creating: state.creating
          .filter((t) => t._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_TASK:
      return Object.assign({}, state, {
        isRequesting: false,
        error: false,
        creating: state.creating.filter(
          (t) => t._request !== action.data._request
        ),
      });
    case types.ERROR_CREATE_TASK:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.POST_UPDATE_TASK:
    case types.POST_DELETE_TASK:
      return Object.assign({}, state, {
        isRequesting: true,
        error: false,
        updating: state.updating
          .filter((t) => t.id !== action.data.id)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_TASK:
      return Object.assign({}, state, {
        isRequesting: false,
        updating: state.updating.filter((t) => t.id !== action.data.id),
        items: state.items.map((t) => {
          if (t.id !== action.data.id) return t;
          return Object.assign({}, t, action.data);
        }),
      });
    case types.SUCCESS_DELETE_TASK:
      return Object.assign({}, state, {
        isRequesting: false,
        updating: state.updating.filter((t) => t.id !== action.data.id),
        items: state.items.filter((t) => t.id !== action.data.id),
      });
    case types.ERROR_UPDATE_TASK:
    case types.ERROR_DELETE_TASK:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
        updating: state.updating.filter((t) => t.id !== action.data.id),
      });
    case types.SHOW_CREATE_TASK_MODAL:
    case types.SHOW_TASK_MODAL:
      return Object.assign({}, state, {
        open: action.data,
      });
    case types.SHOW_TASK_MODAL_CONFIRMATION_DELETE:
      return Object.assign({}, state, {
        open: state.open.id
          ? Object.assign({}, state.open, { confirm_delete: true })
          : state.open,
      });
    case types.CLOSE_CREATE_TASK_MODAL:
    case types.CLOSE_TASK_MODAL:
    case types.CLOSE_TASK_MODAL_CONFIRMATION_DELETE:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
