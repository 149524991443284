import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Message } from 'semantic-ui-react';
import LocationListForm from '../supplies/location-list-form-edit.js';
import { closeLocationListEditModal } from '../../actions/location-list-actions.js';
import 'semantic-ui-css/components/message.min.css';
import './modal-common.css';
import './modal-update-location-list.css';

export class ModalUpdateLocationList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  componentWillUnmount() {
    this.successTimeout && window.clearTimeout(this.successTimeout);
  }

  unsetSuccess = () => {
    this.setState(() => {
      return {
        success: false,
      };
    });
    this.onRequestClose();
  };

  onSuccess = () => {
    this.setState(() => {
      return {
        success: true,
      };
    });
    this.successTimeout = window.setTimeout(this.unsetSuccess, 1000);
  };

  onRequestClose = () => {
    this.props.actions.closeLocationListEditModal();
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.openEditModal}
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.onAfterOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Edit Location List Modal"
      >
        <div className="modal-edit-location-list">
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
          <h3>Edit List</h3>
          <div className="location-list-form-edit-toggle">
            {!this.state.success && (
              <LocationListForm
                locationList={this.props.locationList}
                onSuccess={this.onSuccess}
              />
            )}
            {this.state.success && (
              <Message
                success
                header="Success!"
                content="Your list has been updated."
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalUpdateLocationList.propTypes = {
  actions: PropTypes.shape({
    closeLocationListEditModal: PropTypes.func.isRequired,
  }).isRequired,
  locationList: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  openEditModal: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    locationList: state.locationLists.open,
    openEditModal: state.locationLists.openEditModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeLocationListEditModal,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locationLists.openEditModal === next.locationLists.openEditModal &&
    prev.locationLists.open === next.locationLists.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalUpdateLocationList);
