import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import ProductSearchLocal from '../products/search-local.js';
import SearchResults from '../products/search-results.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './modal-common.css';
import './modal-product-search.css';
import {
  clearProductQueryResults,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import { searchResultSummary } from '../../lib/formatters.js';
import { trackInterfaceOpenProductSearchModal } from '../../lib/analytics.js';
import { filterInLimitedCatalog } from '../../helpers/product-helpers.js';

export class ModalProductSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      searchText: '',
      searchSubmitted: false,
    };
  }

  componentWillUnmount() {
    this.props.actions.clearProductQueryResults();
  }

  handleSearchSubmit = (search_data) => {
    const queryText = search_data
      ? search_data.text_searched
      : this.state.searchText;
    this.props.actions.doGetLocationProductQueryResults(queryText);
    this.setState(() => {
      return {
        searchSubmitted: true,
      };
    });
  };

  setSearchText = (value) => {
    this.setState({ searchText: value });
  };

  getTitle = () => {
    const text = this.state.searchText;
    const { query } = this.props;
    return searchResultSummary(text, query, query.isRequesting);
  };

  classModal = () => {
    return 'modal modal-product-search';
  };

  classModalOverlay() {
    return 'modal-overlay modal-overlay-task';
  }

  onRequestClose = () => {
    this.setState(() => {
      return {
        modalOpen: false,
        searchSubmitted: false,
        searchText: '',
      };
    });
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleTrigger = () => {
    this.setState(() => {
      return { modalOpen: true };
    });
    trackInterfaceOpenProductSearchModal();
  };

  render() {
    return (
      <div className="modal-product-search-wrap">
        <div className="modal-product-search-trigger-wrap">
          <Button
            size="mini"
            secondary
            className="modal-product-search-trigger"
            onClick={this.handleTrigger}
          >
            <Icon name="search" /> {this.props.triggerText}
          </Button>
        </div>
        <Modal
          appElement={document.getElementById('root')}
          isOpen={this.state.modalOpen}
          onRequestClose={this.onRequestClose}
          onAfterOpen={this.onAfterOpen}
          shouldCloseOnOverlayClick={true}
          className={{
            base: 'modal',
            afterOpen: 'modal_after-open',
            beforeClose: 'modal_before-close',
            toString: this.classModal,
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'modal-overlay_after-open',
            beforeClose: 'modal-overlay_before-close',
            toString: this.classModalOverlay,
          }}
          contentLabel="Add Products to your scheduled order"
        >
          <div className="modal-products-search">
            <button
              className="close-icon"
              type="button"
              onClick={this.handleCloseModal}
            >
              &times;
            </button>
            <div className="modal-product-search-container">
              <h4 className="subheader bold-text">{this.props.title}</h4>
              <div className="search-container">
                <ProductSearchLocal
                  onTextChange={this.setSearchText}
                  textValue={this.state.searchText}
                  secondaryFilter={this.props.secondaryFilter}
                  placeholder={this.props.placeholder}
                  onSearchSubmit={this.handleSearchSubmit}
                />
              </div>
              {!this.state.searchSubmitted && (
                <Segment placeholder>
                  <Header icon>
                    <Icon name="search" color="grey" />
                    {this.props.resultsPlaceholder}
                    <Header.Subheader>
                      {this.props.resultsPlaceholderSubhead}
                    </Header.Subheader>
                  </Header>
                </Segment>
              )}
              {this.state.searchSubmitted && (
                <div className="search-results-container">
                  <h3 className="catalog-title">{this.getTitle()}</h3>
                  <div
                    className={classnames('product-search-results-container', {
                      'no-matches-found': !this.props.query.results.length,
                    })}
                  >
                    <SearchResults
                      displayRequestItem={false}
                      displaySuggestions={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ModalProductSearch.propTypes = {
  query: PropTypes.shape({
    isRequesting: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    clearProductQueryResults: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  secondaryFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  resultsPlaceholder: PropTypes.string.isRequired,
  resultsPlaceholderSubhead: PropTypes.string.isRequired,
  triggerText: PropTypes.string.isRequired,
};

ModalProductSearch.defaultProps = {
  secondaryFilter: () => {
    return true;
  },
  title: 'Add Items to Your Order',
  placeholder: 'SEARCH FOR ADDITIONAL ITEMS',
  resultsPlaceholder: 'Search and add anything from the catalog',
  resultsPlaceholderSubhead: '',
  triggerText: 'Add Items',
};

function mapStateToProps(state) {
  const inLimitedViewingMode = state.application.inLimitedViewingMode;
  const in_catalog = state.products.in_catalog;
  const query = {
    ...state.productQuery,
    results: inLimitedViewingMode
      ? filterInLimitedCatalog(state.productQuery.results, in_catalog)
      : state.productQuery.results,
  };
  return {
    query,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearProductQueryResults,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productQuery === next.productQuery &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.products.in_catalog === next.products.in_catalog
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductSearch);
