import { formatDate, formatTime, formatDateTime } from '../lib/formatters.js';
import moment from 'moment';

export const sortByDateTimeDesc = (a, b) => {
  return a.datetime > b.datetime ? -1 : 1;
};

export const formatStatus = (shipment) => {
  if ((shipment.tracking_updates || []).length) {
    return shipment.tracking_updates.sort(sortByDateTimeDesc)[0].message;
  }
  return shipment.status.replace('_', ' ');
};

export const formatStatusDateTime = (shipment) => {
  let status = (shipment.tracking_updates || []).sort(sortByDateTimeDesc)[0];
  if (status && status.datetime) {
    return formatDateTime(status.datetime);
  }
  return formatDateTime(shipment.updated_at);
};

export const formatTrackingLocation = (tracking_location) => {
  if (!tracking_location.state) return '';
  return `${tracking_location.city}, ${tracking_location.state}`;
};

export const STATUS_COLOR_MAP = {
  pre_transit: 'orange',
  in_transit: 'darkorange',
  out_for_delivery: 'limegreen',
  delivered: '#555c6e',
};

export const isLate = (shipment) => {
  return new Date(shipment.expected_at_end) < new Date();
};

export const isNotLate = (shipment) => {
  return new Date(shipment.expected_at_end) > new Date();
};

export const lastMarkedAs = (shipment) => {
  if (shipment.missed_at && !shipment.received_at) return 'missing';
  if (shipment.received_at && !shipment.missed_at) return 'received';

  if (!shipment.missed_at || !shipment.received_at || !shipment.updated_at) {
    return null;
  }

  let updated = moment(shipment.updated_at);
  let missed = moment(shipment.missed_at);
  let received = moment(shipment.received_at);
  let duration_received = moment.duration(updated.diff(received));
  let duration_missed = moment.duration(updated.diff(missed));

  if (duration_missed < duration_received) {
    return 'missing';
  }

  if (duration_received < duration_missed) {
    return 'received';
  }
  return null;
};

export const formatDeliveryWindow = (shipment) => {
  if (!shipment.expected_at_start && !shipment.expected_at_end) {
    return 'TBD';
  }
  const endMoment = moment(shipment.expected_at_end);
  if (
    (!shipment.expected_at_start && shipment.expected_at_end) ||
    endMoment.isSame(moment(), 'day')
  ) {
    return `${formatDate(shipment.expected_at_end)} by ${formatTime(
      shipment.expected_at_end
    )}`;
  }
  let start = moment(shipment.expected_at_start, 'YYYY-MM-D');
  let end = endMoment.format('YYYY-MM-D');

  if (start.isSame(end, 'day')) {
    return `${formatDate(shipment.expected_at_end)}, ${formatTime(
      shipment.expected_at_start
    )}-${formatTime(shipment.expected_at_end)}`;
  } else {
    return `${formatDate(shipment.expected_at_start)}—${formatDate(
      shipment.expected_at_end
    )} by ${formatTime(shipment.expected_at_end)}`;
  }
};

export const isEasyPost = (shipment) =>
  shipment.tracking_url && shipment.tracking_url.match(/track\.easypost\.com/);

export const findRequisitionIds = (shipment, requisitions) => {
  const requ = requisitions.filter((req) =>
    req.shipmentIds.includes(shipment.id)
  );

  return requ.map((req) => req.id);
};
