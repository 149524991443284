import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';
import { submitUpdateLocation } from '../../actions/locations-actions.js';
import { parseNumberInput, twoDecimalPlaces } from '../../lib/formatters.js';
import 'semantic-ui-css/components/form.min.css';
import './office-budget-settings.css';

export class OfficeBudgetSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.constructState(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(Object.assign({}, this.state, this.constructState(props)));
  }

  constructState(props) {
    return {
      budget_annual: props.locations.reduce((acc, val) => {
        return acc + (val.budget_annual || 0);
      }, 0),
      number_of_employees: props.locations.reduce((acc, val) => {
        return acc + (val.number_of_employees || 0);
      }, 0),
    };
  }

  hasMultipleLocations = () => {
    return this.props.locations.length > 1;
  };

  getTitle = () => {
    if (this.hasMultipleLocations()) {
      return 'Total Spend Calculator';
    }
    return 'Spend Calculator';
  };

  handleChangeEmployeeNumber = (e, { value }) => {
    if (this.hasMultipleLocations()) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        number_of_employees: parseNumberInput(value) || '',
      })
    );
  };

  handleChangeBudgetAnnual = (e, { value }) => {
    if (this.hasMultipleLocations()) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        budget_annual: parseNumberInput(value) * 100 || '',
      })
    );
  };

  handleChangeBudgetMonthly = (e, { value }) => {
    if (this.hasMultipleLocations()) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        budget_annual: 12 * parseNumberInput(value) * 100 || '',
      })
    );
  };

  handleChangeBudgetPerEmployee = (e, { value }) => {
    if (this.hasMultipleLocations()) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        budget_annual:
          this.state.number_of_employees * 12 * parseNumberInput(value) * 100 ||
          '',
      })
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.actions.submitUpdateLocation({
      id: this.props.locations[0].id,
      budget_annual: this.state.budget_annual || undefined,
      number_of_employees: this.state.number_of_employees || undefined,
    });
  };

  valuesHaveChanged = () => {
    return (
      this.state.number_of_employees !==
        this.props.locations[0].number_of_employees ||
      this.state.budget_annual !== this.props.locations[0].budget_annual
    );
  };

  isDisabled = () => {
    return (
      !this.state.number_of_employees ||
      this.state.number_of_employees <= 0 ||
      !this.valuesHaveChanged() ||
      this.hasMultipleLocations()
    );
  };

  render() {
    return (
      <div className="office-budget-settings">
        <Form onSubmit={this.handleSubmit} loading={this.props.isRequesting}>
          <Form.Group widths="equal">
            <Form.Input
              icon="dollar"
              iconPosition="left"
              label="Annual Budget"
              placeholder="Annual Budget"
              value={twoDecimalPlaces(this.state.budget_annual)}
              onChange={this.handleChangeBudgetAnnual}
            />
            <Form.Input
              icon="dollar"
              iconPosition="left"
              label="Monthly Budget"
              placeholder="Monthly Budget"
              value={twoDecimalPlaces(this.state.budget_annual / 12)}
              onChange={this.handleChangeBudgetMonthly}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              icon="users"
              iconPosition="left"
              label="# of Employees"
              placeholder="# of Employees"
              value={this.state.number_of_employees.toLocaleString()}
              onChange={this.handleChangeEmployeeNumber}
            />
            <Form.Input
              icon="dollar"
              iconPosition="left"
              label="Spend/Employee/Month"
              placeholder="Per Employee Per Month Budget"
              value={twoDecimalPlaces(
                this.state.budget_annual / 12 / this.state.number_of_employees
              )}
              onChange={this.handleChangeBudgetPerEmployee}
            />
          </Form.Group>
          {!this.hasMultipleLocations() && (
            <Form.Button disabled={this.isDisabled()} secondary>
              Update Budget Numbers
            </Form.Button>
          )}
        </Form>
      </div>
    );
  }
}

OfficeBudgetSettings.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      budget_annual: PropTypes.number,
      number_of_employees: PropTypes.number,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  error: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isRequesting:
      state.locations.requesting.filter((e) => {
        return e.id === state.locations.open.id && !e.error;
      }).length > 0,
    error:
      state.locations.responses.filter((e) => {
        return e.id === state.locations.open.id && e.error;
      }).length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.requesting === next.locations.requesting &&
    prev.locations.responses === next.locations.responses
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeBudgetSettings);
