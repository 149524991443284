import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import CalendarAgendaItem from './calendar-agenda-item.js';
import { GET_SCHEDULED_REQUISITIONS } from '../../actions/action-types.js';
import { trackCalendarAgendaClick } from '../../lib/analytics.js';
import './calendar-agenda.css';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';

export class CalendarAgenda extends React.PureComponent {
  currentDay() {
    if (moment(this.props.selectedDate).isSame(moment(), 'day')) {
      return 'Today';
    } else {
      return moment(this.props.selectedDate).format('ddd, MMM Do');
    }
  }

  actions() {
    return this.props.agendaItems.filter(
      (item) => item.state === 'actionNeeded'
    );
  }

  arrivals() {
    return this.props.agendaItems.filter(
      (item) =>
        item.state === 'pending' ||
        item.state === 'confirmed' ||
        item.state === 'cancelled'
    );
  }

  goToPreviousDay = () => {
    const yesterday = moment(this.props.selectedDate)
      .subtract(1, 'day')
      .toDate();
    this.props.onDateSelect(yesterday);
    trackCalendarAgendaClick('previous day');
  };

  goToNextDay = () => {
    const tomorrow = moment(this.props.selectedDate).add(1, 'day').toDate();
    this.props.onDateSelect(tomorrow);
    trackCalendarAgendaClick('next day');
  };

  canGoBack() {
    return moment(this.props.selectedDate)
      .subtract(1, 'day')
      .endOf('day')
      .isBetween(
        moment().startOf('day'),
        moment().endOf('day').add(3, 'months')
      );
  }

  canGoForward() {
    return moment(this.props.selectedDate)
      .add(1, 'day')
      .startOf('day')
      .isBetween(
        moment().startOf('day'),
        moment().endOf('day').add(3, 'months')
      );
  }

  renderHeader(content) {
    return <Header as="h3" content={content} />;
  }

  renderGroup(items, placeholder) {
    if (items.length) {
      return (
        <Segment
          attached="top"
          style={{ background: 'white' }}
          loading={this.props.isRequesting}
        >
          {items.map((item) => (
            <CalendarAgendaItem
              key={`${item.resource.id}-${Math.random()}`}
              item={item}
            />
          ))}
        </Segment>
      );
    } else {
      return (
        <Segment
          attached="top"
          placeholder={true}
          style={{ minHeight: '100px', background: '#efefef' }}
        >
          <Header style={{ color: '#929292', fontSize: '1rem' }}>
            <Icon
              name="calendar outline"
              size="mini"
              style={{ color: '#929292', fontSize: '1rem' }}
            />
            {placeholder}
          </Header>
        </Segment>
      );
    }
  }

  render() {
    return (
      <div className="calendar-agenda">
        <Header as="h2" className="calendar-agenda__header">
          <Button
            className="calendar-agenda__icon-button"
            onClick={this.goToPreviousDay}
            disabled={!this.canGoBack()}
            title="Show previous day"
          >
            <Icon name="angle left" />
          </Button>
          <span>{this.currentDay()}</span>
          <Button
            className="calendar-agenda__icon-button"
            onClick={this.goToNextDay}
            disabled={!this.canGoForward()}
            title="Show next day"
          >
            <Icon name="angle right" />
          </Button>
        </Header>
        <div style={{ minHeight: '100px' }}>
          {this.renderHeader('Needs Confirmation')}
          {this.renderGroup(this.actions(), 'Nothing to confirm')}
          {this.renderHeader('Arriving Orders')}
          {this.renderGroup(this.arrivals(), 'Nothing arriving')}
        </div>
      </div>
    );
  }
}

CalendarAgenda.propTypes = {
  agendaItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.object,
      color: PropTypes.string,
      state: PropTypes.oneOf([
        'actionNeeded',
        'pending',
        'confirmed',
        'cancelled',
      ]),
      link: PropTypes.shape({
        path: PropTypes.string,
        text: PropTypes.string,
      }),
      resource: PropTypes.shape({
        id: PropTypes.number,
      }),
      eventDate: PropTypes.string,
    })
  ).isRequired,
  selectedDate: PropTypes.object.isRequired,
  onDateSelect: PropTypes.func.isRequired,
};

CalendarAgenda.defaultProps = {
  agendaItems: [],
  onDateSelect: () => {},
};

export function mapStateToProps(state, ownProps) {
  return {
    agendaItems: helpers.agendaItemsForDate(
      state.scheduledRequisitions.items,
      ownProps.selectedDate
    ),
    isRequesting: !!state.scheduledRequisitions.requesting.filter(
      (request) => request._request === GET_SCHEDULED_REQUISITIONS
    ).length,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items &&
    prev.scheduledRequisitions.requesting ===
      next.scheduledRequisitions.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CalendarAgenda
);
