import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import EmployeeStipendDetails from '../employees/employee-stipend-details.js';
import '../modals/modal-common.css';

const ModalStipend = (props) => {
  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={props.modalOpen}
      onRequestClose={() => props.setModalOpen(false)}
      shouldCloseOnOverlayClick={true}
      contentLabel="Stipend Information"
      className={{
        base: 'modal',
        afterOpen: 'modal_after-open',
        beforeClose: 'modal_before-close',
      }}
      overlayClassName={{
        base: 'modal-overlay',
        afterOpen: 'modal-overlay_after-open',
        beforeClose: 'modal-overlay_before-close',
      }}
    >
      <div className="modal-stipend-info">
        <button
          className="close-icon"
          type="button"
          onClick={() => props.setModalOpen(false)}
        >
          &times;
        </button>
        <EmployeeStipendDetails />
      </div>
    </Modal>
  );
};

ModalStipend.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};

export default ModalStipend;
