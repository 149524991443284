import fetch from '../lib/hmac-fetch';
import {
  trackFetchError,
  trackRequisitionSubmitted,
  trackRequisitionUpdated,
  trackRequisitionSubmittedForApproval,
  trackRequisitionFailedToSubmit,
} from '../lib/analytics.js';
import { composeSearchParams } from '../lib/formatters.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import {
  SWITCH_LOCATION,
  CREATE_PRODUCT_REQUISITION,
  UPDATE_PRODUCT_REQUISITION,
  DELETE_PRODUCT_REQUISITION,
  SUCCESS_DELETE_PRODUCT_REQUISITION,
  SUCCESS_UPDATE_PRODUCT_REQUISITION,
  SUCCESS_CREATE_PRODUCT_REQUISITION,
  ERROR_DELETE_PRODUCT_REQUISITION,
  ERROR_UPDATE_PRODUCT_REQUISITION,
  ERROR_CREATE_PRODUCT_REQUISITION,
  POST_CHECKOUT,
  POST_APPROVAL,
  SUCCESS_CHECKOUT,
  ERROR_CHECKOUT,
} from '../actions/action-types.js';
import {
  populateOptimizedProductRequisitions,
  findYourCart,
} from '../helpers/cart-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';

export const ERROR_TIMEOUT = 5000;

// Actions
export const GET_CARTS = 'GET_CARTS';
export const SUCCESS_GET_CARTS = 'SUCCESS_GET_CARTS';
export const ERROR_GET_CARTS = 'ERROR_GET_CARTS';

export const GET_CART = 'GET_CART';
export const SUCCESS_GET_CART = 'SUCCESS_GET_CART';
export const ERROR_GET_CART = 'ERROR_GET_CART';

export const GET_CART_PUNCHOUT_ORDER_MESSAGE =
  'GET_CART_PUNCHOUT_ORDER_MESSAGE';
export const SUCCESS_GET_CART_PUNCHOUT_ORDER_MESSAGE =
  'SUCCESS_GET_CART_PUNCHOUT_ORDER_MESSAGE';
export const ERROR_GET_CART_PUNCHOUT_ORDER_MESSAGE =
  'ERROR_GET_CART_PUNCHOUT_ORDER_MESSAGE';

export const UPDATE_CART = 'UPDATE_CART';
export const SUCCESS_UPDATE_CART = 'SUCCESS_UPDATE_CART';
export const ERROR_UPDATE_CART = 'ERROR_UPDATE_CART';

export const OPEN_CART = 'OPEN_CART';
export const CLOSE_CART = 'CLOSE_CART';

export const UNSET_CART_RESPONSE = 'UNSET_CART_RESPONSE';

export const OPEN_CART_CONFIG = 'OPEN_CART_CONFIG';
export const CLOSE_CART_CONFIG = 'CLOSE_CART_CONFIG';

export const STAGE_CART = 'STAGE_CART';
export const UNSTAGE_CART = 'UNSTAGE_CART';
export const UPDATE_STAGED_CART = 'UPDATE_STAGED_CART';

// Helpers
export const FILTER_GET_INDEX_REQUEST = (req) => req.type === GET_CARTS;

export const FILTER_IS_LOADING_CART = function (cart) {
  return function (req) {
    return (
      (req.type === GET_CART || req.type === UPDATE_CART) &&
      req.data.id === cart.id
    );
  };
};

export const FILTER_IS_LOADING = (req) =>
  req.type === GET_CARTS ||
  req.type === GET_CART ||
  req.type === GET_CART_PUNCHOUT_ORDER_MESSAGE;

export const FILTER_IS_UPDATE_SUCCESS = (req) =>
  req.type === SUCCESS_UPDATE_CART ||
  req.type === SUCCESS_UPDATE_PRODUCT_REQUISITION;

export const FILTER_IS_SAVE_SUCCESS = (req) => req.type === SUCCESS_UPDATE_CART;

export const FILTER_IS_SUBMITTING = (req) =>
  req.type === UPDATE_CART || req.type === UPDATE_PRODUCT_REQUISITION;

export const FILTER_IS_ERROR = (req) => req.type === ERROR_UPDATE_CART;

export const FILTER_IS_PUNCHOUT_ORDER_MESSAGE_ERROR = (req) =>
  req.type === ERROR_GET_CART_PUNCHOUT_ORDER_MESSAGE;

const initialOpenCart = {
  id: 0,
  product_requisitions: [],
  approval_details: {},
};
const initialQuery = window.location.search.match(/cart(_id)?=([0-9]+)/);

// Reducer
export const empty = {
  items: {},
  punchoutOrderMessages: {},
  open: populateOptimizedProductRequisitions(initialOpenCart),
  staged: initialOpenCart,
  meta: {
    cursor: 0,
    next_cursor: 0,
    prev_cursor: 0,
    total: 0,
  },
  requesting: [],
  responses: [],
  cartConfigModalOpen: false,
};

export const initial = Object.assign({}, empty, {
  open: {
    ...empty.open,
    id: initialQuery ? parseInt(initialQuery[2], 10) : empty.open.id,
  },
});

export default (state = initial, action) => {
  let items;
  let foundItem;
  switch (action.type) {
    case GET_CARTS:
    case GET_CART:
    case UPDATE_CART:
    case GET_CART_PUNCHOUT_ORDER_MESSAGE:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_CARTS:
      return {
        ...state,
        items:
          state.meta.location_id &&
          state.meta.location_id !== action.data.meta.location_id
            ? state.items
            : action.data.items.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
              }, {}),
        open: populateOptimizedProductRequisitions({
          ...(action.data.items.filter(
            (item) => item.id === state.open.id
          )[0] ||
            action.data.open ||
            action.data.items[0]),
        }),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
        meta: action.data.meta,
      };
    case ERROR_GET_CARTS:
    case ERROR_UPDATE_CART:
    case ERROR_GET_CART:
    case ERROR_GET_CART_PUNCHOUT_ORDER_MESSAGE:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_GET_CART:
    case SUCCESS_UPDATE_CART:
      items = { ...state.items };
      foundItem = items[action.data.item.id];
      if (
        foundItem ||
        (action.data.item.is_public &&
          state.meta.location_id === action.data.item.location_id)
      ) {
        items[action.data.item.id] = action.data.item;
      }
      return {
        ...state,
        items: items,
        open:
          action.data.item.id === state.open.id
            ? populateOptimizedProductRequisitions(action.data.item)
            : state.open,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_CART_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case OPEN_CART:
      return {
        ...state,
        open: populateOptimizedProductRequisitions(
          state.items[action.data.id] || state.open
        ),
      };
    case CLOSE_CART:
      return {
        ...state,
        open: empty.open,
      };
    /*** Punchout Order Messages ***/
    case SUCCESS_GET_CART_PUNCHOUT_ORDER_MESSAGE:
      items = { ...state.punchoutOrderMessages };
      items[action.data.item.id] = action.data.item.punchout_order_message;
      return {
        ...state,
        punchoutOrderMessages: items,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };

    /*** Product Requisitions ***/
    case CREATE_PRODUCT_REQUISITION:
    case UPDATE_PRODUCT_REQUISITION:
    case DELETE_PRODUCT_REQUISITION:
      items = { ...state.items };
      foundItem = items[action.data.requisition_id];
      if (!foundItem) return { ...state };

      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_CREATE_PRODUCT_REQUISITION:
      items = { ...state.items };
      foundItem = items[action.data.requisition_id];
      if (!foundItem) return { ...state };

      items[action.data.requisition_id] = {
        ...items[action.data.requisition_id],
        product_requisitions: items[
          action.data.requisition_id
        ].product_requisitions
          .filter((pr) => pr.id !== action.data.id)
          .concat(action.data),
      };
      return {
        ...state,
        items: items,
        open:
          state.open.id === action.data.requisition_id
            ? populateOptimizedProductRequisitions({
                ...state.open,
                product_requisitions: state.open.product_requisitions
                  .filter((pr) => pr.id !== action.data.id)
                  .concat(action.data),
              })
            : populateOptimizedProductRequisitions(state.open),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_UPDATE_PRODUCT_REQUISITION:
      items = { ...state.items };
      foundItem = items[action.data.requisition_id];
      if (!foundItem) return { ...state };

      items[action.data.requisition_id] = {
        ...items[action.data.requisition_id],
        product_requisitions: items[
          action.data.requisition_id
        ].product_requisitions.map((pr) => {
          if (pr.id !== action.data.id) return pr;
          return { ...pr, ...action.data };
        }),
      };
      return {
        ...state,
        items: items,
        open:
          state.open.id === action.data.requisition_id
            ? populateOptimizedProductRequisitions({
                ...state.open,
                product_requisitions: state.open.product_requisitions.map(
                  (pr) => {
                    if (pr.id !== action.data.id) return pr;
                    return { ...pr, ...action.data };
                  }
                ),
              })
            : populateOptimizedProductRequisitions(state.open),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_DELETE_PRODUCT_REQUISITION:
      items = { ...state.items };
      foundItem = items[action.data.requisition_id];
      if (!foundItem) return { ...state };

      items[action.data.requisition_id] = {
        ...items[action.data.requisition_id],
        product_requisitions: items[
          action.data.requisition_id
        ].product_requisitions.filter((pr) => pr.id !== action.data.id),
      };
      return {
        ...state,
        items: items,
        open:
          state.open.id === action.data.requisition_id
            ? populateOptimizedProductRequisitions({
                ...state.open,
                product_requisitions: state.open.product_requisitions.filter(
                  (pr) => pr.id !== action.data.id
                ),
              })
            : populateOptimizedProductRequisitions(state.open),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case ERROR_CREATE_PRODUCT_REQUISITION:
    case ERROR_UPDATE_PRODUCT_REQUISITION:
    case ERROR_DELETE_PRODUCT_REQUISITION:
      items = { ...state.items };
      foundItem = items[action.data.requisition_id];
      if (!foundItem) return { ...state };

      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case OPEN_CART_CONFIG:
      return {
        ...state,
        cartConfigModalOpen: true,
      };
    case CLOSE_CART_CONFIG:
      return {
        ...state,
        cartConfigModalOpen: false,
      };
    case STAGE_CART:
      return {
        ...state,
        staged: state.items[action.data.id] || empty.staged,
      };
    case UNSTAGE_CART:
      return {
        ...state,
        staged: empty.staged,
      };
    case UPDATE_STAGED_CART:
      return {
        ...state,
        staged: {
          ...state.staged,
          ...action.data,
        },
      };
    case SWITCH_LOCATION:
      return {
        ...state,
        open: state.open,
        meta: empty.meta,
      };
    default:
      return state;
  }
};

// Action Creators
export const getCarts = (data) => ({ data, type: GET_CARTS });
export const successGetCarts = (data) => ({ data, type: SUCCESS_GET_CARTS });
export const errorGetCarts = (data) => ({ data, type: ERROR_GET_CARTS });
export const getCart = (data) => ({ data, type: GET_CART });
export const successGetCart = (data) => ({ data, type: SUCCESS_GET_CART });
export const errorGetCart = (data) => ({ data, type: ERROR_GET_CART });
export const getCartPunchoutOrderMessage = (data) => ({
  data,
  type: GET_CART_PUNCHOUT_ORDER_MESSAGE,
});
export const successGetCartPunchoutOrderMessage = (data) => ({
  data,
  type: SUCCESS_GET_CART_PUNCHOUT_ORDER_MESSAGE,
});
export const errorGetCartPunchoutOrderMessage = (data) => ({
  data,
  type: ERROR_GET_CART_PUNCHOUT_ORDER_MESSAGE,
});
export const updateCart = (data) => ({ data, type: UPDATE_CART });
export const successUpdateCart = (data) => ({
  data,
  type: SUCCESS_UPDATE_CART,
});
export const errorUpdateCart = (data) => ({ data, type: ERROR_UPDATE_CART });
export const unsetCartResponse = (data) => ({
  data,
  type: UNSET_CART_RESPONSE,
});
export const postCheckout = (data) => ({ data, type: POST_CHECKOUT });
export const successCheckout = (data) => {
  trackRequisitionSubmitted(data);
  return {
    type: SUCCESS_CHECKOUT,
    data,
  };
};
export const errorCheckout = (data) => ({ data, type: ERROR_CHECKOUT });
export const postApproval = (data) => ({ data, type: POST_APPROVAL });
export const openCart = (data) => ({ data, type: OPEN_CART });
export const openCartConfig = () => ({ type: OPEN_CART_CONFIG });
export const closeCartConfig = () => ({ type: CLOSE_CART_CONFIG });
export const stageCart = (data) => ({ data, type: STAGE_CART });
export const unstageCart = () => ({ type: UNSTAGE_CART });
export const updateStagedCart = (data) => ({ data, type: UPDATE_STAGED_CART });

/// THUNKS
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetCartResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetCarts = (data = {}) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getCarts(data));
    let params = {};
    const openSession = getState().punchoutSessions.open;
    if (openSession.access_token) {
      params.session_access_token = openSession.access_token;
    }
    if (data.cursor) {
      params.cursor = data.cursor;
    }
    let url = `${ADMIN_V1_URL}/locations/${
      data.location_id
    }/carts?${composeSearchParams(params)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get Carts' });
        }
        return response.json();
      })
      .then((body) => {
        const actor = getState().auth.actor;
        dispatch(
          doAndUnsetResponse(successGetCarts)({
            open: findYourCart(body.data, actor),
            items: body.data,
            meta: { ...body.meta, location_id: data.location_id },
            _request: data._request,
          })
        );
        if (body.meta && body.meta.next_cursor) {
          dispatch(doGetCarts({ ...data, cursor: body.meta.next_cursor }));
        }
        return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetCarts)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doGetCart = (data = {}) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getCart(data));
    let location_id = data.location_id || getState().locations.open.id;
    return fetch(`${ADMIN_V1_URL}/locations/${location_id}/carts/${data.id}`, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get Cart' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetCart)({
            item: body,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetCart)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doUpdateCart = (data, options = { bubble: false }) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(updateCart(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/carts/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Update Cart' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateCart)({
            item: body,
            _request: data._request,
          })
        );
        trackRequisitionUpdated(body);
        if (body.is_public) {
          dispatch(
            doGetCarts({
              location_id: data.location_id,
            })
          );
        }
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateCart)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doCheckout = (data = {}) => {
  return (dispatch) => {
    if (data.awaiting_approval_at) {
      dispatch(doAwaitApproval(data));
    } else {
      dispatch(doSubmit(data));
    }
  };
};

const doSubmit = (data = {}) => {
  return (dispatch, getState) => {
    data = {
      age_confirmation: data.age_confirmation,
      id: data.id,
      instructions: data.instructions,
      name: data.name,
      notes: data.notes,
      shipping_address: data.shipping_address,
      shipping_address_number: data.shipping_address_number,
      shipping_business: data.shipping_business,
      shipping_care: data.shipping_care,
      shipping_city: data.shipping_city,
      shipping_name: data.shipping_name,
      shipping_state: data.shipping_state,
      shipping_country: data.shipping_country,
      shipping_zip: data.shipping_zip,
      _request: data._request || requestID(),
    };
    let location_id = getState().locations.open.id;
    dispatch(postCheckout(data));
    dispatch(updateCart(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location_id}/carts/${data.id}/submit`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Submit Cart' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateCart)({
            item: body,
            _request: data._request,
          })
        );
        window.totango.track('User Placed An Order.', 'Marketplace');
        trackRequisitionUpdated(body);
        dispatch(successCheckout(body));
        dispatch(
          enqueueConfirmation({
            message: 'Your order has been placed',
            type: 'success',
          })
        );
        if (['draft', 'pending'].indexOf(body.status) < -1) {
          dispatch(
            doGetCarts({
              location_id: body.location_id,
            })
          );
        }
        setTimeout(() => {
          // super hacky but needed to prevent people editing a placed order on accident
          window.location.reload();
        }, '1500');
      })
      .catch((err) => {
        dispatch(
          doAndUnsetResponse(errorUpdateCart)({
            err,
            _request: data._request,
          })
        );
        dispatch(
          errorCheckout({
            error: err.toString(),
            id: data.id,
          })
        );
        dispatch(
          enqueueConfirmation({
            message: 'Failed to place order',
            type: 'error',
          })
        );
        trackFetchError(err);
        trackRequisitionFailedToSubmit(data, err.toString());
      });
  };
};

const doAwaitApproval = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(postApproval(data));
    return doUpdateCart(
      {
        ...data,
        location_id: getState().locations.open.id,
      },
      { bubble: true }
    )(dispatch, getState)
      .then((body) => {
        trackRequisitionSubmittedForApproval(body);
        dispatch(
          enqueueConfirmation({
            message: 'Your order is now awaiting approval.',
            type: 'success',
          })
        );
        if (['draft', 'pending'].indexOf(body.status) < -1) {
          dispatch(
            doGetCarts({
              location_id: body.location_id,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          errorCheckout({
            error: err.toString(),
            id: data.id,
          })
        );
        dispatch(
          enqueueConfirmation({
            message: 'Failed to place order for approval',
            type: 'error',
          })
        );
        trackRequisitionFailedToSubmit(data, err.toString());
      });
  };
};

export const doGetCartPunchoutOrderMessage = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getCartPunchoutOrderMessage(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/carts/${data.id}/punchout_order_message.xml`,
      {
        method: 'GET',
        headers: {
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get Cart Punchout Order Message',
          });
        }
        return response.text();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetCartPunchoutOrderMessage)({
            item: {
              id: data.id,
              punchout_order_message: body,
            },
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetCartPunchoutOrderMessage)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
