import * as types from '../actions/action-types.js';

const initialQuery = window.location.search.match(/[&|?]+list=([0-9]+)/);

export const empty = {
  initialized: false,
  items: [],
  updating: [],
  creating: [],
  open: { id: 0 },
  requesting: [],
  errors: [],
  openCreateModal: false,
  openEditModal: false,
};
const initialOpen = {
  ...empty.open,
  id: (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id,
};
export const initial = Object.assign({}, empty, {
  open: initialOpen,
});

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_LOCATION_LISTS:
      return Object.assign({}, state, {
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_LOCATION_LISTS,
            },
            action.data
          )
        ),
      });
    case types.SUCCESS_LOCATION_LISTS:
      items = action.data.map((ll) => ll.id);
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (ll) => ll._request !== types.GET_LOCATION_LISTS
        ),
        items: state.items
          .filter(
            (ll) =>
              items.indexOf(ll.id) < 0 &&
              ll.location_id === action.data.location_id
          )
          .concat(action.data),
        open: {
          ...state.open,
          ...action.data.filter((s) => s.id === state.open.id)[0],
        },
      });
    case types.ERROR_LOCATION_LISTS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (ll) => ll._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_LOCATION_LIST:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_LOCATION_LIST:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (ll) => ll._request !== action.data._request
        ),
        items: state.items
          .filter((ll) => ll.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_LOCATION_LIST:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (ll) => ll._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_LOCATION_LIST:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((ll) => ll._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_LOCATION_LIST:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (ll) => ll._request !== action.data._request
        ),
        items: state.items.concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_CREATE_LOCATION_LIST:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (ll) => ll._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.DELETE_LOCATION_LIST:
    case types.UPDATE_LOCATION_LIST:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((ll) => ll._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_LOCATION_LIST:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (ll) => ll._request !== action.data._request
        ),
        items: state.items
          .filter((ll) => ll.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.SUCCESS_DELETE_LOCATION_LIST:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (ll) => ll._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? state.items.filter((ll) => ll.id !== action.data.id)[0] ||
              empty.open
            : state.open,
        items: state.items.filter((ll) => ll.id !== action.data.id),
      });
    case types.ERROR_DELETE_LOCATION_LIST:
    case types.ERROR_UPDATE_LOCATION_LIST:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((ll) => ll._request !== action.data._request)
          .concat(action.data),
        errors: state.errors.concat(action.data),
      });
    case types.OPEN_LOCATION_LIST:
      return Object.assign({}, state, {
        open:
          state.items.filter((t) => t.id === action.data.id)[0] || action.data,
      });
    case types.CLOSE_LOCATION_LIST:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.OPEN_LOCATION_LIST_CREATE_MODAL:
      return Object.assign({}, state, {
        openCreateModal: true,
      });
    case types.CLOSE_LOCATION_LIST_CREATE_MODAL:
      return Object.assign({}, state, {
        openCreateModal: false,
      });
    case types.OPEN_LOCATION_LIST_EDIT_MODAL:
      return Object.assign({}, state, {
        openEditModal: true,
      });
    case types.CLOSE_LOCATION_LIST_EDIT_MODAL:
      return Object.assign({}, state, {
        openEditModal: false,
      });
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
