import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message } from 'semantic-ui-react';
import { submitUserDetails } from '../../actions/onboarding-actions.js';
import { PASSWORD_LENGTH } from './../../strings.js';
import { trackEmployeeOnboard } from '../../lib/analytics.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';
import { isAdmin } from '../../helpers/customer-role-helpers.js';

export class OnboardingUserForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const employee = props.onboarding.employee || {};
    this.state = {
      email: employee.email || '',
      first_name: employee.first_name || '',
      last_name: employee.last_name || '',
      title: employee.title || '',
      password: '',
      agree_terms: false,
      errorPassword: null,
      errorEmail: null,
      errorFirstName: null,
      errorLastName: null,
      errorTitle: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const employee = nextProps.onboarding.employee || {};
    this.setState(() => {
      return {
        email: employee.email || '',
        first_name: employee.first_name || '',
        last_name: employee.last_name || '',
        title: employee.title || '',
        errorEmail: null,
        errorFirstName: null,
        errorLastName: null,
        errorTitle: null,
      };
    });
  }

  handleChangeEmail = (e, { value }) => {
    this.setState(() => {
      return {
        email: value.trim(),
        errorEmail: null,
      };
    });
  };

  handleChangeFirstName = (e, { value }) => {
    this.setState(() => {
      return {
        first_name: value,
        errorFirstName: null,
      };
    });
  };

  handleChangeLastName = (e, { value }) => {
    this.setState(() => {
      return {
        last_name: value,
        errorLastName: null,
      };
    });
  };

  handleChangePassword = (e, { value }) => {
    this.setState(() => {
      return {
        password: value,
        errorPassword: null,
      };
    });
  };

  handleChangeToS = (e) => {
    let agree_terms = !!e.target.checked;
    this.setState(() => {
      return {
        agree_terms,
      };
    });
  };

  handleChangeTitle = (e, { value }) => {
    this.setState(() => {
      return {
        title: value,
        errorTitle: null,
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
    if (this.isDisabled()) return;
    this.props.actions.submitUserDetails(this.state);
    this.props.onSubmit(this.state);
    trackEmployeeOnboard(this.state);
  };

  isDisabled = () => {
    return (
      this.props.onboarding.isRequesting ||
      (!this.state.agree_terms && !this.hideCheckbox()) ||
      !this.state.title ||
      this.state.password.length < PASSWORD_LENGTH ||
      !this.state.first_name.trim() ||
      !this.state.last_name.trim() ||
      !this.state.email
    );
  };

  hasErrors = () => {
    return !!(
      this.state.errorLastName ||
      this.state.errorFirstName ||
      this.state.errorEmail ||
      this.state.errorPassword ||
      this.props.onboarding.error ||
      this.state.errorTitle
    );
  };

  validate = () => {
    this.setState((prev) => {
      return {
        errorLastName: prev.last_name.trim() ? null : 'Required',
        errorFirstName: prev.first_name.trim() ? null : 'Required',
        errorEmail: prev.email ? null : 'Required',
        errorTitle: prev.title ? null : 'Required',
        errorPassword:
          prev.password.length < PASSWORD_LENGTH
            ? `Password must be at least ${PASSWORD_LENGTH} characters`
            : null,
      };
    });
  };

  brandColor = () => {
    let color = '#00a98c';
    const employee = this.props.onboarding.employee || {};
    if (!employee.locations || !employee.locations[0]) return color;

    return employee.locations[0].brand_color || color;
  };

  hideCheckbox = () => {
    const employee = this.props.onboarding.employee || {};
    if (!employee.locations || !employee.locations[0]) return false;

    return employee.locations[0].pref_terms_exempt || false;
  };

  render() {
    return (
      <div className="onboarding-user-form">
        <h1
          style={{
            marginBottom: '1rem',
          }}>
          Welcome!
        </h1>

        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}>
          {isAdmin(this.props.roleName)
            ? 'The OfficeLuv platform consolidates all your office ordering into one place. Plus, you get access to budget and analytic tools, order tracking, recurring orders and more!'
            : 'Our marketplace aggregates a curated selection of products from a variety of vendors. If we don’t carry something that you’re looking for, just ask!'}
        </p>

        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}>
          {isAdmin(this.props.roleName)
            ? 'Please take a few minutes to complete your profile to unlock all of OfficeLuv’s features.'
            : 'Please fill out the following information to start shopping!'}
        </p>
        <Form
          loading={this.props.onboarding.isRequesting}
          onSubmit={this.handleSubmit}
          error={this.hasErrors()}>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              autoFocus
              error={this.state.errorFirstName}
              name="first_name"
              label="First Name"
              placeholder="First name"
              value={this.state.first_name}
              onChange={this.handleChangeFirstName}
            />
            <Form.Input
              required
              fluid
              error={this.state.errorLastName}
              name="last_name"
              label="Last Name"
              placeholder="Last name"
              value={this.state.last_name}
              onChange={this.handleChangeLastName}
            />
          </Form.Group>
          <Form.Input
            required
            fluid
            error={this.state.errorTitle}
            name="title"
            label="Job Title"
            placeholder="Enter your title"
            value={this.state.title}
            onChange={this.handleChangeTitle}
          />
          <Form.Input
            required
            fluid
            error={this.state.errorEmail}
            name="email"
            label="Email"
            placeholder="Enter your email"
            value={this.state.email}
            onChange={this.handleChangeEmail}
          />
          <Form.Input
            required
            error={this.state.errorPassword}
            name="password"
            label={`Password (at least ${PASSWORD_LENGTH} characters)`}
            type="password"
            placeholder="Set a password"
            value={this.state.password}
            onChange={this.handleChangePassword}
          />
          {!this.hideCheckbox() && (
            <div className="field">
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  className="hidden"
                  name="agree_terms"
                  id="agree_terms"
                  tabIndex="0"
                  onChange={this.handleChangeToS}
                  checked={this.state.agree_terms}
                />
                <label
                  htmlFor="agree_terms"
                  style={{
                    fontSize: '.875rem',
                  }}>
                  I accept the the&nbsp;
                  <a
                    href="https://www.officeluv.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      color: this.brandColor(),
                      textDecoration: 'none',
                    }}>
                    Privacy Policy
                  </a>{' '}
                  and&nbsp;
                  <a
                    href="https://www.officeluv.com/terms-of-service"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      color: this.brandColor(),
                      textDecoration: 'none',
                    }}>
                    Terms of Service
                  </a>
                  .
                </label>
              </div>
            </div>
          )}

          <Message error>
            <Message.Header>Error</Message.Header>
            <Message.List>
              {(this.state.errorEmail ||
                this.state.errorFirstName ||
                this.state.errorLastName ||
                this.state.errorTitle) && (
                <Message.Item>Please fill out required fields</Message.Item>
              )}
              {this.state.errorPassword && (
                <Message.Item>{this.state.errorPassword}</Message.Item>
              )}
              {this.props.onboarding.error && (
                <Message.Item>
                  {this.props.onboarding.error.message}
                </Message.Item>
              )}
            </Message.List>
          </Message>
          <Form.Group
            style={{
              marginTop: '2rem',
            }}>
            <Form.Button
              width={8}
              fluid
              primary
              style={{
                backgroundColor: this.brandColor(),
              }}>
              {isAdmin(this.props.roleName) ? 'Next' : 'Get Started'}
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

OnboardingUserForm.propTypes = {
  onboarding: PropTypes.shape({
    isRequesting: PropTypes.bool.isRequired,
    employee: PropTypes.shape({
      email: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          brand_color: PropTypes.string,
        })
      ).isRequired,
    }),
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ]).isRequired,
  }).isRequired,
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    submitUserDetails: PropTypes.func.isRequired,
  }).isRequired,
};

OnboardingUserForm.defaultProps = {
  stepTotal: 5,
  stepNumber: 1,
  onSubmit: function () {},
};

function mapStateToProps(state) {
  return {
    onboarding: state.onboarding,
    roleName: state.auth.role.name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ submitUserDetails }, dispatch),
  };
}

function areStatesEqual(prev, next) {
  return prev.onboarding === next.onboarding;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingUserForm);
