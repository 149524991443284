// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class BeforeUnload extends React.PureComponent {
  static propTypes = {
    isRequesting: PropTypes.bool,
  };

  componentDidMount() {
    if (!this.props.isRequesting) {
      return;
    }

    window.onbeforeunload = this.beforeUnload;
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!props.isRequesting) {
      window.onbeforeunload = null;
      return;
    }

    window.onbeforeunload = this.beforeUnload;
  }

  beforeUnload(e) {
    let dialogText =
      'Your pending changes may be lost. Do you still want to close this page?';
    e.returnValue = dialogText;
    return dialogText;
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    isRequesting:
      state.tasks.creating.length > 0 ||
      state.tasks.updating.length > 0 ||
      state.cleanings.creating.length > 0 ||
      state.cleanings.updating.length > 0 ||
      state.requisitions.creating.length > 0 ||
      state.requisitions.updating.length > 0 ||
      state.locationLists.creating.length > 0 ||
      state.locationLists.updating.length > 0 ||
      state.locationListProducts.creating.length > 0 ||
      state.locationListProducts.updating.length > 0 ||
      (state.requisitions.editing.length > 0 &&
        JSON.stringify(state.requisitions.editing[0]) !==
          JSON.stringify(state.requisitions.open)),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.tasks.creating === next.tasks.creating &&
    prev.tasks.updating === next.tasks.updating &&
    prev.cleanings.creating === next.cleanings.creating &&
    prev.cleanings.updating === next.cleanings.updating &&
    prev.requisitions.creating === next.requisitions.creating &&
    prev.requisitions.updating === next.requisitions.updating &&
    prev.locationLists.creating === next.locationLists.creating &&
    prev.locationLists.updating === next.locationLists.updating &&
    prev.locationListProducts.creating === next.locationListProducts.creating &&
    prev.locationListProducts.updating === next.locationListProducts.updating &&
    prev.requisitions.editing === next.requisitions.editing
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BeforeUnload);
