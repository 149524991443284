import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackProductRequisitionBudgetCodeUpdated,
  trackRequisitionUpdated,
  trackRequisitionSubmitted,
  trackProductRequisitionCreated,
  trackProductRequisitionUpdated,
  trackProductRequisitionDeleted,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import {
  ERROR_TIMEOUT as CART_RESPONSE_TIMEOUT,
  unsetCartResponse,
} from '../ducks/carts.js';

export const getRequisitions = (data) => {
  return {
    type: types.GET_REQUISITIONS,
    data,
  };
};

export const successGetRequisitions = (data, meta = null) => {
  return {
    type: types.SUCCESS_REQUISITIONS,
    data,
    meta,
  };
};

export const errorGetRequisitions = (data) => {
  return {
    type: types.ERROR_REQUISITIONS,
    data,
  };
};

export const getRequisition = (data) => {
  return {
    type: types.GET_REQUISITION,
    data,
  };
};

export const successGetRequisition = (data) => {
  return {
    type: types.SUCCESS_REQUISITION,
    data,
  };
};

export const errorGetRequisition = (data) => {
  return {
    type: types.ERROR_REQUISITION,
    data,
  };
};

export const postCreateRequisition = (data) => {
  return {
    type: types.CREATE_REQUISITION,
    data,
  };
};

export const successCreateRequisition = (data) => {
  return {
    type: types.SUCCESS_CREATE_REQUISITION,
    data,
  };
};

export const errorCreateRequisition = (data) => {
  return {
    type: types.ERROR_CREATE_REQUISITION,
    data,
  };
};

const postUpdateRequisition = (data) => {
  return {
    type: types.UPDATE_REQUISITION,
    data,
  };
};

export const successUpdateRequisition = (data, update) => {
  return {
    type: types.SUCCESS_UPDATE_REQUISITION,
    data,
    update,
  };
};

export const errorUpdateRequisition = (data) => {
  return {
    type: types.ERROR_UPDATE_REQUISITION,
    data,
  };
};

export const postCreateProductRequisition = (data) => {
  return {
    type: types.CREATE_PRODUCT_REQUISITION,
    data,
  };
};

export const successCreateProductRequisition = (data) => {
  return {
    type: types.SUCCESS_CREATE_PRODUCT_REQUISITION,
    data,
  };
};

export const errorCreateProductRequisition = (data) => {
  return {
    type: types.ERROR_CREATE_PRODUCT_REQUISITION,
    data,
  };
};

export const postUpdateProductRequisition = (data) => {
  return {
    type: types.UPDATE_PRODUCT_REQUISITION,
    data,
  };
};

export const successUpdateProductRequisition = (data) => {
  return {
    type: types.SUCCESS_UPDATE_PRODUCT_REQUISITION,
    data,
  };
};

export const errorUpdateProductRequisition = (data) => {
  return {
    type: types.ERROR_UPDATE_PRODUCT_REQUISITION,
    data,
  };
};

export const postDeleteProductRequisition = (data) => {
  return {
    type: types.DELETE_PRODUCT_REQUISITION,
    data,
  };
};

export const successDeleteProductRequisition = (data) => {
  return {
    type: types.SUCCESS_DELETE_PRODUCT_REQUISITION,
    data,
  };
};

export const errorDeleteProductRequisition = (data) => {
  return {
    type: types.ERROR_DELETE_PRODUCT_REQUISITION,
    data,
  };
};

export const postCreateProductRequisitionCustomerBudgetCode = (data) => {
  return {
    type: types.CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const successCreateProductRequisitionCustomerBudgetCode = (data) => {
  return {
    type: types.SUCCESS_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const errorCreateProductRequisitionCustomerBudgetCode = (data) => {
  return {
    type: types.ERROR_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const openRequisition = (data) => {
  return {
    type: types.OPEN_REQUISITION,
    data,
  };
};

export const setEditRequisition = (data) => {
  return {
    type: types.SET_EDIT_REQUISITION,
    data: { id: data },
  };
};
export const unsetEditRequisition = (data) => {
  return {
    type: types.UNSET_EDIT_REQUISITION,
    data: { id: data },
  };
};

export const closeRequisition = (data) => {
  return {
    type: types.CLOSE_REQUISITION,
    data,
  };
};

export const postCheckout = (data) => {
  return {
    type: types.POST_CHECKOUT,
    data,
  };
};

export const successCheckout = (data) => {
  trackRequisitionSubmitted(data);
  return {
    type: types.SUCCESS_CHECKOUT,
    data,
  };
};

export const errorCheckout = (data) => {
  return {
    type: types.ERROR_CHECKOUT,
    data,
  };
};

export const postApproval = (data) => {
  return {
    type: types.POST_APPROVAL,
    data,
  };
};

export const postEditRequisition = (data) => {
  return {
    type: types.POST_EDIT_REQUISITION,
    data,
  };
};

export const successEditRequisition = (data) => {
  return {
    type: types.SUCCESS_EDIT_REQUISITION,
    data,
  };
};

export const errorEditRequisition = (data) => {
  return {
    type: types.ERROR_EDIT_REQUISITION,
    data,
  };
};

export const updateRequisitionShippingPrice = () => {
  return {
    type: types.UPDATE_REQUISITION_SHIPPING_PRICE,
  };
};

export const successUpdateRequisitionShippingPrice = () => {
  return {
    type: types.SUCCESS_UPDATE_REQUISITION_SHIPPING_PRICE,
  };
};

export const errorUpdateRequisitionShippingPrice = () => {
  return {
    type: types.ERROR_UPDATE_REQUISITION_SHIPPING_PRICE,
  };
};

/**
 * Submit updates to edited requisition
 *
 * @access public
 * @param {Object[]} [data] new requisition attributes
 * @returns {Function} redux-thunk
 */

export function submitRequisitionEdits(data) {
  return (dispatch, getState) => {
    const { editing, open } = getState().requisitions;
    const editingRequisition = editing.filter((req) => req.id === data.id)[0];
    if (!editingRequisition) return;

    editingRequisition.product_requisitions.map((pr) => {
      if (!pr.id) {
        dispatch(
          submitProductRequisition({
            quantity: pr.quantity,
            product_id: pr.product.id,
            requisition_id: pr.requisition_id,
            instructions: pr.instructions,
          })
        );
      }
      if (pr.quantity < 1) {
        return dispatch(submitDeleteProductRequisition(pr));
      }
      const originalPr = open.product_requisitions.filter(
        (original) => original.id === pr.id
      )[0];
      if (
        originalPr &&
        (originalPr.quantity !== pr.quantity ||
          originalPr.instructions !== pr.instructions)
      ) {
        return dispatch(
          submitUpdateProductRequisition({
            quantity: pr.quantity,
            instructions: pr.instructions,
            id: pr.id,
            requisition_id: pr.requisition_id,
          })
        );
      }
      return null;
    });
    return dispatch(submitEditRequisition(data));
  };
}

/**
 * Edit product requisition on editing requisition
 *
 * @access public
 * @param {Object} [data] product requisition
 * @returns {Function} redux-thunk
 */

export function updateEditProductRequisition(data) {
  return (dispatch, getState) => {
    const { requisitions } = getState();
    const req_id = data.requisition_id;
    let requisition = requisitions.editing.filter(
      (req) => req.id === req_id
    )[0];
    if (!requisition) return;

    requisition = { ...requisition };
    let product_requisitions = requisition.product_requisitions.slice();
    let product_requisition = product_requisitions.filter((pr) => {
      return pr.product_id === data.product_id && pr.price === data.price;
    })[0];
    product_requisition = { ...product_requisition };
    if (!product_requisition) return;

    product_requisition = data;
    product_requisitions = product_requisitions.map((pr) => {
      if (pr.product_id === data.product_id && pr.price === data.price) {
        return product_requisition;
      } else {
        return pr;
      }
    });
    requisition.product_requisitions = product_requisitions;

    return dispatch({
      type: types.UPDATE_PRODUCT_ON_EDITING_REQUISITION,
      data: { requisition },
    });
  };
}

/**
 * Add new product requisition to editing requisition
 *
 * @access public
 * @param {Object} data attributes of new product requisition
 * @param {Number} data.quantity
 * @param {Number} data.product_id
 * @param {Number} data.requisition_id
 * @param {Number} data.price

 * @returns {Function} redux-thunk
 */

export function editAddProductRequistion(data) {
  return (dispatch, getState) => {
    const { requisitions } = getState();
    const req_id = data.requisition_id;
    let requisition = requisitions.editing.filter(
      (req) => req.id === req_id
    )[0];
    if (!requisition) return requisitions;
    requisition = { ...requisition };

    let product_requisitions = requisition.product_requisitions.slice();
    let product_requisition = product_requisitions.filter((pr) => {
      return pr.product_id === data.product_id && pr.price === data.price;
    })[0];

    if (product_requisition) {
      product_requisition = { ...product_requisition };
      product_requisition.quantity = data.quantity;
      product_requisitions = product_requisitions.map((pr) => {
        if (pr.product_id === data.product_id && pr.price === data.price) {
          return product_requisition;
        } else {
          return pr;
        }
      });
    } else {
      product_requisitions = [data].concat(product_requisitions);
    }
    requisition.product_requisitions = product_requisitions;

    return dispatch({
      type: types.ADD_PRODUCT_ON_EDITING_REQUISITION,
      data: { requisition },
    });
  };
}

/**
 * Get requisitions cart info
 *
 * @access public
 * @param {string} [paged] Next URL for pagination
 * @returns {Promise}
 */
export function doGetRequisitions(cursor) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_REQUISITIONS,
      location_id: location.id,
    };
    dispatch(getRequisitions(data));
    let cursor_paged = cursor ? `&cursor=${cursor}` : '';
    const url = `/locations/${location.id}/requisitions?&limit=30${cursor_paged}`;
    return fetch(`${ADMIN_V1_URL}${url}`)
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get cart info' });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = parseInt(url.match(/locations\/(\d+)/)[1], 10);
        dispatch(successGetRequisitions(body.data, body.meta));
        return body;
      })
      .catch((error) => {
        dispatch(errorGetRequisitions(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get requisition cart info
 *
 * @access public
 * @returns {Promise}
 */
export function doGetRequisition(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    dispatch(getRequisition(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/requisitions/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get cart info ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successGetRequisition(body));
      })
      .catch((error) => {
        dispatch(errorGetRequisition(error));
        trackFetchError(error);
      });
  };
}

/**
 * Edit a submitted requisition
 *
 * @access public
 * @param {object} data requisition
 * @returns {function} thunk
 */
export function submitEditRequisition(data) {
  return (dispatch, getState) => {
    dispatch(postEditRequisition(data));
    return submitUpdateRequisition(data, { bubble: true })(dispatch, getState)
      .then((body) => {
        dispatch(successEditRequisition(body));
        dispatch(
          enqueueConfirmation({
            message: 'Your order has been updated',
            type: 'success',
          })
        );
      })
      .catch((err) => {
        dispatch(
          errorEditRequisition({
            error: err.toString(),
            id: data.id,
          })
        );
      });
  };
}

/**
 * Update requisition
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateRequisition(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    dispatch(postUpdateRequisition(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/requisitions/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update requisition ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successUpdateRequisition(body, data));
        trackRequisitionUpdated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorUpdateRequisition(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Create product_requisition
 *
 * @access public
 * @returns {Promise}
 */
export function submitProductRequisition(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const requisition_id = data.requisition_id;
    const { locations } = getState();
    const location = locations.open;

    const productRequisition = {
      requisition_id: requisition_id,
      product_id: data.product_id,
      quantity: data.quantity,
      instructions: data.instructions,
      _request: data._request,
    };
    dispatch(postCreateProductRequisition(productRequisition));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_requisitions`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(productRequisition),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create product requisition',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateProductRequisition(
            Object.assign({}, body, {
              _request: productRequisition._request,
            })
          )
        );
        trackProductRequisitionCreated(body);
        setTimeout(() => {
          dispatch(
            unsetCartResponse(
              Object.assign({}, body, {
                _request: productRequisition._request,
              })
            )
          );
        }, CART_RESPONSE_TIMEOUT);
        return body;
      })
      .catch((error) => {
        dispatch(errorCreateProductRequisition(error));
        trackFetchError(error);
      });
  };
}

/**
 * Update product_requisition
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateProductRequisition(productRequisition) {
  productRequisition._request = productRequisition._request || requestID();
  return (dispatch, getState) => {
    dispatch(postUpdateProductRequisition(productRequisition));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_requisitions/${productRequisition.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': productRequisition._request,
        },
        body: JSON.stringify(productRequisition),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: productRequisition,
            message: 'Update product requisitoin ' + productRequisition.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successUpdateProductRequisition(
            Object.assign({}, body, {
              _request: productRequisition._request,
            })
          )
        );
        trackProductRequisitionUpdated(body);
        setTimeout(() => {
          dispatch(
            unsetCartResponse(
              Object.assign({}, body, {
                _request: productRequisition._request,
              })
            )
          );
        }, CART_RESPONSE_TIMEOUT);
      })
      .catch((error) => {
        dispatch(errorUpdateProductRequisition(error));
        trackFetchError(error);
      });
  };
}

/**
 * Delete product_requisition
 *
 * @access public
 * @returns {Promise}
 */
export function submitDeleteProductRequisition(productRequisition) {
  productRequisition._request = productRequisition._request || requestID();

  return (dispatch, getState) => {
    dispatch(postDeleteProductRequisition(productRequisition));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/product_requisitions/${productRequisition.id}`,
      {
        method: 'DELETE',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': productRequisition._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data: productRequisition,
            message: 'Delete product requisition ' + productRequisition.id,
          });
        }
      })
      .then(() => {
        dispatch(successDeleteProductRequisition(productRequisition));
        trackProductRequisitionDeleted(productRequisition);
        setTimeout(() => {
          dispatch(
            unsetCartResponse(
              Object.assign({}, productRequisition, {
                _request: productRequisition._request,
              })
            )
          );
        }, CART_RESPONSE_TIMEOUT);
      })
      .catch((error) => {
        dispatch(errorDeleteProductRequisition(error));
        trackFetchError(error);
      });
  };
}

/**
 * Create customer_budget_code_product_requisition
 *
 * @access public
 * @param {Object} data
 * @param {Number} data.id
 * @param {Number} data.customer_budget_code_id
 * @param {Object} [options]
 * @param {Bool} options.bubble
 * @returns {Promise}
 */
export function submitProductRequisitionBudgetCode(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postCreateProductRequisitionCustomerBudgetCode(data));
    const { requisitions } = getState();
    const requisition = requisitions.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${requisition.location_id}/product_requisitions/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Create product requisition customer budget code',
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, {
          _request: data._request,
        });
        dispatch(successCreateProductRequisitionCustomerBudgetCode(body));
        trackProductRequisitionBudgetCodeUpdated(body);
        dispatch(
          enqueueConfirmation({
            message:
              'Updated product budget category. Your insights will update shortly.',
            type: 'success',
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateProductRequisitionCustomerBudgetCode(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Create or Update Product Requisition
 *
 * @access public
 * @returns {Promise}
 */

export function createOrUpdateProductRequisition(data) {
  return (dispatch) => {
    if (data.id) {
      delete data.product;
      return dispatch(submitUpdateProductRequisition(data));
    } else {
      return dispatch(submitProductRequisition(data));
    }
  };
}

/**
 * Update Requisition Shipping Price
 *
 * @access public
 */
export function submitUpdateRequisitionShippingPrice() {
  return (dispatch, getState) => {
    const { carts } = getState();
    const requisition = carts.open;

    dispatch(updateRequisitionShippingPrice());

    return fetch(
      `${ADMIN_V1_URL}/locations/${requisition.location_id}/carts/${requisition.id}/update_shipping_price`,
      {
        method: 'PATCH',
        headers: { ...HEADERS_JSON, 'X-Request-ID': requestID() },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            requisition,
            message: 'Update requisition shipping price' + requisition.id,
          });
        }
        return response.json();
      })
      .then(() => {
        dispatch(successUpdateRequisitionShippingPrice());
      })
      .catch(() => {
        dispatch(errorUpdateRequisitionShippingPrice());
      });
  };
}
