import React from 'react';
import PropTypes from 'prop-types';
import CommentStaff from './comment-staff.js';
import CommentEmployee from './comment-employee.js';
import { formatDate } from '../../lib/formatters.js';
import './comments-list.css';

export default class CommentsList extends React.PureComponent {
  renderComment = (comment) => {
    if (comment.commenter_type.match(/employee/i)) {
      return <CommentEmployee comment={comment} key={comment.id} />;
    }
    return <CommentStaff comment={comment} key={comment.id} />;
  };

  render() {
    return (
      <div className="cleaning-comments-list-container">
        <div className="shift-notes">
          <h4 className="site-h4">
            {formatDate(this.props.date)}&rsquo;s Shift Messages
          </h4>
          {this.props.comments.length === 0 &&
            this.props.isCompletedCleaning && (
              <h5 className="completed-empty-state">
                No notes were left about this shift.
              </h5>
            )}
          {this.props.comments.length === 0 && !this.props.isCompletedCleaning && (
            <div className="notes-empty-state">
              <p className="microcopy gray-text">
                This is where you and your Attendants can leave notes for each
                other about the shift.
              </p>
            </div>
          )}
          {this.props.comments.map(this.renderComment)}
        </div>
      </div>
    );
  }
}

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      commenter_id: PropTypes.number.isRequired,
      commenter_type: PropTypes.string.isRequired,
      commentable_id: PropTypes.number.isRequired,
      commentable_type: PropTypes.string.isRequired,
    })
  ).isRequired,
  date: PropTypes.string.isRequired,
  isCompletedCleaning: PropTypes.bool,
};
