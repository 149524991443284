import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import { bindActionCreators } from 'redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import AttendantCleaningDetails from '../components/cleanings/cleaning-details.js';
import CleaningDetailsListEmpty from '../components/cleanings/cleaning-details-empty-state.js';
import CleaningReport from '../components/cleanings/report.js';
import CleaningDetailsList from '../components/cleanings/details-list.js';
import Loader from '../components/loader.js';
import {
  openCleaningDetails,
  openCleaningReport,
  closeCleaning,
} from '../actions/cleaning-actions.js';
import setTitle from '../decorators/set-title.js';
import { FILTER_CLEANINGS_FUTURE } from '../strings.js';
import './office-attendant-cleaning-detail.css';

export class OfficeAttendantCleaningDetailRoute extends React.PureComponent {
  static propTypes = {
    cleaning: PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_datetime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
    }),
    isRequesting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFuture: !!FILTER_CLEANINGS_FUTURE(props.cleaning),
    };
  }

  componentDidMount() {
    this.props.actions.openCleaningDetails({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate() {
    const props = this.props;
    if (
      props.match.params.id !== this.props.match.params.id &&
      props.match.params.id !== props.cleaning.id + ''
    ) {
      props.actions.openCleaningDetails({
        id: parseInt(props.match.params.id, 10),
      });
    }
    if (
      props.match.params.id === this.props.match.params.id &&
      props.cleaning.id &&
      props.cleaning.start_datetime !== this.props.cleaning.start_datetime &&
      !FILTER_CLEANINGS_FUTURE(props.cleaning)
    ) {
      this.props.actions.openCleaningReport({
        id: parseInt(props.match.params.id, 10),
      });
    }
    if (!props.isRequesting && !props.cleaning.start_datetime) {
      return browserHistory.push('/office-attendant/shift-details');
    }
    this.setState(
      Object.assign({}, this.state, {
        isFuture: !!FILTER_CLEANINGS_FUTURE(props.cleaning),
      })
    );
  }

  componentWillUnmount() {
    this.props.actions.closeCleaning();
  }

  render() {
    return (
      <div className="office-attendant-cleaning-detail">
        <AuthCheck permissions={{ can_manage_cleanings: true }} />
        <NavBarAttendant />
        <div className="">
          <CleaningDetailsList />
          {this.props.isRequesting &&
            (!this.props.cleaning || !this.props.cleaning.start_datetime) && (
              <Loader />
            )}
          {!!this.props.cleaning.id && this.state.isFuture && (
            <AttendantCleaningDetails item={this.props.cleaning} />
          )}
          {!!this.props.cleaning.id &&
            !this.state.isFuture &&
            !!this.props.cleaning.start_datetime && (
              <div className="variable-width-column">
                <CleaningReport cleaning={this.props.cleaning} />
              </div>
            )}
          {(!this.props.cleaning || !this.props.cleaning.id) && (
            <div className="empty-shift fixed-width-column">
              <CleaningDetailsListEmpty />
            </div>
          )}
        </div>
      </div>
    );
  }
}

OfficeAttendantCleaningDetailRoute = setTitle((props) => {
  return 'Office Attendant Shift #' + props.match.params.id;
})(OfficeAttendantCleaningDetailRoute);

export function mapStateToProps(state) {
  return {
    cleaning: state.cleanings.open,
    isRequesting: state.cleanings.isRequesting,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningDetails,
        openCleaningReport,
        closeCleaning,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfficeAttendantCleaningDetailRoute);
