import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Checkbox, Button, Table } from 'semantic-ui-react';
import { buildRoleOptions } from '../../helpers/customer-role-helpers.js';
import {
  submitUpdateEmployee,
  doUninviteEmployee,
} from '../../actions/employee-actions.js';
import './team-batch-actions.css';

export class TeamBatchActions extends React.PureComponent {
  state = {
    customerRoleId: null,
    removeLocation: false,
  };

  confirmRemove = () => {
    const plural = this.props.employeeIds.length > 1;
    const message = `This will remove ${
      this.props.employeeIds.length
    } team member${plural ? 's' : ''} from ${
      this.props.location.name
    }. Are you sure you want to do this?`;
    if (window.confirm(message)) {
      this.submitLocationUpdates();
    }
  };

  handleChangeRole = (e, { value }) => {
    const plural = this.props.employeeIds.length > 1;
    const message = `This will assign the ${
      this.props.customerRoles[value].name
    } role to ${this.props.employeeIds.length} team member${
      plural ? 's' : ''
    }. Are you sure you want to do this?`;
    if (window.confirm(message)) {
      this.submitEmployeeUpdates(value);
    }
  };

  selectAllChecked = () =>
    this.props.employeeIds.length !== 0 &&
    this.props.employeeIds.length === this.props.employeesInView.length;

  submitEmployeeUpdates = (customerRoleId) => {
    this.props.employeeIds.forEach((id) => {
      this.props.actions.submitUpdateEmployee({
        id: id,
        customer_role_id: customerRoleId,
      });
    });
  };

  submitLocationUpdates = () => {
    this.props.employeeIds.forEach((id) => {
      id = parseInt(id, 10);
      const employee = this.props.employeesInView.filter((e) => e.id === id)[0];
      this.props.actions
        .doUninviteEmployee(
          {
            id: id,
            email: employee ? employee.email : id,
            location: this.props.location,
            _isOpenLocation: true,
          },
          { bubble: true }
        )
        .then((body) => {
          this.props.onRemoveSuccess && this.props.onRemoveSuccess(body);
        });
    });
  };

  toggleLocationRemoval = () => {
    this.setState((prevState) => {
      return {
        removeLocation: !prevState.removeLocation,
      };
    });
  };

  render() {
    return (
      <Table.Row className="batch-actions-row">
        <Table.Cell width={1}>
          <Checkbox
            onChange={() => this.props.onSelectAll(!this.selectAllChecked())}
            checked={this.selectAllChecked()}
          />
        </Table.Cell>
        <Table.Cell width={4}>
          <div className="employee-details-container">
            {this.props.employeeIds.length} selected
          </div>
        </Table.Cell>
        <Table.Cell width={5}>
          <Form.Dropdown
            selection
            fluid
            width={7}
            onChange={this.handleChangeRole}
            options={buildRoleOptions(this.props.customerRoles)}
            placeholder="Assign Role"
            value={this.state.customerRoleId}
            disabled={!this.props.employeeIds.length}
          />
        </Table.Cell>
        <Table.Cell width={5}>
          <Button
            compact
            fluid
            secondary
            size="tiny"
            onClick={this.confirmRemove}
            disabled={!this.props.employeeIds.length}
            title="Remove selected team members from this office"
          >
            Remove from office
          </Button>
        </Table.Cell>
        <Table.Cell width={1}></Table.Cell>
      </Table.Row>
    );
  }
}

TeamBatchActions.propTypes = {
  actions: PropTypes.shape({
    submitUpdateEmployee: PropTypes.func.isRequired,
    doUninviteEmployee: PropTypes.func.isRequired,
  }),
  customerRoles: PropTypes.object.isRequired,
  employeeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  employeesInView: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func,
};

TeamBatchActions.defaultProps = {
  employeeIds: [],
};

export function mapStateToProps(state) {
  return {
    customerRoles: state.customerRoles.items,
    location: state.locations.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateEmployee,
        doUninviteEmployee,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.items === next.customerRoles.items &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(TeamBatchActions);
