import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCentAmount,
  formatProductImageURL,
} from '../../lib/formatters.js';
import { List, Image } from 'semantic-ui-react';
import 'semantic-ui-css/components/list.min.css';
import 'semantic-ui-css/components/image.min.css';

const SimilarListItem = (props) => {
  return (
    <List.Item key={props.product.id}>
      <Image
        rounded
        src={formatProductImageURL(props.product, 'thumb')}
        size="mini"
      />
      <List.Content>
        <a href={`#${props.product.id}`} onClick={props.onClick}>
          <List.Header>{props.product.name}</List.Header>
          {formatCentAmount(props.product.price)}
        </a>
      </List.Content>
    </List.Item>
  );
};

SimilarListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stock_per_purchase: PropTypes.number.isRequired,
    unit_purchase: PropTypes.string.isRequired,
    unit_stock: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimilarListItem;
