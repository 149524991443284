import React from 'react';
import PropTypes from 'prop-types';
import ProductItem from './item.js';
import './item-list-row.css';
const scrollUnit = 200;

export default class ProductListRow extends React.PureComponent {
  _handleClickBefore = () => {
    this._scrollLeftTo(scrollUnit, -1);
  };

  _handleClickAfter = () => {
    this._scrollLeftTo(scrollUnit, 1);
  };

  _handleClickViewAll = () => {
    if (this.props.onClickViewAll) {
      this.props.onClickViewAll();
    }
  };

  _scrollLeftTo = (target, direction) => {
    this._scrollTimeout = window.setTimeout(
      this._scrollIncrement.bind(this, target, direction),
      33
    );
  };

  _scrollIncrement = (diff, direction) => {
    const half = Math.ceil(diff / 2);
    window.requestAnimationFrame(() => {
      if (direction > 0) {
        this.refs.listRow.scrollLeft += half;
      } else {
        this.refs.listRow.scrollLeft -= half;
      }
    });
    const newDiff = diff - half;
    if (newDiff) {
      this._scrollLeftTo(newDiff, direction);
    }
  };

  _handleScrollRow = () => {
    const offset = this.refs.listRow.scrollLeft;
    const width = this.refs.listRow.clientWidth;
    const total = this.refs.listRow.scrollWidth;
    const threshold = 32;
    this._buttonBeforeTimeout = window.setTimeout(
      this._showButtonBefore.bind(this, offset >= threshold),
      100
    );
    this._buttonAfterTimeout = window.setTimeout(
      this._showButtonAfter.bind(this, total - (offset + width) >= threshold),
      100
    );
  };

  _showButtonBefore = (show) => {
    if (this.refs.buttonBefore)
      this.refs.buttonBefore.style.opacity = show ? 1 : 0;
  };

  _showButtonAfter = (show) => {
    if (this.refs.buttonAfter)
      this.refs.buttonAfter.style.opacity = show ? 1 : 0;
  };

  componentDidMount() {
    this._handleScrollRow();
    this.refs.listRow.addEventListener('scroll', this._handleScrollRow);
  }

  componentDidUpdate() {
    this._handleScrollRow();
  }

  componentWillUnmount() {
    this.refs.listRow.removeEventListener('scroll', this._handleScrollRow);
    this._scrollTimeout && window.clearTimeout(this._scrollTimeout);
    this._buttonBeforeTimeout && window.clearTimeout(this._buttonBeforeTimeout);
    this._buttonAfterTimeout && window.clearTimeout(this._buttonAfterTimeout);
  }

  renderItem = (item) => {
    return <ProductItem key={item.id} item={item} canFavorite={true} />;
  };

  render() {
    return (
      <div className="product-item-list-row-wrap">
        {/* <button
          className="list-row-button before brand-color-override"
          ref="buttonBefore"
          type="button"
          title="Previous product"
          onClick={this._handleClickBefore}
        >
          <span>&lang;</span>
        </button> */}
        <div ref="listRow" className="product-item-list-row">
          {this.props.items
            .filter((product) => product.active)
            .slice(0, this.props.maxDisplay || this.props.items.length)
            .map(this.renderItem)}
          {/* {this.props.items.length > (this.props.maxDisplay || 0) && (
            <div className="product-item view-all-item">
              <button
                className="row-view-all-button brand-color-override"
                type="button"
                onClick={this._handleClickViewAll}
                title="View all"
              >
                See All
              </button>
            </div>
          )} */}
        </div>
        {/* <button
          className="list-row-button after brand-color-override"
          type="button"
          ref="buttonAfter"
          title="Next product"
          onClick={this._handleClickAfter}
        >
          <span>&rang;</span>
        </button> */}
      </div>
    );
  }
}

ProductListRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.number.isRequired,
    })
  ),
  maxDisplay: PropTypes.number,
  onClickViewAll: PropTypes.func,
};
