import PropTypes from 'prop-types';
import { asSentence, formatCentAmount } from '../lib/formatters.js';

export const permissionKeys = {
  can_manage_roles: {
    order: 0,
  },
  can_manage_employees_locations: {
    order: 10,
  },
  can_manage_billing: {
    order: 20,
  },
  can_manage_insights: {
    order: 30,
  },
  can_manage_all_requisitions: {
    order: 40,
  },
  can_manage_location_catalog: {
    order: 50,
  },
  can_manage_cleanings: {
    order: 60,
  },
  can_manage_location_lists: {
    order: 70,
  },
  can_manage_employees_stipends: {
    order: 80,
  },
};

export function customerRoleConfigAsSentence(role) {
  let desc = '';
  let pKeys = Object.keys(role).filter((k) => permissionKeys[k]);
  let permissionKeyLastIdx = pKeys.length - 1;

  desc += asSentence(
    pKeys.reduce((acc, key, idx) => {
      if (idx === permissionKeyLastIdx) {
        acc += ', and ';
      } else if (idx !== 0) {
        acc += ', ';
      }
      if (role[key]) {
        return acc + `can ${buildPermissionText(key)}`;
      }
      return acc + `cannot ${buildPermissionText(key)}`;
    }, desc) + '. '
  );
  if (role.approval_required_after_employee_spend === 0) {
    desc += 'Must submit orders for approval. ';
  } else if (role.approval_required_after_employee_spend > 0) {
    desc += `Must submit orders for approval after spending ${formatCentAmount(
      role.approval_required_after_employee_spend
    )} in a month. `;
  }
  if (role.approval_required_after_location_spend === 0) {
    desc += 'Must submit orders for approval. ';
  } else if (role.approval_required_after_location_spend > 0) {
    desc += `Must submit orders for approval after their office spends ${formatCentAmount(
      role.approval_required_after_location_spend
    )} in a month. `;
  }
  return desc;
}

export function buildPermissionText(key) {
  switch (key) {
    case 'can_manage_employees_locations':
      return 'add and remove other users';
    case 'can_manage_employees_stipends':
      return 'add and remove employees stipends';
    case 'can_manage_cleanings':
      return 'manage on-site service';
    case 'can_manage_location_lists':
      return 'manage favorites and inventory lists';
    case 'can_manage_roles':
      return 'edit roles and permissions';
    case 'can_manage_billing':
      return 'edit billing information';
    case 'can_manage_insights':
      return 'manage reporting and insights';
    case 'can_manage_all_requisitions':
      return 'manage shipments and orders for other users';
    default:
      return key.split('_').slice(1).join(' ');
  }
}

export const sortKeysByOrder = (keyA, keyB) => {
  if (permissionKeys[keyA].order < permissionKeys[keyB].order) return -1;
  if (permissionKeys[keyA].order > permissionKeys[keyB].order) return 1;
  return 0;
};

export const CUSTOMER_ROLE_PROPTYPES = PropTypes.shape({
  approval_required_after_employee_spend: PropTypes.number,
  approval_required_after_location_spend: PropTypes.number,
  can_manage_billing: PropTypes.bool.isRequired,
  can_manage_cleanings: PropTypes.bool.isRequired,
  can_manage_employees_locations: PropTypes.bool.isRequired,
  can_manage_employees_stipends: PropTypes.bool.isRequired,
  can_manage_location_catalog: PropTypes.bool.isRequired,
  can_manage_roles: PropTypes.bool.isRequired,
  customer_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  approved_customer_budget_code_ids: PropTypes.arrayOf(PropTypes.number),
});

export const abilities = (role) =>
  Object.keys(role)
    .filter((key) => permissionKeys[key] && role[key])
    .sort(sortKeysByOrder);

export const inabilities = (role) =>
  Object.keys(role)
    .filter((key) => permissionKeys[key] && !role[key])
    .sort(sortKeysByOrder);

export const buildRoleOptions = (customerRoles) => {
  return Object.values(customerRoles)
    .filter((item) => item.name !== 'OfficeLuv Attendant')
    .map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
};

export const isAdmin = (roleName) => {
  if (!roleName) {
    return false;
  }

  let adminRoles = ['global admin', 'admin', 'team member'];

  return adminRoles.includes(roleName.toLowerCase());
};
