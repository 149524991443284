import moment from 'moment';

/**
 * Checks for existence of product requisitions
 *
 * @param {object[]} product requisitions (include recurring and scheduled product requisitions)
 * @returns {boolean}
 **/
export const IS_EMPTY_REQUISITION = (productRequisitions) => {
  return (productRequisitions || []).length === 0;
};

/**
 * Filters product requisitions for alcohol products
 *
 * @access public
 * @param {object[]} product requisitions (include recurring and scheduled product requisitions)
 * @returns {product requisitions[]} product requisitions
 **/
export const GET_ALCOHOL_PRS = (productRequisitions) => {
  return productRequisitions.filter((pr) => pr.product.alcohol);
};

/**
 * Filters product categories for alcohol category
 *
 * @access public
 * @param {Product{}} product
 * @returns {boolean}
 **/
export const IS_ALCOHOL_PRODUCT = (product) => {
  return product.alcohol;
};

/**
 * Filters non-alcohol products via product requisitions
 *
 * @access public
 * @param {object[]} product requisitions (include recurring and scheduled product requisitions)
 * @returns {boolean}
 **/
export const IS_ALCOHOL_MONO = (productRequisitions) => {
  return (
    GET_ALCOHOL_PRS(productRequisitions).length === productRequisitions.length
  );
};

export const CONTAINS_ALCOHOL = (productRequisitions) => {
  return !IS_NON_ALCOHOL_MONO(productRequisitions || []);
};

/**
 * Filters alcohol products via product requisitions
 *
 * @access public
 * @param {object[]} product requisitions (include recurring and scheduled product requisitions)
 * @returns {boolean}
 **/
export const IS_NON_ALCOHOL_MONO = (productRequisitions) => {
  return GET_ALCOHOL_PRS(productRequisitions).length === 0;
};

/**
 * Filters for shipments where expected at time is at least
 * 7 days out into the future
 *
 * @access public
 * @param {object} shipment
 * @returns {boolean} for shipment with expected_at within 7 days from now
 **/
export const IS_ACTIVE_SHIPMENT = (shipment) => {
  return moment(shipment.expected_at_end).add(7, 'days') > new Date();
};

/**
 * Filters for products where stock is in
 *
 * @access public
 * @param {object} product
 * @returns {boolean} for product in stock
 **/
export const PRODUCT_IS_IN_STOCK = (product) => {
  return !(product.stock_status || '').match(/out/i) && product.active;
};

/**
 * Filters for products where stock is expected back at a date
 * returns date or false
 *
 * @access public
 * @param {object} product
 * @returns {Date|bool} for product in stock expected at
 **/
export const PRODUCT_IS_IN_STOCK_AT = (product) => {
  if (product.in_stock_at) {
    return new Date(product.in_stock_at);
  }
  return false;
};
