import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExternalVendorInvoiceSummary from '../components/insights/external-vendor-invoice-summary.js';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-attendant.js';
import EVIUploadAttachment from '../components/externalVendorInvoices/upload-attachment.js';
import EVIUploadCTA from '../components/insights/external-vendor-invoice-upload.js';
import { Header } from 'semantic-ui-react';
import 'semantic-ui-css/components/header.min.css';
import './insights-external-invoices.css';

export class InsightsExternalInvoices extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Vendor Invoices.', 'Reporting');
  }

  emptyState = () => {
    return this.props.invoices.length === 0 && !this.props.isRequesting;
  };

  renderInvoice = (invoice) => {
    return <ExternalVendorInvoiceSummary key={invoice.id} invoice={invoice} />;
  };

  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <NavBar />
        <div className="fixed-width-column external-vendor-invoices-list">
          {this.emptyState() && <EVIUploadCTA />}
          <EVIUploadAttachment />
          {!this.emptyState() && <Header as="h2">Uploaded Invoices</Header>}
          {this.props.invoices.map(this.renderInvoice)}
        </div>
      </div>
    );
  }
}

InsightsExternalInvoices.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

const sortByTransactionDate = (a, b) => {
  if (a.transaction_date > b.transaction_date) return -1;
  if (b.transaction_date > a.transaction_date) return 1;
  if (a.created_at > b.created_at) return -1;
  if (b.created_at > a.created_at) return 1;
  return 0;
};

export function mapStateToProps(state) {
  return {
    invoices: state.externalVendorInvoices.items.sort(sortByTransactionDate),
    isRequesting: state.externalVendorInvoices.requesting.length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.externalVendorInvoices.items === next.externalVendorInvoices.items
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  InsightsExternalInvoices
);
