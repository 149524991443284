import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import * as queryParser from '../lib/query-parser.js';
import { connect } from 'react-redux';
import { setPasswordResetToken } from '../actions/auth-actions';
import ResetPasswordForm from '../components/resetpassword.js';
import setTitle from '../decorators/set-title.js';

export class ResetPasswordRoute extends React.Component {
  componentDidMount() {
    const query = queryParser.parse(this.props.location.search);
    if (!query.reset_password_token || this.props.auth.hmac) {
      browserHistory.push('/');
      return;
    }
    this.props.actions.setPasswordResetToken({
      token: query.reset_password_token,
    });
  }

  componentDidUpdate() {
    if (this.props.auth.resetPassword) {
      browserHistory.push('/');
    }
  }

  render() {
    return <ResetPasswordForm />;
  }
}

ResetPasswordRoute.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

ResetPasswordRoute = setTitle(() => {
  return 'Reset Password';
})(ResetPasswordRoute);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setPasswordResetToken }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRoute);
