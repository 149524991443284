import browserHistory from '../lib/history.js';
import { trackFetchError, trackLocationUpdated } from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import * as types from './action-types';
import fetch, { regenerateController } from '../lib/hmac-fetch';
import {
  doGetAppConfig,
  setInLimitedViewingModeByLocation,
} from './application-actions.js';
import { signOut } from '../actions/auth-actions.js';
import { doGetInvoices } from './invoices-actions.js';
import { closeCleaning } from './cleaning-actions.js';
import { doGetLocationEmployees } from './employee-actions.js';
import { doGetRequisitions } from './requisition-actions.js';
import { doGetLocationLists } from './location-list-actions.js';
import { doGetShipments } from '../ducks/shipments.js';
import { doGetCustomerBudgetCodes } from './customer-budget-codes-actions.js';
import { doGetScheduledRequisitions } from './scheduled-requisition-actions.js';
import { doGetExternalVendorInvoices } from './external-vendor-invoice-actions.js';
import { doGetProductCategories } from './product-category-actions.js';
import { doGetLocationParentProductCategories } from './location-parent-product-category-actions.js';
import { doGetPromotedBrands } from './promoted-brand-actions.js';
import { doGetCredits } from './credit-actions.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import { doGetCustomerRoles } from '../ducks/customer-roles.js';
import { doGetStipends } from '../ducks/stipends.js';
import { doGetCarts } from '../ducks/carts.js';
import { doGetFeatureTourAttempts } from '../ducks/feature-tour-attempts.js';
import { doUpdatePunchoutSession } from '../ducks/punchout-sessions.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';
import { setLocationCookies } from '../helpers/location-helpers';

const ERROR_TIMEOUT = 5000;

export const openLocation = (data) => ({ type: types.OPEN_LOCATION, data });

export const switchLocation = (data) => {
  return {
    type: types.SWITCH_LOCATION,
    data,
  };
};

export const postUpdateLocation = (data) => {
  return {
    type: types.UPDATE_LOCATION,
    data,
  };
};

export const errorLocations = (data) => {
  return {
    type: types.ERROR_LOCATIONS,
    data,
  };
};

export const successLocations = (data) => {
  return {
    type: types.SUCCESS_LOCATIONS,
    data,
  };
};

const errorUpdateLocation = (data) => {
  return {
    type: types.ERROR_UPDATE_LOCATION,
    data,
  };
};

export const successUpdateLocation = (data) => {
  return {
    type: types.SUCCESS_UPDATE_LOCATION,
    data,
  };
};

const getLocation = (data) => {
  return {
    type: types.GET_LOCATION,
    data,
  };
};

const errorGetLocation = (data) => {
  return {
    type: types.ERROR_GET_LOCATION,
    data,
  };
};

const postCreateLocation = (data) => {
  return {
    type: types.CREATE_LOCATION,
    data,
  };
};

const successCreateLocation = (data) => {
  return {
    type: types.SUCCESS_CREATE_LOCATION,
    data,
  };
};

const errorCreateLocation = (data) => {
  return {
    type: types.ERROR_CREATE_LOCATION,
    data,
  };
};

const unsetLocationResponse = (data) => ({
  data,
  type: types.UNSET_LOCATION_RESPONSE,
});

const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetLocationResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export function doGetLocation(data, options = { bubble: false }) {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getLocation(data));
    return fetch(`${ADMIN_V1_URL}/locations/${data.id}`)
      .then((response) => {
        if (response.status === 401) {
          regenerateController();
          dispatch(signOut());
          browserHistory.push('/sign-in');
          throw fetchError({
            response,
            data: data,
            message: 'Unauthorized for location ' + data.id,
          });
        }
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: data,
            message: 'Get location ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateLocation)({
            ...body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetLocation)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitUpdateLocation(data, options = { bubble: false }) {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(postUpdateLocation(data));
    return fetch(`${ADMIN_V1_URL}/locations/${data.id}`, {
      method: 'PATCH',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify({ ...data, _request: undefined }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update location ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        dispatch(doAndUnsetResponse(successUpdateLocation)(body));
        trackLocationUpdated(body);
        if (window.location.pathname.match(/insights/)) {
          dispatch(
            enqueueConfirmation({
              message:
                'Budget numbers updated! Your insights will update shortly',
            })
          );
        }
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateLocation)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doInitializeLocation(location) {
  return (dispatch, getState) => {
    dispatch(openLocation(location));
    doGetLocation(location, { bubble: true })(dispatch, getState)
      .then((body) => {
        if (!body) return;

        setLocationCookies(body);
        dispatch(setInLimitedViewingModeByLocation(body));
        dispatch(doGetCustomerBudgetCodes({ id: body.customer_id }));
        dispatch(
          doGetStipends({ id: body.customer_id, _request: 'GET_STIPENDS' })
        );
        dispatch(
          doGetCustomerRoles({
            id: body.customer_id,
            _request: 'GET_CUSTOMER_ROLES',
          })
        );
        dispatch(doGetCarts({ location_id: body.id }));
      })
      .catch(() => {});
    dispatch(doGetLocationEmployees(location));
    dispatch(doGetInvoices());
    // dispatch(doGetCleanings(null, true));
    dispatch(doGetLocationLists());
    dispatch(doGetRequisitions());
    dispatch(doGetShipments());
    dispatch(doGetProductCategories());
    dispatch(doGetLocationParentProductCategories());
    dispatch(doGetScheduledRequisitions());
    dispatch(doGetPromotedBrands());
    dispatch(doGetCredits());
    dispatch(doGetExternalVendorInvoices());
  };
}

export function doSwitchLocation(location) {
  return (dispatch, getState) => {
    const openLocation = getState().locations.open;
    if (openLocation.id && openLocation.id !== location.id) {
      regenerateController();
    }
    const { employees, cleanings, staffs, punchoutSessions } = getState();
    setLocationCookies(location);
    dispatch(switchLocation(location));
    dispatch(doGetAppConfig(location.id));
    doGetLocation(location, { bubble: true })(dispatch, getState).then(
      (body) => {
        if (!body) return;

        dispatch(setInLimitedViewingModeByLocation(body));
      }
    );
    if (employees.user.id) {
      dispatch(doGetLocationEmployees(location));
      dispatch(doGetInvoices());
      // dispatch(doGetCleanings(null, true));
      if (
        cleanings.open.location_id &&
        cleanings.open.location_id !== location.id
      ) {
        dispatch(closeCleaning());
      }
      if (punchoutSessions.open.id) {
        dispatch(
          doUpdatePunchoutSession({
            id: punchoutSessions.open.id,
            location_id: location.id,
          })
        );
      }
    }
    if (employees.user.id || staffs.user.id) {
      dispatch(doGetLocationLists());
      dispatch(doGetFeatureTourAttempts({ id: employees.user.id }));
      dispatch(doGetCarts({ location_id: location.id }));
      dispatch(doGetRequisitions());
      dispatch(doGetShipments());
      dispatch(doGetCustomerBudgetCodes({ id: location.customer_id }));
      dispatch(doGetProductCategories());
      dispatch(doGetLocationParentProductCategories());
      dispatch(doGetScheduledRequisitions());
      dispatch(doGetPromotedBrands());
      dispatch(doGetCredits());
      dispatch(doGetExternalVendorInvoices());
      dispatch(
        doGetStipends({ id: location.customer_id, _request: 'GET_STIPENDS' })
      );
      dispatch(
        doGetCustomerRoles({
          id: location.customer_id,
          _request: 'GET_CUSTOMER_ROLES',
        })
      );
    }
  };
}

export function submitLocationRecurlyExternalAccount(
  data,
  options = { bubble: false, method: 'POST' }
) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(postUpdateLocation(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.id}/external_accounts/recurly`,
      {
        method: options.method || 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data.recurly),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Save Recurly account for location ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        let external_accounts =
          (getState().locations.items[data.id] || {}).external_accounts || {};
        external_accounts.recurly = body;
        let update = {
          id: data.id,
          _request: data._request,
          external_accounts: external_accounts,
        };
        dispatch(doAndUnsetResponse(successUpdateLocation)(update));
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (error.status === 409) {
          return error.response.json();
        }
        throw error;
      })
      .then((errorBody) => {
        if (!errorBody || !errorBody.consumer_message) return errorBody;

        dispatch(
          doAndUnsetResponse(errorUpdateLocation)({
            ...errorBody,
            message: errorBody.consumer_message,
            status: 409,
            _request: data._request,
          })
        );

        return errorBody;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(doAndUnsetResponse(errorUpdateLocation)(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitLocationStripeExternalAccount(
  data,
  options = { bubble: false, method: 'POST' }
) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(postUpdateLocation(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.id}/external_accounts/stripe`,
      {
        method: options.method || 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data.stripe),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Save Stripe account for location ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        let external_accounts =
          (getState().locations.items[data.id] || {}).external_accounts || {};
        external_accounts.stripe = body;
        let update = {
          id: data.id,
          _request: data._request,
          external_accounts: external_accounts,
        };
        dispatch(doAndUnsetResponse(successUpdateLocation)(update));
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (error.status === 409) {
          return error.response.json();
        }
        throw error;
      })
      .then((errorBody) => {
        if (!errorBody || !errorBody.consumer_message) return errorBody;

        dispatch(
          doAndUnsetResponse(errorUpdateLocation)({
            ...errorBody,
            message: errorBody.consumer_message,
            status: 409,
            _request: data._request,
          })
        );

        return errorBody;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(doAndUnsetResponse(errorUpdateLocation)(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doGetLocationExternalAccounts(
  data,
  options = { bubble: false }
) {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(postUpdateLocation(data));
    return fetch(`${ADMIN_V1_URL}/locations/${data.id}/external_accounts`, {
      method: 'GET',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get External Accounts for location ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        let update = {
          id: data.id,
          _request: data._request,
          external_accounts: body,
        };
        dispatch(doAndUnsetResponse(successUpdateLocation)(update));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateLocation)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitCreateLocation(data, options = { bubble: false }) {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(postCreateLocation(data));
    return fetch(`${ADMIN_V1_URL}/locations/new`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify({ ...data, _request: undefined }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Failed to create location',
          });
        }
        return response.json();
      })
      .then((body) => {
        body = { ...body, _request: data._request };
        dispatch(doAndUnsetResponse(successCreateLocation)(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateLocation)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
