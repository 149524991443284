import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Checkbox, Table, Form } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import { showProductModal } from '../../actions/product-actions.js';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/table.min.css';
import './edit-budget-code-row.css';

const IMAGE_WIDTH = 25;

export class EditBudgetCodeRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState((prev) => {
      return {
        updated:
          prev.updated ||
          (this.props.item.customer_budget_code || {}).id !==
            (nextProps.item.customer_budget_code || {}).id,
      };
    });
  };

  componentDidUpdate() {
    if (this.state.updated) {
      this._timeoutUpdated = window.setTimeout(this.clearUpdated, 500);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this._timeoutUpdated);
  }

  clearUpdated = () => {
    this.setState(() => {
      return {
        updated: false,
      };
    });
  };

  handleClickName = (e) => {
    e.preventDefault();
    this.props.actions.showProductModal(this.props.item.product);
  };

  handleSelect = () => {
    this.props.onSelect(this.props.item, !this.props.isChecked);
  };

  handleChangeCategory = (e, { value }) => {
    e.preventDefault();
    this.props.onChangeCustomerBudgetCode(this.props.item, { id: value });
  };

  _buildOptions = () => {
    return Object.values(this.props.customerBudgetCodes).map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  };

  render() {
    return (
      <Table.Row
        key={this.props.item.id}
        className={classnames('edit-budget-code-row', {
          updated: this.state.updated,
        })}>
        <Table.Cell>
          <Checkbox
            checked={this.props.isChecked}
            name={`select change ${this.props.item.product.name}`}
            onChange={this.handleSelect}
          />
        </Table.Cell>
        <Table.Cell>
          <div className="product-details">
            <div className="product-info">
              <a
                href={`#${this.props.item.product.id}`}
                onClick={this.handleClickName}
                className="tertiary-link">
                <div className="product-image">
                  <ItemImage
                    width={IMAGE_WIDTH}
                    height={IMAGE_WIDTH}
                    product={this.props.item.product}
                  />
                </div>
                <div className="product-name">
                  {this.props.item.product.name}
                </div>
              </a>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <Form.Dropdown
            selection
            fluid
            style={{ width: '200px' }}
            onChange={this.handleChangeCategory}
            options={this._buildOptions()}
            value={this.props.item.customer_budget_code.id}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const productProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});
const budgetCodeProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

EditBudgetCodeRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: productProp.isRequired,
    customer_budget_code: budgetCodeProp,
  }).isRequired,
  isChecked: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChangeRowCustomerBudgetCode: PropTypes.func.isRequired,
  customerBudgetCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    showProductModal: PropTypes.func.isRequired,
  }).isRequired,
};

EditBudgetCodeRow.defaultProps = {
  onChangeRowCustomerBudgetCode: function () {},
  onSelect: function () {},
};

const filterActiveBudgetCodes = (c) => {
  return c.active;
};

export function mapStateToProps(state) {
  return {
    customerBudgetCodes: state.customerBudgetCodes.items.filter(
      filterActiveBudgetCodes
    ),
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
      },
      dispatch
    ),
  };
}
function areStatesEqual(prev, next) {
  return prev.customerBudgetCodes.items === next.customerBudgetCodes.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EditBudgetCodeRow);
