import PropTypes from 'prop-types';

const brandPropType = PropTypes.shape({
  images_attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string,
      original: PropTypes.string.isRequired,
      medium: PropTypes.string,
      thumb: PropTypes.string,
    })
  ),
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  url: PropTypes.string,
});

export default brandPropType;
