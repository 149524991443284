import React from 'react';

import PropTypes from 'prop-types';

class InputRadio extends React.PureComponent {
  onValueChange(value) {
    this.props.onChange && this.props.onChange(value);
    this.setState({
      value,
    });
  }

  renderOption = (option, index) => (
    <label key={index}>
      <input
        key={index}
        type="radio"
        name={this.props.name}
        value={option.value}
        onChange={this.onValueChange.bind(this, option.value)}
        checked={this.props.value === option.value}
      />
      <span className="input-radio-label">{option.label}</span>
      {option.sublabel && (
        <div className="input-radio-sublabel-container">
          <span className="input-radio-sublabel microcopy">
            {option.sublabel}
          </span>
        </div>
      )}
    </label>
  );

  render() {
    return (
      <div className="input-radio">
        <p>
          {this.props.label !== undefined ? this.props.label : 'Choose one'}
        </p>
        {this.props.options.map(this.renderOption)}
      </div>
    );
  }
}

InputRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      sublabel: PropTypes.string,
    })
  ).isRequired,
};

export default InputRadio;
