import * as types from '../actions/action-types.js';

export const empty = {
  openProduct: { id: 0 },
};

export default (state = empty, action) => {
  switch (action.type) {
    case types.OPEN_LOCATION_CATALOG_PRODUCT:
      return {
        ...state,
        openProduct: action.data,
      };
    case types.CLOSE_LOCATION_CATALOG_PRODUCT:
      return {
        ...state,
        openProduct: empty.openProduct,
      };
    default:
      return state;
  }
};
