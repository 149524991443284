import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Header } from 'semantic-ui-react';
import MultiSelect from '@officeluv/react-multi-select';
import SettingsTeamList from './settings-team-list.js';
import ModalCustomerRolesDesc from '../modals/modal-customer-roles-desc.js';
import { doInviteEmployees } from '../../actions/employee-actions.js';
import { REGEX_EMAIL } from '../../strings.js';
import { compareNameAsc } from '../../lib/formatters.js';
import { processCSV } from '../../helpers/csv-processing-helper.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/table.min.css';
import './settings-team-attendant.css';

export class SettingsTeamAttendant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailString: '',
      invitingRoleId: null,
      invitingLocations: [],
      inviting: [],
    };
  }

  componentDidMount() {
    window.totango.track('Viewed Team Details.', 'Settings');
  }

  componentWillUnmount() {
    this._confirmationTimeout && window.clearTimeout(this._confirmationTimeout);
  }

  parseEmailsInput(value) {
    return value
      .split(',')
      .map((e) => {
        return { email: e.trim() };
      })
      .filter((e) => !!e.email && e.email.match(REGEX_EMAIL))
      .map((e) => {
        return { email: e.email.match(REGEX_EMAIL)[0] };
      });
  }

  handleChangeEmails = (e, { value }) => {
    const inviting = this.parseEmailsInput(value);
    this.setState(() => {
      return {
        emailString: value,
        inviting,
      };
    });
  };

  handleChangeAdmin = (e, { value }) => {
    this.setState(() => {
      return {
        invitingRoleId: value,
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let invitingLocations =
      this.state.invitingLocations.length === 0
        ? [this.props.location.id]
        : this.state.invitingLocations;
    const inviting = this.state.inviting.map((emp) => {
      return {
        ...emp,
        locations: invitingLocations,
        customer_role_id: this.state.invitingRoleId,
      };
    });
    this.setState(() => {
      return {
        emailString: '',
        inviting: [],
      };
    });
    this.props.actions.doInviteEmployees(inviting);
  };

  isDisabled = () => {
    return (
      this.props.isRequesting ||
      !this.state.invitingRoleId ||
      !this.state.inviting.length
    );
  };

  _locationCanAccessBudgetCodes = () => {
    return this.props.location.pref_enable_custom_budget_codes;
  };

  _selectedLocations = () => {
    let employeeLocations = this.state.employeeLocations.map((l) => l.id);
    let userLocations = this.state.userLocations.map((l) => l.id);
    return userLocations.filter((loc) => employeeLocations.indexOf(loc) > -1);
  };

  _locationValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return 'Give access to this office...';
    }

    if (selected.length === options.length) {
      return 'Has access to all offices';
    }

    return `Give access to ${selected.length} ${
      selected.length > 1 ? 'Offices' : 'Office'
    }`;
  };

  _locationItemRenderer = (props) => {
    const { checked, option, onClick } = props;
    return (
      <span className="item-renderer">
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
        />
        <span>{option.label}</span>
      </span>
    );
  };

  _onEmployeeLocationSelect = (selected) => {
    this.setState(() => {
      return { invitingLocations: selected };
    });
  };

  _buildRoleOptions = () => {
    return this.props.customerRoles
      .filter((item) => item.name !== 'OfficeLuv Attendant')
      .map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
  };

  /**
   * Handle change event for CSV file input
   *
   * @access public
   * @param {object} event
   */
  handleChangeCSV = (event) => {
    processCSV(event.target, this.setCSVResult);
  };

  setCSVResult = (result) => {
    if (result.emails) {
      const emails = result.emails;
      delete result.emails;

      this.setState(
        Object.assign({}, this.state, {
          ...result,
          inviting: emails.map((email) => {
            return { email: email };
          }),
        })
      );
    } else {
      this.setState(Object.assign({}, this.state, result));
    }
  };

  render() {
    return (
      <div className="settings-team card">
        <div className="invitation-container">
          <Header as="h2">Your Team</Header>
          {this.props.can_manage_employees_locations && (
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Field width={8} className="">
                  <Form.TextArea
                    label="Invite New Users"
                    rows={2}
                    placeholder="Enter emails separated by commas"
                    onChange={this.handleChangeEmails}
                    value={this.state.emailString}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  {window.FileReader && (
                    <Form.Input
                      label="or Upload Emails via CSV"
                      onChange={this.handleChangeCSV}
                      icon="file alternate outline"
                      accept="text/csv,.csv"
                      error={this.state.errorCSV}
                      type="file"
                    />
                  )}
                </Form.Field>
              </Form.Group>
              <label style={{ fontSize: '.875rem' }}>Configure Access</label>
              <Form.Group>
                <Form.Field width={5} className="invitation-location-select">
                  <MultiSelect
                    valueRenderer={this._locationValueRenderer}
                    ItemRenderer={this._locationItemRenderer}
                    selected={this.state.invitingLocations}
                    onSelectedChanged={this._onEmployeeLocationSelect}
                    selectAllLabel="All Offices"
                    options={this.props.locations
                      .sort(compareNameAsc)
                      .map((l) => ({ value: l.id, label: l.name }))}
                  />
                </Form.Field>
                <Form.Field width={3}>
                  <Form.Dropdown
                    selection
                    fluid
                    placeholder="Select role"
                    onChange={this.handleChangeAdmin}
                    options={this._buildRoleOptions()}
                    value={this.state.invitingRoleId}
                  />
                </Form.Field>
                <Form.Field width={3}>
                  <ModalCustomerRolesDesc />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Button
                  width={8}
                  fluid
                  primary
                  disabled={this.isDisabled()}
                  loading={this.props.isRequesting}>
                  Send{' '}
                  {this.state.inviting.length
                    ? this.state.inviting.length.toLocaleString()
                    : ''}{' '}
                  Invite{this.state.inviting.length !== 1 ? 's' : ''}
                </Form.Button>
              </Form.Group>
            </Form>
          )}
        </div>
        <SettingsTeamList key={this.props.location.id} />
      </div>
    );
  }
}

SettingsTeamAttendant.propTypes = {
  isRequesting: PropTypes.bool,
  can_manage_employees_locations: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  actions: PropTypes.shape({
    doInviteEmployees: PropTypes.func.isRequired,
  }).isRequired,
  customerRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function mapStateToProps(state) {
  const anyUpdating =
    state.employees.updating.filter((e) => !e.error).length > 0;
  const anyCreating =
    state.employees.creating.filter((e) => !e.error).length > 0;
  return {
    isRequesting: !state.employees.initialized || anyCreating || anyUpdating,
    location: state.locations.open,
    locations: Object.values(state.locations.items),
    can_manage_employees_locations:
      state.auth.role.can_manage_employees_locations,
    customerRoles: Object.values(state.customerRoles.items),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doInviteEmployees,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth.role === next.auth.role &&
    prev.employees.initialized &&
    next.employees.initialized &&
    prev.employees.updating === next.employees.updating &&
    prev.employees.creating === next.employees.creating &&
    prev.locations.open === next.locations.open &&
    prev.locations.items === next.locations.items &&
    prev.customerRoles.items === next.customerRoles.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SettingsTeamAttendant);
