import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Pagination, Segment, Header } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import { updateQueryStringParameter } from '../../lib/formatters.js';
import EmptyRequisitions from '../requisitions/empty.js';
import { doGetRequisitions } from '../../actions/requisition-actions.js';
import RequisitionsByDate from '../requisitions/requisitions-by-date.js';
import RequisitionSearch from '../requisitions/requisition-search.js';
import { doGetRequisitionQuery } from '../../ducks/requisition-query.js';
import './orders.css';

const pageMatch = '[&|?]+page=([0-9]+)';

export class Orders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidMount() {
    const regexp = new RegExp(pageMatch, 'i');
    const pageQuery = window.location.search.match(regexp);
    const pageNum = pageQuery && parseInt(pageQuery[1], 10);
    if (pageNum) this.setState({ activePage: pageNum });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.openLocation.id !== this.props.openLocation.id) {
      this.setState({ activePage: 1 });
      return;
    }
    if (!prevProps.isSearching && this.props.isSearching) {
      this.resetPaginationNoScroll();
    }

    const pageChanged = prevState.activePage !== this.state.activePage;
    const noLongerRequesting =
      prevProps.isRequesting && !this.props.isRequesting;

    if (pageChanged || noLongerRequesting) {
      const desiredCount = this.state.activePage * this.props.meta.limit;
      const curTotal = this.props.requisitions.length;
      if (this.props.meta.total <= curTotal) return;

      if (curTotal < desiredCount && this.props.meta.next_cursor) {
        if (this.props.isSearching) {
          this.props.actions.doGetRequisitionQuery({
            query: this.props.meta.query,
            cursor: this.props.meta.next_cursor,
          });
        } else {
          this.props.actions.doGetRequisitions(this.props.meta.next_cursor);
        }
      }
    }
  }

  paginatedRequisitions() {
    const upper = this.state.activePage * this.props.meta.limit - 1;
    const lower = upper - (this.props.meta.limit - 1);

    return this.props.requisitions.slice(lower, upper);
  }

  handlePageChange = (event, data, scroll = true) => {
    this.setState({ activePage: data.activePage });
    this.setPageInUrl(data.activePage);
    scroll && window.scrollTo(0, 0);
  };

  resetPagination = (data = { scrollToTop: true }) => {
    this.handlePageChange({}, { activePage: 1 }, data.scrollToTop);
  };

  resetPaginationNoScroll = () => this.resetPagination({ scrollToTop: false });

  setPageInUrl = (activePage) => {
    const newURL = updateQueryStringParameter(
      window.location.pathname + window.location.search,
      'page',
      activePage
    );
    browserHistory.push(newURL);
  };

  render() {
    return (
      <div className="requisitions">
        <div className="requisitions-header">
          <h2 className="requisitions-title">Your Recent Orders</h2>
        </div>
        <RequisitionSearch onClearSearch={this.resetPaginationNoScroll} />
        {!this.props.isRequesting &&
          !this.props.requisitions.length &&
          !this.props.isSearching && <EmptyRequisitions />}
        {!this.props.isRequesting &&
          !this.props.requisitions.length &&
          this.props.isSearching && (
            <Segment basic>
              <Header as="h4" textAlign="center">
                No search results
              </Header>
            </Segment>
          )}

        <Segment
          basic
          placeholder={this.props.isRequesting}
          loading={this.props.isRequesting}>
          <RequisitionsByDate requisitions={this.paginatedRequisitions()} />
        </Segment>

        {this.props.meta.total > this.props.meta.limit && (
          <Pagination
            activePage={this.state.activePage}
            boundaryRange={0}
            defaultActivePage={this.state.activePage}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            onPageChange={this.handlePageChange}
            siblingRange={2}
            totalPages={Math.ceil(
              this.props.meta.total / this.props.meta.limit
            )}
          />
        )}
      </div>
    );
  }
}

Orders.propTypes = {
  requisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      submitted_at: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]).isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  meta: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};

const filterGroupRequests = (r) => {
  return !r.id;
};
const sortItems = (a, b) => {
  return a.submitted_at > b.submitted_at ? -1 : 1;
};

export function mapStateToProps(state) {
  const isSearching = state.requisitionQuery.initialized;
  return {
    isRequesting:
      !state.requisitions.initialized ||
      state.requisitions.requesting.filter(filterGroupRequests).length > 0 ||
      state.requisitionQuery.requesting.length > 0,
    isSearching,
    requisitions: isSearching
      ? state.requisitionQuery.results.data
      : state.requisitions.items.sort(sortItems),
    meta: isSearching
      ? state.requisitionQuery.results.meta
      : state.requisitions.meta,
    openLocation: state.locations.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetRequisitions,
        doGetRequisitionQuery,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.requesting === next.requisitions.requesting &&
    prev.requisitions.initialized === next.requisitions.initialized &&
    prev.requisitions.items === next.requisitions.items &&
    prev.requisitions.meta === next.requisitions.meta &&
    prev.locations.open === next.locations.open &&
    prev.requisitionQuery.initialized === next.requisitionQuery.initialized &&
    prev.requisitionQuery.requesting === next.requisitionQuery.requesting &&
    prev.requisitionQuery.results === next.requisitionQuery.results
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Orders);
