import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import Avatar from '../avatar.js';
import InputText from '../forms/input-text.js';
import { submitShipmentComment } from '../../ducks/shipments.js';
import './comment-form.css';

export class ShipmentCommentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      body: '',
    };
  }

  isDisabled = () => {
    return this.state.body.length < 3;
  };

  handleChangeComment = (body) => {
    this.setState(Object.assign({}, this.state, { body }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) {
      return;
    }
    window.totango.track('Reported an issue with a shipment.', 'Settings');
    this.setState(Object.assign({}, this.state, { isSubmitted: true }));
    this.props.actions.submitShipmentComment({
      commentable_type: 'Shipment',
      commentable_id: this.props.shipment.id,
      body: this.state.body,
    });
  };

  render() {
    return (
      <div className="shipment-comment-form-container">
        <div className="am-avatar">
          <Avatar item={this.props.account_manager} width={42} height={42} />
        </div>
        <div className="am-text">
          {!this.state.isSubmitted && (
            <div className="prompt-text">
              <p>Hi {this.props.username},</p>
              <p>What can I help you with?</p>
              <p className="microcopy">
                &mdash;{this.props.account_manager.name}
              </p>
            </div>
          )}
          {this.state.isSubmitted && (
            <div className="confirmation-text">
              Sorry to hear your order wasn&rsquo;t what you expected.
              I&rsquo;ll be in touch with you shortly to help fix the issue.
            </div>
          )}
        </div>
        <div className="shipment-comment-form">
          {!this.state.isSubmitted && (
            <form
              className="purchase-order-comment-form"
              onSubmit={this.handleSubmit}>
              <div className="comment-form-inner">
                <InputText
                  value={this.state.body}
                  onChange={this.handleChangeComment}
                  className="shipment-comment-input-body"
                  placeholder="My box of Kleenex came damaged"
                  autoFocus={true}
                  label=""
                  name="shipment-comment"
                />
                <Button
                  primary
                  size="small"
                  className="shipment-comment-submit"
                  disabled={this.isDisabled()}>
                  Report Issue
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

ShipmentCommentForm.propTypes = {
  actions: PropTypes.shape({
    submitShipmentComment: PropTypes.func.isRequired,
  }).isRequired,
  username: PropTypes.string.isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.isRequired,
  }).isRequired,
  account_manager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar_attachment: PropTypes.shape({
      medium: PropTypes.string.isRequired,
      thumb: PropTypes.string.isRequired,
      original: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    username: state.employees.user
      ? state.employees.user.first_name
      : state.staffs.user.name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitShipmentComment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.staffs.user === next.staffs.user
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ShipmentCommentForm);
