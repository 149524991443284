import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { SWITCH_LOCATION } from '../actions/action-types.js';
import { ADMIN_V1_URL } from '../strings.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';
export const ERROR_TIMEOUT = 5000;

// Actions
export const GET_CUSTOMER_ROLES = 'GET_CUSTOMER_ROLES';
export const SUCCESS_GET_CUSTOMER_ROLES = 'SUCCESS_GET_CUSTOMER_ROLES';
export const ERROR_GET_CUSTOMER_ROLES = 'ERROR_GET_CUSTOMER_ROLES';

export const GET_CUSTOMER_ROLE = 'GET_CUSTOMER_ROLE';
export const SUCCESS_GET_CUSTOMER_ROLE = 'SUCCESS_GET_CUSTOMER_ROLE';
export const ERROR_GET_CUSTOMER_ROLE = 'ERROR_GET_CUSTOMER_ROLE';

export const CREATE_CUSTOMER_ROLE = 'CREATE_CUSTOMER_ROLE';
export const SUCCESS_CREATE_CUSTOMER_ROLE = 'SUCCESS_CREATE_CUSTOMER_ROLE';
export const ERROR_CREATE_CUSTOMER_ROLE = 'ERROR_CREATE_CUSTOMER_ROLE';

export const UPDATE_CUSTOMER_ROLE = 'UPDATE_CUSTOMER_ROLE';
export const SUCCESS_UPDATE_CUSTOMER_ROLE = 'SUCCESS_UPDATE_CUSTOMER_ROLE';
export const ERROR_UPDATE_CUSTOMER_ROLE = 'ERROR_UPDATE_CUSTOMER_ROLE';

export const DELETE_CUSTOMER_ROLE = 'DELETE_CUSTOMER_ROLE';
export const SUCCESS_DELETE_CUSTOMER_ROLE = 'SUCCESS_DELETE_CUSTOMER_ROLE';
export const ERROR_DELETE_CUSTOMER_ROLE = 'ERROR_DELETE_CUSTOMER_ROLE';

export const UNSET_CUSTOMER_ROLE_RESPONSE = 'UNSET_CUSTOMER_ROLE_RESPONSE';
export const STAGE_CUSTOMER_ROLE = 'STAGE_CUSTOMER_ROLE';
export const UNSTAGE_CUSTOMER_ROLE = 'UNSTAGE_CUSTOMER_ROLE';
export const UPDATE_STAGED_CUSTOMER_ROLE = 'UPDATE_STAGED_CUSTOMER_ROLE';

// Helpers
export const FILTER_GET_INDEX_REQUEST = (req) =>
  req.data._request === 'GET_CUSTOMER_ROLES';

export const FILTER_IS_LOADING = (req) =>
  req.type === 'GET_CUSTOMER_ROLES' || req.type === 'GET_CUSTOMER_ROLE';

export const FILTER_IS_CREATE_SUCCESS = (req) =>
  req.type === 'SUCCESS_CREATE_CUSTOMER_ROLE';

export const FILTER_IS_UPDATE_SUCCESS = (req) =>
  req.type === 'SUCCESS_UPDATE_CUSTOMER_ROLE';

export const FILTER_IS_SAVE_SUCCESS = (req) =>
  req.type === 'SUCCESS_CREATE_CUSTOMER_ROLE' ||
  req.type === 'SUCCESS_UPDATE_CUSTOMER_ROLE';

export const FILTER_IS_SUBMITTING = (req) =>
  req.type === 'CREATE_CUSTOMER_ROLE' || req.type === 'UPDATE_CUSTOMER_ROLE';

export const FILTER_IS_ERROR = (req) =>
  req.type === 'ERROR_CREATE_CUSTOMER_ROLE' ||
  req.type === 'ERROR_UPDATE_CUSTOMER_ROLE';

// Reducer
export const empty = {
  initialized: false,
  items: {},
  open: {
    id: 0,
  },
  staged: {
    id: 0,
  },
  meta: {
    cursor: 0,
    next_cursor: 0,
    prev_cursor: 0,
    total: 0,
  },
  requesting: [],
  responses: [],
};

export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  let items;
  switch (action.type) {
    case GET_CUSTOMER_ROLES:
    case CREATE_CUSTOMER_ROLE:
    case UPDATE_CUSTOMER_ROLE:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_CUSTOMER_ROLES:
      return {
        ...state,
        items: Object.assign(
          {},
          state.items,
          action.data.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {})
        ),
        open: {
          ...state.open,
          ...action.data.items.filter((s) => s.id === state.open.id)[0],
        },
        staged: {
          ...state.staged,
          ...action.data.items.filter((s) => s.id === state.staged.id)[0],
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
        meta: action.data.meta,
      };
    case ERROR_GET_CUSTOMER_ROLES:
    case ERROR_CREATE_CUSTOMER_ROLE:
    case ERROR_UPDATE_CUSTOMER_ROLE:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_CREATE_CUSTOMER_ROLE:
    case SUCCESS_UPDATE_CUSTOMER_ROLE:
      items = { ...state.items };
      items[action.data.item.id] = action.data.item;
      return {
        ...state,
        items: items,
        open:
          action.data.item.id === state.open.id ? action.data.item : state.open,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_CUSTOMER_ROLE_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case STAGE_CUSTOMER_ROLE:
      return {
        ...state,
        staged: state.items[action.data.id] || action.data,
      };
    case UPDATE_STAGED_CUSTOMER_ROLE:
      return {
        ...state,
        staged: {
          ...state.staged,
          ...action.data,
        },
      };
    case UNSTAGE_CUSTOMER_ROLE:
      return {
        ...state,
        staged: empty.staged,
      };
    case SWITCH_LOCATION:
      return {
        ...state,
        items: empty.items,
        staged: empty.staged,
        open: empty.open,
        meta: empty.meta,
      };
    default:
      return state;
  }
};

// Action Creators
export const getCustomerRoles = (data) => ({ data, type: GET_CUSTOMER_ROLES });
export const successGetCustomerRoles = (data) => ({
  data,
  type: SUCCESS_GET_CUSTOMER_ROLES,
});
export const errorGetCustomerRoles = (data) => ({
  data,
  type: ERROR_GET_CUSTOMER_ROLES,
});

export const updateCustomerRole = (data) => ({
  data,
  type: UPDATE_CUSTOMER_ROLE,
});
export const successUpdateCustomerRole = (data) => ({
  data,
  type: SUCCESS_UPDATE_CUSTOMER_ROLE,
});
export const errorUpdateCustomerRole = (data) => ({
  data,
  type: ERROR_UPDATE_CUSTOMER_ROLE,
});

export const createCustomerRole = (data) => ({
  data,
  type: CREATE_CUSTOMER_ROLE,
});
export const successCreateCustomerRole = (data) => ({
  data,
  type: SUCCESS_CREATE_CUSTOMER_ROLE,
});
export const errorCreateCustomerRole = (data) => ({
  data,
  type: ERROR_CREATE_CUSTOMER_ROLE,
});

export const stageCustomerRole = (data) => ({
  data,
  type: STAGE_CUSTOMER_ROLE,
});
export const unstageCustomerRole = (data) => ({
  data,
  type: UNSTAGE_CUSTOMER_ROLE,
});
export const updateStagedCustomerRole = (data) => ({
  data,
  type: UPDATE_STAGED_CUSTOMER_ROLE,
});
export const unsetCustomerRoleResponse = (data) => ({
  data,
  type: UNSET_CUSTOMER_ROLE_RESPONSE,
});

/// THUNKS
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetCustomerRoleResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetCustomerRoles = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getCustomerRoles(data));
    return fetch(`${ADMIN_V1_URL}/customers/${data.id}/customer_roles`, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get customer roles' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetCustomerRoles)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetCustomerRoles)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doUpdateCustomerRole = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(updateCustomerRole(data));
    return fetch(
      `${ADMIN_V1_URL}/customers/${data.customer_id}/customer_roles/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Update Customer Role' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateCustomerRole)({
            item: body,
            _request: data._request,
          })
        );
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Role Updated!',
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateCustomerRole)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doCreateCustomerRole = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(createCustomerRole(data));
    return fetch(
      `${ADMIN_V1_URL}/customers/${data.customer_id}/customer_roles`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Create Customer Role' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successCreateCustomerRole)({
            item: body,
            _request: data._request,
          })
        );
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Role Created!',
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateCustomerRole)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doSubmitStagedCustomerRole = (data = {}) => {
  return (dispatch, getState) => {
    const {
      customerRoles: { staged },
    } = getState();
    if (staged.id > 0) {
      dispatch(
        doUpdateCustomerRole({
          ...staged,
          ...data,
        })
      );
    } else {
      dispatch(
        doCreateCustomerRole({
          ...staged,
          ...data,
        })
      );
    }
  };
};
