import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '../avatar.js';
import jack from '../../imgs/ordered-by-officeluv.svg';
import DeliveryInfoPdf from '../requisitions/item-delivery-info-pdf.js';
import { formatCentAmount, formatDateTime } from '../../lib/formatters.js';
import {
  displayName,
  displayUserName,
  proposerText,
} from '../../helpers/requisition-helpers.js';
import './subnav-supplies-order.css';
const OL_AVATAR = {
  id: -1,
  avatar_attachment: {
    medium: jack,
    thumb: jack,
    original: jack,
  },
};

export class SubNavSuppliesOrder extends React.PureComponent {
  reduceItemsQuantity(acc, val) {
    return acc + val.quantity;
  }

  cartQuantity = () => {
    return (this.props.item.product_requisitions || [])
      .reduce(this.reduceItemsQuantity, 0)
      .toLocaleString();
  };

  shipmentCount = () => {
    return this.props.shipments.length > 0
      ? this.props.shipments.length
      : 'Pending';
  };

  displayShippingPrice = () => {
    if (this.props.item.shipping_price > 0) {
      return formatCentAmount(this.props.item.shipping_price);
    }
    return 'Free';
  };

  render() {
    return (
      <div className="subnav-container-order">
        <div className="requisition-item-container fixed-width-column">
          <h2>{displayName(this.props.item)}</h2>
          <table className="requisition-item-table hide-when-printing">
            <thead className="requisition-item-header">
              <tr>
                <th className="order-date">
                  <h5>Ordered</h5>
                  {formatDateTime(this.props.item.submitted_at)}
                </th>
                <th className="order-requester">
                  <div className="supplies-order ordered-by-avatar">
                    {this.props.item.requester.id && (
                      <Avatar
                        item={this.props.item.requester}
                        width={36}
                        height={36}
                      />
                    )}
                    {!this.props.item.requester.id && (
                      <Avatar item={OL_AVATAR} width={36} height={36} />
                    )}
                  </div>
                  <div className="supplies-order ordered-by-name">
                    <h5 className="supplies-order ordered-by-subheader">
                      {this.props.item.proposer ? 'Approved By' : 'Ordered By'}
                    </h5>
                    {displayUserName(
                      this.props.item.requester,
                      this.props.item.requester_type
                    )}
                  </div>
                </th>
                {this.props.item.proposer && (
                  <th>
                    <div className="supplies-order ordered-by-avatar">
                      {this.props.item.proposer && (
                        <Avatar
                          item={this.props.item.proposer}
                          width={36}
                          height={36}
                        />
                      )}
                      {!this.props.item.proposer && (
                        <Avatar item={OL_AVATAR} width={36} height={36} />
                      )}
                    </div>
                    <div className="supplies-order ordered-by-name">
                      <h5 className="supplies-order ordered-by-subheader">
                        {proposerText(this.props.item)}
                      </h5>
                      {displayUserName(
                        this.props.item.proposer,
                        this.props.item.proposer_type
                      )}
                    </div>
                  </th>
                )}
                <th className="order-cart-quantity">
                  <h5># Items</h5>
                  {this.cartQuantity()}
                </th>
                <th className="order-shipment-count">
                  <h5>Shipments</h5>
                  {this.shipmentCount()}
                </th>
                <th className="order-total-spend">
                  <h5>Total Spend</h5>
                  {formatCentAmount(this.props.item.total_price)}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="print-pdf">
          <DeliveryInfoPdf
            item={this.props.item}
            location={this.props.location}
            orderedBy={displayUserName(
              this.props.item.requester,
              this.props.item.requester_type
            )}
            proposedBy={
              this.props.item.proposer
                ? displayUserName(
                    this.props.item.proposer,
                    this.props.item.proposer_type
                  )
                : undefined
            }
            shipmentCount={this.shipmentCount()}
            cartQuantity={this.cartQuantity()}
            shippingPrice={this.displayShippingPrice()}
          />
        </div>
      </div>
    );
  }
}

SubNavSuppliesOrder.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    total_price: PropTypes.number.isRequired,
    requester: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

function mapStateToProps(state) {
  const productRequisitionIds =
    state.requisitions.open.product_requisitions.map((pr) => pr.id);
  return {
    location: state.locations.open,
    shipments: Object.values(state.shipments.items).filter(
      (s) =>
        s.product_purchase_orders.filter(
          (ppo) =>
            productRequisitionIds.indexOf(ppo.product_requisition_id) > -1
        ).length > 0
    ),
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.shipments.items === next.shipments.items &&
    prev.requisitions.open.product_requisitions ===
      next.requisitions.open.product_requisitions
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SubNavSuppliesOrder);
