import browserHistory from '../lib/history.js';
import * as types from '../actions/action-types.js';
import { OPEN_SHIPMENT, CLOSE_SHIPMENT } from '../ducks/shipments.js';
import {
  filtersAsParams,
  sortAsParams,
  removeFilters,
} from '../helpers/router-helpers.js';
import { CLOSE_FEATURE_TOUR } from '../ducks/feature-tours.js';

const router = (store) => (next) => (action) => {
  let cleanSearch;
  let state;

  switch (action.type) {
    case CLOSE_FEATURE_TOUR:
      if (action.data.pushTo) {
        browserHistory.push(action.data.pushTo);
      }
      break;
    case types.STAFF_CLOCKED_OUT:
      browserHistory.push('/staff-clocked-out');
      break;
    case types.OPEN_CLEANING:
      if (action.route.match(/detail/)) {
        browserHistory.push(
          `/office-attendant/shift-details/${action.data.id}`
        );
      }
      if (action.route.match(/report/)) {
        browserHistory.push(
          `/office-attendant/shift-reports/${action.data.id}`
        );
      }
      break;
    case OPEN_SHIPMENT:
      if (action.data.__via === 'popstate') {
        break;
      }
      cleanSearch = window.location.search
        .substring(1)
        .replace(/[&|?]*shipment=[0-9]+/g, '');
      browserHistory.push(
        window.location.pathname +
          '?' +
          cleanSearch
            .concat(cleanSearch.length ? '&' : '')
            .concat(`shipment=${action.data.id}`)
      );
      break;
    case CLOSE_SHIPMENT:
      browserHistory.push(
        window.location.pathname +
          window.location.search.replace(/([?]*)[&]*shipment=[0-9]+/g, '$1')
      );
      break;
    case types.OPEN_PRODUCT:
      if (action.data.__via === 'popstate') {
        break;
      }
      cleanSearch = window.location.search
        .substring(1)
        .replace(/[&|?]*product=[0-9]+/g, '');
      browserHistory.push(
        window.location.pathname +
          '?' +
          cleanSearch
            .concat(cleanSearch.length ? '&' : '')
            .concat(`product=${action.data.id}`)
      );
      break;
    case types.CLOSE_PRODUCT:
      browserHistory.push(
        window.location.pathname +
          window.location.search.replace(/([?]*)[&]*product=[0-9]+/g, '$1')
      );
      break;
    case types.SUCCESS_EDIT_REQUISITION:
      browserHistory.push(
        `/supplies/orders/${action.data.id}?confirm_order=${action.data.id}&edit_order=true`
      );
      break;
    case types.SET_PRODUCT_QUERY:
      if (action.data.__via === 'popstate') break;
      if (action.data.filters) {
        browserHistory.push(
          window.location.pathname + filtersAsParams(action.data.filters)
        );
      }
      break;
    case types.SET_PRODUCT_SORT: {
      if (action.data.__via === 'popstate') break;
      browserHistory.push(window.location.pathname + sortAsParams(action.data));
      break;
    }
    case types.CLEAR_PRODUCT_FILTERS:
      browserHistory.push(window.location.pathname + removeFilters());
      break;
    default:
      break;
  }

  let result = next(action);

  switch (action.type) {
    case types.SUCCESS_EMPLOYEE_SELF:
    case types.SWITCH_LOCATION:
      state = store.getState();
      if (
        !state.locations.open.onboarded_at &&
        state.auth.role.can_manage_employees_locations
      ) {
        browserHistory.push('/onboarding/company');
      }
      break;
    default:
      break;
  }

  return result;
};

export default router;
