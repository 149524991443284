import * as types from './action-types.js';
import log from '../lib/log.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackInterfaceOpenInvoice,
  trackInterfaceUpdateBillingAddress,
} from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';
const logger = log('[invoices]');

const getInvoices = () => {
  return {
    type: types.GET_INVOICES,
  };
};

const errorGetInvoices = (data) => {
  return {
    type: types.ERROR_GET_INVOICES,
    data,
  };
};

const successGetInvoices = (data) => {
  return {
    type: types.SUCCESS_GET_INVOICES,
    data,
  };
};

export const doOpenInvoice = (data) => {
  trackInterfaceOpenInvoice(data);
  return {
    type: types.OPEN_INVOICE,
    data,
  };
};

export const doCloseInvoice = () => {
  return {
    type: types.CLOSE_INVOICE,
  };
};

export const getBillingAddresses = () => {
  return {
    type: types.GET_BILLING_ADDRESSES,
  };
};

export const successGetBillingAddresses = (data) => {
  return {
    type: types.SUCCESS_GET_BILLING_ADDRESSES,
    data,
  };
};

export const errorGetBillingAddresses = (data) => {
  return {
    type: types.ERROR_GET_BILLING_ADDRESSES,
    data,
  };
};

export const updateBillingAddress = (data) => {
  return {
    type: types.UPDATE_BILLING_ADDRESS,
    data,
  };
};

export const successUpdateBillingAddress = (data) => {
  return {
    type: types.SUCCESS_UPDATE_BILLING_ADDRESS,
    data,
  };
};

export const errorUpdateBillingAddress = (data) => {
  return {
    type: types.ERROR_UPDATE_BILLING_ADDRESS,
    data,
  };
};

/**
 * Get invoices for open location
 *
 * @access public
 * @returns {Promise}
 */
export function doGetInvoices(paged = null, data = {}) {
  return (dispatch, getState) => {
    dispatch(getInvoices());
    const { locations } = getState();
    const path = paged || `/locations/${locations.open.id}/invoices?limit=1000`;
    return fetch(`${ADMIN_V1_URL}${path}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: { location_id: locations.open.id },
            message: 'Get invoices',
          });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = data.location_id;
        dispatch(successGetInvoices(body.data));
        if (body.meta && body.meta.next_cursor) {
          dispatch(
            doGetInvoices(
              `/locations/${data.location_id}/invoices?cursor=${body.meta.next_cursor}&limit=1000`
            )
          );
        }
      })
      .catch((error) => {
        dispatch(errorGetInvoices(error));
        trackFetchError(error);
      });
  };
}

export function doUpdateQuickbooksBillingInfo(account) {
  return (dispatch, getState) => {
    dispatch(updateBillingAddress(account));
    const { locations } = getState();
    return fetch(
      `${ADMIN_V1_URL}/locations/${locations.open.id}/external_accounts/quickbooks`,
      {
        method: 'PATCH',
        body: JSON.stringify(account),
        headers: HEADERS_JSON,
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: account,
            message: 'Update billing address',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateBillingAddress(body.data));
        trackInterfaceUpdateBillingAddress(body.data);
      })
      .catch((error) => {
        dispatch(errorUpdateBillingAddress(error));
        trackFetchError(error);
      });
  };
}

export function doGetInvoice(id) {
  return (dispatch) => {
    dispatch({ type: types.DO_GET_INVOICE });
    return fetch(`${ADMIN_V1_URL}/invoices/${id}/pdf`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, message: 'Unable to fetch invoice' });
        }
        dispatch({ type: types.SUCCESS_DO_GET_INVOICE });
        return response.blob();
      })
      .then((blob) => {
        let url = '';
        try {
          url = (window.URL || window.webkitURL).createObjectURL(blob);
        } catch (e) {
          logger.error(e);
        }
        return {
          blob,
          url,
        };
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_DO_GET_INVOICE });
        trackFetchError(error);
      });
  };
}
