import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackShipmentComment,
  trackShipmentUpdated,
  trackInterfaceShipmentOpened,
} from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { SWITCH_LOCATION } from '../actions/action-types.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

export const ERROR_TIMEOUT = 5000;

// Action Types
export const GET_SHIPMENTS = 'GET_SHIPMENTS';
export const SUCCESS_GET_SHIPMENTS = 'SUCCESS_GET_SHIPMENTS';
export const ERROR_GET_SHIPMENTS = 'ERROR_GET_SHIPMENTS';
export const GET_SHIPMENT = 'GET_SHIPMENT';
export const SUCCESS_GET_SHIPMENT = 'SUCCESS_GET_SHIPMENT';
export const ERROR_GET_SHIPMENT = 'ERROR_GET_SHIPMENT';
export const OPEN_SHIPMENT = 'OPEN_SHIPMENT';
export const CLOSE_SHIPMENT = 'CLOSE_SHIPMENT';
export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
export const SUCCESS_UPDATE_SHIPMENT = 'SUCCESS_UPDATE_SHIPMENT';
export const ERROR_UPDATE_SHIPMENT = 'ERROR_UPDATE_SHIPMENT';
export const SUCCESS_DELETE_SHIPMENT = 'SUCCESS_DELETE_SHIPMENT';
export const CREATE_SHIPMENT_COMMENT = 'CREATE_SHIPMENT_COMMENT';
export const SUCCESS_CREATE_SHIPMENT_COMMENT =
  'SUCCESS_CREATE_SHIPMENT_COMMENT';
export const ERROR_CREATE_SHIPMENT_COMMENT = 'ERROR_CREATE_SHIPMENT_COMMENT';
export const UNSET_SHIPMENT_RESPONSE = 'UNSET_SHIPMENT_RESPONSE';

// Action Creators
export const getShipments = (data) => {
  return {
    data,
    type: GET_SHIPMENTS,
  };
};

export const errorGetShipments = (data) => {
  return {
    type: ERROR_GET_SHIPMENTS,
    data,
  };
};

export const successGetShipments = (data) => {
  return {
    type: SUCCESS_GET_SHIPMENTS,
    data,
  };
};

export const getShipment = (data) => {
  return {
    type: GET_SHIPMENT,
    data,
  };
};

export const errorGetShipment = (data) => {
  return {
    type: ERROR_GET_SHIPMENT,
    data,
  };
};

export const successGetShipment = (data) => {
  return {
    type: SUCCESS_GET_SHIPMENT,
    data,
  };
};

export const updateShipment = (data) => {
  return {
    type: UPDATE_SHIPMENT,
    data,
  };
};

export const errorUpdateShipment = (data) => {
  return {
    type: ERROR_UPDATE_SHIPMENT,
    data,
  };
};

export const successUpdateShipment = (data) => {
  return {
    type: SUCCESS_UPDATE_SHIPMENT,
    data,
  };
};

export const openShipment = (data) => {
  trackInterfaceShipmentOpened(data);
  return {
    type: OPEN_SHIPMENT,
    data,
  };
};

export const closeShipment = (data) => {
  return {
    type: CLOSE_SHIPMENT,
    data,
  };
};

export const postCreateShipmentComment = (data) => {
  return {
    type: CREATE_SHIPMENT_COMMENT,
    data,
  };
};

export const successCreateShipmentComment = (data) => {
  return {
    type: SUCCESS_CREATE_SHIPMENT_COMMENT,
    data,
  };
};

export const errorCreateShipmentComment = (data) => {
  return {
    type: ERROR_CREATE_SHIPMENT_COMMENT,
    data,
  };
};

export const successDeleteShipment = (data) => {
  return {
    type: SUCCESS_DELETE_SHIPMENT,
    data,
  };
};

export const unsetShipmentResponse = (data) => ({
  data,
  type: UNSET_SHIPMENT_RESPONSE,
});

// Reducer

const initialQuery = window.location.search.match(/[&|?]+shipment=([0-9]+)/);
export const empty = {
  items: {},
  open: { id: 0 },
  requesting: [],
  responses: [],
};
export const initial = Object.assign({}, empty, {
  open: {
    id: (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id,
  },
});

export default (state = initial, action) => {
  let items;
  let item;
  switch (action.type) {
    case GET_SHIPMENTS:
    case GET_SHIPMENT:
    case UPDATE_SHIPMENT:
    case CREATE_SHIPMENT_COMMENT:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_SHIPMENTS:
      return {
        ...state,
        items: Object.assign(
          {},
          state.items,
          action.data.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {})
        ),
        open: {
          ...state.open,
          ...action.data.items.filter((s) => s.id === state.open.id)[0],
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_GET_SHIPMENT:
    case SUCCESS_UPDATE_SHIPMENT:
      items = { ...state.items };
      items[action.data.item.id] = action.data.item;
      return {
        ...state,
        items: items,
        open:
          state.open.id === action.data.item.id
            ? { ...state.open, ...action.data.item }
            : state.open,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case ERROR_GET_SHIPMENTS:
    case ERROR_GET_SHIPMENT:
    case ERROR_UPDATE_SHIPMENT:
      return {
        ...state,
        requesting: state.requesting.filter(
          (x) => x._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_DELETE_SHIPMENT:
      items = { ...state.items };
      delete items[action.data.id];
      return {
        ...state,
        items: items,
        requesting: state.requesting.filter(
          (llp) => llp._request !== action.data._request
        ),
        open: state.open.id === action.data.id ? empty.open : state.open,
      };
    case OPEN_SHIPMENT:
      return {
        ...state,
        open: state.items[action.data.id] || initial.open,
      };
    case CLOSE_SHIPMENT:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case SUCCESS_CREATE_SHIPMENT_COMMENT:
      items = { ...state.items };
      item = items[action.data.item.commentable_id];
      if (item) {
        item = {
          ...items[action.data.item.commentable_id],
          comments: (items[action.data.item.commentable_id].comments || [])
            .filter((comment) => comment.id !== action.data.item.id)
            .concat(action.data.item),
        };
        items[action.data.item.commentable_id] = item;
      }

      return {
        ...state,
        items: items,
        open:
          state.open.id !== action.data.item.commentable_id
            ? state.open
            : Object.assign({}, state.open, {
                comments: state.open.comments
                  .filter((comment) => comment.id !== action.data.item.id)
                  .concat(action.data.item),
              }),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case ERROR_CREATE_SHIPMENT_COMMENT:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (s) => s._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      });
    case UNSET_SHIPMENT_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case SWITCH_LOCATION:
      return {
        ...empty,
      };
    default:
      return state;
  }
};

// Thunks

export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetShipmentResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

/**
 * Get index for shipments
 *
 * @access public
 * @param {Number} [cursor] Next cursor for pagination
 * @returns {Promise}
 */

export function doGetShipments(cursor, data = {}) {
  return (dispatch, getState) => {
    data._request = data._request || GET_SHIPMENTS;
    const location = getState().locations.open;
    data = {
      ...data,
      location_id: location.id,
    };
    dispatch(getShipments(data));
    let cursor_paged = cursor ? `&cursor=${cursor}` : '';
    const url = `/locations/${location.id}/shipments?limit=100${cursor_paged}`;
    return fetch(`${ADMIN_V1_URL}${url}`, {
      headers: {
        ...HEADERS_JSON,
        'x-request-id': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get shipments' });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = data.location_id;
        dispatch(
          doAndUnsetResponse(successGetShipments)({
            items: body.data,
            _request: data._request,
          })
        );

        if (body.meta && body.meta.next_cursor) {
          dispatch(doGetShipments(body.meta.next_cursor));
        }
        return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetShipments)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
}

/**
 * Update shipment
 *
 * @access public
 * @param {Object} data
 * @param {Object} [shipment]
 * @param {Bool} options.bubble
 * @returns {Promise}
 */

export function doUpdateShipment(data, options = { bubble: false }) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    const state = getState();
    data.location_id = data.location_id || state.locations.open.id;
    dispatch(updateShipment(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/shipments/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'x-request-id': data._request,
        },
        body: JSON.stringify({
          ...data,
          location_id: undefined,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: data,
            message: 'Update shipment ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successUpdateShipment)({
            item: body,
            _request: data._request,
          })
        );
        trackShipmentUpdated(body);
        if (options.markedAs && options.markedAs === 'missed') {
          dispatch(
            enqueueConfirmation({
              message:
                'OfficeLuv Support will look into your shipment and follow up with you shortly!',
            })
          );
        }
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorUpdateShipment)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
}

/**
 * Get shipment
 *
 * @access public
 * @param {Object} [shipment]
 * @returns {Promise}
 */

export function doGetShipment(data) {
  return (dispatch, getState) => {
    dispatch(getShipment(data));
    const location = getState().locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/shipments/${data.id}`,
      {
        headers: {
          ...HEADERS_JSON,
          'x-request-id': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get shipment ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetShipment)({
            item: body,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetShipment)({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
}

/**
 * Create shipment comment
 *
 * @access public
 * @returns {Promise}
 */
export function submitShipmentComment(data) {
  let comment = {
    commentable_type: 'Shipment',
    commentable_id: data.commentable_id,
    body: data.body,
    _request: data._request || requestID(),
  };
  return (dispatch, getState) => {
    const { staffs, employees } = getState();
    if (staffs.user.id !== 0) {
      comment.commenter_type = 'Staff';
      comment.commenter_id = staffs.user.id;
    } else if (employees.user.id !== 0) {
      comment.commenter_type = 'Employee';
      comment.commenter_id = employees.user.id;
    }
    dispatch(postCreateShipmentComment(comment));
    return fetch(`${ADMIN_V1_URL}/comments`, {
      method: 'POST',
      headers: HEADERS_JSON,
      body: JSON.stringify(comment),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: comment,
            message: 'Create shipment comment',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successCreateShipmentComment)({
            item: body,
            _request: comment._request,
          })
        );
        trackShipmentComment(body);
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateShipmentComment)({
            error,
            _request: comment._request,
          })
        );
        trackFetchError(error);
      });
  };
}
