import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackReviewCreated,
  trackReviewUpdated,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { AANG_URL, HEADERS_JSON } from '../strings.js';

export const postCreateReview = (data) => {
  return {
    type: types.POST_CREATE_REVIEW,
    data,
  };
};

export const successCreateReview = (data) => {
  return {
    type: types.SUCCESS_CREATE_REVIEW,
    data,
  };
};

export const errorCreateReview = (data) => {
  return {
    type: types.ERROR_CREATE_REVIEW,
    data,
  };
};

export const postUpdateReview = (data) => {
  return {
    type: types.POST_UPDATE_REVIEW,
    data,
  };
};

export const successUpdateReview = (data, update) => {
  return {
    type: types.SUCCESS_UPDATE_REVIEW,
    data,
    update,
  };
};

export const errorUpdateReview = (data) => {
  return {
    type: types.ERROR_UPDATE_REVIEW,
    data,
  };
};

/**
 * Submit Review
 *
 * @access public
 * @param {object}  data
 * @param {string}  data.comment
 * @param {number}  data.customer_id
 * @param {number}  data.employee_id
 * @param {number}  data.cleaning_id
 * @param {number}  data.stars
 * @param {object}  [options]
 * @param {boolean} [options.bubble]
 * @returns {function} redux thunk
 */
export function submitReview(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(postCreateReview(data));
    return fetch(`${AANG_URL}/reviews`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Create review' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateReview(
            Object.assign(body, { _request: data._request }),
            data
          )
        );
        trackReviewCreated(body);
      })
      .catch((error) => {
        dispatch(errorCreateReview(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Submit update review
 *
 * @access public
 * @param {object}  data
 * @param {number}  data.id
 * @param {string}  [data.comment]
 * @param {number}  [data.customer_id]
 * @param {number}  [data.employee_id]
 * @param {number}  [data.cleaning_id]
 * @param {number}  [data.stars]
 * @returns {function} redux thunk
 */
export function submitUpdateReview(data) {
  return (dispatch) => {
    dispatch(postUpdateReview(data));
    return fetch(`${AANG_URL}/reviews/${data.id}`, {
      method: 'PUT',
      headers: HEADERS_JSON,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update review ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateReview(body, data));
        trackReviewUpdated(body);
      })
      .catch((error) => {
        dispatch(errorUpdateReview(error));
        trackFetchError(error);
      });
  };
}
