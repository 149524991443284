import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import CoupaImage from '../../imgs/coupa_logo2013_RGB_rev_LowRes.png';
import CoupaImageIcon from '../../imgs/coupa_logo2013_RGB_rev_LowRes_icon.png';
import './nav-coupa.css';

const NavCoupa = (props) => {
  if (!props.hasPunchoutSession) return null;

  return (
    <div className="nav-coupa">
      {props.expanded && <Image src={CoupaImage} size="small" />}
      {!props.expanded && (
        <Image src={CoupaImageIcon} size="small" style={{ maxWidth: '40px' }} />
      )}
    </div>
  );
};

NavCoupa.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hasPunchoutSession: state.punchoutSessions.open.id > 0,
  };
};

const areStatesEqual = (prev, next) => {
  return prev.punchoutSessions.open === next.punchoutSessions.open;
};

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  NavCoupa
);
