import React from 'react';
import PropTypes from 'prop-types';
import Preview from './preview.js';
import './preview-list.css';

export default class AttachmentPreviewList extends React.PureComponent {
  renderAttachment = (attachment, index) => {
    return (
      <Preview key={index} onRemove={this.props.onRemove} item={attachment} />
    );
  };

  render() {
    return (
      <div className="attachment-preview-list">
        {this.props.items.map(this.renderAttachment)}
      </div>
    );
  }
}

AttachmentPreviewList.propTypes = {
  onRemove: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
};
