import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import setTitle from '../decorators/set-title.js';
import OnboardingStep from '../components/onboarding/step.js';
import OnboardingCompanyForm from '../components/onboarding/company-form.js';
import AuthCheck from '../components/auth-check.js';
import image from '../imgs/onboarding/company.jpeg';
import { isAdmin } from '../helpers/customer-role-helpers.js';

export class OnboardingCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _request: null,
    };
    this.maybeRedirect(props);
  }

  componentDidUpdate() {
    this.maybeRedirect(this.props);
  }

  maybeRedirect(props) {
    if (!props.location.id) return;
    if (!props.role.can_manage_employees_locations) {
      if (props.role.can_manage_all_requisitions) {
        return browserHistory.push('/onboarding/delivery-info');
      } else if (props.role.can_manage_billing) {
        return browserHistory.push('/onboarding/billing-info');
      } else {
        return browserHistory.push('/supplies/catalog');
      }
    }
    if (props.location._request === this.state._request) {
      return browserHistory.push('/onboarding/team');
    }
    if (props.location.onboarded_at) {
      return browserHistory.push('/onboarding/team');
    }
  }

  handleSubmit = (data) => {
    this.setState(() => {
      return { _request: data._request };
    });
  };

  render() {
    return (
      <OnboardingStep backgroundImage={`url(${image})`}>
        <AuthCheck customAuthorized={isAdmin(this.props.role.name)} />
        <OnboardingCompanyForm onSubmit={this.handleSubmit} />
      </OnboardingStep>
    );
  }
}

OnboardingCompany.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    _request: PropTypes.string,
  }).isRequired,
  role: PropTypes.shape({
    can_manage_employees_locations: PropTypes.bool.isRequired,
    can_manage_all_requisitions: PropTypes.bool.isRequired,
    can_manage_billing: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

OnboardingCompany.defaultProps = {};

OnboardingCompany = setTitle(() => {
  return 'OfficeLuv Onboarding';
})(OnboardingCompany);

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    role: state.auth.role,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  OnboardingCompany
);
