import React from 'react';
import PropTypes from 'prop-types';
import './draggable.css';

function Draggable(WrappedComponent) {
  return class extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isDragging: false,
      };
    }

    handleDragStart = (ev) => {
      ev.dataTransfer.setData('id', this.props.item.id);
      ev.dataTransfer.effectAllowed = 'move';
      this.setState({ ...this.state, isDragging: true });
    };

    handleDragEnd = (ev) => {
      this.setState({ ...this.state, isDragging: false });
    };

    render() {
      return (
        <div
          className="draggable"
          draggable={this.props.isDraggable || true}
          id={this.props.item.id}
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}>
          <WrappedComponent {...this.props} />
          {this.state.isDragging && <div className="draggable-phantom"></div>}
        </div>
      );
    }
  };
}

Draggable.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isDraggable: PropTypes.bool.isRequired,
};

export default Draggable;
