import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerRoleDetail from './customer-role-detail.js';

export class CustomerRoleDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (e, titleProps) => {
    const { role } = titleProps;
    this.setState((prevState) => {
      const newState = { ...prevState };
      if (prevState[role.id] !== undefined) {
        newState[role.id] = !prevState[role.id];
      } else {
        newState[role.id] = this.props.compact;
      }
      return newState;
    });
  };

  render() {
    return Object.values(this.props.customerRoles).map((role, i) => (
      <CustomerRoleDetail
        compact={this.props.compact}
        isOpen={
          this.state[role.id] !== undefined
            ? this.state[role.id]
            : !this.props.compact
        }
        customerRoles={this.props.customerRoles}
        key={i}
        handleClick={this.handleClick}
        role={role}
      />
    ));
  }
}

CustomerRoleDetails.propTypes = {
  customerRoles: PropTypes.object.isRequired,
  compact: PropTypes.bool,
};

CustomerRoleDetails.defaultProps = {
  compact: false,
};

export function mapStateToProps(state) {
  return {
    customerRoles: state.customerRoles.items,
  };
}

export function areStatesEqual(prev, next) {
  return prev.customerRoles.items === next.customerRoles.items;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CustomerRoleDetails
);
