import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Segment,
  Header,
  List,
  Grid,
  Icon,
  Accordion,
} from 'semantic-ui-react';
import classnames from 'classnames';
import {
  abilities,
  buildPermissionText,
  CUSTOMER_ROLE_PROPTYPES,
} from '../../helpers/customer-role-helpers.js';
import CustomerRoleSummary from './customer-role-summary.js';
import { formatCentAmount } from '../../lib/formatters.js';
import './customer-role-detail.css';

export class CustomerRoleDetail extends React.PureComponent {
  renderRoleList = (roles, key) => {
    return roles.map((r, i) => {
      const role = this.props.customerRoles[r[key]];
      if (!role) return null;
      if (i === 0) return <b>{role.name}s</b>;
      return (
        <Fragment>
          , <b>{role.name}s</b>
        </Fragment>
      );
    });
  };

  renderCans = (cans) =>
    cans.map((key) => (
      <List.Item>
        <List.Content>{buildPermissionText(key)}</List.Content>
      </List.Item>
    ));

  render() {
    const { role, isOpen, customerRoles } = this.props;
    const cans = abilities(role);
    return (
      <Segment
        attached="top"
        className={classnames('role-detail', {
          compact: this.props.compact,
        })}>
        <Accordion>
          <Accordion.Title
            active={isOpen}
            role={role}
            onClick={this.props.handleClick}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as={this.props.compact ? 'h4' : 'h3'}>
                    <Icon name="dropdown" />
                    {role.name}
                  </Header>
                </Grid.Column>
                {!['global admin', 'officeluv attendant'].includes(
                  role.name.toLowerCase()
                ) && (
                  <Grid.Column width={4}>
                    <Link
                      style={{ float: 'right' }}
                      to={`/customer-roles/${role.id}`}
                      className="tertiary">
                      Edit
                    </Link>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
            {!isOpen && (
              <CustomerRoleSummary
                role={role}
                customerRoles={customerRoles}
                compact={this.props.compact}
              />
            )}
          </Accordion.Title>
          <Accordion.Content active={isOpen}>
            {cans.length > 0 && (
              <Segment vertical>
                <p>{role.name}s can:</p>
                <List bulleted style={{ marginLeft: '3.2rem' }}>
                  {this.renderCans(cans)}
                </List>
              </Segment>
            )}
            {cans.length === 0 && (
              <Segment vertical>
                <p>{role.name}s have no special permissions.</p>
              </Segment>
            )}
            {(role.customer_role_approvals.length > 0 ||
              role.customer_role_approvers.length > 0) && (
              <Segment vertical style={{ display: 'flex' }}>
                <div>
                  {role.customer_role_approvers.length > 0 && (
                    <p>
                      {role.name}s submit orders to{' '}
                      {this.renderRoleList(
                        role.customer_role_approvers,
                        'approver_id'
                      )}{' '}
                      for approval.
                    </p>
                  )}
                  {role.customer_role_approvals.length > 0 && (
                    <p>
                      {role.name}s approve orders from{' '}
                      {this.renderRoleList(
                        role.customer_role_approvals,
                        'proposer_id'
                      )}
                      .
                    </p>
                  )}
                </div>
              </Segment>
            )}
            {role.approval_required_after_employee_spend !== null &&
              role.approval_required_after_employee_spend > 0 && (
                <Segment
                  vertical
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <p>
                    Orders from {role.name}s require approval after spending{' '}
                    <b>
                      {formatCentAmount(
                        role.approval_required_after_employee_spend
                      )}
                    </b>{' '}
                    in a month.
                  </p>
                </Segment>
              )}
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

CustomerRoleDetail.propTypes = {
  compact: PropTypes.bool.isRequired,
  role: CUSTOMER_ROLE_PROPTYPES,
  index: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
};

export default CustomerRoleDetail;
