import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import InputTextArea from '../forms/input-textarea.js';
import { submitCleaningComment } from '../../actions/cleaning-actions.js';
import './comment-form.css';

export class CommentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      body: '',
    };
  }

  handleChangeCleaningCommentBody = (body) => {
    this.setState(Object.assign({}, this.state, { body }));
  };

  handleSubmitCleaningComment = (e) => {
    e.preventDefault();
    this.props.actions.submitCleaningComment(
      { body: this.state.body, commenter_id: this.props.user.id },
      this.props.cleaning
    );
    this.setState(Object.assign({}, this.state, { body: '' }));
  };

  render() {
    return (
      <form
        className="cleaning-comment-form"
        onSubmit={this.handleSubmitCleaningComment}>
        <InputTextArea
          className="cleaning-comment-form-input comment-body"
          value={this.state.body}
          placeholder="E.g. There is a VIP in the office today, please throughly clean the kitchen."
          label="Leave your Attendant a note."
          autoFocus={false}
          onChange={this.handleChangeCleaningCommentBody}
        />
        <div className="button-wrap">
          <Button primary>Leave Note</Button>
        </div>
      </form>
    );
  }
}

CommentForm.propTypes = {
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.employees.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitCleaningComment,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
