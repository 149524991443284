import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import browserHistory from '../../lib/history.js';
import moment from 'moment';
import { Menu } from 'semantic-ui-react';
import ProductBudgetCodeLink from './product-budget-code-link.js';
import { openDataViewRange } from '../../actions/data-view-actions.js';
import 'semantic-ui-css/components/menu.min.css';

export class ProductRequisitionBudgetCodeLinks extends React.PureComponent {
  redirectToBudgetCode = (budgetCode) => {
    let startDate = moment(this.props.requisition.submitted_at).startOf(
      'month'
    );
    let endDate = moment(startDate).endOf('month');
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
    browserHistory.push(`/insights/budget-codes/${budgetCode.id}`);
  };

  renderLink = (budgetCode) => {
    return (
      <ProductBudgetCodeLink
        key={budgetCode.id}
        budgetCode={budgetCode}
        handleRedirect={this.redirectToBudgetCode}
      />
    );
  };

  render() {
    return (
      <Menu text vertical className="">
        {this.props.displayCodes.map(this.renderLink)}
      </Menu>
    );
  }
}

ProductRequisitionBudgetCodeLinks.propTypes = {
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        customer_budget_code: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }),
      })
    ),
  }).isRequired,
};

function mapStateToProps(state) {
  let displayCodes = (
    state.requisitions.open.product_requisitions || []
  ).reduce((acc, pr) => {
    let budgetCode = pr.customer_budget_code;
    let itemCount = 1;
    if (!acc[budgetCode.id]) {
      acc[budgetCode.id] = {
        id: budgetCode.id,
        name: budgetCode.name,
        itemCount,
      };
      return acc;
    }
    acc[budgetCode.id].itemCount += 1;
    return acc;
  }, {});
  return {
    requisition: state.requisitions.open,
    displayCodes: Object.values(displayCodes),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openDataViewRange,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.requisitions.open === next.requisitions.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductRequisitionBudgetCodeLinks);
