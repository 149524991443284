import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';

export const ERROR_TIMEOUT = 5000;

// Action Types
export const GET_PRODUCT_STOCK_NOTIFICATIONS =
  'GET_PRODUCT_STOCK_NOTIFICATIONS';
export const SUCCESS_GET_PRODUCT_STOCK_NOTIFICATIONS =
  'SUCCESS_GET_PRODUCT_STOCK_NOTIFICATIONS';
export const ERROR_GET_PRODUCT_STOCK_NOTIFICATIONS =
  'ERROR_GET_PRODUCT_STOCK_NOTIFICATIONS';
export const CREATE_PRODUCT_STOCK_NOTIFICATION =
  'CREATE_PRODUCT_STOCK_NOTIFICATION';
export const SUCCESS_CREATE_PRODUCT_STOCK_NOTIFICATION =
  'SUCCESS_CREATE_PRODUCT_STOCK_NOTIFICATION';
export const ERROR_CREATE_PRODUCT_STOCK_NOTIFICATION =
  'ERROR_CREATE_PRODUCT_STOCK_NOTIFICATION';
export const UNSET_PRODUCT_STOCK_NOTIFICATION_RESPONSE =
  'UNSET_PRODUCT_STOCK_NOTIFICATION_RESPONSE';

// Action creators
export const getProductStockNotifications = (data) => ({
  type: GET_PRODUCT_STOCK_NOTIFICATIONS,
  data,
});
export const successGetProductStockNotifications = (data) => ({
  type: SUCCESS_GET_PRODUCT_STOCK_NOTIFICATIONS,
  data,
});
export const errorGetProductStockNotifications = (data) => ({
  type: ERROR_GET_PRODUCT_STOCK_NOTIFICATIONS,
  data,
});
export const createProductStockNotification = (data) => ({
  type: CREATE_PRODUCT_STOCK_NOTIFICATION,
  data,
});
export const successCreateProductStockNotification = (data) => ({
  type: SUCCESS_CREATE_PRODUCT_STOCK_NOTIFICATION,
  data,
});
export const errorCreateProductStockNotification = (data) => ({
  type: ERROR_CREATE_PRODUCT_STOCK_NOTIFICATION,
  data,
});
export const unsetProductStockNotificationResponse = (data) => ({
  data,
  type: UNSET_PRODUCT_STOCK_NOTIFICATION_RESPONSE,
});

// Reducer
export const empty = {
  items: {},
  requesting: [],
  responses: [],
};

export const initial = { ...empty };

export default (state = initial, action) => {
  switch (action.type) {
    case GET_PRODUCT_STOCK_NOTIFICATIONS:
    case CREATE_PRODUCT_STOCK_NOTIFICATION:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case ERROR_GET_PRODUCT_STOCK_NOTIFICATIONS:
    case ERROR_CREATE_PRODUCT_STOCK_NOTIFICATION:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case SUCCESS_GET_PRODUCT_STOCK_NOTIFICATIONS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.data.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {}),
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case SUCCESS_CREATE_PRODUCT_STOCK_NOTIFICATION:
      return {
        ...state,
        items: {
          ...state.items,
          [action.data.item.id]: action.data.item,
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case UNSET_PRODUCT_STOCK_NOTIFICATION_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    default:
      return state;
  }
};

// Thunks

export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetProductStockNotificationResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetProductStockNotifications = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getProductStockNotifications(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.employee_id}/product_stock_notifications`,
      {
        method: 'GET',
        headers: {
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get product stock notifications',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetProductStockNotifications)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetProductStockNotifications)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doCreateProductStockNotification = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(createProductStockNotification(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.employee_id}/product_stock_notifications`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create Product Stock Notification',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successCreateProductStockNotification)({
            item: body,
            _request: data._request,
          })
        );
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message:
              'Success! You will be notified when this product comes back in stock.',
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateProductStockNotification)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
