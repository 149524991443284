import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../../lib/history.js';
import { Button } from 'semantic-ui-react';
import CartSubtotals from './cart-subtotals.js';
import {
  formatCentAmountToDollar,
  formatDateTime,
  formatStaffName,
  formatEmployeeNameEmail,
} from '../../lib/formatters.js';
import { CartPropType } from '../../helpers/cart-helpers.js';

export class CartFooter extends React.PureComponent {
  reduceItemsPrice(acc, val) {
    return (
      acc +
      val.quantity *
        (val.price === 0 ? val.price : val.price || val.product.price)
    );
  }

  cartPrice = () => {
    return (
      this.props.cart.product_requisitions ||
      this.props.cart.scheduled_product_requisitions ||
      []
    ).reduce(this.reduceItemsPrice, 0);
  };

  isAwaitingApproval = () => {
    return !!this.props.cart.awaiting_approval_at;
  };

  isCartEmpty = () => {
    return this.cartPrice() === 0;
  };

  doesMeetFreeShippingMinimum = () => {
    return this.cartPrice() > this.props.orderMinimum;
  };

  isFreeShipping = () => {
    return this.props.freeShipping;
  };

  displaySubmitForApproval = () => {
    return this.props.requiresApproval && !this.isAwaitingApproval();
  };

  displaySubmitOrder = () => {
    return !this.props.requiresApproval;
  };

  displayAwaitingApproval = () => {
    return this.props.requiresApproval && this.isAwaitingApproval();
  };

  displayRequesterData = () => {
    if (
      this.props.cart.requester_id === this.props.actor.id &&
      this.props.actor.type ===
        (this.props.cart.requester_type || '').toLowerCase()
    ) {
      return 'You';
    } else if ((this.props.cart.requester_type || '').match(/staff/i)) {
      return formatStaffName(this.props.cart.requester);
    } else if ((this.props.cart.requester_type || '').match(/employee/i)) {
      return formatEmployeeNameEmail(this.props.cart.requester);
    }
  };

  displayProposerData = () => {
    if (
      this.props.cart.proposer_id === this.props.actor.id &&
      this.props.actor.type ===
        (this.props.cart.proposer_type || '').toLowerCase()
    ) {
      return 'You';
    } else if ((this.props.cart.proposer_type || '').match(/staff/i)) {
      return formatStaffName(this.props.cart.proposer);
    } else if ((this.props.cart.proposer_type || '').match(/employee/i)) {
      return formatEmployeeNameEmail(this.props.cart.proposer);
    }
  };

  displayApprovalData = () => {
    let proposerName = this.displayProposerData();
    let approvalSubmissionDate = formatDateTime(
      this.props.cart.awaiting_approval_at
    );
    if (approvalSubmissionDate.match(/today/i)) {
      return `${proposerName} submitted this order for approval ${approvalSubmissionDate.toLowerCase()}.`;
    }
    return `${proposerName} submitted this order for approval on ${approvalSubmissionDate}.`;
  };

  displayPublicRequesterData = () => {
    let requesterName = this.displayRequesterData();
    return `This cart must be submitted by the person who created it (${requesterName}).`;
  };

  hasUnmetRequirements() {
    const { cart } = this.props;
    if (!cart._optimized_product_requisitions) return false;
    return cart._optimized_product_requisitions.unmet_minimums.length > 0;
  }

  isDisabled = () => {
    const { cart } = this.props;
    if (!cart._optimized_product_requisitions) return true;
    if (!cart.product_requisitions || !cart.product_requisitions.length)
      return true;

    let unmet_minimums = cart._optimized_product_requisitions.unmet_minimums;
    if (unmet_minimums.length > 0) return true;

    if (!this.props.requiresApproval) {
      return this.props.isDisabled;
    }
    return this.props.isDisabled;
  };

  _handleClickCheckout = () => {
    browserHistory.push('/supplies/checkout');
  };

  minimumErrorMsg() {
    return 'Meet your minimums above to continue';
  }

  shouldHideCheckout = () => {
    return (
      this.props.hideCheckout ||
      (!this.isAwaitingApproval() &&
        this.props.cart.is_public &&
        !this.isCartRequester())
    );
  };

  isCartRequester = () => {
    if (!this.props.cart.requester) return true;

    return (
      this.props.cart.requester_id === this.props.actor.id &&
      this.props.cart.requester_type === this.props.actor.type
    );
  };

  render() {
    const hasUnmetRequirements = this.hasUnmetRequirements();
    return (
      <div className="cart-footer">
        <CartSubtotals
          showTax={this.props.showTax}
          cart={this.props.cart}
          estimatingTax={this.props.estimatingTax}
          estimatingTaxText={this.props.estimatingTaxText}
        />
        <div>
          {this.isFreeShipping() && (
            <div className="shipping-line-item">
              <p className="shipping-subhead microcopy">
                Free shipping for all your orders!
              </p>
            </div>
          )}
          {!this.isFreeShipping() && !this.doesMeetFreeShippingMinimum() && (
            <div className="shipping-line-item">
              {/* prettier-ignore */}
              <p className='shipping-subhead microcopy'>
                                Free shipping for orders over {formatCentAmountToDollar(this.props.orderMinimum)}
                            </p>
            </div>
          )}
          {!this.isFreeShipping() &&
            this.doesMeetFreeShippingMinimum() &&
            !this.isCartEmpty() &&
            !hasUnmetRequirements && (
              <div className="shipping-line-item">
                <p className="shipping-subhead microcopy">
                  Your order qualifies for free shipping!
                </p>
              </div>
            )}
          {hasUnmetRequirements && (
            <div className="shipping-line-item">
              <p className="shipping-subhead microcopy error">
                {this.minimumErrorMsg()}
              </p>
            </div>
          )}
          {this.isAwaitingApproval() && (
            <p style={{ marginTop: '1rem' }}>{this.displayApprovalData()}</p>
          )}
          {this.props.cart.is_public &&
            !this.isCartRequester() &&
            !this.isAwaitingApproval() && (
              <p style={{ marginTop: '1rem' }}>
                {this.displayPublicRequesterData()}
              </p>
            )}
        </div>
        {!this.shouldHideCheckout() && (
          <div className="cart-checkout-button-wrap">
            <Button
              primary
              size="large"
              className="cart-checkout-button button-with-icon"
              disabled={this.isDisabled()}
              onClick={this._handleClickCheckout}>
              {this.isCartEmpty() && (
                <span className="checkout-button-text">Add Items to Cart</span>
              )}
              {this.displaySubmitOrder() && !this.isCartEmpty() && (
                <span className="checkout-button-text">Checkout</span>
              )}
              {this.displaySubmitForApproval() && !this.isCartEmpty() && (
                <span className="checkout-button-text">
                  Submit for Approval
                </span>
              )}
              {this.displayAwaitingApproval() && !this.isCartEmpty() && (
                <span className="checkout-button-text">
                  Review Pending Order
                </span>
              )}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

CartFooter.propTypes = {
  isDisabled: PropTypes.bool,
  hideCheckout: PropTypes.bool,
  requiresApproval: PropTypes.bool,
  estimatingTax: PropTypes.bool,
  estimatingTaxText: PropTypes.string.isRequired,
  hasUnmetRequirements: PropTypes.bool,
  freeShipping: PropTypes.bool,
  orderMinimum: PropTypes.number.isRequired,
  cart: CartPropType.isRequired,
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

CartFooter.defaultProps = {
  hasUnmetRequirements: false,
  estimatingTaxText: 'estimating...',
};

export function mapStateToProps(state, ownProps) {
  return {
    actor: state.auth.actor,
    freeShipping: state.application.appConfig.freeShipping,
    orderMinimum: state.application.appConfig.orderMinimum,
    requiresApproval:
      state.auth.role.approval_required_after_employee_spend !== null &&
      ownProps.cart.total_price >=
        state.auth.role.approval_required_after_employee_spend,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth.role === next.auth.role &&
    prev.auth.actor === next.auth.actor &&
    prev.application.appConfig === next.application.appConfig
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CartFooter
);
