import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cart from './cart.js';
import CheckoutForm from './checkout-form.js';
import { filterUnapplied } from '../../helpers/credit-helpers.js';
import { FILTER_IS_LOADING, FILTER_IS_SUBMITTING } from '../../ducks/carts.js';
import './checkout-page.css';

export class CheckoutPage extends React.PureComponent {
  render() {
    return (
      <div className="checkout-page">
        <div className="variable-width-column flex-wrapper centered">
          <Cart
            estimatingTax={this.props.isRequesting}
            showTax={true}
            showTotal={true}
            hideCheckout={true}
            showReplacementOptions={true}
          />
          <CheckoutForm key={this.props.cart.id} />
        </div>
      </div>
    );
  }
}

CheckoutPage.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  credits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  return {
    cart: state.carts.open,
    credits: filterUnapplied(state.credits.items),
    isRequesting:
      state.carts.requesting
        .filter(FILTER_IS_LOADING)
        .filter(FILTER_IS_SUBMITTING).length > 0,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.requesting === next.carts.requesting &&
    prev.credits.items === next.credits.items
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CheckoutPage
);
