import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Header,
  Message,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { submitUpdateLocation } from '../../actions/locations-actions.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './settings-location-preferences.css';

export class SettingsLocationPreferences extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pref_fulfillment_delivery_time:
        props.location.pref_fulfillment_delivery_time,
      pref_fulfillment_contact: props.location.pref_fulfillment_contact,
      pref_fulfillment_delivery_access:
        props.location.pref_fulfillment_delivery_access,
      pref_fulfillment_delivery_location:
        props.location.pref_fulfillment_delivery_location,
      pref_fulfillment_delivery_grouping:
        props.location.pref_fulfillment_delivery_grouping,
      submitted: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.hasRequired()) {
      return this.setState({ submitted: true });
    }
    this.props.actions.submitUpdateLocation({
      id: this.props.location.id,
      pref_fulfillment_delivery_time: this.state.pref_fulfillment_delivery_time,
      pref_fulfillment_contact: this.state.pref_fulfillment_contact,
      pref_fulfillment_delivery_access:
        this.state.pref_fulfillment_delivery_access,
      pref_fulfillment_delivery_location:
        this.state.pref_fulfillment_delivery_location,
      pref_fulfillment_delivery_grouping:
        this.state.pref_fulfillment_delivery_grouping,
    });
  };

  handleChangeText = (e, { value, name }) => {
    let update = {};
    update[name] = value;
    this.setState(() => {
      return update;
    });
  };

  handleChangeOption = (e, { name }) => {
    let key = name.split('|')[0];
    let text = decodeURIComponent(name.split('|')[1]);
    let update = {};
    update[key] = text;
    this.setState(() => {
      return update;
    });
  };

  renderPrefDeliveryGroup = () => {
    return (
      <Form.Field>
        {this.renderOption({
          label: 'Attempt all deliveries on the same day.',
          value: 'Attempt all deliveries on the same day',
          binary: true,
          name: 'pref_fulfillment_delivery_grouping',
        })}
        {this.renderOption({
          label:
            'Deliver everything ASAP on a rolling basis throughout the week.',
          value:
            'Deliver everything ASAP on a rolling basis throughout the week',
          binary: true,
          name: 'pref_fulfillment_delivery_grouping',
        })}
      </Form.Field>
    );
  };

  renderOption = ({ name, label, value, binary }) => {
    let statePref = this.state[name];
    let checked = statePref && !!statePref.match(value);
    return (
      <Form.Field
        control={Checkbox}
        name={`${name}|${encodeURIComponent(value)}`}
        value={name}
        onChange={this.handleChangeOption}
        data={{ binary: binary }}
        checked={checked}
        error={this.state.submitted && !statePref}
        label={{ children: label }}
      />
    );
  };

  renderPrefDeliveryContact = () => {
    let value = this.state.pref_fulfillment_contact || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_contact"
        name="pref_fulfillment_contact"
        placeholder="Please provide an on-site contact's name and number."
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'On-Site Delivery Contact' }}
      />
    );
  };

  renderPrefDeliveryTime = () => {
    let value = this.state.pref_fulfillment_delivery_time || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_time"
        name="pref_fulfillment_delivery_time"
        placeholder="What days and times are preferred for your deliveries? Are there any days or times that you do not want to receive deliveries?"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Delivery Timing' }}
      />
    );
  };

  renderPrefDeliveryAccess = () => {
    let value = this.state.pref_fulfillment_delivery_access || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_access"
        name="pref_fulfillment_delivery_access"
        placeholder="How can the delivery drivers gain access to your office?"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Facility Access' }}
      />
    );
  };

  renderPrefDeliveryLocation = () => {
    let value = this.state.pref_fulfillment_delivery_location || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_location"
        name="pref_fulfillment_delivery_location"
        placeholder="Where should delivery vehicles go when arriving at your building? (Street parking with flashers on, loading dock, etc)"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Delivery Location' }}
      />
    );
  };

  hasError = () => {
    return this.state.submitted && !this.hasRequired();
  };

  hasChanges = () => {
    return [
      'pref_fulfillment_delivery_location',
      'pref_fulfillment_delivery_time',
      'pref_fulfillment_contact',
      'pref_fulfillment_delivery_access',
      'pref_fulfillment_delivery_grouping',
    ].reduce((acc, val) => {
      return acc || this.state[val] !== this.props.location[val];
    }, false);
  };

  hasRequired = () => {
    return [
      'pref_fulfillment_delivery_location',
      'pref_fulfillment_delivery_time',
      'pref_fulfillment_contact',
      'pref_fulfillment_delivery_access',
      'pref_fulfillment_delivery_grouping',
    ].reduce((acc, val) => {
      return acc && this.state[val] && this.state[val].trim();
    });
  };

  render() {
    return (
      <div className="card">
        <Header id="preferences" as="h2" attached="top">
          Delivery Preferences
          <Header.Subheader>
            Help our Fulfillment team make the ordering process as smooth as
            possible for your office.
          </Header.Subheader>
        </Header>
        <Segment attached="bottom">
          <Form
            loading={this.props.isRequesting}
            error={this.hasError()}
            onSubmit={this.handleSubmit}>
            {this.renderPrefDeliveryLocation()}
            {this.renderPrefDeliveryAccess()}
            {this.renderPrefDeliveryContact()}
            {this.renderPrefDeliveryTime()}
            {this.renderPrefDeliveryGroup()}
            <Divider hidden />
            <Message
              error
              header="Required Fields"
              content="Please fill out all fields for our Fulfillment team."
            />
            <Button
              fluid
              primary
              disabled={!this.hasChanges() || !this.hasRequired()}>
              Save Preferences
            </Button>
          </Form>
        </Segment>
      </div>
    );
  }
}

SettingsLocationPreferences.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pref_fulfillment_delivery_location: PropTypes.string,
    pref_fulfillment_delivery_access: PropTypes.string,
    pref_fulfillment_contact: PropTypes.string,
    pref_fulfillment_delivery_time: PropTypes.string,
    pref_fulfillment_delivery_grouping: PropTypes.string,
  }).isRequired,
  isRequesting: PropTypes.bool,
  actions: PropTypes.shape({
    submitUpdateLocation: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    isRequesting: state.locations.requesting.length > 0,
    key: state.locations.open.updated_at,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.locations.open === next.locations.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SettingsLocationPreferences);
