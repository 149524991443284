import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import classnames from 'classnames';
import { formatCentAmount } from '../../lib/formatters.js';
import {
  buildTotalsLineItems,
  totalsLineItemsPropTypes,
} from '../../helpers/requisition-helpers.js';
import './item-details-totals.css';

export class ItemDetailsTotals extends React.PureComponent {
  subtotalLineItems() {
    return this.props.lineItems.filter((item) => item.amount !== 0);
  }

  renderRow = (item, total = false) => (
    <Table.Row key={item.label}>
      <Table.Cell
        textAlign="right"
        className={classnames('label', { total: total, 'no-border': !total })}>
        {item.label}:
      </Table.Cell>
      <Table.Cell className={classnames('spacer', { 'no-border': !total })}>
        &nbsp;
      </Table.Cell>
      <Table.Cell
        textAlign="right"
        className={classnames('dollar-value', {
          total: total,
          'no-border': !total,
        })}>
        {formatCentAmount(item.amount)}
      </Table.Cell>
    </Table.Row>
  );

  render() {
    return (
      <Table unstackable collapsing compact="very" className="totals-table">
        <Table.Body>
          {this.subtotalLineItems().map((item) =>
            this.renderRow(item, item.label === 'Total')
          )}
        </Table.Body>
      </Table>
    );
  }
}

ItemDetailsTotals.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    total_price: PropTypes.number.isRequired,
    shipping_price: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    payment_convenience_price: PropTypes.number.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        customer_budget_code: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
  lineItems: totalsLineItemsPropTypes.isRequired,
};

export function mapStateToProps(state, ownProps) {
  const orderMinimum = state.application.appConfig.orderMinimum;
  const shippingPrice = state.application.appConfig.shippingPrice;
  const alcoholConvenienceFee =
    state.application.appConfig.alcoholConvenienceFee;
  const isStipendUser =
    typeof state.employees.user.stipend_remaining === 'number';
  return {
    orderMinimum,
    shippingPrice,
    alcoholConvenienceFee,
    lineItems: buildTotalsLineItems(ownProps.item, {
      orderMinimum,
      shippingPrice,
      alcoholConvenienceFee,
      isStipendUser,
    }),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.application.appConfig === next.application.appConfig &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  ItemDetailsTotals
);
