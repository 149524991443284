import MD5 from 'crypto-js/md5';

export function fetchError(options) {
  let err = new Error(`Failed to ${options.message}`);
  err.response = options.response;
  err.status = options.response.status;
  err.data = options.data;
  return err;
}

export function requestHash(data) {
  MD5(JSON.stringify(data)).toString();
}

export function requestID() {
  if (window.crypto) return uuidv4Crypto();
  return uuidv4Basic();
}

function uuidv4Basic() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-mixed-operators
    return v.toString(16);
  });
}

function uuidv4Crypto() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16) // eslint-disable-line no-mixed-operators
  );
}
