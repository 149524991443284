import React from 'react';
import AuthCheck from '../components/auth-check.js';
import EVIUploadAttachment from '../components/externalVendorInvoices/upload-attachment.js';
import EVIUploadCTA from '../components/insights/external-vendor-invoice-upload.js';
import NavBar from '../components/nav/navbar-attendant.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import './external-vendor-invoice-new.css';

export class ExternalVendorInvoiceNew extends React.Component {
  render() {
    return (
      <div>
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <MountAtPageTop />
        <NavBar />
        <div className="fixed-width-column external-vendor-invoice-new">
          <EVIUploadCTA />
          <EVIUploadAttachment />
        </div>
      </div>
    );
  }
}

ExternalVendorInvoiceNew = setTitle((props) => {
  return 'Upload Vendor Invoice';
})(ExternalVendorInvoiceNew);

export default ExternalVendorInvoiceNew;
