import cookies from '../lib/encrypted-cookies';
import * as types from '../actions/action-types';
import { LOCATION_COOKIE } from '../strings';
import { locationVendorColorMap } from '../lib/formatters.js';

const initialCookie = Object.assign({}, cookies.getJSON(LOCATION_COOKIE));
const initialQuery = window.location.search.match(/location(_id)?=([0-9]+)/);

export const empty = {
  items: {},
  requesting: [],
  responses: [],
  open: { id: 0, vendors: [], vendorColorMap: {} },
};
export const initial = Object.assign({}, empty, {
  open: {
    id:
      (initialQuery && parseInt(initialQuery[2], 10)) ||
      initialCookie.id ||
      empty.open.id,
    vendors: empty.open.vendors,
    vendorColorMap: empty.open.vendorColorMap,
  },
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.UNSET_LOCATION_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case types.OPEN_LOCATION:
      return {
        ...state,
        open: action.data,
      };
    case types.SUCCESS_LOCATIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (t) => t._request !== action.data._request
        ),
        items: action.data.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      });
    case types.SWITCH_LOCATION:
      return Object.assign({}, state, {
        open: {
          ...action.data,
          vendorColorMap: locationVendorColorMap(action.data.vendors || []),
        },
      });
    case types.UPDATE_LOCATION:
    case types.GET_LOCATION:
    case types.CREATE_LOCATION:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_UPDATE_LOCATION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (t) => t._request !== action.data._request
        ),
        responses: state.responses.concat(action),
        items: {
          ...state.items,
          [action.data.id]: { ...state.items[action.data.id], ...action.data },
        },
        open:
          action.data.id === state.open.id
            ? {
                ...state.open,
                ...action.data,
                vendorColorMap: locationVendorColorMap(
                  action.data.vendors || state.open.vendors || []
                ),
              }
            : state.open,
      });
    case types.SUCCESS_CREATE_LOCATION:
    case types.ERROR_UPDATE_LOCATION:
    case types.ERROR_CREATE_LOCATION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (t) => t._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      });
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
