export const processCSV = (input, setCSVResult) => {
  let reader = new FileReader();

  if (!input.files[0]) return;

  reader.onload = (load) => {
    const emails = parseCSVEmails(load.target.result);
    let errorCSV = undefined;

    if (!emails.length) {
      errorCSV =
        'No emails found in the CSV. Be sure to upload your CSV file ' +
        'with the word email at the top of your column list.';
    }

    input.value = '';

    setCSVResult({
      emails: emails,
      emailString: emails.join(', '),
      errorCSV: errorCSV,
    });
  };

  reader.onerror = () => {
    input.value = '';

    setCSVResult({
      errorCSV: 'Unable to read that file. Please select a CSV.',
    });
  };

  reader.readAsText(input.files[0]);
};

const parseCSVEmails = (csv) => {
  const lines = csv.split(/\r\n|\n/).map((line) => {
    return line.split(',').map((col) => {
      return col;
    });
  });
  if (!lines.length) return [];

  const email_index = lines[0]
    .map((col, index) => {
      return col.match(/email|e-mail/i) ? index : -1;
    })
    .filter((index) => index > -1)[0];

  if (email_index === undefined) return [];

  return lines
    .slice(1)
    .map((line) => line[email_index])
    .filter((email) => email && email.trim().length && email.match(/.+@.+/))
    .map((email) => email.trim());
};
