import * as types from '../actions/action-types';

const initialQuery = window.location.search.match(/[&|?]+invoice=([0-9]+)/);
export const empty = {
  items: [],
  accounts: [],
  open: { id: 0 },
  isRequesting: false,
  isRequestingAccounts: false,
  error: false,
};
export const initial = Object.assign({}, empty, {
  open: { id: initialQuery ? parseInt(initialQuery[1], 10) : empty.open.id },
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_INVOICES:
      return Object.assign({}, state, {
        isRequesting: true,
        error: empty.error,
      });
    case types.ERROR_GET_INVOICES:
      return Object.assign({}, state, {
        isRequesting: false,
        error: action.data,
      });
    case types.SUCCESS_GET_INVOICES:
      return Object.assign({}, state, {
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        isRequesting: false,
        error: empty.error,
        items: action.data,
      });
    case types.OPEN_INVOICE:
      return Object.assign({}, state, {
        error: empty.error,
        open: Object.assign(
          {},
          state.items.filter((t) => t.id === action.data.id)[0] || empty.open
        ),
      });
    case types.CLOSE_INVOICE:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.GET_BILLING_ADDRESSES:
      return Object.assign({}, state, {
        isRequestingAccounts: true,
        error: empty.error,
      });
    case types.SUCCESS_GET_BILLING_ADDRESSES:
      return Object.assign({}, state, {
        isRequestingAccounts: false,
        error: empty.error,
        accounts: action.data,
      });
    case types.ERROR_GET_BILLING_ADDRESSES:
      return Object.assign({}, state, {
        isRequestingAccounts: false,
        error: action.data,
      });
    case types.SUCCESS_UPDATE_BILLING_ADDRESS:
      return Object.assign({}, state, {
        isRequestingAccounts: false,
        accounts: state.accounts.map((a) => {
          return (
            action.data.filter((aa) => {
              if (aa.Customer && a.Customer)
                return aa.Customer.Id === a.Customer.Id;
              return aa.account_code === a.account_code;
            })[0] || a
          );
        }),
      });
    case types.DO_GET_INVOICE:
      return {
        ...state,
        isRequesting: true,
      };
    case types.SUCCESS_DO_GET_INVOICE:
      return {
        ...state,
        isRequesting: false,
        error: false,
      };
    case types.ERROR_DO_GET_INVOICE:
      return {
        ...state,
        isRequesting: false,
        error: action.data,
      };
    case types.SWITCH_LOCATION:
      return {
        ...state,
        open: { id: initial.open.id },
      };
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
