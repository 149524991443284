import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message, TextArea } from 'semantic-ui-react';
import StepMarker from './step-marker.js';
import { submitUpdateLocation } from '../../actions/locations-actions.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';

export class OnboardingDeliveryInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pref_fulfillment_delivery_time:
        props.location.pref_fulfillment_delivery_time,
      pref_fulfillment_contact: props.location.pref_fulfillment_contact,
      pref_fulfillment_delivery_access:
        props.location.pref_fulfillment_delivery_access,
      pref_fulfillment_delivery_location:
        props.location.pref_fulfillment_delivery_location,
      submitted: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.hasRequired()) {
      return this.setState({ submitted: true });
    }
    this.props.actions.submitUpdateLocation({
      id: this.props.location.id,
      pref_fulfillment_delivery_time: this.state.pref_fulfillment_delivery_time,
      pref_fulfillment_contact: this.state.pref_fulfillment_contact,
      pref_fulfillment_delivery_access:
        this.state.pref_fulfillment_delivery_access,
      pref_fulfillment_delivery_location:
        this.state.pref_fulfillment_delivery_location,
    });
    this.props.onSubmit();
  };

  handleChangeText = (e, { value, name }) => {
    let update = {};
    update[name] = value;
    this.setState(() => {
      return update;
    });
  };

  hasErrors = () => {
    return this.state.submitted && !this.hasRequired();
  };

  hasRequired = () => {
    return [
      'pref_fulfillment_delivery_location',
      'pref_fulfillment_delivery_time',
      'pref_fulfillment_contact',
      'pref_fulfillment_delivery_access',
    ].reduce((acc, val) => {
      return acc && this.state[val] && this.state[val].trim();
    });
  };

  renderPrefDeliveryContact = () => {
    let value = this.state.pref_fulfillment_contact || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_contact"
        name="pref_fulfillment_contact"
        placeholder="Please provide an on-site contact's name and number."
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'On-Site Delivery Contact' }}
      />
    );
  };

  renderPrefDeliveryTime = () => {
    let value = this.state.pref_fulfillment_delivery_time || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_time"
        name="pref_fulfillment_delivery_time"
        placeholder="What days and times are preferred for your deliveries? Are there any days or times that you do not want to receive deliveries?"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Delivery Timing' }}
      />
    );
  };

  renderPrefDeliveryAccess = () => {
    let value = this.state.pref_fulfillment_delivery_access || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_access"
        name="pref_fulfillment_delivery_access"
        placeholder="How can the delivery drivers gain access to your office?"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Facility Access' }}
      />
    );
  };

  renderPrefDeliveryLocation = () => {
    let value = this.state.pref_fulfillment_delivery_location || '';
    return (
      <Form.Field
        control={TextArea}
        key="pref_fulfillment_delivery_location"
        name="pref_fulfillment_delivery_location"
        placeholder="Where should delivery vehicles go when arriving at your building? (Street parking with flashers on, loading dock, etc)"
        onChange={this.handleChangeText}
        value={value}
        error={this.state.submitted && !value.trim()}
        label={{ children: 'Delivery Location' }}
      />
    );
  };

  render() {
    return (
      <div className="onboarding-company-form">
        <h2
          style={{
            fontWeight: '700',
            marginBottom: '2rem',
          }}
        >
          Delivery Information
        </h2>
        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}
        >
          These details help our Fulfillment Team make sure deliveries are as
          smooth as possible for your office.
        </p>
        <Form
          loading={this.props.isRequesting}
          onSubmit={this.handleSubmit}
          error={this.hasErrors()}
        >
          {this.renderPrefDeliveryLocation()}
          {this.renderPrefDeliveryAccess()}
          {this.renderPrefDeliveryContact()}
          {this.renderPrefDeliveryTime()}
          <Message
            error
            header="Required Fields"
            content="Please fill out all fields for our Fulfillment team."
          />
          <Form.Group
            style={{
              marginTop: '2rem',
            }}
          >
            <Form.Button
              width={8}
              fluid
              primary
              style={{
                backgroundColor: this.props.location.brand_color || '#00a98c',
              }}
            >
              {this.props.submitButtonText}
            </Form.Button>
            <Form.Field width={8}>
              <StepMarker
                stepNumber={this.props.stepNumber}
                stepTotal={this.props.stepTotal}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

OnboardingDeliveryInfoForm.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    submitUpdateLocation: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pref_fulfillment_delivery_location: PropTypes.string,
    pref_fulfillment_delivery_access: PropTypes.string,
    pref_fulfillment_contact: PropTypes.string,
    pref_fulfillment_delivery_time: PropTypes.string,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

OnboardingDeliveryInfoForm.defaultProps = {
  stepTotal: 5,
  stepNumber: 4,
  onSubmit: function () {},
  submitButtonText: 'Next',
};

function mapStateToProps(state) {
  return {
    isRequesting: state.locations.requesting.length > 0,
    location: state.locations.open,
    key: state.locations.open.updated_at,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.locations.requesting === next.locations.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingDeliveryInfoForm);
