import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, Button, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Cart from '../requisitions/cart.js';
import ExpirationStatus from './expiration-status.js';
import UnmetMinimumWarning from './unmet-minimum-warning.js';
import Loader from '../loader.js';
import ShippableForm from '../modules/shippable-form.js';
import { formatDate, formatDateTime } from '../../lib/formatters.js';
import { submitUpdateScheduledRequisition } from '../../actions/scheduled-requisition-actions.js';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import * as queryParser from '../../lib/query-parser.js';
import '../requisitions/checkout-page.css';
import './unified-scheduled-requisition-form.css';

export class UnifiedScheduledRequisitionApprovalForm extends React.PureComponent {
  submitApproval = () => {
    this.props.actions.submitUpdateScheduledRequisition({
      id: this.props.scheduledRequisition.id,
      approved_at: new Date(),
      approver_id: this.props.user.id,
      approver_type: this.props.approverType,
    });
  };

  isApprovingUser() {
    const sr = this.props.scheduledRequisition;
    const { approverType, user } = this.props;
    return (
      helpers.isApprovingUser(sr, user) && approverType === sr.approver_type
    );
  }

  renderEditButton() {
    const sr = this.props.scheduledRequisition;
    if (helpers.isApproved(sr) && !this.isApprovingUser()) {
      return null;
    }

    return (
      <Button secondary>
        <Link to={`/orders/scheduled/${sr.id}/edit?back_to_approval=true`}>
          Edit Order
        </Link>
      </Button>
    );
  }

  renderButtons() {
    const sr = this.props.scheduledRequisition;
    if (helpers.isExpired(sr)) return null;

    return (
      <div>
        {!sr.approve_automatically && !helpers.isApproved(sr) && (
          <Button
            primary
            loading={this.props.isUpdating}
            onClick={this.submitApproval}>
            Confirm Order
          </Button>
        )}
        {this.renderEditButton()}
      </div>
    );
  }

  renderEditableMessage() {
    const sr = this.props.scheduledRequisition;
    if (helpers.isExpired(sr)) return null;

    if (helpers.isApproved(sr) && this.isApprovingUser()) {
      return (
        <Message>
          This order is editable until {formatDateTime(sr.expiration_at)}
        </Message>
      );
    }
    return null;
  }

  render() {
    if (this.props.isRequesting) return <Loader />;
    if (!this.props.scheduledRequisition) return null;

    return (
      <div className="unified-order checkout-page">
        <div
          className="variable-width-column"
          style={{ maxWidth: '1035px', margin: 'auto', marginBottom: '2rem' }}>
          <Header as="h2">
            {formatDate(this.props.scheduledRequisition.expected_at)} Order for{' '}
            {this.props.scheduledRequisition.name}
            {this.props.queryParams.from_calendar === 'true' && (
              <Link
                className="primary"
                to="/calendar"
                style={{ float: 'right' }}>
                &laquo; Back to Calendar
              </Link>
            )}
          </Header>
        </div>
        <div className="variable-width-column flex-wrapper centered">
          <div>
            <Cart
              isScheduled={true}
              parentComponent="edit-scheduled-requisition"
              showTax={true}
              showTotal={true}
              readOnly={true}
            />
          </div>
          <div className="col-right width-contain">
            <div className="left-pad">
              {helpers.isApproved(this.props.scheduledRequisition) && (
                <Message success>
                  <Message.Header>Order Confirmed!</Message.Header>
                  <p>
                    Your order will arrive by{' '}
                    {formatDate(this.props.scheduledRequisition.expected_at)}.
                  </p>
                </Message>
              )}
              <ShippableForm
                canSubmit={false}
                isDisabled={false}
                shippable={this.props.scheduledRequisition}
              />
              <div style={{ marginTop: '3rem' }}>
                {!helpers.isCancelled(this.props.scheduledRequisition) &&
                  this.renderButtons()}
                {this.props.error && (
                  <Message
                    error
                    header="Submit Failed"
                    content="Please try again or contact support"
                  />
                )}
                <ExpirationStatus
                  scheduledRequisition={this.props.scheduledRequisition}
                />
                {this.renderEditableMessage()}
                <UnmetMinimumWarning
                  scheduledRequisition={this.props.scheduledRequisition}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnifiedScheduledRequisitionApprovalForm.propTypes = {
  scheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    approver_type: PropTypes.string,
    approver_id: PropTypes.number,
  }),
  approverType: PropTypes.oneOf(['Employee', 'Staff']),
  isRequesting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool,
  error: PropTypes.shape({
    status: PropTypes.number,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }),
  queryParams: PropTypes.shape({
    from_calendar: PropTypes.string,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  let user =
    state.employees.user.id > 0 ? state.employees.user : state.staffs.user;
  let approverType = state.employees.user.id > 0 ? 'Employee' : 'Staff';
  return {
    user,
    approverType,
    isUpdating:
      state.scheduledRequisitions.updating.filter(
        (sr) => sr.id === (props.scheduledRequisition || {}).id
      ).length > 0,
    error: state.scheduledRequisitions.errors.filter(
      (e) => e.data.id === (props.scheduledRequisition || {}).id
    )[0],
    queryParams: queryParser.parse(props.location.search),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateScheduledRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.staffs.user === next.staffs.user &&
    prev.scheduledRequisitions.updating ===
      next.scheduledRequisitions.updating &&
    prev.scheduledRequisitions.errors === next.scheduledRequisitions.errors
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(UnifiedScheduledRequisitionApprovalForm);
