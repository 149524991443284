import * as types from '../actions/action-types.js';
import { populateOptimizedProductRequisitions } from '../helpers/cart-helpers.js';

const initialQuery = window.location.pathname.match(
  /orders\/scheduled\/([0-9]+)/
);
const emptySchedReq = {
  id: 0,
  scheduled_product_requisitions: [],
};
export const empty = {
  initialized: false,
  items: [],
  open: populateOptimizedProductRequisitions(emptySchedReq),
  updating: [],
  creating: [],
  requesting: [],
  errors: [],
  staged: [populateOptimizedProductRequisitions(emptySchedReq)],
};
const initialOpenId =
  (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id;
export const initial = Object.assign({}, empty, {
  open: populateOptimizedProductRequisitions(
    empty.items.filter((t) => t.id === initialOpenId)[0] || {
      ...emptySchedReq,
      id: initialOpenId,
    }
  ),
  staged: [
    populateOptimizedProductRequisitions(
      empty.items.filter((t) => t.id === initialOpenId)[0] || {
        ...emptySchedReq,
        id: initialOpenId,
      }
    ),
  ],
});

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_SCHEDULED_REQUISITIONS:
      return Object.assign({}, state, {
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_SCHEDULED_REQUISITIONS,
            },
            action.data
          )
        ),
      });
    case types.SUCCESS_SCHEDULED_REQUISITIONS:
      items = action.data.map((r) => r.id);
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== types.GET_SCHEDULED_REQUISITIONS
        ),
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        items: state.items
          .filter(
            (r) =>
              items.indexOf(r.id) < 0 &&
              r.location_id === action.data.location_id
          )
          .concat(action.data),
      });
    case types.ERROR_SCHEDULED_REQUISITIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== types.GET_SCHEDULED_REQUISITIONS
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_GET_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? populateOptimizedProductRequisitions({
                ...state.open,
                ...action.data,
              })
            : state.open,
        items: state.items
          .filter((sreq) => sreq.id !== action.data.id)
          .concat(action.data),
      });
    case types.ERROR_GET_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_SCHEDULED_REQUISITION:
      return {
        ...state,
        creating: state.creating
          .filter((sreq) => sreq._request !== action.data._request)
          .concat(action.data),
      };
    case types.SUCCESS_CREATE_SCHEDULED_REQUISITION:
      return {
        ...state,
        items: state.items
          .filter((sreq) => sreq.id !== action.data.id)
          .concat(action.data),
        creating: state.creating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        open: action.data,
      };
    case types.ERROR_CREATE_SCHEDULED_REQUISITION:
      return {
        ...state,
        creating: state.creating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors
          .filter((sreq) => sreq._request !== action.data._request)
          .concat(action.data),
      };
    case types.UPDATE_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((sreq) => sreq._request !== action.data._request)
          .concat(action.data),
        errors: state.errors.filter((err) => err.data.id !== action.data.id),
      });
    case types.SUCCESS_UPDATE_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, action.data)
              )
            : state.open,
        items: state.items
          .filter((sreq) => sreq.id !== action.data.id)
          .concat(action.data),
        staged:
          state.staged.length && state.staged[0].id === action.data.id
            ? state.staged
                .filter((sreq) => sreq.id !== action.data.id)
                .concat(populateOptimizedProductRequisitions(action.data))
            : state.staged,
      });
    case types.UPDATE_STAGED_SCHEDULED_REQUISITION:
      return {
        ...state,
        staged: action.data,
      };
    case types.ERROR_UPDATE_SCHEDULED_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_SCHEDULED_PRODUCT_REQUISITIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_SCHEDULED_PRODUCT_REQUISITIONS,
            },
            action.data
          )
        ),
      });
    case types.SUCCESS_SCHEDULED_PRODUCT_REQUISITIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== types.GET_SCHEDULED_PRODUCT_REQUISITIONS
        ),
        items: state.items.map((sreq) => {
          if (sreq.id !== action.data.scheduled_requisition_id) return sreq;
          return Object.assign({}, sreq, {
            scheduled_product_requisitions: action.data.data,
          });
        }),
        open:
          state.open.id === action.data.scheduled_requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  scheduled_product_requisitions: action.data.data,
                })
              )
            : state.open,
      });
    case types.ERROR_SCHEDULED_PRODUCT_REQUISITIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (sreq) => sreq._request !== types.GET_SCHEDULED_PRODUCT_REQUISITIONS
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.concat(action.data),
      });
    case types.SUCCESS_CREATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        items: state.items.map((sreq) => {
          if (sreq.id !== action.data.scheduled_requisition_id) return sreq;
          return Object.assign({}, sreq, {
            scheduled_product_requisitions: (
              sreq.scheduled_product_requisitions || []
            )
              .filter((spr) => spr.id !== action.data.id)
              .concat(action.data),
          });
        }),
        open:
          state.open.id === action.data.scheduled_requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  scheduled_product_requisitions: (
                    state.open.scheduled_product_requisitions || []
                  )
                    .filter((spr) => spr.id !== action.data.id)
                    .concat(action.data),
                })
              )
            : state.open,
      });
    case types.ERROR_CREATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.UPDATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.concat(action.data),
      });
    case types.SUCCESS_UPDATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        items: state.items.map((sreq) => {
          if (sreq.id !== action.data.scheduled_requisition_id) return sreq;
          return Object.assign({}, sreq, {
            scheduled_product_requisitions: (
              sreq.scheduled_product_requisitions || []
            ).map((spr) => {
              if (spr.id !== action.data.id) return spr;
              return Object.assign({}, spr, action.data);
            }),
          });
        }),
        open:
          state.open.id === action.data.scheduled_requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  scheduled_product_requisitions:
                    state.open.scheduled_product_requisitions.map((spr) => {
                      if (spr.id !== action.data.id) return spr;
                      return Object.assign({}, spr, action.data);
                    }),
                })
              )
            : state.open,
      });
    case types.ERROR_UPDATE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.DELETE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.concat(action.data),
      });
    case types.SUCCESS_DELETE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        items: state.items.map((sreq) => {
          if (sreq.id !== action.data.scheduled_requisition_id) return sreq;
          return Object.assign({}, sreq, {
            scheduled_product_requisitions: (
              sreq.scheduled_product_requisitions || []
            ).filter((spr) => spr.id !== action.data.id),
          });
        }),
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        open:
          state.open.id === action.data.scheduled_requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  scheduled_product_requisitions: (
                    state.open.scheduled_product_requisitions || []
                  ).filter((spr) => spr.id !== action.data.id),
                })
              )
            : state.open,
      });
    case types.ERROR_DELETE_SCHEDULED_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (sreq) => sreq._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.OPEN_SCHEDULED_REQUISITION:
      return {
        ...state,
        open: populateOptimizedProductRequisitions({
          ...initial.open,
          ...action.data,
        }),
      };
    case types.CLOSE_SCHEDULED_REQUISITION:
      return {
        ...state,
        open: empty.open,
        staged: empty.staged,
      };
    case types.STAGE_SCHEDULED_REQUISITION:
      return {
        ...state,
        staged: [
          populateOptimizedProductRequisitions({
            ...emptySchedReq,
            ...action.data,
          }),
        ],
      };
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
