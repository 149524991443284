import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';
import {
  formatDate,
  formatDateTime,
  formatCentAmount,
} from '../../lib/formatters.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/table.min.css';
import './external-vendor-invoice-summary.css';

export class ExternalVendorInvoiceSummary extends React.PureComponent {
  isPlaceholder = () => {
    return (
      !this.props.isRequesting &&
      (this.props.invoice.external_vendor_invoice_line_items || []).length === 0
    );
  };

  renderLineItem = (lineItem, index) => {
    const budgetCode = this.props.customerBudgetCodes.filter(
      (bc) => bc.id === lineItem.customer_budget_code_id
    )[0];
    return (
      <Table.Row key={index}>
        <Table.Cell>{lineItem.name}</Table.Cell>
        <Table.Cell>{budgetCode && budgetCode.name}</Table.Cell>
        <Table.Cell textAlign="right">{lineItem.quantity}</Table.Cell>
        <Table.Cell textAlign="right">
          {formatCentAmount(lineItem.unit_price)}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderPlaceholder = () => {
    return (
      <Segment
        attached="top"
        className="external-vendor-invoice-summary"
        placeholder
      >
        <Header as="h4" icon>
          <Icon name="alternate file outline" />
          <Link
            to={`/insights/vendor-invoices/${this.props.invoice.id}`}
            className="tertiary"
          >
            Invoice uploaded {formatDateTime(this.props.invoice.created_at)}
          </Link>
          <Header.Subheader>
            The data is being processed and will be available shortly.
          </Header.Subheader>
        </Header>
      </Segment>
    );
  };

  render() {
    if (this.isPlaceholder()) {
      return this.renderPlaceholder();
    }
    return (
      <Segment
        attached="top"
        className="external-vendor-invoice-summary"
        loading={this.props.isRequesting}
      >
        <Table compact style={{ border: 'none' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell
                style={{ padding: '0.3em .7em', fontStyle: 'italic' }}
              >
                {formatDate(this.props.invoice.transaction_date)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell style={{ borderTop: 'none', padding: '0.3em .7em' }}>
                <h4>
                  <Link
                    to={`/insights/vendor-invoices/${this.props.invoice.id}`}
                    className="tertiary"
                  >
                    {this.props.invoice.vendor_name} order{' '}
                    {this.props.invoice.invoice_number}
                  </Link>
                </h4>
              </Table.Cell>
              <Table.Cell
                textAlign="right"
                style={{
                  borderTop: 'none',
                  padding: '0.3em .7em',
                  fontWeight: 'bold',
                }}
              >
                Total: {formatCentAmount(this.props.invoice.total)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table basic>
          <Table.Body>
            {this.props.invoice.external_vendor_invoice_line_items
              .slice(0, 5)
              .map(this.renderLineItem)}
          </Table.Body>
        </Table>
        <div style={{ textAlign: 'right' }}>
          <Link
            to={`/insights/vendor-invoices/${this.props.invoice.id}`}
            className="button tertiary"
          >
            See Full Details
          </Link>
        </div>
      </Segment>
    );
  }
}

ExternalVendorInvoiceSummary.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    invoice_number: PropTypes.string.isRequired,
    transaction_date: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    external_vendor_invoice_line_items: PropTypes.arrayOf(
      PropTypes.shape({
        customer_budget_code_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        unit_price: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    customerBudgetCodes: state.customerBudgetCodes.items,
    isRequesting: !state.externalVendorInvoices.items.filter(
      (evi) => evi.id === ownProps.invoice.id
    ).length,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        // add action creators here
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items &&
    prev.externalVendorInvoices.items === next.externalVendorInvoices.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ExternalVendorInvoiceSummary);
