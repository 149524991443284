import * as types from './action-types.js';
import * as notifications from '../lib/notifications.js';
import { formatEmployeeName } from '../lib/formatters.js';

// silence notifications for first 5 seconds, to allow for updates
let catchUp = true;
setTimeout(() => {
  catchUp = false;
}, 5 * 1000);

export const dismissConfirmation = () => {
  return {
    type: types.DISMISS_CONFIRMATION,
  };
};

/**
 * Enqueue a confirmation
 *
 * @access public
 * @param {Object} data containing message, [link, type]
 * @returns {Object}
 */
export const enqueueConfirmation = (data) => {
  return {
    type: types.ENQUEUE_CONFIRMATION,
    data,
  };
};

export function productFavorited(data) {
  return (dispatch) => {
    let body = {
      message: `Product added to ${data.location_list.name}`,
      link: `/supplies/levels?location_list=${data.location_list.id}`,
    };
    dispatch(enqueueConfirmation(body));
  };
}

export function productUnfavorited() {
  return (dispatch) => {
    let body = {
      message: 'Product removed from your lists',
    };
    dispatch(enqueueConfirmation(body));
  };
}

export function requisitionSubmitted(event) {
  return (dispatch, getState) => {
    if (catchUp) return;
    let { employees } = getState();
    let req = event.data;
    let suffix = '';
    if ((req.requester_type || '').match(/employee/i)) {
      let submitter = employees.items.filter(
        (e) => e.id === req.requester_id
      )[0];
      if (submitter) {
        suffix = ` by ${formatEmployeeName(submitter)}`;
      }
    }
    let data = {
      message: `Order #${req.id} was submitted${suffix}`,
      link: `/supplies/orders/${req.id}`,
    };
    if (notifications.Pushjs.Permission.has()) {
      return notifications.notify(data.message);
    }
    dispatch(enqueueConfirmation(data));
  };
}

export function requisitionProcessing(event) {
  return (dispatch, getState) => {
    if (catchUp) return;
    let { requisitions } = getState();
    let req = event.data;
    if (requisitions.open.id !== req.id) {
      return;
    }
    let data = {
      message: `Order #${req.id} is now processing`,
      link: `/supplies/orders/${req.id}`,
    };
    if (notifications.Pushjs.Permission.has()) {
      return notifications.notify(data.message);
    }
    dispatch(enqueueConfirmation(data));
  };
}

export function requisitionApprovalRequested(event) {
  return (dispatch, getState) => {
    if (catchUp) return;
    let { employees, auth } = getState();
    if (!auth.actor.type.match(/employee/i)) {
      return;
    }
    if (auth.role.approval_required_after_employee_spend !== 0) {
      return;
    }
    let req = event.data;
    let prefix = 'Approval requested for';
    if ((req.proposer_type || '').match(/employee/i)) {
      let proposer = employees.items.filter((e) => e.id === req.proposer_id)[0];
      if (proposer) {
        prefix = `${formatEmployeeName(proposer)} requested approval for`;
      }
    }
    let data = {
      message: `${prefix} Order #${req.id}`,
      link: '/supplies/checkout',
    };
    if (notifications.Pushjs.Permission.has()) {
      return notifications.notify(data.message, {
        body: 'You can approve the order on the checkout page.',
        link: 'supplies/checkout',
      });
    }
    dispatch(enqueueConfirmation(data));
  };
}

export function shipmentReceived(event) {
  return (dispatch, getState) => {
    if (catchUp) return;
    let { employees } = getState();
    let s = event.data;
    let prefix = 'Received';
    if ((s.receiver_type || '').match(/employee/i)) {
      let proposer = employees.items.filter((e) => e.id === s.receiver_id)[0];
      if (proposer) {
        prefix = `${formatEmployeeName(proposer)} received`;
      }
    }
    let data = {
      message: `${prefix} Shipment ${s.tracking_code}`,
      link: '/supplies/orders',
    };
    if (notifications.Pushjs.Permission.has()) {
      return notifications.notify(data.message);
    }
    dispatch(enqueueConfirmation(data));
  };
}
