import * as types from '../actions/action-types.js';

export const emptyFilters = {
  text: '',
  categoryIds: [],
  brandId: null,
  brandNames: [],
  vendorIds: [],
  maxShippingTimes: [],
  restrictedCatalog: false,
};

export const emptySort = {
  attribute: 'relevance',
  descending: true,
};

export const initial = {
  isRequesting: false,
  results: [],
  resultsQuery: '',
  filters: emptyFilters,
  sort: emptySort,
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.SET_PRODUCT_SORT: {
      return {
        ...state,
        sort: action.data,
      };
    }
    case types.SUCCESS_GET_LOCATION_PRODUCT_QUERY_RESULTS:
      return {
        ...state,
        ...action.data,
      };
    case types.CLEAR_PRODUCT_QUERY_RESULTS:
      return initial;
    case types.CLEAR_PRODUCT_FILTERS:
      return {
        ...state,
        filters: {
          ...emptyFilters,
          text: state.filters.text,
        },
      };
    case types.SET_PRODUCT_QUERY:
      return {
        ...state,
        ...action.data,
        filters: {
          ...state.filters,
          ...action.data.filters,
        },
      };
    case types.SIGN_OUT:
      return initial;
    default:
      return state;
  }
};
