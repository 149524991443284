import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import RequisitionSearchBar from './requisition-search-bar.js';

const RequisitionSearch = (props) => {
  return (
    <div>
      <RequisitionSearchBar onClearSearch={props.onClearSearch} />
    </div>
  );
};

RequisitionSearch.propTypes = {
  debounce: PropTypes.number,
  onClearSearch: PropTypes.func,
};

export default RequisitionSearch;
