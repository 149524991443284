import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import ItemActions from './item-actions.js';
import {
  formatStatus,
  formatDeliveryWindow,
  STATUS_COLOR_MAP,
} from '../../helpers/shipment-helpers.js';
import 'semantic-ui-css/components/table.min.css';
import './item.css';
const PRODUCT_DISPLAY_MAX = 10;

export class PurchaseOrderItem extends React.PureComponent {
  shipmentIsLate() {
    return new Date(this.props.item.expected_at_end) < new Date();
  }

  renderTrackingText = () => {
    return this.props.item.tracking_code;
  };

  renderProductPreview = (ppo) => {
    return (
      <div className="product-image" key={ppo.id}>
        <ItemImage
          width={42}
          height={42}
          size={'thumb'}
          product={ppo.product}
          alt={ppo.product.name}
          title={ppo.product.name}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="purchase-order-item">
        <div className="purchase-order-info">
          <Table basic="very" compact unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={7}>Expected</Table.HeaderCell>
                <Table.HeaderCell width={3}>Via</Table.HeaderCell>
                {this.props.item.status && <Table.HeaderCell />}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {this.props.item.expected_at_end &&
                    formatDeliveryWindow(this.props.item)}{' '}
                  for {this.props.item.shipping_name}
                </Table.Cell>
                <Table.Cell>{this.props.item.carrier}</Table.Cell>
                {this.props.item.status && (
                  <Table.Cell>
                    <span
                      className="shipment-status"
                      style={{
                        color:
                          STATUS_COLOR_MAP[this.props.item.status] || 'grey',
                      }}>
                      {formatStatus(this.props.item)}
                    </span>
                  </Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="purchase-order-item-product-data">
            <div className="purchase-order-item-product-images">
              {this.props.item.product_purchase_orders
                .slice(0, PRODUCT_DISPLAY_MAX)
                .map(this.renderProductPreview)}
              {this.props.item.product_purchase_orders.length >
                PRODUCT_DISPLAY_MAX && (
                <div className="purchase-order-item-extra-product-count light-gray-text">
                  {this.props.item.product_purchase_orders.length -
                    PRODUCT_DISPLAY_MAX}{' '}
                  More Items
                </div>
              )}
            </div>
          </div>
        </div>
        <ItemActions item={this.props.item} />
      </div>
    );
  }
}

PurchaseOrderItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number.isRequired,
    tracking_code: PropTypes.string.isRequired,
    received_at: PropTypes.string,
    receiver_id: PropTypes.number,
    receiver_type: PropTypes.string,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_country: PropTypes.string,
    shipping_zip: PropTypes.string,
    tracking_url: PropTypes.string,
    product_purchase_orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        product_id: PropTypes.number.isRequired,
        product_requisition_id: PropTypes.number.isRequired,
        purchase_order_id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        issue: PropTypes.string,
        issue_quantity: PropTypes.number,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

function areStatesEqual(prev, next) {
  return prev === next;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(PurchaseOrderItem);
