import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import { formatDate, formatCentAmount } from '../../lib/formatters.js';
import { doOpenEmployeeStipends } from '../../actions/employee-stipend-actions.js';
import { openStipendEditModal, doDeleteStipend } from '../../ducks/stipends.js';

export class StipendItem extends React.PureComponent {
  handleClickRemove = (stipendId) => {
    if (window.confirm('Do you want to delete this stipend?')) {
      this.props.actions.doDeleteStipend(
        stipendId,
        this.props.stipend.customer_id
      );
    }
  };

  openEmployeeStipendsModal = () => {
    this.props.actions.doOpenEmployeeStipends(this.props.stipend);
  };

  openStipendEditModal = () => {
    this.props.actions.openStipendEditModal({
      stipend_id: this.props.stipend.id,
    });
  };

  render() {
    return (
      <Table.Row className="settings-team-row">
        <Table.Cell width={5}>{this.props.stipend.name}</Table.Cell>
        <Table.Cell width={3}>
          {formatCentAmount(this.props.stipend.amount)}
        </Table.Cell>
        <Table.Cell width={3}>
          {formatDate(moment(this.props.stipend.starts_at).zone('-06:00'))}
        </Table.Cell>
        <Table.Cell width={3}>
          {formatDate(moment(this.props.stipend.expires_at).zone('-06:00'))}
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            style={{ border: 'none', padding: '0.25rem', boxShadow: 'none' }}
            icon="users"
            compact
            size="tiny"
            basic
            onClick={this.openEmployeeStipendsModal}
          />
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            style={{ border: 'none', padding: '0.25rem', boxShadow: 'none' }}
            icon="edit"
            compact
            size="tiny"
            basic
            onClick={this.openStipendEditModal}
          />
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            style={{ border: 'none', padding: '0.25rem', boxShadow: 'none' }}
            icon="trash alternate outline"
            compact
            size="tiny"
            basic
            onClick={this.handleClickRemove.bind(this, this.props.stipend.id)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

StipendItem.propTypes = {
  stipend: PropTypes.shape({
    id: PropTypes.number,
    expires_at: PropTypes.string,
    starts_at: PropTypes.string,
    amount: PropTypes.number,
    name: PropTypes.string,
  }),
  actions: PropTypes.shape({
    doOpenEmployeeStipends: PropTypes.func.isRequired,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openStipendEditModal,
        doOpenEmployeeStipends,
        doDeleteStipend,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps, null, {})(StipendItem);
