import ReactGA from 'react-ga';
import perf from '../lib/perf.js';
import { ENV, CATEGORY_FUNC, VARIABLE_EXEC } from '../strings.js';
const THRESHOLD = 100; // ~ 6 animation frames

const perfMid = (store) => (next) => (action) => {
  perf.start(action.type);

  let result = next(action);

  perf.end(action.type);
  if (perf.duration(action.type) > THRESHOLD) {
    if (!ENV.match(/PROD/)) {
      perf.log(action.type, 'warn');
    }
    ReactGA.timing({
      category: CATEGORY_FUNC,
      variable: VARIABLE_EXEC,
      value: perf.duration(action.type),
      label: 'Redux action ' + action.type,
    });
  }
  return result;
};

export default perfMid;
