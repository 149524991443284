import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import InputText from '../forms/input-text.js';
import { submitCustomerBudgetCode } from '../../actions/customer-budget-codes-actions.js';
import './budget-code-form-new.css';
const EMPTY_STATE = {
  name: '',
  code: '',
};

export class NewBudgetCodeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = EMPTY_STATE;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const success =
      nextProps.budgetCodes.filter((b) => b.name === this.state.name).length >
      0;
    if (this.props.isRequesting && !nextProps.isRequesting && success) {
      this.setState(() => {
        return EMPTY_STATE;
      });
      this.props.onSuccess && this.props.onSuccess(this.state);
    }
  }

  handleChangeName = (name) => {
    this.setState(() => {
      return { name };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.props.actions.submitCustomerBudgetCode({
      name: this.state.name,
      code: this.state.code || this.state.name,
    });
  };

  isDisabled = () => {
    return this.state.name.length < 2;
  };

  render() {
    return (
      <div className="budget-code-form-new">
        <form className="budget-code-form-new-form" onSubmit={this.onSubmit}>
          <InputText
            onChange={this.handleChangeName}
            value={this.state.name}
            label="Category Name"
            placeholder="Name"
            className="budget-code-input-name"
          />
          <Button primary disabled={this.isDisabled()}>
            Add Category
          </Button>
        </form>
      </div>
    );
  }
}

NewBudgetCodeForm.propTypes = {
  budgetCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  onSuccess: PropTypes.func,
  actions: PropTypes.shape({
    submitCustomerBudgetCode: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    budgetCodes: state.customerBudgetCodes.items,
    isRequesting: state.customerBudgetCodes.creating.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitCustomerBudgetCode,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items &&
    prev.customerBudgetCodes.creating === next.customerBudgetCodes.creating
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(NewBudgetCodeForm);
