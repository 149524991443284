import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Button, Header, Table } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import OrdersProductRow from '../requisitions/orders-product-row.js';
import ShipmentCommentForm from '../purchaseOrders/comment-form.js';
import TextWithAnchors from '../text/text-with-anchors.js';
import TrackingUpdates from '../shipments/tracking-updates.js';
import TrackingCode from '../shipments/tracking-code.js';
import ItemActions from '../purchaseOrders/item-actions.js';
import { closeShipment, doGetShipment } from '../../ducks/shipments.js';
import { formatDateTime, formatName } from '../../lib/formatters.js';
import { trackShipmentExternalTracking } from '../../lib/analytics.js';
import {
  formatStatus,
  formatDeliveryWindow,
  STATUS_COLOR_MAP,
  findRequisitionIds,
} from '../../helpers/shipment-helpers.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/table.min.css';
import './modal-common.css';
import './modal-shipment.css';

function FILTER_PRESENT(a) {
  return !!a;
}

function sorter(sortColumn, sortDirection) {
  if (sortDirection === 'descending') return descendingSorter(sortColumn);
  return ascendingSorter(sortColumn);
}
function ascendingSorter(sortColumn) {
  return function (a, b) {
    switch (sortColumn) {
      case 'item':
        return (a.product || {}).name < (b.product || {}).name ? -1 : 1;
      case 'quantity':
      default:
        return a.quantity < b.quantity ? -1 : 1;
    }
  };
}
function descendingSorter(sortColumn) {
  return function (a, b) {
    switch (sortColumn) {
      case 'item':
        return (a.product || {}).name > (b.product || {}).name ? -1 : 1;
      case 'quantity':
      default:
        return a.quantity > b.quantity ? -1 : 1;
    }
  };
}

export class ModalShipment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayCommentForm: false,
      sortColumn: 'item',
      sortDirection: 'ascending',
      sortedProductPurchaseOrders: (
        props.shipment.product_purchase_orders || []
      ).sort(sorter('item', 'ascending')),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shipment.id && !nextProps.shipment.created_at) {
      nextProps.actions.doGetShipment({
        id: nextProps.shipment.id,
      });
    }
    this.setState((prev) => {
      return {
        sortedProductPurchaseOrders: (
          nextProps.shipment.product_purchase_orders || []
        ).sort(sorter(prev.sortColumn, prev.sortDirection)),
      };
    });
  }

  displayCommentForm = () => {
    this.setState(
      Object.assign({}, this.state, {
        displayCommentForm: true,
      })
    );
  };

  onRequestClose = () => {
    this.setState(
      Object.assign({}, this.state, {
        displayCommentForm: false,
      })
    );
    this.props.actions.closeShipment();
  };

  handleRedirectToShipment = (id) => {
    this.props.actions.closeShipment();
    browserHistory.push(`/supplies/orders/${id}`);
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleSort = (col) => {
    if (col !== this.state.sortColumn) {
      return this.setState(() => {
        return {
          sortColumn: col,
          sortDirection: 'ascending',
          sortedProductPurchaseOrders: (
            this.props.shipment.product_purchase_orders || []
          ).sort(sorter(col, 'ascending')),
        };
      });
    }
    this.setState((prev) => {
      let sortDirection =
        prev.sortDirection === 'ascending' ? 'descending' : 'ascending';
      return {
        sortDirection,
        sortedProductPurchaseOrders: (
          this.props.shipment.product_purchase_orders || []
        ).sort(sorter(prev.sortColumn, sortDirection)),
      };
    });
  };

  onClickTracking = () => {
    trackShipmentExternalTracking(this.props.shipment);
  };

  renderTrackingText = () => {
    return this.props.shipment.tracking_code;
  };

  renderProduct = (item) => {
    return (
      <OrdersProductRow
        item={item}
        key={item.id}
        showShippingDetails={false}
        showPrice={false}
        showBudget={false}
      />
    );
  };

  renderComment = (item) => {
    return (
      <div key={item.id} className="submitted-comment-body">
        &ldquo;
        <TextWithAnchors text={item.body} />
        &rdquo;
      </div>
    );
  };

  renderRequisitionLink = (id) => {
    return (
      <Link
        className="primary"
        style={{ fontSize: 'inherit' }}
        key={id}
        onClick={this.handleRedirectToShipment.bind(this, id)}
        to={`/supplies/orders/${id}`}>
        #{id}
      </Link>
    );
  };

  renderStatus = () => {
    if (
      this.props.shipment.missed_at &&
      this.props.shipment.received_at < this.props.shipment.missed_at
    ) {
      return 'Reported Missing';
    }
    if (
      !this.props.shipment.received_at &&
      this.props.shipment.expected_at_end
    ) {
      return 'Expected';
    }
    if (this.props.shipment.received_at) {
      return 'Received';
    }
    return 'Expected';
  };

  renderStatusTime = () => {
    if (
      this.props.shipment.missed_at &&
      this.props.shipment.received_at < this.props.shipment.missed_at
    ) {
      return formatDateTime(this.props.shipment.missed_at);
    }
    if (this.props.shipment.received_at) {
      return `${formatDateTime(
        this.props.shipment.received_at
      )} by ${formatName(this.props.shipment.receiver)}`;
    }
    return formatDeliveryWindow(this.props.shipment);
  };

  render() {
    if (
      !this.props.shipment ||
      !this.props.shipment.id ||
      !this.props.shipment.created_at
    ) {
      return null;
    }
    const column = this.state.sortColumn;
    const direction = this.state.sortDirection;
    const requisitionIds = this.props.requisitionIds || [];
    const shipment = this.props.shipment;

    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!shipment.id}
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.onAfterOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Shipment Modal">
        <div className="modal-shipment">
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
          <div className="modal-shipment-main-content">
            <h3 style={{ marginBottom: '3rem' }}>
              Shipment
              {requisitionIds.length > 0 && (
                <span className="modal-shipment-order-links">
                  &nbsp;for Order&nbsp;
                  {requisitionIds.map(this.renderRequisitionLink)}
                </span>
              )}
            </h3>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>
                    {this.renderStatus()}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    {shipment.status && 'Status'}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5}>Marked As</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{this.renderStatusTime()}</Table.Cell>
                  <Table.Cell>
                    {shipment.status && (
                      <span
                        className="shipment-status"
                        style={{
                          color: STATUS_COLOR_MAP[shipment.status] || 'grey',
                        }}>
                        {formatStatus(shipment)}
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <ItemActions
                      item={shipment}
                      showDetailsLink={false}
                      showTimestamp={false}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>Shipped To</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Via</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Tracking Code</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {[shipment.shipping_name, shipment.shipping_business]
                      .filter(FILTER_PRESENT)
                      .join(', ')}
                    <br />
                    {[
                      shipment.shipping_address,
                      shipment.shipping_address_number,
                    ]
                      .filter(FILTER_PRESENT)
                      .join(' ')}
                    <br />
                    {shipment.shipping_city}, {shipment.shipping_state},{' '}
                    {shipment.shipping_country} {shipment.shipping_zip}
                  </Table.Cell>
                  <Table.Cell>{shipment.carrier}</Table.Cell>
                  <Table.Cell>
                    <TrackingCode item={shipment} asLinkEasypost={false} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          {/*<div style={{ margin: '1rem 0' }}>
                        <ItemActions item={shipment} showDetailsLink={false} />
                    </div>*/}
          <div className="shipment-products">
            <Header as="h4" style={{ margin: '0 0 -0.5rem' }}>
              Items in Shipment
            </Header>
            <Table basic sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan="2"
                    sorted={column === 'item' ? direction : null}
                    onClick={this.handleSort.bind(this, 'item')}>
                    Item
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="right"
                    colSpan="2"
                    sorted={column === 'quantity' ? direction : null}
                    onClick={this.handleSort.bind(this, 'quantity')}>
                    Qty
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.sortedProductPurchaseOrders.map(this.renderProduct)}
              </Table.Body>
            </Table>
          </div>
          <TrackingUpdates shipment={shipment} />
          <div className="shipment-comments">
            {(shipment.comments || []).map(this.renderComment)}
          </div>
          {!this.state.displayCommentForm &&
            (!shipment.comments || shipment.comments.length < 1) && (
              <div className="shipment-submit-comment-link">
                <Button secondary size="mini" onClick={this.displayCommentForm}>
                  Report Issue With This Shipment
                </Button>
                {this.props.account_manager && (
                  <p className="microcopy">
                    {this.props.account_manager.name} will take care of any
                    issues with your order.
                  </p>
                )}
              </div>
            )}
          {this.state.displayCommentForm && (
            <ShipmentCommentForm
              account_manager={this.props.account_manager}
              shipment={shipment}
            />
          )}
        </div>
      </Modal>
    );
  }
}

ModalShipment.propTypes = {
  actions: PropTypes.shape({
    closeShipment: PropTypes.func.isRequired,
  }).isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number,
    tracking_code: PropTypes.string,
    received_at: PropTypes.string,
    receiver_id: PropTypes.number,
    receiver_type: PropTypes.string,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_zip: PropTypes.string,
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        body: PropTypes.string,
      })
    ),
    product_purchase_orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        product_id: PropTypes.number.isRequired,
        product_requisition_id: PropTypes.number,
        purchase_order_id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        issue: PropTypes.string,
        issue_quantity: PropTypes.number,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
      })
    ),
    requisitionIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  account_manager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar_attachment: PropTypes.shape({
      medium: PropTypes.string.isRequired,
      thumb: PropTypes.string.isRequired,
      original: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    shipment: state.shipments.open,
    account_manager: state.locations.open.account_manager,
    requisitionIds: findRequisitionIds(
      state.shipments.open,
      state.requisitions.items
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeShipment,
        doGetShipment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.shipments.open === next.shipments.open &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalShipment);
