import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import OfficeBudgetSettings from './office-budget-settings.js';
import InsightsControls from './controls.js';
import TotalOfficeSpendLine from './total-office-spend-line.js';
import SpendByBudgetCodePieTable from './spend-by-budget-code-pie-table.js';
import SpendByVendorPieTable from './spend-by-vendor-pie-table.js';
import SpendToBudgetProgressPie from './spend-to-budget-progress-pie.js';
import ReportDownload from './report-downloads.js';
import {
  doGetLocationDataView,
  openDataViewRange,
  openDataViewLocations,
} from '../../actions/data-view-actions.js';
import { openFeatureTour } from '../../ducks/feature-tours.js';
import { hasSeenFeatureTour } from '../../helpers/feature-tour-helpers.js';
import './insights-budgets.css';
import { Icon } from 'semantic-ui-react';

const DATA_VIEWS = [
  'customer_purchase_vendor_dates',
  'customer_purchase_budget_code_dates',
  'customer_purchase_dates',
  'customer_purchase_line_item_budget_code_dates',
];

export class InsightsBudgets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeThrottler);
    this._refreshData({
      locations: this.props.viewingLocations,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    });
    if (this.props.displayBudgetInsightsTour) {
      this.introTimeout = setTimeout(() => {
        this.props.actions.openFeatureTour({ name: 'budget_insights_enabled' });
      }, 100);
    }
    window.totango.track('Viewed Budget Insights.', 'Reporting');
  }

  componentWillUnmount() {
    this.introTimeout && window.clearTimeout(this.introTimeout);
    this.resizeTimeout && window.clearTimeout(this.resizeTimeout);
    window.removeEventListener('resize', this.resizeThrottler);
  }

  componentDidUpdate(prevProps) {
    if (
      this._haveLocationsUpdated(prevProps) ||
      this._haveDatesUpdated(prevProps)
    ) {
      this._refreshData({
        locations: this.props.viewingLocations,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }

  _haveLocationsUpdated = (prevProps) => {
    return (
      this.props.viewingLocations.map((l) => l.id).join('') !==
        prevProps.viewingLocations.map((l) => l.id).join('') ||
      this.props.viewingLocations.map((l) => l.updated_at).join('') !==
        prevProps.viewingLocations.map((l) => l.updated_at).join('')
    );
  };

  _haveDatesUpdated = (prevProps) => {
    return (
      this.props.startDate.toJSON() !== prevProps.startDate.toJSON() ||
      this.props.endDate.toJSON() !== prevProps.endDate.toJSON()
    );
  };

  _locationHasAccessToBudgetCodes = () => {
    let matchingPreferences = this.props.viewingLocations.filter((loc) => {
      return loc.pref_enable_custom_budget_codes;
    });
    return matchingPreferences.length === this.props.viewingLocations.length;
  };

  resizeThrottler = () => {
    if (this.resizeTimeout) {
      return;
    }
    this.resizeTimeout = window.setTimeout(this.resizeListener, 200);
  };

  resizeListener = () => {
    this.resizeTimeout = null;
    if (this.state.windowWidth === window.innerWidth) return;
    this.setState(
      Object.assign({}, this.state, {
        windowWidth: window.innerWidth,
      })
    );
  };

  _onChangeDates = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
  };

  _onChangeLocations = (locations) => {
    this.props.actions.openDataViewLocations(locations);
  };

  _refreshData = ({ locations, startDate, endDate }) => {
    let start = new Date(startDate.startOf('day')).toJSON();
    let end = new Date(endDate.endOf('day')).toJSON();
    locations.map((location) => {
      DATA_VIEWS.map((view) => {
        return this.props.actions.doGetLocationDataView({
          location_id: location.id,
          view,
          start,
          end,
        });
      });
      return location;
    });
  };

  beginInAppTutorial = () => {
    this.props.actions.openFeatureTour({ name: 'budget_insights_enabled' });
  };

  render() {
    return (
      <div className="insights-budgets-container">
        <div
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={this.beginInAppTutorial}>
          <Icon name="question circle" />
        </div>
        <InsightsControls
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          openLocations={this.props.viewingLocations}
          windowWidth={this.state.windowWidth}
          onChangeDates={this._onChangeDates}
          onChangeLocations={this._onChangeLocations}
        />
        <div className="insights-budget-spend">
          <TotalOfficeSpendLine
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            locations={this.props.viewingLocations}
            windowWidth={this.state.windowWidth}
          />
          <div className="hide-when-printing office-budget-settings-container">
            <OfficeBudgetSettings locations={this.props.viewingLocations} />
            <ReportDownload
              locations={this.props.viewingLocations}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
            />
          </div>
        </div>
        <div className="insights-budget-code-spend">
          <SpendByBudgetCodePieTable />
        </div>
        <div className="insights-budget-progress-container">
          <SpendToBudgetProgressPie />
        </div>
        <div className="insights-vendor-spend">
          <SpendByVendorPieTable />
        </div>
      </div>
    );
  }
}

InsightsBudgets.propTypes = {
  viewingLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      pref_enable_custom_budget_codes: PropTypes.bool,
    })
  ).isRequired,
  displayCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  actions: PropTypes.shape({
    doGetLocationDataView: PropTypes.func.isRequired,
    openDataViewRange: PropTypes.func.isRequired,
    openDataViewLocations: PropTypes.func.isRequired,
    openFeatureTour: PropTypes.func.isRequired,
  }).isRequired,
  displayBudgetInsightsTour: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    displayBudgetInsightsTour: !hasSeenFeatureTour(
      'budget_insights_enabled',
      state.featureTours.items,
      state.featureTourAttempts.summary
    ),
    isRequesting:
      !state.locations.open.created_at ||
      state.locations.requesting.length > 0 ||
      state.employees.isRequesting,
    viewingLocations: state.dataViews.openLocations.length
      ? state.dataViews.openLocations
      : [state.locations.open],
    startDate: state.dataViews.openRange.startDate,
    endDate: state.dataViews.openRange.endDate,
    displayCodes: state.customerBudgetCodes.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetLocationDataView,
        openDataViewRange,
        openDataViewLocations,
        openFeatureTour,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.locations.requesting === next.locations.requesting &&
    prev.employees.isRequesting === next.employees.isRequesting &&
    prev.featureTours.items === next.featureTours.items &&
    prev.featureTourAttempts.summary === next.featureTourAttempts.summary &&
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsBudgets);
