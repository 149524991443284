import rootReducer from '../reducers';
import middleware from '../middleware';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

export default (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(middleware)
  );
};
