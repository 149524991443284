// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//  import Gossip                       from '../events/gossip.js';
import BeforeUnload from '../events/before-unload.js';
import Visibility from '../events/visibility.js';
import Online from '../events/online.js';
import PopState from '../events/popstate.js';
import Confirmations from '../components/confirmations.js';
import BrandColorOverride from '../components/brand-color-override.js';
import ModalProduct from '../components/modals/modal-product.js';
import ModalShipment from '../components/modals/modal-shipment.js';
import ModalHotkeys from '../components/modals/modal-hotkeys.js';
import ModalCleaningTask from '../components/modals/modal-cleaning-task.js';
import ModalConfirmationDeleteTask from '../components/modals/modal-confirmation-delete-task.js';
import ModalInvoice from '../components/modals/modal-invoice.js';
import ModalStipendEdit from '../components/modals/modal-stipend-edit.js';
import ModalEmployeeStipends from '../components/modals/modal-employee-stipends.js';
import ModalCreateBudgetCode from '../components/modals/modal-create-budget-code.js';
import ModalCreateLocationList from '../components/modals/modal-create-location-list.js';
import ModalUpdateLocationList from '../components/modals/modal-update-location-list.js';
import ModalItemCatalogable from '../components/modals/modal-item-catalogable.js';
import FeatureTour from '../components/onboarding/feature-tour.js';
import setTitle from '../decorators/set-title.js';
import { updateAppVersion } from '../actions/application-actions.js';

import ModalPromotedBrand from '../components/modals/modal-promoted-brand.js';
import ModalCartConfig from '../components/modals/modal-cart-config.js';
import { HALF_HOUR_MILLIS } from '../strings.js';

export class RootAttendant extends React.Component {
  static propTypes = {
    upgradeReady: PropTypes.bool.isRequired,
    appVersion: PropTypes.string.isRequired,
    employeeUser: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      updateAppVersion: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = { visible: !document.hidden };

  constructor(props) {
    super(props);
    this.state = {
      visible: !document.hidden,
      children: props.children,
      backgroundChildren: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let backgroundChildren = this.props.children;
    let backgroundState = {};

    if (nextProps.location.pathname !== this.props.location.pathname) {
      if (nextProps.location.pathname.match(/^\/t\/./)) {
        // this is routing to a ticket modal
        backgroundState.backgroundChildren = backgroundChildren;
      }
      if (this.props.location.pathname.match(/^\/t\/./)) {
        // this is routing away from a ticket modal
        backgroundState.backgroundChildren = null;
      }
    }
    this.setState(
      Object.assign({}, this.state, backgroundState, {
        children: nextProps.children,
      })
    );
  }

  componentDidUpdate() {
    if (this.props.upgradeReady && !this.state.visible) {
      this.props.actions.updateAppVersion(this.props.appVersion);
    }
  }

  handleChangeVisibility = (visible) => {
    this.setState(Object.assign({}, this.state, { visible }));
  };

  render() {
    return (
      <div>
        <BrandColorOverride />
        {this.state.backgroundChildren || this.state.children}
        {/* <Gossip /> */}
        <BeforeUnload />
        <Online />
        <PopState />
        <Visibility
          invisibilityThreshold={HALF_HOUR_MILLIS}
          onChangeVisible={this.handleChangeVisibility}
        />
        <ModalHotkeys />
        <ModalProduct />
        <ModalShipment />
        <ModalCleaningTask />
        <ModalConfirmationDeleteTask />
        <ModalInvoice />
        <ModalStipendEdit />
        <ModalEmployeeStipends />
        <ModalCreateBudgetCode />
        <ModalCreateLocationList />
        <ModalUpdateLocationList />
        <ModalItemCatalogable />
        <ModalPromotedBrand />
        <ModalCartConfig />
        <Confirmations />
        {/* {this.props.employeeUser && <ZendeskChat />} */}
        {this.props.employeeUser && this.props.featureTour.id > 0 && (
          <FeatureTour />
        )}
      </div>
    );
  }
}

RootAttendant = setTitle((props) => {
  return 'OfficeLuv App';
})(RootAttendant);

function mapStateToProps(state) {
  return {
    employeeUser: state.employees.user.id > 0,
    upgradeReady: state.application.upgradeReady,
    appVersion: state.application.appVersion,
    featureTour: state.featureTours.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateAppVersion,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootAttendant);
