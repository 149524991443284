import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeProductBrand } from '../../actions/product-brand-actions.js';
import TextWithAnchors from '../text/text-with-anchors.js';
import brandPropType from '../../propTypes/brand.js';
import './modal-common.css';
import './modal-shipment.css';
import './modal-promoted-brand.css';

export class ModalPromotedBrand extends React.PureComponent {
  onRequestClose = () => {
    this.props.actions.closeProductBrand();
  };

  renderContent() {
    return (
      <div className="modal-brand">
        <img
          src={this.props.openBrand.images_attachments[0].original}
          alt={this.props.openBrand.name}
          className="modal-brand__image"
        />
        <h2 className="brand-color-override">{this.props.openBrand.name}</h2>
        <p className="modal-product__description">
          <TextWithAnchors text={this.props.openBrand.description} />
        </p>
      </div>
    );
  }

  render() {
    if (!this.props.modalIsOpen) return null;
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.onRequestClose}
        onAfterOpen={() => {}}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Brand Modal"
        appElement={document.getElementById('root')}>
        <button className="close-icon" onClick={this.onRequestClose}>
          &times;
        </button>
        {this.renderContent()}
      </Modal>
    );
  }
}

ModalPromotedBrand.propTypes = {
  actions: PropTypes.shape({
    closeProductBrand: PropTypes.func.isRequired,
  }).isRequired,
  openBrand: brandPropType,
  modalIsOpen: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  const openBrand = state.productBrands.open;
  return {
    openBrand,
    modalIsOpen: !!openBrand.id,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeProductBrand,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return prev.productBrands.open === next.productBrands.open;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalPromotedBrand);
