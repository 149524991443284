import moment from 'moment';
import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';
export const ERROR_TIMEOUT = 5000;

// Actions
export const GET_STIPENDS = 'GET_STIPENDS';
export const SUCCESS_GET_STIPENDS = 'SUCCESS_GET_STIPENDS';
export const ERROR_GET_STIPENDS = 'ERROR_GET_STIPENDS';

export const CREATE_STIPEND = 'CREATE_STIPEND';
export const SUCCESS_CREATE_STIPEND = 'SUCCESS_CREATE_STIPEND';
export const ERROR_CREATE_STIPEND = 'ERROR_CREATE_STIPEND';

export const UNSET_STIPEND_RESPONSE = 'UNSET_STIPEND_RESPONSE';
export const STAGE_STIPEND = 'STAGE_STIPEND';
export const UNSTAGE_STIPEND = 'UNSTAGE_STIPEND';
export const UPDATE_STAGED_STIPEND = 'UPDATE_STAGED_STIPEND';

export const OPEN_STIPEND_EDIT_MODAL = 'OPEN_STIPEND_EDIT_MODAL';
export const CLOSE_STIPEND_EDIT_MODAL = 'CLOSE_STIPEND_EDIT_MODAL';
export const SUCCESS_STIPEND_UPDATE = 'SUCCESS_STIPEND_UPDATE';
export const ERROR_STIPEND_UPDATE = 'ERROR_STIPEND_UPDATE';

export const DELETE_STIPEND = 'DELETE_STIPEND';
export const SUCCESS_DELETE_STIPEND = 'SUCCESS_DELETE_STIPEND';
export const ERROR_DELETE_STIPEND = 'ERROR_DELETE_STIPEND';

// Helpers
export const FILTER_GET_INDEX_REQUEST = (req) =>
  req.data._request === 'GET_STIPENDS';

export const FILTER_IS_LOADING = (req) =>
  req.type === 'GET_STIPENDS' || req.type === 'GET_STIPEND';

export const FILTER_IS_CREATE_SUCCESS = (req) =>
  req.type === 'SUCCESS_CREATE_STIPEND';

export const FILTER_IS_UPDATE_SUCCESS = (req) =>
  req.type === 'SUCCESS_UPDATE_STIPEND';

export const FILTER_IS_SAVE_SUCCESS = (req) =>
  req.type === 'SUCCESS_CREATE_STIPEND' ||
  req.type === 'SUCCESS_UPDATE_STIPEND';

export const FILTER_IS_SUBMITTING = (req) =>
  req.type === 'CREATE_STIPEND' || req.type === 'UPDATE_STIPEND';

export const FILTER_IS_ERROR = (req) =>
  req.type === 'ERROR_CREATE_STIPEND' || req.type === 'ERROR_UPDATE_STIPEND';

// Reducer
export const empty = {
  initialized: false,
  items: {},
  staged: {
    amount: 0,
    starts_at: moment().format('YYYY-MM-DD HH:mm'),
    expires_at: moment().endOf('month').format('YYYY-MM-DD HH:mm'),
    name: null,
  },
  edit: {},
  requesting: [],
  responses: [],
};

export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  let items;
  switch (action.type) {
    case GET_STIPENDS:
    case CREATE_STIPEND:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_STIPENDS:
      return {
        ...state,
        items: action.data.items.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
        meta: action.data.meta,
      };
    case ERROR_GET_STIPENDS:
    case ERROR_CREATE_STIPEND:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case SUCCESS_CREATE_STIPEND:
      items = { ...state.items };
      items[action.data.item.id] = action.data.item;
      return {
        ...state,
        items: items,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_STIPEND_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case STAGE_STIPEND:
      return {
        ...state,
        staged: state.items[action.data.id] || action.data,
      };
    case UPDATE_STAGED_STIPEND:
      return {
        ...state,
        staged: {
          ...state.staged,
          ...action.data,
        },
      };
    case UNSTAGE_STIPEND:
      return {
        ...state,
        staged: empty.staged,
      };
    case OPEN_STIPEND_EDIT_MODAL:
      return {
        ...state,
        edit: Object.assign({}, action.data),
      };
    case CLOSE_STIPEND_EDIT_MODAL:
      return {
        ...state,
        edit: empty.edit,
      };
    case SUCCESS_STIPEND_UPDATE:
      items = { ...state.items };
      items[action.data.id] = action.data;

      return {
        ...state,
        items: items,
        edit: empty.edit,
      };
    case SUCCESS_DELETE_STIPEND:
      return {
        ...state,
        items: removeProperty(state.items, action.data.id),
      };
    default:
      return state;
  }
};

// Action Creators
export const getStipends = (data) => ({ data, type: GET_STIPENDS });
export const successGetStipends = (data) => ({
  data,
  type: SUCCESS_GET_STIPENDS,
});
export const errorGetStipends = (data) => ({ data, type: ERROR_GET_STIPENDS });

export const createStipend = (data) => ({ data, type: CREATE_STIPEND });
export const successCreateStipend = (data) => ({
  data,
  type: SUCCESS_CREATE_STIPEND,
});
export const errorCreateStipend = (data) => ({
  data,
  type: ERROR_CREATE_STIPEND,
});

export const stageStipend = (data) => ({ data, type: STAGE_STIPEND });
export const unstageStipend = (data) => ({ data, type: UNSTAGE_STIPEND });
export const updateStagedStipend = (data) => ({
  data,
  type: UPDATE_STAGED_STIPEND,
});
export const unsetStipendResponse = (data) => ({
  data,
  type: UNSET_STIPEND_RESPONSE,
});

export const openStipendEditModal = (data) => ({
  data,
  type: OPEN_STIPEND_EDIT_MODAL,
});
export const closeStipendEditModal = () => ({ type: CLOSE_STIPEND_EDIT_MODAL });
export const successStipendUpdate = (data) => ({
  data,
  type: SUCCESS_STIPEND_UPDATE,
});
export const errorStipendUpdate = () => ({ type: ERROR_STIPEND_UPDATE });

export const deleteStipend = () => ({ type: DELETE_STIPEND });
export const successDeleteStipend = (data) => ({
  data,
  type: SUCCESS_DELETE_STIPEND,
});
export const errorDeleteStipend = () => ({ type: ERROR_DELETE_STIPEND });

/// THUNKS
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetStipendResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetStipends = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getStipends(data));
    return fetch(`${ADMIN_V1_URL}/customers/${data.id}/stipends?limit=1000`, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get Stipends' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetStipends)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetStipends)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doCreateStipend = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(createStipend(data));
    return fetch(`${ADMIN_V1_URL}/customers/${data.customer_id}/stipends`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Request-ID': data._request,
      },
      body: JSON.stringify({
        ...data,
        _request: undefined,
      }),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Create Stipend' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successCreateStipend)({
            item: body,
            _request: data._request,
          })
        );
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Stipend Created!',
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorCreateStipend)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};

export const doSubmitStagedStipend = (data = {}) => {
  return (dispatch, getState) => {
    const {
      stipends: { staged },
    } = getState();
    dispatch(
      doCreateStipend({
        ...staged,
        ...data,
      })
    );
  };
};

export function doDeleteStipend(stipendId, customerId) {
  return (dispatch) => {
    dispatch(deleteStipend());
    return fetch(
      `${ADMIN_V1_URL}/customers/${customerId}/stipends/${stipendId}`,
      {
        method: 'DELETE',
      }
    )
      .then(() => {
        return { id: stipendId };
      })
      .then((body) => {
        dispatch(successDeleteStipend(body));
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Stipend was deleted!',
          })
        );
      })
      .catch((error) => {
        dispatch(errorDeleteStipend());
        trackFetchError(error);
      });
  };
}

export function doUpdateStipend(editedStipend) {
  return (dispatch) => {
    editedStipend._request = editedStipend._request || requestID();

    return fetch(
      `${ADMIN_V1_URL}/customers/${editedStipend.customer_id}/stipends/${editedStipend.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': editedStipend._request,
        },
        body: JSON.stringify({
          ...editedStipend,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((body) => {
        dispatch(closeStipendEditModal);
        dispatch(successStipendUpdate(body));
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Stipend was updated successfully.',
          })
        );
      })
      .catch((error) => {
        dispatch(errorStipendUpdate());
        dispatch(
          enqueueConfirmation({
            type: 'error',
            message: "Stipend couldn't be updated.",
          })
        );
        trackFetchError(error);
      });
  };
}

// Auxiliar function

const removeProperty = (object, key) => {
  const newObject = Object.assign({}, object);
  delete newObject[key];

  return newObject;
};
