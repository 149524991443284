import log from './log.js';
export const hasPerf = typeof window !== 'undefined' && window.performance;
export const hasPerfNow = hasPerf && window.performance.now;
export const hasPerfMark = hasPerf && window.performance.mark;
let marks = {};
let measures = {};

function mean(values) {
  return (
    values.reduce((acc, val) => {
      return acc + val;
    }, 0) / values.length
  );
}

export const perf = {
  now: () => {
    if (!hasPerfNow) return Date.now();
    return window.performance.now();
  },
  mark: (name = 'untitled') => {
    if (hasPerfMark) {
      return window.performance.mark(name);
    }
    return (marks[name] = perf.now());
  },
  measure: (
    name = 'untitled',
    start = 'untitled_start',
    end = 'untitled_end'
  ) => {
    if (hasPerfMark) {
      return window.performance.measure(name, start, end);
    }
    measures[name] = measures[name] || [];
    measures[name].push({
      duration: marks[end] - marks[start],
      start: marks[start],
      end: marks[end],
    });
  },
  clearMarks: (name) => {
    if (hasPerfMark) return window.performance.clearMarks(name);
    if (name) {
      delete marks[name];
      return;
    }
    marks = {};
  },
  clearMeasures: (name) => {
    if (hasPerfMark) return window.performance.clearMeasures(name);
    if (name) {
      delete measures[name];
      return;
    }
    measures = {};
  },
  getEntriesByName: (name = 'untitled') => {
    if (hasPerfMark) {
      return window.performance.getEntriesByName(name);
    }
    return measures[name] || [];
  },
  getEntryByName: (name = 'untitled') => {
    const entries = perf.getEntriesByName(name);
    return entries[entries.length - 1];
  },
  start: (name = 'untitled') => {
    return perf.mark(name + '_start');
  },
  end: (name = 'untitled') => {
    const e = perf.mark(name + '_end');
    perf.measure(name, name + '_start', name + '_end');
    return e;
  },
  duration: (name = 'untitled') => {
    return (perf.getEntryByName(name) || {}).duration;
  },
  mean: (name = 'untitled') => {
    return mean(perf.getEntriesByName(name).map((d) => d.duration));
  },
  sdev: (name = 'untitled') => {
    const entries = perf.getEntriesByName(name).map((d) => d.duration);
    const avg = mean(entries);
    const squareDiffs = entries.map((v) => Math.pow(v - avg, 2));
    return Math.sqrt(mean(squareDiffs));
  },
  percentile: (name = 'untitled', percent = 0) => {
    const values = perf
      .getEntriesByName(name)
      .map((d) => d.duration)
      .sort();
    const len = values.length;
    const index = Math.min(len - 1, Math.max(0, Math.floor(len * percent)));
    return values[index];
  },
  onFPS: (cb, interval = 1000) => {
    let prevTime = perf.now(),
      time = prevTime;
    let frames = 0;
    let fps = 0;
    let scalar = 1000 / interval;
    (function iterate() {
      frames++;
      time = perf.now();
      if (time >= prevTime + interval) {
        fps = ((frames * interval) / (time - prevTime)) * scalar;
        frames = 0;
        prevTime = time;
        if (cb) cb(fps, time);
      }
      (window.requestAnimationFrame || window.setTimeout)(iterate, 0);
    })();
  },
  log: (name = 'untitled', level = 'info') => {
    log('[perf]')[level](`${name} took ${perf.duration(name).toFixed(2)}ms`, {
      mean: perf.mean(name),
      sdev: perf.sdev(name),
      samples: perf.getEntriesByName(name).length,
      '95_perc': perf.percentile(name, 0.95),
    });
  },
};
export default perf;
