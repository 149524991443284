import {
  trackFetchError,
  trackTaskDeleted,
  trackTaskUpdated,
  trackTaskCreated,
  trackInterfaceOpenTask,
  trackInterfaceOpenCreateTaskModal,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import * as types from './action-types';
import fetch from '../lib/hmac-fetch';
import { AANG_URL, HEADERS_JSON } from '../strings.js';

export const postCreateTask = (data) => {
  return {
    type: types.POST_CREATE_TASK,
    data,
  };
};

export const successCreateTask = (data) => {
  return {
    type: types.SUCCESS_CREATE_TASK,
    data,
  };
};

export const errorCreateTask = (data) => {
  return {
    type: types.ERROR_CREATE_TASK,
    data,
  };
};

export const postUpdateTask = (data) => {
  return {
    type: types.POST_UPDATE_TASK,
    data,
  };
};

export const successUpdateTask = (data, update) => {
  return {
    type: types.SUCCESS_UPDATE_TASK,
    data,
    update,
  };
};

export const errorUpdateTask = (data) => {
  return {
    type: types.ERROR_UPDATE_TASK,
    data,
  };
};

export const postDeleteTask = (data) => {
  return {
    type: types.POST_DELETE_TASK,
    data,
  };
};

export const successDeleteTask = (data) => {
  return {
    type: types.SUCCESS_DELETE_TASK,
    data,
  };
};

export const errorDeleteTask = (data) => {
  return {
    type: types.ERROR_DELETE_TASK,
    data,
  };
};

export const showCreateTaskModal = (data) => {
  trackInterfaceOpenCreateTaskModal(data);
  return {
    type: types.SHOW_CREATE_TASK_MODAL,
    data,
  };
};

export const closeCreateTaskModal = (data) => {
  return {
    type: types.CLOSE_CREATE_TASK_MODAL,
    data,
  };
};

export const showTaskModal = (data) => {
  trackInterfaceOpenTask(data);
  return {
    type: types.SHOW_TASK_MODAL,
    data,
  };
};

export const closeTaskModal = (data) => {
  return {
    type: types.CLOSE_TASK_MODAL,
    data,
  };
};

export const showTaskModalConfirmationDelete = (data) => {
  return {
    type: types.SHOW_TASK_MODAL_CONFIRMATION_DELETE,
    data,
  };
};

export const closeTaskModalConfirmationDelete = (data) => {
  return {
    type: types.CLOSE_TASK_MODAL_CONFIRMATION_DELETE,
  };
};

/**
 * Submit task
 *
 * @access public
 * @param {object}  data
 * @param {string}  data.title
 * @param {boolean} data.recurring
 * @param {number}  data.cleaning_id
 * @param {string}  data.note
 * @param {string}  data.area
 * @param {number}  data.checkin_id
 * @param {number}  data.order
 * @returns {function} redux thunk
 */
export function submitTask(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(postCreateTask(data));
    return fetch(`${AANG_URL}/tasks`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Create task' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateTask(
            Object.assign(body, { _request: data._request }),
            data
          )
        );
        trackTaskCreated(body);
      })
      .catch((error) => {
        dispatch(errorCreateTask(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Submit update task
 *
 * @access public
 * @param {object}  data
 * @param {number}  data.id
 * @param {string}  data.title
 * @param {boolean} data.recurring
 * @param {number}  data.cleaning_id
 * @param {string}  data.note
 * @param {string}  data.area
 * @param {number}  data.checkin_id
 * @param {number}  data.order
 * @returns {function} redux thunk
 */
export function submitUpdateTask(data) {
  return (dispatch) => {
    dispatch(postUpdateTask(data));
    return fetch(`${AANG_URL}/tasks/${data.id}`, {
      method: 'PUT',
      headers: HEADERS_JSON,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update task ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateTask(body, data));
        trackTaskUpdated(body);
      })
      .catch((error) => {
        dispatch(errorUpdateTask(error));
        trackFetchError(error);
      });
  };
}

/**
 * Delete task
 *
 * @access public
 * @param {object}  data
 * @param {number}  data.id
 * @returns {function} redux thunk
 */
export function submitDeleteTask(data) {
  return (dispatch) => {
    dispatch(postDeleteTask(data));
    return fetch(`${AANG_URL}/tasks/${data.id}`, {
      method: 'DELETE',
      headers: HEADERS_JSON,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data,
            message: 'Delete task ' + data.id,
          });
        }
        return null;
      })
      .then(() => {
        dispatch(successDeleteTask(data));
        trackTaskDeleted(data);
      })
      .catch((error) => {
        dispatch(errorDeleteTask(error));
        trackFetchError(error);
      });
  };
}
