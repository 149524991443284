const HOUR_MILLIS = 3600000;
const DAY_MILLIS = HOUR_MILLIS * 24;

export const vendorsWithNoMinimums = (vendors) => {
  return vendors.filter((v) => !hasMinimum(v));
};

export const vendorMinimumType = (vendor) => {
  if (!vendor) return '';
  if (vendor.quantity_minimum > 0) {
    return 'quantity_minimum';
  }
  if (vendor.price_minimum > 0) {
    return 'price_minimum';
  }
  return '';
};

export const hasMinimum = (vendor) => {
  if (!vendor) return false;
  if (vendor.price_minimum) return true;
  if (vendor.quantity_minimum) return true;
};

export const hasNextDayShipping = (vendor) => {
  if (!vendor || !vendor.shipping) return false;
  return vendor.shipping.max_seconds <= DAY_MILLIS / 1000;
};

export const formatVendorMinimum = (vendor) => {
  const dollars = vendor.price_minimum / 100;
  switch (vendorMinimumType(vendor)) {
    case 'quantity_minimum':
      return `${vendor.quantity_minimum} items`;
    case 'price_minimum':
      return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    default:
      return '';
  }
};

export const default_vendor = {
  id: 0,
  price_minimum: 0,
  quantity_minimum: 0,
  shipping: {
    max_seconds: DAY_MILLIS * 5,
    min_seconds: DAY_MILLIS,
  },
};

export const getProductPriorityVendor = (product) => {
  let vendors = product.vendors || [default_vendor];
  if (!vendors.length) return default_vendor;
  if (vendors.length < 2) return vendors[0] || default_vendor;
};

export const getProductVendorForMinimums = (product, cart) => {
  let vendors = product.vendors || [default_vendor];
  if (!vendors.length) return default_vendor;
  if (vendors.length < 2) return vendors[0];
  let unmet_vendor_ids =
    cart._optimized_product_requisitions.unmet_minimums.map((m) => m.vendor_id);
  let match = vendors.filter((v) => {
    return unmet_vendor_ids.indexOf(v.id) > -1;
  })[0];
  if (match) return match;
  let all_vendor_ids = cart._optimized_product_requisitions.minimums.map(
    (m) => m.vendor_id
  );
  match = vendors.filter((v) => {
    return all_vendor_ids.indexOf(v.id) > -1;
  })[0];
  if (match) return match;
  return vendors[0];
};
