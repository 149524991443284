import React from 'react';
import PropTypes from 'prop-types';

import * as requisitionHelpers from '../../helpers/requisition-helpers';
import * as arrayHelpers from '../../helpers/array-helpers';
import './pending-shipments.css';

export default class PendingShipments extends React.PureComponent {
  displayUniqueShipmentVendors = (shipmentVendors) => {
    let shipmentVendorNames = shipmentVendors.map((sv) => sv.vendor_name);

    return shipmentVendorNames.filter((svn, i, a) => a.indexOf(svn) === i);
  };

  displayPendingShipments = () => {
    const { product_requisitions = [] } = this.props.openRequisition;
    let shipmentVendors = product_requisitions.map(
      (pr) => pr.shipmentSummaries
    );
    shipmentVendors = shipmentVendors.filter((sv) => sv);
    shipmentVendors = shipmentVendors.filter((sv) => sv.shipment_id === null);

    return this.displayUniqueShipmentVendors(
      arrayHelpers.flattenArray(shipmentVendors)
    );
  };

  formatShipments = (pendingShipments) => {
    return (
      <div className="pending-shipments">
        <h3
          className="section-header"
          style={{ 'text-transform': 'uppercase' }}
        >
          pending shipments
        </h3>
        <p className="product-shipping">
          {' '}
          From: <i> {pendingShipments} </i>
        </p>
      </div>
    );
  };

  render() {
    const pendingShipments = this.displayPendingShipments();
    const isProcessing = requisitionHelpers.isProcessing(
      this.props.openRequisition
    );

    if (isProcessing) {
      return (
        <div className="pending-shipments">
          <p className="requisition-item-note">Processing</p>
        </div>
      );
    } else if (pendingShipments.length > 0) {
      return this.formatShipments(pendingShipments);
    }
    return null;
  }
}

PendingShipments.propTypes = {
  openRequisition: PropTypes.shape({
    shipmentSummaries: PropTypes.shape({
      shipment_id: PropTypes.string,
      vendor_name: PropTypes.string,
    }),
  }).isRequired,
};
