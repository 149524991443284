import React from 'react';
import AuthCheck from '../components/auth-check.js';
import SettingsLocationPreferences from '../components/employees/settings-location-preferences.js';
import setTitle from '../decorators/set-title.js';

export class SettingsEmployeeDeliveryPreference extends React.Component {
  render() {
    return (
      <div className="settings-panel">
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <SettingsLocationPreferences />
      </div>
    );
  }
}

SettingsEmployeeDeliveryPreference = setTitle((props) => {
  return 'Delivery Settings';
})(SettingsEmployeeDeliveryPreference);

export default SettingsEmployeeDeliveryPreference;
