import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar.js';
import { formatEmployeeName, formatDateTime } from '../../lib/formatters.js';
import './comment-employee.css';

export default class CommentEmployee extends React.PureComponent {
  formatName = () => {
    return formatEmployeeName(this.props.comment.commenter);
  };

  formatDate = () => {
    return formatDateTime(this.props.comment.updated_at);
  };

  render() {
    return (
      <div className="cleaning-comment-container employee">
        <div className="cleaning-comment commenter-avatar employee-avatar">
          <Avatar item={this.props.comment.commenter} width={24} height={24} />
        </div>
        <div className="cleaning-comment comment-details-container">
          <div className="cleaning-comment name-date">
            <div className="cleaning-comment commenter-name microcopy">
              {this.formatName()}
            </div>
            <div className="cleaning-comment date microcopy">
              {this.formatDate()}
            </div>
          </div>
          <div className="cleaning-comment body">{this.props.comment.body}</div>
        </div>
      </div>
    );
  }
}

CommentEmployee.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    commentable_id: PropTypes.number.isRequired,
    commentable_type: PropTypes.string.isRequired,
    commenter_id: PropTypes.number.isRequired,
    commenter_type: PropTypes.string.isRequired,
    commenter: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};
