import * as types from '../actions/action-types.js';

export const empty = {
  items: [],
  updating: [],
  creating: [],
  requesting: [],
  errors: [],
};

export const initial = { ...empty };

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_PROMOTED_BRANDS:
      return Object.assign({}, state, {
        requesting: state.requesting.concat({
          _request: types.GET_PROMOTED_BRANDS,
        }),
      });
    case types.SUCCESS_GET_PROMOTED_BRANDS:
      return Object.assign({}, state, {
        items: action.data,
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PROMOTED_BRANDS;
        }),
      });
    case types.ERROR_GET_PROMOTED_BRANDS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PROMOTED_BRANDS;
        }),
        errors: state.errors.concat({
          _request: types.GET_PROMOTED_BRANDS,
          data: action.data,
        }),
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
