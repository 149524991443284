import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Header, List, Loader, Icon } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import ScheduledRequisitions from './scheduled-requisitions.js';
import {
  humanInterval,
  nextSourceOccurrences,
} from '../../helpers/scheduled-requisition-helpers.js';
import { asSentence } from '../../lib/formatters.js';
import cookies from '../../lib/encrypted-cookies.js';
import { TIMELINE_VIEW_COOKIE } from '../../strings.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/list.min.css';
import './recurring-requisitions.css';

const sortByStartAt = (a, b) => {
  return a.start_at > b.start_at ? 1 : -1;
};

export class RecurringRequisitions extends React.PureComponent {
  renderItem = (scheduledRequisition) => {
    return (
      <List.Item key={scheduledRequisition.id}>
        <List.Header as="h4">{scheduledRequisition.name}</List.Header>
        <List.Content>
          {scheduledRequisition.archived && (
            <p className="light-gray-text subheader-item">Archived</p>
          )}
          {!scheduledRequisition.archived && (
            <p className="gray-text subheader-item bold-text">
              {asSentence(humanInterval(scheduledRequisition))}
            </p>
          )}
          <Link
            to={`/orders/scheduled/${scheduledRequisition.id}`}
            className="recurring-requisition-link primary"
          >
            View Next Order &raquo;
          </Link>
        </List.Content>
      </List.Item>
    );
  };

  pushAndSetCookie = () => {
    cookies.remove(TIMELINE_VIEW_COOKIE);
    browserHistory.push('/calendar');
  };

  render() {
    return (
      <div className="recurring-requisitions">
        <div style={{ textAlign: 'right' }}>
          <Button icon primary onClick={this.pushAndSetCookie}>
            <Icon name="calendar alternate" />
            Calendar View
          </Button>
        </div>
        {this.props.scheduledRequisitions.length > 0 && (
          <div className="recurring-requisitions-existent-state">
            <div className="recurring-scheduled-requisitions-container flex-wrapper">
              <div className="recurring-requisitions-container">
                <div className="recurring-requisitions-header-container">
                  <Header as="h2">Your Recurring Orders</Header>
                  <Button secondary size="mini">
                    <Link to="/orders/scheduled/new" className="">
                      Create a Recurring Order
                    </Link>
                  </Button>
                </div>
                {this.props.isRequesting && <Loader active inline="centered" />}
                {!this.props.isRequesting && (
                  <List relaxed>
                    {this.props.scheduledRequisitions
                      .sort(sortByStartAt)
                      .map(this.renderItem)}
                  </List>
                )}
              </div>
              <div className="scheduled-requisitions-container">
                <ScheduledRequisitions isRequesting={this.props.isRequesting} />
              </div>
            </div>
          </div>
        )}
        {!this.props.scheduledRequisitions.length && (
          <div className="recurring-requisitions-empty-state">
            <Header as="h4">You currently have no recurring orders.</Header>
            <Button primary>
              <Link to="/orders/scheduled/new" className="">
                Create a Recurring Order
              </Link>
            </Button>
            <p style={{ marginTop: '1rem' }}>
              You can schedule an order to automatically arrive on a regular
              interval.
            </p>
            <List ordered>
              <List.Item>
                Create a scheduled order and choose a start date.
              </List.Item>
              <List.Item>
                Add items to the scheduled order and adjust quantities.
              </List.Item>
              <List.Item>
                Approve and edit the scheduled order before it is placed.
              </List.Item>
            </List>
          </div>
        )}
      </div>
    );
  }
}

RecurringRequisitions.propTypes = {
  scheduledRequisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      location_id: PropTypes.number.isRequired,
      approve_automatically: PropTypes.bool.isRequired,
      instructions: PropTypes.string,
      shipping_address: PropTypes.string,
      shipping_address_number: PropTypes.string,
      shipping_business: PropTypes.string,
      shipping_care: PropTypes.string,
      shipping_city: PropTypes.string,
      shipping_name: PropTypes.string,
      shipping_state: PropTypes.string,
      shipping_zip: PropTypes.string,
      start_at: PropTypes.string,
      status: PropTypes.string,
      rrule: PropTypes.string,
    })
  ).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

RecurringRequisitions.defaultProps = {
  isRequesting: true,
};

function mapStateToProps(state) {
  return {
    scheduledRequisitions: nextSourceOccurrences(
      state.scheduledRequisitions.items
    ),
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return prev.scheduledRequisitions.items === next.scheduledRequisitions.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(RecurringRequisitions);
