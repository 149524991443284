import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import browserHistory from '../../lib/history.js';
import brandPropType from '../../propTypes/brand.js';
import Loader from '../loader.js';
import ProductListRow from '../products/item-list-row.js';
import PromotedBrandsDescription from './promoted-brands-description.js';
import './promoted-brands.css';

const MAX_DISPLAY = 7;

const SORT_UPDATED_DESC = function (a, b) {
  if (a.updated_at > b.updated_at) return -1;
  if (b.updated_at > a.updated_at) return 1;
  return 0;
};

const goToAllBrandProducts = (brandId) => {
  browserHistory.push(`/supplies/catalog-search?brand_id=${brandId}`);
};

const PromotedBrandsRow = (props) => (
  <div className="promoted-brands__row">
    <div className="promoted-brands__row-header">
      <h4>{props.brand.name}</h4>
      {props.brand.products && props.brand.products.length > MAX_DISPLAY && (
        <Link to={`/supplies/catalog-search?brand_id=${props.brand.id}`}>
          See All
        </Link>
      )}
    </div>
    <div className="promoted-brands__row-content">
      <div className="promoted-brands__row-left">
        {(!props.brand.products || !props.brand.products.length) && <Loader />}
        {props.brand.products && props.brand.products.length && (
          <ProductListRow
            items={props.brand.products.sort(SORT_UPDATED_DESC)}
            maxDisplay={MAX_DISPLAY}
            onClickViewAll={() => goToAllBrandProducts(props.brand.id)}
          />
        )}
      </div>
      <div className="promoted-brands__row-right">
        <img
          className="promoted-brands__image"
          src={`${props.brand.images_attachments[0].original}`}
          alt={props.brand.name}
        />
        <PromotedBrandsDescription brand={props.brand} />
      </div>
    </div>
  </div>
);

PromotedBrandsRow.propTypes = {
  brand: brandPropType,
  isLoading: PropTypes.bool.isRequired,
};

PromotedBrandsRow.defaultProps = {
  isLoading: true,
};

export default PromotedBrandsRow;
