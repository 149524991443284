import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import OfficeluvCategoryContainer from '../components/supplies/officeluv-category-container.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import {
  openProductCategory,
  closeProductCategory,
} from '../actions/product-category-actions.js';
import './product-category.css';

export class ProductCategoryRoute extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openProductCategory({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate() {
    const id = parseInt(this.props.match.params.id, 10);
    if (this.props.openCategory.id !== id) {
      this.props.actions.openProductCategory({ id });
    }
  }

  componentWillUnmount() {
    this.props.actions.closeProductCategory();
  }

  render() {
    return (
      <div className="">
        <MountAtPageTop />
        <AuthCheck />
        <NavBarAttendant />
        <div className="product-category">
          <OfficeluvCategoryContainer />
        </div>
      </div>
    );
  }
}

ProductCategoryRoute = setTitle((props) => {
  return props.openCategory.name;
})(ProductCategoryRoute);

ProductCategoryRoute.propTypes = {
  openCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    openProductCategory: PropTypes.func.isRequired,
    closeProductCategory: PropTypes.func.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    openCategory: state.productCategories.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openProductCategory,
        closeProductCategory,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoryRoute);
