import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PromotedBrandsRow from './promoted-brands-row.js';
import ProductListRowLoading from '../products/item-list-row-loading.js';
import { PROMOTED_BRANDS_LINK } from '../../strings.js';

const FILTER_WITH_CONTENT = function (brand) {
  return !!brand.products.length && !!brand.images_attachments.length;
};

const SORT_UPDATED_DESC = function (a, b) {
  if (a.updated_at > b.updated_at) return -1;
  if (b.updated_at > a.updated_at) return 1;
  return 0;
};

export class PromotedBrandsContainer extends React.PureComponent {
  _renderBrandRows() {
    return this.props.promotedBrands.map((brand, i) => (
      <PromotedBrandsRow
        key={i}
        brand={brand}
        isLoading={this.props.isRequesting}
      />
    ));
  }

  render() {
    return (
      <div className="promoted-brands variable-width-column">
        <div className="promoted-brands__header">
          <h2>{PROMOTED_BRANDS_LINK.label}</h2>
        </div>
        {this.props.isRequesting && <ProductListRowLoading size={10} />}
        {!this.props.isRequesting && this._renderBrandRows()}
      </div>
    );
  }
}

PromotedBrandsContainer.propTypes = {
  promotedBrands: PropTypes.arrayOf(PropTypes.object),
};

export function mapStateToProps(state) {
  return {
    promotedBrands: state.promotedBrands.items
      .filter(FILTER_WITH_CONTENT)
      .sort(SORT_UPDATED_DESC),
    isRequesting: !!state.promotedBrands.requesting.length,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.promotedBrands.items === next.promotedBrands.items &&
    prev.promotedBrands.requesting === next.promotedBrands.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  PromotedBrandsContainer
);
