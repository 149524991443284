import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Button, Segment } from 'semantic-ui-react';
import {
  doCloseInvoice,
  doGetInvoice,
} from '../../actions/invoices-actions.js';

import './modal-common.css';
import './modal-invoice.css';

export class ModalInvoice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 500,
      file: {},
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState(() => {
      return { windowHeight: window.innerHeight };
    });
  }

  componentDidMount() {
    if (this.props.open.id) {
      this.props.actions.doGetInvoice(this.props.open.id).then((result) => {
        if (!result) return;

        this.setState(() => {
          return { file: result };
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open.id && this.props.open.id) {
      this.props.actions.doGetInvoice(this.props.open.id).then((result) => {
        if (!result) return;

        this.setState(() => {
          return { file: result };
        });
      });
    }
  }

  onRequestClose = () => {
    this.setState(() => {
      return { file: {} };
    });
    this.props.actions.doCloseInvoice();
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleDownload = () => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        this.state.file.blob,
        this.getFileName()
      );
    }
  };

  getFileName = () => {
    return `officeluv-invoice-${this.props.open.id}.pdf`;
  };

  getInvoiceNumber = () => {
    return (
      this.props.open.invoice_number ||
      this.props.open.recurly_id ||
      this.props.open.quickbooks_id ||
      this.props.open.stripe_id
    );
  };

  render() {
    if (!this.props.open || !this.props.open.id) {
      return null;
    }
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!this.props.open.id}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Invoice Modal">
        <div className="modal-invoice-header">
          <div className="invoice-form-header-container">
            <h2 className="your-title">Invoice #{this.getInvoiceNumber()}</h2>
          </div>
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
        </div>
        <div className="modal-invoice-pdf">
          <Segment basic loading={this.props.isRequesting}>
            <object
              height={this.state.windowHeight - 250}
              data={this.state.file.url}
              type="application/pdf"
              aria-label="modal-invoice"></object>
          </Segment>
        </div>
        <div className="modal-invoice-save-container">
          <Button primary>
            <a
              className="modal-invoice-save-pdf primary download"
              href={this.state.file.url}
              onClick={this.handleDownload}
              target="_blank"
              rel="noopener noreferrer"
              download={this.getFileName()}>
              Save Invoice
            </a>
          </Button>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.invoices.open,
    isRequesting: state.invoices.isRequesting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCloseInvoice,
        doGetInvoice,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.invoices.open === next.invoices.open &&
    prev.invoices.isRequesting === next.invoices.isRequesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalInvoice);
