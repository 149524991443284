import React from 'react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import InsightsBudgets from '../components/insights/insights-budgets.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';

export class InsightsBudgetsRoute extends React.PureComponent {
  render() {
    return (
      <div className="">
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <div className="variable-width-column">
          <InsightsBudgets />
        </div>
      </div>
    );
  }
}

InsightsBudgetsRoute = setTitle(() => {
  return 'OfficeLuv Insights';
})(InsightsBudgetsRoute);

export default InsightsBudgetsRoute;
