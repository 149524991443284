import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-attendant.js';
import setTitle from '../decorators/set-title.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import CheckoutConfirmContainer from '../components/requisitions/checkout-confirm-container.js';
import { CartPropType } from '../helpers/cart-helpers.js';
import { FILTER_IS_SUBMITTING, FILTER_IS_LOADING } from '../ducks/carts.js';

export class CheckoutConfirm extends React.PureComponent {
  isAuthorized = () => {
    if (this.props.isLoading) return true;

    return (
      !this.props.cart.submitted_at && !!this.props.cart.awaiting_approval_at
    );
  };

  render() {
    return (
      <div>
        <MountAtPageTop />
        <AuthCheck
          customAuthorized={this.isAuthorized()}
          redirect={() => browserHistory.push('/supplies/orders')}
          redirectConfirmation={() =>
            'You are not authorized to view this page'
          }
        />
        <NavBar />
        <CheckoutConfirmContainer />
      </div>
    );
  }
}

CheckoutConfirm.propTypes = {
  cart: CartPropType,
  isLoading: PropTypes.bool.isRequired,
};

CheckoutConfirm = setTitle(() => {
  return 'Order Awaiting Approval';
})(CheckoutConfirm);

export function mapStateToProps(state) {
  return {
    cart: state.carts.open,
    isLoading:
      state.carts.requesting.filter(FILTER_IS_SUBMITTING).length > 0 ||
      state.carts.requesting.filter(FILTER_IS_LOADING).length > 0 ||
      state.carts.open.id === 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.requesting === next.carts.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CheckoutConfirm
);
