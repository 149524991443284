import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import classnames from 'classnames';
import { Message } from 'semantic-ui-react';
import { dismissConfirmation } from '../actions/confirmations-actions.js';
import {
  CONFIRMATION_TIMEOUT_MS,
  HOVER_CONFIRMATION_TIMEOUT_MS,
} from '../strings.js';
import 'semantic-ui-css/components/message.min.css';

export class Confirmation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
    };
  }

  componentDidMount() {
    this.timeout_fadeIn = window.setTimeout(this.triggerFadeIn, 500);
    this.setTimeoutDismiss();
  }

  setTimeoutDismiss = () => {
    this.timeout_triggerDismiss &&
      window.clearTimeout(this.timeout_triggerDismiss);
    this.timeout_triggerDismiss = window.setTimeout(
      this.triggerDismiss,
      CONFIRMATION_TIMEOUT_MS
    );
  };

  componentWillUnmount() {
    this.timeout_triggerDismiss &&
      window.clearTimeout(this.timeout_triggerDismiss) &&
      this.props.actions.dismissConfirmation();
    this.timeout_fadeIn && window.clearTimeout(this.timeout_fadeIn);
    this.timeout_dismiss && window.clearTimeout(this.timeout_dismiss);
  }

  handleClick = () => {
    this.timeout_triggerDismiss &&
      window.clearTimeout(this.timeout_triggerDismiss);
    this.triggerDismiss();
    if (this.props.item.link) {
      browserHistory.push(this.props.item.link);
    }
  };

  triggerDismiss = () => {
    this.triggerFadeOut();
    this.timeout_dismiss = window.setTimeout(
      this.props.actions.dismissConfirmation,
      1000
    );
  };

  triggerFadeIn = () => {
    this.setState(Object.assign({}, this.state, { fadeIn: true }));
  };

  triggerFadeOut = () => {
    this.setState(Object.assign({}, this.state, { fadeIn: false }));
  };

  pauseTimer = () => {
    this.timeout_triggerDismiss &&
      window.clearTimeout(this.timeout_triggerDismiss);
  };

  resetTimer = () => {
    this.timeout_triggerDismiss &&
      window.clearTimeout(this.timeout_triggerDismiss);
    this.timeout_triggerDismiss = window.setTimeout(
      this.triggerDismiss,
      HOVER_CONFIRMATION_TIMEOUT_MS
    );
  };

  render() {
    return (
      <Message
        floating
        onMouseLeave={this.resetTimer}
        onMouseEnter={this.pauseTimer}
        onClick={this.handleClick}
        info={this.props.item.type === 'info'}
        error={this.props.item.type === 'error'}
        success={this.props.item.type === 'success'}
        className={classnames('confirmation', {
          'confirmation-fadein': this.state.fadeIn,
        })}
        content={this.props.item.message}
      />
    );
  }
}

Confirmation.propTypes = {
  item: PropTypes.shape({
    message: PropTypes.string.isRequired,
    link: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    dismissConfirmation: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        dismissConfirmation,
      },
      dispatch
    ),
  };
}

function areStatesEqual() {
  return true;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Confirmation);
