import React from 'react';
import setTitle from '../decorators/set-title.js';
import './example-404.css';

class FourOFour extends React.Component {
  render() {
    return (
      <div className="four-oh-four">
        <div className="four-oh-four-wrap">
          <h1 className="four-oh-four-title">Not Found</h1>
          <p className="four-oh-four-body">
            The page you requested: &nbsp;
            <code>{this.props.location.pathname}</code>&nbsp; does not exist.
          </p>
          <p>
            But you can always&nbsp;
            <a href="/" className="tertiary-link" rel="home">
              go home
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default setTitle((props) => {
  return 'Page not found: ' + props.location.pathname;
})(FourOFour);
