import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import InsightsControls from './controls.js';
import CustomerPurchaseLineGraph from './customer-purchase-line-graph.js';
import BudgetCodeProducts from './budget-code-products.js';
import {
  doGetLocationDataView,
  openDataViewRange,
  openDataViewLocations,
} from '../../actions/data-view-actions.js';
import './insights-budget-code.css';

const DATA_VIEWS = [
  'customer_purchase_line_item_budget_code_dates',
  'customer_purchase_budget_code_dates',
];

export class InsightsBudgetCode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeThrottler);
    this._refreshData({
      locations: this.props.viewingLocations,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    });
  }

  componentWillUnmount() {
    this.resizeTimeout && window.clearTimeout(this.resizeTimeout);
    window.removeEventListener('resize', this.resizeThrottler);
  }

  componentDidUpdate(prevProps) {
    if (
      this._haveLocationsUpdated(prevProps) ||
      this._haveDatesUpdated(prevProps) ||
      this._hasBudgetCodeUpdated(prevProps)
    ) {
      this._refreshData({
        locations: this.props.viewingLocations,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }

  _hasBudgetCodeUpdated = (prevProps) => {
    return this.props.budgetCode.id !== prevProps.budgetCode.id;
  };

  _haveLocationsUpdated = (prevProps) => {
    return (
      this.props.viewingLocations.map((l) => l.id).join('') !==
        prevProps.viewingLocations.map((l) => l.id).join('') ||
      this.props.viewingLocations.map((l) => l.updated_at).join('') !==
        prevProps.viewingLocations.map((l) => l.updated_at).join('')
    );
  };

  _haveDatesUpdated = (prevProps) => {
    return (
      this.props.startDate.toJSON() !== prevProps.startDate.toJSON() ||
      this.props.endDate.toJSON() !== prevProps.endDate.toJSON()
    );
  };

  _refreshData = ({ locations, startDate, endDate }) => {
    let start = new Date(startDate.startOf('day')).toJSON();
    let end = new Date(endDate.endOf('day')).toJSON();
    locations.map((location) => {
      DATA_VIEWS.map((view) => {
        return this.props.actions.doGetLocationDataView({
          location_id: location.id,
          view,
          start,
          end,
        });
      });
      return location;
    });
  };

  _onChangeDates = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
  };

  _onChangeLocations = (locations) => {
    this.props.actions.openDataViewLocations(locations);
  };

  dataReducer = (acc, val) => {
    if (val.budget_code_id !== this.props.budgetCode.id) {
      acc.push({
        interval: val.submitted_date,
        lineValue: 0,
      });
      return acc;
    }
    acc.push({
      interval: val.submitted_date,
      lineValue: val.customer_purchase_line_items_total_spend,
    });
    return acc;
  };

  render() {
    return (
      <div className="insights-budget-code">
        <InsightsControls
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          openLocations={this.props.viewingLocations}
          windowWidth={this.state.windowWidth}
          onChangeDates={this._onChangeDates}
          onChangeLocations={this._onChangeLocations}
        />
        <div className="insights-budget-code-chart">
          <CustomerPurchaseLineGraph
            windowWidth={this.state.windowWidth}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            locations={this.props.viewingLocations}
            budgetCode={this.props.budgetCode}
            dataViewName={DATA_VIEWS[1]}
            dataReducer={this.dataReducer}
          />
        </div>
        <div className="insights-budget-code-products">
          <BudgetCodeProducts
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            locations={this.props.viewingLocations}
            budgetCode={this.props.budgetCode}
          />
        </div>
      </div>
    );
  }
}

InsightsBudgetCode.propTypes = {
  viewingLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  budgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  actions: PropTypes.shape({
    doGetLocationDataView: PropTypes.func.isRequired,
    openDataViewRange: PropTypes.func.isRequired,
    openDataViewLocations: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    viewingLocations: state.dataViews.openLocations.length
      ? state.dataViews.openLocations
      : [state.locations.open],
    startDate: state.dataViews.openRange.startDate,
    endDate: state.dataViews.openRange.endDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetLocationDataView,
        openDataViewRange,
        openDataViewLocations,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsBudgetCode);
