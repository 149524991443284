import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import * as queryParser from '../lib/query-parser.js';
import AuthCheck from '../components/auth-check.js';
import NavBar from '../components/nav/navbar-attendant.js';
import setTitle from '../decorators/set-title.js';
import CheckoutPage from '../components/requisitions/checkout-page.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import {
  doGetCart,
  FILTER_IS_LOADING,
  FILTER_IS_SUBMITTING,
} from '../ducks/carts.js';

export class Checkout extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Checkout Page.', 'Marketplace');
    if (this.props.cart.id) {
      this.props.actions.doGetCart(this.props.cart);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cart.id !== prevProps.cart.id && this.props.cart.id) {
      this.props.actions.doGetCart(this.props.cart);
    }
  }

  isAuthorized() {
    if (!this.props.queryParams.cart_id) return true;
    if (this.props.isRequesting) return true;

    return parseInt(this.props.queryParams.cart_id, 10) === this.props.cart.id;
  }

  redirect = () => {
    this.props.queryParams.cart_id
      ? browserHistory.push(
          `/supplies/orders/${this.props.queryParams.cart_id || 0}`
        )
      : browserHistory.push('/supplies/orders');
  };

  render() {
    return (
      <div>
        <MountAtPageTop />
        <AuthCheck
          customAuthorized={this.isAuthorized()}
          redirect={this.redirect}
          redirectConfirmation={() => 'Your order has already been submitted'}
        />
        <NavBar />
        <CheckoutPage />
      </div>
    );
  }
}

Checkout = setTitle(() => {
  return 'Checkout';
})(Checkout);

Checkout.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    doGetCart: PropTypes.func.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  queryParams: PropTypes.shape({
    cart_id: PropTypes.string,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  const cart = state.carts.open;
  return {
    cart: { ...cart, location_id: state.locations.open.id },
    isRequesting:
      state.carts.requesting
        .filter(FILTER_IS_LOADING)
        .filter(FILTER_IS_SUBMITTING).length > 0 || state.carts.open.id === 0,
    queryParams: queryParser.parse(props.location.search),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetCart,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open.id === next.carts.open.id &&
    prev.locations.open.id === next.locations.open.id &&
    prev.carts.requesting === next.carts.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Checkout);
