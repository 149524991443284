export const filterProductsByParentCategories = (
  listItems,
  parentCategories
) => {
  return listItems.filter((item) => {
    return item.product?.product_category_ids?.some((id) =>
      parentCategories.includes(id)
    );
  });
};
