import * as types from '../actions/action-types.js';
import { PRODUCT_MODAL_TABS } from '../strings.js';

const initialQuery = window.location.search.match(/[&|?]+product=([0-9]+)/);

export const empty = {
  items: [],
  updating: [],
  creating: [],
  open: { id: 0 },
  requesting: [],
  errors: [],
  catalog_searches: [],
  catalog_searches_initialized: false,
  in_location_lists: [],
  in_catalog: [],
  initialProductModalTab: PRODUCT_MODAL_TABS.TAB_CART,
};
export const initial = Object.assign({}, empty, {
  open: {
    id: (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id,
  },
});

export default (state = initial, action) => {
  let items = [];
  let in_location_lists = state.in_location_lists;
  let in_catalog = state.in_catalog;
  switch (action.type) {
    case types.CREATE_LOCATION_CATALOG_PRODUCT:
    case types.SUCCESS_CREATE_LOCATION_CATALOG_PRODUCT:
      in_catalog = in_catalog.slice();
      in_catalog[action.data.product_id] = action.data.id;
      return {
        ...state,
        in_catalog,
      };
    case types.ERROR_CREATE_LOCATION_CATALOG_PRODUCT:
    case types.DELETE_LOCATION_CATALOG_PRODUCT:
    case types.SUCCESS_DELETE_LOCATION_CATALOG_PRODUCT:
      in_catalog = in_catalog.slice();
      in_catalog[action.data.product_id] = null;
      return {
        ...state,
        in_catalog,
      };
    case types.CREATE_LOCATION_LIST_PRODUCT:
    case types.SUCCESS_CREATE_LOCATION_LIST_PRODUCT:
      in_location_lists = in_location_lists.slice();
      in_location_lists[action.data.product_id] = (
        in_location_lists[action.data.product_id] || []
      )
        .filter((llpid) => llpid !== action.data.location_list_id)
        .concat([action.data.location_list_id]);
      return {
        ...state,
        in_location_lists,
      };
    case types.ERROR_CREATE_LOCATION_LIST_PRODUCT:
    case types.SUCCESS_DELETE_LOCATION_LIST_PRODUCT:
      in_location_lists = in_location_lists.slice();
      in_location_lists[action.data.product_id] = (
        in_location_lists[action.data.product_id] || []
      ).filter((llid) => llid !== action.data.location_list_id);
      return {
        ...state,
        in_location_lists,
      };
    case types.SUCCESS_LOCATION_LIST_PRODUCTS:
      items = state.items.concat([]);
      in_location_lists = in_location_lists.slice();
      in_catalog = in_catalog.slice();
      action.data.map((llp) => {
        in_location_lists[llp.product_id] = (
          in_location_lists[llp.product_id] || []
        )
          .filter((llid) => llid !== llp.location_list_id)
          .concat([llp.location_list_id]);
        in_catalog[llp.product.id] = llp.product.catalog_product_id;
        return (items[llp.product.id] = llp.product);
      });
      return Object.assign({}, state, {
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        items,
        in_location_lists,
        in_catalog,
      });
    case types.SWITCH_LOCATION:
      return Object.assign({}, state, {
        items: empty.items,
        in_location_lists: empty.in_location_lists,
        in_catalog: empty.in_catalog,
      });
    case types.CREATE_CATALOG_SEARCH:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((cs) => cs._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_CATALOG_SEARCH:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (cs) => cs._request !== action.data._request
        ),
        catalog_searches: [action.data].concat(
          state.catalog_searches
            .filter((cs) => cs.id !== action.data.id)
            .filter((cs) => cs.text_searched !== action.data.text_searched)
        ),
      });
    case types.ERROR_CREATE_CATALOG_SEARCH:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (cs) => cs._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_CATALOG_SEARCHES:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
        catalog_searches_initialized: true,
      });
    case types.SUCCESS_GET_CATALOG_SEARCHES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cs) => cs._request !== types.GET_CATALOG_SEARCHES
        ),
        catalog_searches: state.catalog_searches.concat(action.data),
      });
    case types.ERROR_GET_CATALOG_SEARCHES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cs) => cs._request !== types.GET_CATALOG_SEARCHES
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_LOCATION_CACHED_PRODUCT:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_GET_LOCATION_CACHED_PRODUCT:
      items = state.items.concat([]);
      items[action.data.id] = action.data;
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cs) => cs._request !== action.data._request
        ),
        open: state.open.id === action.data.id ? action.data : state.open,
        items,
      });
    case types.ERROR_GET_LOCATION_CACHED_PRODUCT:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cs) => cs._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.OPEN_PRODUCT:
      return Object.assign({}, state, {
        open:
          state.items.filter((i) => i.id === action.data.id)[0] || action.data,
        initialProductModalTab: action.data.initialProductModalTab
          ? action.data.initialProductModalTab
          : state.initialProductModalTab,
      });
    case types.CLOSE_PRODUCT:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.SET_INITIAL_PRODUCT_MODAL_TAB:
      return {
        ...state,
        initialProductModalTab: action.data,
      };
    case types.UNSET_INITIAL_PRODUCT_MODAL_TAB:
      return {
        ...state,
        initialProductModalTab: empty.initialProductModalTab,
      };
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
