import LogRocket from 'logrocket';
import cookies from '../lib/encrypted-cookies.js';
import * as types from './action-types';
import {
  trackFetchError,
  trackEmployeePasswordUpated,
} from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import { doGetEmployeeSelf } from './employee-actions.js';
import { doGetStaffSelf } from './staff-actions.js';
import {
  HEADERS_JSON,
  AUTH_COOKIE,
  LOCATION_COOKIE,
  LAST_APP_COOKIE,
  ADMIN_VI_URL_PASSWORDS,
  LOGROCKET_ENABLED,
} from '../strings.js';

export const setPasswordResetToken = (data) => {
  return {
    type: types.SET_PASSWORD_RESET_TOKEN,
    data,
  };
};

export const signOut = () => {
  cookies.remove(AUTH_COOKIE);
  cookies.remove(LOCATION_COOKIE);
  cookies.remove(LAST_APP_COOKIE);
  return {
    type: types.SIGN_OUT,
  };
};

export const postSignIn = (credentials) => {
  return {
    type: types.POST_SIGN_IN,
    credentials,
  };
};

export const errorSignIn = (data) => {
  return {
    type: types.ERROR_SIGN_IN,
    data,
  };
};

const successfulSignIn = (data) => {
  cookies.set(AUTH_COOKIE, data, { expires: 30 });
  if (LOGROCKET_ENABLED) {
    LogRocket.identify(data.hmac.username);
  }
  return {
    type: types.SUCCESS_SIGN_IN,
    data,
  };
};

export const postForgotPassword = (credentials) => {
  return {
    type: types.POST_FORGOT_PASSWORD,
    credentials,
  };
};

const errorForgotPassword = (data) => {
  return {
    type: types.ERROR_FORGOT_PASSWORD,
    data,
  };
};

const successfulForgotPassword = (data) => {
  return {
    type: types.SUCCESS_FORGOT_PASSWORD,
    data,
  };
};

export const postResetPassword = (credentials) => {
  return {
    type: types.POST_RESET_PASSWORD,
    credentials,
  };
};

export const postChangePassword = (credentials) => {
  return {
    type: types.POST_CHANGE_PASSWORD,
    credentials,
  };
};

const errorResetPassword = (data) => {
  return {
    type: types.ERROR_RESET_PASSWORD,
    data,
  };
};

const errorChangePassword = (data) => {
  return {
    type: types.ERROR_CHANGE_PASSWORD,
    data,
  };
};

const successfulResetPassword = (data) => {
  return {
    type: types.SUCCESS_RESET_PASSWORD,
    data,
  };
};

const successfulChangePassword = (data) => {
  return {
    type: types.SUCCESS_CHANGE_PASSWORD,
    data,
  };
};

export const doGetSelf = () => {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (auth.actor.type.match(/employee/i)) {
      return dispatch(doGetEmployeeSelf());
    }
    if (auth.actor.type.match(/staff/i)) {
      return dispatch(doGetStaffSelf());
    }
  };
};

export const doForgotPassword = (credentials) => {
  return (dispatch) => {
    dispatch(postForgotPassword(credentials));

    return fetch(`${ADMIN_VI_URL_PASSWORDS}/employees/reset_password`, {
      method: 'POST',
      headers: HEADERS_JSON,
      body: JSON.stringify({ email: credentials.email }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: {},
            message: 'Invalid credentials',
          });
        }
        return response.json();
      })
      .then((body) => dispatch(successfulForgotPassword(body || {})))
      .catch((error) => {
        dispatch(errorForgotPassword({ message: error.toString() }));
      });
  };
};

export const doResetPassword = (credentials) => {
  return (dispatch, getState) => {
    dispatch(postResetPassword(credentials));
    const { auth } = getState();
    return fetch(
      `${ADMIN_VI_URL_PASSWORDS}/employees/set_password_with_token`,
      {
        method: 'POST',
        headers: HEADERS_JSON,
        body: JSON.stringify({
          reset_password_token: auth.resetToken,
          password: credentials.password,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data: {}, message: 'Update password' });
        }
        return response.json();
      })
      .then((body) => dispatch(successfulResetPassword(body || {})))
      .catch((error) => {
        dispatch(errorResetPassword({ message: error.toString() }));
      });
  };
};

export const doChangePassword = (credentials) => {
  return (dispatch) => {
    dispatch(postChangePassword(credentials));
    return fetch(`${ADMIN_VI_URL_PASSWORDS}/employees/update_password`, {
      method: 'POST',
      headers: HEADERS_JSON,
      body: JSON.stringify({
        email: credentials.email,
        old_password: credentials.passwordExisting,
        password: credentials.passwordNew,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data: {}, message: 'Update password' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successfulChangePassword(body || {}));
        trackEmployeePasswordUpated(body);
      })
      .catch((error) => {
        dispatch(errorChangePassword(error));
        trackFetchError(error);
      });
  };
};

export function doSignIn(credentials, options = { bubble: false }) {
  return (dispatch) => {
    dispatch(postSignIn(credentials));
    return fetch(`${ADMIN_VI_URL_PASSWORDS}/employees/login`, {
      method: 'POST',
      headers: HEADERS_JSON,
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (response.status !== 200) {
          return doSignInStaff(credentials, { bubble: true })(dispatch);
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successfulSignIn(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorSignIn(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doSignInStaff(credentials, options = { bubble: false }) {
  return (dispatch) => {
    dispatch(postSignIn(credentials));
    return fetch(`${ADMIN_VI_URL_PASSWORDS}/staffs/login`, {
      method: 'POST',
      headers: HEADERS_JSON,
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data: {}, message: 'Sign in' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successfulSignIn(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorSignIn(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
