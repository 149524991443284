import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import Avatar from '../../avatar.js';
import Textarea from '../../forms/input-textarea.js';
import {
  submitUpdateReview,
  submitReview,
} from '../../../actions/review-actions.js';
import { formatStaffName } from '../../../lib/formatters.js';
import star from '../../../imgs/star-full.svg';
import starEmpty from '../../../imgs/star-empty.svg';
import './form.css';

export class CleaningReviewForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      selectedStars: props.review ? props.review.stars : 0,
      hoveredStars: props.review ? props.review.stars : 0,
      maxStars: 5,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      Object.assign({}, this.state, {
        text: '',
        selectedStars: props.review ? props.review.stars : 0,
        hoveredStars: props.review ? props.review.stars : 0,
        maxStars: 5,
      })
    );
  }

  _onHoverStar = (e) => {
    if (this.state.selectedStars > 0) {
      return;
    }
    const index = parseInt(e.target.dataset.index, 10);
    this.setState(
      Object.assign({}, this.state, {
        hoveredStars: index,
      })
    );
  };

  _onLeaveStars = () => {
    if (this.state.selectedStars > 0) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        hoveredStars: 0,
      })
    );
  };

  _onClickStar = (e) => {
    if (this.state.selectedStars > 0) {
      return;
    }
    const index = parseInt(e.target.dataset.index, 10);
    this.setState(
      Object.assign({}, this.state, {
        selectedStars: index,
      })
    );
    this.props.actions.submitReview({
      cleaning_id: this.props.cleaning.id,
      employee_id: this.props.user.id,
      customer_id: this.props.user.customer_id,
      stars: index,
    });
  };

  _onChangeText = (text) => {
    this.setState(
      Object.assign({}, this.state, {
        text,
      })
    );
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.review || !this.props.review.id) {
      return;
    }
    this.props.actions.submitUpdateReview({
      id: this.props.review.id,
      cleaning_id: this.props.cleaning.id,
      employee_id: this.props.user.id,
      customer_id: this.props.user.customer_id,
      comment: this.state.text,
      stars: this.state.selectedStars,
    });
  };

  renderStars = () => {
    let elements = [];
    let i = 1;
    while (i <= this.state.maxStars) {
      if (i <= this.state.hoveredStars) {
        elements.push(this.renderStar(i));
      } else if (!this.state.selectedStars) {
        elements.push(this.renderStarEmpty(i));
      }
      i++;
    }
    return elements;
  };

  renderRatingWord = (star) => {
    switch (star) {
      case 1:
        return 'Poor';
      case 2:
        return 'Below Average';
      case 3:
        return 'Acceptable';
      case 4:
        return 'Good';
      case 5:
        return 'Excellent!';
      default:
        return '';
    }
  };

  renderStaffNames = () => {
    return this.props.cleaning.staffs.map((s) => formatStaffName(s)).join(', ');
  };

  renderCommentLabel = (star) => {
    switch (star) {
      case 1:
      case 2:
      case 3:
        return 'Please let me know how we can improve.';
      case 4:
      case 5:
        return `I'm glad to hear it! What did ${this.renderStaffNames()} do well?`;
      default:
        return '';
    }
  };

  renderStar(iter) {
    return (
      <div
        key={iter}
        data-index={iter}
        onClick={this._onClickStar}
        className="cleaning-review-star">
        <img
          className="cleaning-review-star-img"
          onMouseEnter={this._onHoverStar}
          data-index={iter}
          alt="Review star"
          width="26"
          height="26"
          src={star}
        />
      </div>
    );
  }

  renderStarEmpty(iter) {
    return (
      <div
        key={iter}
        data-index={iter}
        onClick={this._onClickStar}
        className="cleaning-review-star star-empty">
        <img
          className="cleaning-review-star-img star-empty"
          onMouseEnter={this._onHoverStar}
          data-index={iter}
          alt="Review star empty"
          width="26"
          height="26"
          src={starEmpty}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="cleaning-review-form">
        <div className="cleaning-review-form-am employee-avatar">
          <Avatar width={51} height={51} item={this.props.account_manager} />
        </div>
        <form onSubmit={this._handleSubmit}>
          <legend className="site-h2 cleaning-review-legend">
            Hi, {this.props.user.first_name}. How did we do?
          </legend>
          <fieldset className="cleaning-review-fieldset">
            <div className="cleaning-review-stars-input">
              <div
                className="cleaning-review-form-stars"
                onMouseLeave={this._onLeaveStars}>
                {this.renderStars()}
              </div>
              <div className="cleaning-review-form-stars-word">
                {!this.state.selectedStars &&
                  this.renderRatingWord(this.state.hoveredStars)}
              </div>
            </div>
            {this.state.selectedStars > 0 && (
              <div className="cleaning-review-comment-input">
                {this.state.selectedStars < 3 && (
                  <div className="bold-text cleaning-review-comment-prelabel">
                    I'm sorry we didn't meet your expectations.
                  </div>
                )}
                <Textarea
                  label={this.renderCommentLabel(this.state.selectedStars)}
                  autoFocus={true}
                  onChange={this._onChangeText}
                />
                <div className="cleaning-review-submit-wrap">
                  <Button primary>Send Feedback</Button>
                </div>
              </div>
            )}
          </fieldset>
          {this.state.selectedStars < 1 && (
            <div className="cleaning-review-form-footer">
              <p>
                Don't worry, we will only notify your attendant
                {this.props.cleaning.staffs.length > 1 ? 's' : ''} if you give
                them 5-stars.
              </p>
              <p className="italic-text">
                &mdash;{this.props.account_manager.name}
              </p>
            </div>
          )}
        </form>
      </div>
    );
  }
}

CleaningReviewForm.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stars: PropTypes.number.isRequired,
    comment: PropTypes.string,
  }),
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
  }).isRequired,
  account_manager: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar_attachment: PropTypes.shape({
      medium: PropTypes.string,
      thumb: PropTypes.string,
      original: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    user: state.employees.user,
    account_manager: state.locations.open.account_manager || {},
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitReview,
        submitUpdateReview,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CleaningReviewForm);
