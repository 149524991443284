import { formatTitleCase } from '../lib/formatters.js';

export const NO_REPLACE = 'do not replace';
export const BEST_OPTION = 'replace with best option';
export const CUSTOM = 'custom instructions';

export const hasCustomInstructions = (productRequisition) => {
  const val = productRequisition.instructions;
  if (!val) return false;
  if (val.trim() === '') return false;
  if (val.trim().toLowerCase() === NO_REPLACE) return false;
  if (val.trim().toLowerCase() === BEST_OPTION) return false;
  return true;
};

export const formattedInstructions = (productRequisition) => {
  if (!productRequisition.instructions) return formatTitleCase(BEST_OPTION);
  if (hasCustomInstructions(productRequisition))
    return productRequisition.instructions;
  return formatTitleCase(productRequisition.instructions);
};
