// @flow
import React from 'react';
import PropTypes from 'prop-types';
import blankGIF from '../imgs/blank.gif';
import './avatar.css';

export default class Avatar extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
      avatar: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
    size: PropTypes.oneOf(['medium', 'thumb', 'original']).isRequired,
  };

  static defaultProps = {
    size: 'thumb',
  };

  getColorFromString(str: string): string {
    let hash = 0;
    let color = '#';
    let value = 0;
    str += '';
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (let i = 0; i < 3; i++) {
      value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  getAlt = (): string => {
    return this.hasFirstName()
      ? `${this.props.item.first_name} ${this.props.item.last_name}`
      : this.props.item.name || this.props.item.id || '';
  };

  avatar = () => {
    return this.props.item.avatar_attachment || this.props.item.avatar || {};
  };

  hasAvatar = (): boolean => {
    return !!this.avatar()[this.props.size];
  };

  getAvatar = (): string => {
    return this.avatar()[this.props.size] || blankGIF;
  };

  hasFirstName = (): boolean => {
    return !!this.props.item.first_name;
  };

  getInitials = (): string => {
    if (
      (this.props.item.email || '').match(/(support|development)@officeluv.com/)
    ) {
      return 'OL';
    }
    return this.hasFirstName()
      ? `${this.props.item.first_name[0]}${
          (this.props.item.last_name || ' ')[0]
        }`
      : (this.props.item.name || this.props.item.email || ' ')[0];
  };

  render() {
    return (
      <div className="avatar">
        {this.hasAvatar() && (
          <img
            src={this.getAvatar()}
            width={this.props.width}
            height={this.props.height}
            title={this.getAlt()}
            alt={this.getAlt()}
          />
        )}
        {!this.hasAvatar() && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${this.props.width} ${this.props.height}`}
            width={this.props.width}
            height={this.props.height}
            style={{ background: this.getColorFromString(this.getAlt()) }}
            title={this.getAlt()}
          >
            <text
              fontSize={this.props.width * 0.4}
              fill="white"
              x={this.props.width / 2}
              textAnchor="middle"
              y="65%"
            >
              {this.getInitials()}
            </text>
          </svg>
        )}
      </div>
    );
  }
}
