import PropTypes from 'prop-types';
import { formatEmployeeName } from '../lib/formatters.js';

export const ProductRequisitionPropType = PropTypes.shape({
  id: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      })
    ),
    price: PropTypes.number,
  }).isRequired,
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity_minimum: PropTypes.number,
    price_minimum: PropTypes.number,
    price: PropTypes.number.isRequired,
    shipping: PropTypes.shape({
      max_seconds: PropTypes.number.isRequired,
      min_seconds: PropTypes.number.isRequired,
    }),
  }),
});

export const VendorMinimumGroupPropType = PropTypes.shape({
  vendor_id: PropTypes.number.isRequired,
  vendor_product_requisitions: PropTypes.arrayOf(ProductRequisitionPropType)
    .isRequired,
  minimum: PropTypes.shape({
    type: PropTypes.oneOf(['price_min', 'quantity_min', '']).isRequired,
    value: PropTypes.number.isRequired,
    portion_total: PropTypes.number.isRequired,
  }),
});

export const OptimizedProductRequisitionsPropType = PropTypes.shape({
  product_requisitions: PropTypes.arrayOf(ProductRequisitionPropType)
    .isRequired,
  minimums: PropTypes.arrayOf(VendorMinimumGroupPropType).isRequired,
  unmet_minimums: PropTypes.arrayOf(VendorMinimumGroupPropType).isRequired,
  met_minimum_product_requisitions: PropTypes.arrayOf(
    ProductRequisitionPropType
  ).isRequired,
});

export const CartPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  awaiting_approval_at: PropTypes.string,
  age_confirmation: PropTypes.bool,
  instructions: PropTypes.string,
  _optimized_product_requisitions: OptimizedProductRequisitionsPropType,
  product_requisitions: PropTypes.arrayOf(ProductRequisitionPropType),
  shipping_address: PropTypes.string,
  shipping_address_number: PropTypes.string,
  shipping_business: PropTypes.string,
  shipping_care: PropTypes.string,
  shipping_city: PropTypes.string,
  shipping_name: PropTypes.string,
  shipping_state: PropTypes.string,
  shipping_zip: PropTypes.string,
  submitted_at: PropTypes.string,
});

function hasQuantity(pr) {
  return pr.quantity > 0;
}

/**
 * Adds _optimized_product_requisitions key and value to new cart object
 *
 * @access public
 * @param {Object} cart
 * @returns {Object} cart
 */
export const populateOptimizedProductRequisitions = (cart) => {
  return {
    ...cart,
    _optimized_product_requisitions: optimizedVendorGroups(
      (
        cart.product_requisitions ||
        cart.scheduled_product_requisitions ||
        []
      ).filter(hasQuantity)
    ),
  };
};

/**
 * For group of product requisitions, calculate portion of price minimum met
 *
 * @access public
 * @param {Object[]} group
 * @param {Number} price_minimum
 * @returns {Number} portion of total
 */
export const vendorGroupCostPortionTotal = (group, price_minimum) => {
  return group.reduce((acc, pr) => {
    let ratio =
      ((pr.price === 0 ? pr.price : pr.price || pr.product.price) *
        pr.quantity) /
      price_minimum;
    if (isNaN(ratio) || ratio === Infinity) ratio = 1;
    return acc + ratio;
  }, 0);
};

/**
 * For group of product requisitions, calculate portion of quantity minimum met
 *
 * @access public
 * @param {Object[]} group
 * @param {Number} quantity_minimum
 * @returns {Number} portion of total
 */
export const vendorGroupQuantityPortionTotal = (group, quantity_minimum) => {
  return group.reduce((acc, pr) => {
    let ratio = pr.quantity / quantity_minimum;
    if (isNaN(ratio) || ratio === Infinity) ratio = 1;
    return acc + ratio;
  }, 0);
};

/**
 * Recursively build a space where product requisitions are assigned to vendors
 *
 * @access public
 * @param {Object[]} unhandled_lines
 * @param {Array[]} groups where each group is an array of product_requisitions
 * @returns {Array[]} where each index is a vendor ID and the value is an array of product requisitions
 */
export const groupLinesByPossibleVendor = (unhandled_lines, groups) => {
  if (unhandled_lines.length < 1) return groups;
  return unhandled_lines.reduce((groups_acc, line) => {
    let handled_vendors = groups_acc
      .filter((group) => group[0])
      .map((group) => group[0].vendor);
    let line_vendors = line.vendors || [];
    if (line_vendors.length < 1) line_vendors = [default_vendor];
    return line_vendors
      .filter((vendor) => handled_vendors.indexOf(vendor.id) < 0)
      .reduce((groups_inner_acc, vendor) => {
        let handled_lines = groups_inner_acc.reduce((acc, group) => {
          return acc.concat(group.map((pr) => pr.id));
        }, []);
        let now_unhandled_lines = unhandled_lines.filter(
          (pr) => handled_lines.indexOf(pr.id) < 0
        );
        if (now_unhandled_lines.length < 1) return groups_inner_acc;
        let lines_with_vendor = now_unhandled_lines
          .filter((pr) => {
            return pr.vendors.map((v) => v.id).indexOf(vendor.id) > -1;
          })
          .map((pr) => {
            let price = pr.product.price;
            if (vendor.id)
              price = pr.vendors.filter((v) => v.id === vendor.id)[0].price;
            return { ...pr, vendor: { ...vendor, price } };
          });
        groups_inner_acc[vendor.id] = lines_with_vendor;
        let line_ids_with_vendor = lines_with_vendor.map((pr) => pr.id);
        return groupLinesByPossibleVendor(
          now_unhandled_lines.filter(
            (pr) => line_ids_with_vendor.indexOf(pr.id) < 0
          ),
          groups_inner_acc
        );
      }, groups_acc);
  }, groups);
};

/**
 * Build a multi-dimensional array of all unique product requisition vendor combinations
 *
 * @access public
 * @param {Object[]} product_requisitions
 * @returns {Array[]} where each index is a vendor ID and the value is an array of product requisitions
 */
export const allLinesByAllVendors = (product_requisitions) => {
  return (product_requisitions || []).reduce((acc, pr) => {
    pr.vendors = ((pr.product || {}).vendors || [default_vendor]).filter((v) =>
      (v.stock_status || 'in').match(/in/)
    );
    if (pr.vendors.length < 1) pr.vendors = [default_vendor];
    return pr.vendors.reduce((vacc, vval, vidx) => {
      vacc[vval.id] = (vacc[vval.id] || []).concat([
        { ...pr, priority: vidx, vendor: vval },
      ]);
      return vacc;
    }, acc);
  }, []);
};

/**
 * Compute optimal combination of vendors to fulfill product requisitions
 *
 * @access public
 * @param {Object[]} product_requisitions
 * @returns {Object} with product_requisitions, minimums, unmet_minimums, met_minimum_product_requisitions
 */
export const optimizedVendorGroups = (product_requisitions) => {
  let unique_search_space = allLinesByAllVendors(product_requisitions)
    .map((group, vendor_id) => {
      let group_ids = group.map((pr) => pr.id);
      let lines_not_in_group = product_requisitions.filter(
        (pr) => group_ids.indexOf(pr.id) < 0
      );
      let groups = [];
      groups[vendor_id] = group;
      if (lines_not_in_group.length < 1) return groups;
      return groupLinesByPossibleVendor(lines_not_in_group, groups);
    })
    .map((space) => {
      return space.reduce((acc, pr) => acc.concat(pr));
    });
  let unique_search_space_minimums = unique_search_space.map((space) => {
    return productRequisitionsByMinimums(space);
  });
  let scores = unique_search_space_minimums.map((space) => {
    let unmet_count = space.filter(
      (set) => set.minimum.portion_total < 1
    ).length;
    let total_count = space.reduce((acc) => {
      return acc + 1;
    }, 0);
    let difference = space.reduce((acc, val) => {
      return acc + (1 - Math.min(1, val.minimum.portion_total));
    }, 0);
    return { difference, unmet_count, total_count };
  });
  let best_score_index = scores.indexOf(
    scores.reduce((acc, score) => {
      if (score.total_count < acc.total_count) return score;
      if (score.unmet_count < acc.unmet_count) return score;
      return acc.difference < score.difference ? acc : score;
    }, {})
  );
  let best_combination = unique_search_space[best_score_index];
  let best_combination_minimum = unique_search_space_minimums[best_score_index];
  if (!best_combination)
    return {
      minimums: [],
      unmet_minimums: [],
      product_requisitions: [],
      met_minimum_product_requisitions: [],
    };
  return {
    product_requisitions: best_combination,
    minimums: best_combination_minimum,
    unmet_minimums: best_combination_minimum.filter(
      (min) => min.minimum.portion_total < 1
    ),
    met_minimum_product_requisitions: best_combination_minimum
      .filter((min) => min.minimum.portion_total >= 1)
      .map((min) => min.vendor_product_requisitions)
      .reduce((acc, val) => acc.concat(val), []),
  };
};

export const default_vendor_minimum_group = {
  vendor_id: 0,
  vendor_product_requisitions: [],
  minimum: { type: '', value: 0, portion_total: 1 },
};

export const default_vendor = {
  id: 0,
  price: 0,
  quantity_minimum: 0,
  price_minimum: 0,
  shipping: {
    max_seconds: 432000,
    min_seconds: 0,
  },
  stock_status: 'in',
};

/**
 * Compute minimums by vendor assigned to each product_requisition and portion met
 *
 * @access public
 * @param {Object[]} product_requisitions with vendor field populated
 * @returns {Object[]} with minimum, vendor_id, vendor_product_requisitions
 */
export const productRequisitionsByMinimums = (product_requisitions) => {
  let prs_by_vendor = product_requisitions.reduce((acc, val) => {
    let id = (val.vendor || { id: 0 }).id;
    acc[id] = (acc[id] || []).concat(val);
    return acc;
  }, []);
  let minimums = prs_by_vendor.map((pr_group, vendor_id) => {
    const vendor = (pr_group[0] || {}).vendor || {
      price_minimum: 0,
      quantity_minimum: 0,
    };
    if (
      !pr_group.length ||
      (!vendor.quantity_minimum && !vendor.price_minimum)
    ) {
      return {
        ...default_vendor_minimum_group,
        vendor_id,
        vendor_product_requisitions: pr_group,
      };
    }
    const price_portion_total = vendorGroupCostPortionTotal(
      pr_group,
      vendor.price_minimum
    );
    const quantity_portion_total = vendorGroupQuantityPortionTotal(
      pr_group,
      vendor.quantity_minimum
    );

    if (vendor.quantity_minimum > 0) {
      return {
        vendor_id,
        vendor_product_requisitions: pr_group,
        minimum: {
          type: 'quantity_min',
          value: vendor.quantity_minimum,
          portion_total: quantity_portion_total,
        },
      };
    }
    return {
      vendor_id,
      vendor_product_requisitions: pr_group,
      minimum: {
        type: 'price_min',
        value: vendor.price_minimum,
        portion_total: price_portion_total,
      },
    };
  });
  // let unmet_minimums = minimums
  //     .filter(m => m.minimum.portion_total < 1);
  // let overmet_minimums = minimums
  //     .filter(m => m.minimum.portion_total > 1);
  // let possible_donor_minimums = [];
  // let overlapping_minimums = unmet_minimums.filter((um, idx) => {
  //     return overmet_minimums.filter(m => {
  //         let donors =  m.vendor_product_requisitions.filter(pr => {
  //             return pr.quantity > 1
  //                 && pr.product.vendors.filter(v => v.id === um.vendor_id).length > 0;
  //         });
  //         if (!donors.length) return false;
  //         possible_donor_minimums[idx] = { ...m, possible_donors: donors, };
  //         return true;
  //     }).length > 0;
  // });
  // if (!possible_donor_minimums.length)
  //     return minimums;
  return minimums;
};

export const isRequester = (cart, user) => {
  if (!cart.requester_type || !user.type) return false;

  return (
    cart.requester_id === user.id &&
    cart.requester_type.toLowerCase() === user.type.toLowerCase()
  );
};

export const cartName = (cart, user) => {
  if (cart.name) return cart.name;
  if (isRequester(cart, user)) {
    return 'Your Cart';
  }
  if (!cart.id) return '';
  if (!cart.requester) return `Cart #${cart.id}`;
  return `${formatEmployeeName(cart.requester)}'s Cart`;
};

export const findYourCart = (carts, user) =>
  carts.filter((cart) => !cart.is_public && isRequester(cart, user))[0];
