import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelect from '@officeluv/react-multi-select';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import './product-filters.css';
import { STOCK_STATUS_OPTIONS } from '../../strings.js';

export class ProductStockStatusFilter extends React.PureComponent {
  _options() {
    return STOCK_STATUS_OPTIONS;
  }

  _selectedOptions() {
    const { stockStatus } = this.props;
    if (!stockStatus) {
      return [];
    }
    return [stockStatus];
  }

  onSelectedChanged = (data) => {
    this.props.actions.setProductQuery({
      filters: {
        stockStatus: data.length > 1 ? undefined : data[0],
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
  };

  render() {
    return (
      <div className="product-filter">
        <MultiSelect
          overrideStrings={{ selectSomeItems: 'Filter by Stock Status' }}
          hasSelectAll={false}
          options={this._options()}
          onSelectedChanged={this.onSelectedChanged}
          selected={this._selectedOptions()}
          disableSearch={true}
        />
      </div>
    );
  }
}

ProductStockStatusFilter.propTypes = {
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }),
  stockStatus: PropTypes.oneOf(['in', 'out']),
};

export function mapStateToProps(state) {
  return {
    stockStatus: state.productQuery.filters.stockStatus,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productQuery.filters.stockStatus ===
    next.productQuery.filters.stockStatus
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductStockStatusFilter);
