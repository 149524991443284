import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StipendItem from './item.js';
import { Segment, Table, Header, Pagination } from 'semantic-ui-react';

const PAGINATION_CONFIG = { limit: 10 };

export class StipendsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stipends !== this.props.stipends) {
      this.setState({ activePage: 1 });
    }
  }

  paginatedStipends() {
    const upper = this.state.activePage * PAGINATION_CONFIG.limit;
    const lower = upper - PAGINATION_CONFIG.limit;

    return this.props.stipends.slice(lower, upper);
  }

  handlePageChange = (_, data) => {
    this.setState({ activePage: data.activePage });
  };

  renderStipend = (stipend) => {
    return <StipendItem key={stipend.id} stipend={stipend} />;
  };

  render() {
    if (!this.props.stipends.length) {
      return null;
    }

    return (
      <Segment basic style={{ minHeight: '100vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Header as="h4" style={{ margin: 0 }}>
            Your Stipends
          </Header>
        </div>
        <Table basic className="location-team-container">
          <Table.Body>
            <Table.Row className="batch-actions-row">
              <Table.Cell width={5}>Stipend Name</Table.Cell>
              <Table.Cell width={3}>Amount</Table.Cell>
              <Table.Cell width={5}>Starting date</Table.Cell>
              <Table.Cell width={5}>Expiration date</Table.Cell>
              <Table.Cell width={1}></Table.Cell>
              <Table.Cell width={1}></Table.Cell>
            </Table.Row>

            {this.paginatedStipends().map(this.renderStipend)}
          </Table.Body>
        </Table>

        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <Pagination
            defaultActivePage={1}
            firstItem={null}
            lastItem={null}
            onPageChange={this.handlePageChange}
            totalPages={Math.ceil(
              this.props.stipends.length / PAGINATION_CONFIG.limit
            )}
          />
        </div>
      </Segment>
    );
  }
}

StipendsList.propTypes = {
  stipends: PropTypes.object.isRequired,
};

export function mapStateToProps(state) {
  return {
    stipends: Object.values(state.stipends.items),
  };
}

export function areStatesEqual(prev, next) {
  return prev.stipends.items === next.stipends.items;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  StipendsList
);
