import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelect from '@officeluv/react-multi-select';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import './product-filters.css';
import { SHIPPING_TIME_OPTIONS } from '../../strings.js';

export class ProductShippingFilter extends React.PureComponent {
  _options() {
    return SHIPPING_TIME_OPTIONS;
  }

  _selectedOptions() {
    const { maxShippingTimes } = this.props;
    if (!maxShippingTimes || maxShippingTimes.length === 0) {
      return [];
    }
    return maxShippingTimes;
  }

  onSelectedChanged = (data) => {
    this.props.actions.setProductQuery({
      filters: {
        maxShippingTimes: data,
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
  };

  render() {
    return (
      <div className="product-filter">
        <MultiSelect
          overrideStrings={{ selectSomeItems: 'Filter by Shipping Time' }}
          hasSelectAll={true}
          options={this._options()}
          onSelectedChanged={this.onSelectedChanged}
          selected={this._selectedOptions()}
        />
      </div>
    );
  }
}

ProductShippingFilter.propTypes = {
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }),
  maxShippingTimes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export function mapStateToProps(state) {
  return {
    maxShippingTimes: state.productQuery.filters.maxShippingTimes,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productQuery.filters.maxShippingTimes ===
    next.productQuery.filters.maxShippingTimes
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductShippingFilter);
