import * as types from '../actions/action-types';

export const empty = {
  initialized: false,
  items: [],
  open: {},
  isRequesting: false,
  error: false,
};
export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_STIPENDS:
      return {
        ...state,
        initialized: true,
        isRequesting: true,
      };
    case types.SUCCESS_EMPLOYEE_STIPENDS:
      return Object.assign({}, state, {
        items: action.data.data,
        isRequesting: false,
        error: false,
      });
    case types.OPEN_EMPLOYEE_STIPENDS:
      return Object.assign({}, state, {
        error: empty.error,
        open: { ...action.data },
      });
    case types.CLOSE_EMPLOYEE_STIPENDS:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.POST_EMPLOYEE_STIPEND:
      return Object.assign({}, state, {
        isPosting: true,
      });
    case types.SUCCESS_POST_EMPLOYEE_STIPEND:
      return Object.assign({}, state, {
        isPosting: false,
        error: false,
        items: state.items.concat(action.data),
      });
    case types.ERROR_POST_EMPLOYEE_STIPEND:
      return Object.assign({}, state, {
        isPosting: false,
        error: true,
      });
    case types.SUCCESS_DELETE_EMPLOYEE_STIPEND:
      return Object.assign({}, state, {
        isRequesting: false,
        items: state.items.filter((item) => item.id !== action.data.id),
      });
    case types.ERROR_DELETE_EMPLOYEE_STIPEND:
      return Object.assign({}, state, {
        error: true,
      });
    default:
      return state;
  }
};
