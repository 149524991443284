import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/message.min.css';

export default class ApprovalDetails extends React.PureComponent {
  render() {
    if (
      this.props.approval_reason === 'employee_spend' &&
      this.props.approval_required_after_employee_spend > 0
    ) {
      return (
        <Message>
          <p>
            Spending over{' '}
            {formatCentAmount(
              this.props.approval_required_after_employee_spend
            )}{' '}
            in a month requires approval. So far this month, you have spent{' '}
            {formatCentAmount(this.props.current_employee_spend)}.
          </p>
        </Message>
      );
    }
    if (
      this.props.approval_reason === 'location_spend' &&
      this.props.approval_required_after_location_spend > 0
    ) {
      return (
        <Message>
          <p>
            Collective office spend over{' '}
            {formatCentAmount(
              this.props.approval_required_after_location_spend
            )}{' '}
            in a month requires approval. So far this month, your office has
            spent {formatCentAmount(this.props.current_location_spend)}.
          </p>
        </Message>
      );
    }
    if (this.props.approval_reason === 'staff') {
      return (
        <Message>
          <p>Field attendant orders require approval.</p>
        </Message>
      );
    }
    return null;
  }
}

ApprovalDetails.propTypes = {
  approval_reason: PropTypes.string,
  approval_required: PropTypes.bool,
  approval_required_after_employee_spend: PropTypes.number,
  approval_required_after_location_spend: PropTypes.number,
  current_employee_spend: PropTypes.number,
  current_location_spend: PropTypes.number,
};
