import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackInterfaceOpenProduct,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

export const unsetInitialProductModalTab = () => ({
  type: types.UNSET_INITIAL_PRODUCT_MODAL_TAB,
});

export const setInitialProductModalTab = (data) => ({
  type: types.SET_INITIAL_PRODUCT_MODAL_TAB,
  data,
});

export const showProductModal = (data) => {
  return openProduct(data);
};

export const openProduct = (data) => {
  trackInterfaceOpenProduct(data);
  return {
    type: types.OPEN_PRODUCT,
    data,
  };
};

export const closeProductModal = () => {
  return closeProduct();
};

export const closeProduct = () => {
  return {
    type: types.CLOSE_PRODUCT,
  };
};

export const getProductsSuggestions = (data) => {
  return {
    type: types.GET_PRODUCTS_SUGGESTIONS,
    data: data,
  };
};

export const successGetProductsSuggestions = (data) => {
  return {
    type: types.SUCCESS_GET_PRODUCTS_SUGGESTIONS,
    data: data,
  };
};

export const errorGetProductsSuggestions = (data) => {
  return {
    type: types.ERROR_GET_PRODUCTS_SUGGESTIONS,
    data: data,
  };
};

export const createCatalogSearch = (data) => {
  return {
    type: types.CREATE_CATALOG_SEARCH,
    data: data,
  };
};

export const successCreateCatalogSearch = (data) => {
  return {
    type: types.SUCCESS_CREATE_CATALOG_SEARCH,
    data: data,
  };
};

export const errorCreateCatalogSearch = (data) => {
  return {
    type: types.ERROR_CREATE_CATALOG_SEARCH,
    data: data,
  };
};

export const getCatalogSearches = (data) => {
  return {
    type: types.GET_CATALOG_SEARCHES,
    data: data,
  };
};

export const successGetCatalogSearches = (data) => {
  return {
    type: types.SUCCESS_GET_CATALOG_SEARCHES,
    data: data,
  };
};

export const errorGetCatalogSearches = (data) => {
  return {
    type: types.ERROR_GET_CATALOG_SEARCHES,
    data: data,
  };
};

export const getLocationCachedProduct = (data) => {
  return {
    type: types.GET_LOCATION_CACHED_PRODUCT,
    data: data,
  };
};

export const successGetLocationCachedProduct = (data) => {
  return {
    type: types.SUCCESS_GET_LOCATION_CACHED_PRODUCT,
    data: data,
  };
};

export const errorGetLocationCachedProduct = (data) => {
  return {
    type: types.ERROR_GET_LOCATION_CACHED_PRODUCT,
    data: data,
  };
};

export function doGetCatalogSearches() {
  return (dispatch, getState) => {
    const data = {
      _request: types.GET_CATALOG_SEARCHES,
    };
    dispatch(getCatalogSearches(data));
    const { auth } = getState();
    return fetch(
      `${ADMIN_V1_URL}/${auth.actor.type.toLowerCase()}s/${
        auth.actor.id
      }/catalog_searches?limit=20`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            message: 'Get catalog searches',
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        let dedupedSearches = body.data.reduce((acc, item) => {
          acc[item.text_searched] = item;
          return acc;
        }, {});
        dispatch(successGetCatalogSearches(Object.values(dedupedSearches)));
        return body.data;
      })
      .catch((error) => {
        dispatch(errorGetCatalogSearches(error));
        trackFetchError(error);
      });
  };
}

export function submitCatalogSearch(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(createCatalogSearch(data));
    const { auth } = getState();
    return fetch(
      `${ADMIN_V1_URL}/${auth.actor.type.toLowerCase()}s/${
        auth.actor.id
      }/catalog_searches`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            message: 'Create catalog search',
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successCreateCatalogSearch(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateCatalogSearch(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get product data at location
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationCachedProduct(data) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(getLocationCachedProduct(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location}/products/${data.product}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            message: 'Get location cached product',
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetLocationCachedProduct(body));
      })
      .catch((error) => {
        dispatch(errorGetLocationCachedProduct(error));
        trackFetchError(error);
      });
  };
}
