import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, Segment } from 'semantic-ui-react';
import { doGetLocationExternalAccounts } from '../../actions/locations-actions.js';
import AchRequestForm from './ach-request-form.js';
import QuickbooksBillingInfo from './quickbooks-billing-info.js';
import StripeBillingInfo from './stripe-billing-info.js';
import StripeBillingInfoSetup from './stripe-billing-info-setup.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './billing-info.css';

export class BillingInfo extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Billing and Invoices.', 'Settings');
  }

  UNSAFE_componentWillMount() {
    if (!this.props.location.external_accounts) {
      this.props.actions.doGetLocationExternalAccounts(this.props.location);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.location.id !== this.props.location.id) {
      this.props.actions.doGetLocationExternalAccounts(props.location);
    }
  }

  integrationPreference = () => {
    const multiple =
      this.props.location.external_accounts &&
      this.props.location.external_accounts.quickbooks &&
      this.props.location.external_accounts.stripe;
    if (!multiple) return '';
    if (!this.props.location.pref_billing_integration) return 'quickbooks';
    return this.props.location.pref_billing_integration;
  };

  render() {
    const accts = this.props.location.external_accounts || {};
    const pref = this.integrationPreference();
    return (
      <div className="customer-billing-info card">
        <Header as="h2" attached="top">
          Company Bill To
        </Header>
        <Segment attached="bottom" loading={this.props.isRequesting}>
          {accts.quickbooks && (
            <QuickbooksBillingInfo
              active={!!pref.match(/quickbooks/i)}
              account={accts.quickbooks}
            />
          )}
          {accts.stripe && (
            <StripeBillingInfo
              active={!!pref.match(/stripe/i)}
              canEdit={this.props.can_manage_billing}
              account={accts.stripe}
            />
          )}
          {!accts.stripe && !accts.quickbooks && !this.props.isRequesting && (
            <StripeBillingInfoSetup canEdit={this.props.can_manage_billing} />
          )}
          {!accts.quickbooks && !this.props.isRequesting && (
            <AchRequestForm
              style={{ marginBottom: '1rem', marginTop: '2rem' }}
            />
          )}
        </Segment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    can_manage_billing: state.auth.role.can_manage_billing,
    isRequesting: state.locations.requesting.length > 1,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetLocationExternalAccounts,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.requesting === next.locations.requesting &&
    prev.auth.role === next.auth.role &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BillingInfo);
