import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatCentAmount } from '../../lib/formatters.js';
import {
  buildTotalsLineItems,
  totalsLineItemsPropTypes,
} from '../../helpers/requisition-helpers.js';
import { CartPropType } from '../../helpers/cart-helpers.js';

export class CartSubtotals extends React.PureComponent {
  subtotalLineItems() {
    return this.props.lineItems.filter(
      (item) => item.amount !== 0 && item.key !== 'total'
    );
  }

  totalLineItem() {
    return this.props.lineItems.filter((item) => item.key === 'total')[0];
  }

  render() {
    const productReqs =
      this.props.cart.product_requisitions ||
      this.props.cart.scheduled_product_requisitions ||
      [];
    if (!productReqs.length) {
      return null;
    }

    const totalItem = this.totalLineItem();
    return (
      <div className="cart-subtotal">
        {this.subtotalLineItems().map((item) => (
          <div className="shipping-line-item" key={item.label}>
            <p className="shipping-title bold-text">{item.label}</p>
            <p className="shipping-fee">{formatCentAmount(item.amount)}</p>
          </div>
        ))}

        {this.props.showTax && this.props.estimatingTax && (
          <p>{this.props.estimatingTaxText}</p>
        )}
        {this.props.showTax && (
          <div className="shipping-line-item cart-total">
            <p className="shipping-title bold-text">{totalItem.label}</p>
            <p className="shipping-fee bold-text">
              {formatCentAmount(totalItem.amount)}
            </p>
          </div>
        )}
      </div>
    );
  }
}

CartSubtotals.defaultProps = {
  estimatingTax: false,
  estimatingTaxText: 'estimating...',
};

CartSubtotals.propTypes = {
  cart: CartPropType,
  estimatingTax: PropTypes.bool.isRequired,
  estimatingTaxText: PropTypes.string.isRequired,
  lineItems: totalsLineItemsPropTypes,
};

export function mapStateToProps(state, ownProps) {
  const orderMinimum = state.application.appConfig.orderMinimum;
  const shippingPrice = state.application.appConfig.shippingPrice;
  const alcoholConvenienceFee =
    state.application.appConfig.alcoholConvenienceFee;
  const isStipendUser =
    typeof state.employees.user.stipend_remaining === 'number';
  return {
    orderMinimum,
    shippingPrice,
    alcoholConvenienceFee,
    lineItems: buildTotalsLineItems(ownProps.cart, {
      orderMinimum,
      shippingPrice,
      alcoholConvenienceFee,
      isStipendUser,
      showTax: ownProps.showTax,
    }),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.application.appConfig === next.application.appConfig &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CartSubtotals
);
