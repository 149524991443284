import React from 'react';
import { connect } from 'react-redux';
import AuthCheck from '../components/auth-check.js';
import { Form, Input, Button, Header, Segment, Icon } from 'semantic-ui-react';
import fetch from '../lib/hmac-fetch.js';

import { requestID } from '../actions/action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';
import SearchResultsRequestItem from '../components/products/search-results-request-item.js';

import './contact-us.css';

export class ContactUsRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.user.first_name
        ? `${this.props.user.first_name} ${this.props.user.last_name}`
        : '',
      email: this.props.user.email ? this.props.user.email : '',
      order_number: '',
      reason: '',
      message: '',
      success: false,
      failed: false,
    };
  }

  doContactUsMessageRequest = () => {
    let body = {
      name: this.state.name,
      email: this.state.email,
      order_number: this.state.order_number,
      reason: this.state.reason,
      message: this.state.message,
    };

    body._request = requestID();
    const location = this.props.location;
    const user = this.props.user;

    body.employee_id = user.id;

    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/contact_us_messages`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': body._request,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          console.log('error', response);
          this.setState({
            failed: true,
          });
        } else {
          this.setState({
            success: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          failed: true,
        });
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleSubmit = () => {
    this.doContactUsMessageRequest();
  };

  componentWillReceiveProps(nextProps) {
    if (
      `${nextProps.user.first_name} ${nextProps.user.last_name}` !==
      this.state.name
    ) {
      this.setState({
        name: `${nextProps.user.first_name} ${nextProps.user.last_name}`,
      });
    }
    if (nextProps.user.email !== this.state.email) {
      this.setState({
        email: nextProps.user.email,
      });
    }
  }

  render() {
    if (this.state.success) {
      return (
        <div className="contact-us">
          <AuthCheck />
          <div className="card">
            <Header id="contact-us" as="h2">
              Contact Us
            </Header>
            <div className="contact-us-notification">
              <Header.Subheader>
                Thank you for contacting us! We will get back to you shortly.
              </Header.Subheader>
              <Icon className="check" name="check circle" size="huge" />
            </div>
          </div>
        </div>
      );
    }
    if (this.state.failed) {
      return (
        <div className="contact-us">
          <AuthCheck />
          <div className="card">
            <Header id="contact-us" as="h2">
              Contact Us
            </Header>
            <div className="contact-us-notification">
              <Header.Subheader>
                We're sorry, something went wrong. Please try again later.
              </Header.Subheader>
              <Icon className="warning" name="warning sign" size="huge" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="contact-us">
        <AuthCheck />
        <div className="card">
          <Header id="contact-us" as="h2">
            Contact Us
          </Header>
          <Header.Subheader>
            Please provide some additional information about what you're
            experiencing, and we'll help you momentarily!
          </Header.Subheader>
          <Segment attached="bottom">
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <label>Name</label>
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Email</label>
                <Input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Order Number (if applicable)</label>
                <Input
                  type="number"
                  name="order_number"
                  value={this.state.order_number}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Reasons</label>
                <Form.Dropdown
                  selection
                  fluid
                  width={7}
                  name="reason"
                  value={this.state.reason}
                  onChange={this.handleChange}
                  options={[
                    {
                      key: 'tracking',
                      text: 'Order Tracking',
                      value: 'tracking',
                    },
                    {
                      key: 'shipping',
                      text: 'Shipment Issue',
                      value: 'shipping',
                    },
                    {
                      key: 'placing',
                      text: 'Issue with Placing My Order',
                      value: 'placing',
                    },
                    {
                      key: 'billing',
                      text: 'Billing/Invoices',
                      value: 'billing',
                    },
                    {
                      key: 'equipment',
                      text: 'In-office Equipment (coffee, H2o, etc)',
                      value: 'equipment',
                    },
                    {
                      key: 'quality',
                      text: 'Product Quality',
                      value: 'quality',
                    },
                    {
                      key: 'modify',
                      text: 'Update/Modify My Order',
                      value: 'modify',
                    },
                    {
                      key: 'attendant',
                      text: 'Attendant Service',
                      value: 'attendant',
                    },
                    {
                      key: 'item',
                      text: 'Item Add Request',
                      value: 'item',
                    },
                    {
                      key: 'return',
                      text: 'Return/Exchange',
                      value: 'return',
                    },
                    {
                      key: 'stipends',
                      text: 'Stipends',
                      value: 'stipends',
                    },
                    {
                      key: 'other',
                      text: 'Other',
                      value: 'other',
                    },
                  ]}
                />
              </Form.Field>
              <Form.Field>
                <label>Message</label>
                <Form.TextArea
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Button fluid primary type="submit">
                Submit
              </Button>
            </Form>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="faq"
              href="https://officeluv.zendesk.com/hc/en-us">
              For additional help please check out our FAQ
            </a>
          </Segment>
        </div>
        <SearchResultsRequestItem />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    locationAddress: state.locations.open.address,
    location: state.locations.open,
    user: state.employees.user,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.locations.open.services === next.locations.open.services
  );
}

export default connect(mapStateToProps, null, null, {
  areStatesEqual,
})(ContactUsRoute);
