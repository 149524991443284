import React from 'react';
import PropTypes from 'prop-types';
import browserHistory from '../lib/history.js';
import * as queryParser from '../lib/query-parser.js';

export class BeforeLeaveConfirmation extends React.PureComponent {
  componentDidMount() {
    if (this.props.activated) this.activate();
    this.urlListener = browserHistory.block(this.evaluateConfirm);
  }

  componentDidUpdate(prevProps) {
    this.evaluateActivation(prevProps);
  }

  componentWillUnmount() {
    this.deactivate();
    this.urlListener();
  }

  evaluateActivation(prevProps) {
    if (!prevProps.activated && this.props.activated) {
      this.activate();
    }
    if (prevProps.activated && !this.props.activated) {
      this.deactivate();
    }
  }

  evaluateConfirm = (location) => {
    if (location.pathname.match(window.location.pathname)) return;
    if (!this.props.activated) return;
    const queryParams = queryParser.parse(location.search);
    if (queryParams.force === 'true') return;

    return this.props.message;
  };

  activate() {
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  deactivate() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  beforeUnload = (e) => {
    e.preventDefault();
    let dialogText = this.props.message;
    e.returnValue = dialogText;
    return dialogText;
  };

  render() {
    return null;
  }
}

BeforeLeaveConfirmation.propTypes = {
  activated: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

BeforeLeaveConfirmation.defaultProps = {
  activated: false,
  message:
    'Your pending changes may be lost. Do you still want to close this page?',
};

export default BeforeLeaveConfirmation;
