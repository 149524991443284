import React from 'react';
import AuthCheck from '../components/auth-check.js';
import SettingsNotifications from '../components/employees/settings-employee-notifications.js';
import setTitle from '../decorators/set-title.js';

export class SettingsUserNotifications extends React.Component {
  render() {
    return (
      <div className="settings-panel">
        <AuthCheck />
        <SettingsNotifications />
      </div>
    );
  }
}

SettingsUserNotifications = setTitle((props) => {
  return 'User Settings';
})(SettingsUserNotifications);

export default SettingsUserNotifications;
