export const filterInLimitedCatalog = (products, cache) => {
  return products.filter(productIsInLimitedCatalog(cache));
};

export const filterProductsByParentCategories = (
  products,
  parentCategories
) => {
  return products.filter((product) => {
    return product.product_category_ids?.some((id) =>
      parentCategories.includes(id)
    );
  });
};

/**
 * Thunk which retuns a function which returns a boolean of whether a product is in the limited catalog
 *
 * @access public
 * @param {Array} cache of limited catalog products, index by product ID, values are limited catalog ID, null, or undefined
 * @returns {Function} with param of product
 */
export const productIsInLimitedCatalog = (cache) => {
  return (product) => {
    return (
      !!cache[product.id] ||
      (!!product.catalog_product_id && cache[product.id] !== null)
    );
  };
};

export const measurePerPurchase = (product) => {
  return (product.stock_per_purchase || 1) * (product.measure_per_stock || 1);
};

export const pricePerUnitOfMeasure = (product) => {
  return product.price / measurePerPurchase(product);
};

export const pricePerUnitOfMeasureDiff = (parent, child) => {
  const diff = pricePerUnitOfMeasure(parent) - pricePerUnitOfMeasure(child);
  return Number.parseFloat(diff).toFixed(2);
};

export const pricePerUnitOfMeasurePercentSavings = (parent, child) => {
  const x = pricePerUnitOfMeasure(parent);
  const y = pricePerUnitOfMeasure(child);
  const diff = x - y;
  return (diff / x || 0) * 100;
};

export const mergePopularProducts = (
  popularProducts = [],
  categoryProducts = []
) => {
  const popularProductsIds = popularProducts.map((product) => product.id);

  const filteredProducts = categoryProducts.filter((product) => {
    return !popularProductsIds.includes(product.id);
  });

  return popularProducts
    .concat(filteredProducts.slice(0, 8))
    .filter((product) => product.active);
};
