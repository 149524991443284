import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Segment } from 'semantic-ui-react';
import StepMarker from './step-marker.js';
import { doInviteEmployees } from '../../actions/employee-actions.js';
import { REGEX_EMAIL, SORT_BY_ID } from '../../strings.js';
import { FILTER_GET_INDEX_REQUEST } from '../../ducks/customer-roles.js';
import ModalCustomerRolesDesc from '../modals/modal-customer-roles-desc.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import './team-form.css';

export class OnboardingTeamForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      employees: props.employees.concat([{}, {}, {}]),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => {
      return {
        employees: nextProps.employees.concat([{}, {}, {}]),
      };
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let data = this.state.employees
      .filter((e) => !e.id)
      .filter((e) => e.email)
      .map((e) => {
        if (!e.customer_role_id)
          e.customer_role_id =
            this.props.customerRoles[this.props.customerRoles.length - 1].id;
        return e;
      });
    this.props.actions.doInviteEmployees(data);
    this.props.onSubmit(data);
  };

  handleChangeEmployeeAdmin = (emp, key) => {
    return (e, { value }) => {
      this.setState((prev) => {
        return {
          employees: prev.employees.map((pemp, pkey) => {
            if (key !== pkey) return pemp;
            pemp.customer_role_id = value;
            return pemp;
          }),
        };
      });
    };
  };

  handleChangeEmployeeEmail = (emp, key) => {
    return (e, { value }) => {
      this.setState((prev) => {
        return {
          employees: prev.employees.map((pemp, pkey) => {
            if (key !== pkey) return pemp;
            pemp.email = value.trim();
            return pemp;
          }),
        };
      });
    };
  };

  handleBlurEmployeeEmail = (emp, key) => {
    return () => {
      this.setState((prev) => {
        return {
          employees: prev.employees.map((pemp, pkey) => {
            if (key !== pkey) return pemp;
            pemp.email = ((pemp.email || '').match(REGEX_EMAIL) || [''])[0];
            return pemp;
          }),
        };
      });
    };
  };

  handleAddMore = () => {
    this.setState((prev) => {
      return {
        employees: prev.employees.concat({
          email: '',
        }),
      };
    });
  };

  _createRoleOptions = () => {
    return this.props.customerRoles
      .filter((item) => item.name !== 'OfficeLuv Attendant')
      .map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
  };

  _getcustomerRoleValue = (emp) => {
    if (emp.customer_role_id) {
      return emp.customer_role_id;
    }
    if (this.props.customerRoles.length) {
      return this.props.customerRoles[this.props.customerRoles.length - 1].id;
    }
    return null;
  };

  renderEmployeeWithAdmin = (emp, key) => {
    return (
      <Form.Group key={emp.id || key} unstackable>
        <Form.Input
          width={12}
          readOnly={!!emp.invitation_accepted_at}
          name="employee[]email"
          placeholder="Enter email address"
          value={emp.email}
          onBlur={this.handleBlurEmployeeEmail(emp, key)}
          onChange={this.handleChangeEmployeeEmail(emp, key)}
        />
        <Form.Dropdown
          selection
          width={7}
          disabled={!!emp.id}
          onChange={this.handleChangeEmployeeAdmin(emp, key)}
          options={this._createRoleOptions()}
          value={this._getcustomerRoleValue(emp)}
          className="onboarding-team-form-dropdown"
        />
      </Form.Group>
    );
  };

  render() {
    return (
      <div className="onboarding-team-form">
        <h2
          style={{
            fontWeight: '700',
            marginBottom: '2rem',
          }}>
          Your Team
        </h2>
        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}>
          Invite your manager or fellow team members to collaborate on the
          OfficeLuv ordering platform. You can always add or remove members and
          change permissions in Settings.
        </p>
        <Segment basic loading={this.props.pageIsLoading}>
          <Form onSubmit={this.handleSubmit}>
            <label>Invite New Users</label>
            {this.state.employees.map(this.renderEmployeeWithAdmin)}
            <Form.Group>
              <Form.Field width={16}>
                <Button
                  basic
                  width={4}
                  size="mini"
                  style={{
                    color: this.props.location.brand_color || '#00a98c',
                    boxShadow: 'none',
                  }}
                  type="button"
                  onClick={this.handleAddMore}>
                  <span
                    style={{
                      color: this.props.location.brand_color || '#00a98c',
                    }}>
                    + Add More
                  </span>
                </Button>
              </Form.Field>
              <Form.Field width={2}>
                <ModalCustomerRolesDesc canManageRoles={false} />
              </Form.Field>
            </Form.Group>
            <Form.Group
              style={{
                marginTop: '2rem',
              }}>
              <Form.Button
                width={8}
                fluid
                primary
                style={{
                  backgroundColor: this.props.location.brand_color || '#00a98c',
                }}>
                Next
              </Form.Button>
              <Form.Field width={8}>
                <StepMarker
                  stepNumber={this.props.stepNumber}
                  stepTotal={this.props.stepTotal}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </div>
    );
  }
}

OnboardingTeamForm.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invitation_accepted_at: PropTypes.string,
      customer_role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        can_manage_employees_locations: PropTypes.bool.isRequired,
      }).isRequired,
    })
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    doInviteEmployees: PropTypes.func.isRequired,
  }).isRequired,
  customerRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      can_manage_employees_locations: PropTypes.bool.isRequired,
    })
  ).isRequired,
  pageIsLoading: PropTypes.bool.isRequired,
};

OnboardingTeamForm.defaultProps = {
  stepTotal: 5,
  stepNumber: 3,
  onSubmit: function () {},
};

function mapStateToProps(state) {
  const sortedEmployees = state.employees.items
    .filter((a) => {
      return (
        a.id !== state.employees.user.id &&
        a.locations.map((l) => l.id).indexOf(state.locations.open.id) > -1
      );
    })
    .sort(SORT_BY_ID);
  const pageIsLoading = !!state.customerRoles.requesting.filter(
    FILTER_GET_INDEX_REQUEST
  ).length;
  return {
    employees: sortedEmployees,
    location: state.locations.open,
    user: state.employees.user,
    customerRoles: Object.values(state.customerRoles.items),
    pageIsLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doInviteEmployees,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.items === next.employees.items &&
    prev.employees.user === next.employees.user &&
    prev.employees.isRequesting === next.employees.isRequesting &&
    prev.locations.open === next.locations.open &&
    prev.customerRoles.items === next.customerRoles.items &&
    prev.customerRoles.requesting === next.customerRoles.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingTeamForm);
