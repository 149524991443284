import React from 'react';

const BudgetBasedOnSpend = ({
  spendPerEmployee,
  budgetBasedOnDateRange,
  displayBudgetBasedOnDateRange,
}) => {
  return (
    <React.Fragment>
      <h5 className="total-spend-header hide-when-printing">
        <span>Spend Per Employee</span>
        <strong>{spendPerEmployee}</strong>
      </h5>
      {displayBudgetBasedOnDateRange && (
        <h5 className="total-spend-header hide-when-printing">
          <span>budget based on selected date range</span>
          <strong>{budgetBasedOnDateRange}</strong>
        </h5>
      )}
    </React.Fragment>
  );
};

export default BudgetBasedOnSpend;
