import * as types from '../actions/action-types.js';

export const empty = {
  items: [],
  updating: [],
  creating: [],
  requesting: [],
  errors: [],
  open: {
    id: 0,
  },
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.OPEN_PRODUCT_BRAND:
      return {
        ...state,
        open: action.data,
      };
    case types.CLOSE_PRODUCT_BRAND:
      return {
        ...state,
        open: empty.open,
      };
    case types.GET_PRODUCT_BRANDS:
      return Object.assign({}, state, {
        requesting: state.requesting.concat({
          _request: types.GET_PRODUCT_BRANDS,
        }),
      });
    case types.SUCCESS_GET_PRODUCT_BRANDS:
      return Object.assign({}, state, {
        items: action.data,
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PRODUCT_BRANDS;
        }),
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
      });
    case types.ERROR_GET_PRODUCT_BRANDS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PRODUCT_BRANDS;
        }),
        errors: state.errors.concat({
          _request: types.GET_PRODUCT_BRANDS,
          data: action.data,
        }),
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
