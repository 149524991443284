import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelect from '@officeluv/react-multi-select';
import { sortProductBrands } from '../../lib/formatters.js';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import { doGetProductBrands } from '../../actions/product-brand-actions.js';
import './product-filters.css';

export class ProductBrandFilter extends React.PureComponent {
  componentDidMount() {
    if (this.props.allBrands.length > 0) {
      return;
    }
    this.props.actions.doGetProductBrands();
  }

  componentWillUnmount() {
    this.props.actions.doGetProductBrands();
  }

  handleSearchChange = (value) => {
    const trimmed = value.trim();

    if (trimmed.length > 0) {
      this.props.actions.doGetProductBrands(value);
    }
    if (!trimmed) {
      this.props.actions.doGetProductBrands();
    }
  };

  _options() {
    const { allBrands, brandFilters } = this.props;
    if (!allBrands) return [];
    const labels = sortProductBrands(allBrands, brandFilters).map((c) => ({
      label: c.value,
      value: c.value,
    }));
    return labels;
  }

  _selectedOptions() {
    if (!this.props.allBrands || this.props.allBrands.length === 0) {
      return [];
    }
    return this.props.brandFilters;
  }

  _valueRenderer(selected, options) {
    if (selected.length === 0) {
      return 'Filter by Brand';
    }
    return selected.join(', ');
  }

  onSelectedChanged = (data) => {
    this.props.actions.setProductQuery({
      filters: {
        brandNames: data,
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
  };

  render() {
    return (
      <div className="product-filter">
        <MultiSelect
          overrideStrings={{ selectSomeItems: 'Filter by Brand' }}
          hasSelectAll={false}
          options={this._options()}
          onSelectedChanged={this.onSelectedChanged}
          onSearchChange={this.handleSearchChange}
          selected={this._selectedOptions()}
          valueRenderer={this._valueRenderer}
        />
      </div>
    );
  }
}

ProductBrandFilter.propTypes = {
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
    doGetProductBrands: PropTypes.func.isRequired,
  }).isRequired,
  allBrands: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ).isRequired,
  brandFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export function mapStateToProps(state) {
  return {
    allBrands: state.productBrands.items,
    brandFilters: state.productQuery.filters.brandNames,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
        doGetProductBrands,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productBrands.items === next.productBrands.items &&
    prev.productQuery.filters.brandNames ===
      next.productQuery.filters.brandNames
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductBrandFilter);
