import LogRocket from 'logrocket';
import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from './logger';
import storage from './storage.js';
import router from './router.js';
import perf from './perf.js';
import notifications from './notifications.js';

const middlewares = applyMiddleware(
  thunkMiddleware,
  logger,
  router,
  storage,
  perf,
  notifications,
  LogRocket.reduxMiddleware({
    stateSanitizer: function (state) {
      return {
        cleanings: {
          open: state.cleanings.open,
        },
        employees: {
          user: state.employees.user,
        },
        locations: {
          open: state.locations.open,
        },
        requisitions: {
          open: state.requisitions.open,
          editing: state.requisitions.editing,
        },
        scheduledRequisitions: {
          open: state.scheduledRequisitions.open,
          staged: state.scheduledRequisitions.staged,
        },
        shipments: {
          open: state.shipments.open,
        },
        staffs: {
          user: state.staffs.user,
        },
        tasks: {
          open: state.tasks.open,
        },
        auth: {
          role: state.auth.role,
        },
        carts: {
          open: state.carts.open,
        },
        featureTours: {
          open: state.featureTours.open,
        },
        featureTourAttempts: {
          open: state.featureTourAttempts.open,
          summary: state.featureTourAttempts.summary,
        },
        punchoutSessions: {
          open: state.punchoutSessions.open,
        },
      };
    },
  }) /* LogRocket should be final middleware */
);

export default middlewares;
