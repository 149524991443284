import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon, Form, Message } from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import { RRule } from 'rrule';
import moment from 'moment';
import InputLabel from '../forms/input-label.js';
import InputRrule from '../forms/input-rrule.js';
import {
  humanInterval,
  scheduleHasChanged,
} from '../../helpers/scheduled-requisition-helpers.js';
import { formatDate } from '../../lib/formatters.js';
import 'semantic-ui-css/components/accordion.min.css';
import './schedule-form.css';

export class ScheduleForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editMode: props.isDisabled || props.editModeOverride,
      focused: false,
    };
  }

  handleClick = () => {
    this.setState(() => ({
      editMode: !this.state.editMode,
    }));
  };

  scheduleHasChanged = () => {
    return scheduleHasChanged(
      this.props.schedulable,
      this.props.originalFormData
    );
  };

  constructDateRange = (value, start_at) => {
    const rrule = RRule.fromString(value);
    rrule.options.count = 10;
    rrule.options.dtstart = start_at;
    return rrule.all();
  };

  onChangeRruleField = (value) => {
    this.props.onChangeField('rrule', value.replace(/RRULE:/, ''));
    const startDate = this.props.schedulable.expected_at
      ? new Date(this.props.schedulable.expected_at)
      : new Date();
    const range = this.constructDateRange(value, startDate);
    const nextStartAt = range.filter((date) => {
      return date > moment().add(1, 'weeks');
    })[0];
    const hour = (RRule.fromString(value).options.byhour || [10])[0];
    this.props.onChangeField(
      'start_at',
      moment(nextStartAt).startOf('day').hour(hour)
    );
    this.props.onChangeField(
      'expected_at',
      moment(nextStartAt).startOf('day').hour(hour)
    );
    this.props.onChangeField('_update_future_occurrences', '1');
  };

  onChangeFocus = (focused) => {
    this.setState(() => {
      return focused;
    });
  };

  onChangeDate = (start_at) => {
    this.props.onChangeField('start_at', moment(start_at).startOf('day'));
  };

  onChangePaused = (paused) => {
    const active = paused ? 'paused' : 'active';
    this.props.onChangeField('status', active);
  };

  getScheduleText() {
    if (!this.props.schedulable.rrule) {
      return 'Please enter a frequency';
    }
    let base = 'Arrives ';
    base += humanInterval(this.props.schedulable);
    base += ' ';
    if (this.props.schedulable.approve_automatically) {
      base += '(automatically ';
    } else {
      base += '(manually ';
    }
    base += 'confirmed)';
    return base;
  }

  getHourString(num) {
    if (num === 12) {
      return `${num}:00pm`;
    }
    if (num > 12) {
      return `${num - 12}:00pm`;
    }
    return `${num}:00am`;
  }

  getHours() {
    const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14];
    return hours.map((num) => ({
      key: num,
      text: this.getHourString(num),
      value: num,
    }));
  }

  isDayBlocked = (day) => {
    let minMoment = moment().add(6, 'days');
    if (!this.props.schedulable.rrule) return day.isBefore(minMoment);
    if (day.isBefore(minMoment)) return true;
    const range = this.constructDateRange(
      this.props.schedulable.rrule,
      day.startOf('day').toDate()
    );
    return !range[0] || !day.isSame(range[0], 'day');
  };

  render() {
    if (!this.props.schedulable) return null;

    return (
      <Accordion className="schedule-form">
        <Accordion.Title
          active={this.state.editMode}
          index={0}
          onClick={this.handleClick}>
          <div>
            <Icon name="dropdown" />
            {this.getScheduleText()}
          </div>
        </Accordion.Title>
        <Accordion.Content active={this.state.editMode}>
          <div className="accordion-content">
            <div>
              {this.props.schedulable && (
                <InputRrule
                  onChange={this.onChangeRruleField}
                  value={this.props.schedulable.rrule || ''}
                />
              )}
              {this.props.schedulable && this.scheduleHasChanged() && (
                <Message
                  warning
                  header="Warning"
                  style={{ marginBottom: '1rem', display: 'block' }}
                  content={
                    <p>
                      Changing the schedule will update this and all future
                      orders for <strong>{this.props.schedulable.name}</strong>{' '}
                      and will move this order's arrival to{' '}
                      <strong>
                        {formatDate(this.props.schedulable.start_at)}
                      </strong>
                    </p>
                  }
                />
              )}
            </div>
            <Form.Group>
              {this.props.schedulable.id < 1 && (
                <Form.Field>
                  <InputLabel text="Start Date" />
                  <div>
                    <SingleDatePicker
                      date={this.props.schedulable.start_at}
                      onDateChange={this.onChangeDate}
                      focused={this.state.focused}
                      onFocusChange={this.onChangeFocus}
                      isDayBlocked={this.isDayBlocked}
                      isOutsideRange={this.checkIfOutsideRange}
                      id="recurring-requisition-start-at"
                    />
                  </div>
                </Form.Field>
              )}
              <Form.Select
                label="Confirm Orders by"
                name="expiration_hour"
                value={this.props.schedulable.expiration_hour}
                onChange={(e, { value }) => {
                  this.props.onChangeField('expiration_hour', value);
                }}
                options={this.getHours()}
              />
              <Form.Field>
                <Form.Radio
                  style={{ margin: '1.25rem 0 0.25rem' }}
                  label="Confirm manually"
                  checked={!this.props.schedulable.approve_automatically}
                  onChange={() => {
                    this.props.onChangeField('approve_automatically', false);
                  }}
                />
                <Form.Radio
                  label="Confirm automatically"
                  checked={this.props.schedulable.approve_automatically}
                  onChange={() => {
                    this.props.onChangeField('approve_automatically', true);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </div>
        </Accordion.Content>
      </Accordion>
    );
  }
}

ScheduleForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUncancel: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  schedulable: PropTypes.shape({
    id: PropTypes.number,
    rrule: PropTypes.string,
    location_id: PropTypes.number,
    approve_automatically: PropTypes.bool,
    start_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(moment),
    ]),
    archived: PropTypes.bool,
    expiration_hour: PropTypes.number,
  }),
  originalFormData: PropTypes.shape({
    id: PropTypes.number,
    rrule: PropTypes.string,
    location_id: PropTypes.number,
    approve_automatically: PropTypes.bool,
    start_at: PropTypes.string,
    archived: PropTypes.bool,
    expiration_hour: PropTypes.number,
  }),
};

ScheduleForm.defaultProps = {
  onCancel: () => {},
  onUncancel: () => {},
};

export default ScheduleForm;
