import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProgressPie from './progress-pie.js';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';

export class SpendToBudgetProgressPie extends React.PureComponent {
  _buildSpend = () => {
    return this.props.dataViews.reduce(
      (dvacc, dval) => {
        return dval.data.reduce((acc, val) => {
          acc.spend += val.customer_purchases_total_spend;
          acc.budget += this._locationBudgetAnnual(val) / 365;
          return acc;
        }, dvacc);
      },
      { budget: 0, spend: 0 }
    );
  };

  _locationBudgetAnnual = (val) => {
    const found = this.props.locationHash[val.location_id];
    if (!found) return val.location_budget_annual;

    return found.budget_annual;
  };

  renderEmpty = () => {
    return (
      <div className="spend-to-budget-progress-pie empty hide-when-printing">
        <Segment placeholder attached="top">
          <Header icon>
            <Icon name="chart pie" color="grey" />
            Spend to Budget
          </Header>
          <p>Complete your expense reports in a flash.</p>
          <p style={{ marginTop: '1rem' }}>
            Be notified when your office spend reaches a percentage of your
            monthly budget.
          </p>
          <p style={{ marginTop: '1rem' }}>
            <strong>First, set your budget above.</strong>
          </p>
        </Segment>
      </div>
    );
  };

  render() {
    const data = this._buildSpend();
    if (!data.budget) {
      return this.renderEmpty();
    }
    let percent =
      Math.round((100 * data.spend) / data.budget).toLocaleString() + '%';
    return (
      <div className="spend-to-budget-progress-pie">
        <Header className="borderless" attached="top" as="h2">
          Spend to Budget
        </Header>
        <Segment
          attached
          loading={this.props.isRequesting}
          className="borderless">
          <ProgressPie percent={(100 * data.spend) / data.budget} />
        </Segment>
        <Segment attached="bottom" className="borderless">
          <p>
            Your total spend of {formatCentAmount(data.spend)} is {percent} of
            your total expected budget within the selected date range.
          </p>
          {!this.props.budgetSpendPreference && (
            <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>
              Be notified when your office spend reaches a percentage of your
              monthly budget.
              <br />
              <Button style={{ marginTop: '1rem' }} secondary size="mini">
                <Link
                  to="/settings/user#user-notifications"
                  className="primary">
                  Set Up Notifications
                </Link>
              </Button>
            </p>
          )}
        </Segment>
      </div>
    );
  }
}

SpendToBudgetProgressPie.propTypes = {
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          customer_purchases_count: PropTypes.number,
          customer_purchases_total_spend: PropTypes.number,
          location_budget_annual: PropTypes.number,
          submitted_date: PropTypes.string,
          location_id: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  budgetSpendPreference: PropTypes.number,
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  locationHash: PropTypes.objectOf(
    PropTypes.shape({
      budget_annual: PropTypes.number,
    })
  ).isRequired,
};

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_dates';
function mapStateToProps(state) {
  const locations = state.dataViews.openLocations.length
    ? state.dataViews.openLocations
    : [state.locations.open];
  const locationIds = locations.map((l) => l.id);
  const startDate = state.dataViews.openRange.startDate;
  const endDate = state.dataViews.openRange.endDate;
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === startDate.toJSON() &&
        d.end === endDate.toJSON() &&
        locationIds.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === startDate.toJSON() &&
          d.end === endDate.toJSON() &&
          locationIds.indexOf(d.topic.id) > -1
      ).length > 0,
    budgetSpendPreference:
      state.employees.user.pref_notify_location_spend_budget_month_threshold,
    locationHash: locations.reduce((acc, loc) => {
      acc[loc.id] = loc;
      return acc;
    }, {}),
    startDate,
    endDate,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.isRequesting === next.dataViews.isRequesting &&
    prev.employees.user.pref_notify_location_spend_budget_month_threshold ===
      next.employees.user.pref_notify_location_spend_budget_month_threshold &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  SpendToBudgetProgressPie
);
