import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import StripeBillingInfoForm from './stripe-billing-info-form.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/table.min.css';

export default class StripeBillingInfoSetup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  handleToggleEdit = () => {
    this.setState((prev) => {
      return {
        editing: !prev.editing,
      };
    });
  };

  render() {
    if (this.state.editing) {
      return (
        <StripeBillingInfoForm
          action="create"
          onSuccess={this.handleToggleEdit}
          onCancel={this.handleToggleEdit}
        />
      );
    }
    return (
      <Table basic="very" className="stripe-billing-info">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Credit Card</Table.HeaderCell>
            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Button
                size="mini"
                compact
                onClick={this.handleToggleEdit}
                className="tertiary">
                Set Credit Card
              </Button>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

StripeBillingInfoSetup.propTypes = {
  canEdit: PropTypes.bool,
};
