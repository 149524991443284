import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message } from 'semantic-ui-react';
import { doRequestCategorySpendAlert } from '../../actions/email-actions.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';

export class CategorySpendAlertRequestForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displaySuccess: false,
    };
  }

  handleSubmit = () => {
    this.props.actions.doRequestCategorySpendAlert();
    this.setState(() => {
      return {
        displaySuccess: true,
      };
    });
  };

  render() {
    return (
      <div className="ach-request-wrap" style={this.props.style}>
        {this.state.displaySuccess && (
          <Message success style={{ display: 'block' }}>
            <Message.Header>Request Processing</Message.Header>
            <p>
              Our support team will reach out to you with next steps to set up
              spend notifications by category.
            </p>
          </Message>
        )}
        {!this.state.displaySuccess && (
          <Form onSubmit={this.handleSubmit}>
            <p>
              You can also be notified when category spend reaches a certain
              level.
            </p>
            <Form.Button secondary size="tiny">
              Request Spend Notifications By Category
            </Form.Button>
          </Form>
        )}
      </div>
    );
  }
}

CategorySpendAlertRequestForm.propTypes = {
  actions: PropTypes.shape({
    doRequestCategorySpendAlert: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
};

CategorySpendAlertRequestForm.defaultProps = {
  onSubmit: function () {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doRequestCategorySpendAlert,
      },
      dispatch
    ),
  };
}

function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  CategorySpendAlertRequestForm
);
