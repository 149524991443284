import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import { formatDateTime, formatEmployeeName } from '../../lib/formatters.js';
import { Message } from 'semantic-ui-react';

const ExpirationStatus = (props) => {
  const sr = props.scheduledRequisition;
  if (!sr) return null;

  if (helpers.isExpired(sr) && !helpers.isApproved(sr)) {
    return (
      <Message error>
        This order expired {formatDateTime(sr.expiration_at)}.
      </Message>
    );
  }

  if (sr.approve_automatically && !helpers.isApproved(sr)) {
    return (
      <Message>
        This order will be automatically confirmed{' '}
        <strong>{formatDateTime(sr.expiration_at)}</strong>.
      </Message>
    );
  }

  if (!sr.approve_automatically && !helpers.isApproved(sr)) {
    return (
      <Message warning>
        This order will be cancelled{' '}
        <strong>{formatDateTime(sr.expiration_at)}</strong> unless confirmed.
      </Message>
    );
  }

  if (!sr.approve_automatically && helpers.isApproved(sr)) {
    return (
      <Message>
        This order was confirmed {formatDateTime(sr.approved_at)} by{' '}
        {formatEmployeeName(sr.approver)}
      </Message>
    );
  }

  return (
    <Message>
      This order was automatically confirmed {formatDateTime(sr.expiration_at)}
    </Message>
  );
};

ExpirationStatus.propTypes = {
  scheduledRequisition: PropTypes.object,
};

export default ExpirationStatus;
