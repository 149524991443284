import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import * as queryParser from '../lib/query-parser.js';
import cookies from '../lib/encrypted-cookies';
import setTitle from '../decorators/set-title.js';
import { doGetWithToken } from '../actions/onboarding-actions.js';
import { signOut } from '../actions/auth-actions.js';
import OnboardingStep from '../components/onboarding/step.js';
import OnboardingUserForm from '../components/onboarding/user-form.js';
import { AUTH_COOKIE, AUTH_STATUS_CHECK_INTERVAL_MS } from '../strings.js';
import { isAdmin } from '../helpers/customer-role-helpers.js';

export class OnboardingUser extends React.Component {
  componentDidMount() {
    const query = queryParser.parse(this.props.location.search);
    if (!query.invitation_token) {
      browserHistory.push(this.nextRoute());
      return;
    } else if (query.invitation_token === 'resume') {
      return;
    } else if (this.props.auth.hmac) {
      this._signOut();
    }
    this.props.actions.doGetWithToken({
      token: query.invitation_token,
    });
    this.intervalId = window.setInterval(
      this.checkAuthStatus,
      AUTH_STATUS_CHECK_INTERVAL_MS
    );
  }

  componentDidUpdate() {
    if (this.props.auth.hmac) {
      browserHistory.push(this.nextRoute());
    } else if (this.props.invitationInvalid) {
      browserHistory.push('/sign-in');
    }
  }

  componentWillUnmount() {
    this.intervalId && window.clearInterval(this.intervalId);
  }

  checkAuthStatus = () => {
    if (
      this.previousCookie &&
      this.previousCookie !== cookies.get(AUTH_COOKIE)
    ) {
      this._signOut();
      browserHistory.push('/sign-in');
    }
    this.previousCookie = cookies.get(AUTH_COOKIE);
  };

  _signOut = () => {
    this.intervalId && window.clearInterval(this.intervalId);
    this.props.actions.signOut();
  };

  handleButton = () => {};

  nextRoute = () => {
    return isAdmin(this.props.roleName) ? this.props.nextRoute : '/';
  };

  render() {
    return (
      <OnboardingStep backgroundPosition="right top">
        <OnboardingUserForm />
      </OnboardingStep>
    );
  }
}

OnboardingUser.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  auth: PropTypes.shape({
    hmac: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  }).isRequired,
  roleName: PropTypes.string.isRequired,
  invitationInvalid: PropTypes.bool,
  nextRoute: PropTypes.string.isRequired,
};

OnboardingUser.defaultProps = {
  nextRoute: '/onboarding/company',
};

OnboardingUser = setTitle(() => {
  return 'OfficeLuv Onboarding';
})(OnboardingUser);

function mapStateToProps(state) {
  return {
    auth: state.auth,
    roleName: state.auth.role.name,
    invitationInvalid: state.onboarding.error
      ? state.onboarding.error.response && state.onboarding.error.status < 500
      : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ doGetWithToken, signOut }, dispatch),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth === next.auth && prev.onboarding.error === next.onboarding.error
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingUser);
