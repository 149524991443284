import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';
import StepMarker from './step-marker.js';
import CreditCardInformation from '../credit-card-information.js';
import AchRequestForm from '../customers/ach-request-form.js';
import {
  submitLocationStripeExternalAccount,
  doGetLocationExternalAccounts,
  submitUpdateLocation,
} from '../../actions/locations-actions.js';
import 'semantic-ui-css/components/form.min.css';

export class OnboardingBillingInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      _request: Math.random().toString(),
    };
  }

  componentDidMount() {
    if (!this.props.location.external_accounts) {
      this.props.actions.doGetLocationExternalAccounts(this.props.location);
    }
  }

  handleToken = (payment_method_id) => {
    let data = {
      id: this.props.location.id,
      _request: this.state._request,
      stripe: {
        email: this.props.user.email,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        payment_method_id,
      },
    };
    this.props.actions
      .submitLocationStripeExternalAccount(data, {
        bubble: true,
      })
      .then(() => {
        this.props.actions.submitUpdateLocation({
          id: this.props.location.id,
          onboarded_at: new Date(),
        });
      });
    this.props.onSubmit(data);
  };

  render() {
    return (
      <div className="onboarding-billing-form">
        <h2
          style={{
            fontWeight: '700',
            marginBottom: '2rem',
          }}>
          Let's Get Started
        </h2>
        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}>
          Thank you for taking the time to complete your profile. Now we just
          need some billing information to finalize your account.
        </p>
        <CreditCardInformation
          renderSubmitButton={false}
          onToken={this.handleToken}
          loading={this.props.isRequesting}
          firstName={this.props.user.first_name}
          lastName={this.props.user.last_name}
          address1={this.props.location.address}
          address2={this.props.location.address2}
          city={this.props.location.city}
          state={this.props.location.state}
          postalCode={this.props.location.zip}>
          <div className="ui form">
            <Form.Group
              style={{
                marginTop: '2rem',
              }}>
              <Form.Button
                width={8}
                fluid
                primary
                style={{
                  backgroundColor: this.props.location.brand_color || '#00a98c',
                }}>
                Get Started
              </Form.Button>
              <Form.Field width={8}>
                <StepMarker
                  stepNumber={this.props.stepNumber}
                  stepTotal={this.props.stepTotal}
                />
              </Form.Field>
            </Form.Group>
          </div>
        </CreditCardInformation>
        <AchRequestForm style={{ marginTop: '4rem' }} />
      </div>
    );
  }
}

OnboardingBillingInfoForm.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    submitLocationStripeExternalAccount: PropTypes.func.isRequired,
    doGetLocationExternalAccounts: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

OnboardingBillingInfoForm.defaultProps = {
  stepTotal: 5,
  stepNumber: 5,
  onSubmit: function () {},
};

function mapStateToProps(state) {
  return {
    isRequesting: state.locations.requesting.length > 0,
    location: state.locations.open,
    user: state.employees.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationStripeExternalAccount,
        doGetLocationExternalAccounts,
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.locations.requesting === next.locations.requesting &&
    prev.employees.user === next.employees.user
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingBillingInfoForm);
