import React from 'react';
import AuthCheck from '../components/auth-check.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import PromotedBrandsContainer from '../components/promotedBrands/promoted-brands-container.js';

export class PromotedBrands extends React.PureComponent {
  render() {
    return (
      <div>
        <AuthCheck />
        <MountAtPageTop />
        <NavBarAttendant />
        <PromotedBrandsContainer />
      </div>
    );
  }
}

PromotedBrands.propTypes = {};

export default PromotedBrands;
