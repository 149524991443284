import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { Dropdown } from 'semantic-ui-react';
import MultiSelect from '../../components/multiSelect';
import {
  trackInterfaceBudgetsChangeLocations,
  trackInterfaceBudgetsChangeDateRange,
} from '../../lib/analytics.js';
import 'react-dates/lib/css/_datepicker.css';
import 'semantic-ui-css/components/dropdown.min.css';
import './controls.css';
const END_DATE_ID = 'endDate';
const START_DATE_ID = 'startDate';

export class InsightsControls extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusedDateInput: null,
      startDate: props.startDate || moment().subtract(30, 'days'),
      endDate: props.endDate || moment().add(7, 'days'),
      locationOptions: this._buildLocationsOptions(props),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let hasFinishedSelectingDate =
      prevState.focusedDateInput && !this.state.focusedDateInput;
    hasFinishedSelectingDate =
      hasFinishedSelectingDate ||
      (prevState.startDate !== this.state.startDate &&
        prevState.endDate !== this.state.endDate);
    if (hasFinishedSelectingDate) {
      this.props.onChangeDates({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      });
      trackInterfaceBudgetsChangeDateRange(this.state);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        locationOptions: this._buildLocationsOptions(nextProps),
        startDate: nextProps.startDate || this.state.startDate,
        endDate: nextProps.endDate || this.state.endDate,
      })
    );
  }

  _setCalendarDisplay = () => {
    if (!this.props.windowWidth || this.props.windowWidth > 650) {
      return 2;
    }
    return 1;
  };

  _buildLocationsOptions = (props) => {
    return props.allLocations.map((loc) => {
      return { value: loc.id, label: loc.name };
    });
  };

  hasMultipleLocations = () => {
    return this.props.openLocations.length > 1;
  };

  getTitle = () => {
    if (this.hasMultipleLocations()) {
      return (
        <h3 className="intro--budget-insights-reporting">
          Total Spend Calculator
        </h3>
      );
    }
    return (
      <h2 className="intro--budget-insights-reporting">Spend Calculator</h2>
    );
  };

  onFocusChanged = (focusedDateInput) => {
    this.setState((prev) => {
      return {
        focusedDateInput,
        endDate: focusedDateInput
          ? prev.endDate
          : prev.endDate || moment(prev.startDate).add(7, 'days'),
        startDate: focusedDateInput
          ? prev.startDate
          : prev.startDate || moment(prev.endDate).subtract(30, 'days'),
      };
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    if (
      startDate &&
      endDate &&
      startDate.isSame(this.state.startDate) &&
      endDate.isSame(this.state.endDate)
    ) {
      return;
    }
    let focusedDateInput =
      endDate && endDate.isSame(this.state.endDate) ? END_DATE_ID : null;
    if (!endDate || !startDate) {
      focusedDateInput = this.state.focusedDateInput;
    }
    this.setState(
      Object.assign({}, this.state, {
        startDate,
        endDate,
        focusedDateInput,
      })
    );
  };

  isOutsideRange = (d) => {
    if (this.state.focusedDateInput === END_DATE_ID) {
      return d < this.state.startDate;
    }
    return false;
  };

  _onInsightsLocationSelect = (selected) => {
    let locations = this.props.allLocations.filter(
      (loc) => selected.indexOf(loc.id) > -1
    );
    if (!locations.length) {
      return;
    }
    this.props.onChangeLocations(locations);
    trackInterfaceBudgetsChangeLocations(locations);
  };

  _locationValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return 'Select a Location...';
    }

    if (selected.length === options.length) {
      return 'Viewing All Locations';
    }

    if (selected.length === 1) {
      return;
    }

    return `Viewing ${selected.length} Locations`;
  };

  _selectedLocations = () => {
    return this.props.openLocations.map((l) => l.id);
  };

  datePresetOptions = () => {
    const options = [
      {
        text: 'Last Year',
        key: 'last_year',
        startDate: moment().startOf('year').subtract(1, 'week').startOf('year'),
        endDate: moment().startOf('year').subtract(1, 'day'),
      },
      {
        text: 'Last Quarter',
        key: 'last_quarter',
        startDate: moment()
          .startOf('quarter')
          .subtract(1, 'week')
          .startOf('quarter'),
        endDate: moment().startOf('quarter').subtract(1, 'day'),
      },
      {
        text: 'Last Month',
        key: 'last_month',
        startDate: moment()
          .startOf('month')
          .subtract(1, 'week')
          .startOf('month'),
        endDate: moment().startOf('month').subtract(1, 'day'),
      },
      {
        text: 'Current Month',
        key: 'current_month',
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
    ];
    return options.map((opt) => {
      const active =
        this.state.startDate &&
        this.state.startDate.isSame(opt.startDate, 'day') &&
        this.state.endDate.isSame(opt.endDate, 'day');
      return {
        ...opt,
        active,
        value: `${opt.startDate.format('YYYY-MM-DD')}-${opt.endDate.format(
          'YYYY-MM-DD'
        )}`,
      };
    });
  };

  handlePresetDateChange = (e, obj) => {
    let option = obj.options.filter((o) => o.value === obj.value)[0];
    this.onDatesChange({
      startDate: option.startDate,
      endDate: option.endDate,
    });
  };

  render() {
    return (
      <div className="insights-controls selector-container flex-wrapper hide-when-printing">
        <div className="intro--budget-insights-controls">
          <div className="flex-item">
            {this.props.allLocations && this.props.allLocations.length > 1 && (
              <div className="location-select">
                <MultiSelect
                  valueRenderer={this._locationValueRenderer}
                  selected={this._selectedLocations()}
                  onSelectedChanged={this._onInsightsLocationSelect}
                  selectAllLabel="All Locations"
                  options={this.state.locationOptions}
                />
              </div>
            )}
            <div className="date-picker">
              <DateRangePicker
                numberOfMonths={this._setCalendarDisplay()}
                keepOpenOnDateSelect
                startDateId={START_DATE_ID}
                endDateId={END_DATE_ID}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedDateInput}
                isOutsideRange={this.isOutsideRange}
                onFocusChange={this.onFocusChanged}
              />
            </div>
            <Dropdown
              text={
                (
                  this.datePresetOptions().filter((a) => a.active)[0] || {
                    text: 'Custom Range',
                  }
                ).text
              }
              selection
              onChange={this.handlePresetDateChange}
              value={
                (this.datePresetOptions().filter((a) => a.active)[0] || {})
                  .value
              }
              options={this.datePresetOptions()}
            />
          </div>
        </div>
        <div className="flex-item report-download-container">
          {this.getTitle()}
        </div>
      </div>
    );
  }
}

InsightsControls.propTypes = {
  openLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  allLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  windowWidth: PropTypes.number,
  onChangeLocations: PropTypes.func.isRequired,
  onChangeDates: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    allLocations: Object.values(state.locations.items),
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return prev.locations.items === next.locations.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsControls);
