import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'intro.js-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'intro.js/introjs.css';
import './intro.css';
import { highlightNextStep, allFeatureTourSteps } from './config.js';
import { closeFeatureTour } from '../../ducks/feature-tours.js';
import { doCreateFeatureTourAttempt } from '../../ducks/feature-tour-attempts.js';
const OPTIONS = {
  showStepNumbers: false,
  positionPrecedence: ['left', 'right', 'top', 'bottom'],
  tooltipPosition: 'auto',
  scrollToElement: false,
};

export class FeatureTour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
    };
  }

  getSteps = () => {
    return (
      allFeatureTourSteps[this.props.featureTour.name] ||
      function () {
        return [];
      }
    )(this.props.role);
  };

  featureTourAttemptId = () => {
    return this.props.featureTourAttempt
      ? this.props.featureTourAttempt.id
      : undefined;
  };

  updateFeatureTourStepAttempt = (nextStepIndex) => {
    let data = {
      employee_id: this.props.actor.id,
      feature_tour_id: this.props.featureTour.id,
      steps_completed: nextStepIndex + 1,
      id: this.featureTourAttemptId(),
    };
    this.props.actions.doCreateFeatureTourAttempt(data);
  };

  skipFeatureTour = () => {
    this.props.actions.doCreateFeatureTourAttempt({
      employee_id: this.props.actor.id,
      id: this.featureTourAttemptId(),
      feature_tour_id: this.props.featureTour.id,
      skipped_at: new Date(),
    });
  };

  onBeforeChange = (nextStepIndex) => {
    this.steps && this.steps.updateStepElement(nextStepIndex);
    const nextStep = this.getSteps()[nextStepIndex].element;
    if (this.getSteps()[nextStepIndex].scrollToTop) {
      window.scrollTo(0, 0);
    }
    this.updateFeatureTourStepAttempt(nextStepIndex);
    highlightNextStep(nextStep);
  };

  onExit = (step) => {
    if (step === undefined) return;
    if (!this.state.complete && this.featureTourAttemptId()) {
      this.skipFeatureTour();
    }
    let pushTo;
    if (this.props.featureTour.id) {
      pushTo = (this.getSteps()[step] || {}).pushTo;
    }
    this.props.actions.closeFeatureTour({ pushTo });
    this.props.onExit(step);
  };

  onComplete = () => {
    this.setState(() => {
      return {
        complete: true,
      };
    });
  };

  render() {
    return (
      <Steps
        ref={(steps) => (this.steps = steps)}
        enabled={this.props.enabled}
        steps={this.getSteps()}
        initialStep={this.props.initialStep}
        onExit={this.onExit}
        onComplete={this.onComplete}
        onBeforeChange={this.onBeforeChange}
        key={this.props.featureTour.id}
        options={OPTIONS}
      />
    );
  }
}

FeatureTour.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  initialStep: PropTypes.number.isRequired,
  onBeforeExit: PropTypes.func,
  onStart: PropTypes.func,
  onChange: PropTypes.func,
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    doCreateFeatureTourAttempt: PropTypes.func.isRequired,
    closeFeatureTour: PropTypes.func.isRequired,
  }).isRequired,
  featureTour: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  featureTourAttempt: PropTypes.shape({
    id: PropTypes.number.isRequired,
    employee_id: PropTypes.number.isRequired,
    feature_tour_id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    completed_at: PropTypes.string,
    skipped_at: PropTypes.string,
  }),
  role: PropTypes.shape({
    can_manage_all_requisitions: PropTypes.bool,
    can_manage_employees_locations: PropTypes.bool,
  }).isRequired,
};

FeatureTour.defaultProps = {
  enabled: false,
  initialStep: 0,
  onExit: function () {},
};

function mapStateToProps(state) {
  let featureTour = state.featureTours.open;
  let featureTourAttempt = Object.values(state.featureTourAttempts.items)
    .filter(function (attempt) {
      return (
        attempt.feature_tour_id === featureTour.id &&
        !(attempt.completed_at || attempt.skipped_at)
      );
    })
    .sort(function (a, b) {
      return a.created_at > b.created_at ? -1 : 1;
    })[0];

  return {
    actor: state.auth.actor,
    role: state.auth.role,
    enabled: !!featureTour.id,
    featureTourAttempt,
    featureTour,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCreateFeatureTourAttempt,
        closeFeatureTour,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth.actor === next.auth.actor &&
    prev.auth.role === next.auth.role &&
    prev.featureTours.open === next.featureTours.open &&
    prev.featureTourAttempts.items === next.featureTourAttempts.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(FeatureTour);
