import * as types from '../actions/action-types.js';
import { populateOptimizedProductRequisitions } from '../helpers/cart-helpers.js';

const initialQuery = window.location.pathname.match(
  /supplies\/orders\/([0-9]+)/
);
const emptyReq = { id: 0, product_requisitions: [] };
export const empty = {
  initialized: false,
  items: [],
  updating: [],
  creating: [],
  open: populateOptimizedProductRequisitions(emptyReq),
  requesting: [],
  errors: [],
  editing: [],
  meta: {
    count: 0,
    total: 0,
    next: null,
    previous: null,
    limit: 0,
    offset: 0,
  },
};
const initialOpenId =
  (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id;
export const initial = Object.assign({}, empty, {
  open: populateOptimizedProductRequisitions(
    empty.items.filter((t) => t.id === initialOpenId)[0] ||
      Object.assign({}, emptyReq, { id: initialOpenId })
  ),
});

export default (state = initial, action) => {
  let items = [];
  let item = null;
  switch (action.type) {
    case types.UPDATE_EDIT_REQUISITION_TAX: {
      return {
        ...state,
        updating: state.updating
          .filter((r) => r.id !== action.data.id)
          .concat([action.data]),
      };
    }
    case types.SUCCESS_UPDATE_EDIT_REQUISITION_TAX: {
      return {
        ...state,
        updating: state.updating.filter((r) => r.id !== action.data.id),
        editing: state.editing
          .filter((req) => req.id !== action.data.id)
          .concat([populateOptimizedProductRequisitions(action.data)]),
      };
    }
    case types.ADD_PRODUCT_ON_EDITING_REQUISITION: {
      return {
        ...state,
        editing: state.editing
          .filter((req) => req.id !== action.data.requisition.id)
          .concat([
            populateOptimizedProductRequisitions(action.data.requisition),
          ]),
      };
    }
    case types.UPDATE_PRODUCT_ON_EDITING_REQUISITION: {
      return {
        ...state,
        editing: state.editing
          .filter((req) => req.id !== action.data.requisition.id)
          .concat([
            populateOptimizedProductRequisitions(action.data.requisition),
          ]),
      };
    }
    case types.SET_EDIT_REQUISITION: {
      item = state.items.filter((req) => req.id === action.data.id)[0];
      if (!item) return state;
      return {
        ...state,
        editing: state.editing
          .filter((req) => req.id !== action.data.id)
          .concat([populateOptimizedProductRequisitions(item)]),
      };
    }
    case types.UNSET_EDIT_REQUISITION:
      return {
        ...state,
        editing: state.editing.filter((req) => req.id !== action.data.id),
      };
    case types.GET_REQUISITIONS:
      return Object.assign({}, state, {
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_REQUISITIONS,
            },
            action.data
          )
        ),
      });
    case types.SUCCESS_REQUISITIONS:
      items = action.data.map((r) => r.id);
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== types.GET_REQUISITIONS
        ),
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        items: state.items
          .filter((r) => items.indexOf(r.id) < 0)
          .concat(action.data),
        meta: action.meta || initial.meta,
      });
    case types.ERROR_REQUISITIONS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== types.GET_REQUISITIONS
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== action.data._request
        ),
        items: state.items
          .filter((req) => {
            return req.id !== action.data.id;
          })
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, action.data)
              )
            : state.open,
      });
    case types.ERROR_REQUISITION:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((req) => req._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_REQUISITION:
      return Object.assign({}, state, {
        items: state.items
          .filter((req) => req.id !== action.data.id)
          .concat(action.data),
        creating: state.creating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_CREATE_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.UPDATE_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((req) => req._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_REQUISITION:
      return Object.assign({}, state, {
        items: state.items
          .filter((req) => req.id !== action.data.id)
          .concat(action.data),
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, action.data)
              )
            : populateOptimizedProductRequisitions(state.open),
      });
    case types.ERROR_UPDATE_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.concat(action.data),
      });
    case types.SUCCESS_CREATE_PRODUCT_REQUISITION:
      items = state.items.map((req) => {
        if (req.id !== action.data.requisition_id) return req;
        return Object.assign({}, req, {
          product_requisitions: (req.product_requisitions || [])
            .filter((pr) => pr.id !== action.data.id)
            .concat(action.data),
        });
      });
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  product_requisitions: state.open.product_requisitions
                    .filter((pr) => pr.id !== action.data.id)
                    .concat(action.data),
                })
              )
            : populateOptimizedProductRequisitions(state.open),
        items: items,
      });
    case types.ERROR_CREATE_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.UPDATE_PRODUCT_REQUISITION:
    case types.CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.concat(action.data),
      });
    case types.SUCCESS_UPDATE_PRODUCT_REQUISITION:
    case types.SUCCESS_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE:
      items = state.items.map((req) => {
        if (req.id !== action.data.requisition_id) return req;
        return Object.assign({}, req, {
          product_requisitions: req.product_requisitions.map((pr) => {
            if (pr.id !== action.data.id) return pr;
            return Object.assign({}, pr, action.data);
          }),
        });
      });
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.requisition_id
            ? Object.assign({}, state.open, {
                product_requisitions: state.open.product_requisitions.map(
                  (pr) => {
                    if (pr.id !== action.data.id) return pr;
                    return Object.assign({}, pr, action.data);
                  }
                ),
              })
            : state.open,
        items: items,
      });
    case types.ERROR_UPDATE_PRODUCT_REQUISITION:
    case types.ERROR_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.DELETE_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.concat(action.data),
      });
    case types.SUCCESS_DELETE_PRODUCT_REQUISITION:
      items = state.items.map((req) => {
        if (req.id !== action.data.requisition_id) return req;
        return Object.assign({}, req, {
          product_requisitions: req.product_requisitions.filter(
            (pr) => pr.id !== action.data.id
          ),
        });
      });
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.requisition_id
            ? populateOptimizedProductRequisitions(
                Object.assign({}, state.open, {
                  product_requisitions: state.open.product_requisitions.filter(
                    (pr) => pr.id !== action.data.id
                  ),
                })
              )
            : populateOptimizedProductRequisitions(state.open),
        items: items,
      });
    case types.ERROR_DELETE_PRODUCT_REQUISITION:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });

    case types.OPEN_REQUISITION:
      item =
        state.items.filter((req) => req.id === action.data.id)[0] ||
        action.data;
      return Object.assign({}, state, {
        open: populateOptimizedProductRequisitions(item || initial.open),
      });
    case types.CLOSE_REQUISITION:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.SWITCH_LOCATION:
      return {
        ...empty,
        open: initial.open,
      };
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
