import moment from 'moment';
import ColorHash from 'color-hash';
import { vendorsWithNoMinimums } from '../helpers/vendor-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';

export function formatProductImageURL(product, size) {
  return `${ADMIN_V1_URL}/public/images/products/${product.id}/avatar/${size}`;
}

/**
 * Transforms an object into search param string for use in URL
 */
export function composeSearchParams(obj = {}) {
  return Object.keys(obj)
    .reduce(function (acc, key) {
      return acc.concat(
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      );
    }, [])
    .join('&');
}

/**
 * Formats an a numerical interval and number
 * representing a day of the week
 * into a readable string
 */
export function formatReadableInterval(interval, dayOfTheWeek) {
  const intervalConversion = {
    1: 'Once a week',
    2: 'Once every two weeks',
    3: 'Once every three weeks',
    4: 'Monthly',
  };

  const dayOfTheWeeKConversion = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  return `${intervalConversion[interval]} on ${dayOfTheWeeKConversion[dayOfTheWeek]}`;
}

/**
 * Formats an item's unit of stock
 * into a readable string
 */
export function convertUnitToString(unit) {
  const unitConversion = {
    bg: 'Bag',
    bt: 'Bottle',
    bx: 'Box',
    cn: 'Can',
    ct: 'Carton',
    ca: 'Case',
    ea: 'Each',
    ga: 'Gallon',
    oz: 'Ounce',
    pk: 'Pack',
    rm: 'Ream',
    rl: 'Roll',
    tb: 'Tube',
    pt: 'Pint',
    qt: 'Quart',
    lt: 'Liter',
    lb: 'Pound',
    yd: 'Yard',
    ft: 'Foot',
    sf: 'Square Foot',
  };
  return unitConversion[unit] ? unitConversion[unit] : 'Each';
}

export function pluralizedProductUnit(unit) {
  switch (unit) {
    case 'Box':
      return 'Boxes';
    case 'Each':
      return 'Each';
    case 'Foot':
      return 'Feet';
    case 'Square Foot':
      return 'Square Feet';
    default:
      return unit + 's';
  }
}

/**
 * Format a unit of purchase give stock/purchase units
 *
 * @access public
 * @param {String} purchaseUnit
 * @param {Number} stockCount
 * @param {String} stockUnit
 * @returns {String}
 */
export function formatUnitOfPurchase(purchaseUnit, stockCount, stockUnit) {
  let convertedUnitOfStock = convertUnitToString(stockUnit);
  let convertedUnitOfPurchase = convertUnitToString(purchaseUnit);
  let convertedStockCount = (stockCount || 0).toLocaleString();

  if (purchaseUnit === stockUnit) {
    return `${convertedStockCount} ${
      stockCount > 1
        ? pluralizedProductUnit(convertedUnitOfPurchase)
        : convertedUnitOfPurchase
    }`;
  } else {
    return `${convertedUnitOfPurchase} of ${convertedStockCount} ${
      stockCount > 1
        ? pluralizedProductUnit(convertedUnitOfStock)
        : convertedUnitOfStock
    }`;
  }
}

/**
 * Format a unit of measure give stock/purchase units
 *
 * @access public
 * @param {String} measureUnit
 * @param {Number} stockCount
 * @returns {String}
 */
export function formatUnitOfMeasure(measureUnit, stockCount) {
  let convertedUnitOfMeasure = pluralizedProductUnit(
    convertUnitToString(measureUnit)
  );
  let convertedStockCount = (stockCount || 0).toLocaleString();

  return `${convertedStockCount} ${convertedUnitOfMeasure}`;
}

/**
 * Format a price per unit of purchase give stock/purchase units
 *
 * @access public
 * @param {Number} price
 * @param {Number} stockCount
 * @param {String} purchaseUnit
 * @returns {String}
 */
export function formatPricePerUnitOfPurchase(price, stockCount, purchaseUnit) {
  let convertedUnitOfPurchase = convertUnitToString(purchaseUnit);
  let convertedPricePer = formatCentAmount(Math.max(1, price / stockCount));

  return `${convertedPricePer}/${convertedUnitOfPurchase}`;
}

export function formatTitleCase(str) {
  if (!str) return '';
  let smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

  return str.replace(
    /[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g,
    function (match, index, title) {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ':' &&
        (title.charAt(index + match.length) !== '-' ||
          title.charAt(index - 1) === '-') &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase();
      }

      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match;
      }

      return match.charAt(0).toUpperCase() + match.substr(1);
    }
  );
}

/**
 * Format a product stock status to readable
 *
 * @access public
 * @param {Object} product
 * @param {String} product.stock_status enum
 * @returns {String}
 */
export function formatStockStatus(product) {
  if (!product.active) return 'Currently out of stock';
  switch (product.stock_status) {
    case 'out':
      return 'Currently out of stock';
    default:
      return (product.stock_status || '').split('_').join(' ');
  }
}

/**
 * Format an employee/staff name for display
 *
 * @access public
 * @param {object} employee|staff
 * @returns {string}
 */
export function formatName(record) {
  if (record.email && record.email.match(/@officeluv.com/)) {
    return 'OfficeLuv';
  }
  return (
    (record.first_name
      ? formatEmployeeName(record)
      : formatStaffName(record)) || 'Someone anonymously'
  );
}

/**
 * Format an employee name for display
 *
 * @access public
 * @param {object} employee Employee
 * @returns {string}
 */
export function formatEmployeeName(employee) {
  return employee.first_name
    ? employee.first_name + ' ' + (employee.last_name || ' ').charAt(0) + '.'
    : 'Someone anonymously';
}

/**
 * Format a staff name for display
 *
 * @access public
 * @param {object} staff Staff
 * @returns {string}
 */
export function formatStaffName(staff) {
  if (!staff.name) return 'A Staff member';
  const words = staff.name.split(' ');
  if (words.length < 2) return staff.name;
  words.splice(words.length - 1, 1, words[words.length - 1][0]);
  return words.join(' ') + '.';
}

/**
 * Format an employee name/email for display
 *
 * @access public
 * @param {object} employee Employee
 * @returns {string}
 */
export function formatEmployeeNameEmail(employee) {
  return employee.first_name
    ? employee.first_name + ' ' + (employee.last_name || ' ').charAt(0) + '.'
    : employee.email;
}

/**
 * Format an employee name for display
 *
 * @access public
 * @param {object} employee Employee
 * @returns {string}
 */
export function formatCommenterName(employee) {
  return employee.first_name ? formatEmployeeName(employee) : 'Anonymous';
}

/**
 * Calculate date of day beginning of date
 *
 * @access public
 * @param {string|number|Date} date
 * @returns {Date}
 * @example
 * // returns 2017-09-14T05:00:00.000Z
 * dateDayOf('017-09-14T14:44:21.109Z');
 */
export function dateDayOf(date) {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

/**
 * Format a date/string for display
 *
 * @access public
 * @param {string|Date} date
 * @returns {string} calendar date-time (Tue, Oct 3 @ 7:00pm)
 */
export function formatDateTime(date) {
  return `${formatDate(date)} @ ${formatTime(date)}`;
}

/**
 * Format a date/string for display
 *
 * @access public
 * @param {string|Date} date
 * @returns {string} calendar date (Tue, Oct 3)
 */
export function formatDate(date_or_string) {
  let instance = moment(date_or_string);

  if (instance.isSame(new Date(), 'day')) return 'Today';
  if (instance.isSame(new Date(), 'year')) return instance.format('ddd, MMM D');
  return instance.format('ddd, ll');
}

/**
 * Format a date/string for display
 *
 * @access public
 * @param {string|Date} date
 * @returns {string} calendar date (Tue, Oct 3, 2017)
 */
export function formatDateYear(date_or_string) {
  let instance = moment(date_or_string);

  if (instance.isSame(new Date(), 'day')) return 'Today';
  return instance.format('ddd, MMM D, YYYY');
}

/**
 * Format a date/string for display
 *
 * @access public
 * @param {string|Date} date
 * @returns {string} calendar time
 */
export function formatShortDate(date_or_string) {
  let instance = moment(date_or_string);
  return instance.format('l');
}

/**
 * Format a date/string for display
 *
 * @access public
 * @param {string|Date} date
 * @returns {string} calendar time
 */
export function formatTime(date_or_string) {
  let date = new Date(date_or_string);
  let string = date.toLocaleTimeString('en-US');
  let time = string.split(':').slice(0, 2).join(':');
  let meridian = string
    .replace(/[\u202F]/, ' ')
    .split(' ')[1]
    .toLowerCase();
  return `${time}${meridian}`;
}

/**
 * Return two date objects representing start and
 * end dates for quarter for a date/string
 *
 * @access public
 * @param {string|Date} date
 * @returns {Object} Object with keys quarterStart and quarterEnd
 */
export function calculateQuarterDates(date_or_string) {
  let date = moment(date_or_string);
  let quarterStart = moment(date_or_string);
  let quarterEnd = moment(date_or_string);

  switch (date.quarter()) {
    case 1:
      quarterStart.set({
        month: 0,
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
      });
      quarterEnd.set({
        month: 2,
        date: 31,
        hour: 23,
        minute: 59,
        second: 0,
      });
      break;
    case 2:
      quarterStart.set({
        month: 3,
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
      });
      quarterEnd.set({
        month: 5,
        date: 30,
        hour: 23,
        minute: 59,
        second: 0,
      });
      break;
    case 3:
      quarterStart.set({
        month: 6,
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
      });
      quarterEnd.set({
        month: 8,
        date: 30,
        hour: 23,
        minute: 59,
        second: 0,
      });
      break;
    case 4:
      quarterStart.set({
        month: 9,
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
      });
      quarterEnd.set({
        month: 11,
        date: 31,
        hour: 23,
        minute: 59,
        second: 0,
      });
      break;
    default:
      break;
  }
  return {
    quarterStart: quarterStart.toDate(),
    quarterEnd: quarterEnd.toDate(),
  };
}

/**
 * Format a snake case string into camel case
 * @access public
 * @param {string}
 * @returns {string}
 */
export function formatSnakeCaseToCamelCase(snakeString) {
  return snakeString.split('_').reduce(function (acc, str) {
    return acc + str.charAt(0).toUpperCase() + str.slice(1);
  }, '');
}

/**
 * Format file size/bytes to string
 *
 * @access public
 * @param {number} bytes
 * @returns {string} 0.00mb
 */
export function formatBytesSize(bytes) {
  return Math.max(0, bytes / 1024 / 1024).toFixed(2) + 'mb';
}

/**
 * Format integer cents to USD display string
 *
 * @access public
 * @param {number} cents
 * @returns {string} $2,300.25
 */
export function formatCentAmount(cents) {
  return ((cents || 0) / 100).toLocaleString(navigator.language, {
    style: 'currency',
    currency: 'USD',
  });
}

export function twoDecimalPlaces(numberText) {
  if (!numberText) return '';
  return (Math.round(parseFloat(numberText)) / 100).toLocaleString();
}

/**
 * Format integer cents to USD display string
 *
 * @access public
 * @param {number} cents
 * @returns {string} $2,300.25
 */
export function formatCentAmountToDollar(cents) {
  return Math.round(cents / 100).toLocaleString(navigator.language, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
}

/**
 * Format integer cents to USD display string rounded to dollar
 *
 * @access public
 * @param {number} cents
 * @returns {string} $2,300
 */
export function formatCentAmountRounded(cents) {
  return formatCentAmount(Math.round(cents / 100) * 100);
}

/**
 * Parse user-input string of number to float
 *
 * @access public
 * @param {string} string
 * @returns {Number}
 *  @example
 * // returns 4500567.98
 * parseNumberInput('$4,500,567.98')
 */
export function parseNumberInput(string) {
  return parseFloat(
    string
      .replace(/^[^0-9]*/, '')
      .replace(
        new RegExp((1000.11).toLocaleString().match(/[^0-9]/)[0], 'g'),
        ''
      )
  );
}

/**
 * Build a doubly-linked list of product categories
 *
 * @access public
 * @param {object[]} categories each with parent_id
 * @returns {object[]} categories with linked parent and array of children
 */
export function linkProductCategories(categories) {
  return categories.reduce((acc, val) => {
    if (!val.parent_id) {
      acc.push(val);
      return acc;
    }
    val.parent = categories.filter((c) => c.id === val.parent_id)[0];
    if (val.parent) {
      val.parent.children = val.parent.children || [];
      val.parent.children.push(val);
    }
    acc.push(val);
    return acc;
  }, []);
}

/**
 * Determine if one product_category is parent of another
 *
 * @access public
 * @param {object} parent category
 * @param {object} child category
 * @returns {boolean}
 */
export function isCategoryParentOf(parent, child) {
  let iter = child.parent;
  while (iter && parent) {
    if (iter.id === parent.id) {
      return true;
    }
    iter = iter.parent;
  }
  return false;
}

/**
 * Stringify potentially circular JSON objects
 *
 * @access public
 * @param {object} obj
 * @param {function} replacer
 * @param {number} spaces
 * @param {function} cycleReplacer
 * @returns {string}
 */
export function stringify(obj, replacer, spaces, cycleReplacer) {
  function serializer(replacer, cycleReplacer) {
    let stack = [],
      keys = [];

    if (cycleReplacer == null)
      cycleReplacer = function (key, value) {
        if (stack[0] === value) return '[Circular ~]';
        return (
          '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']'
        );
      };

    return function (key, value) {
      if (stack.length > 0) {
        let thisPos = stack.indexOf(this);
        ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
        ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
        if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value);
      } else stack.push(value);

      return replacer == null ? value : replacer.call(this, key, value);
    };
  }
  return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
}

/**
 * Escape special characters in string to be used in regex
 *
 * @access public
 * @param {string} str
 * @returns {string} escaped
 */
export function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Group time series data set into intervals to fit ticks minimum
 *
 * @access public
 * @param {Object} opt
 * @param {Date} opt.start
 * @param {Date} opt.end
 * @param {Number} opt.minTicks
 * @param {Object[]} opt.data
 * @param {String[]} opt.dateKey
 * @param {String[]} opt.sumKeys
 * @param {String} [opt.interval]
 * @returns {Object[]} data grouped by interval
 */
export function groupTimeSeriesData(opt) {
  const interval =
    opt.interval || findBestSeriesInterval(opt.start, opt.end, opt.minTicks);
  return Object.values(
    opt.data
      .map((d) => {
        let clone = Object.assign({}, d);
        clone[opt.dateKey] = moment(d[opt.dateKey]).startOf(interval).toDate();
        return clone;
      })
      .reduce((acc, val) => {
        const group = val[opt.dateKey];
        acc[group] = acc[group] || [];
        acc[group].push(val);
        return acc;
      }, {})
  ).map((data) => {
    if (!data.length) return {};
    const initial = Object.keys(data[0]).reduce((acc, val) => {
      acc[val] = null;
      return acc;
    }, {});
    return data.reduce((acc, val) => {
      Object.keys(val).map((k) => {
        if (opt.sumKeys.indexOf(k) > -1) {
          return (acc[k] += val[k] || 0);
        } else {
          return (acc[k] = val[k] || acc[k]);
        }
      });
      return acc;
    }, initial);
  });
}

/**
 * Find best of a few time intervals given ticks
 *
 * @access public
 * @param {Date} start
 * @param {Date} end
 * @param {Number} minTicks
 * @returns {String} interval day|week|month|year
 */
export function findBestSeriesInterval(start, end, minTicks) {
  const HOUR_MILLIS = 3600000;
  const DAY_MILLIS = HOUR_MILLIS * 24;
  const span = end - start;
  return (
    [
      { name: 'year', ms: DAY_MILLIS * 365 },
      { name: 'month', ms: DAY_MILLIS * 30 },
      { name: 'week', ms: DAY_MILLIS * 7 },
      { name: 'day', ms: DAY_MILLIS },
    ].filter((t) => span / t.ms > minTicks)[0] || { name: 'day' }
  ).name;
}

/**
 * Zips arrays together with a merging function
 * where each array's entry at a given index will be merged
 *
 * @access public
 * @param {Array[]} arrays
 * @param {Function} zipper function handling entries at an index
 * @returns {Array} zipped
 */
export function zipArrays(arrays, zipper) {
  const len = Math.max.apply(
    Math,
    arrays.map((a) => a.length)
  );
  let zipped = [];
  let i = 0;
  function plucker(a) {
    return a[i];
  }
  for (; i < len; i++) {
    zipped.push(zipper(arrays.map(plucker)));
  }
  return zipped;
}

export function compareNameAsc(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
}

function compareValueAsc(a, b) {
  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
}

function notSelected(selectedIds, ary) {
  if (selectedIds.length === 0) return ary;
  const filtered = ary.filter((item) => {
    return selectedIds.filter((id) => id === item.id).length === 0;
  });
  return filtered;
}

function notSelectedValues(selectedNames, ary) {
  if (selectedNames.length === 0 || ary.length === 0) return ary;
  const filtered = ary.filter((item) => {
    return selectedNames.filter((value) => value === item.value).length === 0;
  });
  return filtered;
}

/**
 * Sorts options by label attribute
 * @access public
 * @param {Object[]} array of objects with a label key
 * @returns {Object[]} array of object sorted by label key
 */
export function sortProductCategories(options, selectedIds) {
  const selected = options
    .filter((c) => {
      return selectedIds.filter((id) => id === c.id).length > 0;
    })
    .sort(compareNameAsc);
  const parentCats = notSelected(selectedIds, options)
    .filter((c) => c.parent_id === null)
    .sort(compareNameAsc);
  const subCats = notSelected(selectedIds, options)
    .filter((c) => c.parent_id !== null)
    .sort(compareNameAsc);

  return selected.concat(parentCats).concat(subCats);
}

export function sortProductBrands(options, selectedNames) {
  const selected = options
    .filter((option) => {
      return selectedNames.filter((name) => name === option.value).length > 0;
    })
    .sort(compareValueAsc);

  const notSelected = notSelectedValues(selectedNames, options).sort(
    compareValueAsc
  );

  return selected.concat(notSelected);
}

export function formatAlgoliaFilters(locationVendors, filters) {
  const base = 'active:true';
  const noMinLabel = 'no_min';
  if (!locationVendors.length) {
    return base;
  }
  let collectedVendors = locationVendors;
  let noMinVendors = [];
  if (filters.vendorIds && filters.vendorIds.length) {
    if (filters.vendorIds.filter((item) => item === noMinLabel).length) {
      noMinVendors = vendorsWithNoMinimums(locationVendors);
    }
    const noMinVendorIds = noMinVendors.map((v) => v.id);
    collectedVendors = locationVendors
      .filter((item) => {
        return (
          filters.vendorIds.indexOf(item.id) > -1 &&
          noMinVendorIds.indexOf(item.id) < 0
        );
      })
      .concat(noMinVendors);
  }
  let vendorFilters = collectedVendors
    .map((v) => `_tags:vendor_${v.id}`)
    .join(' OR ');
  let categories = filters.categoryIds || [];
  let catFilters = categories
    .map((id) => `_tags:product_category_${id}`)
    .join(' OR ');
  let brands = filters.brandNames || [];
  let brandFilters = brands.map((name) => `brand:"${name}"`).join(' OR ');
  let brandIdFilter = filters.brandId ? `brand_id:${filters.brandId}` : '';

  const collect = [
    base,
    `(${vendorFilters})`,
    `(${catFilters})`,
    `(${brandFilters})`,
    `(${brandIdFilter})`,
  ].filter((x) => x.length > 2);

  return collect.join(' AND ');
}

export function formatInternalFilters(filters, locationVendors) {
  const noMinLabel = 'no_min';
  let collect = filters.vendorIds;
  if (filters.vendorIds.filter((item) => item === noMinLabel).length) {
    collect = collect
      .filter((item) => item !== noMinLabel)
      .concat(vendorsWithNoMinimums(locationVendors).map((v) => v.id));
  }
  return {
    vendorIds: collect,
    maxShippingTimes: filters.maxShippingTimes,
    stockStatus: filters.stockStatus,
  };
}

export function searchResultSummary(text, query, isRequesting = false) {
  if (isRequesting) {
    if (text.length > 30) {
      text = text.slice(0, 30) + '...';
    }
    if (!text.trim().length) {
      return 'Searching All Products';
    }
    return `Searching for “${text}”`;
  }
  if (!query.results.length) {
    return `We couldn't find anything matching “${text}”`;
  }
  /* Request has finished, use results query for text */
  if (text.length) {
    if (text.length > 30) {
      text = text.slice(0, 30) + '...';
    }
    if (text.trim().length) {
      text = `for “${text}”`;
    }
    if (query.results.length === 1) {
      return `${query.results.length} Result ${text}`;
    }
    return `${query.results.length} Results ${text}`;
  }
  return `${query.results.length} Results`;
}

export const minimumInWords = (vendorGroup) => {
  const { minimum } = vendorGroup;
  switch (minimum.type) {
    case 'price_min': {
      const dollars = minimum.value / 100;
      return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
    case 'quantity_min': {
      return `${minimum.value.toLocaleString()} items`;
    }
    default:
      return '';
  }
};

export const minimumDeficitInWords = (vendorGroup) => {
  const { minimum } = vendorGroup;
  switch (minimum.type) {
    case 'price_min': {
      const dollars =
        (minimum.value - minimum.portion_total * minimum.value) / 100;
      return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
    case 'quantity_min': {
      const difference = minimum.value - minimum.portion_total * minimum.value;
      if (difference === 1) return '1 item';
      return `${difference.toLocaleString()} items`;
    }
    default:
      return '';
  }
};

export const colorHash = new ColorHash({ lightness: 0.4 });

export const locationVendorColorMap = (vendors) => {
  const sorted = vendors.sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 1;
  });
  return sorted.reduce((acc, vendor) => {
    if (vendor.price_minimum > 0 || vendor.quantity_minimum > 0) {
      acc[vendor.id] = colorHash.hex(vendor.id);
    }
    return acc;
  }, {});
};

export const encodeAsUrlHash = (string) => encodeURIComponent(btoa(string));

export const decodeFromUrlHash = (hash) => atob(decodeURIComponent(hash));

export const formatDateSmall = (dateString) => {
  let instance = moment(dateString);

  if (instance.isSame(new Date(), 'day')) return 'Today';

  if (moment().diff(instance, 'years') > 1) {
    return instance.format('MM/DD/YY');
  }

  return instance.format('MMM D');
};

export const formatInitials = (string) => {
  return string
    .replace(/\W /g, '')
    .split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const asSentence = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export function updateQueryStringParameter(uri, key, value) {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}
