import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../lib/history.js';
import { openShipment, doUpdateShipment } from '../../ducks/shipments.js';
import TodaysOrdersSectionCapsule from './todays-orders-section-capsule.js';
import IconClose from './../../imgs/close-icon.svg';
import { FILTER_TODAY_MY_SUPPLY_ARRIVALS } from '../../strings.js';
import { IS_ACTIVE_SHIPMENT } from '../../flags.js';

import './todays-orders-section.css';

export class TodaysOrdersSection extends React.PureComponent {
  _handlePackingSlipClick = (item) => {
    this.props.actions.openShipment(item);
  };

  _handleMarkReceivedClick = (item) => {
    const receivedShipment = Object.assign({}, item, {
      received_at: new Date(),
    });
    this.props.actions.doUpdateShipment(receivedShipment);
  };

  _handleArrivingSoon = () => {
    browserHistory.push('/supplies/orders');
  };

  _closeSection = () => {
    this.refs.SECTION.style.display = 'none';
  };

  renderCapsules = (item) => {
    return (
      <TodaysOrdersSectionCapsule
        key={item.id}
        onPackingSlipClick={this._handlePackingSlipClick}
        onMarkReceivedClick={this._handleMarkReceivedClick}
        item={item}
      />
    );
  };

  render() {
    if (!this.props.items.length) return null;
    return (
      <div className="todays-orders-section" ref="SECTION">
        <div className="variable-width-column">
          <span className="notification-text">Notifications</span>
          <h2 className="todays-orders-section-text">
            {this.props.items.length} Shipment
            {this.props.items.length === 1 ? '' : 's'} expected today.
          </h2>
          {this.props.items.map(this.renderCapsules)}
        </div>
        <button className="close-btn" onClick={this._closeSection}>
          <img src={IconClose} alt="close" />
        </button>
      </div>
    );
  }
}

TodaysOrdersSection.propTypes = {
  actions: PropTypes.shape({
    openShipment: PropTypes.func.isRequired,
    doUpdateShipment: PropTypes.func.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      expected_at_end: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })
  ).isRequired,
};
const filterNotReceived = (p) => {
  return !p.received_at;
};
const sortExpectedAt = (a, b) => {
  return a.expected_at_end > b.expected_at_end ? 1 : -1;
};

export function mapStateToProps(state) {
  return {
    items: Object.values(state.shipments.items)
      .filter(IS_ACTIVE_SHIPMENT)
      .filter(filterNotReceived)
      .filter(FILTER_TODAY_MY_SUPPLY_ARRIVALS)
      .sort(sortExpectedAt),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openShipment,
        doUpdateShipment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.shipments.items === next.shipments.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(TodaysOrdersSection);
