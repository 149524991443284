import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showCreateTaskModal } from '../../actions/task-actions.js';
import TaskItemRecurring from './task-item-recurring.js';
import TaskItemOneOff from './task-item-one-off.js';
import './task-items-list.css';

export class TaskItemsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recurring: props.cleaning.tasks.filter((t) => {
        return t.recurring;
      }),
      oneOff: props.cleaning.tasks.filter((t) => {
        return !t.recurring;
      }),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      Object.assign({}, this.state, {
        recurring: props.cleaning.tasks.filter((t) => {
          return t.recurring;
        }),
        oneOff: props.cleaning.tasks.filter((t) => {
          return !t.recurring;
        }),
      })
    );
  }

  handleAddRecurringTaskModalTrigger = (e) => {
    e.preventDefault();
    this.props.actions.showCreateTaskModal({
      id: 'new',
      cleaning_id: this.props.cleaning.id,
      recurring: true,
    });
  };

  handleAddOneOffTaskModalTrigger = (e) => {
    e.preventDefault();
    this.props.actions.showCreateTaskModal({
      id: 'new',
      cleaning_id: this.props.cleaning.id,
      recurring: false,
    });
  };

  renderRecurringTaskItem = (item) => {
    return (
      <TaskItemRecurring
        editable={this.props.editable}
        item={item}
        key={item.id}
      />
    );
  };

  renderOneOffTaskItem = (item) => {
    return (
      <TaskItemOneOff
        editable={this.props.editable}
        item={item}
        key={item.id}
      />
    );
  };

  sortById = (firstTask, secondTask) => {
    return firstTask.id - secondTask.id;
  };

  render() {
    return (
      <div className="task-items-list">
        <div className="one-off-task-items to-do-container">
          <div className="task-header">
            <h4 className="one-off-header">One&ndash;Time Tasks</h4>
            <p className="gray-text">Things that only need to be done once.</p>
          </div>
          {this.state.oneOff.length === 0 && this.props.isCompletedCleaning && (
            <h5 className="completed-empty-state">
              There were no 1x To&ndash;Do&rsquo;s assigned during this shift.
            </h5>
          )}
          <div className="flex-wrap">
            {this.props.editable && (
              <button
                className="add-task-link primary brand-color-override brand-border-color-override"
                onClick={this.handleAddOneOffTaskModalTrigger}
              >
                <span className="plus-icon">+</span>
                <span>
                  Add a <br /> One&ndash;Time Task
                </span>
              </button>
            )}
            {this.state.oneOff.length === 0 &&
              !this.props.isCompletedCleaning && (
                <p className="gray-text empty-state">
                  You don't have any one&ndash;time Tasks.
                </p>
              )}
            {this.state.oneOff
              .sort(this.sortById)
              .map(this.renderOneOffTaskItem)}
          </div>
        </div>

        <div className="recurring-task-items to-do-container">
          <div className="task-header">
            <h4 className="recurring-header">Recurring Tasks</h4>
            <p className="gray-text">
              Things that need to be done every shift.
            </p>
          </div>
          {this.state.recurring.length === 0 &&
            this.props.isCompletedCleaning && (
              <h5 className="completed-empty-state">
                There were no Repeating To&ndash;Do&rsquo;s assigned during this
                shift.
              </h5>
            )}
          {this.state.recurring.length === 0 &&
            !this.props.isCompletedCleaning && (
              <p className="microcopy empty-state">
                You don&rsquo;t have any repeating To&ndash;Do&rsquo;s.
              </p>
            )}
          <div className="flex-wrap">
            {this.props.editable && (
              <button
                className="add-task-link primary"
                onClick={this.handleAddRecurringTaskModalTrigger}
              >
                <span className="plus-icon">+</span>
                <span>
                  Add a <br /> Recurring Task
                </span>
              </button>
            )}
            {this.state.recurring
              .sort(this.sortById)
              .map(this.renderRecurringTaskItem)}
          </div>
        </div>
      </div>
    );
  }
}

TaskItemsList.propTypes = {
  editable: PropTypes.bool,
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        cleaning_id: PropTypes.number.isRequired,
        area: PropTypes.string,
        note: PropTypes.string,
        completed: PropTypes.bool.isRequired,
        recurring_task_id: PropTypes.number,
        recurring: PropTypes.bool,
      })
    ).isRequired,
  }).isRequired,
  isCompletedCleaning: PropTypes.bool,
};

export function mapStateToProps(state, props) {
  return {};
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showCreateTaskModal,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItemsList);
