import * as types from '../actions/action-types.js';
import * as notifications from '../lib/notifications.js';

function askPermissions() {
  notifications.askPermission({
    onGrant: function () {
      notifications.notify('Thanks!', {
        body: 'You will be notified of shipments & orders here.',
      });
    },
  });
}

const notificationsMid = (store) => (next) => (action) => {
  let result = next(action);
  switch (action.type) {
    case types.POST_APPROVAL:
    case types.POST_CHECKOUT:
      askPermissions();
      break;
    default:
      break;
  }
  return result;
};

export default notificationsMid;
