import React from 'react';
import AuthCheck from '../components/auth-check.js';
import SettingsUserAttendant from '../components/employees/settings-user-attendant.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import SettingsNotifications from '../components/employees/settings-employee-notifications.js';
import setTitle from '../decorators/set-title.js';
import EmployeeStipendDetails from '../components/employees/employee-stipend-details.js';
import EmployeeBillingInfo from '../components/modules/employee-billing-info.js';

export class SettingsUserRouteAttendant extends React.Component {
  render() {
    return (
      <div className="settings-panel">
        <AuthCheck />
        <MountAtPageTop />
        <SettingsUserAttendant />
        <EmployeeStipendDetails />
        <EmployeeBillingInfo />
        <SettingsNotifications />
      </div>
    );
  }
}

SettingsUserRouteAttendant = setTitle((props) => {
  return 'User Settings';
})(SettingsUserRouteAttendant);

export default SettingsUserRouteAttendant;
