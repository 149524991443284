import * as types from '../actions/action-types.js';

const empty = {
  selectedDate: new Date().toDateString(),
};

export default (state = empty, action) => {
  switch (action.type) {
    case types.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.data,
      };
    default:
      return state;
  }
};
