import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Message } from 'semantic-ui-react';
import { dismissAnnouncement } from '../../actions/application-actions.js';

import './announcement.css';
import 'semantic-ui-css/components/message.min.css';

export class Announcement extends React.PureComponent {
  render() {
    if (!this.props.announcement) return null;
    if (window.location.pathname.match(/supplies\/catalog/)) return null;
    return (
      <Message
        className="app-announcement"
        warning={this.props.announcement.type === 'warning'}
        info={this.props.announcement.type === 'info'}
        success={this.props.announcement.type === 'success'}
        error={this.props.announcement.type === 'error'}
        color={this.props.announcement.color}
        onDismiss={this.props.actions.dismissAnnouncement}
      >
        <Message.Header>{this.props.announcement.title}</Message.Header>
        <p>{this.props.announcement.body}</p>
      </Message>
    );
  }
}

Announcement.propTypes = {
  announcement: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    color: PropTypes.string,
    body: PropTypes.string.isRequired,
  }),
  actions: PropTypes.shape({
    dismissAnnouncement: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    announcement: state.application.announcement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        dismissAnnouncement,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.application.announcement === next.application.announcement;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Announcement);
