import React from 'react';
import { ADMIN_URL, HEADERS_JSON } from '../../strings.js';
import WeekCalendar from './week-calendar.js';
import './service-schedule.css';

export class ServiceSchedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.initialState = {
      res: [],
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    fetch(`${ADMIN_URL}/deputy?location_id=${this.props.id}`, {
      method: 'GET',
      headers: HEADERS_JSON,
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Submitting deputy search of location');
        }
        return response.json();
      })
      .then((body) => {
        this.setState({ res: body.body });
      });
  }

  render() {
    return (
      <div className="service-schedule">
        <h2>On-site Service</h2>
        <WeekCalendar data={this.state.res} />
      </div>
    );
  }
}

export default ServiceSchedule;
