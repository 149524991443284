import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Header, Segment, Form } from 'semantic-ui-react';
import ModalCustomerRolesDesc from '../modals/modal-customer-roles-desc.js';
import {
  submitUpdateEmployee,
  submitUpdateAvatar,
} from '../../actions/employee-actions.js';
import { doChangePassword } from '../../actions/auth-actions.js';
import { PASSWORD_LENGTH } from '../../strings.js';
import avatarPlaceholder from '../../imgs/icon-avatar--gray.svg';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './settings-user-attendant.css';
import '../change-password-attendant.css';

export class SettingsUser extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: props.user.first_name || '',
      lastName: props.user.last_name || '',
      email: props.user.email || '',
      displayPasswords: false,
      passwordExisting: '',
      passwordNew: '',
      passwordConfirm: '',
      passwordText: `New Password (minimum ${PASSWORD_LENGTH} characters)`,
      isChanged: false,
      title: props.user.title || '',
    };
  }

  componentDidMount() {
    window.totango.track('Viewed Profile Details.', 'Settings');
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      Object.assign({}, this.state, {
        id: props.user.id,
        firstName: props.user.first_name,
        lastName: props.user.last_name,
        email: props.user.email,
        title: props.user.title,
      })
    );
  }

  componentWillUnmount() {
    this.toggleDisplayTimeout && window.clearTimeout(this.toggleDisplayTimeout);
    this.resetTimeout && window.clearTimeout(this.resetTimeout);
  }

  onChangeFirstName = (e, { value }) => {
    let firstName = value;
    this.setState(
      Object.assign({}, this.state, {
        firstName,
        isChanged:
          this.state.email !== this.props.user.email ||
          this.state.lastName !== this.props.user.last_name ||
          firstName !== this.props.user.first_name,
      })
    );
  };

  onChangeLastName = (e, { value }) => {
    let lastName = value;
    this.setState(
      Object.assign({}, this.state, {
        lastName,
        isChanged:
          this.state.email !== this.props.user.email ||
          this.state.firstName !== this.props.user.first_name ||
          lastName !== this.props.user.last_name,
      })
    );
  };

  handleChangeAvatar = (e) => {
    let target = e.target;
    if (!target.files[0]) return;
    let data = new window.FormData(this.refs.form);
    this.props.actions.submitUpdateAvatar(this.props.user, data);
  };

  isDisabled = () => {
    return (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.title ||
      this.shouldPasswordSubmitBeDisabled(
        this.state.passwordExisting,
        this.state.passwordNew,
        this.state.passwordConfirm
      ) ||
      (!this.state.isChanged &&
        !this.passwordLengthCheck(
          this.state.passwordExisting,
          this.state.passwordNew,
          this.state.passwordConfirm
        ))
    );
  };

  resetPasswordForm = () => {
    this.setState(
      Object.assign({}, this.state, {
        passwordExisting: '',
        passwordNew: '',
        passwordConfirm: '',
      })
    );
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.isChanged) {
      const update = {
        id: this.props.user.id,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        title: this.state.title,
      };
      this.props.actions.submitUpdateEmployee(update);
    }

    if (
      this.passwordLengthCheck(
        this.state.passwordExisting,
        this.state.passwordNew,
        this.state.passwordConfirm
      )
    ) {
      this.props.actions.doChangePassword({
        email: this.state.email,
        passwordExisting: this.state.passwordExisting,
        passwordNew: this.state.passwordNew,
      });

      // TODO: This is a bad, bad hack :)
      // We don't understand something about the component lifecycle...
      this.toggleDisplayTimeout = window.setTimeout(
        this.togglePasswordDisplay,
        300
      );
      this.resetTimeout = window.setTimeout(this.resetPasswordForm, 300);
    }
  };

  onChangePasswordExisting = (e, { value }) => {
    let passwordExisting = value;
    this.setState(Object.assign({}, this.state, { passwordExisting }));
  };

  onChangePasswordNew = (e, { value }) => {
    let passwordNew = value;
    this.setState(
      Object.assign({}, this.state, {
        passwordNew,
        passwordText:
          this.passwordLengthGoal(passwordNew) > 0
            ? 'New Password (' +
              this.passwordLengthGoal(passwordNew) +
              ' more character' +
              (this.passwordLengthGoal(passwordNew) === 1
                ? ' is needed)'
                : 's are needed)')
            : 'New Password',
      })
    );
  };

  onChangePasswordConfirm = (e, { value }) => {
    let passwordConfirm = value;
    this.setState(Object.assign({}, this.state, { passwordConfirm }));
  };

  onChangeEmail = (e, { value }) => {
    let email = value;
    this.setState(
      Object.assign({}, this.state, {
        email,
        isChanged:
          this.state.firstName !== this.props.user.first_name ||
          this.state.lastName !== this.props.user.last_name ||
          email !== this.props.user.email,
      })
    );
  };

  onChangeTitle = (e, { value }) => {
    let title = value;

    this.setState(
      Object.assign({}, this.state, {
        title,
        isChanged: this.state.title !== title,
      })
    );
  };

  onFileClick = () => {
    this.refs.file.click();
  };

  passwordLengthGoal = (password) => {
    return PASSWORD_LENGTH - password.length;
  };

  passwordEmptyCheck = (passwordExisting, passwordNew, passwordConfirm) => {
    return (
      (!passwordExisting && !passwordNew && !passwordConfirm) ||
      (!!passwordExisting && !!passwordNew && !!passwordConfirm)
    );
  };

  isSamePassword = (passwordExisting, passwordNew, passwordConfirm) => {
    return passwordNew === passwordConfirm;
  };

  passwordLengthCheck = (passwordExisting, passwordNew, passwordConfirm) => {
    return (
      passwordExisting.length + passwordNew.length + passwordConfirm.length !==
      0
    );
  };

  shouldPasswordSubmitBeDisabled = (
    passwordExisting,
    passwordNew,
    passwordConfirm
  ) => {
    return (
      this.props.isRequesting ||
      !this.isSamePassword(passwordExisting, passwordNew, passwordConfirm) ||
      (passwordNew.length < PASSWORD_LENGTH &&
        this.passwordLengthCheck(
          passwordExisting,
          passwordNew,
          passwordConfirm
        )) ||
      !this.passwordEmptyCheck(passwordExisting, passwordNew, passwordConfirm)
    );
  };

  togglePasswordDisplay = () => {
    this.setState(
      Object.assign({}, this.state, {
        displayPasswords: !this.state.displayPasswords,
      })
    );
  };

  handleTogglePasswordDisplay = (e) => {
    e.preventDefault();
    this.togglePasswordDisplay();
  };

  render() {
    const hasAvatar =
      this.props.user.avatar_attachment &&
      this.props.user.avatar_attachment.medium;
    return (
      <div className="settings-user-attendant card">
        <Header as="h2" attached="top">
          Your Details
        </Header>
        <Segment attached="bottom" className="flex-wrapper">
          <form
            className={
              'ui form user-details-form ' +
              (this.props.isRequesting ? 'loading' : '')
            }
            ref="form"
            onSubmit={this.handleFormSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="First Name"
                name="first_name"
                onChange={this.onChangeFirstName}
                value={this.state.firstName}
                placeholder="First Name"
              />

              <Form.Input
                fluid
                label="Last Name"
                name="last_name"
                onChange={this.onChangeLastName}
                value={this.state.lastName}
                placeholder="Last Name"
              />
            </Form.Group>
            <Form.Input
              fluid
              label="Job Title"
              name="title"
              onChange={this.onChangeTitle}
              className="settings-title"
              value={this.state.title}
              placeholder={'Title'}
            />
            <Form.Input
              fluid
              label="Email"
              type="email"
              name="email"
              onChange={this.onChangeEmail}
              className="settings-email"
              value={this.state.email}
              placeholder={'Email'}
            />
            <Form.Field
              fluid
              className="settings-role"
              content={
                <div>
                  <label>Role</label>
                  <div
                    style={{
                      margin: '0.25rem 0',
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span style={{ padding: '0 1rem 0 0' }}>
                      {this.props.user.customer_role.name}
                    </span>
                    <ModalCustomerRolesDesc />
                  </div>
                </div>
              }
            />
            <input
              name="file"
              ref="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif,image/gif,image/png,image/jpeg"
              onChange={this.handleChangeAvatar}
              className="avatar"
            />
            <div className="change-password-wrapper">
              <Button
                className="tertiary change-password-toggle"
                onClick={this.handleTogglePasswordDisplay}>
                Change password
              </Button>
              {this.state.displayPasswords && (
                <div className="change-password">
                  <Form.Input
                    fluid
                    type="password"
                    onChange={this.onChangePasswordExisting}
                    value={this.state.passwordExisting}
                    label="Current Password"
                  />
                  <Form.Input
                    fluid
                    type="password"
                    onChange={this.onChangePasswordNew}
                    value={this.state.passwordNew}
                    label={this.state.passwordText}
                  />
                  <Form.Input
                    fluid
                    type="password"
                    onChange={this.onChangePasswordConfirm}
                    value={this.state.passwordConfirm}
                    isValid={this.isSamePassword(
                      this.state.passwordExisting,
                      this.state.passwordNew,
                      this.state.passwordConfirm
                    )}
                    label="Confirm New Password"
                  />
                </div>
              )}
              <Button type="submit" primary disabled={this.isDisabled()}>
                Update Settings
              </Button>
            </div>
          </form>
          <div className="user-details-icon-wrap">
            <div className="user-details-icon">
              {!hasAvatar && (
                <img
                  className="input-avatar-preview-placeholder"
                  width="100"
                  height="100"
                  alt={this.state.firstName}
                  src={avatarPlaceholder}
                  onClick={this.onFileClick}
                />
              )}
              {hasAvatar && (
                <img
                  className="input-avatar-preview"
                  width="145"
                  height="145"
                  alt={this.state.firstName}
                  src={this.props.user.avatar_attachment.medium}
                  onClick={this.onFileClick}
                />
              )}
            </div>
            <Button secondary compact size="mini" onClick={this.onFileClick}>
              Upload Profile Pic
            </Button>
          </div>
        </Segment>
      </div>
    );
  }
}

SettingsUser.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool,
  actions: PropTypes.shape({
    submitUpdateEmployee: PropTypes.func.isRequired,
    submitUpdateAvatar: PropTypes.func.isRequired,
    doChangePassword: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.employees.user,
    location: state.locations.open,
    isRequesting:
      state.employees.updating.filter((e) => {
        return e.id === state.employees.user.id && !e.error;
      }).length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateEmployee,
        submitUpdateAvatar,
        doChangePassword,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUser);
