import React from 'react';
import ScheduleAttendantForm from './schedule-attendant-form.js';
import { FILTER_DATES_BOOKABLE } from '../../strings.js';
import { Button } from 'semantic-ui-react';
import './reports-list-empty-state.css';

export default class ReportsListEmptyState extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showScheduleAttendantForm: false,
    };
  }

  toggleScheduleAttendantForm = (e) => {
    e.preventDefault();
    this.setState(
      Object.assign({}, this.state, {
        showScheduleAttendantForm: !this.state.showScheduleAttendantForm,
      })
    );
  };

  render() {
    return (
      <div className="reports-list-empty-state">
        <div className="fixed-width-column">
          <div className="empty-state-container">
            <div className="empty-shift-illustration"></div>
            <h3 className="empty-state-header">
              You don't have any shift reports.
            </h3>
            <p className="empty-state-subheader">
              Let's start by getting one of our Office Attendants over to help
              you cover the everyday tasks while you focus on a special project
              or big event.
            </p>
            {!this.state.showScheduleAttendantForm && (
              <Button primary onClick={this.toggleScheduleAttendantForm}>
                Book an Attendant
              </Button>
            )}
            {this.state.showScheduleAttendantForm && (
              <div className="schedule-attendant-form-container">
                <ScheduleAttendantForm filterBookable={FILTER_DATES_BOOKABLE} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
