import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message } from 'semantic-ui-react';
import { doRequestACH } from '../../actions/email-actions.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';

export class AchRequestForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displaySuccess: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.actions.doRequestACH();
    this.setState(() => {
      return {
        displaySuccess: true,
      };
    });
  };

  render() {
    return (
      <div className="ach-request-wrap" style={this.props.style}>
        {this.state.displaySuccess && (
          <Message success>
            <Message.Header>Request Processing</Message.Header>
            <p>
              Our billing team will reach out to you with next steps to set up
              ACH payments.
            </p>
          </Message>
        )}
        {!this.state.displaySuccess && (
          <Form onSubmit={this.handleSubmit}>
            <p>
              Payments made via credit card and debit card may incur a payment
              processing fee. Should you wish to avoid convenience fees you may
              pay via ACH or check which have no convenience fee.
            </p>
            <Form.Button secondary>Request ACH Setup</Form.Button>
          </Form>
        )}
      </div>
    );
  }
}

AchRequestForm.propTypes = {
  actions: PropTypes.shape({
    doRequestACH: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
};

AchRequestForm.defaultProps = {
  onSubmit: function () {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doRequestACH,
      },
      dispatch
    ),
  };
}

function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  AchRequestForm
);
