import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';

export const getPromotedBrands = (data) => ({
  type: types.GET_PROMOTED_BRANDS,
  data,
});

export const successGetPromotedBrands = (data) => ({
  type: types.SUCCESS_GET_PROMOTED_BRANDS,
  data,
});

export const errorGetPromotedBrands = (data) => ({
  type: types.ERROR_GET_PROMOTED_BRANDS,
  data,
});

/**
 * Get all product brands
 *
 * @access public
 * @returns {Promise}
 */
export function doGetPromotedBrands() {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_PROMOTED_BRANDS,
      location_id: location.id,
    };
    dispatch(getPromotedBrands(data));
    return fetch(`${ADMIN_V1_URL}/locations/${location.id}/featured_brands`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get featured brands' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetPromotedBrands(body.data));
      })
      .catch((error) => {
        dispatch(errorGetPromotedBrands(error));
        trackFetchError(error);
      });
  };
}
