import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Loader } from 'semantic-ui-react';
import { CartPropType, cartName } from '../../helpers/cart-helpers.js';
import {
  openCart,
  openCartConfig,
  FILTER_IS_LOADING,
} from '../../ducks/carts.js';
import './cart-switcher.css';

export class CartSwitcher extends React.PureComponent {
  cartName = (cart) => cartName(cart, this.props.actor);

  options = () => {
    return this.props.carts.map((cart) => ({
      key: cart.id,
      text: this.cartName(cart),
      value: cart.id,
    }));
  };

  handleChange = (e, { value }) => {
    this.props.actions.openCart({ id: value });
  };

  handleEditClick = () => {
    this.props.actions.openCartConfig();
  };

  render() {
    if (this.props.isLoading) {
      return <Loader active inline size="mini" />;
    }
    return (
      <Dropdown
        simple
        className="cart-switcher"
        loading={this.props.isLoading}
        text={this.cartName(this.props.openCart)}
        value={this.props.openCart.id}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon="edit"
            text="Cart Settings"
            onClick={this.handleEditClick}
          />
          <Dropdown.Divider />
          {this.options().map((item) => (
            <Dropdown.Item
              text={item.text}
              key={item.key}
              value={item.value}
              onClick={this.handleChange}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

CartSwitcher.propTypes = {
  enabled: PropTypes.bool,
  openCart: CartPropType,
  carts: PropTypes.arrayOf(CartPropType),
  actor: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['Employee', 'Staff']),
  }),
};

CartSwitcher.defaultProps = {
  enabled: false,
};

export function mapStateToProps(state) {
  return {
    openCart: state.carts.open,
    carts: Object.values(state.carts.items),
    isLoading: state.carts.requesting.filter(FILTER_IS_LOADING).length > 0,
    actor: state.auth.actor,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCart,
        openCartConfig,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.items === next.carts.items &&
    prev.carts.requesting === next.carts.requesting &&
    prev.auth.actor === next.auth.actor
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CartSwitcher);
