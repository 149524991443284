import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import StripeBillingInfoForm from './stripe-billing-info-form.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/table.min.css';

export default class StripeBillingInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  handleToggleEdit = () => {
    this.setState((prev) => {
      return {
        editing: !prev.editing,
      };
    });
  };

  render() {
    if (this.state.editing) {
      return (
        <StripeBillingInfoForm
          action="update"
          onSuccess={this.handleToggleEdit}
          onCancel={this.handleToggleEdit}
        />
      );
    }
    let billing_details =
      this.props.account.payment_method.billing_details || {};
    let { address } = billing_details;
    let payment_info = this.props.account.payment_method || null;
    let address_info = address ? address : null;

    return (
      <Table basic="very" className="stripe-billing-info">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Credit Card</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {this.props.active && <em>Preferred Method</em>}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <p>Company</p>
            </Table.Cell>
            <Table.Cell>
              <p>{this.props.account.description}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>Name</p>
            </Table.Cell>
            <Table.Cell>
              <p>{billing_details.name}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>Email</p>
            </Table.Cell>
            <Table.Cell>
              <p>{billing_details.email}</p>
            </Table.Cell>
          </Table.Row>
          {payment_info && payment_info.card.brand && (
            <Table.Row>
              <Table.Cell>
                <p>Credit Card</p>
              </Table.Cell>
              <Table.Cell>
                <p>
                  {payment_info.card.brand} *** {payment_info.card.last4}
                </p>
              </Table.Cell>
            </Table.Row>
          )}
          {payment_info && payment_info.card.exp_month && (
            <Table.Row>
              <Table.Cell>
                <p>Expiration</p>
              </Table.Cell>
              <Table.Cell>
                <p>
                  {payment_info.card.exp_month}/{payment_info.card.exp_year}
                </p>
              </Table.Cell>
            </Table.Row>
          )}
          {address_info && (
            <Table.Row>
              <Table.Cell>
                <p>Address</p>
              </Table.Cell>
              <Table.Cell>
                <p>{address_info.line1}</p>
                <p>{address_info.line2}</p>
                <p>
                  {address_info.city}, {address_info.state}{' '}
                  {address_info.postal_code}
                </p>
                <p>{address_info.phone}</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        {this.props.canEdit && (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <Button
                  size="mini"
                  compact
                  onClick={this.handleToggleEdit}
                  className="tertiary">
                  {this.state.editing ? 'View' : 'Update Credit Card'}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    );
  }
}
