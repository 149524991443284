import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelect from '@officeluv/react-multi-select';
import { sortProductCategories } from '../../lib/formatters.js';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import './product-filters.css';

export class ProductCategoryFilter extends React.PureComponent {
  _options() {
    const { categories, categoryFilters } = this.props;
    if (!categories) return [];
    const labels = sortProductCategories(categories, categoryFilters).map(
      (c) => ({
        label: c.name,
        value: c.id,
      })
    );
    return labels;
  }

  _selectedOptions() {
    if (!this.props.categories || this.props.categories.length === 0) {
      return [];
    }
    return this.props.categoryFilters;
  }

  onSelectedChanged = (data) => {
    this.props.actions.setProductQuery({
      filters: {
        categoryIds: data,
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
  };

  render() {
    return (
      <div className="product-filter">
        <MultiSelect
          overrideStrings={{ selectSomeItems: 'Filter by Category' }}
          hasSelectAll={false}
          options={this._options()}
          onSelectedChanged={this.onSelectedChanged}
          selected={this._selectedOptions()}
        />
      </div>
    );
  }
}

ProductCategoryFilter.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  categoryFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export function mapStateToProps(state) {
  return {
    categories: state.productCategories.items,
    categoryFilters: state.productQuery.filters.categoryIds || [],
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productCategories === next.productCategories &&
    prev.productQuery.filters.categoryIds ===
      next.productQuery.filters.categoryIds
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductCategoryFilter);
