import React from 'react';
import PropTypes from 'prop-types';
import { Header, Image, Table } from 'semantic-ui-react';
import {
  formatUnitOfPurchase,
  formatCentAmount,
  convertUnitToString,
  formatProductImageURL,
} from '../../lib/formatters.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/image.min.css';
import 'semantic-ui-css/components/table.min.css';
import './similar-row.css';

const SimilarRow = (props) => {
  const unit = convertUnitToString(props.product.unit_measure);
  return (
    <Table.Row key={props.product.id}>
      <Table.Cell>
        <a href={`#${props.product.id}`} onClick={props.onClick}>
          <Header as="h4" image>
            <Image
              src={formatProductImageURL(props.product, 'thumb')}
              rounded
              size="mini"
            />
            <Header.Content>{props.product.name}</Header.Content>
          </Header>
        </a>
      </Table.Cell>
      <Table.Cell>
        {formatUnitOfPurchase(
          props.product.unit_purchase,
          props.product.stock_per_purchase,
          props.product.unit_stock
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {formatCentAmount(props.product.price)}
        {props.showCostSaving && props.costSaving > 0 && (
          <div className="cost-saving__text">
            Save {parseInt(props.costSaving, 10)}% {unit !== 'Each' && 'per'}{' '}
            {unit}!
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

SimilarRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stock_per_purchase: PropTypes.number.isRequired,
    unit_purchase: PropTypes.string.isRequired,
    unit_stock: PropTypes.string.isRequired,
  }).isRequired,
  costSaving: PropTypes.number,
  showCostSaving: PropTypes.bool.isRequired,
};

SimilarRow.defaultProps = {
  showCostSaving: false,
};

export default SimilarRow;
