import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from './lib/history.js';
import AuthCheck from './components/auth-check.js';
import NavBarAttendant from './components/nav/navbar-attendant.js';
import Loader from './components/loader.js';
import './app-attendant.css';

class AppAttendant extends React.PureComponent {
  componentDidMount() {
    this.redirect();
  }

  componentDidUpdate() {
    this.redirect();
  }

  redirect() {
    if (
      this.props.inLimitedViewingModeChecked &&
      this.props.inLimitedViewingMode
    ) {
      browserHistory.push('/supplies/levels');
      return;
    } else if (!this.props.inLimitedViewingModeChecked) {
      return;
    }

    if (this.props.isRequesting) {
      return;
    } else {
      browserHistory.push('/supplies/levels');

      return;
    }
  }

  catalogRedirect() {
    if (this.props.services.match(/procurement/)) {
      browserHistory.push('/supplies/catalog/');
    } else if (this.props.services.match(/attendant/)) {
      browserHistory.push('/office-attendant/shift-details');
    } else {
      browserHistory.push('/supplies/catalog/');
    }
  }

  render() {
    if (!this.props.auth.hmac) {
      window.location = '/sign-in';
      return null;
    }
    if (this.props.isClockedOut) {
      window.location = '/staff-clocked-out';
      return null;
    }
    return (
      <div className="AppAttendant">
        <AuthCheck />
        <NavBarAttendant />
        {this.props.isRequesting && <Loader />}
        {this.props.isOrphan && (
          <div className="root-orphan">
            <h2>You are not currently connected to any offices.</h2>
            <p>
              Please contact your account manager through the help form below
              for more information.
            </p>
          </div>
        )}
      </div>
    );
  }
}

AppAttendant.propTypes = {
  auth: PropTypes.shape({
    hmac: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  }).isRequired,
  services: PropTypes.string.isRequired,
  isOrphan: PropTypes.bool,
  isRequesting: PropTypes.bool,
  isClockedOut: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    services: state.locations.open.services || '',
    isRequesting:
      state.employees.isRequesting ||
      state.locations.requesting.length > 0 ||
      state.requisitions.requesting.length > 0,
    isOrphan:
      state.employees.user.id > 0 &&
      !state.employees.isRequesting &&
      !state.locations.requesting.length &&
      state.employees.user.lastFetch > state.application.appStart &&
      Object.keys(state.locations.items).length < 1,
    isClockedOut:
      state.staffs.user.id > 0 && state.staffs.user.current_checkins.length < 1,
    inLimitedViewingModeChecked: state.application.inLimitedViewingModeChecked,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.auth === next.auth &&
    prev.staffs.user.id === next.staffs.user.id &&
    prev.locations.open.services === next.locations.open.services &&
    prev.employees.user === next.employees.user &&
    prev.employees.isRequesting === next.employees.isRequesting &&
    prev.locations.requesting === next.locations.requesting &&
    prev.locations.items === next.locations.items &&
    prev.staffs.user.current_checkins === next.staffs.user.current_checkins &&
    prev.requisitions.requesting === next.requisitions.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(AppAttendant);
