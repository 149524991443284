import { trackFetchError } from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { ADMIN_V1_URL } from '../strings.js';

const getLocationParentProductCategories = () => {
  return {
    type: types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
  };
};

const errorGetLocationParentProductCategories = (data) => {
  return {
    type: types.ERROR_GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
    data,
  };
};

const successGetLocationParentProductCategories = (data) => {
  return {
    type: types.SUCCESS_GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
    data,
  };
};

/**
 * Get all open location parent product categories
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationParentProductCategories() {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
      location_id: location.id,
    };

    dispatch(getLocationParentProductCategories(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/parent_product_categories`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get open location parent product categories',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetLocationParentProductCategories(body.data));
      })
      .catch((error) => {
        dispatch(errorGetLocationParentProductCategories(error));
        trackFetchError(error);
      });
  };
}
