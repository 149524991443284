import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthCheck from '../components/auth-check.js';
import BillingInfo from '../components/customers/billing-info.js';
import Invoicing from '../components/customers/invoicing.js';
import setTitle from '../decorators/set-title.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import { doOpenInvoice } from '../actions/invoices-actions.js';
import './company-invoices.css';

export class CompanyInvoices extends React.PureComponent {
  componentDidMount() {
    const initialQuery = window.location.search.match(/[&|?]+invoice=([0-9]+)/);
    if (initialQuery) {
      this.props.actions.doOpenInvoice({
        id: parseInt(initialQuery[1], 10),
      });
    }
  }

  render() {
    return (
      <div className="company-invoices-route">
        <AuthCheck permissions={{ can_manage_billing: true }} />
        <MountAtPageTop />
        <div className="settings-panel">
          <BillingInfo />
          <Invoicing />
        </div>
      </div>
    );
  }
}

CompanyInvoices = setTitle(() => {
  // eslint-disable-line no-class-assign
  return 'Invoices';
})(CompanyInvoices);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doOpenInvoice,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps, null, {})(CompanyInvoices);
