import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputText from '../forms/input-text.js';
import InputEmail from '../forms/input-email.js';
import { doUpdateQuickbooksBillingInfo } from '../../actions/invoices-actions.js';
import { Button } from 'semantic-ui-react';
import './quickbooks-billing-info-form.css';

export class QuickbooksBillingInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      given_name: props.account.given_name,
      family_name: props.account.family_name,
      phone: (props.account.primary_phone || {}).free_form_number,
      email: (props.account.primary_email_address || {}).address,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      Object.assign({}, this.state, {
        given_name: props.account.given_name,
        family_name: props.account.family_name,
        phone: (props.account.primary_phone || {}).free_form_number,
        email: (props.account.primary_email_address || {}).address,
      })
    );
  }

  _onChangeGivenName = (given_name) => {
    this.setState(
      Object.assign({}, this.state, {
        given_name,
      })
    );
  };

  _onChangeFamilyName = (family_name) => {
    this.setState(
      Object.assign({}, this.state, {
        family_name,
      })
    );
  };

  _onChangePrimaryPhone = (phone) => {
    this.setState(
      Object.assign({}, this.state, {
        phone,
      })
    );
  };

  _onChangePrimaryEmailAddr = (email) => {
    this.setState(
      Object.assign({}, this.state, {
        email,
      })
    );
  };

  _handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    this.props.actions.doUpdateQuickbooksBillingInfo({
      given_name: this.state.given_name,
      family_name: this.state.family_name,
      phone: this.state.phone,
      email: this.state.email,
    });
    if (this.props.onSubmit) this.props.onSubmit();
  };

  render() {
    const billing_address = this.props.account.billing_address || {};
    return (
      <form
        className="quickbooks-billing-info-form"
        onSubmit={this._handleSubmit}
      >
        <p>{this.props.account.company_name}</p>
        <p>{billing_address.line1}</p>
        <p>
          {billing_address.city}, {billing_address.country_sub_division_code}{' '}
          {billing_address.postal_code}
        </p>
        <br />
        <InputText
          onChange={this._onChangeGivenName}
          label={'First Name'}
          required={true}
          value={this.state.given_name}
        />
        <InputText
          onChange={this._onChangeFamilyName}
          label={'Last Name'}
          required={true}
          value={this.state.family_name}
        />
        <InputText
          onChange={this._onChangePrimaryPhone}
          label={'Phone Number'}
          required={true}
          value={this.state.phone}
        />
        <InputEmail
          onChange={this._onChangePrimaryEmailAddr}
          label={'Email'}
          required={true}
          value={this.state.email}
        />
        <div className="update-bill-info flex-wrapper">
          <div className="flex-item">
            <Button primary>Update Billing Info</Button>
          </div>
        </div>
      </form>
    );
  }
}

QuickbooksBillingInfoForm.propTypes = {
  actions: PropTypes.shape({
    doUpdateQuickbooksBillingInfo: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
  account: PropTypes.shape({
    billing_address: PropTypes.shape({
      id: PropTypes.string.isRequired,
      line1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country_sub_division_code: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
    }).isRequired,
    company_name: PropTypes.string.isRequired,
    family_name: PropTypes.string.isRequired,
    given_name: PropTypes.string.isRequired,
    primary_phone: PropTypes.shape({
      free_form_number: PropTypes.string.isRequired,
    }),
    primary_email_address: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doUpdateQuickbooksBillingInfo,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(QuickbooksBillingInfoForm);
