import logger from './log.js';
import pkg from '../../package.json';
const log = logger('[storage]');
const version = pkg.version;

/**
 * get value for key
 *
 * @access public
 * @param {string} key
 * @returns {object} or undefined
 */
export function getItem(key) {
  if (!window.localStorage) return;
  const item = localStorage.getItem(key);
  if (item) {
    log.log('reading', key);
  }
  let parsed = item && JSON.parse(item);
  if (parsed && parsed.version) {
    if (version === parsed.version) {
      return parsed.data;
    } else {
      log.log('version mismatch', key, 'is', parsed.version, 'not', version);
      return;
    }
  }
}
/**
 * set value for key
 *
 * @access public
 * @param {string} key
 * @param {object} value
 * @returns {object} or undefined
 */
export function setItem(key, value) {
  if (!window.localStorage) return;
  log.log('updating', key, ':', value);
  try {
    return localStorage.setItem(key, JSON.stringify({ version, data: value }));
  } catch (e) {
    log.error(e);
  }
}
/**
 * set value for key asynchronously
 *
 * @access public
 * @param {string} key
 * @param {object} value
 * @param {function} cb (error, result)
 */
export function setItemAsync(key, value, cb) {
  if (!window.localStorage) {
    if (cb) cb(null);
    return;
  }
  log.log('updating', key, 'async:', value);
  setTimeout(
    function (key, value) {
      let result;
      let error = null;
      try {
        result = localStorage.setItem(
          key,
          JSON.stringify({ version, data: value })
        );
      } catch (e) {
        log.error(e);
        error = e;
      }
      if (cb) cb(error, result);
    },
    1,
    key,
    value
  );
}
/**
 * remove value for key
 *
 * @access public
 * @param {string} key
 */
export function removeItem(key) {
  if (!window.localStorage) return;
  log.log('removing', key);
  return localStorage.removeItem(key);
}
/**
 * clear all items from memory
 *
 * @access public
 */
export function clear() {
  if (!window.localStorage) return;
  log.log('clearing');
  return localStorage.clear();
}
/**
 * get key at index
 *
 * @access public
 * @param {number} num
 * @returns {string} or undefined
 */
export function key(num) {
  if (!window.localStorage) return;
  return localStorage.key(num);
}
