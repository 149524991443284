import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelect from '@officeluv/react-multi-select';
import {
  VendorMinimumGroupPropType,
  default_vendor_minimum_group,
} from '../../helpers/cart-helpers.js';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import ProductVendorMinimumFilterItem from './product-vendor-minimum-filter-item.js';

const noMinLabel = 'No Minimum Required';
const noMinValue = 'no_min';

export class ProductVendorMinimumFilter extends React.PureComponent {
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.locationId !== this.props.locationId) {
      this.props.actions.setProductQuery({
        filters: {
          vendorIds: [],
        },
      });
    }
  }

  _determineMinimumTypes(vendor) {
    if (vendor.quantity_minimum > 0) {
      return 'quantity_min';
    }
    if (vendor.price_minimum > 0) {
      return 'price_min';
    }
  }

  _determineMinimumValue(vendor) {
    if (vendor.quantity_minimum > 0) {
      return vendor.quantity_minimum;
    }
    if (vendor.price_minimum > 0) {
      return vendor.price_minimum;
    }
  }

  _options() {
    const { unmetGroups, locationVendors, vendorColorMap } = this.props;
    if (!unmetGroups) return [];
    const noMinimumOption = {
      label: noMinLabel,
      value: noMinValue,
      group: default_vendor_minimum_group,
      color: null,
    };
    const unmetLabels = unmetGroups.map((group) => ({
      label: group.vendor_id,
      value: group.vendor_id,
      group: group,
      color: vendorColorMap[group.vendor_id],
    }));
    const remainingLabels = (locationVendors || [])
      .filter((lv) => {
        return (
          unmetGroups.filter((vg) => vg.vendor_id === lv.id).length === 0 &&
          (lv.price_minimum > 0 || lv.quantity_minimum > 0)
        );
      })
      .map((vendor) => ({
        label: vendor.id,
        value: vendor.id,
        group: {
          vendor_id: vendor.id,
          minimum: {
            type: this._determineMinimumTypes(vendor),
            value: this._determineMinimumValue(vendor),
            portion_total: 1,
          },
          vendor_product_requisitions: [],
        },
        color: vendorColorMap[vendor.id],
      }))
      .sort((a, b) => {
        if (a.group.minimum.type === b.group.minimum.type)
          return a.group.minimum.value < b.group.minimum.value ? -1 : 1;
        return a.group.minimum.type < b.group.minimum.type ? -1 : 1;
      });
    return [noMinimumOption].concat(unmetLabels).concat(remainingLabels);
  }

  _selectedOptions() {
    return this.props.vendorIds;
  }

  _valueRenderer(selected) {
    if (selected.length === 0) {
      return 'Filter by Cart Minimum';
    }
    if (selected.length === 1) {
      return '1 item selected';
    }
    return `${selected.length} items selected`;
  }

  onSelectedChanged = (data) => {
    this.props.actions.setProductQuery({
      filters: {
        vendorIds: data,
      },
    });
    this.props.actions.doGetLocationProductQueryResults();
  };

  render() {
    return (
      <div className="product-filter">
        <MultiSelect
          overrideStrings={{ selectSomeItems: 'Filter by Cart Minimum' }}
          disableSearch={true}
          hasSelectAll={false}
          ItemRenderer={ProductVendorMinimumFilterItem}
          valueRenderer={this._valueRenderer}
          options={this._options()}
          onSelectedChanged={this.onSelectedChanged}
          selected={this._selectedOptions()}
        />
      </div>
    );
  }
}

ProductVendorMinimumFilter.propTypes = {
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  vendorIds: PropTypes.arrayOf(
    PropTypes.oneOf(['no_min', PropTypes.instanceOf(Number)])
  ).isRequired,
  unmetGroups: PropTypes.arrayOf(VendorMinimumGroupPropType).isRequired,
  locationId: PropTypes.number.isRequired,
  locationVendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      price_minimum: PropTypes.number.isRequired,
      quantity_minimum: PropTypes.number.isRequired,
    })
  ).isRequired,
  vendorColorMap: PropTypes.object.isRequired,
};

export function mapStateToProps(state) {
  const cart = state.carts.open;
  let unmetGroups = cart._optimized_product_requisitions.unmet_minimums;
  const vendorIds = state.productQuery.filters.vendorIds;
  const locationVendors = state.locations.open.vendors;
  const vendorColorMap = state.locations.open.vendorColorMap;
  return {
    vendorIds,
    unmetGroups,
    locationVendors,
    vendorColorMap,
    locationId: state.locations.open.id,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.locations.open.vendorColorMap === next.locations.open.vendorColorMap &&
    prev.carts.open === next.carts.open &&
    prev.productQuery.filters.vendorIds ===
      next.productQuery.filters.vendorIds &&
    prev.locations.open.id === next.locations.open.id &&
    prev.locations.open.vendors === next.locations.open.vendors
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductVendorMinimumFilter);
