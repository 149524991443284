import React from 'react';

export default class MountAtPageTop extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return null;
  }
}
