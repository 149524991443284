import * as types from '../actions/action-types';

export const empty = {
  initialized: false,
  items: [],
  updating: [],
  creating: [],
  user: {
    id: 0,
    lastFetch: false,
    email: '',
    first_name: '',
    last_name: '',
    locations: [],
    customer_role: {
      name: null,
    },
    external_accounts: {},
  },
  open: { id: 0 },
  isRequesting: false,
  error: false,
};
export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_LOCATION_EMPLOYEES:
      return {
        ...state,
        initialized: true,
      };
    case types.SUCCESS_EMPLOYEES:
      return Object.assign({}, state, {
        items: action.data,
        isRequesting: false,
        error: false,
      });
    case types.GET_EMPLOYEE_SELF:
      return Object.assign({}, state, {
        isRequesting: true,
      });
    case types.SUCCESS_EMPLOYEE_SELF:
      return Object.assign({}, state, {
        isRequesting: false,
        user: Object.assign({}, action.data, {
          lastFetch: new Date(),
        }),
      });
    case types.POST_INVITE_EMPLOYEE:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((t) => t._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_INVITE_EMPLOYEE:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (t) => t._request !== action.data._request
        ),
        items: state.items
          .filter((t) => t.id !== action.data.id)
          .concat(action.data),
        open:
          action.data.id === state.open.id
            ? { ...state.open, ...action.data }
            : state.open,
      });
    case types.SUCCESS_UNINVITE_EMPLOYEE:
      return Object.assign({}, state, {
        updating: state.updating.filter((t) => t.id !== action.data.id),
        items: action.data._isOpenLocation
          ? state.items.filter((t) => t.id !== action.data.id)
          : state.items
              .filter((t) => t.id !== action.data.id)
              .concat(action.data),
      });
    case types.ERROR_INVITE_EMPLOYEE:
      return Object.assign({}, state, {
        creating: state.creating.map((c) => {
          if (c._request !== action.data._request) return c;
          return Object.assign({}, c, { error: action.data });
        }),
      });
    case types.UPDATE_EMPLOYEE:
    case types.UPDATE_AVATAR:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((t) => t.id !== action.data.id)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_EMPLOYEE:
    case types.SUCCESS_UPDATE_AVATAR:
    case types.SUCCESS_GET_EMPLOYEE_STRIPE_ACCOUNT:
      return Object.assign({}, state, {
        updating: state.updating.filter((t) => t.id !== action.data.id),
        items: state.items.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, action.data);
        }),
        open:
          action.data.id === state.open.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
        user:
          action.data.id === state.user.id
            ? Object.assign({}, state.user, action.data)
            : state.user,
      });
    case types.ERROR_UPDATE_EMPLOYEE:
    case types.ERROR_UPDATE_AVATAR:
      return Object.assign({}, state, {
        updating: state.updating.filter((c) => {
          return c._request !== action.data._request;
        }),
      });
    case types.OPEN_EMPLOYEE:
      return {
        ...state,
        open:
          state.items.filter((e) => e.id === action.data.id)[0] || action.data,
      };
    case types.CLOSE_EMPLOYEE:
      return {
        ...state,
        open: empty.open,
      };
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
