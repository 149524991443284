import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './droppable.css';

export default function Droppable(WrappedComponent) {
  class WrappingComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isDragging: false,
      };
    }

    componentDidMount() {
      window.document.addEventListener('dragstart', this.handleDragStart);
      window.document.addEventListener('dragend', this.handleDragEnd);
    }

    componentWillUnmount() {
      window.document.removeEventListener('dragstart', this.handleDragStart);
      window.document.removeEventListener('dragend', this.handleDragEnd);
    }

    handleDragStart = (ev) => {
      this.setState({ ...this.state, isDragging: true });
    };

    handleDragEnd = (ev) => {
      this.setState({ ...this.state, isDragging: false });
      this.clearActiveStyles();
    };

    handleDrop = (ev) => {
      const mouseRelation = this.getMouseRelation(ev);
      const data = {
        event: ev,
        transferId: parseInt(ev.dataTransfer.getData('id'), 10),
        destinationId: parseInt(ev.target.id, 10),
        direction: mouseRelation,
      };
      this.props.onDrop && this.props.onDrop(data);
    };

    handleDragEnter = (ev) => {
      ev.preventDefault();
    };

    handleDragOver = (ev) => {
      this.setActiveStyles(ev);
      // do things here then preventDefault, this needs
      // to be here for handleDrop to work.
      ev.preventDefault();
    };

    handleDragLeave = (ev) => {
      this.clearActiveStyles();
    };

    setActiveStyles = (ev) => {
      const mouseRelation = this.getMouseRelation(ev);
      if (mouseRelation === 'TOP') {
        this.refs.wrapper.style.paddingTop = '5rem';
        this.refs.wrapper.style.paddingBottom = '0';
      }
      if (mouseRelation === 'BOTTOM') {
        this.refs.wrapper.style.paddingBottom = '5rem';
        this.refs.wrapper.style.paddingTop = '0';
      }
      this.refs.wrapper.style.transitionProperty = 'padding-top padding-bottom';
      this.refs.wrapper.style.transitionDuration = '0.15s, 0.15s';
      this.refs.wrapper.style.transitionTimingFunction =
        'ease-in-out, ease-in-out';
      this.refs.wrapper.style.backgroundColor = '#c799c7';
      this.refs.wrapper.style.cursor = 'grabbing';
    };

    clearActiveStyles = () => {
      this.refs.wrapper.style.margin = '';
      this.refs.wrapper.style.opacity = 1;
      this.refs.wrapper.style.paddingTop = '0';
      this.refs.wrapper.style.paddingBottom = '0';
      this.refs.wrapper.style.backgroundColor = '';
      this.refs.wrapper.style.cursor = '';
    };

    getMouseRelation = (ev) => {
      const distanceFromTop =
        ev.pageY - (ev.target.getBoundingClientRect().top + window.scrollY);
      const distanceFromBottom =
        ev.target.getBoundingClientRect().bottom + window.scrollY - ev.pageY;
      const cardHeight = distanceFromBottom - distanceFromTop;
      if (distanceFromTop < cardHeight * 0.5) return 'TOP';
      if (distanceFromBottom < cardHeight * 0.5) return 'BOTTOM';
      return 'BOTTOM';
    };

    render() {
      return (
        <div
          className="droppable"
          ref="wrapper"
          onDrop={this.handleDrop}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
        >
          <WrappedComponent {...this.props} />
          {this.state.isDragging && (
            <div className="droppable-phantom" id={this.props.item.id}></div>
          )}
        </div>
      );
    }
  }

  function mapStateToProps(state, props) {
    return {};
  }

  function mapDispatchToProps(dispatch, props) {
    return {
      actions: bindActionCreators({}, dispatch),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappingComponent);
}

Droppable.PropTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  ondrop: PropTypes.func.isRequired /*** should handle reordering of items ***/,
};
