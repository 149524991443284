import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deskImage from '../../imgs/onboarding/desk.jpeg';
import logo from '../../imgs/officeluv-full-logo-teal.svg';
import './step.css';

export class OnboardingStep extends React.Component {
  render() {
    let img = this.props.location.avatar_attachment
      ? this.props.location.avatar_attachment.medium
      : logo;
    return (
      <div className={'onboarding-step ' + (this.props.className || '')}>
        <div className="content-panel">
          <img src={img} alt="OfficeLuv Logo" height={40} className="logo" />
          <div className="control-panel-inner">{this.props.children}</div>
        </div>
        <div
          className="image-panel"
          style={{
            backgroundImage: this.props.backgroundImage,
            backgroundPosition: this.props.backgroundPosition,
          }}
        />
      </div>
    );
  }
}

OnboardingStep.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  backgroundPosition: PropTypes.string.isRequired,
  className: PropTypes.string,
  location: PropTypes.shape({
    brand_color: PropTypes.string,
    avatar_attachment: PropTypes.shape({
      medium: PropTypes.string,
    }),
  }).isRequired,
};

OnboardingStep.defaultProps = {
  backgroundImage: `url(${deskImage})`,
  backgroundPosition: 'center top',
  location: {},
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
  };
}

function areStatesEqual(prev, next) {
  return prev.locations.open === next.locations.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  OnboardingStep
);
