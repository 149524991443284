import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import RRuleGenerator from 'react-rrule-generator';
import './input-rrule.css';

const appendBYHOURonChange = (props) => {
  const match = props.value.match(/;BYHOUR=\d+/);
  const BYHOUR = match ? match[0] : '';
  return (value) => {
    props.onChange(value.concat(BYHOUR));
  };
};

const inputOrder = ['Weekly', 'Monthly', 'Daily', 'Yearly'];

const InputRrule = (props) => {
  if (!props.onChange) return null;
  return (
    <div className="rrule-input">
      <RRuleGenerator
        onChange={appendBYHOURonChange(props)}
        value={props.value || ''}
        config={{
          allowBYSETPOS: false,
          hideEnd: true,
          hideStart: true,
          repeat: inputOrder,
          frequency: inputOrder,
        }}
      />
    </div>
  );
};

InputRrule.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputRrule;
