import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { doCreateProductStockNotification } from '../../ducks/product-stock-notifications.js';
import 'semantic-ui-css/components/button.min.css';

const ProductStockNotifyIcon = (props) => {
  if (props.actor.type !== 'Employee') return null;

  if (props.isAlreadyNotifiable) {
    return (
      <Button
        title="You will be notified when this item is back in stock"
        className="tertiary"
        icon="bell"
        size="tiny"
        disabled
        style={{
          padding: '0.85rem 0.75rem',
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      />
    );
  }

  const handleNotify = (e) => {
    e.stopPropagation();
    props.actions.doCreateProductStockNotification({
      location_id: props.locationId,
      employee_id: props.actor.id,
      product_id: props.product.id,
    });
  };

  return (
    <Button
      title="Notify me when item is back in stock"
      className="tertiary"
      icon="bell outline"
      size="tiny"
      onClick={handleNotify}
      style={{
        padding: '0.85rem 0.75rem',
        position: 'absolute',
        bottom: 0,
        right: 0,
      }}
    />
  );
};

ProductStockNotifyIcon.propTypes = {
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['Employee', 'Staff']).isRequired,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  locationId: PropTypes.number.isRequired,
  isAlreadyNotifiable: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    locationId: state.locations.open.id,
    actor: state.auth.actor,
    isAlreadyNotifiable: !!Object.values(
      state.productStockNotifications.items
    ).filter(
      (item) =>
        item.product_id === ownProps.product.id &&
        item.location_id === state.locations.open.id &&
        !item.notification_sent_at
    ).length,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCreateProductStockNotification,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open.id === next.locations.open.id &&
    prev.auth.actor === next.auth.actor &&
    prev.productStockNotifications === next.productStockNotifications
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductStockNotifyIcon);
