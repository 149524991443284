import * as types from '../actions/action-types';

export const empty = {
  initialized: false,
  errors: [],
  creating: [],
  requesting: [],
  updating: [],
  items: [],
  open: { id: 0 },
};

export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_EXTERNAL_VENDOR_INVOICES:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_EXTERNAL_VENDOR_INVOICES,
            },
            action.data
          )
        ),
      };
    case types.SUCCESS_GET_EXTERNAL_VENDOR_INVOICES:
      items = action.data.map((evi) => evi.id);
      return {
        ...state,
        items: state.items
          .filter(
            (evi) =>
              items.indexOf(evi.id) < 0 &&
              evi.location_id === action.data.location_id
          )
          .concat(action.data),
        open: {
          ...state.open,
          ...action.data.filter((s) => s.id === state.open.id)[0],
        },
        requesting: empty.requesting,
      };
    case types.CREATE_EXTERNAL_VENDOR_INVOICE:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((req) => req._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_EXTERNAL_VENDOR_INVOICE:
      return Object.assign({}, state, {
        items: state.items
          .filter((req) => req.id !== action.data.id)
          .concat(action.data),
        creating: state.creating.filter(
          (req) => req._request !== action.data._request
        ),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_CREATE_EXTERNAL_VENDOR_INVOICE:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (req) => req._request !== action.data.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_EXTERNAL_VENDOR_INVOICE:
      return {
        ...state,
        requesting: state.requesting.concat(action.data),
      };
    case types.SUCCESS_GET_EXTERNAL_VENDOR_INVOICE:
    case types.SUCCESS_UPDATE_EXTERNAL_VENDOR_INVOICE:
      return {
        ...state,
        open:
          state.open.id === action.data.id
            ? { ...state.open, ...action.data }
            : state.open,
        items: state.items
          .filter((item) => item.id !== action.data.id)
          .concat([action.data]),
        requesting: state.requesting.filter(
          (item) => item.id !== action.data.id
        ),
      };
    case types.SUCCESS_DELETE_EXTERNAL_VENDOR_INVOICE:
      return {
        ...state,
        open: state.open.id === action.data.id ? { ...empty.open } : state.open,
        items: state.items.filter((item) => item.id !== action.data.id),
        requesting: state.requesting.filter(
          (item) => item.id !== action.data.id
        ),
      };
    case types.OPEN_EXTERNAL_VENDOR_INVOICE:
      return {
        ...state,
        open:
          state.items.filter((item) => item.id === action.data.id)[0] ||
          action.data,
      };
    case types.CLOSE_EXTERNAL_VENDOR_INVOICE:
      return {
        ...state,
        open: empty.open,
      };
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
