import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import AuthCheck from '../components/auth-check.js';
import CustomerRoleForm from '../components/customerRoles/form.js';
import { Header, Segment } from 'semantic-ui-react';
import {
  stageCustomerRole,
  unstageCustomerRole,
  FILTER_IS_LOADING,
  FILTER_IS_UPDATE_SUCCESS,
} from '../ducks/customer-roles.js';
import './customer-role-form.css';

export class CustomerRoleEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!props.match.params.id) return;

    props.actions.stageCustomerRole({
      id: parseInt(props.match.params.id, 10),
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isUpdateSuccess && this.props.isUpdateSuccess) {
      browserHistory.push('/settings/team');
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions.stageCustomerRole({
        id: parseInt(this.props.match.params.id, 10),
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.unstageCustomerRole();
  }

  render() {
    return (
      <Segment
        basic
        className="customer-role-form"
        loading={this.props.isLoading}
      >
        <AuthCheck permissions={{ can_manage_roles: true }} />
        <Fragment>
          {this.props.originalCustomerRole && (
            <Header as="h1" content={this.props.originalCustomerRole.name} />
          )}
          <CustomerRoleForm
            customer={this.props.customer}
            customerRole={this.props.stagedCustomerRole}
            isNewRecord={this.props.isNewRecord}
            isLoading={this.props.isLoading}
          />
        </Fragment>
      </Segment>
    );
  }
}

CustomerRoleEdit.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  stagedCustomerRole: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  originalCustomerRole: PropTypes.shape({
    name: PropTypes.number.isRequired,
  }),
  isNewRecord: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    stagedCustomerRole: state.customerRoles.staged,
    originalCustomerRole: state.customerRoles.items[ownProps.match.params.id],
    isNewRecord: state.customerRoles.staged.id === 0,
    customer: state.customers.open,
    isLoading:
      state.customerRoles.requesting.filter(FILTER_IS_LOADING).length > 0 ||
      !state.customers.open.name,
    isUpdateSuccess:
      state.customerRoles.responses.filter(FILTER_IS_UPDATE_SUCCESS).length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        stageCustomerRole,
        unstageCustomerRole,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.staged === next.customerRoles.staged &&
    prev.customers.open === next.customers.open &&
    prev.customerRoles.requesting === next.customerRoles.requesting &&
    prev.customerRoles.responses === next.customerRoles.responses
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CustomerRoleEdit);
