import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackLocationListProductCreated,
  trackLocationListProductUpdated,
  trackLocationListProductDeleted,
  trackLocationListProducBudgetCodetUpdated,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import {
  productUnfavorited,
  productFavorited,
  enqueueConfirmation,
} from './confirmations-actions.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

export const getLocationListProducts = (data) => {
  return {
    type: types.GET_LOCATION_LIST_PRODUCTS,
    data,
  };
};

export const successGetLocationListProducts = (data) => {
  return {
    type: types.SUCCESS_LOCATION_LIST_PRODUCTS,
    data,
  };
};

export const errorGetLocationListProducts = (data) => {
  return {
    type: types.ERROR_LOCATION_LIST_PRODUCTS,
    data,
  };
};

export const getLocationListProduct = (data) => {
  return {
    type: types.GET_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const successGetLocationListProduct = (data) => {
  return {
    type: types.SUCCESS_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const errorGetLocationListProduct = (data) => {
  return {
    type: types.ERROR_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const postCreateLocationListProduct = (data) => {
  return {
    type: types.CREATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const successCreateLocationListProduct = (data) => {
  return {
    type: types.SUCCESS_CREATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const errorCreateLocationListProduct = (data) => {
  return {
    type: types.ERROR_CREATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const postUpdateLocationListProduct = (data) => {
  return {
    type: types.UPDATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const successUpdateLocationListProduct = (data) => {
  return {
    type: types.SUCCESS_UPDATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const errorUpdateLocationListProduct = (data) => {
  return {
    type: types.ERROR_UPDATE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const postDeleteLocationListProduct = (data) => {
  return {
    type: types.DELETE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const successDeleteLocationListProduct = (data) => {
  return {
    type: types.SUCCESS_DELETE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const errorDeleteLocationListProduct = (data) => {
  return {
    type: types.ERROR_DELETE_LOCATION_LIST_PRODUCT,
    data,
  };
};

export const postLocationListProductCustomerBudgetCode = (data) => {
  return {
    type: types.CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const successCreateLocationListProductCustomerBudgetCode = (data) => {
  return {
    type: types.SUCCESS_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const errorCreateLocationListProductCustomerBudgetCode = (data) => {
  return {
    type: types.ERROR_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE,
    data,
  };
};

/**
 * Add Product to Default Location List
 *
 * @access public
 * @param {Object} [product]
 * @param {NUmber} [product.id] ID of product
 * @returns {Promise}
 */

export function favoriteProduct(product) {
  return async (dispatch, getState) => {
    const locationlist = getState().locationLists.open;
    try {
      let result = await dispatch(
        submitLocationListProduct(
          {
            location_list_id: locationlist.id,
            product_id: product.id,
            min: 1,
            max: 1,
          },
          { bubble: true }
        )
      );

      if (result) {
        dispatch(
          productFavorited({
            location_list: locationlist,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 * Remove Product from Default Location List
 *
 * @access public
 * @param {Number} [product_id]
 * @param {Number} [location_list_id] ID of location list
 * @returns {Promise}
 */

export function unFavoriteProduct(product_id, location_list_id) {
  return async (dispatch, getState) => {
    const location_list_product = getState().locationListProducts.items.filter(
      (llp) =>
        llp.product_id === product_id &&
        llp.location_list_id === location_list_id
    )[0];
    try {
      let result = await dispatch(
        submitDeleteLocationListProduct(
          {
            location_list_id: location_list_id,
            id: location_list_product && location_list_product.id,
          },
          { bubble: true }
        )
      );
      if (result) dispatch(productUnfavorited());
    } catch (e) {
      console.log(e);
    }
  };
}

/**
 * Get location list products
 *
 * @access public
 * @param {Object} [options]
 * @param {String} [options.next_cursor] Next cursor for pagination
 * @param {Number} [options.location_list_id] Location List to read from
 * @param {Object[]} [options.items] Location List Products to concatenate
 * @returns {Promise}
 */
export function doGetLocationListProducts({ next_cursor = '', items = [] }) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_LOCATION_LIST_PRODUCTS,
    };
    dispatch(getLocationListProducts(data));
    let cursor_paged = next_cursor ? `&cursor=${next_cursor}` : '';
    const url = `/locations/${location.id}/location_list_products?&limit=100${cursor_paged}`;
    return fetch(`${ADMIN_V1_URL}${url}`)
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get location list products',
          });
        }
        return response.json();
      })
      .then((body) => {
        items = items.concat(body.data);
        dispatch(successGetLocationListProducts(items));
        if (body.meta.next_cursor) {
          dispatch(
            doGetLocationListProducts({
              next_cursor: body.meta.next_cursor,
              items: items,
            })
          );
        }
        return body;
      })
      .catch((error) => {
        dispatch(errorGetLocationListProducts(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get location list product
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationListProduct(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(getLocationListProduct(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_list_products/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get location list product ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successGetLocationListProduct(body));
      })
      .catch((error) => {
        dispatch(errorGetLocationListProduct(error));
        trackFetchError(error);
      });
  };
}

/**
 * Create location_list_product
 *
 * @access public
 * @returns {Promise}
 */
export function submitLocationListProduct(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postCreateLocationListProduct(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_list_products`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create location list product',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateLocationListProduct(
            Object.assign(body, { _request: data._request })
          )
        );
        trackLocationListProductCreated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateLocationListProduct(error));
        trackFetchError(error);
      });
  };
}

/**
 * Update location_list_product
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateLocationListProduct(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postUpdateLocationListProduct(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_list_products/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update location list product ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successUpdateLocationListProduct(body, data));
        trackLocationListProductUpdated(body);
      })
      .catch((error) => {
        dispatch(errorUpdateLocationListProduct(error));
        trackFetchError(error);
      });
  };
}

/**
 * Delete location list product
 *
 * @access public
 * @returns {Promise}
 */
export function submitDeleteLocationListProduct(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postDeleteLocationListProduct(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_list_products/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data,
            message: 'Delete location list product ' + data.id,
          });
        }
      })
      .then((_body) => {
        const locationListProducts = getState().locationListProducts;
        const locationListProduct = locationListProducts.items.filter(
          (llp) => llp.id === data.id
        )[0];
        dispatch(
          successDeleteLocationListProduct({
            ...data,
            product_id: locationListProduct.product_id,
          })
        );
        trackLocationListProductDeleted(data);
        if (options.bubble) return data;
      })
      .catch((error) => {
        dispatch(errorDeleteLocationListProduct(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Create customer_budget_code_location_list_product
 *
 * @access public
 * @param {Object} data
 * @param {Number} data.id
 * @param {Number} data.customer_budget_code_id
 * @param {Object} [options]
 * @param {Bool} options.bubble
 * @returns {Promise}
 */
export function submitLocationListProductBudgetCode(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postLocationListProductCustomerBudgetCode(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_list_products/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Create location list product customer budget code',
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, {
          _request: data._request,
        });
        dispatch(successCreateLocationListProductCustomerBudgetCode(body));
        trackLocationListProducBudgetCodetUpdated(body);
        dispatch(
          enqueueConfirmation({
            message: 'Updated product budget category.',
            type: 'success',
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateLocationListProductCustomerBudgetCode(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
