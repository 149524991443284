import React from 'react';
import AuthCheck from '../components/auth-check.js';
import SettingsTeamPermissions from '../components/customers/settings-team-attendant.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';

export class SettingsTeamAttendantRoute extends React.PureComponent {
  render() {
    return (
      <div className="settings-panel" style={{ maxWidth: '50rem' }}>
        <AuthCheck permissions={{ can_manage_employees_locations: true }} />
        <MountAtPageTop />
        <SettingsTeamPermissions />
      </div>
    );
  }
}

SettingsTeamAttendantRoute = setTitle((props) => {
  return 'Team Permissions';
})(SettingsTeamAttendantRoute);

export default SettingsTeamAttendantRoute;
