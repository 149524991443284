import React from 'react';
import PropTypes from 'prop-types';

export default class SearchItemHistory extends React.PureComponent {
  render() {
    return (
      <div className="search-item-history">
        {this.props.suggestion.text_searched}
      </div>
    );
  }
}

SearchItemHistory.propTypes = {
  suggestion: PropTypes.shape({
    text_typed: PropTypes.string.isRequired,
    text_searched: PropTypes.string.isRequired,
  }).isRequired,
};
