import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import setTitle from '../decorators/set-title.js';
import OnboardingStep from '../components/onboarding/step.js';
import AuthCheck from '../components/auth-check.js';
import Form from '../components/onboarding/onboarding-billing-info-form.js';
import image from '../imgs/onboarding/package.jpg';
import { isAdmin } from '../helpers/customer-role-helpers.js';

export class OnboardingBillingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _request: null,
    };
  }

  componentDidUpdate() {
    if (this.props.location._request === this.state._request) {
      browserHistory.push(this.props.nextRoute);
    }
  }

  handleSubmit = (data) => {
    this.setState(() => {
      return { _request: data._request };
    });
  };

  render() {
    return (
      <OnboardingStep backgroundImage={`url(${image})`}>
        <AuthCheck
          customAuthorized={isAdmin(this.props.roleName)}
          permissions={{ can_manage_billing: true }}
        />
        <Form onSubmit={this.handleSubmit} />
      </OnboardingStep>
    );
  }
}

OnboardingBillingInfo.propTypes = {
  roleName: PropTypes.string.isRequired,
  nextRoute: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    _request: PropTypes.string,
  }).isRequired,
};

OnboardingBillingInfo.defaultProps = {
  nextRoute: '/supplies/catalog',
};

OnboardingBillingInfo = setTitle(() => {
  return 'OfficeLuv Onboarding';
})(OnboardingBillingInfo);

function mapStateToProps(state) {
  return {
    roleName: state.auth.role.name,
    location: state.locations.open,
  };
}

function areStatesEqual(prev, next) {
  return prev.locations.open === next.locations.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  OnboardingBillingInfo
);
