import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { trackFetchError } from '../lib/analytics.js';

export const ERROR_TIMEOUT = 10000;

// Actions
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
export const SUCCESS_VALIDATE_ADDRESS = 'SUCCESS_VALIDATE_ADDRESS';
export const ERROR_VALIDATE_ADDRESS = 'ERROR_VALIDATE_ADDRESS';

export const UNSET_ADDRESS_RESPONSE = 'UNSET_ADDRESS_RESPONSE';

export const validateAddress = (data) => ({ type: VALIDATE_ADDRESS, data });
export const successValidateAddress = (data) => ({
  type: SUCCESS_VALIDATE_ADDRESS,
  data,
});
export const errorValidateAddress = (data) => ({
  type: ERROR_VALIDATE_ADDRESS,
  data,
});
export const unsetAddressResponse = (data) => ({
  type: UNSET_ADDRESS_RESPONSE,
  data,
});

// Helpers
export const FILTER_IS_REQUESTING = (response) =>
  response.type === VALIDATE_ADDRESS;

export const FILTER_IS_ERROR = (response) =>
  response.type === ERROR_VALIDATE_ADDRESS;

export const FILTER_SHOW_ERROR = (action) => {
  if (!action.data) return false;
  if (!action.data.error) return false;
  return (
    action.type === ERROR_VALIDATE_ADDRESS && action.data.error.status === 400
  );
};

// Reducer
export const empty = {
  requesting: [],
  responses: [],
};

export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case VALIDATE_ADDRESS:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_VALIDATE_ADDRESS:
    case ERROR_VALIDATE_ADDRESS:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case UNSET_ADDRESS_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    default:
      return state;
  }
};

// THUNKS

export const doValidateAddress = (data, options = { bubble: false }) => {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(validateAddress(data));
    const location_id = data.location_id || getState().locations.open.id;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location_id}/addresses/validate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          ...data,
          location_id,
          _request: undefined,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Validate Address' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successValidateAddress({
            item: body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (error.status === 400) {
          return error.response.json();
        }
        throw error;
      })
      .then((body) => {
        /* if body exists, then it was an error */
        if (body && body.error) {
          dispatch(
            errorValidateAddress({
              error: {
                ...body,
                status: 400,
              },
              _request: data._request,
            })
          );
          return;
        }
        return body;
      })
      .catch((error) => {
        dispatch(
          errorValidateAddress({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
