import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInLimitedViewingMode } from '../actions/application-actions.js';
import { trackInterfaceSetLimitedCatalogViewing } from '../lib/analytics.js';
import { Icon } from 'semantic-ui-react';
import './limited-catalog-toggle.css';

export class LimitedCatalogToggle extends React.PureComponent {
  handleClick = (e) => {
    e.stopPropagation();
    trackInterfaceSetLimitedCatalogViewing(!this.props.inLimitedViewingMode);
    this.props.actions.setInLimitedViewingMode(
      !this.props.inLimitedViewingMode
    );
  };

  renderTitle = () => {
    if (this.props.inLimitedViewingMode)
      return 'View Full/Unrestricted Catalog';
    return 'Exit Full Catalog Mode';
  };

  renderModeOff() {
    return (
      <span className="catalog-toggle__content activated">
        <Icon name="eye" size="large" />
      </span>
    );
  }

  renderModeOn() {
    return (
      <span className="catalog-toggle__content">
        <Icon name="eye slash" size="large" />
      </span>
    );
  }

  render() {
    if (!this.props.canManageLocationCatalog) return null;
    return (
      <button
        title={this.renderTitle()}
        className="catalog-toggle"
        onClick={this.handleClick}
      >
        {this.props.inLimitedViewingMode && this.renderModeOn()}
        {!this.props.inLimitedViewingMode && this.renderModeOff()}
      </button>
    );
  }
}

LimitedCatalogToggle.propTypes = {
  canManageLocationCatalog: PropTypes.bool.isRequired,
  inLimitedViewingMode: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  return {
    canManageLocationCatalog: state.auth.role.can_manage_location_catalog,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    supplyLevelsAccess:
      state.locations.open.pref_enable_location_list_stock_levels,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setInLimitedViewingMode,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.auth.role === next.auth.role &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(LimitedCatalogToggle);
