import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Icon } from 'semantic-ui-react';
import {
  submitUpdateProductRequisition,
  updateEditProductRequisition,
} from '../../actions/requisition-actions.js';
import { updateStagedScheduledProductRequisition } from '../../actions/scheduled-product-requisition-actions.js';
import {
  NO_REPLACE,
  BEST_OPTION,
  CUSTOM,
} from '../../helpers/product-requisition-helpers.js';
import './cart-substitution-preferences.css';
import checkmarkIconGreen from '../../imgs/checkmark-icon--green.svg';

const OPTIONS = [
  { key: 1, text: BEST_OPTION, value: BEST_OPTION },
  { key: 2, text: NO_REPLACE, value: NO_REPLACE },
  { key: 3, text: CUSTOM, value: CUSTOM },
];

const formatPlaceholder = (pref) => {
  if (!pref) return BEST_OPTION;
  if (pref.trim() === '') return BEST_OPTION;
  if (pref.trim() === NO_REPLACE) return NO_REPLACE;
  if (pref.trim() === BEST_OPTION) return BEST_OPTION;
  return CUSTOM;
};

export class CartSubstitutionPreferences extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: formatPlaceholder(props.item.instructions),
      updated: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.selected === CUSTOM) return;
    if (prevProps.item.instructions !== this.props.item.instructions) {
      this.setState({
        selected: formatPlaceholder(this.props.item.instructions),
      });
    }
  }

  handleChange = (e, { value }) => {
    this.submitForm(value);
    this.setState({ selected: value });
  };

  handleUpdatedCustomInstructionsFlag = () => {
    setTimeout(() => {
      this.setState({ saved: false });
    }, 5000);
  };

  handleChangeDebounce = (e) => {
    const value = e.target.value;
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.debounceTimeout = null;
      this.submitForm(value);
      this.setState({ saved: true });
      this.handleUpdatedCustomInstructionsFlag();
    }, 500);
  };

  submitForm = (value) => {
    if (this.props.isEditing) {
      return this.props.actions.updateEditProductRequisition({
        ...this.props.item,
        instructions: value,
      });
    }
    if (this.props.isEditingScheduled) {
      return this.props.actions.updateStagedScheduledProductRequisition({
        ...this.props.item,
        instructions: value,
      });
    }
    return this.props.actions.submitUpdateProductRequisition({
      id: this.props.item.id,
      instructions: value,
      requisition_id: this.props.item.requisition_id,
    });
  };

  revertToDropdown = () => {
    this.handleChange({}, { value: BEST_OPTION });
  };

  render() {
    return (
      <div className="cart-sub-pref">
        {this.state.selected !== CUSTOM && (
          <Dropdown
            selection
            text={this.state.selected}
            options={OPTIONS}
            onChange={this.handleChange}
          />
        )}
        {this.state.selected === CUSTOM && (
          <div className="cart-sub-pref__custom-wrap">
            <button
              className="cart-sub-pref__close"
              onClick={this.revertToDropdown}
            >
              <Icon name="close" />
            </button>
            <textarea
              className="cart-sub-pref__custom"
              placeholder="add your instructions here"
              onChange={this.handleChangeDebounce}
            >
              {this.props.item.instructions}
            </textarea>
          </div>
        )}
        {this.state.saved && (
          <div className="custom-preference-saved">
            <img
              src={checkmarkIconGreen}
              className="main-icon"
              alt="completed-checkmark"
            />
            Updated
          </div>
        )}
      </div>
    );
  }
}

CartSubstitutionPreferences.propTypes = {
  actions: PropTypes.shape({
    submitUpdateProductRequisition: PropTypes.func.isRequired,
    updateEditProductRequisition: PropTypes.func.isRequired,
    updateStagedScheduledProductRequisition: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    requisition_id: PropTypes.number,
    scheduled_requisition_id: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
    }).isRequired,
    instructions: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
};

CartSubstitutionPreferences.defaultProps = {
  isEditing: false,
  isEditingScheduled: false,
  actions: {
    submitUpdateProductRequisition: () => {},
    updateEditProductRequisition: () => {},
    updateStagedScheduledProductRequisition: () => {},
  },
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateProductRequisition,
        updateEditProductRequisition,
        updateStagedScheduledProductRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  CartSubstitutionPreferences
);
