import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Segment, Button } from 'semantic-ui-react';
import Calendar from 'react-calendar';
import moment from 'moment';
import CalendarMonthDay from './calendar-month-day.js';
import CalendarLegend from './calendar-legend.js';
import NextOccurrenceList from './next-occurrence-list.js';
import { GET_SCHEDULED_REQUISITIONS } from '../../actions/action-types.js';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import './calendar.css';

export class CalendarComponent extends React.PureComponent {
  isSameDay = (date) =>
    moment(date).isSame(moment(this.props.selectedDate), 'day');

  maxDate = () => moment().startOf('day').add(3, 'months').toDate();

  minDate = () => moment().startOf('day').toDate();

  onChangeActiveStartDate = (obj) => {
    if (moment(obj.activeStartDate).isSame(moment(), 'month')) {
      this.props.onDateSelect(new Date());
    } else {
      this.props.onDateSelect(obj.activeStartDate);
    }
  };

  render() {
    return (
      <Segment attached="top" loading={this.props.isRequesting}>
        <div className="calendar">
          <Calendar
            value={this.props.selectedDate}
            view="month"
            minDetail="month"
            onClickDay={this.props.onDateSelect}
            tileClassName={({ date }) =>
              this.isSameDay(date) ? 'selected' : ''
            }
            tileContent={({ date }) => (
              <CalendarMonthDay date={date} key={date.toString()} />
            )}
            calendarType="US"
            onDrillUp={this.props.onDrillUp}
            maxDate={this.maxDate()}
            minDate={this.minDate()}
            onActiveStartDateChange={this.onChangeActiveStartDate}
            key={this.props.selectedDate}
          />
        </div>
        <div className="back-to-today">
          <Button
            secondary
            size="mini"
            style={{
              textAlign: 'center',
              display: 'block',
              margin: 'auto',
              marginTop: '0.5rem',
            }}
            disabled={this.isSameDay(new Date())}
            onClick={() => this.props.onDateSelect(new Date())}>
            Back to today
          </Button>
        </div>
        <CalendarLegend />
        <NextOccurrenceList />
        <Button secondary size="mini" style={{ marginTop: '0.5rem' }}>
          <Link
            to={`/orders/scheduled/new${helpers.getQueryParams(
              moment(this.props.selectedDate).add(1, 'week').toString()
            )}&from_calendar=true`}>
            New Recurring Order
          </Link>
        </Button>
      </Segment>
    );
  }
}

CalendarComponent.propTypes = {
  onDateSelect: PropTypes.func,
  onDrillUp: PropTypes.func,
  selectedDate: PropTypes.object,
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    isRequesting:
      state.scheduledRequisitions.requesting.filter(
        (request) => request._request === GET_SCHEDULED_REQUISITIONS
      ).length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.requesting ===
    next.scheduledRequisitions.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CalendarComponent
);
