import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { closeCartConfig } from '../../ducks/carts.js';
import CartConfigForm from '../requisitions/cart-config-form.js';

export class ModalCartConfig extends React.PureComponent {
  handleCloseModal = () => {
    this.onRequestClose();
  };

  onRequestClose = () => {
    this.props.actions.closeCartConfig();
  };

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.isOpen}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Cart Config Modal"
      >
        <div className="cart-config">
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
          <CartConfigForm />
        </div>
      </Modal>
    );
  }
}

ModalCartConfig.propTypes = {
  isOpen: PropTypes.bool,
};

export function mapStateToProps(state) {
  return {
    isOpen: state.carts.cartConfigModalOpen,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeCartConfig,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return prev.carts.cartConfigModalOpen === next.carts.cartConfigModalOpen;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalCartConfig);
