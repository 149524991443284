import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';

export const getProductBrands = (data) => ({
  type: types.GET_PRODUCT_BRANDS,
  data,
});

export const successGetProductBrands = (data) => ({
  type: types.SUCCESS_GET_PRODUCT_BRANDS,
  data,
});

export const errorGetProductBrands = (data) => ({
  type: types.ERROR_GET_PRODUCT_BRANDS,
  data,
});

export const openProductBrand = (data) => ({
  type: types.OPEN_PRODUCT_BRAND,
  data,
});

export const closeProductBrand = (data) => ({
  type: types.CLOSE_PRODUCT_BRAND,
  data,
});

/**
 * Get all product brands
 *
 * @access public
 * @returns {Promise}
 */
export function doGetProductBrands(queryString = null) {
  return (dispatch, getState) => {
    const { locations, productQuery } = getState();
    const location = locations.open;
    const selectedBrandNames = productQuery.filters.brandNames;
    const data = {
      facetName: 'brand',
      facetQuery: queryString || '',
      options: {
        maxFacetHits: 100,
        sortFacetValuesBy: 'count',
      },
      _request: types.GET_PRODUCT_BRANDS,
    };
    dispatch(getProductBrands(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/products/search_facets`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get product brands' });
        }
        return response.json();
      })
      .then((body) => {
        const selectedBrandObjs = selectedBrandNames
          .filter((name) => {
            return body.filter((fh) => fh.value === name).length === 0;
          })
          .map((name) => {
            return { value: name };
          });
        const combinedResults = selectedBrandObjs.concat(body);
        dispatch(successGetProductBrands(combinedResults));
      })
      .catch((error) => {
        dispatch(errorGetProductBrands(error));
        trackFetchError(error);
      });
  };
}
