import React from 'react';
import PropTypes from 'prop-types';
import CleaningStaffItem from './cleaning-staff-item.js';
import './cleaning-staffs-list.css';

export default class CleaningStaffsList extends React.PureComponent {
  staffFilter = (staff) => {
    return (item) => {
      return item.staff_id === staff.id;
    };
  };

  renderCleaningStaffs = (staff) => {
    return (
      <CleaningStaffItem
        key={staff.id}
        staff={staff}
        showTimes={
          this.props.showTimes !== undefined ? this.props.showTimes : true
        }
        showStatus={
          this.props.showStatus !== undefined ? this.props.showStatus : true
        }
        startDatetime={this.props.cleaning.start_datetime}
        endDatetime={this.props.cleaning.end_datetime}
        checkins={this.props.cleaning.checkins.filter(this.staffFilter(staff))}
        checkinExpectations={this.props.cleaning.checkin_expectations.filter(
          this.staffFilter(staff)
        )}
      />
    );
  };

  render() {
    return (
      <div className="cleaning-staffs-list flex-wrapper">
        {this.props.cleaning.staffs.map(this.renderCleaningStaffs)}
      </div>
    );
  }
}

CleaningStaffsList.propTypes = {
  showTimes: PropTypes.bool,
  showStatus: PropTypes.bool,
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    task_list_id: PropTypes.number,
    day: PropTypes.string,
    add_task: PropTypes.string,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    checkins: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        staff_id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        cleaner_note: PropTypes.string,
        checkout_time: PropTypes.string,
      })
    ).isRequired,
    checkin_expectations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        staff_id: PropTypes.number.isRequired,
        cleaning_id: PropTypes.number.isRequired,
        expected_at: PropTypes.string.isRequired,
        note: PropTypes.note,
      })
    ).isRequired,
  }).isRequired,
};
