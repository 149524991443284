import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { List, Loader, Message, Table } from 'semantic-ui-react';
import {
  closeProductModal,
  showProductModal,
  doGetLocationCachedProduct,
} from '../../actions/product-actions.js';
import {
  trackInterfaceOpenProductSimilar,
  trackInterfaceOpenProductSizeVariant,
} from '../../lib/analytics.js';
import SimilarRow from '../products/similar-row.js';
import SimilarListItem from '../products/similar-list-item.js';
import {
  filterInLimitedCatalog,
  pricePerUnitOfMeasurePercentSavings,
  filterProductsByParentCategories,
} from '../../helpers/product-helpers.js';
import { mapLocationParentCategoriesIds } from '../../helpers/product-category-helpers.js';
import 'semantic-ui-css/components/list.min.css';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/message.min.css';
import './modal-product-similars.css';

export class ModalProductSimilars extends React.PureComponent {
  componentDidMount() {
    if (!this.props.product.similars || !this.props.product.size_variants) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.location.id,
        product: this.props.product.id,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const hasChangedProduct = prevProps.product.id !== this.props.product.id;
    if (
      hasChangedProduct &&
      (!this.props.product.comparables || !this.props.product.size_variants)
    ) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.location.id,
        product: this.props.product.id,
      });
    }
  }

  handleClickSimilar = (similar, e) => {
    e.preventDefault();
    this.props.actions.showProductModal(similar);
    trackInterfaceOpenProductSimilar(similar);
  };

  handleClickSizeVariant = (similar, e) => {
    e.preventDefault();
    this.props.actions.showProductModal(similar);
    trackInterfaceOpenProductSizeVariant(similar);
  };

  renderSimilarRow = (similar, showCostSaving = false) => {
    return (
      <SimilarRow
        product={similar}
        onClick={this.handleClickSimilar.bind(this, similar)}
        showCostSaving={showCostSaving}
        costSaving={
          pricePerUnitOfMeasurePercentSavings(this.props.product, similar) || 0
        }
        key={similar.id}
      />
    );
  };

  renderSimilarListItem = (similar) => {
    return (
      <SimilarListItem
        product={similar}
        onClick={this.handleClickSimilar.bind(this, similar)}
      />
    );
  };

  renderSizeVariantRow = (similar, showCostSaving = false) => {
    return (
      <SimilarRow
        product={similar}
        onClick={this.handleClickSizeVariant.bind(this, similar)}
        showCostSaving={showCostSaving}
        costSaving={
          pricePerUnitOfMeasurePercentSavings(this.props.product, similar) || 0
        }
        key={similar.id}
      />
    );
  };

  renderSizeVariantListItem = (similar) => {
    return (
      <SimilarListItem
        product={similar}
        onClick={this.handleClickSizeVariant.bind(this, similar)}
      />
    );
  };

  render() {
    const { isRequesting } = this.props;
    let hasRequested = !!this.props.product.similars;
    let similars = this.props.product.similars || [];
    let sizeVariants = this.props.product.size_variants || [];
    let limit = this.props.limit || 5;
    if (
      !isRequesting &&
      hasRequested &&
      !similars.length &&
      !sizeVariants.length
    ) {
      return (
        <div className="product-similars-empty">
          <p className="message">
            We couldn't find any products similar to {this.props.product.name}.
          </p>
          <p>
            <Link to="/supplies/catalog-search" className="tertiary-link">
              Search our full catalog
            </Link>
          </p>
        </div>
      );
    }
    return (
      <div className="product-similars-action">
        {isRequesting && (
          <Loader active inline="centered">
            Loading
          </Loader>
        )}
        {!isRequesting && !!sizeVariants.length && (
          <Message className="light">
            <Message.Header>Need a Different Size?</Message.Header>
            <p>Potential savings when you buy a different size.</p>
          </Message>
        )}
        {!this.props.horizontal && !!sizeVariants.length && (
          <Table basic>
            <Table.Body>
              {sizeVariants
                .slice(0, limit)
                .map((p) => this.renderSizeVariantRow(p, true))}
            </Table.Body>
          </Table>
        )}
        {this.props.horizontal && !!sizeVariants.length && (
          <List horizontal>
            {sizeVariants.slice(0, limit).map(this.renderSizeVariantListItem)}
          </List>
        )}

        <Message className="light">
          <Message.Header>
            {this.props.title || 'Recommended Items'}
          </Message.Header>
          <p>These items are often bought together.</p>
        </Message>
        {isRequesting && (
          <Loader active inline="centered">
            Loading Recommendations
          </Loader>
        )}
        {!this.props.horizontal && (
          <Table basic>
            <Table.Body>
              {similars
                .slice(0, limit)
                .map((p) => this.renderSimilarRow(p, false))}
            </Table.Body>
          </Table>
        )}
        {this.props.horizontal && (
          <List horizontal>
            {similars.slice(0, limit).map(this.renderSimilarListItem)}
          </List>
        )}
      </div>
    );
  }
}

ModalProductSimilars.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  limit: PropTypes.number,
  horizontal: PropTypes.bool,
  title: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    similars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  actions: PropTypes.shape({
    closeProductModal: PropTypes.func.isRequired,
    showProductModal: PropTypes.func.isRequired,
    doGetLocationCachedProduct: PropTypes.func.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const inLimitedViewingMode = state.application.inLimitedViewingMode;
  let product = ownProps.product;
  const in_catalog = state.products.in_catalog;

  const locationParentCategories = mapLocationParentCategoriesIds(
    state.locationParentProductCategories.items
  );

  const similars = product.similars
    ? filterProductsByParentCategories(
        product.similars,
        locationParentCategories
      )
    : null;

  if (similars) {
    product = {
      ...ownProps.product,
      similars: inLimitedViewingMode
        ? filterInLimitedCatalog(similars, in_catalog)
        : similars,
    };
  }
  return {
    product,
    location: state.locations.open,
    isRequesting:
      state.products.requesting.filter((p) => p.id === ownProps.product.id)
        .length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        closeProductModal,
        doGetLocationCachedProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items &&
    prev.products.requesting === next.products.requesting &&
    prev.products.in_catalog === next.products.in_catalog
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductSimilars);
