import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown } from 'semantic-ui-react';
import classnames from 'classnames';
import { lastMarkedAs } from '../../helpers/shipment-helpers.js';
import { openShipment, doUpdateShipment } from '../../ducks/shipments.js';
import { formatDateTime, formatName } from '../../lib/formatters';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import './item.css';

export class ItemActions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      markedAs: lastMarkedAs(this.props.item),
    };
  }

  componentDidUpdate(prevProps) {
    const currentMarkedAs = lastMarkedAs(this.props.item);
    if (lastMarkedAs(prevProps.item) !== currentMarkedAs) {
      this.setState({ markedAs: currentMarkedAs });
    }
  }

  _handleClickMarkReceived = () => {
    const receivedShipment = {
      id: this.props.item.id,
      received_at: new Date(),
    };
    this.props.actions.doUpdateShipment(receivedShipment, {
      bubble: false,
      markedAs: 'received',
    });
  };

  _handleMarkMissed = () => {
    window.totango.track('Reported a shipment as missing.', 'Settings');
    const missedShipment = {
      id: this.props.item.id,
      missed_at: new Date(),
    };
    this.props.actions.doUpdateShipment(missedShipment, {
      bubble: false,
      markedAs: 'missed',
    });
  };

  _handleChange = (event, value) => {
    this.setState(
      () => ({
        markedAs: value.value,
      }),
      () => {
        if (this.state.markedAs === 'received') {
          this._handleClickMarkReceived();
        } else if (this.state.markedAs === 'missing') {
          this._handleMarkMissed();
        }
      }
    );
  };

  _handleClickSeePackingSlip = () => {
    this.props.actions.openShipment(this.props.item);
  };

  render() {
    if (!this.props.item) return null;
    let item = this.props.item;

    return (
      <div className="purchase-order-item-buttons flex-wrapper">
        <div className="actions-wrap">
          <Dropdown
            text={this.state.markedAs || 'mark as'}
            className={classnames('actions-dropdown', {
              missed: this.state.markedAs === 'missing',
              received: this.state.markedAs === 'received',
            })}>
            <Dropdown.Menu>
              <Dropdown.Item
                text="Received"
                value="received"
                className="actions-dropdown-item received"
                onClick={this._handleChange}
              />
              <Dropdown.Item
                text="Missing"
                value="missing"
                className="actions-dropdown-item missed"
                onClick={this._handleChange}
              />
            </Dropdown.Menu>
          </Dropdown>
          {lastMarkedAs(item) === 'received' &&
            this.props.showTimestamp &&
            item.receiver && (
              <span className="action-timestamp">
                {formatDateTime(item.received_at)} by{' '}
                {formatName(item.receiver)}
              </span>
            )}
          {lastMarkedAs(item) === 'missing' && this.props.showTimestamp && (
            <span className="action-timestamp">
              {formatDateTime(item.missed_at)}
            </span>
          )}
        </div>
        {this.props.showDetailsLink && (
          <Button
            primary
            size="tiny"
            compact
            className="packing-slip-button"
            onClick={this._handleClickSeePackingSlip}>
            Details
          </Button>
        )}
      </div>
    );
  }
}

ItemActions.propTypes = {
  actions: PropTypes.shape({
    openShipment: PropTypes.func.isRequired,
    doUpdateShipment: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_id: PropTypes.number.isRequired,
    tracking_code: PropTypes.string.isRequired,
    received_at: PropTypes.string,
    receiver_id: PropTypes.number,
    receiver_type: PropTypes.string,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_zip: PropTypes.string,
    tracking_url: PropTypes.string,
    product_purchase_orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        product_id: PropTypes.number.isRequired,
        product_requisition_id: PropTypes.number.isRequired,
        purchase_order_id: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        issue: PropTypes.string,
        issue_quantity: PropTypes.number,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  showDetailsLink: PropTypes.bool.isRequired,
  showTimestamp: PropTypes.bool.isRequired,
};

ItemActions.defaultProps = {
  showDetailsLink: true,
  showTimestamp: true,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openShipment,
        doUpdateShipment,
      },
      dispatch
    ),
  };
}

export function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  ItemActions
);
