import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import setTitle from '../decorators/set-title.js';
import NavBar from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import {
  openRequisition,
  doGetRequisition,
  closeRequisition,
  setEditRequisition,
  unsetEditRequisition,
} from '../actions/requisition-actions.js';
import Loader from '../components/loader.js';
import * as requisitionHelpers from '../helpers/requisition-helpers.js';
import browserHistory from '../lib/history.js';
import EditRequisition from '../components/requisitions/edit-requisition.js';

export class SuppliesOrderEdit extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
    this.props.actions.setEditRequisition(
      parseInt(this.props.match.params.id, 10)
    );
    this.props.actions.doGetRequisition({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate(prevProps) {
    const { requisition, isRequesting } = this.props;
    if (isRequesting) return;
    if (prevProps.isRequesting && !isRequesting) {
      this.props.actions.setEditRequisition(
        parseInt(this.props.match.params.id, 10)
      );
    }

    const isEditable = requisitionHelpers.isEditable(
      requisition,
      this.props.auth.actor,
      this.props.auth.actor.type
    );

    if (!isEditable) {
      browserHistory.push(`/supplies/orders/${this.props.match.params.id}`);
    }
  }

  componentWillUnmount() {
    this.props.actions.closeRequisition();
    this.props.actions.unsetEditRequisition(this.props.requisition.id);
  }

  shouldShowLoader() {
    const { isRequesting, requisition } = this.props;
    if (isRequesting) {
      return true;
    }
    if (requisition.id) {
      return false;
    }
  }

  authorized = () => {
    if (!this.props.requisition.requester_type) return true;
    if (this.props.auth.role.can_manage_all_requisitions) return true;
    if (this.props.requisition.is_public) return true;
    if (
      this.props.requisition.requester_type === this.props.auth.actor.type &&
      this.props.requisition.requester_id === this.props.auth.actor.id
    )
      return true;
    if (
      this.props.requisition.proposer_type === this.props.auth.actor.type &&
      this.props.requisition.proposer_id === this.props.auth.actor.id
    )
      return true;
    return false;
  };

  render() {
    const loading = this.shouldShowLoader();
    return (
      <div className="supplies-order-route">
        <MountAtPageTop />
        <AuthCheck customAuthorized={this.authorized()} />
        <NavBar />
        {loading && <Loader />}
        {!loading && (
          <EditRequisition
            requisition={this.props.requisition}
            isRequesting={this.props.isRequesting}
          />
        )}
      </div>
    );
  }
}

SuppliesOrderEdit = setTitle((props) => {
  return 'Edit Order #' + props.match.params.id;
})(SuppliesOrderEdit);

SuppliesOrderEdit.propTypes = {
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    requster_type: PropTypes.string,
    location_id: PropTypes.number,
    _optimized_product_requisitions: PropTypes.shape({
      unmet_minimums: PropTypes.array.isRequired,
    }),
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    openRequisition: PropTypes.func.isRequired,
    doGetRequisition: PropTypes.func.isRequired,
    closeRequisition: PropTypes.func.isRequired,
    setEditRequisition: PropTypes.func.isRequired,
    unsetEditRequisition: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    role: PropTypes.shape({
      can_manage_all_requisitions: PropTypes.bool,
    }).isRequired,
    actor: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  const requisition = state.requisitions.editing[0] || state.requisitions.open;
  const isRequesting =
    !requisition ||
    !requisition.requester_type ||
    state.requisitions.requesting.filter((r) => r.id === requisition.id)
      .length > 0;
  return {
    requisition,
    isRequesting,
    auth: state.auth,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openRequisition,
        doGetRequisition,
        closeRequisition,
        setEditRequisition,
        unsetEditRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.requisitions.open === next.requisitions.open &&
    prev.requisitions.editing === next.requisitions.editing &&
    prev.requisitions.requesting === next.requisitions.requesting &&
    prev.auth === next.auth
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SuppliesOrderEdit);
