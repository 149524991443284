import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { doSignIn } from '../actions/auth-actions';
import NavbarSignIn from './nav/navbar-sign-in.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './sign-in.css';

export class SignIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      password: '',
      email: '',
      hasError: false,
    };
  }

  componentDidMount() {
    this.intervalIE = window.setInterval(this.stupidIEInputCheck, 1000);
    if (this.props.auth.OTPToken) {
      this.props.actions.doSignIn({
        one_time_password_token: this.props.auth.OTPToken,
      });
    }
  }

  componentWillUnmount() {
    this.intervalIE && window.clearInterval(this.intervalIE);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notification && nextProps.notification.message) {
      this.setState(Object.assign({}, this.state, { hasError: true }));
    }
  }

  stupidIEInputCheck = () => {
    if (this.state.email) return;
    if (this.state.password) return;
    this.setState(
      Object.assign({}, this.state, {
        password: this.refs.password.value,
        email: this.refs.email.value,
      })
    );
  };

  onChangePassword(e) {
    let password = e.target.value;
    this.setState(Object.assign({}, this.state, { password }));
  }

  onChangeEmail(e) {
    let email = e.target.value;
    this.setState(Object.assign({}, this.state, { email }));
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.props.actions.doSignIn({
      email: this.state.email,
      password: this.state.password,
    });
  }

  isDisabled() {
    return this.props.auth.isRequesting;
  }

  render() {
    return (
      <div className="sign-in">
        <NavbarSignIn />
        <div className="well-container hero-image">
          <div
            style={{
              maxWidth: '22rem',
              margin: '0 auto',
            }}>
            <Header attached="top" as="h2">
              Sign In
            </Header>
            <Segment attached="bottom" loading={this.props.auth.isRequesting}>
              <Form
                error={this.state.hasError}
                onSubmit={this.handleFormSubmit}>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    ref="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password</label>
                  <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    ref="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />
                </Form.Field>
                <Button
                  primary
                  type="submit"
                  disabled={this.isDisabled()}
                  style={{ minWidth: '50%' }}>
                  Sign In
                </Button>
                <Message
                  error
                  header="Failed to Sign In"
                  content="The email or password you entered is incorrect."
                />
              </Form>
              <div className="sign-in-forgot-password">
                <Link to="/password/forgot" className="tertiary-link">
                  Forgot Password
                </Link>
              </div>
              <div className="sign-in-forgot-password">
                New Customer?&nbsp;
                <a href="https://signup.officeluvinc.com">Create an account.</a>
              </div>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  notification: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    notification: state.auth.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ doSignIn }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
