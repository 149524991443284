import React from 'react';
import PropTypes from 'prop-types';
import TextWithAnchors from '../text/text-with-anchors.js';
import ItemBillingInfo from './item-billing-info.js';
import './item-delivery-info.css';

export default class RequisitionItemDelivery extends React.PureComponent {
  render() {
    return (
      <div className="requisition-delivery-info">
        <div className="requisition-shipping">
          <h4 className="requisition-shipping-title">Shipped To</h4>
          <p className="shipping-name">{this.props.item.shipping_name}</p>
          <p className="shipping-business">
            {this.props.item.shipping_business}
          </p>
          <p className="shipping-care">{this.props.item.shipping_care}</p>
          <p className="shipping-address">
            {this.props.item.shipping_address || '__'}
          </p>
          <p className="shipping-address-number">
            {this.props.item.shipping_address_number}
          </p>
          <p className="shipping-locality">
            {this.props.item.shipping_city || '__'},{' '}
            {this.props.item.shipping_state || '__'}{' '}
            {this.props.item.shipping_country || '__'}{' '}
            {this.props.item.shipping_zip || '__'}
          </p>
        </div>
        {this.props.item.instructions && (
          <div className="requisition-shipping">
            <h4 className="requisition-shipping-title">Instructions</h4>
            <p className="shipping-instructions">
              <TextWithAnchors text={this.props.item.instructions} />
            </p>
          </div>
        )}
        <ItemBillingInfo item={this.props.item} />
      </div>
    );
  }
}

RequisitionItemDelivery.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shipping_name: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_address: PropTypes.string.isRequired,
    shipping_address_number: PropTypes.string,
    shipping_zip: PropTypes.string.isRequired,
    shipping_city: PropTypes.string.isRequired,
    shipping_state: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    purchaser: PropTypes.object,
  }).isRequired,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
