import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../lib/history.js';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import {
  showProductModal,
  doGetLocationCachedProduct,
} from '../../actions/product-actions.js';
import { doSwitchLocation } from '../../actions/locations-actions.js';
import { formatDateYear, formatCentAmount } from '../../lib/formatters.js';
import { doOpenInvoice } from '../../actions/invoices-actions.js';
import './budget-code-products-row.css';

const IMAGE_WIDTH = 25;

export class BudgetCodeProductsRow extends React.PureComponent {
  componentDidMount() {
    if (
      !this.props.product &&
      typeof this.props.productData.product_id === 'number'
    ) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.location.id,
        product: this.props.productData.product_id,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productData === this.props.productData) return;

    if (
      !this.props.product &&
      typeof this.props.productData.product_id === 'number'
    ) {
      this.props.actions.doGetLocationCachedProduct({
        location: this.props.location.id,
        product: this.props.productData.product_id,
      });
    }
  }

  handleClickName = (e) => {
    e.preventDefault();
    this.props.actions.showProductModal(this.props.product);
  };

  onClickSubmittedDate = (e) => {
    e.preventDefault();
    if (this.props.location.id === this.props.productData.location_id) {
      return browserHistory.push(new URL(e.target.href).pathname);
    }
    this.props.actions.doSwitchLocation({
      id: this.props.productData.location_id,
    });
    browserHistory.push(new URL(e.target.href).pathname);
  };

  renderLink = (link, submittedDate) => {
    return (
      <Link
        to={link}
        className="tertiary-link"
        style={{ textAlign: 'right', padding: 0 }}
        onClick={this.onClickSubmittedDate}
      >
        {submittedDate}
      </Link>
    );
  };

  onShowModal = (e, extractId) => {
    e.preventDefault();
    if (this.props.location.id !== this.props.productData.location_id) {
      this.props.actions.doSwitchLocation({
        id: this.props.productData.location_id,
      });
    }
    this.props.actions.doOpenInvoice({ id: extractId });
    browserHistory.push(new URL(e.target.href).pathname);
  };

  renderInvoiceModalLink = (extractId, submittedDate) => {
    return (
      <Link
        to={'/company/invoices'}
        className="tertiary-link"
        onClick={(e) => this.onShowModal(e, extractId)}
      >
        {submittedDate}
      </Link>
    );
  };

  renderSubmittedDate() {
    const submittedDate = formatDateYear(this.props.productData.submitted_date);
    let extractId;
    let customerPurchaseId = this.props.productData.customer_purchase_id;

    if (customerPurchaseId.match(/requisition_/)) {
      extractId = customerPurchaseId.replace('requisition_', '').trim();
      return this.renderLink(`/supplies/orders/${extractId}`, submittedDate);
    } else if (customerPurchaseId.match(/external_vendor_invoice_/)) {
      extractId = customerPurchaseId
        .replace('external_vendor_invoice_', '')
        .trim();
      return this.renderLink(
        `/insights/vendor-invoices/${extractId}`,
        submittedDate
      );
    } else if (customerPurchaseId.match(/invoice_/)) {
      extractId = parseInt(
        customerPurchaseId.replace('invoice_', '').trim(),
        10
      );
      return this.renderInvoiceModalLink(extractId, submittedDate);
    }

    return;
  }

  render() {
    return (
      <div className="product-row flex-wrapper">
        <div className="product-details name">
          {!this.props.product && (
            <div className="tertiary-link unlinked">
              <div className="product-item-image">
                <Icon
                  name="file alternate outline"
                  size="large"
                  style={{ color: 'lightslategrey' }}
                />
              </div>
              <span className="name-text formatted">
                {this.props.productData.product_name}
              </span>
            </div>
          )}
          {this.props.product && (
            <a
              href={`#${this.props.product.id}`}
              onClick={this.handleClickName}
              className="tertiary-link"
            >
              <ItemImage
                height={IMAGE_WIDTH}
                width={IMAGE_WIDTH}
                alt={this.props.product.name}
                title={this.props.product.name}
                size={'thumb'}
                product={this.props.product}
              />
              <span>{this.props.product.name}</span>
            </a>
          )}
        </div>

        <div className="product-details vendor_name">
          {this.props.productData.vendor_name}
        </div>

        <div className="product-details count">
          {this.props.productData.item_quantity.toLocaleString()}
        </div>
        <div className="product-details price">
          {formatCentAmount(this.props.productData.total_spend)}
        </div>
        <div className="product-details submitted-date">
          {this.renderSubmittedDate()}
        </div>
      </div>
    );
  }
}

BudgetCodeProductsRow.propTypes = {
  productData: PropTypes.shape({
    product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    item_quantity: PropTypes.number.isRequired,
    total_spend: PropTypes.number.isRequired,
    product_name: PropTypes.string,
    submitted_date: PropTypes.string,
    customer_purchase_id: PropTypes.string.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    showProductModal: PropTypes.func.isRequired,
    doSwitchLocation: PropTypes.func.isRequired,
    doGetLocationCachedProduct: PropTypes.func.isRequired,
    doOpenInvoice: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    product: state.products.items[props.productData.product_id],
    location: state.locations.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        doGetLocationCachedProduct,
        doSwitchLocation,
        doOpenInvoice,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.products.items === next.products.items &&
    prev.locations.open.id === next.locations.open.id
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BudgetCodeProductsRow);
