import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import browserHistory from '../../lib/history.js';
import ProductListRow from '../products/item-list-row.js';
import ProductListRowLoading from '../products/item-list-row-loading.js';
import {
  doGetPopularCategoryProducts,
  doGetCategoryProducts,
} from '../../actions/product-category-actions.js';
import { SEE_ALL_CATEGORY_THRESHOLD } from '../../strings.js';
import {
  filterInLimitedCatalog,
  mergePopularProducts,
} from '../../helpers/product-helpers.js';
import { mapLocationParentCategoriesIds } from '../../helpers/product-category-helpers.js';
import './officeluv-catalog.css';

export class OfficeluvFullCatalog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getProducts(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isRequesting) return;

    this.getProducts(nextProps);
  }

  getProducts(props) {
    props.categories.map((category) => {
      if (!category.popular_products) {
        props.actions.doGetPopularCategoryProducts(category, '', false);
      } else if (category.popular_products.length < 7 && !category.products) {
        props.actions.doGetCategoryProducts(category, '', false);
      }

      return category;
    });
  }

  _handleClickViewAll = (category) => {
    browserHistory.push('/supplies/catalog/' + category.id);
  };

  renderItem = (item) => {
    let products = mergePopularProducts(item.popular_products, item.products);

    if (this.props.inLimitedViewingMode) {
      products = filterInLimitedCatalog(products, this.props.inCatalogCache);
    }

    if (!this.props.isRequesting && products && products.length) {
      return (
        <div key={item.id} className="category-catalog-all-row">
          <div className="category-catalog-row-header">
            <h4>Top {item.name}</h4>
            {!!products && products.length > SEE_ALL_CATEGORY_THRESHOLD && (
              <Link to={'/supplies/catalog/' + item.id}>See All</Link>
            )}
          </div>
          {(this.props.isRequesting || !products) && <ProductListRowLoading />}
          {!this.props.isRequesting && products && !!products.length && (
            <ProductListRow
              items={products}
              onClickViewAll={this._handleClickViewAll.bind(this, item)}
              maxDisplay={SEE_ALL_CATEGORY_THRESHOLD}
            />
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="catalog-all">
        {this.props.categories.map(this.renderItem)}
      </div>
    );
  }
}

OfficeluvFullCatalog.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      popular_products: PropTypes.array,
      location_popular_products: PropTypes.array,
    })
  ).isRequired,
  actions: PropTypes.shape({
    doGetPopularCategoryProducts: PropTypes.func.isRequired,
  }).isRequired,
  inLimitedViewingMode: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  inCatalogCache: PropTypes.array.isRequired,
};

function filterParents(c) {
  return !c.parent_id;
}

function filterEnabledCategories(locationParentCategories, category) {
  return locationParentCategories.includes(category.id);
}

function sortByOrder(a, b) {
  if (!a.order) return 1;
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
}

export function mapStateToProps(state) {
  const locationParentCategories = mapLocationParentCategoriesIds(
    state.locationParentProductCategories.items
  );

  return {
    categories: state.productCategories.items
      .filter((item) => filterEnabledCategories(locationParentCategories, item))
      .filter(filterParents)
      .sort(sortByOrder),
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    isRequesting: !!state.productCategories.requesting.length,
    inCatalogCache: state.products.in_catalog,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetPopularCategoryProducts,
        doGetCategoryProducts,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.items === next.productCategories.items &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.productCategories.requesting === next.productCategories.requesting &&
    prev.products.in_catalog === next.products.in_catalog
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeluvFullCatalog);
