import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  trackInsightsDownloadCSV,
  trackInsightsDownloadExcel,
} from '../../lib/analytics.js';
import './report-downloads.css';

export class ReportDownloads extends React.PureComponent {
  cleanForCSV(val) {
    const clean = (val || '').toString().replace(/"|'/g, "'");
    return `"${clean}"`;
  }

  getCSVBlob = () => {
    const data = this.props.products_by_date
      .map((pbd) => pbd.data)
      .reduce((acc, val) => {
        return acc.concat(val);
      }, [])
      .sort((a, b) => (a.submitted_date > b.submitted_date ? 1 : -1))
      .map((d) => {
        d.submitted_date = d.submitted_date.toString().slice(0, 10);
        return d;
      })
      .reduce((acc, val, idx, arr) => {
        if (!val.customer_purchase_id) {
          let next = arr[idx + 1];
          if (next && next.submitted_date === val.submitted_date) {
            return acc;
          }
        }
        return acc.concat(val);
      }, []);
    const columns = [
      'Date',
      '"Office"',
      'Order',
      '"Uploaded Invoice"',
      '"Item Name"',
      '"Item Quantity"',
      '"Item Price (USD)"',
      '"Total Spend (USD)"',
      '"Category"',
      '"Purchaser"',
    ];
    const contents = [columns]
      .concat(
        data.map((d) => {
          return [
            d.submitted_date,
            this.cleanForCSV(
              (
                this.props.locations.filter(
                  (l) => l.id === d.location_id
                )[0] || { name: '' }
              ).name
            ),
            (d.customer_purchase_id.match(/requisition_(\d+)/) || [
              null,
              '',
            ])[1],
            (d.customer_purchase_id.match(/external_vendor_invoice_(\d+)/) || [
              null,
              '',
            ])[1],
            this.cleanForCSV(d.product_name),
            d.item_quantity,
            ((d.item_price || 0) / 100).toFixed(2),
            (d.total_spend / 100).toFixed(2),
            this.cleanForCSV(d.budget_code_name),
            this.cleanForCSV(d.purchaser_name),
          ];
        })
      )
      .map((d) => d.join(','))
      .join('\n');
    return new Blob([contents], { type: 'text/csv' });
  };

  getCSVURL = () => {
    if (this.props.isRequesting)
      return 'javascript:alert("Data is still loading.");'; // eslint-disable-line
    if (window.Blob) {
      return URL.createObjectURL(this.getCSVBlob());
    }
    return 'javascript:alert("Your browser does not support CSV creation.");'; // eslint-disable-line
  };

  getFileName = () => {
    if (!this.props.startDate || !this.props.endDate) {
      return 'officeluv-spend.csv';
    }
    const dated = `${this.props.startDate.format(
      'YYYYY-MM-DD'
    )}-${this.props.endDate.format('YYYY-MM-DD')}`;
    return `officeluv-spend-${dated}.csv`;
  };

  handleClickCSV = () => {
    if (window.navigator.msSaveBlob)
      window.navigator.msSaveBlob(this.getCSVBlob(), this.getFileName());
    trackInsightsDownloadCSV();
  };

  handleClickExcel = () => {
    if (window.navigator.msSaveBlob)
      window.navigator.msSaveBlob(this.getCSVBlob(), this.getFileName());
    trackInsightsDownloadExcel();
  };

  render() {
    return (
      <div className="insights-downloads">
        <h4 className="insights-downloads-title">Download Spend Report</h4>
        <div className="insights-downloads-links">
          <a
            href={this.getCSVURL()}
            onClick={this.handleClickExcel}
            className="insights-download-csv brand-bg-color-override"
            download={this.getFileName()}>
            EXCEL
          </a>
          <a
            href={this.getCSVURL()}
            onClick={this.handleClickCSV}
            className="insights-download-csv brand-bg-color-override"
            download={this.getFileName()}>
            CSV
          </a>
        </div>
      </div>
    );
  }
}

ReportDownloads.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  products_by_date: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          submitted_date: PropTypes.string,
          item_quantity: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_line_item_budget_code_dates';
function mapStateToProps(state, props) {
  const locations = props.locations.map((l) => l.id);
  return {
    products_by_date: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === props.startDate.toJSON() &&
        d.end === props.endDate.toJSON() &&
        locations.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === props.startDate.toJSON() &&
          d.end === props.endDate.toJSON() &&
          locations.indexOf(d.topic.id) > -1
      ).length > 0,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ReportDownloads);
