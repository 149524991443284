import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import TaskForm from '../cleanings/task-form.js';
import { closeCreateTaskModal } from '../../actions/task-actions.js';
import './modal-common.css';
import './modal-cleaning-task.css';

export class ModalCleaningTask extends React.PureComponent {
  onRequestClose = () => {
    this.props.actions.closeCreateTaskModal();
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  render() {
    if (!this.props.task.id || !this.props.task.cleaning_id) {
      return null;
    }
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!this.props.task.id}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal modal-cleaning-task',
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Task Modal"
      >
        <div className="modal-cleaning-task-header">
          <div className="cleaning-task-form-header-container">
            {this.props.task.id && this.props.task.id === 'new' && (
              <h3 className="cleaning-task-form-header">Add To-Do</h3>
            )}
            {this.props.task.id && this.props.task.id !== 'new' && (
              <h3 className="cleaning-task-form-header">Update To-Do</h3>
            )}
          </div>
          <button
            className="close-icon"
            type="button"
            onClick={this.handleCloseModal}
          >
            &times;
          </button>
        </div>
        <TaskForm item={this.props.task} />
      </Modal>
    );
  }
}

ModalCleaningTask.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cleaning_id: PropTypes.number,
    title: PropTypes.string,
    note: PropTypes.string,
    area: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    task: state.tasks.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeCreateTaskModal,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCleaningTask);
