// @flow
import React from 'react';
import PropTypes from 'prop-types';
import log from '../lib/log.js';
const logger = log('[visible]');

export default class Visibility extends React.PureComponent {
  static propTypes = {
    invisibilityThreshold: PropTypes.number,
    onChangeVisible: PropTypes.func,
  };

  timer = '';

  componentDidMount() {
    document.addEventListener('visibilitychange', this.visibilityChanged);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('visibilitychange', this.visibilityChanged);
  }

  visibilityChanged = () => {
    let visibility = !document.hidden;
    let threshold = this.props.invisibilityThreshold || 0;

    if (!visibility) {
      this.timer = setTimeout(this.handleInvisible, threshold);
      logger.log('hidden');
    } else {
      this.handleVisible();
      logger.log('visible');
    }
  };

  handleInvisible = () => {
    this.props.onChangeVisible(false);
    logger.log('hidden threshold triggered');
  };

  handleVisible = () => {
    this.props.onChangeVisible(true);
    clearTimeout(this.timer);
  };

  render() {
    return null;
  }
}
