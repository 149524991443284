import React from 'react';
import PropTypes from 'prop-types';
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory';

export default class ProgressPie extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      data: this.getData(0),
    };
  }

  componentDidMount() {
    this.setStateTimeout = window.setTimeout(this.progressToState, 1500);
  }

  UNSAFE_componentWillReceiveProps() {
    window.clearTimeout(this.setStateTimeout);
    this.setStateTimeout = window.setTimeout(this.progressToState, 1500);
  }

  componentWillUnmount() {
    window.clearTimeout(this.setStateTimeout);
  }

  progressToState = () => {
    this.setState(() => {
      return {
        percent: this.props.percent,
        data: this.getData(this.props.percent),
      };
    });
  };

  getData(percent) {
    return [
      { x: 1, y: percent },
      { x: 2, y: 100 - percent },
    ];
  }

  render() {
    return (
      <div className="progress-pie">
        <svg viewBox="0 0 300 300" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={300}
            height={300}
            data={this.state.data}
            innerRadius={140}
            cornerRadius={25}
            labels={() => null}
            style={{
              data: {
                fill: (d) => {
                  if (d.datum.x === 2) return 'transparent';
                  if (this.props.fill) return this.props.fill;
                  if (d.datum.y > 100) return '#354722';
                  if (d.datum.y > 80) return '#466631';
                  if (d.datum.y > 60) return '#649146';
                  if (d.datum.y > 40) return '#8AB25C';
                  return '#A9C97E';
                },
              },
            }}
          />
          <VictoryAnimation duration={1000} data={this.state}>
            {(newState) => {
              return (
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={150}
                  y={150}
                  text={`${Math.round(newState.percent).toLocaleString()}%`}
                  style={{
                    fontSize: 45,
                    fontFamily: 'Roboto, Helvetica, sans-serif',
                  }}
                />
              );
            }}
          </VictoryAnimation>
        </svg>
      </div>
    );
  }
}

ProgressPie.propTypes = {
  percent: PropTypes.number.isRequired,
  fill: PropTypes.string,
};

ProgressPie.defaultProps = {
  percent: 50,
};
