import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ProductReq from './cart-product-requisition.js';
import { colorHash } from '../../lib/formatters.js';
import { CartPropType } from '../../helpers/cart-helpers.js';
import CartProductVendorGroup from './cart-product-vendor-group.js';
import CartSwitcher from './cart-switcher.js';
import CartFooter from './cart-footer.js';
import { Button, Icon } from 'semantic-ui-react';
import './cart.css';

export class RequisitionCart extends React.PureComponent {
  reduceItemsQuantity(acc, val) {
    return acc + val.quantity;
  }

  cartQuantity = () => {
    return (
      this.props.cart.product_requisitions ||
      this.props.cart.scheduled_product_requisitions ||
      []
    ).reduce(this.reduceItemsQuantity, 0);
  };

  cartQuantityCopy = () => {
    const count = this.cartQuantity();
    return `${count.toLocaleString()} ${count === 1 ? 'item' : 'items'}`;
  };

  hasUnmetRequirements() {
    const { cart } = this.props;
    if (!cart._optimized_product_requisitions) return false;
    return cart._optimized_product_requisitions.unmet_minimums.length > 0;
  }

  _sortItems(a, b) {
    return a.created_at > b.created_at ? -1 : 1;
  }

  renderItem = (item, i) => {
    return (
      <div className="cart-product" key={item.id || i}>
        <ProductReq
          item={item}
          isEditing={this.props.isEditing}
          readOnly={this.props.readOnly}
          showReplacementOptions={this.props.showReplacementOptions}
          isEditingScheduled={this.props.isEditingScheduled}
        />
      </div>
    );
  };

  renderProductVendorGroups() {
    if (
      typeof this.props.cart.product_requisitions === 'undefined' &&
      typeof this.props.cart.scheduled_product_requisitions === 'undefined'
    ) {
      return null;
    }
    const groups =
      this.props.cart._optimized_product_requisitions.unmet_minimums;
    return groups.map((group, i) => (
      <CartProductVendorGroup
        key={i}
        vendorGroup={group}
        color={
          this.props.vendorColorMap[group.vendor_id] ||
          colorHash.hex(group.vendor_id)
        }
        isEditing={this.props.isEditing}
        isEditingScheduled={this.props.isEditingScheduled}
        readOnly={this.props.readOnly}
        showReplacementOptions={this.props.showReplacementOptions}
      />
    ));
  }

  renderRemainingProducts() {
    const { cart } = this.props;
    let metGroupProductRequisitions =
      cart._optimized_product_requisitions.met_minimum_product_requisitions;
    if (!metGroupProductRequisitions.length) return null;
    return (
      <div>
        <h5 className="no-min-header light-gray-text">No Minimum Required</h5>
        {metGroupProductRequisitions.sort(this._sortItems).map(this.renderItem)}
      </div>
    );
  }

  renderItems() {
    const productReqs =
      this.props.cart.product_requisitions ||
      this.props.cart.scheduled_product_requisitions;
    return (
      <div>
        {productReqs &&
          productReqs
            .sort(this._sortItems)
            .filter((pr) => pr.quantity > 0)
            .map(this.renderItem)}
      </div>
    );
  }

  render() {
    const hasUnmetRequirements = this.hasUnmetRequirements();
    return (
      <div
        className={classnames('requisition-cart', {
          'edit-mode': this.props.isEditing,
          'with-replacement-options': this.props.showReplacementOptions,
        })}>
        <div
          className={classnames('cart-header', {
            'with-icon':
              this.props.isScheduled || this.props.isEditingScheduled,
          })}>
          {!this.props.isScheduled && !this.props.isEditingScheduled && (
            <CartSwitcher enabled={this.props.canSwitchCarts} />
          )}
          <div className="cart-header-right">
            <h4 className="cart-title">{this.cartQuantityCopy()}</h4>
            {this.props.hideable && (
              <Button
                icon
                style={{ padding: 0, background: 'transparent' }}
                title="Hide cart"
                onClick={this.props.hideCart}>
                <Icon name="close" />
              </Button>
            )}
          </div>
        </div>
        <div
          className={classnames('cart-items', {
            expanded: this.props.expanded,
          })}>
          {hasUnmetRequirements && this.renderProductVendorGroups()}
          {hasUnmetRequirements && this.renderRemainingProducts()}
          {!hasUnmetRequirements && this.renderItems()}
        </div>
        <CartFooter
          showTax={this.props.showTax}
          cart={this.props.cart}
          hideCheckout={
            this.props.hideCheckout ||
            this.props.isEditing ||
            this.props.isScheduled ||
            this.props.isEditingScheduled
          }
          estimatingTax={this.props.estimatingTax}
          estimatingTaxText={this.props.estimatingTaxText}
        />
        {this.props.hideable && !this.props.cartIsOpen && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '1rem',
            }}>
            <Button
              className="btn tertiary"
              onClick={this.props.showCart}
              style={{ fontSize: '.8rem', color: '#999da9' }}>
              Keep&nbsp;Open
            </Button>
          </div>
        )}
      </div>
    );
  }
}

RequisitionCart.propTypes = {
  canSwitchCarts: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  hideCheckout: PropTypes.bool,
  estimatingTax: PropTypes.bool,
  estimatingTaxText: PropTypes.string.isRequired,
  cart: CartPropType.isRequired,
  readOnly: PropTypes.bool.isRequired,
  vendorColorMap: PropTypes.object.isRequired,
  showReplacementOptions: PropTypes.bool.isRequired,
  hideCart: PropTypes.func.isRequired,
  showCart: PropTypes.func.isRequired,
  cartIsOpen: PropTypes.bool.isRequired,
};

RequisitionCart.defaultProps = {
  canSwitchCarts: false,
  isEditing: false,
  isScheduled: false,
  expanded: false,
  estimatingTaxText: 'estimating...',
  readOnly: false,
  showReplacementOptions: false,
  hideable: false,
  hideCart: () => {},
  showCart: () => {},
};

export function mapStateToProps(state, ownProps) {
  let cart = state.carts.open;
  if (ownProps.isEditing) {
    cart = state.requisitions.editing[0] || cart;
  }
  if (ownProps.isScheduled) {
    cart = state.scheduledRequisitions.open;
  }
  if (ownProps.isEditingScheduled) {
    cart = state.scheduledRequisitions.staged[0];
  }
  return {
    cart,
    vendorColorMap: state.locations.open.vendorColorMap,
    cartIsOpen: state.application.cartIsOpen,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.requisitions.editing === next.requisitions.editing &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.locations.open.vendorColorMap === next.locations.open.vendorColorMap &&
    prev.scheduledRequisitions.staged === next.scheduledRequisitions.staged &&
    prev.application.cartIsOpen === next.application.cartIsOpen
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  RequisitionCart
);
