import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message, Input } from 'semantic-ui-react';
import {
  FILTER_IS_ERROR,
  FILTER_IS_REQUESTING,
  FILTER_IS_SUCCESS,
  doApplyPromoCode,
  ERROR_APPLY_PROMO_CODE,
  unsetPromoCodeResponse,
  clearPromoCodeResponses,
} from '../../ducks/promo-codes.js';
import 'semantic-ui-css/components/form.min.css';
import './checkout-form.css';

export class PromoCodeField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.success && this.props.success) {
      this.setState({ code: null });
      this.timeout = setTimeout(() => {
        this.props.actions.unsetPromoCodeResponse({
          _request: this.props.success._request,
          type: ERROR_APPLY_PROMO_CODE,
        });
      }, 3000);
    } else if (prevState.code !== this.state.code && this.props.error) {
      this.props.actions.unsetPromoCodeResponse({
        _request: this.props.error._request,
        type: ERROR_APPLY_PROMO_CODE,
      });
    }
  };

  componentWillUnmount() {
    this.props.actions.clearPromoCodeResponses();
    this.timeout && clearTimeout(this.timeout);
  }

  formatError = (message) => {
    if (message.match(/already applied/)) {
      return `${this.state.code} Promo Code already applied.`;
    }
    return message
      .replace('PromoCode', 'Promo Code')
      .replace('Validation failed: ', '');
  };

  handleChange = (e, { value }) => {
    this.setState({ code: value });
  };

  handleSubmit = () => {
    this.props.actions.doApplyPromoCode({
      code: this.state.code.trim(),
      cart_id: this.props.cart.id,
      location_id: this.props.location.id,
      _request: this.state.code,
    });
  };

  render() {
    return (
      <div className="checkout-form-promo-code">
        <Form.Field>
          <label>Promo Code</label>
          <Input
            fluid
            action={{
              content: 'Apply',
              loading: this.props.isRequesting,
              disabled:
                this.props.isRequesting || (this.state.code || '').length < 1,
              onClick: this.handleSubmit,
              secondary: true,
            }}
            disabled={this.props.isRequesting}
            placeholder="Enter Promo Code Here"
            value={this.state.code || ''}
            onChange={this.handleChange}
          />
        </Form.Field>
        {this.props.error && !this.props.success && (
          <Message error content={this.formatError(this.props.error.error)} />
        )}
        {this.props.success && (
          <Message
            success
            content={`${this.props.success.item.description} applied`}
          />
        )}
      </div>
    );
  }
}

PromoCodeField.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  error: PropTypes.shape({
    error: PropTypes.string,
  }),
  isRequesting: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  success: PropTypes.shape({
    item: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  }),
};

export function mapStateToProps(state) {
  const errors = state.promoCodes.responses.filter(FILTER_IS_ERROR);
  const successes = state.promoCodes.responses.filter(FILTER_IS_SUCCESS);
  return {
    cart: state.carts.open,
    location: state.locations.open,
    error: errors.length ? errors[errors.length - 1].data : null,
    success: successes.length ? successes[successes.length - 1].data : null,
    isRequesting:
      state.promoCodes.requesting.filter(FILTER_IS_REQUESTING).length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doApplyPromoCode,
        unsetPromoCodeResponse,
        clearPromoCodeResponses,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.promoCodes.requesting === next.promoCodes.requesting &&
    prev.promoCodes.responses === next.promoCodes.responses &&
    prev.carts.open === next.carts.open &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(PromoCodeField);
