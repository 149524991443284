import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Pagination, Segment } from 'semantic-ui-react';
import classnames from 'classnames';
import Item from './item.js';
import SuggestedSearches from './suggested-searches.js';
import SearchResultsRequestItem from './search-results-request-item.js';
import {
  filterInLimitedCatalog,
  filterProductsByParentCategories,
} from '../../helpers/product-helpers.js';
import { mapLocationParentCategoriesIds } from '../../helpers/product-category-helpers.js';
import { trackInterfacePaginateProductSearchResults } from '../../lib/analytics.js';

const PAGINATION_CONFIG = {
  limit: 25,
};

export class SearchResults extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.paginate) return;

    if (prevProps.query !== this.props.query) {
      console.log(prevProps.query);
      console.log(this.props.query);
      this.setState({ activePage: 1 });
    }
  }

  paginatedResults() {
    const upper = this.state.activePage * PAGINATION_CONFIG.limit - 1;
    const lower = upper - (PAGINATION_CONFIG.limit - 1);
    return this.props.results.slice(lower, upper);
  }

  handlePageChange = (event, data) => {
    this.setState({ activePage: data.activePage });
    window.scrollTo(0, 0);
    trackInterfacePaginateProductSearchResults(data);
  };

  renderItem = (item) => {
    return (
      <Item key={item.id + Math.random()} item={item} canFavorite={true} />
    );
  };

  render() {
    return (
      <div
        className={classnames('officeluv-category-catalog', {
          'matched-results': this.props.results.length > 0,
        })}>
        <Segment
          basic
          loading={this.props.isRequesting}
          placeholder={false}
          style={{ padding: 0 }}>
          <div>
            <div className="flex-wrapper">
              {this.props.paginate &&
                this.paginatedResults().map(this.renderItem)}
              {!this.props.paginate && this.props.results.map(this.renderItem)}
            </div>
          </div>
        </Segment>
        {this.props.paginate &&
          !this.props.isRequesting &&
          this.props.results.length > PAGINATION_CONFIG.limit && (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
              <Pagination
                defaultActivePage={1}
                onPageChange={this.handlePageChange}
                totalPages={Math.ceil(
                  this.props.results.length / PAGINATION_CONFIG.limit
                )}
              />
            </div>
          )}
        {this.props.displaySuggestions &&
          this.props.results.length <= PAGINATION_CONFIG.limit &&
          !this.props.isRequesting && <SuggestedSearches />}
        {this.props.displayRequestItem && !this.props.isRequesting && (
          <SearchResultsRequestItem />
        )}
      </div>
    );
  }
}

SearchResults.propTypes = {
  onItemsFound: PropTypes.func,
  secondaryFilter: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  displayRequestItem: PropTypes.bool.isRequired,
  displaySuggestions: PropTypes.bool.isRequired,
  paginate: PropTypes.bool.isRequired,
};

SearchResults.defaultProps = {
  displaySuggestions: true,
  displayRequestItem: true,
  paginate: false,
};

function mapStateToProps(state) {
  const inLimitedViewingMode = state.application.inLimitedViewingMode;
  const restrictedCatalogFilter = state.productQuery.filters.restrictedCatalog;

  const showLimitedCatalog = inLimitedViewingMode || restrictedCatalogFilter;
  const in_catalog = state.products.in_catalog;

  const query = state.productQuery;

  const locationParentCategories = mapLocationParentCategoriesIds(
    state.locationParentProductCategories.items
  );

  const results = filterProductsByParentCategories(
    state.productQuery.results,
    locationParentCategories
  );

  return {
    query: query,
    results: showLimitedCatalog
      ? filterInLimitedCatalog(results, in_catalog)
      : results,
    isRequesting:
      state.productQuery.isRequesting || state.locations.requesting.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productQuery.results === next.productQuery.results &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items &&
    prev.productQuery.isRequesting === next.productQuery.isRequesting &&
    prev.locations.requesting === next.locations.requesting &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.productQuery.filters.restrictedCatalog ===
      next.productQuery.filters.restrictedCatalog &&
    prev.products.in_catalog === next.products.in_catalog
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SearchResults);
