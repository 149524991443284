import Cookies from 'js-cookie';

export default Cookies.withConverter({
  read: function (value, name) {
    return decodeURIComponent(window.atob(value));
  },
  write: function (value, name) {
    return window.btoa(encodeURIComponent(value));
  },
});
