import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CleaningStaffsList from '../staffs/cleaning-staffs-list.js';
import TaskItemsList from './task-items-list.js';
import Review from './reviews/item.js';
import ReviewForm from './reviews/form.js';
import CommentsList from './comments-list.js';
import { formatDate } from '../../lib/formatters.js';
import './report.css';

export class CleaningReport extends React.PureComponent {
  renderReview = (review) => {
    return <Review key={review.id} item={review} />;
  };

  renderReviewForm = (review) => {
    return (
      <ReviewForm
        key={review.id}
        review={review}
        cleaning={this.props.cleaning}
      />
    );
  };

  render() {
    return (
      <div className="cleaning-report">
        <div className="cleaning-report-reviews">
          {this.props.cleaning.reviews.length > 0 &&
            this.props.cleaning.reviews[0].comment &&
            this.props.cleaning.reviews.map(this.renderReview)}
          {this.props.cleaning.reviews.length > 0 &&
            !this.props.cleaning.reviews[0].comment &&
            this.props.cleaning.reviews.map(this.renderReviewForm)}
          {this.props.cleaning.reviews.length === 0 && (
            <ReviewForm cleaning={this.props.cleaning} />
          )}
        </div>
        <div className="cleaning-header-container">
          <h2>
            {formatDate(this.props.cleaning.start_datetime)}
            &rsquo;s Shift
          </h2>
          <div className="cleaning-details-cleaning-staff flex-wrapper">
            <CleaningStaffsList
              cleaning={this.props.cleaning}
              showStatus={false}
            />
          </div>
        </div>
        <div className="cleaning-details-container flex-wrapper">
          <div className="shift-notes flex-item">
            <CommentsList
              comments={this.props.cleaning.comments}
              isCompletedCleaning={true}
            />
          </div>
          <div className="cleaning-details-tasks-container flex-item ">
            <TaskItemsList
              cleaning={this.props.cleaning}
              isCompletedCleaning={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

CleaningReport.propTypes = {
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    cleaning: state.cleanings.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CleaningReport);
