import * as types from '../actions/action-types.js';
import moment from 'moment';

const startAtMatch = window.location.search.match(/start_at=(.*?(?=&|$))/i);
const endAtMatch = window.location.search.match(/end_at=(.*?(?=&|$))/i);

export const empty = {
  openRange: {
    startDate: startAtMatch
      ? moment(startAtMatch[1])
      : moment().startOf('month'),
    endDate: endAtMatch ? moment(endAtMatch[1]) : moment().endOf('month'),
  },
  openLocations: [],
  items: [],
  requesting: [],
  errors: [],
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.OPEN_DATA_VIEW_RANGE:
      return Object.assign({}, state, {
        openRange: action.data,
      });
    case types.CLOSE_DATA_VIEW_RANGE:
      return Object.assign({}, state, {
        openRange: empty.openRange,
      });
    case types.OPEN_DATA_VIEW_LOCATIONS:
      return Object.assign({}, state, {
        openLocations: action.data,
      });
    case types.SWITCH_LOCATION:
      return Object.assign({}, state, {
        openLocations: [action.data],
      });
    case types.SUCCESS_UPDATE_LOCATION:
      return Object.assign({}, state, {
        openLocations: state.openLocations.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, action.data);
        }),
      });
    case types.CLOSE_DATA_VIEW_LOCATIONS:
      return Object.assign({}, state, {
        openLocations: empty.openLocations,
      });
    case types.GET_DATA_VIEW:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
        errors: state.errors.filter(
          (req) => req._request !== action.data._request
        ),
      });
    case types.SUCCESS_DATA_VIEW:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== action.data._request
        ),
        items: state.items
          .filter((req) => {
            return !(
              req.view === action.data.view &&
              req.topic.id === action.data.topic.id &&
              req.topic.model === action.data.topic.model &&
              req.start === action.data.start &&
              req.end === action.data.end
            );
          })
          .concat(action.data),
      });
    case types.ERROR_DATA_VIEW:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
