import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import VictoryChart from '../victory/ie-chart.js';
import Loader from '../loader.js';
import {
  formatCentAmount,
  groupTimeSeriesData,
  findBestSeriesInterval,
} from '../../lib/formatters.js';
import ModalEditBudgetCode from '../modals/modal-edit-budget-code.js';
import BudgetBasedOnSpend from './budget-based-on-spend.js';
import './budget-code-spend-line.css';

const DATA_TOPIC_MODEL = 'location';
const FONT_FAMILY = 'Roboto, Helvetica, Verdana, sans-serif';
const MIN_DOLLAR_RANGE = 10000;

export class CustomerPurchaseLineGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    let reducedData = this._buildReducedData(props.dataViews);
    let interval = this._buildInterval(reducedData);
    this.state = {
      data: this._buildData(reducedData, interval),
      topRange: this._calculatetopRange(reducedData),
      totalSpend: this._calculateTotalSpend(reducedData),
      interval,
      controlModal: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let reducedData = this._buildReducedData(nextProps.dataViews);
    let interval = this._buildInterval(reducedData);
    this.setState(
      Object.assign({}, this.state, {
        data: this._buildData(reducedData, interval),
        topRange: this._calculatetopRange(reducedData),
        totalSpend: this._calculateTotalSpend(reducedData),
        interval,
      })
    );
  }

  numberOfEmployees = () => {
    return this.props.locations.reduce((acc, val) => {
      return acc + (val.number_of_employees || 0);
    }, 0);
  };

  _calculateTotalSpend(data) {
    return data.reduce((acc, d) => {
      return acc + d.lineValue;
    }, 0);
  }

  _calculatetopRange(data) {
    return Math.max.apply(
      Math,
      data.map((d) => d.lineValue).concat(MIN_DOLLAR_RANGE)
    );
  }

  _buildData(data, interval) {
    return groupTimeSeriesData({
      data: data,
      dateKey: 'interval',
      sumKeys: ['lineValue'],
      interval: interval,
    });
  }

  _buildReducedData(dataViews) {
    return [].concat.apply(
      [],
      dataViews.map((d) => this._reduceDataViews(d))
    );
  }

  _reduceDataViews(dataView) {
    return dataView.data.reduce(this.props.dataReducer, []);
  }

  _buildInterval(dataViews) {
    if (!dataViews.length) {
      return 'day';
    }

    const lastIndex = dataViews.length - 1;
    return findBestSeriesInterval(
      new Date(dataViews[0].interval),
      new Date(dataViews[lastIndex].interval),
      3
    );
  }

  tickValuesX = () => {
    return this.state.data.map((d) => d.interval);
  };

  _formatXAxis = (x) => {
    const d = new Date(x);
    switch (this.state.interval) {
      case 'year':
        return `${d.getFullYear()}`;
      case 'month':
        return `${d.toUTCString().split(' ')[2]}`;
      case 'week':
      case 'day':
      default:
        return `${d.toUTCString().split(' ')[2]} ${d.getDate()}`;
    }
  };

  _formatYAxis = (y) => {
    if (y < 100000) {
      return `$${y / 100}`;
    }
    return `$${y / 100 / 1000}k`;
  };

  _resizeChart = () => {
    if (this.props.windowWidth > 650) {
      return this.props.windowWidth / 2;
    }
    return this.props.windowWidth;
  };

  _labels = (d) => {
    if (d.datum._y > 0) {
      return `${this._intervalLabel('Spend')}: ${formatCentAmount(d.datum._y)}`;
    } else {
      return `${this._intervalLabel('Spend')}: ${formatCentAmount(0)}`;
    }
  };

  _intervalLabel = (labelType) => {
    switch (this.state.interval) {
      case 'year':
        return `Yearly ${labelType}`;
      case 'month':
        return `Monthly ${labelType}`;
      case 'week':
        return `Weekly ${labelType}`;
      case 'day':
        return `Daily ${labelType}`;
      default:
        return labelType;
    }
  };

  _orientTooltip = (d) => {
    if ((this.state.data.slice(-2)[0] || {}).interval <= d.datum._x) {
      return 'left';
    }
    return 'right';
  };

  _timePad = () => {
    switch (this.state.interval) {
      case 'year':
        return [1, 'month'];
      case 'month':
        return [2, 'week'];
      case 'week':
        return [3, 'day'];
      case 'day':
        return [1, 'day'];
      default:
        return [3, 'day'];
    }
  };

  _getDomain = () => {
    if (this.state.data.length >= 2) {
      let timePad = this._timePad();
      return {
        x: [
          moment(this.state.data[0].interval).subtract(...timePad),
          moment(this.state.data[this.state.data.length - 1].interval).add(
            ...timePad
          ),
        ],
      };
    }
    return 0;
  };

  _color = () => {
    return this.props.brandColor || '#00a98c';
  };

  toggleModal = () => {
    this.setState((prev) => {
      return { controlModal: !prev.controlModal };
    });
  };

  daysInSelectedRange = () => {
    let start = this.props.startDate;
    let end = this.props.endDate;

    return end.diff(start, 'days');
  };

  spendPerEmployee = () => {
    return formatCentAmount(this.state.totalSpend / this.numberOfEmployees());
  };

  budgetBasedOnDateRange = () => {
    return formatCentAmount(
      this.props.budgetCode.budget_annual / this.daysInSelectedRange()
    );
  };

  render() {
    const budgetCode = this.props.budgetCode;

    return (
      <div className="budget-code-spend-line">
        {this.props.isRequesting && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        <h3>
          <Button
            size="mini"
            compact
            style={{ float: 'right' }}
            icon
            secondary
            title="Edit"
            onClick={() => this.toggleModal()}
          >
            <Icon name="pencil" />
          </Button>
          &nbsp;
          {budgetCode.name}:&nbsp;
          {formatCentAmount(this.state.totalSpend)}
          <ModalEditBudgetCode
            control={this.state.controlModal}
            onClose={this.toggleModal}
          />
        </h3>
        <VictoryChart
          width={this._resizeChart()}
          domain={this._getDomain()}
          height={300}
          domainPadding={{ x: 1, y: 20 }}
          padding={{ top: 20, bottom: 30, left: 50, right: 20 }}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              labels={this._labels}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={5}
                  pointerLength={5}
                  orientation={this._orientTooltip}
                  dx={0}
                  horizontal={true}
                  flyoutStyle={{ fill: '#535662', stroke: 0 }}
                  groupComponent={<g className="budget-code-spend-tooltip" />}
                  style={{ fontFamily: FONT_FAMILY }}
                />
              }
            />
          }
        >
          <VictoryAxis
            tickFormat={this._formatXAxis}
            tickValues={this.tickValuesX()}
            style={{
              tickLabels: {
                fill: '#535662',
                fontSize: 12,
                fontFamily: FONT_FAMILY,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={this._formatYAxis}
            domain={{ y: [0, this.state.topRange] }}
            style={{
              tickLabels: {
                fill: '#535662',
                fontSize: 13,
                fontFamily: FONT_FAMILY,
              },
            }}
          />
          <VictoryBar
            barRatio={0.5}
            style={{
              data: {
                fill: this._color(),
                fillOpacity: '0.8',
              },
              labels: { fill: 'white', fontWeight: 'bold' },
            }}
            animate={{
              duration: 300,
              onLoad: { duration: 300 },
            }}
            data={this.state.data}
            x={'interval'}
            y={'lineValue'}
            labels={this._labels}
            labelComponent={
              <VictoryLabel
                renderInPortal
                dy={-5}
                className={'total-spend-line-label'}
              />
            }
          />
        </VictoryChart>
        <BudgetBasedOnSpend
          spendPerEmployee={this.spendPerEmployee()}
          budgetBasedOnDateRange={this.budgetBasedOnDateRange()}
          displayBudgetBasedOnDateRange={budgetCode.budget_annual > 0}
        />
      </div>
    );
  }
}

CustomerPurchaseLineGraph.propTypes = {
  isRequesting: PropTypes.bool,
  windowWidth: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  budgetCode: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
    budget_annual: PropTypes.number,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          line_items_submitted_price: PropTypes.number,
          budget_code_id: PropTypes.number,
          budget_code_name: PropTypes.string,
          budget_code_code: PropTypes.string,
          budget_code_budget_annual: PropTypes.number,
          submitted_date: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  dataViewName: PropTypes.string,
  brandColor: PropTypes.string,
  dataReducer: PropTypes.func,
};

function mapStateToProps(state, props) {
  const locations = props.locations.map((l) => l.id);
  const start = props.startDate.toJSON();
  const end = props.endDate.toJSON();
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === props.dataViewName &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === start &&
        d.end === end &&
        locations.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === props.dataViewName &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === start &&
          d.end === end &&
          locations.indexOf(d.topic.id) > -1
      ).length > 0,
    brandColor: state.locations.open.brand_color,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting &&
    prev.locations.open.brand_color === next.locations.open.brand_color
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CustomerPurchaseLineGraph
);
