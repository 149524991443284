import ReactGA from 'react-ga';

export const CATEGORY_XHR = 'JS XHR Requests';
export const CATEGORY_REQUISITIONS = 'Requisitions';
export const CATEGORY_AUTH = 'Authentication';
export const CATEGORY_NOTIFICATIONS = 'Notifications';
export const CATEGORY_APPLICATION = 'Application';
export const CATEGORY_EMPLOYEES = 'Employees';
export const CATEGORY_LOCATIONS = 'Locations';
export const CATEGORY_SHIPMENTS = 'Shipments';
export const CATEGORY_INTERFACE = 'Interface';
export const CATEGORY_LOCATION_LISTS = 'Location Lists';
export const CATEGORY_CLEANINGS = 'Cleanings';
export const CATEGORY_TASKS = 'Tasks';
export const CATEGORY_REVIEWS = 'Reviews';
export const CATEGORY_RECURRING_REQUISITIONS = 'Recurring Requisitions';
export const CATEGORY_SCHEDULED_REQUISITIONS = 'Scheduled Requisitions';
export const CATEGORY_INSIGHTS = 'Budget Insights';
export const CATEGORY_LOCATION_CATALOG = 'Location Catalog';

export const VARIABLE_LOAD = 'load';

export const ACTION_DOWNLOAD_ORDER_PDF = 'KPI: Downloaded PDF from order page';
export const ACTION_SIGN_IN = 'Sign In';
export const ACTION_SIGN_OUT = 'Sign Out';
export const ACTION_FORGOT_PASSWORD = 'Forgot Password';
export const ACTION_RESET_PASSWORD = 'Update Password';

export const trackFetchError = (error) => {
  if (error.name === 'AbortError') return;

  if (error.status === 400) {
    let contentType = error.response.headers.get('content-type');
    if (contentType.match(/text.plain/)) {
      return error.response.text().then((text) => {
        ReactGA.event({
          category: CATEGORY_XHR,
          action: `Fetch Error: ${error.message}`,
          label: `Fetch Error:${error.status}: ${error.message} (${text})`,
          nonInteraction: true,
        });
      });
    } else if (contentType.match(/json/)) {
      return error.response.json().then((json) => {
        let text = json.message;
        ReactGA.event({
          category: CATEGORY_XHR,
          action: `Fetch Error: ${error.message}`,
          label: `Fetch Error:${error.status}: ${error.message} (${text})`,
          nonInteraction: true,
        });
      });
    }
  }
  ReactGA.event({
    category: CATEGORY_XHR,
    action: `Fetch Error: ${error.message}`,
    label: `Fetch Error:${error.status}: ${error.message}`,
    nonInteraction: true,
  });
};

export const trackOrderPrint = (req) => {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: ACTION_DOWNLOAD_ORDER_PDF,
    label: 'KPI: Downloaded PDF for order ' + req.id,
  });
};

export const trackSignInSuccess = (auth) => {
  ReactGA.event({
    category: CATEGORY_AUTH,
    action: ACTION_SIGN_IN,
    label: 'Sign in success',
  });
};

export const trackSignOutSuccess = (auth) => {
  ReactGA.event({
    category: CATEGORY_AUTH,
    action: ACTION_SIGN_OUT,
    label: 'Sign Out success',
  });
};

export const trackForgotPassword = (auth) => {
  window.totango.track('Forgot password request.', 'Other');
  ReactGA.event({
    category: CATEGORY_AUTH,
    action: ACTION_FORGOT_PASSWORD,
    label: 'Forgot password request',
  });
};

export const trackUpdatePassword = (auth) => {
  window.totango.track('Update password.', 'Other');
  ReactGA.event({
    category: CATEGORY_AUTH,
    action: ACTION_RESET_PASSWORD,
    label: 'Update password',
  });
};

export const trackNotificationSent = (notification) => {
  ReactGA.event({
    category: CATEGORY_NOTIFICATIONS,
    action: 'Sent Browser Notification',
    label: 'Sent Browser Notification: ' + notification.title,
  });
};

export const trackNotificationClosed = (notification) => {
  ReactGA.event({
    category: CATEGORY_NOTIFICATIONS,
    action: 'Closed Browser Notification',
    label: 'Closed Browser Notification',
  });
};

export const trackNotificationPermissionGranted = () => {
  ReactGA.event({
    category: CATEGORY_NOTIFICATIONS,
    action: 'Browser Notification Permissions Ask',
    label: 'Browser Notification Permission Granted',
  });
};

export const trackNotificationPermissionDenied = () => {
  ReactGA.event({
    category: CATEGORY_NOTIFICATIONS,
    action: 'Browser Notification Permissions Ask',
    label: 'Browser Notification Permission Granted',
  });
};

export const trackApplicationUpdate = (app) => {
  ReactGA.event({
    category: CATEGORY_APPLICATION,
    action: 'Update app version',
    label: `Updating to app version ${app.version}`,
    nonInteraction: true,
  });
};

export const trackEmailBookAttendant = (email) => {
  ReactGA.event({
    category: 'Emails',
    action: 'Email request to Book attendant',
    label: 'KPI: Submit request to book an attendant',
  });
};

export const trackEmailHelp = (email) => {
  ReactGA.event({
    category: 'Emails',
    action: 'Email request help',
    label: 'KPI: Submit request help',
  });
};

export const trackEmailACH = (email) => {
  ReactGA.event({
    category: 'Emails',
    action: 'Email request ACH setup',
    label: 'KPI: Submit request ACH setup: ' + email.subject,
  });
};

export const trackEmailServiceQuote = (email) => {
  ReactGA.event({
    category: 'Emails',
    action: 'Email request service quote',
    label: 'KPI: Submit request service quote: ' + email.subject,
  });
};

export const trackCatalogItemRequestCreate = (cir) => {
  ReactGA.event({
    category: 'Catalog Item Requests',
    action: 'Create catalog item request',
    label: 'KPI: Submit request catalog product',
  });
};

export const trackEmployeeOnboard = (employee) => {
  ReactGA.event({
    category: CATEGORY_AUTH,
    action: 'Onboard employee details',
    label: 'KPI: Submit onboarding user details',
  });
};

export const trackEmployeeAvatarUpated = (employee) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Update employee avatar',
    label: `KPI: Set User Avatar for employee ${employee.id}`,
  });
};

export const trackEmployeeUpated = (employee) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Update employee details',
    label: `KPI: Update employee ${employee.id}`,
  });
};

export const trackEmployeePasswordUpated = (employee) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Update employee password',
    label: `KPI: Update password for employee ${employee.id}`,
  });
};

export const trackEmployeeInvited = (employee) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Invite employee',
    label: `KPI: Invited employee ${employee.id}`,
  });
};

export const trackEmployeeUnInvited = (employee) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Uninvite employee',
    label: `KPI: Uninvited employee ${employee.id}`,
  });
};

export const trackEmployeePreferenceUpdated = (preference) => {
  ReactGA.event({
    category: CATEGORY_EMPLOYEES,
    action: 'Update employee preference ' + preference.pref_key,
    label: `KPI: Update ${preference.pref_key} for employee ${preference.preferable_id}`,
  });
};

export const trackLocationUpdated = (location) => {
  ReactGA.event({
    category: CATEGORY_LOCATIONS,
    action: 'Update location',
    label: `KPI: Updated location ${location.id}`,
  });
};

export function trackShipmentComment(shipment) {
  ReactGA.event({
    category: CATEGORY_SHIPMENTS,
    action: 'Create Shipment comment',
    label: 'KPI: Created comment on shipment ' + shipment.id,
  });
}

export function trackShipmentUpdated(shipment) {
  ReactGA.event({
    category: CATEGORY_SHIPMENTS,
    action: 'Update shipment',
    label: 'KPI: Updated shipment ' + shipment.id,
  });
}

export function trackShipmentExternalTracking(shipment) {
  ReactGA.event({
    category: CATEGORY_SHIPMENTS,
    action: 'Open Shipment external tracking',
    label: 'KPI: Open external tracking for shipment ' + shipment.id,
  });
}

export function trackInterfaceOnboardingIntroSkip(step) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Skip onboarding intro',
    label: 'Skip onboarding intro after step ' + step,
  });
}

export function trackInterfaceOnboardingIntroStep(step) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'View onboarding intro Step',
    label: 'View onboarding intro step ' + step,
  });
}

export function trackInterfaceOnboardingIntroComplete() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Complete onboarding intro Steps',
    label: 'Complete onboarding intro steps',
  });
}

export function trackInterfaceShowShippingInstructions() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Show shipping instructions',
    label: 'Show shipping instructions',
  });
}

export function trackInterfaceShowShippingInputs() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Show shipping Inputs',
    label: 'Show shipping Inputs',
  });
}

export function trackInterfaceBudgetCodeTableSort(sort) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Sort budget insights table',
    label: `Clicked sort ${sort.value} on products table`,
  });
}

export function trackInterfaceBudgetsChangeLocations(locations) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Change budget insights locations',
    label: 'Change budget insights locations to ' + locations.length,
  });
}

export function trackInterfaceBudgetsChangeDateRange(range) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Change budget insights date range',
    label: 'Change budget insights date range',
  });
}

export function trackInterfaceOpenProductSearchModal() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Product Search modal',
    label: 'KPI: Open Product Search modal',
  });
}

export function trackInterfaceOpenCustomerRolesDescModal() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open customer roles description modal',
    label: 'KPI: Open customer roles description modal',
  });
}

export function trackInterfaceShipmentOpened(shipment) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Shipment',
    label: 'KPI: Open shipment ' + shipment.id,
  });
}

export function trackInterfaceToggleCart(view) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Toggle Menu Cart',
    label: `Clicked to ${view.state} cart`,
  });
}

export function trackInterfaceAddToCart(view) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Add to Cart',
    label: 'KPI: Add to cart from ' + view.source,
  });
}

export function trackInterfaceOpenProduct(product) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Product Modal',
    label: 'KPI: Open modal for product ' + product.id,
  });
}

export function trackInterfaceLocationListCategoryChange(category) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Change location list category view',
    label: 'KPI: Change location list category ' + category.id,
  });
}

export function trackInterfaceLocationListSearch(search) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Search location list',
    label: 'KPI: Search location list with term: ' + search.value,
  });
}

export function trackInterfaceLocationListToggleEditMode(location_list) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Toggle Location List Edit mode',
    label: 'Toggle Edit mode Location list ' + location_list.id,
  });
}

export function trackInterfaceLocationListProductMoveToTop(
  location_list_product
) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Send Location List Product to top',
    label:
      'KPI: Send to top, location list product ' + location_list_product.id,
  });
}

export function trackInterfaceLocationListProductMovePosition(
  location_list_product
) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Move location list product position',
    label:
      'KPI: Move position, location list product ' + location_list_product.id,
  });
}

export function trackInterfaceOpenCleaningDetails(cleaning) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Cleaning Details Via Select',
    label: 'KPI: Select details for cleaning ' + cleaning.id,
  });
}

export function trackInterfaceOpenCreateTaskModal(cleaning) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open create task modal',
    label: 'Open Modal to Add a Cleaning Task for cleaning ' + cleaning.id,
  });
}

export function trackInterfaceOpenTask(task) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open task modal',
    label: 'Open Modal to edit Cleaning Task ' + task.id,
  });
}

export function trackInterfaceTaskNoteToggle(task) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Toggle task notes',
    label: 'Toggle notes for task ' + task.id,
  });
}

export function trackInterfaceSelectAllForBudgetCodeAssignment() {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Select all for budget code assignment',
    label: 'Select all for budget code assignment',
  });
}

export function trackInterfaceOpenLocationCatalogProduct(product) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Location Catalog Product',
    label: 'KPI: Open Location Catalog Product ' + product.id,
  });
}

export function trackInterfaceFavoriteProduct(product) {
  window.totango.track('Click Favorite Product button.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Click Favorite Product button',
    label: 'KPI: Click Favorite Product ' + product.id,
  });
}

export function trackInterfaceUnFavoriteProduct(product) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Click UnFavorite Product button',
    label: 'KPI: Click UnFavorite Product ' + product.id,
  });
}

export function trackInterfaceAddToCartFromProductItem(product) {
  window.totango.track('Click Add-to-Cart button on Product.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Click Add-to-Cart button on Product',
    label: 'KPI: Click Add-to-Cart button on Product ' + product.id,
  });
}

export function trackInterfacePaginateProductSearchResults(data) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Paginate product search results',
    label: 'Page product search results to ' + data.activePage,
  });
}

export function trackInterfaceSelectedProductSearchSuggestion(suggestion) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Select Product Search suggestion',
    label: 'Select Product Search suggestion product ' + suggestion.id,
  });
}

export function trackInterfaceSetLimitedCatalogViewing(bool) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Toggle limited catalog viewing mode',
    label: 'Toggle limited catalog viewing mode: ' + bool,
  });
}

export function trackInterfaceDownloadInvoice(invoice) {
  window.totango.track('Downloaded invoice.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Downloaded invoice',
    label: 'Downloaded invoice ' + invoice.id,
  });
}

export function trackInterfaceOpenInvoice(invoice) {
  window.totango.track('Open invoice.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open invoice',
    label: 'Open invoice ' + invoice.id,
  });
}

export function trackInterfaceUpdateBillingAddress(data) {
  window.totango.track('Update Billing Address.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Update Billing Address',
    label: 'Update Billing Address',
  });
}

export function trackInterfaceOpenProductSimilar(product) {
  window.totango.track('Open Product Similar.', 'Other');
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Product Similar',
    label: 'Open Product Similar ' + product.id,
  });
}

export function trackInterfaceOpenProductSizeVariant(variant) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Product Size Variant',
    label: 'Open Product Size Variant ' + variant.id,
  });
}

export function trackInterfaceOpenProductComparable(comp) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Open Product Comparable',
    label: 'Open Product Comparable ' + comp.id,
  });
}

export function trackInterfaceOpenProductModalTab(tab) {
  ReactGA.event({
    category: CATEGORY_INTERFACE,
    action: 'Change product modal active tab',
    label: 'Clicked product modal tab: ' + tab,
  });
}

export function trackLocationListUpdated(location_list) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Update location list',
    label: 'KPI: Updated location list ' + location_list.id,
  });
}

export function trackLocationListCreated(location_list) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Create location list',
    label: 'KPI: Created location list ' + location_list.id,
  });
}

export function trackLocationListProductCreated(location_list_product) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Created location list product',
    label: 'KPI: Created location list product ' + location_list_product.id,
  });
}

export function trackLocationListProductUpdated(location_list_product) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Updated location list product',
    label: 'KPI: Updated location list product ' + location_list_product.id,
  });
}

export function trackLocationListProductDeleted(location_list_product) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Deleted location list product',
    label: 'KPI: Deleted location list product ' + location_list_product.id,
  });
}

export function trackLocationListProducBudgetCodetUpdated(
  location_list_product
) {
  ReactGA.event({
    category: CATEGORY_LOCATION_LISTS,
    action: 'Update location list product budget code',
    label:
      'KPI: Update budget code for location list product ' +
      location_list_product.id,
  });
}

export function trackCreateCleaningComment(cleaning) {
  ReactGA.event({
    category: CATEGORY_CLEANINGS,
    action: 'Create cleaning comment',
    label: 'KPI: Create comment on cleaning ' + cleaning.id,
  });
}

export function trackTaskCreated(task) {
  ReactGA.event({
    category: CATEGORY_TASKS,
    action: 'Created cleaning task',
    label:
      `KPI: Added a ${
        task.recurring_task_id ? 'Recurring' : 'One-Time'
      } Task ` + task.id,
  });
}

export function trackTaskUpdated(task) {
  ReactGA.event({
    category: CATEGORY_TASKS,
    action: 'Updated cleaning task',
    label:
      `KPI: Updated ${
        task.recurring_task_id ? 'Recurring' : 'One-Time'
      } Task ` + task.id,
  });
}

export function trackTaskDeleted(task) {
  ReactGA.event({
    category: CATEGORY_TASKS,
    action: 'Deleted cleaning task',
    label:
      `KPI: Deleted ${
        task.recurring_task_id ? 'Recurring' : 'One-Time'
      } Task ` + task.id,
  });
}

export function trackReviewCreated(review) {
  ReactGA.event({
    category: CATEGORY_REVIEWS,
    action: 'Created cleaning review',
    label: `KPI: Created a cleaning review ${review.id}, with ${review.stars} stars`,
  });
}

export function trackReviewUpdated(review) {
  ReactGA.event({
    category: CATEGORY_REVIEWS,
    action: 'Updated cleaning review',
    label: 'KPI: Updated cleaning review ' + review.id,
  });
}

export function trackRecurringRequisitionCreated(rreq) {
  ReactGA.event({
    category: CATEGORY_RECURRING_REQUISITIONS,
    action: 'Created recurring requisition',
    label: 'KPI: Created Recurring Requisition ' + rreq.id,
  });
}

export function trackRecurringRequisitionUpdated(rreq) {
  ReactGA.event({
    category: CATEGORY_RECURRING_REQUISITIONS,
    action: 'Updated recurring requisition',
    label: 'KPI: Updated Recurring Requisition ' + rreq.id,
  });
}

export function trackScheduledRequisitionCreated(sreq) {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Created scheduled requisition',
    label: 'KPI: Created Scheduled Requisition ' + sreq.id,
  });
}

export function trackScheduledRequisitionUpdated(sreq) {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Updated scheduled requisition',
    label: 'KPI: Updated Scheduled Requisition ' + sreq.id,
  });
}

export function trackRecurringProductRequisitionCreated(rpr) {
  ReactGA.event({
    category: CATEGORY_RECURRING_REQUISITIONS,
    action: 'Created recurring product requisition',
    label: 'KPI: Created Recurring Product Requisition ' + rpr.id,
  });
}

export function trackRecurringProductRequisitionUpdated(rpr) {
  ReactGA.event({
    category: CATEGORY_RECURRING_REQUISITIONS,
    action: 'Updated recurring product requisition',
    label: 'KPI: Updated Recurring Product Requisition ' + rpr.id,
  });
}

export function trackRecurringProductRequisitionDeleted(rpr) {
  ReactGA.event({
    category: CATEGORY_RECURRING_REQUISITIONS,
    action: 'Deleted recurring product requisition',
    label: 'KPI: Deleted Recurring Product Requisition ' + rpr.id,
  });
}

export function trackScheduledProductRequisitionCreated(rpr) {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Created Scheduled product requisition',
    label: 'KPI: Created Scheduled Product Requisition ' + rpr.id,
  });
}

export function trackScheduledProductRequisitionUpdated(rpr) {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Updated Scheduled product requisition',
    label: 'KPI: Updated Scheduled Product Requisition ' + rpr.id,
  });
}

export function trackScheduledProductRequisitionDeleted(rpr) {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Deleted Scheduled product requisition',
    label: 'KPI: Deleted Scheduled Product Requisition ' + rpr.id,
  });
}

export function trackCustomerBudgetCodeCreated(cbc) {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Created customer budget code',
    label: 'KPI: Created customer budget code ' + cbc.id,
  });
}

export function trackCustomerBudgetCodeUpdated(cbc) {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Updated customer budget code',
    label: 'KPI: Updated customer budget code ' + cbc.id,
  });
}

export function trackInterfaceCompleteInsightsOnboarding() {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Complete budget insights onboarding',
    label: 'KPI: Finish insights onboarding',
  });
}

export function trackInsightsDownloadPDF() {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Downloaded budget insights PDF',
    label: 'KPI: Downloaded insights pdf',
  });
}

export function trackInsightsDownloadCSV() {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Downloaded budget insights CSV',
    label: 'KPI: Downloaded insights csv',
  });
}

export function trackInsightsDownloadExcel() {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Downloaded budget insights Excel',
    label: 'KPI: Downloaded insights excel',
  });
}

export function trackInterfaceClickBudgetCodeBar(cbc) {
  ReactGA.event({
    category: CATEGORY_INSIGHTS,
    action: 'Clicked budget code bar',
    label: 'Clicked spend by category bar ' + cbc.id,
  });
}

export function trackLocationCatalogProductCreated(lcp) {
  ReactGA.event({
    category: CATEGORY_LOCATION_CATALOG,
    action: 'Created Location Catalog Product',
    label: 'Created Location Catalog Product ' + lcp.id,
  });
}

export function trackLocationCatalogProductDeleted(lcp) {
  ReactGA.event({
    category: CATEGORY_LOCATION_CATALOG,
    action: 'Deleted Location Catalog Product',
    label: 'Deleted Location Catalog Product ' + lcp.id,
  });
}

export function trackLocationCatalogProductCreatedAllLocations() {
  ReactGA.event({
    category: CATEGORY_LOCATION_CATALOG,
    action: 'Created Location Catalog Product for all locations',
    label: 'Created Location Catalog Product for all locations',
  });
}

export function trackRequisitionCreated(req) {
  window.totango.track('Created Requisition.', 'Other');
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Created Requisition',
    label: 'Created Requisition ' + req.id,
  });
}

export function trackRequisitionUpdated(req) {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Updated Requisition',
    label: 'Updated Requisition ' + req.id,
  });
}

export function trackRequisitionSubmitted(req) {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Submitted Requisition',
    label: 'Submitted Requisition ' + req.id,
  });
}

export function trackRequisitionFailedToSubmit(req, errMessage) {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Failed To Submit Requisition',
    label:
      'Failed To Submit Requisition ' + req.id + ' with error ' + errMessage,
  });
}

export function trackRequisitionSubmittedForApproval(req) {
  window.totango.track('Requisition Submitted For Approval.', 'Other');
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Proposed Requisition',
    label: 'Proposed Requisition ' + req.id,
  });
}

export const trackProductRequisitionBudgetCodeUpdated = (preq) => {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Update product requisition budget code',
    label: 'KPI: Update budget code for product requisition ' + preq.id,
  });
};

export function trackProductRequisitionCreated(req) {
  window.totango.track('Created Product Requisition.', 'Other');
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Created Product Requisition',
    label: 'Created Product Requisition ' + req.id,
  });
}

export function trackProductRequisitionUpdated(req) {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Updated Product Requisition',
    label: 'Updated Product Requisition ' + req.id,
  });
}

export function trackProductRequisitionDeleted(req) {
  ReactGA.event({
    category: CATEGORY_REQUISITIONS,
    action: 'Deleted Product Requisition',
    label: 'Deleted Product Requisition ' + req.id,
  });
}

export const trackCalendarAgendaClick = (direction) => {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Clicked Calendar Agenda Arrow',
    label: 'Clicked Calendar Agenda Arrow: ' + direction,
  });
};

export const trackCalendarDayClick = () => {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Clicked Calendar Day',
    label: 'Clicked Calendar Day',
  });
};

export const trackRevertToTimeline = () => {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Revert to Timeline View',
    label: 'Clicked Revert to Timeline View',
  });
};

export const trackNewSchedReqClick = () => {
  ReactGA.event({
    category: CATEGORY_SCHEDULED_REQUISITIONS,
    action: 'Clicked New Scheduled Order button from Calendar Day',
    label: 'Clicked New Scheduled Order button from Calendar Day',
  });
};
