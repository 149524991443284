import PropTypes from 'prop-types';
import { formatStaffName, formatEmployeeName } from '../lib/formatters.js';
import { CONTAINS_ALCOHOL } from '../flags.js';
import {
  filterPromoCodeCredits,
  filterNonPromoCodeCredits,
  totalAmount,
} from '../helpers/credit-helpers.js';

export function isEditable(requisition, user, userType) {
  if (!user || user.id === 0) {
    return false;
  }
  if (
    userType.toLowerCase() !== (requisition.requester_type || '').toLowerCase()
  ) {
    return false;
  }
  if (user.id !== requisition.requester_id) {
    return false;
  }
  return (
    requisition.status && requisition.status.match(/draft|unworked/) !== null
  );
}

export function isProcessing(requisition) {
  return (
    requisition.status && requisition.status.match(/claimed|deferred/) !== null
  );
}

export const itemsNotInBoth = (prs1, prs2) => {
  if (!prs1) return [];
  if (!prs2) return [];

  const productIds = prs1.map((pr) => pr.product_id);
  return prs2.filter((openPr) => {
    return productIds.indexOf(openPr.product_id) === -1;
  });
};

/** FILTERS **/
export const filterSubmitted = (req) => req.submitted_at !== null;

export const sortBySubmittedAtDesc = (a, b) => {
  return a.submitted_at > b.submitted_at ? -1 : 1;
};

export const requesterText = (req) => {
  if (!req) return '';

  return req.proposer ? 'Approved By' : 'Ordered By';
};

export const proposerText = (req) => {
  if (!req) return '';
  if (!req.proposer) return '';

  return 'Requested By';
};

export const displayUserName = (user, userType) => {
  if (userType && userType.match(/staff/i)) return formatStaffName(user);
  else if (userType && userType.match(/employee/i)) {
    return formatEmployeeName(user);
  } else {
    return 'OfficeLuv';
  }
};

export const displayName = (requisition) => {
  if (!requisition) return '';
  if (requisition.name) {
    return `${requisition.name} (#${requisition.id})`;
  }
  return `Order #${requisition.id}`;
};

export const reduceItemsPrice = (acc, val) => {
  return (
    acc +
    val.quantity *
      (val.price === 0 ? val.price : val.price || val.product.price)
  );
};

export const priceFromProdReqs = (requisition) => {
  return (
    requisition.product_requisitions ||
    requisition.scheduled_product_requisitions ||
    []
  ).reduce(reduceItemsPrice, 0);
};

export const shippingFee = (req) => {
  return req.shipping_price;
};

export const alcoholFee = (req, opts) => {
  if (req.submitted_at) return 0;

  let alcohol = opts.alcoholConvenienceFee;
  if (!CONTAINS_ALCOHOL(req.product_requisitions)) alcohol = 0;
  return alcohol;
};

export const taxesAndFees = (req) =>
  (req.tax || 0) + (req.payment_convenience_price || 0);

export const appliedSavings = (req) => {
  return -totalAmount((req.credits || []).filter(filterNonPromoCodeCredits));
};

export const appliedStipend = (req) => {
  if (req.stipend_details) {
    return -req.stipend_details.location_purchase_price;
  }
  return -(req.total_price - req.requester_purchase_price);
};

export const totalPrice = (req, opts) => {
  let total =
    priceFromProdReqs(req) +
    shippingFee(req) +
    alcoholFee(req, opts) +
    taxesAndFees(req) -
    totalAmount(req.credits);
  if (opts.isStipendUser) return total + appliedStipend(req);
  return total;
};

export const buildTotalsLineItems = (requisition, opts = {}) => {
  let collect = [];
  collect.push({
    key: 'subtotal',
    label: 'Subtotal',
    amount: priceFromProdReqs(requisition),
  });
  collect.push({
    key: 'shipping',
    label: requisition.submitted_at ? 'Shipping & Fees' : 'Shipping',
    amount: shippingFee(requisition),
  });
  collect.push({
    key: 'alcohol',
    label: 'Alcohol Fee',
    amount: alcoholFee(requisition, opts),
  });
  collect.push({
    key: 'appliedSavings',
    label: 'Applied Savings',
    amount: appliedSavings(requisition),
  });
  let promos = requisition.credits
    ? requisition.credits.filter(filterPromoCodeCredits).sort((a, b) => {
        if (a.applied_at < b.applied_at) return -1;
        if (a.applied_at > b.applied_at) return 1;
        return 1;
      })
    : [];
  promos.forEach((credit, i) => {
    collect.push({
      key: `promo_${i + 1}`,
      label: `Applied ${credit.description}`,
      amount: -credit.amount,
    });
  });
  if (opts.showTax) {
    collect.push({
      key: 'taxesAndFees',
      label: requisition.submitted_at
        ? 'Taxes & Fees'
        : 'Estimated Taxes & Fees',
      amount: taxesAndFees(requisition),
    });
  }
  if (opts.isStipendUser) {
    collect.push({
      key: 'appliedStipend',
      label: 'Applied Stipend',
      amount: appliedStipend(requisition),
    });
  }
  if (opts.showTax) {
    collect.push({
      key: 'total',
      label: 'Total',
      amount: totalPrice(requisition, opts),
    });
  }
  return collect;
};

export const totalsLineItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    amount: PropTypes.number,
  })
);
