import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import { Link } from 'react-router-dom';
import setTitle from '../decorators/set-title.js';
import OnboardingStep from '../components/onboarding/step.js';
import AuthCheck from '../components/auth-check.js';
import OnboardingDeliveryInfoForm from '../components/onboarding/delivery-info-form.js';
import image from '../imgs/onboarding/delivery.jpg';
import {
  doGetLocationExternalAccounts,
  submitUpdateLocation,
} from '../actions/locations-actions.js';
import { isAdmin } from '../helpers/customer-role-helpers.js';

export class OnboardingDeliveryInfo extends React.Component {
  componentDidMount() {
    if (this.props.location.id) {
      this.props.actions.doGetLocationExternalAccounts(this.props.location);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.id !== this.props.location.id) {
      this.props.actions.doGetLocationExternalAccounts(this.props.location);
    }
    if (
      !this.props.isUpdating &&
      this.locationHasBilling(this.props) &&
      !this.props.location.onboarded_at
    ) {
      this.props.actions.submitUpdateLocation({
        id: this.props.location.id,
        onboarded_at: new Date(),
      });
    }
  }

  handleSubmit = () => {
    browserHistory.push(this.nextRoute());
  };

  nextRoute = () => {
    if (!this.props.can_manage_billing) {
      return '/supplies/catalog';
    }
    if (this.locationHasBilling()) {
      return '/supplies/catalog';
    }
    return '/onboarding/billing-info';
  };

  locationHasBilling = (props = this.props) => {
    if (props.location.pref_billing_exempt) {
      return true;
    }
    let accounts = props.location.external_accounts || {};
    if (accounts.stripe && accounts.stripe.payment_method) {
      return true;
    }
    return false;
  };

  stepNumber = () => {
    let steps = 2;
    if (this.props.can_manage_employees_locations) {
      steps += 1;
    }
    if (this.props.can_manage_billing) {
      steps += 1;
    }
    return steps;
  };

  stepTotal = () => {
    let steps = 2;
    if (this.props.can_manage_employees_locations) {
      steps += 2;
    }
    if (this.props.can_manage_billing) {
      steps += 1;
    }
    return steps;
  };

  render() {
    return (
      <OnboardingStep backgroundImage={`url(${image})`}>
        <AuthCheck customAuthorized={isAdmin(this.props.roleName)} />
        <OnboardingDeliveryInfoForm
          onSubmit={this.handleSubmit}
          stepTotal={this.stepTotal()}
          stepNumber={this.stepNumber()}
        />
        <Link
          className="skip-link"
          to={this.nextRoute()}
          style={{
            color: this.props.location.brand_color || '#00a98c',
            textDecoration: 'none',
          }}>
          Skip for Now
          <span>&nbsp;&rsaquo;&rsaquo;</span>
        </Link>
      </OnboardingStep>
    );
  }
}

OnboardingDeliveryInfo.propTypes = {
  roleName: PropTypes.string.isRequired,
  can_manage_billing: PropTypes.bool.isRequired,
  can_manage_employees_locations: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pref_billing_exempt: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    doGetLocationExternalAccounts: PropTypes.func.isRequired,
    submitUpdateLocation: PropTypes.func.isRequired,
  }).isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

OnboardingDeliveryInfo.defaultProps = {};

OnboardingDeliveryInfo = setTitle(() => {
  return 'OfficeLuv Onboarding';
})(OnboardingDeliveryInfo);

function mapStateToProps(state) {
  const location = state.locations.open;
  return {
    roleName: state.auth.role.name,
    can_manage_employees_locations:
      state.auth.role.can_manage_employees_locations,
    can_manage_billing: state.auth.role.can_manage_billing,
    isUpdating:
      state.locations.requesting.filter((loc) => loc.id === location.id)
        .length > 0,
    location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetLocationExternalAccounts,
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth.role === next.auth.role &&
    prev.locations.open === next.locations.open &&
    prev.locations.requesting === next.locations.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingDeliveryInfo);
