import * as types from './action-types';
import fetch from '../lib/hmac-fetch';
import {
  trackFetchError,
  trackCreateCleaningComment,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { AANG_URL, HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

const INFLATES =
  'inflate=cleaning_reviews&inflate=cleaning_checkins&inflate=cleaning_checkin_expectations&inflate=cleaning_tasks&inflate=cleaning_staffs&inflate=cleaning_checkins&inflate=cleaning_comments&inflate=comment_commenter';

export const getCleanings = () => {
  return {
    type: types.GET_CLEANINGS,
  };
};

export const successGetCleanings = (data, meta = null) => {
  return {
    type: types.SUCCESS_CLEANINGS,
    data,
    meta,
  };
};

export const errorGetCleanings = (data) => {
  return {
    type: types.ERROR_GET_CLEANINGS,
    data,
  };
};

export const getCleaning = (data) => {
  return {
    type: types.GET_CLEANING,
    data,
  };
};

export const errorGetCleaning = (data) => {
  return {
    type: types.ERROR_GET_CLEANING,
    data,
  };
};

export const successCreateCleaning = (data) => {
  return {
    type: types.SUCCESS_CREATE_CLEANING,
    data,
  };
};

export const successUpdateCleaning = (data, update) => {
  return {
    type: types.SUCCESS_UPDATE_CLEANING,
    data,
    update,
  };
};

export const postCreateCleaningComment = (data) => {
  return {
    type: types.CREATE_CLEANING_COMMENT,
    data,
  };
};

export const successCreateCleaningComment = (data) => {
  return {
    type: types.SUCCESS_CREATE_CLEANING_COMMENT,
    data,
  };
};

export const errorCreateCleaningComment = (data) => {
  return {
    type: types.ERROR_CREATE_CLEANING_COMMENT,
    data,
  };
};

export const openCleaningReport = (data) => {
  return {
    type: types.OPEN_CLEANING,
    data,
    route: 'reports',
  };
};

export const openCleaningDetails = (data) => {
  return {
    type: types.OPEN_CLEANING,
    data,
    route: 'details',
  };
};

export const closeCleaning = () => {
  return {
    type: types.CLOSE_CLEANING,
  };
};

/**
 * Fetch cleanings for the currently open location
 *
 * @access public
 * @param {String} [paged] Next URL for pagination
 * @param {Boolean} [untilPast] Force results pagination fetch until past cleanings are reached
 * @returns {function} redux thunk
 */
export function doGetCleanings(paged, untilPast) {
  return (dispatch, getState) => {
    dispatch(getCleanings());
    const { locations } = getState();
    const open_location_id = locations.open.id;
    const url =
      paged ||
      `/locations/${open_location_id}/cleanings?limit=30&orderBy=start_datetime__desc&${INFLATES}`;
    return fetch(`${AANG_URL}${url}`, {
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': requestID(),
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data: {}, message: 'Get cleanings' });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = parseInt(url.match(/locations\/(\d+)/)[1], 10);
        dispatch(successGetCleanings(body.data, body.meta));
        return body;
      })
      .then((body) => {
        if (!untilPast) return body;
        let now = new Date().toJSON();
        let havePast =
          body.data.filter((c) => c.start_datetime < now).length > 0;
        if (!havePast && body.meta.next) {
          dispatch(doGetCleanings(body.meta.next, untilPast));
        }
        return body;
      })
      .catch((error) => {
        dispatch(errorGetCleanings(error));
        trackFetchError(error);
      });
  };
}

/**
 * Fetch cleaning
 *
 * @access public
 * @param {object} cleaning
 * @param {number} cleaning.id
 * @returns {function} redux thunk
 */
export function doGetCleaning(cleaning) {
  return (dispatch, getState) => {
    dispatch(getCleaning(cleaning));
    const { locations } = getState();
    const open_location_id = locations.open.id;
    return fetch(
      `${AANG_URL}/locations/${open_location_id}/cleanings/${cleaning.id}?${INFLATES}`,
      {
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': requestID(),
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: { id: cleaning.id },
            message: 'Get cleaning ' + cleaning.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        const { cleanings } = getState();
        const extant = cleanings.items.filter((t) => t.id === body.id)[0];
        let update = {};
        if (extant) {
          dispatch(successUpdateCleaning(body, update));
        } else {
          dispatch(successCreateCleaning(body));
        }
      })
      .catch((error) => {
        dispatch(errorGetCleaning(error));
        trackFetchError(error);
      });
  };
}

/**
 * Create comment for the currently viewed cleaning
 *
 * @access public
 * @returns {function} redux thunk
 */
export function submitCleaningComment(data, cleaning) {
  const comment = {
    commentable_type: 'Cleaning',
    commentable_id: cleaning.id,
    commenter_type: 'Employee',
    commenter_id: data.commenter_id,
    body: data.body,
    _request: data._request || requestID(),
  };
  return (dispatch) => {
    dispatch(postCreateCleaningComment(comment));
    return fetch(`${ADMIN_V1_URL}/comments`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': comment._request,
      },
      body: JSON.stringify(comment),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: comment,
            message: 'Create cleaning comment',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateCleaningComment(
            Object.assign({}, body, { _request: comment._request })
          )
        );
        trackCreateCleaningComment(cleaning);
      })
      .catch((error) => {
        dispatch(errorCreateCleaningComment(error));
        trackFetchError(error);
      });
  };
}
