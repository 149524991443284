import React from 'react';
import PropTypes from 'prop-types';
import { VictoryPie, VictoryLabel } from 'victory';
import { formatCentAmount } from '../../lib/formatters.js';

const EMPTY_DATA = {
  x: 'No Spend',
  y: 0.1,
};

export default class GroupedSpendPie extends React.PureComponent {
  constructor(props) {
    super(props);
    let data = this.formatData(props);
    this.state = {
      data,
      label: null,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let data = this.formatData(props);
    this.setState(() => {
      return {
        data,
        label: null,
      };
    });
  }

  formatData = (props) => {
    let positiveData = props.data.filter(
      (d) => d.line_items_submitted_price > 0
    );
    if (!positiveData.length) {
      return [EMPTY_DATA];
    }
    return positiveData
      .map((d) => {
        return {
          y: d.line_items_submitted_price,
          x: d.name,
        };
      })
      .reduce((acc, val, idx) => {
        if (idx === props.maxGroups) {
          return acc.concat({
            x: props.maxLabel,
            y: val.y,
          });
        }
        if (idx > props.maxGroups) {
          acc[props.maxGroups].y += val.y;
          return acc;
        }
        return acc.concat(val);
      }, []);
  };

  label(d) {
    if (!d.x) return '';
    return `${d.x}\n${formatCentAmount(d.y)}`;
  }

  getColorScaleForData = (data) => {
    if (data.length === 1 && data[0].x === EMPTY_DATA.x) {
      return ['#00a98c'];
    }
    return ['#009077', '#00a98c', '#00c3a1', '#00dcb6', '#00f6cb'];
  };

  defaultLabel = () => {
    if (this.props.displayTotal) {
      let total = this.state.data.reduce((acc, val) => acc + val.y, 0);
      return this.label({ x: 'Total Spend', y: total });
    }
    return '';
  };

  render() {
    let label =
      this.state.label || (this.props.displayTotal ? this.defaultLabel() : '');
    return (
      <svg viewBox="0 0 300 300" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          width={300}
          height={300}
          innerRadius={140}
          data={this.state.data}
          labels={() => null}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onMouseOver: (e, props) => {
                  this.setState(() => {
                    return { label: this.label(props.datum) };
                  });
                  return [];
                },
                onMouseOut: () => {
                  this.setState(() => {
                    return { label: null };
                  });
                  return [];
                },
              },
            },
          ]}
          colorScale={this.getColorScaleForData(this.state.data)}
          style={{ fontFamily: 'Roboto, Helvetica, sans-serif' }}
          animate={{
            duration: 300,
            onLoad: { duration: 300 },
          }}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          x={150}
          y={150}
          text={label}
          style={{
            fontSize: 20,
            fontFamily: 'Roboto, Helvetica, sans-serif',
          }}
        />
      </svg>
    );
  }
}

GroupedSpendPie.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      line_items_submitted_price: PropTypes.number.isRequired,
    })
  ).isRequired,
  maxGroups: PropTypes.number.isRequired,
  maxLabel: PropTypes.string.isRequired,
  displayTotal: PropTypes.bool.isRequired,
  colorScale: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

GroupedSpendPie.defaultProps = {
  maxGroups: 8,
  maxLabel: 'Other',
  colorScale: 'qualitative',
  displayTotal: true,
};
