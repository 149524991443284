import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Message } from 'semantic-ui-react';
import {
  doGetCartPunchoutOrderMessage,
  FILTER_IS_LOADING,
  FILTER_IS_PUNCHOUT_ORDER_MESSAGE_ERROR,
} from '../../ducks/carts.js';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/message.min.css';

export class CartPunchoutOrderMessageForm extends React.PureComponent {
  state = {
    error: null,
  };

  componentDidMount() {
    if (!this.props.cart.id) return;

    this.props.actions.doGetCartPunchoutOrderMessage(this.props.cart);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cart.id !== this.props.cart.id) {
      return this.props.actions.doGetCartPunchoutOrderMessage(this.props.cart);
    }
    if (this.props.isLoading) return;
    if (this.props.error && !this.state.error) {
      return this.setState({ error: this.props.error });
    }
    if (this.state.error) return;
    if (!this.props.punchoutOrderMessage) return;
    if (!this.props.submit) return;
    if (this.props.onSubmit({ target: this.refs.form }) === false) {
      return;
    }

    this.refs.form.submit();
  }

  render() {
    return (
      <form
        ref="form"
        method="POST"
        action={this.props.punchoutSession.request_url}
      >
        <input
          type="hidden"
          name="cxml-urlencoded"
          value={this.props.punchoutOrderMessage}
        />
        {this.props.isLoading && this.props.displayLoading && (
          <Loader inline="centered" active>
            Preparing Order
          </Loader>
        )}
        {this.state.error && this.props.displayError && (
          <Message error>
            <Message.Content>
              There was an error preparing your punchout order.
            </Message.Content>
          </Message>
        )}
      </form>
    );
  }
}

CartPunchoutOrderMessageForm.propTypes = {
  submit: PropTypes.bool.isRequired,
  displayError: PropTypes.bool.isRequired,
  displayLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  punchoutSession: PropTypes.shape({
    id: PropTypes.number.isRequired,
    request_url: PropTypes.string.isRequired,
  }).isRequired,
  punchoutOrderMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    data: PropTypes.shape({
      error: PropTypes.shape({}).isRequired,
    }).isRequired,
  }),
  actions: PropTypes.shape({
    doGetCartPunchoutOrderMessage: PropTypes.func.isRequired,
  }).isRequired,
};

CartPunchoutOrderMessageForm.defaultProps = {
  displayLoading: true,
  displayError: true,
  submit: false,
  isLoading: false,
  onSubmit: function () {},
};

export function mapStateToProps(state, props) {
  return {
    punchoutOrderMessage: state.carts.punchoutOrderMessages[props.cart.id],
    punchoutSession: state.punchoutSessions.open,
    isLoading: state.carts.requesting.filter(FILTER_IS_LOADING).length > 0,
    error: state.carts.responses.filter(
      FILTER_IS_PUNCHOUT_ORDER_MESSAGE_ERROR
    )[0],
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetCartPunchoutOrderMessage,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.carts.punchoutOrderMessages === next.carts.punchoutOrderMessages &&
    prev.carts.requesting === next.carts.requesting &&
    prev.carts.responses === next.carts.responses
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CartPunchoutOrderMessageForm);
