import React from 'react';
import PropTypes from 'prop-types';
import QBillingForm from './quickbooks-billing-info-form.js';
import checkmarkIconGreen from '../../imgs/checkmark-icon--green.svg';
import { Button, Table } from 'semantic-ui-react';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/table.min.css';
import './billing-info.css';

export default class QuickbooksBillingInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isSubmitted: false,
    };
  }

  _onClickUpdate = () => {
    this.setState(
      Object.assign({}, this.state, {
        isEditing: true,
      })
    );
  };

  _handleSubmit = () => {
    this.setState(
      Object.assign({}, this.state, {
        isEditing: false,
        isSubmitted: true,
      })
    );
  };

  render() {
    const billing_address = this.props.account.billing_address || {};
    if (this.state.isEditing) {
      return (
        <div className="quickbooks-billing-info">
          <QBillingForm
            account={this.props.account}
            onSubmit={this._handleSubmit}
          />
        </div>
      );
    }
    if (this.state.isSubmitted) {
      return (
        <div className="quickbooks-billing-info">
          <div className="update-bill-info-success">
            <div className="success-checkmark">
              <img
                src={checkmarkIconGreen}
                className="checkmark"
                alt="completed-checkmark"
              />
              <div className="success-received">We received your request.</div>
            </div>
            <p>Please note, it may take up to 3 days for the</p>
            <p>changes to be reflected in your account.</p>
          </div>
        </div>
      );
    }
    return (
      <Table basic="very" className="quickbooks-billing-info">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Invoicing</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {this.props.active && <em>Preferred Method</em>}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <p>Company</p>
            </Table.Cell>
            <Table.Cell>
              <p>{this.props.account.company_name}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>Name</p>
            </Table.Cell>
            <Table.Cell>
              <p>
                {this.props.account.given_name} {this.props.account.family_name}
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>Email</p>
            </Table.Cell>
            <Table.Cell>
              <p>{(this.props.account.primary_email_address || {}).address}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>Address</p>
            </Table.Cell>
            <Table.Cell>
              <p>{billing_address.line1}</p>
              <p>
                {billing_address.city},{' '}
                {billing_address.country_sub_division_code}{' '}
                {billing_address.postal_code}
              </p>
              <p>{(this.props.account.primary_phone || {}).free_form_number}</p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        {this.props.canEdit && (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <Button
                  size="mini"
                  compact
                  onClick={this._onClickUpdate}
                  className="tertiary">
                  {this.state.editing ? 'View' : 'Edit Invoicing Info'}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    );
  }
}

QuickbooksBillingInfo.propTypes = {
  active: PropTypes.bool,
  account: PropTypes.shape({
    billing_address: PropTypes.shape({
      id: PropTypes.string.isRequired,
      line1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country_sub_division_code: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
    }).isRequired,
    company_name: PropTypes.string.isRequired,
    family_name: PropTypes.string.isRequired,
    given_name: PropTypes.string.isRequired,
    primary_phone: PropTypes.shape({
      free_form_number: PropTypes.string.isRequired,
    }),
    primary_email_address: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

QuickbooksBillingInfo.defaultProps = {
  canEdit: true,
};
