import React from 'react';
import PropTypes from 'prop-types';
import browserHistory from '../lib/history.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doInitializeApp } from '../actions/application-actions.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';
import cookies from '../lib/encrypted-cookies.js';
import { AUTH_COOKIE, AUTH_STATUS_CHECK_INTERVAL_MS } from '../strings.js';

export class CheckAuth extends React.PureComponent {
  intervalId = null;

  componentDidMount() {
    if (!this.props.auth.hmac) {
      return this.redirectToSignIn();
    }
    if (!this.authorized()) {
      return this.redirect();
    }
    this.initializeApp();
    this.intervalId = window.setInterval(
      this.checkAuthStatus,
      AUTH_STATUS_CHECK_INTERVAL_MS
    );
  }

  componentWillUnmount() {
    this.intervalId && window.clearInterval(this.intervalId);
  }

  componentDidUpdate() {
    if (!this.authorized()) {
      return this.redirect();
    }
    if (!this.props.auth.hmac) {
      return this.redirectToSignIn();
    }
  }

  authorized = () => {
    return (
      Object.keys(this.props.permissions).reduce((acc, key) => {
        return acc && this.props.auth.role[key] === this.props.permissions[key];
      }, true) && this.props.customAuthorized
    );
  };

  checkAuthStatus = () => {
    if (!cookies.get(AUTH_COOKIE)) {
      this.intervalId && window.clearInterval(this.intervalId);
      browserHistory.push('/sign-out');
    }
  };

  initializeApp = () => {
    if (!this.props.appInitialized) {
      this.props.actions.doInitializeApp();
    }
  };

  redirect = () => {
    this.props.actions.enqueueConfirmation({
      message: this.props.redirectConfirmation(),
      type: 'error',
    });
    this.props.redirect.call();
  };

  redirectToSignIn() {
    browserHistory.push(
      '/sign-in?path=' +
        encodeURIComponent(
          window.location.pathname +
            window.location.search +
            window.location.hash
        )
    );
  }

  render() {
    return <div name="CheckAuth" />;
  }
}

CheckAuth.propTypes = {
  redirect: PropTypes.func.isRequired,
  redirectConfirmation: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    can_manage_roles: PropTypes.bool,
    can_manage_billing: PropTypes.bool,
    can_manage_location_catalog: PropTypes.bool,
    can_manage_employees_locations: PropTypes.bool,
    can_manage_employees_stipends: PropTypes.bool,
    can_manage_all_requisitions: PropTypes.bool,
    can_manage_insights: PropTypes.bool,
  }).isRequired,
  auth: PropTypes.shape({
    hmac: PropTypes.object,
    lastFetch: PropTypes.instanceOf(Date),
  }).isRequired,
  actions: PropTypes.shape({
    doInitializeApp: PropTypes.func.isRequired,
    enqueueConfirmation: PropTypes.func.isRequired,
  }).isRequired,
  customAuthorized: PropTypes.bool,
  appInitialized: PropTypes.bool.isRequired,
};

CheckAuth.defaultProps = {
  redirectConfirmation: function () {
    return 'Your role does not permit access to that page.';
  },
  redirect: function () {
    if (!browserHistory.location.key) {
      // proxy for if loading initial app page
      browserHistory.push('/'); // push to custom route
    } else {
      browserHistory.goBack(); // go back normally
    }
  },
  permissions: {},
  customAuthorized: true,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appInitialized: state.application.initialized,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doInitializeApp,
        enqueueConfirmation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth === next.auth &&
    prev.application.initialized === next.application.initialized
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CheckAuth);
