export default function ({ color }) {
  color = color || '#00a98c';
  let svg = `
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="352.000000pt" height="352.000000pt" viewBox="0 0 352.000000 352.000000"
         preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g transform="translate(0.000000,352.000000) scale(0.100000,-0.100000)"
        fill="${color}" stroke="none">
        <path d="M234 3024 c-68 -33 -71 -138 -6 -171 9 -4 118 -10 242 -13 l224 -5
        254 -636 254 -635 -81 -195 c-44 -107 -81 -204 -81 -216 0 -42 20 -74 55 -88
        29 -13 167 -15 905 -15 935 0 913 -1 940 49 27 52 1 122 -52 140 -21 8 -282
        11 -814 11 -431 0 -784 4 -784 8 0 8 79 203 84 209 2 2 371 32 822 68 450 35
        834 68 853 72 19 5 42 19 53 33 25 34 233 939 224 976 -4 14 -18 37 -31 50
        l-24 24 -1148 0 c-631 0 -1153 4 -1159 8 -6 4 -37 75 -70 157 -78 198 -52 185
        -379 185 -204 0 -256 -3 -281 -16z m2871 -556 c-19 -88 -155 -672 -157 -674
        -5 -5 -1549 -125 -1564 -122 -11 2 -70 137 -179 410 -89 224 -160 409 -157
        412 3 4 468 6 1034 6 l1030 0 -7 -32z"/>
        <path d="M1390 961 c-94 -30 -173 -100 -218 -191 -38 -78 -39 -191 -1 -270 71
        -149 208 -223 366 -198 111 17 195 84 251 198 23 46 27 68 26 135 0 67 -5 89
        -28 137 -62 124 -163 191 -296 195 -41 1 -86 -1 -100 -6z m168 -208 c37 -23
        62 -73 62 -125 0 -89 -92 -156 -179 -129 -59 17 -101 70 -101 127 0 74 29 116
        97 139 42 15 86 10 121 -12z"/>
        <path d="M2393 950 c-75 -30 -146 -98 -185 -178 -29 -58 -33 -75 -32 -142 2
        -84 17 -129 70 -201 63 -86 155 -130 272 -130 126 0 229 64 292 183 86 160 24
        357 -139 447 -56 30 -75 35 -145 38 -64 2 -92 -1 -133 -17z m157 -180 c32 0
        89 -58 101 -102 9 -33 7 -49 -6 -84 -42 -110 -186 -124 -252 -25 -37 55 -26
        141 21 180 22 19 88 41 104 36 8 -3 22 -5 32 -5z"/>
        </g>
        </svg>
    `;
  let blob = new Blob([svg], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
}
