import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackLocationListUpdated,
  trackLocationListCreated,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { doGetLocationListProducts } from './location-list-product-actions.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

export const getLocationLists = (data) => {
  return {
    type: types.GET_LOCATION_LISTS,
    data,
  };
};

const successGetLocationLists = (data) => {
  return {
    type: types.SUCCESS_LOCATION_LISTS,
    data,
  };
};

const errorGetLocationLists = (data) => {
  return {
    type: types.ERROR_LOCATION_LISTS,
    data,
  };
};

export const getLocationList = (data) => {
  return {
    type: types.GET_LOCATION_LIST,
    data,
  };
};

const successGetLocationList = (data) => {
  return {
    type: types.SUCCESS_LOCATION_LIST,
    data,
  };
};

const errorGetLocationList = (data) => {
  return {
    type: types.ERROR_LOCATION_LIST,
    data,
  };
};

const postCreateLocationList = (data) => {
  return {
    type: types.CREATE_LOCATION_LIST,
    data,
  };
};

const successCreateLocationList = (data) => {
  return {
    type: types.SUCCESS_CREATE_LOCATION_LIST,
    data,
  };
};

const errorCreateLocationList = (data) => {
  return {
    type: types.ERROR_CREATE_LOCATION_LIST,
    data,
  };
};

const postUpdateLocationList = (data) => {
  return {
    type: types.UPDATE_LOCATION_LIST,
    data,
  };
};

const successUpdateLocationList = (data) => {
  return {
    type: types.SUCCESS_UPDATE_LOCATION_LIST,
    data,
  };
};

const errorUpdateLocationList = (data) => {
  return {
    type: types.ERROR_UPDATE_LOCATION_LIST,
    data,
  };
};

const postDeleteLocationList = (data) => {
  return {
    type: types.DELETE_LOCATION_LIST,
    data,
  };
};

const successDeleteLocationList = (data) => {
  return {
    type: types.SUCCESS_DELETE_LOCATION_LIST,
    data,
  };
};

const errorDeleteLocationList = (data) => {
  return {
    type: types.SUCCESS_DELETE_LOCATION_LIST,
    data,
  };
};

export const openLocationList = (data) => {
  return {
    type: types.OPEN_LOCATION_LIST,
    data,
  };
};

export const closeLocationList = (data) => {
  return {
    type: types.CLOSE_LOCATION_LIST,
    data,
  };
};

export const openLocationListCreateModal = () => {
  return {
    type: types.OPEN_LOCATION_LIST_CREATE_MODAL,
  };
};

export const closeLocationListCreateModal = () => {
  return {
    type: types.CLOSE_LOCATION_LIST_CREATE_MODAL,
  };
};

export const openLocationListEditModal = (data) => {
  return {
    type: types.OPEN_LOCATION_LIST_EDIT_MODAL,
    data,
  };
};

export const closeLocationListEditModal = () => {
  return {
    type: types.CLOSE_LOCATION_LIST_EDIT_MODAL,
  };
};

/**
 * Get location lists
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationLists() {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_LOCATION_LISTS,
      location_id: location.id,
    };
    dispatch(getLocationLists(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_lists?limit=100`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get location lists' });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = location.id;
        dispatch(successGetLocationLists(body.data));
        dispatch(doFindLocationList(body.data));
      })
      .catch((error) => {
        dispatch(errorGetLocationLists(error));
        trackFetchError(error);
      });
  };
}

export function doFindLocationList(data) {
  return (dispatch, getState) => {
    let { locationLists } = getState();
    let openListId = locationLists.open.id;

    let nullPriorityLists = data.filter((l) => l.priority === null);
    let priorityLists = data.filter((l) => l.priority !== null);

    let sortedData = priorityLists
      .sort((a, b) => a.priority - b.priority)
      .concat(nullPriorityLists);

    if (sortedData.length) {
      dispatch(
        openLocationList(
          sortedData.filter((ll) => ll.id === openListId)[0] || sortedData[0]
        )
      );
      dispatch(doGetLocationListProducts({}));
    }
  };
}

/**
 * Get location list
 *
 * @access public
 * @returns {Promise}
 */
export function doGetLocationList(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(getLocationList(data));
    const { locations } = getState();
    const location = locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/location_lists/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get location list ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successGetLocationList(
            Object.assign(body, { _request: data._request })
          )
        );
      })
      .catch((error) => {
        dispatch(errorGetLocationList(error));
        trackFetchError(error);
      });
  };
}

/**
 * Create location_list
 *
 * @access public
 * @returns {Promise}
 */
export function submitLocationList(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(postCreateLocationList(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/location_lists`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Create location list' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateLocationList(
            Object.assign(body, { _request: data._request })
          )
        );
        dispatch(openLocationList(body));
        trackLocationListCreated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateLocationList(error));
        trackFetchError(error);
      });
  };
}

/**
 * Update location_list
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateLocationList(data) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(postUpdateLocationList(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/location_lists/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update location list ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successUpdateLocationList(
            Object.assign(body, { _request: data._request })
          )
        );
        trackLocationListUpdated(body);
      })
      .catch((error) => {
        dispatch(errorUpdateLocationList(error));
        trackFetchError(error);
      });
  };
}

/**
 * Delete location_list
 *
 * @access public
 * @returns {Promise}
 */
export function submitDeleteLocationList(data) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(postDeleteLocationList(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/location_lists/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': requestID(),
        },
      }
    )
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data,
            message: 'Delete location list ' + data.id,
          });
        }
      })
      .then(() => {
        dispatch(successDeleteLocationList(data));
        dispatch(closeLocationListEditModal());
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Your list has been removed',
          })
        );
      })
      .catch((error) => {
        dispatch(errorDeleteLocationList(error));
        trackFetchError(error);
      });
  };
}
