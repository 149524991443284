import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { formatCentAmount } from '../../lib/formatters.js';
import InsightsPdfLocationsData from './insights-pdf-locations-data.js';

const reduceBudgetAnnual = (acc, loc) => {
  return acc + loc.budget_annual;
};

export class InsightsPdf extends React.PureComponent {
  displaySpendDataHeader = () => {
    let totalLocationsCount = this.props.allLocations.length;
    let openLocationsCount = this.props.locations.length;
    if (openLocationsCount === totalLocationsCount) {
      return 'Viewing All Locations';
    } else {
      return `Viewing ${openLocationsCount} Locations`;
    }
  };

  renderPdfChart = (location) => {
    return (
      <InsightsPdfLocationsData
        key={location.id}
        openLocation={location}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
      />
    );
  };

  render() {
    return (
      <div className="insights-pdf">
        <div className="insight-data print-pdf">
          {this.props.locations.map(this.renderPdfChart)}
        </div>

        {this.props.locations.length > 1 && (
          <div className="insights-pdf-locations-data">
            <h3 className="multiple-locations-header">
              {this.displaySpendDataHeader()}
            </h3>
            <div className="insights-data-row">
              <p className="header bold-text">Total Annual Budget</p>
              <p className="data">
                {formatCentAmount(
                  this.props.locations.reduce(reduceBudgetAnnual, 0)
                )}
              </p>
            </div>
            <div className="insights-data-row">
              <p className="header bold-text">Total Employee Count</p>
              <p className="data">
                {this.props.numberOfEmployees.toLocaleString()}
              </p>
            </div>
            <div className="insights-data-row">
              <p className="header bold-text">Total Locations Spend</p>
              <p className="data">{formatCentAmount(this.props.totalSpend)}</p>
            </div>
            <div className="insights-data-row">
              <p className="header bold-text">Total Spend Per Employee</p>
              <p className="data">
                {formatCentAmount(
                  this.props.totalSpend / this.props.numberOfEmployees
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

InsightsPdf.propTypes = {
  allLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      budget_annual: PropTypes.number,
    })
  ),
  numberOfEmployees: PropTypes.number.isRequired,
  totalSpend: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

function mapStateToProps(state) {
  return {
    allLocations: Object.values(state.locations.items),
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return prev.locations.items === next.locations.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsPdf);
