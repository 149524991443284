import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import Modal from 'react-modal';
import ExpandableTextDefault from '../text/expandable-text-default.js';
import TextWithAnchors from '../text/text-with-anchors.js';
import ItemImage from '../products/item-image.js';
import ShippingIcon from '../../imgs/truck-speed.svg';
import ModalProductLocationListForm from './modal-product-location-list-form.js';
import ModalProductRecurringReqForm from './modal-product-recurring-req-form.js';
import ModalProductReqForm from './modal-product-req-form.js';
import ModalProductSimilars from './modal-product-similars.js';
import VendorMinimumIcon from '../products/vendor-minimum-icon.js';
import ProductStockNotify from '../products/product-stock-notify.js';
import {
  formatUnitOfPurchase,
  formatPricePerUnitOfPurchase,
  formatCentAmount,
  formatDate,
  formatStockStatus,
  formatUnitOfMeasure,
} from '../../lib/formatters.js';
import {
  closeProductModal,
  doGetLocationCachedProduct,
} from '../../actions/product-actions.js';
import {
  hasNextDayShipping,
  getProductVendorForMinimums,
  getProductPriorityVendor,
} from '../../helpers/vendor-helpers.js';
import { PRODUCT_MODAL_TABS } from '../../strings.js';
import { trackInterfaceOpenProductModalTab } from '../../lib/analytics.js';
import { PRODUCT_IS_IN_STOCK_AT, PRODUCT_IS_IN_STOCK } from '../../flags.js';
import './modal-common.css';
import './modal-product.css';

export class ModalProduct extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.initialProductModalTab || PRODUCT_MODAL_TABS.TAB_CART,
      imageZoom: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product.id && !nextProps.product.name) {
      nextProps.actions.doGetLocationCachedProduct({
        location: nextProps.location.id,
        product: nextProps.product.id,
      });
    }
    if (
      this.props.initialProductModalTab !== nextProps.initialProductModalTab
    ) {
      this.setState((prev) => {
        return {
          activeTab: this.determineActiveTab(nextProps, prev),
        };
      });
    }
  }

  determineActiveTab = (nextProps, state) => {
    if (nextProps.initialProductModalTab)
      return nextProps.initialProductModalTab;

    if (nextProps.open_scheduled_requisition.id) {
      return PRODUCT_MODAL_TABS.TAB_SCHEDULED;
    } else if (nextProps.product.id === this.props.product.id) {
      return state.activeTab;
    }
    return PRODUCT_MODAL_TABS.TAB_CART;
  };

  formatUnitData = () => {
    const purchase = formatUnitOfPurchase(
      this.props.product.unit_purchase,
      this.props.product.stock_per_purchase,
      this.props.product.unit_stock
    );
    if (
      !this.props.product.measure_per_stock ||
      this.props.product.measure_per_stock === 1
    ) {
      return purchase;
    }
    const measure = formatUnitOfMeasure(
      this.props.product.unit_measure,
      this.props.product.measure_per_stock
    );
    let each = '';
    if (
      this.props.product.stock_per_purchase !== 1 &&
      this.props.product.unit_measure !== 'ea'
    ) {
      each += 'each';
    }
    return `${purchase}, ${measure} ${each}`;
  };

  formatPricePerUnitData = () => {
    if (this.props.product.unit_stock === this.props.product.unit_purchase) {
      return;
    }
    return (
      '(' +
      formatPricePerUnitOfPurchase(
        this.props.product.price,
        this.props.product.stock_per_purchase,
        this.props.product.unit_stock
      ) +
      ')'
    );
  };

  onRequestClose = () => {
    this.props.actions.closeProductModal();
    this.setState(() => {
      return {
        activeTab:
          this.props.initialProductModalTab || PRODUCT_MODAL_TABS.TAB_CART,
        imageZoom: false,
      };
    });
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleAddToCart = () => {
    this.onRequestClose();
  };

  handleClickTab = (activeTab) => {
    this.setState(() => {
      return { activeTab };
    });
    trackInterfaceOpenProductModalTab(activeTab);
  };

  handleClickImage = () => {
    this.setState((prevState) => {
      return {
        imageZoom: !prevState.imageZoom,
      };
    });
  };

  render() {
    if (
      !this.props.product ||
      !this.props.product.id ||
      !this.props.product.name
    ) {
      return null;
    }
    const inStock = PRODUCT_IS_IN_STOCK(this.props.product);
    const inStockAt = PRODUCT_IS_IN_STOCK_AT(this.props.product);
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!this.props.product.id}
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.onAfterOpen}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Product Modal"
      >
        <div className="modal-product">
          <button className="close-icon" onClick={this.handleCloseModal}>
            &times;
          </button>
          <div className="modal-product-main-content">
            <div className="product-brand">{this.props.product.brand}</div>
            <h4 className="product-name">{this.props.product.name}</h4>
          </div>
          <div
            className={classnames('modal-product-main-content flex-wrapper', {
              modal_product_image_zoom: this.state.imageZoom,
            })}
          >
            <div className="product-details">
              <div className="product-unit-data">
                {this.formatUnitData()}&nbsp;
              </div>
              <div className="">
                <div className="product-price">
                  {formatCentAmount(this.props.product.price)}
                </div>
                <em className="gray-text product-price-unit">
                  {this.formatPricePerUnitData()}
                </em>
              </div>
              <div className="product-description">
                {this.props.product.description.length > 300 && (
                  <ExpandableTextDefault
                    text={this.props.product.description}
                  />
                )}
                {this.props.product.description.length <= 300 && (
                  <TextWithAnchors text={this.props.product.description} />
                )}
              </div>
              {hasNextDayShipping(this.props.vendor) && (
                <div
                  className="product-shipping"
                  title="Delivery in one business day"
                >
                  <img
                    src={ShippingIcon}
                    height={30}
                    width={30}
                    className="shipping-time-icon"
                    title="Delivery in one business day"
                    alt="Delivery in one business day"
                  />
                  <div className="product-shipping-detail">
                    Delivery in One Business Day
                    <div className="gray-text">When Ordering Before 2pm</div>
                  </div>
                </div>
              )}
              <VendorMinimumIcon
                vendor={this.props.vendor}
                detailView={true}
                detailSize={30}
              />
              {!inStock && (
                <Fragment>
                  <div className="product-shipping">
                    <div className="light-gray-text">
                      {formatStockStatus(this.props.product)}
                    </div>
                    {inStockAt && (
                      <div className="light-gray-text">
                        (Available {formatDate(inStockAt)})
                      </div>
                    )}
                  </div>
                  <ProductStockNotify product={this.props.product} />
                </Fragment>
              )}
            </div>
            <div
              className="product-image"
              role="button"
              onClick={this.handleClickImage}
            >
              <ItemImage
                title={this.props.product.name}
                alt={this.props.product.name}
                product={this.props.product}
                size={'original'}
              />
            </div>
          </div>
          <div className="product-actions-tabs">
            <button
              className={classnames('product-actions-tab', {
                active: this.state.activeTab === PRODUCT_MODAL_TABS.TAB_CART,
              })}
              onClick={this.handleClickTab.bind(
                this,
                PRODUCT_MODAL_TABS.TAB_CART
              )}
            >
              Order Now
            </button>
            <button
              className={classnames('product-actions-tab', {
                active: this.state.activeTab === PRODUCT_MODAL_TABS.TAB_STOCK,
              })}
              onClick={this.handleClickTab.bind(
                this,
                PRODUCT_MODAL_TABS.TAB_STOCK
              )}
            >
              {this.props.canRecordSupplyLevels && 'Inventory'}
              {!this.props.canRecordSupplyLevels && 'Favorites'}
            </button>
            {this.props.canScheduleOrders && (
              <button
                className={classnames('product-actions-tab', {
                  active:
                    this.state.activeTab === PRODUCT_MODAL_TABS.TAB_SCHEDULED,
                })}
                onClick={this.handleClickTab.bind(
                  this,
                  PRODUCT_MODAL_TABS.TAB_SCHEDULED
                )}
              >
                Recurring Orders
              </button>
            )}
            <button
              className={classnames('product-actions-tab', {
                active:
                  this.state.activeTab === PRODUCT_MODAL_TABS.TAB_SIMILARS,
              })}
              onClick={this.handleClickTab.bind(
                this,
                PRODUCT_MODAL_TABS.TAB_SIMILARS
              )}
            >
              Recommended
            </button>
          </div>
          <div className="product-actions">
            {this.state.activeTab === PRODUCT_MODAL_TABS.TAB_CART && (
              <ModalProductReqForm product={this.props.product} />
            )}
            {this.state.activeTab === PRODUCT_MODAL_TABS.TAB_STOCK && (
              <ModalProductLocationListForm product={this.props.product} />
            )}
            {this.state.activeTab === PRODUCT_MODAL_TABS.TAB_SCHEDULED && (
              <ModalProductRecurringReqForm product={this.props.product} />
            )}
            {this.state.activeTab === PRODUCT_MODAL_TABS.TAB_SIMILARS && (
              <ModalProductSimilars product={this.props.product} />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalProduct.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    unit_purchase: PropTypes.string,
    stock_per_purchase: PropTypes.number,
    unit_stock: PropTypes.string,
    brand: PropTypes.string,
    last_submitted_date: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    closeProductModal: PropTypes.func.isRequired,
    doGetLocationCachedProduct: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  open_scheduled_requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  canScheduleOrders: PropTypes.bool,
  canRecordSupplyLevels: PropTypes.bool,
  initialProductModalTab: PropTypes.oneOf(Object.keys(PRODUCT_MODAL_TABS)),
};

function mapStateToProps(state) {
  let vendor = getProductVendorForMinimums(
    state.products.open,
    state.carts.open
  );
  if (state.scheduledRequisitions.open.id) {
    vendor = getProductPriorityVendor(state.products.open);
  }
  return {
    vendor,
    initialProductModalTab: state.products.initialProductModalTab,
    product: state.products.open,
    location: state.locations.open,
    open_scheduled_requisition: state.scheduledRequisitions.open,
    canScheduleOrders: state.locations.open.pref_enable_scheduled_requisitions,
    canRecordSupplyLevels:
      state.locations.open.pref_enable_location_list_stock_levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeProductModal,
        doGetLocationCachedProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.products.open === next.products.open &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.products.initialProductModalTab ===
      next.products.initialProductModalTab &&
    prev.locations.open === next.locations.open &&
    prev.carts.open === next.carts.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProduct);
