import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackCustomerBudgetCodeCreated,
  trackCustomerBudgetCodeUpdated,
} from '../lib/analytics.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import { fetchError, requestID } from './action-helpers.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

export const getCustomerBudgetCodes = (data) => {
  return {
    type: types.GET_CUSTOMER_BUDGET_CODES,
    data,
  };
};

export const successGetCustomerBudgetCodes = (data) => {
  return {
    type: types.SUCCESS_GET_CUSTOMER_BUDGET_CODES,
    data,
  };
};

export const errorGetCustomerBudgetCodes = (data) => {
  return {
    type: types.ERROR_GET_CUSTOMER_BUDGET_CODES,
    data,
  };
};

export const getCustomerBudgetCode = (data) => {
  return {
    type: types.GET_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const successGetCustomerBudgetCode = (data) => {
  return {
    type: types.SUCCESS_GET_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const errorGetCustomerBudgetCode = (data) => {
  return {
    type: types.ERROR_GET_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const postCreateCustomerBudgetCode = (data) => {
  return {
    type: types.CREATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const successCreateCustomerBudgetCode = (data) => {
  return {
    type: types.SUCCESS_CREATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const errorCreateCustomerBudgetCode = (data) => {
  return {
    type: types.ERROR_CREATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const postUpdateCustomerBudgetCode = (data) => {
  return {
    type: types.UPDATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const successUpdateCustomerBudgetCode = (data) => {
  return {
    type: types.SUCCESS_UPDATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const errorUpdateCustomerBudgetCode = (data) => {
  return {
    type: types.ERROR_UPDATE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const openCustomerBudgetCode = (data) => {
  return {
    type: types.OPEN_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const closeCustomerBudgetCode = (data) => {
  return {
    type: types.CLOSE_CUSTOMER_BUDGET_CODE,
    data,
  };
};

export const openCustomerBudgetCodeCreateModal = () => {
  return {
    type: types.OPEN_CUSTOMER_BUDGET_CODE_CREATE_MODAL,
  };
};

export const closeCustomerBudgetCodeCreateModal = () => {
  return {
    type: types.CLOSE_CUSTOMER_BUDGET_CODE_CREATE_MODAL,
  };
};

/**
 * Get CustomerBudgetCodes
 *
 * @access public
 * @returns {Promise}
 */
export function doGetCustomerBudgetCodes(opt = {}) {
  return (dispatch, getState) => {
    const { customers } = getState();
    const customer = customers.open;
    const data = {
      _request: types.GET_CUSTOMER_BUDGET_CODES,
      customer_id: opt.id || customer.id,
    };
    dispatch(getCustomerBudgetCodes(data));
    const path =
      opt.paged ||
      `/customers/${data.customer_id}/customer_budget_codes?limit=1000`;
    return fetch(`${ADMIN_V1_URL}${path}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get customer budget codes',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetCustomerBudgetCodes(body.data));
        if (body.meta && body.meta.next_cursor) {
          body.data.customer_id = data.customer_id;
          dispatch(
            doGetCustomerBudgetCodes({
              paged: `/customers/${data.customer_id}/customer_budget_codes?cursor=${body.meta.next_cursor}&limit=1000`,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(errorGetCustomerBudgetCodes(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get CustomerBudgetCode
 *
 * @access public
 * @returns {Promise}
 */
export function doGetCustomerBudgetCode(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { customers } = getState();
    const customer = customers.open;
    dispatch(getCustomerBudgetCode(data));
    return fetch(
      `${ADMIN_V1_URL}/customers/${customer.id}/customer_budget_codes/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get customer budget code ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetCustomerBudgetCode(body));
      })
      .catch((error) => {
        dispatch(errorGetCustomerBudgetCode(error));
        trackFetchError(error);
      });
  };
}

/**
 * Create CustomerBudgetCode
 *
 * @access public
 * @returns {Promise}
 */
export function submitCustomerBudgetCode(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postCreateCustomerBudgetCode(data));
    const { customers, locations } = getState();
    const customerID = locations.open.customer_id || customers.open.id;
    return fetch(
      `${ADMIN_V1_URL}/customers/${customerID}/customer_budget_codes`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create customer budget code',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateCustomerBudgetCode(
            Object.assign(body, { _request: data._request })
          )
        );
        trackCustomerBudgetCodeCreated(body);
        if (options.bubble) {
          return body;
        }
      })
      .catch((error) => {
        dispatch(errorCreateCustomerBudgetCode(error));
        trackFetchError(error);
      });
  };
}

/**
 * Update CustomerBudgetCode
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateCustomerBudgetCode(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { customers } = getState();
    const customer = customers.open;
    dispatch(postUpdateCustomerBudgetCode(data));
    return fetch(
      `${ADMIN_V1_URL}/customers/${customer.id}/customer_budget_codes/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update customer budget code ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateCustomerBudgetCode(body, data));
        trackCustomerBudgetCodeUpdated(body);
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Updated Category: ' + body.name,
          })
        );
        if (options.bubble) {
          return body;
        }
      })
      .catch((error) => {
        dispatch(errorUpdateCustomerBudgetCode(error));
        trackFetchError(error);
        if (options.bubble) {
          throw error;
        }
      });
  };
}
