import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Icon } from 'semantic-ui-react';
import classnames from 'classnames';
import { doSwitchLocation } from '../../actions/locations-actions.js';
import { compareNameAsc } from '../../lib/formatters.js';
import './nav-location-switch.css';
import 'semantic-ui-css/components/accordion.min.css';

export class NavLocationSwitch extends React.PureComponent {
  state = { activeIndex: null };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.expanded && !newProps.expanded) {
      this.setState({ activeIndex: null });
    }
  }

  handleToggle = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSelect = (location) => {
    this.props.actions.doSwitchLocation(location);
    this.setState({ activeIndex: null });
  };

  renderLocations() {
    return this.props.locations
      .filter((loc) => loc.id !== this.props.openLocation.id)
      .sort(compareNameAsc)
      .map((loc) => (
        <button
          key={loc.id}
          className="nav-location-switch__item"
          onClick={() => this.handleSelect(loc)}
        >
          <div className="nav-location-switch__name">{loc.name}</div>
          <div className="nav-location-switch__address">{loc.address}</div>
        </button>
      ));
  }

  renderCollapsed() {
    return (
      <div
        className="nav-location-switch--collapsed"
        onMouseEnter={this.props.onMouseEnter}
      >
        <Icon name="map marker alternate" />
      </div>
    );
  }

  renderExpanded() {
    const { activeIndex } = this.state;
    return (
      <Accordion className="nav-location-switch">
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={
            this.props.locations.length > 1 ? this.handleToggle : () => {}
          }
          className="nav-location-switch__title"
        >
          <div>
            <div className="nav-location-switch__name">
              {this.props.openLocation.name}
            </div>
            <div className="nav-location-switch__address">
              {this.props.openLocation.address}
            </div>
          </div>
          {this.props.locations.length > 1 && (
            <Icon name={activeIndex === 0 ? 'caret down' : 'caret left'} />
          )}
        </Accordion.Title>
        <Accordion.Content
          className="nav-location-switch__content"
          active={activeIndex === 0}
        >
          {this.renderLocations()}
        </Accordion.Content>
      </Accordion>
    );
  }

  render() {
    return (
      <div
        className={classnames('nav-location-switch__wrap', {
          expanded: this.props.expanded,
        })}
      >
        {!this.props.expanded && this.renderCollapsed()}
        {this.props.expanded && this.renderExpanded()}
      </div>
    );
  }
}

NavLocationSwitch.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
};

NavLocationSwitch.defaultProps = {
  onMouseEnter: () => {},
};

export function mapStateToProps(state) {
  return {
    locations: Object.values(state.locations.items),
    openLocation: state.locations.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doSwitchLocation,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.locations.items === next.locations.items &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(NavLocationSwitch);
