import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { ADMIN_V1_URL } from '../strings.js';

// Actions
export const GET_BANNER_ITEMS = 'GET_BANNER_ITEMS';
export const SUCCESS_GET_BANNER_ITEMS = 'SUCCESS_GET_BANNER_ITEMS';
export const ERROR_GET_BANNER_ITEMS = 'ERROR_GET_BANNER_ITEMS';
export const UNSET_BANNER_ITEM_RESPONSE = 'UNSET_BANNER_ITEM_RESPONSE';

// Action Creators
export const getBannerItems = (data) => ({ data, type: GET_BANNER_ITEMS });
export const successGetBannerItems = (data) => ({
  data,
  type: SUCCESS_GET_BANNER_ITEMS,
});
export const errorGetBannerItems = (data) => ({
  data,
  type: ERROR_GET_BANNER_ITEMS,
});
export const unsetBannerItemResponse = (data) => ({
  data,
  type: UNSET_BANNER_ITEM_RESPONSE,
});

export const empty = {
  initialized: false,
  items: [],
  requesting: [],
  responses: [],
};

export const initial = {
  ...empty,
  ...{},
};

export default (state = initial, action) => {
  switch (action.type) {
    case GET_BANNER_ITEMS:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_BANNER_ITEMS:
      return {
        ...state,
        items: action.data.items,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case ERROR_GET_BANNER_ITEMS:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_BANNER_ITEM_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    default:
      return state;
  }
};

// thunks
export const ERROR_TIMEOUT = 5000;
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetBannerItemResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetBannerItems = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getBannerItems(data));
    let url = `${ADMIN_V1_URL}/banner_items`;
    if (window.location.search.match(/preview=true/)) {
      url += '?preview=true';
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get banner items' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetBannerItems)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetBannerItems)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
