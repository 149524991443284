import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import { formatDateTime } from '../../lib/formatters.js';
import { Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const EditStatus = (props) => {
  const sr = props.scheduledRequisition;
  if (!sr || !sr.id) return null;
  if (helpers.isExpired(sr)) return null;
  if (helpers.isSource(sr)) return null;
  if (helpers.isApproved(sr)) return null;
  if (!helpers.isApproved(sr)) return null;

  return (
    <Message>
      This order is editable until {formatDateTime(sr.expiration_at)}
      &nbsp;&nbsp;
      {props.showEditLink && (
        <Link to={`/orders/scheduled/${sr.id}/edit?back_to_approval=true`}>
          Edit Order &raquo;
        </Link>
      )}
    </Message>
  );
};

EditStatus.propTypes = {
  scheduledRequisition: PropTypes.object,
  showEditLink: PropTypes.bool,
};

export default EditStatus;
