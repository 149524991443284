import React from 'react';
import { connect } from 'react-redux';
import AuthCheck from '../components/auth-check.js';
import CleaningsEmpty from '../components/cleanings/empty-all.js';
import ServiceSchedule from '../components/cleanings/service-schedule.js';
import setTitle from '../decorators/set-title.js';
import { Segment } from 'semantic-ui-react';
import './office-tasks.css';

export class OfficeAttendantScheduleRoute extends React.PureComponent {
  render() {
    return (
      <div className="office-attendant-cleaning-schedule">
        <AuthCheck />
        <Segment basic className="service-schedule">
          {!this.props.deputyId && this.props.locationAddress && (
            <div className="variable-width-column">
              <CleaningsEmpty />
            </div>
          )}
          {this.props.deputyId && <ServiceSchedule id={this.props.deputyId} />}
        </Segment>
      </div>
    );
  }
}

OfficeAttendantScheduleRoute = setTitle((props) => {
  return 'Office Attendant Schedule';
})(OfficeAttendantScheduleRoute);

export function mapStateToProps(state) {
  return {
    deputyId: state.locations.open.deputy_id,
    locationAddress: state.locations.open.address,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.cleanings.items === next.cleanings.items &&
    prev.cleanings.isRequesting === next.cleanings.isRequesting &&
    prev.cleanings.initialized === next.cleanings.initialized &&
    prev.locations.open.services === next.locations.open.services
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  OfficeAttendantScheduleRoute
);
