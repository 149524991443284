import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import {
  setProductQuery,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions';

const SuggestedSearches = (props) => {
  const performSearch = (category) => {
    props.actions.setProductQuery({
      filters: {
        text: '',
        categoryIds: [category.id],
        brandId: null,
        brandNames: [],
        vendorIds: [],
        maxShippingTimes: [],
      },
    });
    props.actions.doGetLocationProductQueryResults();
  };

  const renderSuggestion = (category) => (
    <Button
      secondary
      size="mini"
      style={{ marginBottom: '0.25rem' }}
      key={category.id}
      type="button"
      onClick={() => performSearch(category)}
    >
      Search all {category.name}
    </Button>
  );

  return props.resultCategories.map(renderSuggestion);
};

SuggestedSearches.propTypes = {
  resultCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
};

function words(input) {
  return input.split(/\s/).filter((a) => a.match(/[a-z]+/i));
}

function mapStateToProps(state) {
  const searchWordRegex = new RegExp(
    words(state.productQuery.resultsQuery).join('|'),
    'i'
  );
  let resultCategories = [];
  if ((state.productQuery.resultsQuery || '').length) {
    resultCategories = state.productCategories.items
      .filter((item) => {
        return (
          words(item.name).filter((name) => name.match(searchWordRegex))
            .length > 0
        );
      })
      .slice(0, 5);
  }
  return {
    resultCategories,
    isRequesting:
      state.productQuery.isRequesting || state.locations.requesting.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productQuery.resultsQuery === next.productQuery.resultsQuery &&
    prev.productCategories.items === next.productCategories.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SuggestedSearches);
