export function hasSeenFeatureTour(name, tours, attemptSummary) {
  let tour = Object.values(tours).filter((item) => item.name === name)[0];
  if (!tour) return true;
  let tourSummary = attemptSummary[tour.id];
  if (!tourSummary) return false;
  if (tourSummary.completed_at) return true;
  if (!tourSummary.skipped_at) return false;
  let lastWeek = new Date();
  lastWeek = new Date(lastWeek.getTime() - 1000 * 60 * 60 * 24 * 7);
  if (tourSummary.skipped_at < lastWeek && tourSummary.attempts < 2) {
    return false;
  }
  return true;
}
