import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Segment, Button, Header } from 'semantic-ui-react';
import ApproverData from './approver-data.js';
import { formatDate, formatDateTime } from '../../lib/formatters.js';
import { submitUpdateScheduledRequisition } from '../../actions/scheduled-requisition-actions.js';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './scheduled-requisitions.css';

export class ScheduledRequisitionItem extends React.PureComponent {
  formatLink = () => {
    const { scheduledRequisition } = this.props;
    if (scheduledRequisition.requisition_id) {
      return '/supplies/orders/' + scheduledRequisition.requisition_id;
    }
    if (helpers.willBeApproved(scheduledRequisition)) {
      return `/orders/scheduled/${scheduledRequisition.id}`;
    } else if (!helpers.isApproved(scheduledRequisition)) {
      return `/orders/scheduled/${scheduledRequisition.id}/approve`;
    } else {
      return `/orders/scheduled/${scheduledRequisition.id}`;
    }
  };

  formatLinkText = () => {
    const { scheduledRequisition } = this.props;
    if (scheduledRequisition.requisition_id) {
      return 'View Order';
    }
    if (helpers.willBeApproved(scheduledRequisition)) {
      return 'View Draft Order';
    } else if (!helpers.isApproved(scheduledRequisition)) {
      return `Approve this Draft by ${formatDateTime(
        scheduledRequisition.expiration_at
      )}`;
    } else {
      return 'View Pending Order';
    }
  };

  isRemovable = () => {
    const { scheduledRequisition } = this.props;
    return (
      !scheduledRequisition.requisition_id ||
      scheduledRequisition.requisition_id === 0
    );
  };

  confirmRemove = () => {
    if (
      window.confirm(
        'Do you really want to cancel this pending order? This cannot be undone.'
      )
    ) {
      this.remove();
    }
  };

  remove = () => {
    const { scheduledRequisition } = this.props;
    this.props.actions.submitUpdateScheduledRequisition({
      id: scheduledRequisition.id,
      archived: true,
    });
  };

  render() {
    const { scheduledRequisition } = this.props;
    const isApproved = helpers.isApproved(scheduledRequisition);
    const isExpired = helpers.isExpired(scheduledRequisition);
    const willBeApproved = helpers.willBeApproved(scheduledRequisition);
    const isOrdered = !!scheduledRequisition.requisition_id;
    return (
      <Segment attached="top" loading={this.props.isRequesting}>
        <Header>{scheduledRequisition.name}</Header>
        <p className="scheduled-requisition-expected-at">
          <span className="light-gray-text">Scheduled to arrive by</span>&nbsp;
          <strong className="dark-text">
            {formatDate(scheduledRequisition.expected_at)}
          </strong>
        </p>
        <div className="scheduled-requisition-subheader">
          {isExpired && !isApproved && (
            <div className="scheduled-requisition-expired">
              Cancelled {formatDateTime(scheduledRequisition.expiration_at)}
            </div>
          )}
          {(!isExpired || isOrdered) && (
            <Button
              secondary={willBeApproved || isApproved}
              primary={!willBeApproved && !isApproved}
              size="mini"
            >
              <Link to={this.formatLink()} className="edit">
                {this.formatLinkText()}
              </Link>
            </Button>
          )}
          {isApproved && !scheduledRequisition.approve_automatically && (
            <div className="scheduled-requisition-approver-data-container">
              <ApproverData scheduledRequisition={scheduledRequisition} />
            </div>
          )}
          {scheduledRequisition.approve_automatically && (
            <div className="scheduled-requisition-approver-data-container">
              <div className="approver-data flex-wrapper">
                <div className="approved-at-data-container flex-item-1">
                  <p className="approved-at-details-container">
                    <span className="light-gray-text">
                      Will Be Automatically Ordered
                    </span>
                    &nbsp;
                    <strong className="dark-text">
                      {formatDateTime(scheduledRequisition.expiration_at)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.isRemovable() && (
          <div className="scheduled-requisition-remove">
            <span className="light-gray-text" onClick={this.confirmRemove}>
              Cancel
            </span>
          </div>
        )}
      </Segment>
    );
  }
}

ScheduledRequisitionItem.propTypes = {
  scheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expected_at: PropTypes.string.isRequired,
    expiration_at: PropTypes.string.isRequired,
    requisition_id: PropTypes.number,
    approved_at: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

ScheduledRequisitionItem.defaultProps = {
  isRequesting: true,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateScheduledRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  ScheduledRequisitionItem
);
