import React, { Fragment } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { formatEmployeeName, formatCentAmount } from '../../lib/formatters.js';
import './item-delivery-info.css';

const ItemBillingInfo = (props) => {
  return (
    <div className="requisition-billing hide-when-printing">
      <h4
        className="requisition-billing-title"
        style={{ marginBottom: '1rem' }}
      >
        Billing
      </h4>
      {props.item.requester_purchase_price > 0 && (
        <Fragment>
          <div>
            {formatCentAmount(props.item.requester_purchase_price)} billed to{' '}
            {formatEmployeeName(props.item.requester)}
          </div>
        </Fragment>
      )}
      {props.item.requester_purchase_price !== props.item.total_price && (
        <Fragment>
          <p className="billing-account-name">
            {formatCentAmount(
              props.item.total_price - props.item.requester_purchase_price
            )}{' '}
            billed to office
          </p>
          {props.item.invoices &&
            props.item.invoices.length > 0 &&
            props.role.can_manage_billing && (
              <List>
                {props.item.invoices.map((invoice) => (
                  <List.Item>
                    Part of&nbsp;
                    <Link
                      className="primary"
                      to={`/company/invoices?invoice=${invoice.id}`}
                    >
                      Invoice #{invoice.invoice_number}
                    </Link>
                  </List.Item>
                ))}
              </List>
            )}
          {props.role.can_manage_billing && (
            <Link className="primary" to="/company/invoices">
              View All Invoices &raquo;
            </Link>
          )}
        </Fragment>
      )}
    </div>
  );
};

ItemBillingInfo.propTypes = {
  role: PropTypes.shape({
    can_manage_billing: PropTypes.bool.isRequired,
  }),
  item: PropTypes.shape({
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        invoice_number: PropTypes.string.isRequired,
      })
    ),
    requester: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
    requester_purchase_price: PropTypes.number.isRequired,
    total_price: PropTypes.number.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

const areStatesEqual = (prev, next) => {
  return prev.auth.role === next.auth.role;
};

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  ItemBillingInfo
);
