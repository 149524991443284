export default function logger(prefix) {
  if (!window.ODEBUG || window.expect)
    return {
      log: () => {},
      error: () => {},
      info: () => {},
      warn: () => {},
    };
  const prefixP = (prefix + '          ').substring(0, 10);
  return {
    log: console.log.bind(console, prefixP),
    error: console.error.bind(console, prefixP),
    info: console.info.bind(console, prefixP),
    warn: console.warn.bind(console, prefixP),
  };
}
