import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import AuthCheck from '../components/auth-check.js';
import CalendarComponent from '../components/calendar/calendar-component.js';
import CalendarAgenda from '../components/calendar/calendar-agenda.js';
import { setSelectedDate } from '../actions/calendar-view-actions.js';
import {
  trackCalendarDayClick,
  trackRevertToTimeline,
} from '../lib/analytics.js';
import cookies from '../lib/encrypted-cookies.js';
import { TIMELINE_VIEW_COOKIE } from '../strings.js';
import './calendar-view.css';

export class CalendarView extends React.PureComponent {
  onDateSelect = (date) => {
    this.props.actions.setSelectedDate(date.toDateString());
    trackCalendarDayClick();
  };

  date() {
    return new Date(this.props.selectedDate);
  }

  setTimelineCookie = () => {
    cookies.set(TIMELINE_VIEW_COOKIE, { view: 'timeline' }, { expires: 60 });
    trackRevertToTimeline();
  };

  componentDidMount() {
    window.totango.track('Viewed Recurring Orders.', 'Marketplace');
  }

  render() {
    return (
      <div className="variable-width-column calendar-view">
        <AuthCheck permissions={{ can_manage_all_requisitions: true }} />
        <div className="calendar-view__container">
          <div className="calendar-view__left">
            <CalendarAgenda
              selectedDate={this.date()}
              onDateSelect={this.onDateSelect}
            />
          </div>
          <div className="calendar-view__right">
            <Header
              as="h4"
              style={{
                marginTop: '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <span>Recurring Order Arrivals</span>
              <Link
                className="primary"
                to="/orders/scheduled/"
                onClick={this.setTimelineCookie}>
                &laquo; Revert to Timeline
              </Link>
            </Header>
            <CalendarComponent
              selectedDate={this.date()}
              onDateSelect={this.onDateSelect}
            />
          </div>
        </div>
      </div>
    );
  }
}

CalendarView.propTypes = {
  selectedDate: PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  return {
    selectedDate: state.calendarView.selectedDate,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setSelectedDate,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return prev.calendarView.selectedDate === next.calendarView.selectedDate;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CalendarView);
