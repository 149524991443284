import * as types from './action-types.js';
import { populateOptimizedProductRequisitions } from '../helpers/cart-helpers.js';

export const openScheduledProductRequisition = (data) => {
  return {
    type: types.OPEN_SCHEDULED_PRODUCT_REQUISITION,
    data,
  };
};

export const closeScheduledProductRequisition = (data) => {
  return {
    type: types.CLOSE_SCHEDULED_PRODUCT_REQUISITION,
    data,
  };
};

/**
 * Stage scheduled product requisition
 *
 * @access public
 * @returns {Promise}
 */
export function updateStagedScheduledProductRequisition(data) {
  return (dispatch, getState) => {
    const staged = getState().scheduledRequisitions.staged[0];
    if (!staged) return;

    let stagedSprs = staged.scheduled_product_requisitions.slice();

    const filtered = stagedSprs.filter((pr) => {
      return pr.product_id === data.product_id;
    });
    const foundSpr = filtered[0];
    let sprResult;

    if (!foundSpr) {
      sprResult = [data].concat(stagedSprs);
    } else {
      sprResult = stagedSprs.map((spr) => {
        if (spr.product_id === data.product_id) {
          return { ...data, id: spr.id };
        } else {
          return spr;
        }
      });
    }
    const result = { ...staged };
    result.scheduled_product_requisitions = sprResult;

    return dispatch({
      type: types.UPDATE_STAGED_SCHEDULED_REQUISITION,
      data: [populateOptimizedProductRequisitions(result)],
    });
  };
}
