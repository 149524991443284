import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table, Icon } from 'semantic-ui-react';
import ItemImage from '../products/item-image.js';
import TextWithAnchors from '../text/text-with-anchors.js';
import { showProductModal } from '../../actions/product-actions.js';
import { openShipment } from '../../ducks/shipments.js';
import {
  formatUnitOfPurchase,
  formatCentAmount,
  formatDate,
  formatTime,
} from '../../lib/formatters.js';
import { lastMarkedAs } from '../../helpers/shipment-helpers.js';
import { formattedInstructions } from '../../helpers/product-requisition-helpers.js';
import './order-product-row.css';

export class OrdersProductRow extends React.PureComponent {
  getStatus = () => {
    return (
      this.props.item.status ||
      (this.props.product_requisition || {}).status ||
      ''
    );
  };

  getPrice = () => {
    if (this.props.item.price || this.props.item.price === 0)
      return this.props.item.price;
    let pr = this.props.product_requisition || {};
    if (pr.price || pr.price === 0) return pr.price;
    return this.props.item.product.price;
  };

  getTax = () => {
    return (
      this.props.item.tax || (this.props.product_requisition || {}).tax || 0
    );
  };

  getTotalPrice = () => {
    return this.getPrice() * this.props.item.quantity + this.getTax();
  };

  handleClickImage = () => {
    this.props.actions.showProductModal(this.props.item.product);
  };

  openShipment = () => {
    this.props.actions.openShipment(this.props.shipment);
  };

  formatShipmentText = () => {
    const { shipment } = this.props;
    if (lastMarkedAs(shipment) === 'received') {
      return `Delivered ${formatDate(shipment.received_at)}`;
    }
    return `Expected ${formatDate(shipment.expected_at_end)} by ${formatTime(
      shipment.expected_at_end
    )}`;
  };

  displayShippingInformation = () => {
    const { shipment } = this.props;
    const { shipmentSummaries } = this.props.item;

    if (this.props.item.status === 'unavailable') {
      return (
        <p className="product-shipping gray-text">
          {' '}
          <i>Unavailable</i>{' '}
        </p>
      );
    }

    if (!this.props.showShippingDetails) {
      return (
        <p>
          <span className="product-unit-stock">
            {formatUnitOfPurchase(
              this.props.item.product.unit_purchase,
              this.props.item.product.stock_per_purchase,
              this.props.item.product.unit_stock
            )}
          </span>
        </p>
      );
    }

    if (shipment && shipment.expected_at_end) {
      return (
        <p className="product-shipping">
          <Button size="mini" className="tertiary" onClick={this.openShipment}>
            {this.formatShipmentText()}
          </Button>
        </p>
      );
    }

    if (
      shipmentSummaries &&
      shipmentSummaries.length > 0 &&
      shipmentSummaries[0].vendor_name
    ) {
      return (
        <p className="product-shipping gray-text">
          {' '}
          <i>Pending shipment from {shipmentSummaries[0].vendor_name}</i>
        </p>
      );
    }

    if (this.props.item.status === 'backordered') {
      return (
        <p className="product-shipping gray-text">
          {' '}
          <i>Backordered</i>{' '}
        </p>
      );
    }

    return (
      <p className="product-shipping gray-text">
        {' '}
        <i>Pending shipment update</i>{' '}
      </p>
    );
  };

  render() {
    return (
      <Table.Row key={this.props.item.id} className="order-row">
        <Table.Cell verticalAlign="top" width="1">
          <div className="product-image" onClick={this.handleClickImage}>
            <ItemImage
              width={42}
              height={42}
              size={'thumb'}
              product={this.props.item.product}
              title={this.props.item.product.name}
            />
          </div>
        </Table.Cell>
        <Table.Cell verticalAlign="top">
          <p className="product-name" onClick={this.handleClickImage}>
            {this.props.item.product.name}&nbsp;
            {this.props.showShippingDetails && (
              <em className="product-unit-stock">
                (
                {formatUnitOfPurchase(
                  this.props.item.product.unit_purchase,
                  this.props.item.product.stock_per_purchase,
                  this.props.item.product.unit_stock
                )}
                )
              </em>
            )}
          </p>
          {this.props.item.status === 'substituted' && (
            <p className="gray-text bold-text">
              <Icon name="repeat" size="small" />
              Replacement
            </p>
          )}
          {this.props.showInstructions && (
            <p className="custom-instructions gray-text">
              <TextWithAnchors text={formattedInstructions(this.props.item)} />
            </p>
          )}
          {this.displayShippingInformation()}
        </Table.Cell>
        {this.props.showBudget && (
          <Table.Cell verticalAlign="top">
            {this.props.item.customer_budget_code &&
              this.props.item.customer_budget_code.name}
          </Table.Cell>
        )}
        <Table.Cell width="2" textAlign="right" className="hide-mobile">
          {this.props.item.quantity}
        </Table.Cell>
        {this.props.showPrice && (
          <Table.Cell
            collapsing
            textAlign="right"
            className="hide-mobile"
            style={{ paddingLeft: 0, paddingRight: 0 }}>
            &times;
          </Table.Cell>
        )}
        {this.props.showPrice && (
          <Table.Cell width="1" textAlign="right" className="hide-mobile">
            {formatCentAmount(this.getPrice())}
          </Table.Cell>
        )}

        <Table.Cell textAlign="right" className="show-mobile">
          {this.props.item.quantity}{' '}
          {this.props.showPrice && (
            <span>&times; {formatCentAmount(this.getPrice())}</span>
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
}

OrdersProductRow.propTypes = {
  product_requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    status: PropTypes.string,
    price: PropTypes.number.isRequired,
  }),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    status: PropTypes.string,
    product_requisition_id: PropTypes.number,
    price: PropTypes.number,
    customer_budget_code: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
      sku_brand: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    showProductModal: PropTypes.func.isRequired,
  }).isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expected_at_end: PropTypes.string,
    received_at: PropTypes.string,
  }),
  showPrice: PropTypes.bool.isRequired,
  showShippingDetails: PropTypes.bool.isRequired,
  showInstructions: PropTypes.bool.isRequired,
};

OrdersProductRow.defaultProps = {
  showPrice: true,
  showBudget: true,
  showInstructions: false,
  showShippingDetails: true,
};

function mapStateToProps(state, props) {
  return {
    product_requisition: state.requisitions.items.reduce((acc, val) => {
      return (
        val.product_requisitions.filter(
          (pr) => pr.id === props.item.product_requisition_id
        )[0] || acc
      );
    }, undefined),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        openShipment,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.requisitions.items === next.requisitions.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OrdersProductRow);
