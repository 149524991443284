import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { isCategoryParentOf } from '../../lib/formatters.js';
import { openProductCategory } from '../../actions/product-category-actions.js';
import { Menu, Dropdown, Accordion, Icon } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import './officeluv-sibling-category-list.css';
import { mapLocationParentCategoriesIds } from '../../helpers/product-category-helpers.js';

export class OfficeluvSiblingCategoryList extends React.PureComponent {
  initialState = {
    activeIndexes: {},
    dropdownActiveIndex: -1,
  };

  state = this.initialState;

  onBlur = () => {
    this.setState({
      activeIndexes: {},
      dropdownActiveIndex: -1,
    });
  };

  handleParentDropdown = (e, { value }) => {
    const { dropdownActiveIndex } = this.state;
    const newValue = dropdownActiveIndex === value ? -1 : value;
    this.setState({ dropdownActiveIndex: newValue });
  };

  handleChildDropdown = (e, titleProps) => {
    e.preventDefault();
    e.stopPropagation();
    const { index } = titleProps;
    let activeIndexes = { ...this.state.activeIndexes };
    activeIndexes[index] = !activeIndexes[index];
    this.setState({ activeIndexes });
  };

  goToPage = (path) => {
    browserHistory.push(path);
    this.setState(this.initialState);
  };

  goToCategory = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    browserHistory.push(`/supplies/catalog/${value}`);
    this.setState(this.initialState);
  };

  renderChild = (child, i) => {
    if (child.children && child.children.length) {
      return (
        <Dropdown.Item key={child.id} className="no-hover">
          <Accordion style={{ margin: 0 }}>
            <Accordion.Title
              active={this.state.activeIndexes[`${child.name}:${i}`]}
              index={`${child.name}:${i}`}
              onClick={this.handleChildDropdown}
            >
              {child.name}
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content
              active={this.state.activeIndexes[`${child.name}:${i}`]}
            >
              <Dropdown.Item
                text={`All ${child.name}`}
                value={child.id}
                onClick={(e) => this.goToCategory(e, child.id)}
              />
              {child.children.sort(sortAlphaOrder).map(this.renderChild)}
            </Accordion.Content>
          </Accordion>
        </Dropdown.Item>
      );
    }
    return (
      <Dropdown.Item
        key={`${child.name}:${i}`}
        text={child.name}
        value={child.id}
        onClick={(e) => this.goToCategory(e, child.id)}
      />
    );
  };

  renderCategories = (category, i) => {
    if (
      (category.children && this.props.locationParentCategories.length === 0) ||
      (category.children &&
        this.props.locationParentCategories.includes(category.id))
    ) {
      return (
        <Menu.Item
          key={category.id}
          className={classnames('sibling-category-link', {
            'active-link':
              category.id === this.props.openCategory.id ||
              isCategoryParentOf(category, this.props.openCategory),
            'brand-border-color-override':
              category.id === this.props.openCategory.id ||
              isCategoryParentOf(category, this.props.openCategory),
          })}
        >
          <Dropdown
            key={category.id}
            pointing
            scrolling
            value={`${category.name}:${i}`}
            open={this.state.dropdownActiveIndex === `${category.name}:${i}`}
            text={category.name}
            onClick={this.handleParentDropdown}
            onBlur={this.onBlur}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                key="all"
                text={`All ${category.name}`}
                onClick={(e) => this.goToCategory(e, category.id)}
              />
              <Dropdown.Divider key="divider" />
              {category.children.sort(sortAlphaOrder).map(this.renderChild)}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      );
    }
  };

  render() {
    return (
      <div className="officeluv-sibling-category-list">
        <div className="variable-width-column category-link-container">
          <Menu stackable>
            <Menu.Item
              key="all-supplies"
              onClick={() => this.goToPage('/supplies/catalog/')}
            >
              All Supplies
            </Menu.Item>
            {this.props.categories.map(this.renderCategories)}
            {this.props.locationServices.includes('attendant') &&
              this.props.canManageCleanings && (
                <Menu.Item
                  key="on-site-service"
                  onClick={() => this.goToPage('/office-attendant/schedule')}
                >
                  On-Site-Service
                </Menu.Item>
              )}
          </Menu>
        </div>
      </div>
    );
  }
}

OfficeluvSiblingCategoryList.propTypes = {
  openCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    parent_id: PropTypes.number,
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  locationParentCategories: PropTypes.arrayOf(
    PropTypes.shape({
      product_category_id: PropTypes.number.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    openProductCategory: PropTypes.func.isRequired,
  }).isRequired,
  hasNotOrdered: PropTypes.bool,
  isRequesting: PropTypes.bool,
  canManageCleanings: PropTypes.bool,
  locationServices: PropTypes.string,
};

OfficeluvSiblingCategoryList.defaultProps = {
  canManageCleanings: false,
  isRequesting: false,
  hasNotOrdered: false,
  locationServices: 'procurement',
};

function hasSubmittedAt(r) {
  return r.submitted_at;
}

function hasId(r) {
  return !r.id;
}

function hasParentId(r) {
  return !r.parent_id;
}

function sortCatOrder(a, b) {
  return a.order < b.order ? -1 : 1;
}

function sortAlphaOrder(a, b) {
  return a.name < b.name ? -1 : 1;
}

export function mapStateToProps(state) {
  return {
    canManageCleanings: state.auth.role.can_manage_cleanings,
    locationServices: state.locations.open.services,
    openCategory: state.productCategories.open,
    hasNotOrdered: state.requisitions.items.filter(hasSubmittedAt).length === 0,
    isRequesting:
      state.requisitions.items.length < 1 ||
      state.requisitions.requesting.filter(hasId).length > 0 ||
      state.productCategories.requesting.filter(hasId).length > 0,
    categories: state.productCategories.items
      .filter(hasParentId)
      .sort(sortCatOrder),
    locationParentCategories: mapLocationParentCategoriesIds(
      state.locationParentProductCategories.items
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openProductCategory,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.open === next.productCategories.open &&
    prev.requisitions.items === next.requisitions.items &&
    prev.requisitions.requesting === next.requisitions.requesting &&
    prev.productCategories.requesting === next.productCategories.requesting &&
    prev.productCategories.items === next.productCategories.items &&
    prev.auth.role.can_manage_cleanings ===
      next.auth.role.can_manage_cleanings &&
    prev.locations.open.services === next.locations.open.services &&
    prev.locationParentProductCategories.items ===
      next.locationParentProductCategories.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeluvSiblingCategoryList);
