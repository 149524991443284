import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

const ButtonUnstyled = (props) => (
  <button
    onClick={props.onClick}
    style={{
      border: 'none',
      outline: 'none',
      background: 'transparent',
      margin: 0,
      padding: 0,
    }}
  >
    {props.children}
  </button>
);

ButtonUnstyled.propTypes = {
  onClick: PropTypes.func,
};

export default ButtonUnstyled;
