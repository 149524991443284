import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Message, Form, Radio, Icon, Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import browserHistory from '../../lib/history.js';
import UnmetMinimumWarning from './unmet-minimum-warning.js';
import EditStatus from './edit-status.js';
import Loader from '../loader.js';
import Cart from '../requisitions/cart.js';
import ModalProductSearch from '../modals/modal-product-search.js';
import ModalConfirm from '../modals/modal-confirm.js';
import ShippableForm from '../modules/shippable-form.js';
import ScheduleForm from './schedule-form.js';
import BeforeLeaveConfirmation from '../../events/before-leave-confirmation.js';
import ArrivalForm from './arrival-form.js';
import { default_vendor } from '../../helpers/vendor-helpers.js';
import { formatDateTime, formatDate } from '../../lib/formatters.js';
import * as queryParser from '../../lib/query-parser.js';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import {
  doCreateScheduledRequisition,
  submitUpdateScheduledRequisition,
} from '../../actions/scheduled-requisition-actions.js';
import { enqueueConfirmation } from '../../actions/confirmations-actions.js';
import '../requisitions/checkout-page.css';
import './unified-scheduled-requisition-form.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/segment.min.css';

export class UnifiedScheduledRequisitionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scheduledRequisition: {
        ...this.emptyRecordState(),
        approve_automatically: true,
        rrule: this.evaulateRrule(props),
        ...props.scheduledRequisition,
        start_at: this.evaluateStartAt(props),
        _update_future_occurrences: '0',
      },
      original_scheduled_product_requisitions: null,
      confirmOpen: false,
      cancelConfirmOpen: false,
      submitSuccess: false,
      shouldDisplayConfirmation: false,
    };
  }

  emptyRecordState = () => ({
    name: '',
    age_confirmation: false,
    shipping_address: '',
    shipping_address_number: '',
    shipping_business: '',
    shipping_care: '',
    shipping_city: '',
    shipping_name: '',
    shipping_state: '',
    shipping_zip: '',
    instructions: '',
    approve_automatically: true,
    rrule: null,
    archived: false,
    expected_at: '',
    expiration_hour: 10,
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextSr = nextProps.scheduledRequisition;

    if (nextSr && nextSr.id && nextSr.archived) {
      browserHistory.push('/calendar?force=true');
      return;
    }
    /* Set original product requisitions to chech if they change in this form */
    if (
      nextSr &&
      nextSr.id &&
      nextSr.scheduled_product_requisitions &&
      !this.state.original_scheduled_product_requisitions
    ) {
      this.setState({
        original_scheduled_product_requisitions:
          nextSr.scheduled_product_requisitions,
      });
    }
    let serverSentNewData =
      nextSr.scheduled_product_requisitions !==
        this.props.scheduledRequisition.scheduled_product_requisitions ||
      helpers.formDataHasChanged(nextSr, this.props.scheduledRequisition);
    /* Hydrate state with any updates from server on page load */
    /* Do not do this on form submit*/
    if (
      !nextProps.isRequesting &&
      serverSentNewData &&
      !this.props.isUpdating &&
      !nextProps.isUpdating
    ) {
      this.setState((prevState) => ({
        scheduledRequisition: this.hydrateState(prevState, nextSr),
      }));
    }
  }

  hydrateState = (prevState, nextSr) => {
    return {
      ...prevState.scheduledRequisition,
      ...nextSr,
      approve_automatically: nextSr.id ? nextSr.approve_automatically : true,
      start_at: this.evaluateStartAt({
        scheduledRequisition: { ...prevState.scheduledRequisition, ...nextSr },
        location: this.props.location,
      }),
      age_confirmation: nextSr.id ? nextSr.age_confirmation : false,
      scheduled_product_requisitions: nextSr.scheduled_product_requisitions,
      _optimized_product_requisitions: nextSr._optimized_product_requisitions,
      _update_future_occurrences: '0',
    };
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.scheduledRequisition &&
      this.props.scheduledRequisition &&
      this.props.scheduledRequisition.id > 0
    ) {
      this.onChangeFieldScheduled('id', this.props.scheduledRequisition.id);
    }
    if (!prevProps.error && this.props.error) {
      this.setState({ confirmOpen: false });
    }
  }

  evaulateRrule(props) {
    if (props.scheduledRequisition && props.scheduledRequisition.rrule) {
      return props.scheduledRequisition.rrule;
    }
    if (props.queryParams.startDate) {
      const rruleDay = moment(props.queryParams.startDate)
        .format('dd')
        .toUpperCase();
      return `FREQ=WEEKLY;BYDAY=${rruleDay}`;
    }
    return null;
  }

  evaluateStartAt(props) {
    if (props.scheduledRequisition && props.scheduledRequisition.start_at) {
      return moment(props.scheduledRequisition.start_at);
    }
    if (props.queryParams.startDate) {
      return moment(props.queryParams.startDate).startOf('day');
    }
    return moment().add(1, 'week').startOf('day');
  }

  productFilter = (p) => {
    return (
      (p.vendors[0] || default_vendor).shipping.max_seconds <=
      helpers.getShippingMaxSeconds(this.props.scheduledRequisition)
    );
  };

  onAgeConfirmed = (age_confirmation) => {
    this.setState((prevState) => {
      return {
        scheduledRequisition: {
          ...prevState.scheduledRequisition,
          age_confirmation: age_confirmation,
        },
      };
    });
  };

  onChangeName = (e, { value }) => {
    this.onChangeFieldScheduled('name', value);
  };

  onChangeRadio = (e, { value }) => {
    this.onChangeFieldScheduled('_update_future_occurrences', value);
  };

  onChangeExpectedDate = (momentDate) => {
    this.onChangeFieldScheduled('expected_at', momentDate);
  };

  onChangeFieldScheduled = (field, value) => {
    this.setState((prevState) => {
      const scheduledRequisition = { ...prevState.scheduledRequisition };
      const shouldDisplayConfirmation =
        field === 'expected_at' ? prevState.shouldDisplayConfirmation : true;
      scheduledRequisition[field] = value;
      return { scheduledRequisition, shouldDisplayConfirmation };
    });
  };

  onCancel = () => {
    this.setState({ cancelConfirmOpen: true });
  };

  submitCancel = () => {
    this.props.actions
      .submitUpdateScheduledRequisition(
        {
          id: this.state.scheduledRequisition.id,
          _update_future_occurrences:
            this.state.scheduledRequisition._update_future_occurrences,
          archived: true,
        },
        { bubble: true }
      )
      .then(() => {
        this.setState((prevState) => ({
          cancelConfirmOpen: false,
          scheduledRequisition: prevState.scheduledRequisition,
          _update_future_occurrences: false,
        }));
        this.props.actions.enqueueConfirmation({
          message: 'Recurring order cancelled',
          type: 'success',
        });
        browserHistory.push('/calendar?force=true');
      })
      .catch(() => {});
  };

  formDataHasChanged = () => {
    if (
      this.props.isRequesting ||
      this.props.isCreating ||
      this.props.isUpdating
    )
      return false;
    if (this.props.scheduledRequisition.id) {
      return helpers.formDataHasChanged(
        this.props.scheduledRequisition,
        this.state.scheduledRequisition
      );
    } else {
      return helpers.formDataHasChanged(
        this.emptyRecordState(),
        this.state.scheduledRequisition
      );
    }
  };

  productReqsHaveChanged = () => {
    if (
      this.props.isRequesting ||
      this.props.isCreating ||
      this.props.isUpdating
    )
      return false;
    if (!this.props.scheduledRequisition.scheduled_product_requisitions)
      return false;
    if (!this.props.stagedScheduledRequisition.scheduled_product_requisitions)
      return false;

    return helpers.productReqsHaveChanged(
      this.props.scheduledRequisition.scheduled_product_requisitions || [],
      this.props.stagedScheduledRequisition.scheduled_product_requisitions || []
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ confirmOpen: false });
    if (
      this.state.scheduledRequisition.id &&
      (this.state.shouldDisplayConfirmation || this.productReqsHaveChanged())
    ) {
      this.setState({ confirmOpen: true });
    } else if (this.state.scheduledRequisition.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  };

  submitCreate = () => {
    const sr = this.state.scheduledRequisition;
    const { rrule } = sr;
    const staged = this.props.stagedScheduledRequisition;

    this.props.actions
      .doCreateScheduledRequisition(
        {
          name: sr.name,
          age_confirmation: sr.age_confirmation,
          shipping_address: sr.shipping_address,
          shipping_address_number: sr.shipping_address_number,
          shipping_business: sr.shipping_business,
          shipping_care: sr.shipping_care,
          shipping_city: sr.shipping_city,
          shipping_name: sr.shipping_name,
          shipping_state: sr.shipping_state,
          shipping_zip: sr.shipping_zip,
          instructions: sr.instructions,
          approve_automatically: sr.approve_automatically,
          _update_future_occurrences: '1',
          start_at: sr.start_at,
          expiration_hour: sr.expiration_hour,
          rrule: rrule,
          scheduled_product_requisitions_attributes:
            helpers.constructSPRAttributes(
              staged ? staged.scheduled_product_requisitions : []
            ),
        },
        { bubble: true }
      )
      .then((body) => {
        this.showSuccessMessage();
        if (body.id) {
          this.props.actions.enqueueConfirmation({
            message: 'Recurring Order created!',
            type: 'success',
          });
          browserHistory.push('/calendar?force=true');
        }
      })
      .catch(() => {});
  };

  submitUpdate = () => {
    this.setState({ confirmOpen: false });
    const sr = this.state.scheduledRequisition;
    this.props.actions
      .submitUpdateScheduledRequisition(
        {
          age_confirmation: sr.age_confirmation,
          approve_automatically: sr.approve_automatically,
          archived: sr.archived,
          expected_at: sr.expected_at,
          id: sr.id,
          instructions: sr.instructions,
          location_id: sr.location_id,
          name: sr.name,
          rrule: sr.rrule,
          shipping_address: sr.shipping_address,
          shipping_address_number: sr.shipping_address_number,
          shipping_business: sr.shipping_business,
          shipping_care: sr.shipping_care,
          shipping_city: sr.shipping_city,
          shipping_name: sr.shipping_name,
          shipping_state: sr.shipping_state,
          shipping_zip: sr.shipping_zip,
          start_at: sr.start_at,
          expiration_hour: sr.expiration_hour,
          _update_future_occurrences: sr._update_future_occurrences,
          scheduled_product_requisitions_attributes:
            this.productReqsHaveChanged()
              ? helpers.constructSPRAttributes(
                  this.props.stagedScheduledRequisition
                    .scheduled_product_requisitions,
                  sr._update_future_occurrences
                )
              : undefined,
        },
        { bubble: true }
      )
      .then(() => {
        if (this.props.queryParams.back_to_approval) {
          this.props.actions.enqueueConfirmation({
            message: 'Recurring order updated!',
            type: 'success',
          });
          browserHistory.push(
            `/orders/scheduled/${this.props.scheduledRequisition.id}/approve?force=true`
          );
        } else {
          this.showSuccessMessage();
          this.onChangeFieldScheduled('_update_future_occurrences', '0');
          this.setState({ shouldDisplayConfirmation: false });
        }
      })
      .catch(() => {});
  };

  showSuccessMessage = () => {
    this.setState({ submitSuccess: true });
    setTimeout(() => {
      this.setState({ submitSuccess: false });
    }, 5000);
  };

  closeModal = () => {
    this.setState({ confirmOpen: false, cancelConfirmOpen: false });
  };

  populateDeliveryField = () => {
    this.setState((prevState) => ({
      scheduledRequisition: {
        ...prevState.scheduledRequisition,
        instructions: prevState.scheduledRequisition.instructions,
      },
    }));
  };

  getSubmitText = () => {
    if (
      !this.props.scheduledRequisition ||
      this.props.scheduledRequisition.id <= 0
    ) {
      return 'Create Recurring Order';
    }
    return 'Update Recurring Order';
  };

  isDisabled = () => {
    return helpers.isInvalid({
      ...this.state.scheduledRequisition,
      scheduled_product_requisitions:
        this.props.stagedScheduledRequisition.scheduled_product_requisitions,
    });
  };

  renderConfirm(
    header = 'Apply Changes To:',
    submitAction = this.submitUpdate
  ) {
    return (
      <ModalConfirm isOpen={true} onRequestClose={this.closeModal}>
        <div>
          <h3>{header}</h3>
          <Form style={{ marginTop: '1.5rem' }}>
            {(!helpers.scheduleHasChanged(
              this.props.scheduledRequisition,
              this.state.scheduledRequisition
            ) ||
              this.state.scheduledRequisition._update_future_occurrences ===
                '0') && (
              <Form.Field>
                <Radio
                  label="This order"
                  name="_update_future_occurrences"
                  value="0"
                  checked={
                    this.state.scheduledRequisition
                      ._update_future_occurrences === '0'
                  }
                  onChange={this.onChangeRadio}
                />
              </Form.Field>
            )}
            <Form.Field>
              <Radio
                label="This and all following orders"
                name="_update_future_occurrences"
                value="1"
                checked={
                  this.state.scheduledRequisition._update_future_occurrences ===
                  '1'
                }
                onChange={this.onChangeRadio}
              />
            </Form.Field>
            <Form.Field>
              <Button
                primary
                loading={this.props.isUpdating}
                onClick={submitAction}
                className="checkout-form-submit purchase hvr-buzz-out">
                {this.getSubmitText()}
              </Button>
            </Form.Field>
          </Form>
        </div>
      </ModalConfirm>
    );
  }

  renderAlert(canEdit) {
    if (canEdit) {
      return (
        <EditStatus scheduledRequisition={this.props.scheduledRequisition} />
      );
    }
    if (helpers.isExpired(this.props.scheduledRequisition)) {
      return (
        <Message compact color="red">
          <p>
            Expired{' '}
            {formatDateTime(this.props.scheduledRequisition.expiration_at)}
          </p>
        </Message>
      );
    }
    return null;
  }

  renderScheduleForm() {
    if (this.props.isRequesting) return null;

    return (
      <Form>
        <Form.Field className="required">
          <label>Frequency</label>
          <ScheduleForm
            schedulable={this.state.scheduledRequisition}
            onChangeField={this.onChangeFieldScheduled}
            originalFormData={this.props.scheduledRequisition}
            editModeOverride={!!window.location.pathname.match(/new/)}
          />
        </Form.Field>
      </Form>
    );
  }

  expirationWouldBeInPast = (momentDate) => {
    const { scheduledRequisition } = this.props;
    const duration =
      moment(scheduledRequisition.expected_at) -
      moment(scheduledRequisition.expiration_at);
    /*** wrapping date passed from calendar in another moment
        so we dont modify it ***/
    const newExpiry = moment(momentDate).subtract(duration, 'ms');
    return moment().isAfter(newExpiry);
  };

  isAfterIntervalFrequency = (momentDate) => {
    const interval = 1;
    const frequency = 'week';
    return momentDate.isAfter(
      moment(this.props.scheduledRequisition.expected_at).add(
        interval,
        frequency
      )
    );
  };

  expectedInvalid = (momentDate) => {
    return (
      this.expirationWouldBeInPast(momentDate) ||
      this.isAfterIntervalFrequency(momentDate)
    );
  };

  render() {
    if (this.props.isRequesting)
      return (
        <div className="unified-order checkout-page">
          <div className="variable-width-column">
            <Loader />
          </div>
        </div>
      );
    const canEdit = this.props.scheduledRequisition
      ? helpers.canEdit(this.props.scheduledRequisition, this.props.user)
      : true;
    return (
      <div className="unified-order checkout-page">
        <BeforeLeaveConfirmation
          activated={
            !this.props.isRequesting &&
            !this.props.isCreating &&
            !this.props.isUpdating &&
            (this.formDataHasChanged() || this.productReqsHaveChanged())
          }
        />
        {this.state.confirmOpen && this.renderConfirm()}
        {this.state.cancelConfirmOpen &&
          this.renderConfirm('Cancel', this.submitCancel)}
        <div
          className="variable-width-column flex-wrapper"
          style={{ maxWidth: '1030px' }}>
          <Header as="h2" style={{ marginBottom: '1rem' }}>
            {this.props.scheduledRequisition.id > 0
              ? `${formatDate(
                  this.props.scheduledRequisition.expected_at
                )} Order for ${this.props.scheduledRequisition.name}`
              : 'New Recurring Order'}
          </Header>
        </div>
        <div className="variable-width-column flex-wrapper centered">
          <div>
            <ModalProductSearch
              secondaryFilter={this.productFilter}
              resultsPlaceholder="Add Items with Similar Shipping Time"
            />
            <Cart
              isEditingScheduled={true}
              parentComponent="edit-scheduled-requisition"
              showTax={true}
              showTotal={true}
              readOnly={false}
              showReplacementOptions={true}
            />
          </div>
          <div className="col-right width-contain ui form">
            {this.props.queryParams.back_to_approval && canEdit && (
              <div style={{ textAlign: 'right', height: '2.8rem' }}>
                <Link
                  className="primary"
                  to={`/orders/scheduled/${this.props.scheduledRequisition.id}/approve`}>
                  &laquo; Back to Confirmation
                </Link>
              </div>
            )}
            {this.props.queryParams.from_calendar && (
              <div style={{ textAlign: 'right', height: '2.8rem' }}>
                <Link className="primary" to="/calendar">
                  &laquo; Back to Calendar
                </Link>
              </div>
            )}
            <Form.Group>
              {this.props.scheduledRequisition.id > 0 && (
                <ArrivalForm
                  date={this.state.scheduledRequisition.expected_at}
                  onDateChange={this.onChangeExpectedDate}
                  isOutsideRange={this.expectedInvalid}
                />
              )}
              <Form.Input
                required
                width={12}
                label="Order Name"
                value={
                  this.state.scheduledRequisition &&
                  this.state.scheduledRequisition.name
                    ? this.state.scheduledRequisition.name
                    : ''
                }
                onChange={this.onChangeName}
                placeholder="Enter a name for your order"
              />
            </Form.Group>
            {this.renderScheduleForm()}
            <div className="">
              <ShippableForm
                canSubmit={canEdit}
                isAlcoholAware={
                  this.props.scheduledRequisition
                    ? helpers.hasAlcoholItems(this.props.scheduledRequisition)
                    : false
                }
                isDisabled={this.isDisabled()}
                onAgeConfirmation={this.onAgeConfirmed}
                onChangeField={this.onChangeFieldScheduled}
                onSubmit={this.submitForm}
                populateDelivery={this.populateDeliveryField}
                shippable={this.state.scheduledRequisition}
                submitButtonText={this.getSubmitText()}
                isLoading={
                  this.props.isCreating || this.props.isUpdating ? true : false
                }
                editModeOverride={true}
              />
              {this.props.stagedScheduledRequisition
                .scheduled_product_requisitions &&
                !this.props.stagedScheduledRequisition
                  .scheduled_product_requisitions.length && (
                  <Message
                    negative
                    content="Please add items to your cart to submit order"
                  />
                )}
              {this.props.error && (
                <Message
                  negative
                  header="Submit Failed"
                  content="Please try again or contact support if failures continue"
                />
              )}
              {this.state.submitSuccess && (
                <Message
                  positive
                  header="Success!"
                  content="Your changes have been saved"
                />
              )}
              <UnmetMinimumWarning
                scheduledRequisition={this.props.stagedScheduledRequisition}
              />
              {this.renderAlert(canEdit)}
              {this.props.scheduledRequisition &&
                !!this.props.scheduledRequisition.id && (
                  <div style={{ marginTop: '3rem' }}>
                    <Button
                      type="button"
                      size="mini"
                      secondary
                      onClick={this.onCancel}>
                      <Icon name="trash alternate outline" />
                      Cancel Order
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnifiedScheduledRequisitionForm.propTypes = {
  scheduledRequisition: PropTypes.shape({
    name: PropTypes.string,
    rrule: PropTypes.string,
    expected_at: PropTypes.string,
    expiration_at: PropTypes.string,
    start_at: PropTypes.string,
    archived: PropTypes.bool,
  }).isRequired,
  isNewRecord: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    data: PropTypes.object,
  }),
  queryParams: PropTypes.shape({
    back_to_approval: PropTypes.string,
    from_calendar: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  return {
    user: state.employees.user,
    isCreating: state.scheduledRequisitions.creating.length > 0,
    isUpdating:
      state.scheduledRequisitions.updating.filter(
        (sr) => sr.id === props.scheduledRequisition.id
      ).length > 0,
    error: state.scheduledRequisitions.errors[0],
    stagedScheduledRequisition: state.scheduledRequisitions.staged[0],
    queryParams: queryParser.parse(props.location.search),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCreateScheduledRequisition,
        submitUpdateScheduledRequisition,
        enqueueConfirmation,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.scheduledRequisitions.creating ===
      next.scheduledRequisitions.creating &&
    prev.scheduledRequisitions.updating ===
      next.scheduledRequisitions.updating &&
    prev.scheduledRequisitions.errors === next.scheduledRequisitions.errors &&
    prev.scheduledRequisitions.staged === next.scheduledRequisitions.staged
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(UnifiedScheduledRequisitionForm);
