import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button } from 'semantic-ui-react';
import { trackInterfaceOpenCustomerRolesDescModal } from '../../lib/analytics.js';
import CustomerRoleDetails from '../customerRoles/customer-role-details.js';
import CustomerRoleAdd from '../customerRoles/customer-role-add.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/list.min.css';
import './modal-common.css';

export class ModalCustomerRolesDesc extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  onRequestClose = () => {
    this.setState(() => {
      return {
        modalOpen: false,
      };
    });
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleTrigger = (e) => {
    e.preventDefault();
    this.setState(() => {
      return { modalOpen: true };
    });
    trackInterfaceOpenCustomerRolesDescModal();
  };

  render() {
    return (
      <div className="modal-customer-roles-desc-wrap">
        <div className="modal-customer-roles-desc-trigger-wrap">
          <Button
            size="mini"
            secondary
            circular
            icon="info"
            className="modal-customer-roles-desc-trigger"
            onClick={this.handleTrigger}
          />
        </div>
        <Modal
          appElement={document.getElementById('root')}
          isOpen={this.state.modalOpen}
          onRequestClose={this.onRequestClose}
          onAfterOpen={this.onAfterOpen}
          shouldCloseOnOverlayClick={true}
          className={{
            base: 'modal',
            afterOpen: 'modal_after-open',
            beforeClose: 'modal_before-close',
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'modal-overlay_after-open',
            beforeClose: 'modal-overlay_before-close',
          }}
          contentLabel="Customer roles descriptions"
        >
          <div className="modal-customer-roles-desc">
            <button
              className="close-icon"
              type="button"
              onClick={this.handleCloseModal}
            >
              &times;
            </button>
            <div className="modal-customer-roles-desc-container">
              <h4 className="subheader bold-text">Roles</h4>
              <CustomerRoleDetails compact />
              {this.props.canManageRoles && <CustomerRoleAdd />}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ModalCustomerRolesDesc.propTypes = {
  customerRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  canManageRoles: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    customerRoles: Object.values(state.customerRoles.items),
    canManageRoles: ownProps.canManageRoles && state.auth.role.can_manage_roles,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.items === next.customerRoles.items &&
    prev.auth.role.can_manage_roles === next.auth.role.can_manage_roles
  );
}

const Wrapper = connect(mapStateToProps, null, null, { areStatesEqual })(
  ModalCustomerRolesDesc
);

Wrapper.defaultProps = {
  canManageRoles: true,
};

export default Wrapper;
