import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { trackFetchError } from '../lib/analytics.js';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';

export const empty = {
  requesting: [],
  responses: [],
};

export const initial = {
  ...empty,
};

// Actions
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const SUCCESS_APPLY_PROMO_CODE = 'SUCCESS_APPLY_PROMO_CODE';
export const ERROR_APPLY_PROMO_CODE = 'ERROR_APPLY_PROMO_CODE';
export const UNSET_PROMO_CODE_RESPONSE = 'UNSET_PROMO_CODE_RESPONSE';
export const CLEAR_PROMO_CODE_RESPONSES = 'CLEAR_PROMO_CODE_RESPONSES';

// Action Creators
export const applyPromoCode = (data) => ({ type: APPLY_PROMO_CODE, data });
export const errorApplyPromoCode = (data) => ({
  type: ERROR_APPLY_PROMO_CODE,
  data,
});
export const successApplyPromoCode = (data) => ({
  type: SUCCESS_APPLY_PROMO_CODE,
  data,
});
export const unsetPromoCodeResponse = (data) => ({
  type: UNSET_PROMO_CODE_RESPONSE,
  data,
});
export const clearPromoCodeResponses = () => ({
  type: CLEAR_PROMO_CODE_RESPONSES,
});

// Helpers
export const FILTER_IS_REQUESTING = (response) =>
  response.type === APPLY_PROMO_CODE;

export const FILTER_IS_ERROR = (response) =>
  response.type === ERROR_APPLY_PROMO_CODE;

export const FILTER_IS_SUCCESS = (response) =>
  response.type === SUCCESS_APPLY_PROMO_CODE;

export default (state = initial, action) => {
  switch (action.type) {
    case APPLY_PROMO_CODE:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_APPLY_PROMO_CODE:
    case ERROR_APPLY_PROMO_CODE:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses
          .filter((req) => req.data._request !== action.data._request)
          .concat(action),
      };
    case UNSET_PROMO_CODE_RESPONSE:
      return {
        ...state,
        responses: state.responses
          .filter((res) => res.data._request !== action.data._request)
          .filter((res) => res.type !== action.data.type),
      };
    case CLEAR_PROMO_CODE_RESPONSES:
      return {
        ...state,
        responses: empty.responses,
      };
    default:
      return state;
  }
};

// Thunks

export const doApplyPromoCode = (data, options = { bubble: false }) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(applyPromoCode(data));

    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/carts/${data.cart_id}/credits`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-ID': data._request,
        },
        body: JSON.stringify({
          code: data.code,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({ response, data, message: 'Apply Promo Code' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successApplyPromoCode({
            item: body,
            _request: data._request,
          })
        );
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (
          error.status === 400 ||
          error.status === 404 ||
          error.status === 405
        ) {
          return error.response.json();
        }
        throw error;
      })
      .then((body) => {
        /* if body exists, then it was an error */
        if (body) {
          dispatch(
            errorApplyPromoCode({
              ...body,
              _request: data._request,
            })
          );
          dispatch(
            enqueueConfirmation({
              type: 'error',
              message: "Couldn't find promo code.",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          errorApplyPromoCode({
            error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
