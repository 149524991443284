import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cart from './cart.js';
import {
  submitDeleteProductRequisition,
  submitUpdateProductRequisition,
} from '../../actions/requisition-actions.js';
import './edit-requisition-products.css';

export class EditRequisitionProducts extends React.PureComponent {
  subTotal = (requisition) => {
    return (requisition.product_requisitions || []).reduce(
      this.reduceItemsPrice,
      0
    );
  };

  reduceItemsPrice(acc, val) {
    return (
      acc +
      val.quantity *
        (val.price === 0 ? val.price : val.price || val.product.price)
    );
  }

  render() {
    return (
      <Cart
        estimatingTax={this.props.isRequesting}
        estimatingTaxText={'(will be recalculated)'}
        isEditing={true}
        parentComponent="edit-requisition"
        showTax={true}
        showTotal={true}
        showReplacementOptions={true}
      />
    );
  }
}

EditRequisitionProducts.propTypes = {
  actions: PropTypes.shape({
    submitDeleteProductRequisition: PropTypes.func.isRequired,
    submitUpdateProductRequisition: PropTypes.func.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
  requisition: PropTypes.shape({
    id: PropTypes.number,
    tax: PropTypes.number.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
};

export function mapStateToProps(state, props) {
  return {
    isRequesting:
      state.requisitions.updating.filter((r) => r.id === props.requisition.id)
        .length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitDeleteProductRequisition,
        submitUpdateProductRequisition,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.requisitions.editing === next.requisitions.editing &&
    prev.requisitions.updating === next.requisitions.updating
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EditRequisitionProducts);
