export const filtersAsParams = (filters) => {
  if (!filters) return '';
  let string = window.location.search;
  string = filterAsParam(string, 'category', filters.categoryIds);
  string = filterAsParam(string, 'brand', filters.brandNames);
  string = filterAsParam(string, 'shipping', filters.maxShippingTimes);
  string = filterAsParam(string, 'cart_minimum', filters.vendorIds);
  string = filterAsParam(
    string,
    'stock_status',
    [filters.stockStatus].filter((i) => !!i)
  );
  return string;
};

export const filterAsParam = (string, key, values) => {
  if (values) {
    let base = '&';
    if (!string.trim().length) {
      base = '?';
    }
    if (!values.length) {
      const regexString = `[&|?]${key}=([^&]*)`;
      return string.replace(new RegExp(regexString), '');
    }
    let valuesAsString = values.map((x) => encodeURIComponent(x)).join(',');
    let newSearch;
    const regexString = `${key}=([^&]*)`;
    const match = string.match(new RegExp(regexString));
    if (match) {
      newSearch = string.replace(
        new RegExp(regexString),
        `${key}=${valuesAsString}`
      );
    } else {
      newSearch = string + `${base}${key}=${valuesAsString}`;
    }
    return newSearch;
  }
  return string;
};

export const removeFilters = () => {
  let collect = [];
  const textMatch = window.location.search.match(/[&|?]q=([^&]*)/);
  const sortMatch = window.location.search.match(/[&|?]sortBy=([^&]*)/);
  const sortDescMatch = window.location.search.match(/[&|?]sortDesc=([^&]*)/);
  if (textMatch) collect.push(textMatch[0].slice(1));
  if (sortMatch) collect.push(sortMatch[0].slice(1));
  if (sortDescMatch) collect.push(sortDescMatch[0].slice(1));
  return '?' + collect.join('&');
};

export const sortAsParams = (data) => {
  let string = window.location.search;
  let base = '&';
  if (!string.trim().length) {
    base = '?';
  }
  const sortMatch = string.match(/[&|?]sortBy=([^&]*)/);
  const attr = encodeURIComponent(data.attribute);
  if (sortMatch) {
    string = string.replace(/sortBy=([^&]*)/, `sortBy=${attr}`);
  } else {
    string = string + `${base}sortBy=${attr}`;
  }
  const sortDescendingMatch = string.match(/[&|?]sortDesc=([^&]*)/);
  if (sortDescendingMatch && data.descending) {
    string = string.replace(/sortDesc=([^&]*)/, 'sortDesc=1');
  } else if (sortDescendingMatch && !data.descending) {
    string = string.replace(/sortDesc=([^&]*)/, 'sortDesc=0');
  } else if (data.descending) {
    string = string + `${base}sortDesc=1`;
  }
  return string;
};
