import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import AuthCheck from '../components/auth-check.js';
import CustomerRoleForm from '../components/customerRoles/form.js';
import { Header, Segment } from 'semantic-ui-react';
import {
  FILTER_IS_LOADING,
  FILTER_IS_CREATE_SUCCESS,
} from '../ducks/customer-roles.js';
import './customer-role-form.css';

export class CustomerRoleNew extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (!prevProps.isCreateSuccess && this.props.isCreateSuccess) {
      browserHistory.push('/settings/team');
    }
  }

  render() {
    return (
      <Segment
        basic
        className="customer-role-form"
        loading={this.props.isLoading}
      >
        <AuthCheck permissions={{ can_manage_roles: true }} />
        <Fragment>
          <Header
            as="h1"
            content={`New Role for ${this.props.customer.name}`}
          />
          <CustomerRoleForm
            customer={this.props.customer}
            customerRole={this.props.stagedCustomerRole}
            isNewRecord={this.props.isNewRecord}
            isLoading={this.props.isLoading}
          />
        </Fragment>
      </Segment>
    );
  }
}

CustomerRoleNew.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  stagedCustomerRole: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isNewRecord: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    stagedCustomerRole: state.customerRoles.staged,
    isNewRecord: state.customerRoles.staged.id === 0,
    customer: state.customers.open,
    isLoading:
      state.customerRoles.requesting.filter(FILTER_IS_LOADING).length > 0 ||
      !state.customers.open.name,
    isCreateSuccess:
      state.customerRoles.responses.filter(FILTER_IS_CREATE_SUCCESS).length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.staged === next.customerRoles.staged &&
    prev.customers.open === next.customers.open &&
    prev.customerRoles.requesting === next.customerRoles.requesting &&
    prev.customerRoles.responses === next.customerRoles.responses
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CustomerRoleNew
);
