import React from 'react';
import { Button } from 'semantic-ui-react';
import RequestItemForm from '../supplies/request-item-form.js';

export class SearchResultsRequestItem extends React.PureComponent {
  state = {
    showRequestItem: false,
  };

  _onClickRequest = () => {
    this.setState(
      Object.assign({}, this.state, {
        showRequestItem: !this.state.showRequestItem,
      })
    );
  };

  _onResetRequest = () => {
    this.setState(
      Object.assign({}, this.state, {
        showRequestItem: false,
      })
    );
  };

  render() {
    return (
      <div className="subnav-category-request flex-item">
        {!this.state.showRequestItem && (
          <div className="">
            <div className="subnav-category-request-label">
              Need something we don't have?
            </div>
            <div className="subnav-category-request-toggle">
              <Button secondary size="mini" onClick={this._onClickRequest}>
                Request an Item
              </Button>
            </div>
          </div>
        )}
        {this.state.showRequestItem && (
          <RequestItemForm autoFocus={true} onReset={this._onResetRequest} />
        )}
      </div>
    );
  }
}

SearchResultsRequestItem.propTypes = {};

export default SearchResultsRequestItem;
