import React from 'react';
import PropTypes from 'prop-types';

const IconShipment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
    height={props.size}
    width={props.size}
    viewBox="0 0 26 26"
  >
    <g
      fill="#fff"
      fillRule="evenodd"
      stroke={props.color}
      transform="translate(1 1)"
    >
      <g strokeLinejoin="round">
        <path
          d="m12 24v-16.36363636l-12-3.27272728v14.18181814z"
          fill="#fff"
          strokeWidth="1"
        />
        <path
          d="m0 4.36363636 12-4.36363636 12 3.27272727-12 4.36363637z"
          fill="#fff"
          strokeWidth="1"
        />
        <path
          d="m12 7.63636364v16.36363636l12-5.4545455v-15.27272723z"
          fill="#fff"
          strokeWidth="1"
        />
        <path
          d="m16.3636364 6.54545455v6.54545455l3.2727272-2.1818182v-5.45454545z"
          fill="#fff"
        />
      </g>
      <path
        d="m15.2727273 5.45454545-9.81818185-2.18181818 3.27272728-1.09090909 9.81818177 2.18181818z"
        fill="#fff"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

IconShipment.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

IconShipment.defaultProps = {
  color: '#1aa7d2',
  size: 26,
  style: {},
};

export default IconShipment;
