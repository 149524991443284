import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import VendorReport from '../components/insights/vendor-report.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import VendorLinks from '../components/insights/vendor-links.js';
import { doGetLocationDataView } from '../actions/data-view-actions.js';
import { decodeFromUrlHash } from '../lib/formatters.js';
import './insights-budget-code.css';

const DATA_VIEWS = [
  'customer_purchase_vendor_dates',
  'customer_purchase_line_item_budget_code_dates',
];

export class InsightsVendorRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openVendorName: decodeFromUrlHash(props.match.params.name),
    };
    this._refreshData({
      locations: props.viewingLocations,
      startDate: props.startDate,
      endDate: props.endDate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.setState({
      openVendorName: decodeFromUrlHash(this.props.match.params.name),
    });
    if (
      this._haveLocationsUpdated(prevProps) ||
      this._haveDatesUpdated(prevProps) ||
      this._hasVendorUpdated(prevProps, prevState)
    ) {
      this._refreshData({
        locations: this.props.viewingLocations,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }

  _hasVendorUpdated = (props, state) => {
    return this.state.openVendorName !== state.openVendorName;
  };

  _haveLocationsUpdated = (prevProps) => {
    return (
      this.props.viewingLocations.map((l) => l.id).join('') !==
        prevProps.viewingLocations.map((l) => l.id).join('') ||
      this.props.viewingLocations.map((l) => l.updated_at).join('') !==
        prevProps.viewingLocations.map((l) => l.updated_at).join('')
    );
  };

  _haveDatesUpdated = (prevProps) => {
    return (
      this.props.startDate.toJSON() !== prevProps.startDate.toJSON() ||
      this.props.endDate.toJSON() !== prevProps.endDate.toJSON()
    );
  };

  _refreshData = ({ locations, startDate, endDate }) => {
    let start = new Date(startDate).toJSON();
    let end = new Date(endDate).toJSON();
    locations.map((location) => {
      DATA_VIEWS.map((view) => {
        return this.props.actions.doGetLocationDataView({
          location_id: location.id,
          view,
          start,
          end,
        });
      });
      return location;
    });
  };

  render() {
    return (
      <div className="insights-budget-code-route">
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <NavBarAttendant />
        <MountAtPageTop />
        <div className="variable-width-column">
          <div className="insights-budget-code">
            <VendorReport vendorName={this.state.openVendorName} />
          </div>
          <div className="links-container">
            <Link
              to="/insights/budgets"
              className="budget-insights-link primary"
            >
              &laquo; Return to Insights
            </Link>
            <VendorLinks
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              locations={this.props.viewingLocations}
            />
          </div>
        </div>
      </div>
    );
  }
}

InsightsVendorRoute.propTypes = {
  actions: PropTypes.shape({
    doGetLocationDataView: PropTypes.func.isRequired,
  }),
  viewingLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

export function mapStateToProps(state) {
  return {
    viewingLocations: state.dataViews.openLocations.length
      ? state.dataViews.openLocations
      : [state.locations.open],
    startDate: state.dataViews.openRange.startDate,
    endDate: state.dataViews.openRange.endDate,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetLocationDataView,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsVendorRoute);
