import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import { Link } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import SubNavSuppliesOrder from '../components/nav/subnav-supplies-order.js';
import EditBudgetCodeProducts from '../components/insights/edit-product-requisitions-budget-codes.js';
import RequisitionsBudgetCodeLinks from '../components/insights/requisitions-budget-code-links.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import {
  openRequisition,
  closeRequisition,
} from '../actions/requisition-actions.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './supplies-order-budget-codes.css';

export class SuppliesOrderBudgetCodes extends React.PureComponent {
  componentDidMount() {
    if (!this.props.requisition || !this.props.requisition.id) {
      this.props.actions.openRequisition({
        id: parseInt(this.props.match.params.id, 10),
      });
    }
  }

  componentDidUpdate() {
    const id = parseInt(this.props.match.params.id, 10);
    if (this.props.requisition.id !== id) {
      this.props.actions.openRequisition({ id });
    }
    if (this.props.isRequesting && !this.props.isRequesting) {
      if (this.shouldRedirectToOrders(this.props)) {
        browserHistory.push('/supplies/orders');
      }
    }
  }

  componentWillUnmount() {
    const backToEditPath = `/supplies/orders/${this.props.match.params.id}`;

    if (window.location.pathname !== backToEditPath) {
      this.props.actions.closeRequisition();
    }
  }

  shouldRedirectToOrders = (props) => {
    return (
      !props.requisition.submitted_at ||
      props.requisition.location_id !== props.openLocation.id
    );
  };

  authorized = () => {
    if (!this.props.requisition.requester_type) return true;
    if (this.props.auth.role.can_manage_all_requisitions) return true;
    if (this.props.requisition.is_public) return true;
    if (
      this.props.requisition.requester_type === this.props.auth.actor.type &&
      this.props.requisition.requester_id === this.props.auth.actor.id
    )
      return true;
    if (
      this.props.requisition.proposer_type === this.props.auth.actor.type &&
      this.props.requisition.proposer_id === this.props.auth.actor.id
    )
      return true;
    return false;
  };

  render() {
    return (
      <div className="supplies-order-budget-codes">
        <AuthCheck customAuthorized={this.authorized()} />
        <MountAtPageTop />
        <NavBarAttendant />
        {this.props.requisition.id > 0 &&
          this.props.requisition.submitted_at && (
            <SubNavSuppliesOrder item={this.props.requisition} />
          )}
        <div className="supplies-order-container fixed-width-column flex-wrapper">
          <div className="supplies-order-budget-codes-edit">
            <Header as="h3" attached="top">
              Assign Categories
            </Header>
            <Segment attached="bottom">
              <EditBudgetCodeProducts
                productRequisitions={
                  this.props.requisition.product_requisitions || []
                }
              />
            </Segment>
          </div>
          <div className="supplies-order-budget-codes-sidebar">
            <Link
              to={`/supplies/orders/${this.props.requisition.id}`}
              className="primary"
            >
              &laquo; Return to Order #{this.props.requisition.id}
            </Link>
            <div className="budget-code-links-container">
              <RequisitionsBudgetCodeLinks />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SuppliesOrderBudgetCodes = setTitle((props) => {
  return 'My Order Categories';
})(SuppliesOrderBudgetCodes);

SuppliesOrderBudgetCodes.propTypes = {
  actions: PropTypes.shape({
    openRequisition: PropTypes.func.isRequired,
    closeRequisition: PropTypes.func.isRequired,
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        customer_budget_code: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }),
      })
    ),
  }).isRequired,
  auth: PropTypes.shape({
    role: PropTypes.shape({
      can_manage_all_requisitions: PropTypes.bool,
    }).isRequired,
    actor: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    requisition: state.requisitions.open,
    openLocation: state.locations.open,
    auth: state.auth,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openRequisition,
        closeRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.open === next.requisitions.open &&
    prev.auth === next.auth &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SuppliesOrderBudgetCodes);
