import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../lib/history.js';
import { Link } from 'react-router-dom';
import setTitle from '../decorators/set-title.js';
import OnboardingStep from '../components/onboarding/step.js';
import Form from '../components/onboarding/team-form.js';
import AuthCheck from '../components/auth-check.js';
import image from '../imgs/onboarding/team.jpeg';
import { isAdmin } from '../helpers/customer-role-helpers.js';

export class OnboardingTeam extends React.Component {
  handleSubmit = () => {
    browserHistory.push(this.props.nextRoute);
  };

  render() {
    return (
      <OnboardingStep backgroundImage={`url(${image})`}>
        <AuthCheck customAuthorized={isAdmin(this.props.roleName)} />
        <Form onSubmit={this.handleSubmit} />
        <Link
          className="skip-link brand-color-override"
          to={this.props.nextRoute}
          style={{
            color: '#00a98c',
            textDecoration: 'none',
          }}
        >
          Skip for Now
          <span>&nbsp;&rsaquo;&rsaquo;</span>
        </Link>
      </OnboardingStep>
    );
  }
}

OnboardingTeam.propTypes = {
  roleName: PropTypes.string.isRequired,
  nextRoute: PropTypes.string.isRequired,
};

OnboardingTeam.defaultProps = {
  nextRoute: '/onboarding/delivery-info',
};

OnboardingTeam = setTitle(() => {
  return 'OfficeLuv Onboarding';
})(OnboardingTeam);

function mapStateToProps(state) {
  return {
    roleName: state.auth.role.name,
    onboarding: state.onboarding,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

function areStatesEqual(prev, next) {
  return prev.onboarding === next.onboarding;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OnboardingTeam);
