import React from 'react';
import PropTypes from 'prop-types';
import { REGEX_URL } from '../../strings.js';
import './text-with-anchors.css';

export default class TextWithAnchors extends React.PureComponent {
  reduceAnchors = (acc, val, index) => {
    const pos = acc.length - 1;
    const pieces = acc[pos].split(val);

    acc[pos] = [pieces.shift(), pieces.join(val)];
    acc[pos].splice(1, 0, this.renderAnchor(val, index + val));
    acc = acc.reduce(this.flattenArray, []);
    return acc;
  };

  flattenArray(acc, val) {
    return acc.concat(val);
  }

  getURLs = () => {
    return (this.props.text || '').match(REGEX_URL) || [];
  };

  handleClickAnchor = (e) => {
    e.stopPropagation();
    if (this.props.onClickAnchor) this.props.onClickAnchor(e);
  };

  renderText = (href) => {
    if (this.props.renderAnchorText) return this.props.renderAnchorText(href);
    return href;
  };

  renderAnchor(href, key) {
    return (
      <a
        key={key}
        href={href}
        onClick={this.handleClickAnchor}
        className="populated-anchor brand-color-override"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {this.renderText(href)}
      </a>
    );
  }

  render() {
    return (
      <span className="text-with-anchors">
        {this.getURLs().reduce(this.reduceAnchors, [this.props.text])}
      </span>
    );
  }
}

TextWithAnchors.propTypes = {
  text: PropTypes.string.isRequired,
  renderAnchorText: PropTypes.func,
  onClickAnchor: PropTypes.func,
};
