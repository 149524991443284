import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { formatDateTime } from '../../lib/formatters.js';
import './item-scheduled-info.css';

export default class RequisitionItemScheduled extends React.PureComponent {
  render() {
    const sr = this.props.scheduled_requisition;
    if (!sr && !this.props.isRequesting) return null;

    return (
      <Segment
        basic
        className="requisition-scheduled-info"
        loading={this.props.isRequesting}
      >
        {sr && (
          <div className="requisition-scheduled">
            <h4 className="requisition-scheduled-title">Recurring Order</h4>
            <div className="schedule-history">
              This order was placed via
              <div>
                <Link className="primary" to={`/orders/scheduled/${sr.id}`}>
                  {sr.name}
                </Link>
              </div>
              approved {formatDateTime(sr.approved_at)}.
              {sr.archived && (
                <div>
                  <em className="gray-text">
                    (This scheduled order was later removed{' '}
                    {formatDateTime(sr.updated_at)}.)
                  </em>
                </div>
              )}
            </div>
          </div>
        )}
      </Segment>
    );
  }
}

RequisitionItemScheduled.propTypes = {
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string,
  }).isRequired,
  scheduled_requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    approved_at: PropTypes.string.isRequired,
    expected_at: PropTypes.string.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

RequisitionItemScheduled.defaultProps = {
  scheduled_requisition_id: 0,
  isRequesting: false,
};
