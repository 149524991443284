import React from 'react';
import { Segment, Accordion, Icon, Header, Message } from 'semantic-ui-react';
import BasicLocationForm from '../basic-location-form.js';
import 'semantic-ui-css/components/accordion.min.css';
import './new-location-form.css';

export class NewLocationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
      success: false,
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  onSubmitSuccess = () => {
    this.setState({ success: true });
    setTimeout(() => {
      this.setState({ success: false });
    }, 5000);
  };

  renderForm() {
    const { activeIndex } = this.state;
    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}>
          <Header as="h3">
            <Icon name="dropdown" />
            Request a New Office
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <p style={{ marginBottom: '1.5rem' }}>
            Use this form to request a new office setup from your OfficeLuv
            manager.
          </p>
          <BasicLocationForm
            isNewLocation
            submitText="Submit"
            onSubmitSuccess={this.onSubmitSuccess}
          />
        </Accordion.Content>
      </Accordion>
    );
  }

  renderSuccess() {
    return (
      <Message positive>
        <Message.Header>Request successful!</Message.Header>
        <p>Your OfficeLuv manager will be in touch shortly.</p>
      </Message>
    );
  }

  render() {
    const { success } = this.state;
    return (
      <div
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
        className="fixed-width-column new-location-form card">
        <Segment>
          {success && this.renderSuccess()}
          {!success && this.renderForm()}
        </Segment>
      </div>
    );
  }
}

NewLocationForm.propTypes = {};

export default NewLocationForm;
