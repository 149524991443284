import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch.js';
import { fetchError, requestID } from './action-helpers.js';
import {
  trackFetchError,
  trackScheduledRequisitionUpdated,
} from '../lib/analytics.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

export const stageScheduledRequisition = (data = {}) => ({
  type: types.STAGE_SCHEDULED_REQUISITION,
  data,
});

export const getScheduledRequisitions = (data) => {
  return {
    type: types.GET_SCHEDULED_REQUISITIONS,
    data,
  };
};

export const successGetScheduledRequisitions = (data) => {
  return {
    type: types.SUCCESS_SCHEDULED_REQUISITIONS,
    data,
  };
};

export const errorGetScheduledRequisitions = (data) => {
  return {
    type: types.ERROR_SCHEDULED_REQUISITIONS,
    data,
  };
};

export const getScheduledRequisition = (data) => {
  return {
    type: types.GET_SCHEDULED_REQUISITION,
    data,
  };
};

export const successGetScheduledRequisition = (data) => {
  return {
    type: types.SUCCESS_GET_SCHEDULED_REQUISITION,
    data,
  };
};

export const errorGetScheduledRequisition = (data) => {
  return {
    type: types.ERROR_GET_SCHEDULED_REQUISITION,
    data,
  };
};

export const postUpdateScheduledRequisition = (data) => {
  return {
    type: types.UPDATE_SCHEDULED_REQUISITION,
    data,
  };
};

export const successUpdateScheduledRequisition = (data) => {
  return {
    type: types.SUCCESS_UPDATE_SCHEDULED_REQUISITION,
    data,
  };
};

export const errorUpdateScheduledRequisition = (data) => {
  return {
    type: types.ERROR_UPDATE_SCHEDULED_REQUISITION,
    data,
  };
};

export const openScheduledRequisition = (data) => {
  return {
    type: types.OPEN_SCHEDULED_REQUISITION,
    data,
  };
};

export const closeScheduledRequisition = (data) => {
  return {
    type: types.CLOSE_SCHEDULED_REQUISITION,
    data,
  };
};

export const createScheduledRequisition = (data) => {
  return {
    type: types.CREATE_SCHEDULED_REQUISITION,
    data,
  };
};

export const successCreateScheduledRequisition = (data) => {
  return {
    type: types.SUCCESS_CREATE_SCHEDULED_REQUISITION,
    data,
  };
};

export const errorCreateScheduledRequisition = (data) => {
  return {
    type: types.ERROR_CREATE_SCHEDULED_REQUISITION,
    data,
  };
};

export function doFindScheduledRequisition(data) {
  return (dispatch) => {
    dispatch(doGetScheduledRequisition(data, { bubble: true })).then((body) => {
      if (body && body.id) {
        dispatch(openScheduledRequisition(body));
        dispatch(stageScheduledRequisition(body));
      }
    });
  };
}

export function doCreateScheduledRequisition(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(createScheduledRequisition(data));
    const location = getState().locations.open;
    data.location_id = location.id;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/scheduled_requisitions`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            message: 'Failed to create scheduled requisition',
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = {
          ...body,
          _request: data._request,
        };
        dispatch(successCreateScheduledRequisition(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateScheduledRequisition(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

/**
 * Get scheduled requisitions
 *
 * @access public
 * @param {string} [paged] Next URL for pagination
 * @returns {Promise}
 */
export function doGetScheduledRequisitions(paged) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    const data = {
      _request: types.GET_SCHEDULED_REQUISITIONS,
      location_id: location.id,
    };
    dispatch(getScheduledRequisitions(data));
    const url =
      paged || `/locations/${location.id}/scheduled_requisitions?limit=100`;
    return fetch(`${ADMIN_V1_URL}${url}`, {
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            message: 'Get scheduled requisitions',
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        body.data.location_id = data.location_id;
        dispatch(successGetScheduledRequisitions(body.data));
        return body;
      })
      .catch((error) => {
        dispatch(errorGetScheduledRequisitions(error));
        trackFetchError(error);
      });
  };
}

/**
 * Get scheduled requisition
 *
 * @access public
 * @returns {Promise}
 */
export function doGetScheduledRequisition(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    const location = locations.open;
    data.location_id = location.id;
    dispatch(getScheduledRequisition(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/scheduled_requisitions/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            message: `Get scheduled requisition ${data.id}`,
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successGetScheduledRequisition(body));
        if (options.bubble) return body;
      })
      .catch((error) => {
        error._request = data._request;
        error.id = data.id;
        dispatch(errorGetScheduledRequisition(error));
        trackFetchError(error);
        if (options.bubble) return error;
      });
  };
}

/**
 * Update scheduled requisition
 *
 * @access public
 * @returns {Promise}
 */
export function submitUpdateScheduledRequisition(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(postUpdateScheduledRequisition(data));
    const location = getState().locations.open;
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/scheduled_requisitions/${data.id}`,
      {
        method: 'PATCH',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            message: 'Update scheduled requisition ' + data.id,
            response,
            data,
          });
        }
        return response.json();
      })
      .then((body) => {
        body._request = data._request;
        dispatch(successUpdateScheduledRequisition(body, data));
        trackScheduledRequisitionUpdated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(errorUpdateScheduledRequisition(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
