import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Header, Divider } from 'semantic-ui-react';
import { FILTER_IS_LOADING } from '../ducks/customer-roles.js';
import AuthCheck from '../components/auth-check.js';
import CustomerRoleDetails from '../components/customerRoles/customer-role-details.js';
import CustomerRoleAdd from '../components/customerRoles/customer-role-add.js';

export class CustomerRoles extends React.PureComponent {
  render() {
    return (
      <Segment
        basic
        className="customer-role-form"
        loading={this.props.isLoading}
      >
        <AuthCheck permissions={{ can_manage_roles: true }} />
        <Fragment>
          <Header as="h1" content={`Roles for ${this.props.customer.name}`} />
          <CustomerRoleDetails />
          <Divider />
          <CustomerRoleAdd />
        </Fragment>
      </Segment>
    );
  }
}

CustomerRoles.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    customer: state.customers.open,
    isLoading:
      state.customerRoles.requesting.filter(FILTER_IS_LOADING).length > 0 ||
      !state.customerRoles.initialized,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.requesting === next.customerRoles.requesting &&
    prev.customerRoles.initialized === next.customerRoles.initialized &&
    prev.customers.open === next.customers.open
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CustomerRoles
);
