import ReactGA from 'react-ga';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import * as types from './action-types';
import cookies from '../lib/encrypted-cookies.js';
import fetch from '../lib/hmac-fetch.js';
import { successGetCustomers } from './customer-actions.js';
import { successLocations } from './locations-actions.js';
import { doGetProductCategories } from './product-category-actions.js';
import {
  findStaffCustomer,
  findStaffLocation,
} from '../helpers/staff-helpers.js';
import { AANG_URL, ENV_STRING, LOCATION_COOKIE } from '../strings';

export const INFLATES =
  'inflate=staff_current_checkins&inflate=checkin_cleaning&inflate=cleaning_location&inflate=location_customer&inflate=location_account_manager&inflate=location_vendors';

const getStaffSelf = () => {
  return {
    type: types.GET_STAFF_SELF,
  };
};

export const successStaffSelf = (data) => {
  ReactGA.set({
    dimension3: 'staff', // set user role dimension
    dimension5: `staff-${data.id}-${ENV_STRING}`, // set user remote id
  });
  return {
    type: types.SUCCESS_STAFF_SELF,
    data,
  };
};

const errorStaffSelf = (data) => {
  return {
    type: types.ERROR_STAFF_SELF,
    data,
  };
};

const staffClockedOut = (data) => {
  cookies.remove(LOCATION_COOKIE);
  return {
    type: types.STAFF_CLOCKED_OUT,
    data,
  };
};

export function doGetStaffSelf(options = { bubble: false }) {
  return (dispatch, getState) => {
    const { auth } = getState();
    dispatch(getStaffSelf());
    return fetch(`${AANG_URL}/staffs/${auth.record.id}?${INFLATES}`)
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: auth.record,
            message: 'Get staff account',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successStaffSelf(body));
        const location = findStaffLocation(body);
        const customer = findStaffCustomer(body);
        if (!location) {
          dispatch(staffClockedOut());
          return;
        }
        dispatch(successGetCustomers([customer]));
        dispatch(successLocations([location]));
        dispatch(doGetProductCategories());
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorStaffSelf(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
