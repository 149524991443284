import * as types from '../actions/action-types.js';
import pkg from '../../package.json';
import { CART_SHOW_COOKIE } from '../strings.js';
import cookies from '../lib/encrypted-cookies.js';

export const empty = {
  cartIsOpen: false,
  initialized: false,
  appVersion: pkg.version,
  appStart: new Date(),
  upgradeReady: false,
  online: window.navigator.onLine,
  activePath: '',
  isRequesting: false,
  errors: [],
  appConfig: {
    freeShipping: false,
    orderMinimum: 10000,
    shippingPrice: 1199,
    alcoholConvenienceFee: 499,
    algolia: {
      application: '',
      apiKeySearch: '',
    },
  },
  announcement: null,
  inLimitedViewingModeChecked: false,
  inLimitedViewingMode: false,
};

export const initial = Object.assign({}, empty, {
  cartIsOpen: cookies.get(CART_SHOW_COOKIE) ? true : false,
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.SET_APP_INITIALIZED:
      return {
        ...state,
        initialized: action.data,
      };
    case types.SET_IN_LIMITED_VIEWING_MODE:
      return {
        ...state,
        inLimitedViewingModeChecked: true,
        inLimitedViewingMode: action.data,
      };
    case types.SET_ACTIVE_PATH:
      return Object.assign({}, state, {
        activePath: action.data.activePath,
      });
    case types.SET_ONLINE_STATUS:
      return Object.assign({}, state, {
        online: action.data.online,
      });
    case types.NEW_APP_VERSION:
      return Object.assign({}, state, {
        appVersion: action.data,
        upgradeReady: action.data > pkg.version,
      });
    case types.GET_APP_CONFIG:
      return Object.assign({}, state, {
        isRequesting: true,
      });
    case types.SUCCESS_GET_APP_CONFIG:
      return Object.assign({}, state, {
        isRequesting: false,
        appConfig: action.data,
      });
    case types.ERROR_GET_APP_CONFIG:
      return Object.assign({}, state, {
        isRequesting: false,
        errors: state.errors.concat(action.data),
      });
    case types.DISMISS_ANNOUNCEMENT:
      return {
        ...state,
        announcement: null,
      };
    case types.SET_CART_OPEN:
      return {
        ...state,
        cartIsOpen: action.data,
      };
    default:
      return state;
  }
};
