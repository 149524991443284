import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavLogo from './../../imgs/ol-logo--teal.svg';
import NavLogoDesktopSmall from './../../imgs/nav-logo.svg';

import './navbar-attendant.css';

export class NavBarSignIn extends React.PureComponent {
  render() {
    return (
      <div
        ref="wrapper"
        className="well-container navbar-attendant navbar-sign-in hide-when-printing"
      >
        <div className="nav-logo">
          <Link to="/" className="logo desktop signin">
            <img src={NavLogoDesktopSmall} alt="OfficeLuv" />
          </Link>
          <Link to="/" className="logo mobile">
            <img src={NavLogo} alt="OfficeLuv" width="30" height="30" />
          </Link>
          {!this.props.online && (
            <span className="nav-online-offline">Offline</span>
          )}
        </div>
        <div className="nav-wrapper">
          <div className="nav-upper signin">
            <div className="flex-item">
              {this.props.actor.type.match(/staff/i) && (
                <Link
                  to="/sign-out"
                  className="sign-out"
                  style={{ lineHeight: '2rem' }}
                >
                  Sign Out
                </Link>
              )}
              {!this.props.actor.id && (
                <Link
                  to="/sign-in"
                  className="sign-in"
                  style={{ lineHeight: '2rem' }}
                >
                  Sign In
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavBarSignIn.propTypes = {
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  online: PropTypes.bool,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  services: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    actor: state.auth.actor,
    online: state.application.online,
    location: state.locations.open,
    services: state.locations.open.services || '',
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.auth.actor === next.auth.actor &&
    prev.application.online === next.application.online &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  NavBarSignIn
);
