import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Message, Segment } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import ShippableForm from '../modules/shippable-form.js';
import PurchasableForm from '../modules/purchasable-form.js';
import PromoCodeField from './promo-code-field.js';
import ApprovalDetails from './approval-details.js';
import StipendDetails from './stipend-details.js';
import ModalDeliveryInfo from '../modals/modal-delivery-info.js';
import { CONTAINS_ALCOHOL } from '../../flags.js';
import { CartPropType } from '../../helpers/cart-helpers.js';
import { doUpdateCredit } from '../../actions/credit-actions.js';
import {
  doCheckout,
  doGetCart,
  doUpdateCart,
  FILTER_IS_LOADING,
  FILTER_IS_SUBMITTING,
} from '../../ducks/carts.js';
import { filterUnapplied, totalAmount } from '../../helpers/credit-helpers.js';
import { locationDeliveryPrefsComplete } from '../../helpers/location-helpers.js';
import { requestID } from '../../actions/action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../../strings.js';
import fetch from '../../lib/hmac-fetch.js';
import './checkout-form.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/segment.min.css';

export class CheckoutForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.constructState(props);
  }

  redirectLink = () => {
    browserHistory.push(
      `/supplies/orders/${this.props.cart.id}?confirm_order=${this.props.cart.id}`
    );
  };

  componentDidMount() {
    if (this.props.cart.id && !this.props.cart.viewed_checkout) {
      let request_ID = requestID();
      fetch(
        `${ADMIN_V1_URL}/locations/${this.props.cart.location_id}/carts/${this.props.cart.id}/update_viewed_checkout`,
        {
          method: 'PATCH',
          headers: {
            ...HEADERS_JSON,
            'X-Request-ID': request_ID,
          },
          body: JSON.stringify({ _request: request_ID, viewed_checkout: true }),
        }
      ).then((response) => {
        if (response.status !== 200) {
          console.error('Error updating cart viewed_checkout');
        }

        this.props.actions.doGetCart({ id: this.props.cart.id });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState(this.constructState(this.props));
      return;
    }
    if (!prevProps.cart.submitted_at && this.props.cart.submitted_at) {
      this.redirectLink();
    }
  }

  componentWillUnmount() {
    if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout);
  }

  constructState(props) {
    let cart = props.cart;
    let state = this.state || {};

    return {
      age_confirmation: cart.age_confirmation,
      shipping_name: state.shipping_name || cart.shipping_name,
      shipping_business: state.shipping_business || cart.shipping_business,
      shipping_care: state.shipping_care || cart.shipping_care,
      shipping_address: state.shipping_address || cart.shipping_address,
      shipping_address_number:
        state.shipping_address_number || cart.shipping_address_number,
      shipping_city:
        state.shipping_city || cart.shipping_city || props.openLocation.city,
      shipping_state: state.shipping_state || cart.shipping_state,
      shipping_country: state.shipping_country || cart.shipping_country,
      shipping_zip: state.shipping_zip || cart.shipping_zip,
      instructions: state.instructions || '',
    };
  }

  onChangeField = (field, value) => {
    let update = {};
    update[field] = value;
    this.setState(update);
  };

  onValidAddress = () => {
    this.props.actions.doUpdateCart({
      id: this.props.cart.id,
      location_id: this.props.cart.location_id,
      shipping_address: this.state.shipping_address,
      shipping_address_number: this.state.shipping_address_number,
      shipping_business: this.state.shipping_business,
      shipping_care: this.state.shipping_care,
      shipping_city: this.state.shipping_city,
      shipping_name: this.state.shipping_name,
      shipping_state: this.state.shipping_state,
      shipping_country: this.state.shipping_country,
      shipping_zip: this.state.shipping_zip,
    });
  };

  isAwaitingApproval = () => {
    return this.props.cart.awaiting_approval_at;
  };

  displaySubmitForApproval = () => {
    return this.props.requiresApproval && !this.isAwaitingApproval();
  };

  displayAwaitingApproval = () => {
    return this.props.requiresApproval && this.isAwaitingApproval();
  };

  isAlcoholCart = () => {
    if (!this.props.cart.product_requisitions) return false;
    return CONTAINS_ALCOHOL(this.props.cart.product_requisitions);
  };

  handleClickAgeConfirmation = () => {
    this.setState((prev) => {
      return {
        age_confirmation: !prev.age_confirmation,
      };
    });
  };

  isDisabled = () => {
    const { cart } = this.props;
    const hasUnmetRequirements =
      cart._optimized_product_requisitions.unmet_minimums.length > 0;
    const requiresPayment =
      this.props.requesterPurchaseRequired && !this.props.hasPurchaseDetails;
    return (
      hasUnmetRequirements ||
      requiresPayment ||
      this.props.deliveryInfoRequired ||
      !this.props.cart.product_requisitions ||
      !this.props.cart.product_requisitions.length ||
      !this.state.shipping_name ||
      !this.state.shipping_address ||
      !this.state.shipping_city ||
      !this.state.shipping_state ||
      !this.state.shipping_zip ||
      !this.state.shipping_name.trim().length ||
      !this.state.shipping_address.trim().length ||
      !this.state.shipping_city.trim().length ||
      !this.state.shipping_state.trim().length ||
      !this.state.shipping_zip.trim().length ||
      (this.isAlcoholCart() && !this.state.age_confirmation)
    );
  };

  applyAllEligibleCreditsToRequisition = () => {
    const cartId = this.props.cart.id;
    let appliableItems = this.props.credits;

    if (!appliableItems.length) return;

    if (this.props.totalPrice < totalAmount(appliableItems)) return;

    appliableItems.forEach((appliableItem) => {
      appliableItem.requisition_id = cartId;
      appliableItem.applied_at_date = new Date();
      return this.props.actions.doUpdateCredit(appliableItem);
    });
  };

  _handleClickSubmitForApproval = () => {
    let data = this.collectFormData();
    data.awaiting_approval_at = new Date();
    this.props.actions.doCheckout(data);
    if (this.props.punchoutSession.id) {
      browserHistory.push('/supplies/checkout-confirmation');
    } else {
      browserHistory.push('/supplies/orders');
    }
  };

  _handleClickCheckout = () => {
    const data = this.collectFormData();
    data.submitted_at = new Date();
    this.applyAllEligibleCreditsToRequisition();
    this.props.actions.doCheckout(data);
  };

  collectFormData = () => {
    const data = {
      id: this.props.cart.id,
      shipping_name: this.state.shipping_name,
      shipping_business: this.state.shipping_business || null,
      shipping_care: this.state.shipping_care || null,
      shipping_address: this.state.shipping_address,
      shipping_address_number: this.state.shipping_address_number || null,
      shipping_city: this.state.shipping_city,
      shipping_state: this.state.shipping_state,
      shipping_country: this.state.shipping_country,
      shipping_zip: this.state.shipping_zip,
      instructions: this.state.instructions || null,
      age_confirmation: this.state.age_confirmation,
      punchout_session_id: this.props.punchoutSession.id || null,
    };
    return data;
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    if (!this.props.requiresApproval) {
      this._handleClickCheckout();
    } else {
      this._handleClickSubmitForApproval();
    }
  };

  paymentSubheader = () => {
    const { cart } = this.props;
    let base = ['Charged to'];
    if (cart.total_price > cart.requester_purchase_price) {
      base.push('your office');
    }
    if (cart.requester_purchase_price > 0) {
      if (base.length >= 2) base.push('and');
      base.push('your payment method');
    }
    return base.join(' ') + '.';
  };

  populateDeliveryField = () => {
    const { cart, lastRequisition } = this.props;
    this.setState({
      instructions: (lastRequisition || {}).instructions || cart.instructions,
    });
  };

  stipendDetails = () => {
    if (!this.props.cart.stipend_details) return {};
    return {
      ...this.props.cart.stipend_details,
      requester_purchase_price:
        this.props.totalPrice -
        (this.props.cart.stipend_details.total_employee_stipend -
          this.props.cart.stipend_details.employee_stipend_spent),
    };
  };

  renderTitle() {
    if (this.isAwaitingApproval() && !this.props.requiresApproval) {
      return <h2>Approve and Checkout</h2>;
    }
    if (this.displayAwaitingApproval()) {
      return <h2>Waiting For Approval</h2>;
    }
    if (this.displaySubmitForApproval()) {
      return <h2>Submit for Approval</h2>;
    }
    if (!this.isAwaitingApproval() && !this.displaySubmitForApproval()) {
      return <h2>Checkout</h2>;
    }
  }

  renderButtonText() {
    if (this.displaySubmitForApproval()) {
      return 'Submit for Approval';
    }
    if (!this.isAwaitingApproval() && !this.displaySubmitForApproval()) {
      return 'Place Order Now';
    }
    if (this.isAwaitingApproval() && !this.props.requiresApproval) {
      return 'Approve and Place Order Now';
    }
    if (this.displayAwaitingApproval()) {
      return 'Submit Changes';
    }
  }

  render() {
    return (
      <Segment basic className="checkout-form-container">
        {this.renderTitle()}
        {!!this.props.cart.id && (
          <>
            <ShippableForm
              isDisabled={
                this.isDisabled() ||
                this.props.isRequesting ||
                this.props.isUpdating
              }
              isRequesting={this.props.isRequesting}
              isLoading={this.props.isRequesting || this.props.isUpdating}
              onAgeConfirmation={this.handleClickAgeConfirmation}
              onChangeField={this.onChangeField}
              onSubmit={this.handleFormSubmit}
              prevInstructions={(this.props.lastRequisition || {}).instructions}
              populateDelivery={this.populateDeliveryField}
              shippable={this.state}
              submitButtonText={this.renderButtonText()}
              isAlcoholAware={this.isAlcoholCart()}
              onValidAddress={this.onValidAddress}>
              <PromoCodeField />
              <Message success style={{ display: 'block' }}>
                <p>
                  Due to high demand, product availability and lead time may
                  vary.
                </p>
                <p>
                  At this time, due to supplier restrictions, we cannot accept
                  refunds or returns on specific products.
                </p>
              </Message>
              {this.props.requiresApproval && (
                <ApprovalDetails {...this.props.cart.approval_details} />
              )}
            </ShippableForm>
            {this.props.requesterPurchaseRequired && (
              <StipendDetails {...this.stipendDetails()} />
            )}
            <PurchasableForm
              allowUseShippingAddress={true}
              allowEdit={true}
              shippingAddress={{
                address1: this.state.shipping_address,
                address2: this.state.shipping_address_number,
                city: this.state.shipping_city,
                state: this.state.shipping_state,
                country: this.state.shipping_country,
                postalCode: this.state.shipping_zip,
              }}
              className="checkout-form-footer"
              requiresPayment={this.props.cart.requester_purchase_price > 0}
              subheader={this.paymentSubheader()}
            />
          </>
        )}
        {this.props.deliveryInfoRequired && <ModalDeliveryInfo />}
        {this.props.deliveryInfoRequired && (
          <Message warning>
            <p>Please fill out delivery preferences before placing orders.</p>
          </Message>
        )}
      </Segment>
    );
  }
}

CheckoutForm.propTypes = {
  shippingPrice: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  cart: CartPropType.isRequired,
  lastRequisition: CartPropType,
  actor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  requiresApproval: PropTypes.bool,
  requesterPurchaseRequired: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  hasPurchaseDetails: PropTypes.bool,
  customer: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  openLocation: PropTypes.shape({
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    doCheckout: PropTypes.func.isRequired,
    doUpdateCart: PropTypes.func.isRequired,
    doUpdateCredit: PropTypes.func.isRequired,
  }).isRequired,
  deliveryInfoRequired: PropTypes.bool.isRequired,
  punchoutSession: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const filterPastRequisitions = (r) => {
  return !!r.submitted_at;
};

const sortRequisitionsSubmitted = (a, b) => {
  return a.submitted_at > b.submitted_at ? -1 : 1;
};

const reduceTotalPrice = (acc, val) => {
  return acc + val.price * val.quantity;
};

function mapStateToProps(state) {
  const cart = state.carts.open;
  const cartItemsPrice = (cart.product_requisitions || []).reduce(
    reduceTotalPrice,
    0
  );
  let shippingPrice =
    cartItemsPrice < state.application.appConfig.orderMinimum
      ? state.application.appConfig.shippingPrice
      : 0;
  if (CONTAINS_ALCOHOL(cart.product_requisitions)) {
    shippingPrice += state.application.appConfig.alcoholConvenienceFee;
  }
  const totalPrice =
    cartItemsPrice +
    (cart.tax || 0) +
    (cart.payment_convenience_price || 0) +
    shippingPrice;
  const actorIsRequester =
    cart.requester_id === state.auth.actor.id &&
    cart.requester_type === state.auth.actor.type;
  return {
    cart,
    totalPrice,
    shippingPrice,
    lastRequisition: state.requisitions.items
      .filter(filterPastRequisitions)
      .sort(sortRequisitionsSubmitted)[0],
    customer: state.customers.open,
    openLocation: state.locations.open,
    credits: filterUnapplied(state.credits.items),
    actor: state.auth.actor,
    requiresApproval:
      state.punchoutSessions.open.id > 0 ||
      ((cart.approval_details || {}).approval_required && actorIsRequester),
    requesterPurchaseRequired: (cart.stipend_details || {})
      .requester_purchase_required,
    hasPurchaseDetails: !!(
      (cart.requester || {}).stripe_customer_id ||
      state.employees.user.stripe_customer_id
    ),
    isRequesting:
      !cart.id ||
      state.carts.requesting
        .filter(FILTER_IS_LOADING)
        .filter(FILTER_IS_SUBMITTING).length > 0,
    isLoading:
      !cart.id || state.carts.requesting.filter(FILTER_IS_LOADING).length > 0,
    isUpdating: state.carts.requesting.filter(FILTER_IS_SUBMITTING).length > 0,
    deliveryInfoRequired:
      !locationDeliveryPrefsComplete(state.locations.open) &&
      state.auth.role.can_manage_location_catalog,
    punchoutSession: state.punchoutSessions.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCheckout,
        doUpdateCredit,
        doUpdateCart,
        doGetCart,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.requesting === next.carts.requesting &&
    prev.requisitions.items === next.requisitions.items &&
    prev.customers.open === next.customers.open &&
    prev.locations.open === next.locations.open &&
    prev.employees.user === next.employees.user &&
    prev.auth.role === next.auth.role &&
    prev.auth.actor === next.auth.actor &&
    prev.credits.items === next.credits.items &&
    prev.application.appConfig === next.application.appConfig &&
    prev.punchoutSessions.open === next.punchoutSessions.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CheckoutForm);
