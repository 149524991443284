import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Menu } from 'semantic-ui-react';
import VendorLink from './vendor-link.js';
import 'semantic-ui-css/components/menu.min.css';
import './budget-code-links.css';

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_line_item_budget_code_dates';
const OL_VENDOR_NAME = 'OfficeLuv';

export class VendorLinks extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this._buildData(props.dataViews),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: this._buildData(nextProps.dataViews),
    });
  }

  _buildData = (dataViews) => {
    let locationsData = this._reduceDataViews(dataViews);
    let aggregateData = this._reducedVendorData(locationsData);
    return Object.values(aggregateData);
  };

  _reduceDataViews = (dataViews) => {
    return dataViews.reduce(this._reduceData, []);
  };

  _reduceData = (acc, val) => {
    return acc.concat(val.data);
  };

  _reducedVendorData = (locationsData) => {
    return locationsData.reduce(this._reduceVendorData, {});
  };

  _reduceVendorData = (acc, val) => {
    if (val.vendor_name && acc[val.vendor_name]) {
      acc[val.vendor_name].total_spend += val.total_spend;
    } else if (!val.vendor_name && acc[OL_VENDOR_NAME]) {
      acc[OL_VENDOR_NAME].total_spend += val.total_spend;
    } else if (!val.vendor_name) {
      acc[OL_VENDOR_NAME] = {
        name: OL_VENDOR_NAME,
        total_spend: 0,
      };
    } else {
      acc[val.vendor_name] = {
        name: val.vendor_name,
        total_spend: val.total_spend,
      };
    }
    return acc;
  };

  _sortData(a, b) {
    return a.id > b.id ? 1 : -1;
  }

  renderLink = (vendor, index) => {
    return <VendorLink vendor={vendor} key={index} />;
  };

  render() {
    return (
      <Menu text vertical className="budget-code-links hide-when-printing">
        <Menu.Item header as="h5">
          Vendor Reports
        </Menu.Item>
        {this.state.data.map(this.renderLink)}
      </Menu>
    );
  }
}

VendorLinks.propTypes = {
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          location_id: PropTypes.number,
          requisition_id: PropTypes.number,
          product_name: PropTypes.string,
          product_id: PropTypes.number,
          budget_code_id: PropTypes.number,
          budget_code_name: PropTypes.string,
          budget_code_code: PropTypes.string,
          submitted_date: PropTypes.string,
          total_spend: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({}).isRequired,
};

export function mapStateToProps(state, props) {
  const locations = props.locations.map((l) => l.id);
  const start = props.startDate.toJSON();
  const end = props.endDate.toJSON();
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === start &&
        d.end === end &&
        locations.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === start &&
          d.end === end &&
          locations.indexOf(d.topic.id) > -1
      ).length > 0 || state.customerBudgetCodes.requesting.length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  VendorLinks
);
