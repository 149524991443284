import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Popup, Header, Segment } from 'semantic-ui-react';
import { doGetRequisition } from '../../actions/requisition-actions.js';
import ItemImage from '../products/item-image.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/popup.min.css';
import 'semantic-ui-css/components/segment.min.css';

const PRODUCT_DISPLAY_MAX = 10;

export class RequisitionParentDetails extends React.PureComponent {
  componentDidMount() {
    this.loadParent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requisition.parent_id !== this.props.requisition.parent_id) {
      this.loadParent();
    }
  }

  loadParent() {
    if (!this.props.requisition.parent_id) return;
    if (this.props.parent) return;

    this.props.actions.doGetRequisition({
      id: this.props.requisition.parent_id,
      location_id: this.props.requisition.location_id,
    });
  }

  renderProductPreview = (ppo) => {
    return (
      <div className="product-image" key={ppo.id}>
        <ItemImage
          width={50}
          height={50}
          size={'thumb'}
          product={ppo.product}
          title={ppo.product.name}
          alt={ppo.product.name}
        />
      </div>
    );
  };

  render() {
    if (!this.props.parent) return null;
    let productReqs = this.props.parent.product_requisitions.slice(
      0,
      PRODUCT_DISPLAY_MAX
    );
    return (
      <Segment
        attached="top"
        style={{
          marginBottom: '2rem',
        }}
        className="hide-when-printing"
        clearing>
        <Header
          as="h4"
          style={{
            width: 'calc(100% - 2rem)',
            display: 'inline-block',
          }}>
          Split from{' '}
          <Link
            to={`/supplies/orders/${this.props.parent.id}`}
            style={{
              fontSize: 'inherit',
            }}>
            Order #{this.props.parent.id}
          </Link>
        </Header>
        <Popup
          content="Orders are sometimes split to ensure speedy delivery"
          trigger={
            <Button
              circular
              style={{
                fontSize: '0.6rem',
              }}
              icon="info"
              size="mini"
            />
          }
        />
        <div
          style={{
            display: 'flex',
          }}>
          {productReqs.map(this.renderProductPreview)}
          {this.props.parent.product_requisitions.length >
            PRODUCT_DISPLAY_MAX && (
            <div className="purchase-order-item-extra-product-count light-gray-text">
              {this.props.parent.product_requisitions.length -
                PRODUCT_DISPLAY_MAX}{' '}
              More Items
            </div>
          )}
        </div>
      </Segment>
    );
  }
}

RequisitionParentDetails.propTypes = {
  requisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    child_ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  parent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  actions: PropTypes.shape({
    doGetRequisition: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    parent: state.requisitions.items.filter(function (req) {
      return req.id === props.requisition.parent_id;
    })[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.requisitions.items === next.requisitions.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(RequisitionParentDetails);
