import fetch from 'unfetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import cookies from '../lib/encrypted-cookies.js';
import perf from '../lib/perf.js';
import ReactGA from 'react-ga';
import MD5 from 'crypto-js/md5';
import HMACSHA1 from 'crypto-js/hmac-sha1';
import BASE64 from 'crypto-js/enc-base64';
import { AUTH_COOKIE, CLIENT } from '../strings.js';
import { CATEGORY_XHR, VARIABLE_LOAD } from './analytics.js';
import { requestID } from '../actions/action-helpers.js';

let abortController = new AbortController();
export const regenerateController = () => {
  abortController.abort();
  return (abortController = new AbortController());
};

function hmacFetch() {
  const defaults = {
    method: 'GET',
    headers: {},
  };
  const auth = Object.assign({}, cookies.getJSON(AUTH_COOKIE));
  const now = new Date().toGMTString();
  const username_hmac = (auth.hmac || {}).username || '';
  const secret_hmac = (auth.hmac || {}).secret || 'foobar';
  let options = Object.assign({}, defaults, arguments[1] || {});
  let body_md5 = MD5(options.body).toString();
  let signing_string = `x-date: ${now}\ncontent-md5: ${body_md5}`;
  let hmac_sha1 = HMACSHA1(signing_string, secret_hmac);
  let signature = hmac_sha1.toString(BASE64);
  if (arguments[0].match('locations')) {
    options.signal = abortController.signal;
  }
  options.headers['x-officeluv-client'] = CLIENT;
  options.headers['x-date'] = now;
  options.headers['content-md5'] = body_md5;
  options.headers[
    'authorization'
  ] = `hmac username="${username_hmac}", algorithm="hmac-sha1", headers="x-date content-md5", signature="${signature}"`;
  if (!options.headers['X-Request-ID'] || !options.headers['x-request-id']) {
    options.headers['X-Request-ID'] = requestID();
  }
  const callName = (options.method || 'GET') + ':' + arguments[0].split('?')[0];
  perf.start(callName);
  return (window.fetch || fetch)
    .call(this, arguments[0], options)
    .then((resp) => {
      perf.end(callName);
      if (window.ODEBUG) {
        perf.log(callName);
      } else {
        ReactGA.timing({
          category: CATEGORY_XHR,
          variable: VARIABLE_LOAD,
          value: perf.duration(callName),
          label: callName,
        });
      }
      return resp;
    });
}

export default hmacFetch;
