import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import EditBudgetCodeProducts from '../components/insights/edit-location-list-products-budget-codes.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './supply-levels-budget-codes.css';

export class SuppliesSupplyLevelsBudgetCode extends React.PureComponent {
  render() {
    return (
      <div className="supply-levels-budget-codes">
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <div className="supply-levels-container fixed-width-column flex-wrapper">
          <div className="supply-levels-budget-codes-edit">
            <Header as="h3" attached="top">
              Assign Categories
            </Header>
            <Segment attached="bottom">
              <EditBudgetCodeProducts
                locationListProducts={this.props.locationListProducts}
              />
            </Segment>
          </div>
          <div className="supplies-order-budget-codes-sidebar">
            <Link to="/supplies/levels" className="primary">
              &laquo; Return to List
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

SuppliesSupplyLevelsBudgetCode = setTitle((props) => {
  return 'Set Supply Categories';
})(SuppliesSupplyLevelsBudgetCode);

SuppliesSupplyLevelsBudgetCode.propTypes = {
  locationListProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  return {
    locationListProducts: state.locationListProducts.items.filter(
      (llp) => llp.location_list_id === state.locationLists.open.id
    ),
    locationList: state.locationLists.open,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        // in the future, we will need to explicitly open the locationList here
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locationLists.open === next.locationLists.open &&
    prev.locationListProducts.items === next.locationListProducts.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SuppliesSupplyLevelsBudgetCode);
