import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import withWindowSizes from '../../decorators/with-window-sizes.js';
import InsightsControls from './controls.js';
import CustomerPurchaseLineGraph from './customer-purchase-line-graph.js';
import BudgetCodeProducts from './budget-code-products.js';
import {
  openDataViewRange,
  openDataViewLocations,
} from '../../actions/data-view-actions.js';
import './insights-budget-code.css';

export class VendorReport extends React.PureComponent {
  _onChangeDates = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
  };

  _onChangeLocations = (locations) => {
    this.props.actions.openDataViewLocations(locations);
  };

  dataFilter = (row) => {
    if (
      !row.vendor_name &&
      row.customer_purchase_id &&
      this.props.vendorName === 'OfficeLuv'
    ) {
      return true;
    }
    return row.vendor_name === this.props.vendorName;
  };

  dataReducer = (acc, val) => {
    if (val.vendor_name !== this.props.vendorName) {
      acc.push({
        interval: val.submitted_date,
        lineValue: 0,
      });
      return acc;
    }
    acc.push({
      interval: val.submitted_date,
      lineValue: val.customer_purchase_line_items_total_spend,
    });
    return acc;
  };

  render() {
    return (
      <div>
        <InsightsControls
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          openLocations={this.props.viewingLocations}
          windowWidth={this.props.windowWidth}
          onChangeDates={this._onChangeDates}
          onChangeLocations={this._onChangeLocations}
        />
        <div className="insights-budget-code-chart">
          <CustomerPurchaseLineGraph
            windowWidth={this.props.windowWidth}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            locations={this.props.viewingLocations}
            dataViewName="customer_purchase_vendor_dates"
            dataReducer={this.dataReducer}
            title={this.props.vendorName}
            budgetCode={{
              id: this.props.vendorName,
              name: this.props.vendorName,
              code: this.props.vendorName,
            }}
          />
        </div>
        <div className="insights-budget-code-products">
          <BudgetCodeProducts
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            locations={this.props.viewingLocations}
            budgetCode={{
              id: this.props.vendorName,
              name: this.props.vendorName,
              code: this.props.vendorName,
            }}
            dataFilter={this.dataFilter}
          />
        </div>
      </div>
    );
  }
}

VendorReport.propTypes = {
  vendorName: PropTypes.string.isRequired,
  viewingLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

export function mapStateToProps(state) {
  return {
    viewingLocations: state.dataViews.openLocations.length
      ? state.dataViews.openLocations
      : [state.locations.open],
    startDate: state.dataViews.openRange.startDate,
    endDate: state.dataViews.openRange.endDate,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openDataViewRange,
        openDataViewLocations,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.locations.open === next.locations.open
  );
}

const VendorReportWithWindowResize = withWindowSizes(VendorReport);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(VendorReportWithWindowResize);
