import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Header } from 'semantic-ui-react';
import ModalStipend from '../modals/modal-stipend.js';
import ButtonUnstyled from '../button-unstyled.js';
import { formatCentAmount } from '../../lib/formatters.js';
import { doGetCart, FILTER_IS_LOADING_CART } from '../../ducks/carts.js';
import './navbar-stipend.css';

export class NavbarStipend extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }
  componentDidMount() {
    if (!this.shouldGetCart()) return;

    this.props.actions.doGetCart(this.props.cart);
  }

  componentDidUpdate() {
    if (!this.shouldGetCart()) return;
    this.props.actions.doGetCart(this.props.cart);
  }

  shouldGetCart = () => {
    if (!this.props.cart.id) return false;
    if (this.props.isLoading) return false;
    if (typeof this.props.monthlyEmployeeStipend !== 'number') return false;

    return true;
  };

  setModalOpen = (open) => {
    this.setState({ modalOpen: open });
  };

  render() {
    const stipend_remaining = this.props.employee.stipend_remaining;

    if (typeof stipend_remaining !== 'number') return null;
    if (this.props.cart.requester_id !== this.props.employee.id) return null;

    return (
      <div className="navbar-stipend">
        <ButtonUnstyled onClick={() => this.setState({ modalOpen: true })}>
          <Header
            as="h3"
            className={classnames('navbar-stipend__balance', {
              'navbar-stipend__balance--positive': stipend_remaining > 0,
              'brand-color-override': stipend_remaining > 0,
            })}
          >
            {formatCentAmount(this.props.employee.stipend_remaining)}
          </Header>
          <p>Available</p>
        </ButtonUnstyled>
        <ModalStipend
          modalOpen={this.state.modalOpen}
          setModalOpen={this.setModalOpen}
        />
      </div>
    );
  }
}

NavbarStipend.propTypes = {
  isLoading: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stipend_remaining: PropTypes.number,
  }).isRequired,
  cart: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    doGetCart: PropTypes.func.isRequired,
  }).isRequired,
};

NavbarStipend.defaultProps = {};

export function mapStateToProps(state) {
  return {
    cart: state.carts.open,
    employee: state.employees.user,
    isLoading:
      state.carts.requesting.filter(FILTER_IS_LOADING_CART(state.carts.open))
        .length > 0,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.requesting === next.carts.requesting &&
    prev.employees.user.id === next.employees.user.id
  );
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetCart,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(NavbarStipend);
