import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showProductModal } from '../../actions/product-actions.js';
import {
  favoriteProduct,
  unFavoriteProduct,
} from '../../actions/location-list-product-actions.js';
import { PRODUCT_MODAL_TABS } from '../../strings.js';
import heartIcon from '../../imgs/ol-logo-heart.js';
import heartIconOutline from '../../imgs/ol-logo-heart--outline.svg';

export class ItemFavorite extends React.PureComponent {
  favoriteItem = (e) => {
    e.stopPropagation();

    if (this.props.isInLocationList) {
      if (
        this.props.locationListIds.length > 1 ||
        !this.props.canManageLocationLists
      ) {
        this.props.actions.showProductModal({
          ...this.props.item,
          initialProductModalTab: PRODUCT_MODAL_TABS.TAB_STOCK,
        });
        return;
      } else {
        if (!this.props.canManageLocationLists) return;
        this.props.actions.showProductModal({
          ...this.props.item,
          initialProductModalTab: PRODUCT_MODAL_TABS.TAB_STOCK,
        });
        return;
      }
    }
    if (!this.props.canManageLocationLists) return;

    this.props.actions.showProductModal({
      ...this.props.item,
      initialProductModalTab: PRODUCT_MODAL_TABS.TAB_STOCK,
    });
  };

  render() {
    if (this.props.canManageLocationCatalog && !this.props.inLimitedViewingMode)
      return null;

    if (this.props.canFavorite) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <img
            src={
              this.props.isInLocationList
                ? heartIcon({ color: this.props.brandColor })
                : heartIconOutline
            }
            className="product-item__favorite hvr-grow"
            alt="favorite product"
            title={
              this.props.isInLocationList
                ? 'Manage this product in your supplies lists'
                : 'Favorite this product'
            }
            onClick={this.favoriteItem}
          />
        </div>
      );
    }
    return null;
  }
}

ItemFavorite.propTypes = {
  actions: PropTypes.shape({
    showProductModal: PropTypes.func.isRequired,
    favoriteProduct: PropTypes.func.isRequired,
  }).isRequired,
  brandColor: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    unit_purchase: PropTypes.string.isRequired,
    stock_per_purchase: PropTypes.number.isRequired,
    unit_stock: PropTypes.string.isRequired,
  }).isRequired,
  isInLocationList: PropTypes.bool.isRequired,
  canFavorite: PropTypes.bool.isRequired,
  canManageLocationCatalog: PropTypes.bool.isRequired,
  canManageLocationLists: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, props) {
  return {
    locationListIds: state.products.in_location_lists[props.item.id],
    isInLocationList:
      !!state.products.in_location_lists[props.item.id] &&
      !!state.products.in_location_lists[props.item.id].length,
    canManageLocationCatalog:
      state.locations.open.pref_enable_limited_catalog &&
      state.auth.role.can_manage_location_catalog,
    canManageLocationLists: state.auth.role.can_manage_location_lists,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    brandColor: state.locations.open.brand_color,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        favoriteProduct,
        unFavoriteProduct,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.products.in_location_lists === next.products.in_location_lists &&
    prev.auth.role === next.auth.role &&
    prev.locations.open.pref_enable_limited_catalog ===
      next.locations.open.pref_enable_limited_catalog &&
    prev.locations.open.brand_color === next.locations.open.brand_color &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ItemFavorite);
