import React, { useContext, useEffect, useState } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CarouselContext,
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Icon } from 'semantic-ui-react';
import BannerItem from './banner-item.js';
import './banner-items.css';

/*** pure react carousel has no callback prop for slide changes,
so you have to get creative:
https://github.com/express-labs/pure-react-carousel#hooks-and-usecontext
***/
const SlideChangeListener = (props) => {
  const carouselContext = useContext(CarouselContext);
  const { onSlideChange } = props;
  useEffect(() => {
    function onChange() {
      onSlideChange(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, onSlideChange]);
  return null;
};

const BannerItems = (props) => {
  const [isPlaying, setIsPlaying] = useState(true);

  if (!props.bannerItems.length) return null;

  const onSlideChange = (currentSlide) => {
    /* Stop playing when you reach the end of the carousel. */
    if (currentSlide + 1 === props.bannerItems.length) {
      setIsPlaying(false);
    }
  };

  return (
    <div className="banner-items__wrap">
      <CarouselProvider
        className="banner-items"
        dragEnabled={false}
        interval={8000}
        isPlaying={isPlaying}
        naturalSlideWidth={959}
        naturalSlideHeight={274}
        totalSlides={props.bannerItems.length}
        infinite={true}>
        <SlideChangeListener onSlideChange={onSlideChange} />
        <Slider classNameAnimation="animation_custom">
          {props.bannerItems.map((item, index) => (
            <BannerItem
              key={item.id}
              index={index}
              imageSrc={item.image_url}
              header={item.headline}
              description={item.description}
              url={item.url}
              buttonText={item.button_text}
            />
          ))}
        </Slider>
        {props.bannerItems.length > 1 && (
          <ButtonBack className="banner-items__button back">
            <Icon name="angle left" size="big" />
          </ButtonBack>
        )}
        {props.bannerItems.length > 1 && (
          <ButtonNext className="banner-items__button next">
            <Icon name="angle right" size="big" />
          </ButtonNext>
        )}
      </CarouselProvider>
    </div>
  );
};

BannerItems.propTypes = {
  bannerItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      url: PropTypes.string,
      headline: PropTypes.string,
      description: PropTypes.string,
      button_text: PropTypes.string,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  return {
    bannerItems: state.bannerItems.items,
  };
}

export function areStatesEqual(prev, next) {
  return prev.bannerItems.items === next.bannerItems.items;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  BannerItems
);
