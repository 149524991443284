import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import browserHistory from '../lib/history.js';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import InsightsBudgetCode from '../components/insights/insights-budget-code.js';
import BudgetCodeLinks from '../components/insights/budget-code-links.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import {
  openCustomerBudgetCode,
  closeCustomerBudgetCode,
} from '../actions/customer-budget-codes-actions.js';
import './insights-budget-code.css';

export class InsightsBudgetCodeRoute extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openCustomerBudgetCode({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate() {
    const id = parseInt(this.props.match.params.id, 10);
    if (this.props.openBudgetCode.id !== id) {
      this.props.actions.openCustomerBudgetCode({ id });
      return;
    }
    if (
      !this.props.isRequesting &&
      this.props.openBudgetCode.customer_id &&
      this.props.openBudgetCode.customer_id !==
        this.props.openLocation.customer_id
    ) {
      browserHistory.push('/insights/budgets');
    }
  }

  componentWillUnmount() {
    this.props.actions.closeCustomerBudgetCode();
  }

  render() {
    return (
      <div className="insights-budget-code-route">
        <AuthCheck permissions={{ can_manage_insights: true }} />
        <MountAtPageTop />
        <NavBarAttendant />
        <div className="variable-width-column">
          <InsightsBudgetCode budgetCode={this.props.openBudgetCode} />
          {this.props.displayCodes.length > 0 && (
            <div className="links-container">
              <Link
                to="/insights/budgets"
                className="budget-insights-link primary"
              >
                &laquo; Return to Insights
              </Link>
              <BudgetCodeLinks
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                locations={this.props.viewingLocations}
                displayCodes={this.props.displayCodes}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

InsightsBudgetCodeRoute = setTitle((props) => {
  return (props.openBudgetCode.name || '') + ' OfficeLuv Budget Code Insights';
})(InsightsBudgetCodeRoute);

InsightsBudgetCodeRoute.propTypes = {
  openBudgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  viewingLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  displayCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    openCustomerBudgetCode: PropTypes.func.isRequired,
    closeCustomerBudgetCode: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    openBudgetCode: state.customerBudgetCodes.open,
    isRequesting: state.customerBudgetCodes.requesting.length > 0,
    openLocation: state.locations.open,
    viewingLocations: state.dataViews.openLocations.length
      ? state.dataViews.openLocations
      : [state.locations.open],
    startDate: state.dataViews.openRange.startDate,
    endDate: state.dataViews.openRange.endDate,
    displayCodes: state.customerBudgetCodes.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCustomerBudgetCode,
        closeCustomerBudgetCode,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.open === next.customerBudgetCodes.open &&
    prev.customerBudgetCodes.requesting ===
      next.customerBudgetCodes.requesting &&
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items &&
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InsightsBudgetCodeRoute);
