import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import log from '../lib/log.js';
import { setOnlineStatus } from '../actions/application-actions.js';
const logger = log('[online]');

export class Online extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    actions: PropTypes.shape({
      setOnlineStatus: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    window.addEventListener('online', this._handleOnline);
    window.addEventListener('offline', this._handleOffline);
    if (window.navigator.onLine) {
      logger.log('connected');
    } else {
      logger.warn('disconnected');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this._handleOnline);
    window.removeEventListener('offline', this._handleOffline);
  }

  _handleOnline = () => {
    logger.log('connected');
    this.props.actions.setOnlineStatus(true);
    if (this.props.onChange) this.props.onChange(true);
  };

  _handleOffline = () => {
    logger.warn('disconnected');
    this.props.actions.setOnlineStatus(false);
    if (this.props.onChange) this.props.onChange(false);
  };

  render() {
    return null;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setOnlineStatus,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Online);
