import React from 'react';
import PropTypes from 'prop-types';
import ItemImage from '../products/item-image.js';
import './search-item-product.css';

export default class SearchItemProduct extends React.PureComponent {
  render() {
    const reg = new RegExp(this.props.query, 'i');
    const def = { 0: '', index: 0 };
    const matchName = this.props.suggestion.name
      ? this.props.suggestion.name.match(reg) || def
      : def;
    const matchBrand = this.props.suggestion.brand
      ? this.props.suggestion.brand.match(reg) || def
      : def;
    return (
      <div className="search-item-product">
        <div className="search-item-product-image">
          <ItemImage product={this.props.suggestion} size={'thumb'} />
        </div>
        <div className="search-item-product-brand-and-name">
          <div className="search-item-product-brand">
            {this.props.suggestion.brand.slice(0, matchBrand.index)}
            <strong>
              {this.props.suggestion.brand.slice(
                matchBrand.index,
                matchBrand.index + matchBrand[0].length
              )}
            </strong>
            {this.props.suggestion.brand.slice(
              matchBrand.index + matchBrand[0].length
            )}
          </div>
          <div className="search-item-product-name">
            {this.props.suggestion.name.slice(0, matchName.index)}
            <strong>
              {this.props.suggestion.name.slice(
                matchName.index,
                matchName.index + matchName[0].length
              )}
            </strong>
            {this.props.suggestion.name.slice(
              matchName.index + matchName[0].length
            )}
          </div>
        </div>
      </div>
    );
  }
}

SearchItemProduct.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
  }).isRequired,
};
