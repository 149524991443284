import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar.js';
import {
  formatTime,
  formatDateTime,
  formatStaffName,
} from '../../lib/formatters.js';
import {
  REDUCE_LATEST_CLOCKOUT,
  REDUCE_LATEST_EXPECTED_AT,
  REDUCE_EARLIEST_CREATED_AT,
  MINUTE_MILLIS,
  HOUR_MILLIS,
  DAY_MILLIS,
} from '../../strings.js';
import '../staffs/item.css';

export default class CleaningStaffItem extends React.PureComponent {
  getRunningLateAmount = () => {
    let minuteDiff = 0,
      status = null;

    minuteDiff =
      this.getLatestExpectation() - new Date(this.props.startDatetime);
    minuteDiff = Math.round(
      ((minuteDiff % DAY_MILLIS) % HOUR_MILLIS) / MINUTE_MILLIS
    );
    if (minuteDiff > 0) {
      status = `Running ${minuteDiff} minutes late.`;
    }

    return status;
  };

  getLatestExpectation = () => {
    if (!this.props.checkinExpectations.length) {
      return this.props.startDatetime;
    }
    return this.props.checkinExpectations.reduce(
      REDUCE_LATEST_EXPECTED_AT,
      new Date(this.props.startDatetime)
    );
  };

  getEarliestCheckin = () => {
    if (!this.props.checkins.length) {
      return this.props.startDatetime;
    }
    return this.props.checkins.reduce(
      REDUCE_EARLIEST_CREATED_AT,
      new Date(this.props.endDatetime)
    );
  };

  displayStartTime = () => {
    if (this.isRunningLate()) {
      return new Date(this.getLatestExpectation());
    } else if (!this.props.checkins.length) {
      return new Date(this.props.startDatetime);
    }
    return this.getEarliestCheckin();
  };

  displayEndTime = () => {
    if (this.props.checkins.length < 1) {
      return this.props.endDatetime;
    }
    if (this.isClockedIn() || this.isOnBreak()) {
      return this.props.endDatetime;
    }
    return this.props.checkins.reduce(
      REDUCE_LATEST_CLOCKOUT,
      new Date(this.props.startDatetime)
    );
  };

  isClockedIn = () => {
    return (
      this.props.checkins.filter((c) => {
        return c.id && !c.checkout_time;
      }).length > 0
    );
  };

  isClockedOut = () => {
    return (
      this.props.checkins.length > 0 &&
      this.props.checkins.filter((c) => {
        return c.id && !c.checkout_time;
      }).length === 0
    );
  };

  isOnBreak = () => {
    return (
      this.isClockedOut() &&
      new Date(this.props.endDatetime).getTime() - new Date().getTime() >
        MINUTE_MILLIS * 30
    );
  };

  isRunningLate = () => {
    if (this.isClockedIn() || this.isClockedOut()) {
      return false;
    }
    return this.props.checkinExpectations.length > 0;
  };

  render() {
    return (
      <div className="staff">
        <div className="staff-avatar">
          <Avatar width={47} height={47} item={this.props.staff} />
          {this.isClockedIn() && (
            <span className="staff-avatar-clocked-in"></span>
          )}
        </div>
        <div className="staff-name">
          {formatStaffName(this.props.staff)}
          {this.props.showTimes && (
            <div className="staff-times light-gray-text">
              <span title={formatDateTime(this.displayStartTime())}>
                {formatTime(this.displayStartTime())}
              </span>
              &ndash;
              <span title={formatDateTime(this.displayEndTime())}>
                {formatTime(this.displayEndTime())}
              </span>
            </div>
          )}
          {this.props.showStatus && this.isClockedIn() && (
            <div className="staff-name-clocked-in brand-color-override">
              Clocked In
            </div>
          )}
          {this.props.showStatus &&
            this.isClockedOut() &&
            !this.isOnBreak() && (
              <div className="staff-name-status">Clocked Out</div>
            )}
          {this.props.showStatus && this.isOnBreak() && (
            <div className="staff-name-status">On Break</div>
          )}
          {this.props.showStatus && this.isRunningLate() && (
            <div className="staff-name-status">
              {this.getRunningLateAmount()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CleaningStaffItem.propTypes = {
  staff: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  startDatetime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  endDatetime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  showTimes: PropTypes.bool,
  showStatus: PropTypes.bool,
  checkins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      staff_id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      cleaner_note: PropTypes.string,
      checkout_time: PropTypes.string,
    })
  ).isRequired,
  checkinExpectations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      staff_id: PropTypes.number.isRequired,
      cleaning_id: PropTypes.number.isRequired,
      expected_at: PropTypes.string.isRequired,
      note: PropTypes.note,
    })
  ).isRequired,
};
