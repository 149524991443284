import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table, Header, Accordion } from 'semantic-ui-react';
import {
  doGetEmployeeStripeAccount,
  submitEmployeeStripeExternalAccount,
} from '../../actions/employee-actions.js';
import CreditCardInformation from '../credit-card-information.js';

export class EmployeeBillingInfo extends React.PureComponent {
  state = {
    editing: false,
  };

  infoPanel = [
    {
      key: 'info_what',
      title: {
        content: 'What is this for?',
        icon: 'dropdown',
      },
      content: {
        content: (
          <div>
            <p>
              For offices set up with team member stipends, individuals may have
              to pay for items that exceed their stipend. Any spend over the
              stipend will be charged to this payment method.
            </p>
          </div>
        ),
      },
    },
  ];

  componentDidMount() {
    if (this.props.employee.id) {
      this.props.actions.doGetEmployeeStripeAccount(this.props.employee);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employee.id !== this.props.employee.id) {
      this.props.actions.doGetEmployeeStripeAccount(this.props.employee);
    }
  }

  handleToken = (payment_method_id) => {
    const data = {
      id: this.props.employee.id,
      stripe: {
        payment_method_id,
      },
    };
    this.props.actions
      .submitEmployeeStripeExternalAccount(data, {
        method: this.props.employee.stripe_customer_id ? 'PATCH' : 'POST',
        confirm: true,
        bubble: true,
      })
      .then((responseBody) => {
        if (!responseBody) return;

        this.setState({ editing: false });
      });
  };

  toggleEdit = () => {
    this.setState((prevState) => {
      return { editing: !prevState.editing };
    });
  };

  renderHeader() {
    return (
      <Fragment>
        <Header as="h2" content="Employee Billing Info" />
        <Accordion defaultActiveIndex={-1} panels={this.infoPanel} />
      </Fragment>
    );
  }

  renderCardDetails(stripe) {
    return (
      <div className="card" style={{ marginBottom: '2rem', marginTop: '2rem' }}>
        {this.renderHeader()}
        <Table basic="very" className="stripe-billing-info">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Payment Method</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {this.props.active && <em>Preferred Method</em>}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <p>Credit Card</p>
              </Table.Cell>
              <Table.Cell>
                <p>
                  {stripe.payment_method.card.brand} ***{' '}
                  {stripe.payment_method.card.last4}
                </p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button
          size="mini"
          compact
          className="tertiary"
          onClick={this.toggleEdit}>
          Edit
        </Button>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="card" style={{ marginBottom: '2rem', marginTop: '2rem' }}>
        {this.renderHeader()}
        <CreditCardInformation
          loading={this.props.isRequesting}
          renderSubmitButton={true}
          renderCancelButton={this.state.editing}
          onCancel={this.toggleEdit}
          onToken={this.handleToken}
        />
      </div>
    );
  }

  render() {
    if (!this.props.hasSavedCreditCard) return this.renderForm();
    if (this.state.editing) return this.renderForm();

    return this.renderCardDetails(this.props.employee.external_accounts.stripe);
  }
}

export function mapStateToProps(state) {
  const employee = state.employees.user;
  return {
    employee,
    isRequesting: !state.employees.user.id,
    hideComponent: !state.employees.stipend_remaining,
    hasSavedCreditCard:
      employee.external_accounts &&
      employee.external_accounts.stripe &&
      employee.external_accounts.stripe.payment_method &&
      employee.external_accounts.stripe.payment_method.card.brand,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetEmployeeStripeAccount,
        submitEmployeeStripeExternalAccount,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EmployeeBillingInfo);
