import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cart from './cart.js';
import stickybits from 'stickybits';
import { setCartOpen } from '../../actions/application-actions.js';
import './cart-flex.css';

export class RequisitionCartFlex extends React.PureComponent {
  componentDidMount() {
    this.sticky = stickybits('.requisition-cart-sticky', {
      useStickyClasses: true,
      stickyBitStickyOffset: 0,
    });
    window.addEventListener('scroll', this.debounceScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartIsOpen && !prevProps.cartIsOpen) {
      this.sticky = stickybits('.requisition-cart-sticky', {
        useStickyClasses: true,
        stickyBitStickyOffset: 0,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debounceScroll);
    if (this.debounceTimeout) {
      window.clearTimeout(this.debounceTimeout);
    }
  }

  debounceScroll = () => {
    if (this.debouncingScroll) return;
    this.debouncingScroll = true;
    this.debounceTimeout = setTimeout(() => {
      this.debouncingScroll = false;
      this.sticky && this.sticky.update();
    }, 500);
  };

  hideCart = () => {
    this.props.actions.setCartOpen(false);
  };

  render() {
    if (!this.props.cartIsOpen) return null;

    return (
      <div ref="wrapper" className="requisition-cart-flex flex-item">
        <div className="requisition-cart-sticky">
          <Cart
            parentComponent={'cart'}
            canSwitchCarts={this.props.canSwitchCarts}
            hideCart={this.hideCart}
            hideable={true}
          />
        </div>
      </div>
    );
  }
}

RequisitionCartFlex.propTypes = {
  canSwitchCarts: PropTypes.bool,
  cartIsOpen: PropTypes.bool,
};

RequisitionCartFlex.defaultProps = {
  canSwitchCarts: false,
};

function mapStateToProps(state) {
  return {
    cartIsOpen: state.application.cartIsOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setCartOpen,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.application.cartIsOpen === next.application.cartIsOpen;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(RequisitionCartFlex);
