import * as storage from '../lib/localstorage.js';
import * as types from '../actions/action-types.js';
import {
  STORAGE_USER,
  STORAGE_EMPLOYEES,
  STORAGE_CLEANINGS,
  STORAGE_CUSTOMER,
  STORAGE_REQUISITIONS,
  STORAGE_LOCATION_LIST,
  STORAGE_LOCATION_LIST_PRODUCTS,
  STORAGE_STAFF_USER,
  STORAGE_OPEN_LOCATION,
} from '../strings.js';

const storer = (store) => (next) => (action) => {
  let result = next(action);

  switch (action.type) {
    case types.SUCCESS_UPDATE_EMPLOYEE:
      storage.setItemAsync(STORAGE_EMPLOYEES, store.getState().employees.items);
      if (action.data.id === store.getState().employees.user.id)
        storage.setItemAsync(
          STORAGE_USER,
          Object.assign({}, action.data, { lastFetch: false })
        );
      break;
    case types.SUCCESS_EMPLOYEE_SELF:
      storage.setItemAsync(STORAGE_EMPLOYEES, store.getState().employees.items);
      storage.setItemAsync(
        STORAGE_USER,
        Object.assign({}, action.data, { lastFetch: false })
      );
      break;
    case types.SUCCESS_STAFF_SELF:
      storage.setItemAsync(
        STORAGE_STAFF_USER,
        Object.assign({}, action.data, {
          lastFetch: false,
          current_checkins: [],
        })
      );
      break;
    case types.SIGN_OUT:
    case types.STAFF_CLOCKED_OUT:
      storage.removeItem(STORAGE_USER);
      storage.removeItem(STORAGE_STAFF_USER);
      storage.removeItem(STORAGE_EMPLOYEES);
      storage.removeItem(STORAGE_CUSTOMER);
      storage.removeItem(STORAGE_CLEANINGS);
      storage.removeItem(STORAGE_REQUISITIONS);
      storage.removeItem(STORAGE_LOCATION_LIST);
      storage.removeItem(STORAGE_LOCATION_LIST_PRODUCTS);
      storage.removeItem(STORAGE_OPEN_LOCATION);
      break;
    default:
      break;
  }
  return result;
};

export default storer;
