import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  Segment,
  Form,
  Checkbox,
  Header,
  Button,
  Message,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import {
  updateStagedCustomerRole,
  doSubmitStagedCustomerRole,
  FILTER_IS_SAVE_SUCCESS,
  FILTER_IS_SUBMITTING,
  FILTER_IS_ERROR,
} from '../../ducks/customer-roles.js';
import { buildPermissionText } from '../../helpers/customer-role-helpers.js';

export class CustomerRoleForm extends React.PureComponent {
  state = {
    requiresApproval: false,
  };

  componentDidMount() {
    if (this.props.isNewRecord) return;

    if (
      this.props.customerRole.customer_role_approvers &&
      this.props.customerRole.customer_role_approvers.length
    ) {
      this.setState({ requiresApproval: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isNewRecord) return;

    if (
      !prevProps.customerRole.customer_role_approvers &&
      this.props.customerRole.customer_role_approvers &&
      this.props.customerRole.customer_role_approvers.length
    ) {
      this.setState({ requiresApproval: true });
    }
  }

  handleSubmit = () => {
    this.props.actions.doSubmitStagedCustomerRole({
      ...this.props.customerRole,
      _request: this.props.customerRole.id || 'NEW_CUSTOMER_ROLE',
      customer_id: this.props.customer.id,
      customer_role_approvers: undefined,
      customer_role_approvals: undefined,
      created_at: undefined,
      updated_at: undefined,
      customer_role_approvers_attributes:
        this.props.customerRole.customer_role_approvers,
    });
  };

  handleFieldChange = (e, { value, name }) => {
    let obj = { ...this.props.customerRole };
    obj[name] = value;
    if (
      name === 'approval_required_after_employee_spend' ||
      name === 'approval_required_after_location_spend'
    ) {
      obj[name] = parseInt(value, 10) * 100;
    }
    this.props.actions.updateStagedCustomerRole(obj);
  };

  handleCheckboxChange = (e, { name }) => {
    let obj = { ...this.props.customerRole };
    obj[name] = !this.props.customerRole[name];
    this.props.actions.updateStagedCustomerRole(obj);
  };

  handleMultiSelectChange = (event, { name, value }) => {
    let obj = { ...this.props.customerRole };
    if (name === 'customer_role_approvers') {
      obj[name] = value.map((val) => ({
        approver_id: val,
      }));
    }
    this.props.actions.updateStagedCustomerRole(obj);
  };

  handleRequireApproval = () => {
    this.setState(
      (prevState) => ({
        requiresApproval: !prevState.requiresApproval,
      }),
      () => {
        if (!this.state.requiresApproval) {
          this.props.actions.updateStagedCustomerRole({
            ...this.props.customerRole,
            customer_role_approvers: [],
            approval_required_after_employee_spend: null,
            approval_required_after_location_spend: null,
          });
        }

        if (
          this.state.requiresApproval &&
          this.props.customerRole.approval_required_after_employee_spend ===
            null
        ) {
          this.props.actions.updateStagedCustomerRole({
            ...this.props.customerRole,
            approval_required_after_employee_spend: 0,
          });
        }
      }
    );
  };

  _customerRoleOptions = () => {
    return (Object.values(this.props.customerRoles) || [])
      .map((cr) => ({
        key: cr.id,
        text: cr.name,
        value: cr.id,
      }))
      .filter((option) => option.value !== this.props.customerRole.id);
  };

  _centAsDollar = (val = 0) => val / 100;

  _formIsDisabled = () => {
    const { customerRole, isSubmitting } = this.props;
    if (isSubmitting) return true;
    if (!customerRole.name) return true;

    const hasApprovers =
      customerRole.customer_role_approvers &&
      customerRole.customer_role_approvers.length;
    const orderApprovalSet =
      typeof customerRole.approval_required_after_employee_spend === 'number';

    if (this.state.requiresApproval && !hasApprovers) return true;
    if (orderApprovalSet && !hasApprovers) return true;

    /* TODO: add back when location approval can be edited by user */
    // const locationApprovalSet = customerRole.approval_required_after_location_spend !== null;
    // if (locationApprovalSet && !hasApprovers) return true;

    return false;
  };

  renderRoleApproval = (last_idx, role, idx) => {
    return (
      <span key={role.id}>
        <Link style={{ fontSize: '1rem' }} to={`/customer-roles/${role.id}`}>
          {role.name}
        </Link>
        {idx !== last_idx && ', '}
      </span>
    );
  };

  renderRoleApprovals = () => {
    if (!(this.props.customerRole.customer_role_approvals || []).length)
      return null;
    let approval_roles = this.props.customerRole.customer_role_approvals.map(
      (item) => item.proposer_id
    );
    let roles = approval_roles.map((role) => this.props.customerRoles[role]);
    return (
      <Form.Field style={{ fontSize: '1rem' }}>
        Approves orders by{' '}
        {roles.map(this.renderRoleApproval.bind(this, roles.length - 1))} users.
      </Form.Field>
    );
  };

  renderApproverSection() {
    return (
      <Fragment>
        <Form.Field
          required
          width={16}
          disabled={!this.state.requiresApproval}
          style={{ paddingLeft: '2rem' }}
        >
          <label>Approving Roles</label>
          <p style={{ marginBottom: '0.5rem' }}>
            These roles approve submitted orders
            {this.props.customerRole.name &&
              ` by ${this.props.customerRole.name}`}
            .
          </p>
          <Dropdown
            placeholder="select"
            fluid
            multiple
            selection
            disabled={!this.state.requiresApproval}
            name="customer_role_approvers"
            options={this._customerRoleOptions()}
            onChange={this.handleMultiSelectChange}
            value={(this.props.customerRole.customer_role_approvers || []).map(
              (a) => a.approver_id
            )}
          />
        </Form.Field>
        <Form.Field
          required
          width={16}
          disabled={!this.state.requiresApproval}
          style={{ paddingLeft: '2rem' }}
        >
          <label>Monthly Spend Limit</label>
          <p style={{ marginBottom: '0.5rem' }}>
            Orders require approval after spending this amount in a month ($0
            means all orders require approval)
          </p>
          <Input
            icon="dollar"
            iconPosition="left"
            type="number"
            name="approval_required_after_employee_spend"
            min={0}
            value={this._centAsDollar(
              this.props.customerRole.approval_required_after_employee_spend
            )}
            onChange={this.handleFieldChange}
          />
        </Form.Field>
      </Fragment>
    );
  }

  render() {
    return (
      <Segment basic style={{ padding: 0 }}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            required
            label="Name"
            name="name"
            placeholder="e.g. Finance User, Accounting Admin, HR Member"
            value={this.props.customerRole.name}
            onChange={this.handleFieldChange}
          />
          <Header as="h3" content="This role:" />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText('can_manage_roles')}`}
            name="can_manage_roles"
            checked={this.props.customerRole.can_manage_roles}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText(
              'can_manage_employees_stipends'
            )}`}
            name="can_manage_employees_stipends"
            checked={this.props.customerRole.can_manage_employees_stipends}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText(
              'can_manage_employees_locations'
            )}`}
            name="can_manage_employees_locations"
            checked={this.props.customerRole.can_manage_employees_locations}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText('can_manage_billing')}`}
            name="can_manage_billing"
            checked={this.props.customerRole.can_manage_billing}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText('can_manage_insights')}`}
            name="can_manage_insights"
            checked={this.props.customerRole.can_manage_insights}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText('can_manage_all_requisitions')}`}
            name="can_manage_all_requisitions"
            checked={this.props.customerRole.can_manage_all_requisitions}
            onChange={this.handleCheckboxChange}
          />
          {this.props.hasLimitedCatalog && (
            <Form.Field
              control={Checkbox}
              label={`can ${buildPermissionText(
                'can_manage_location_catalog'
              )}`}
              name="can_manage_location_catalog"
              checked={this.props.customerRole.can_manage_location_catalog}
              onChange={this.handleCheckboxChange}
            />
          )}
          {this.props.hasAttendantServices && (
            <Form.Field
              control={Checkbox}
              label={`can ${buildPermissionText('can_manage_cleanings')}`}
              name="can_manage_cleanings"
              checked={this.props.customerRole.can_manage_cleanings}
              onChange={this.handleCheckboxChange}
            />
          )}
          <Form.Field
            control={Checkbox}
            label={`can ${buildPermissionText('can_manage_location_lists')}`}
            name="can_manage_location_lists"
            checked={this.props.customerRole.can_manage_location_lists}
            onChange={this.handleCheckboxChange}
          />
          <Form.Field
            control={Checkbox}
            label="requires order approval"
            name="requiresApproval"
            checked={this.state.requiresApproval}
            onChange={this.handleRequireApproval}
          />
          {this.state.requiresApproval && this.renderApproverSection()}
          {this.renderRoleApprovals()}
          <Form.Group style={{ marginTop: '2rem' }}>
            <Button
              primary
              type="Submit"
              loading={this.props.isLoading || this.props.isSubmitting}
              disabled={this._formIsDisabled()}
            >
              Save
            </Button>
          </Form.Group>
        </Form>
        <Form.Group style={{ marginTop: '2rem' }}>
          {this.props.isSaveSuccess && (
            <Message
              success
              header="Role saved"
              content="Your role is ready for use"
            />
          )}
          {this.props.isSaveError && (
            <Message
              error
              header="Role not saved"
              content={
                this.props.errorMessage
                  ? `Message: ${this.props.errorMessage}`
                  : ''
              }
            />
          )}
        </Form.Group>
      </Segment>
    );
  }
}

CustomerRoleForm.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  customerRole: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNewRecord: PropTypes.bool.isRequired,
  isSaveSuccess: PropTypes.bool.isRequired,
  isSaveError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  customerRoles: PropTypes.objectOf(PropTypes.object).isRequired,
  hasLimitedCatalog: PropTypes.bool.isRequired,
  hasAttendantServices: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  const errorResponses = state.customerRoles.responses.filter(FILTER_IS_ERROR);
  return {
    customerRoles: state.customerRoles.items,
    isSubmitting:
      state.customerRoles.requesting.filter(FILTER_IS_SUBMITTING).length > 0,
    isSaveSuccess:
      state.customerRoles.responses.filter(FILTER_IS_SAVE_SUCCESS).length > 0,
    isSaveError: errorResponses.length > 0,
    errorMessage: errorResponses.length ? errorResponses[0].message : null,
    hasLimitedCatalog: state.locations.open.pref_enable_limited_catalog,
    hasAttendantServices:
      (state.locations.open.services || '').match(/attendant/) !== null,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doSubmitStagedCustomerRole,
        updateStagedCustomerRole,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerRoles.requesting === next.customerRoles.requesting &&
    prev.customerRoles.responses === next.customerRoles.responses &&
    prev.customerRoles.items === next.customerRoles.items &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CustomerRoleForm);
