import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Message, Button, Header } from 'semantic-ui-react';
import { doRequestServiceQuote } from '../../actions/email-actions.js';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/message.min.css';

export class QuoteRequestForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayForm: false,
      displaySuccess: false,
      formData: {},
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.actions.doRequestServiceQuote(this.state.formData);
    this.setState({ displaySuccess: true });
  };

  onChangeInput = (e, { name, type, value, checked }) => {
    this.setState((prevState) => {
      let formData = { ...prevState.formData };
      formData[name] = value;
      if (type === 'checkbox') formData[name] = checked;
      return { formData };
    });
  };

  openForm = (e) => {
    e.preventDefault();
    this.setState({ displayForm: true });
  };

  render() {
    return (
      <div className="ach-request-wrap" style={this.props.style}>
        {!this.state.displayForm && (
          <Button secondary onClick={this.openForm}>
            Get a Quote
          </Button>
        )}
        {this.state.displayForm && (
          <Form onSubmit={this.handleSubmit}>
            <Form.Input
              required
              width={7}
              type="number"
              label="Square Footage"
              name="square_footage"
              min="0"
              value={this.state.formData.square_footage || ''}
              onChange={this.onChangeInput}
            />
            <Form.Input
              required
              width={7}
              type="number"
              label="Employee Count"
              name="employee_count"
              min="0"
              value={this.state.formData.employee_count || ''}
              onChange={this.onChangeInput}
            />
            <Form.Input
              required
              width={7}
              type="number"
              label="How many days per week?"
              name="days_per_week"
              min="0"
              value={this.state.formData.days_per_week || ''}
              onChange={this.onChangeInput}
            />
            <Form.Input
              required
              width={7}
              type="number"
              label="How many hours per week?"
              name="hours_per_week"
              min="0"
              value={this.state.formData.hours_per_week || ''}
              onChange={this.onChangeInput}
            />
            <Header as="h5" content="Which services will you need most?" />
            <Form.Checkbox
              label="Managing Inventory"
              name="managing_inventory"
              checked={this.state.formData.managing_inventory || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Ordering Supplies"
              name="ordering_supplies"
              checked={this.state.formData.ordering_supplies || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Restocking Supplies"
              name="restocking_supplies"
              checked={this.state.formData.restocking_supplies || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Administrative Tasks"
              name="administrative_tasks"
              checked={this.state.formData.administrative_tasks || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Daily Ad-Hoc Projects"
              name="ad_hoc_projects"
              checked={this.state.formData.ad_hoc_projects || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Sanitizing and Disinfecting"
              name="sanitizing"
              checked={this.state.formData.sanitizing || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Detailed Office Cleaning"
              name="office_cleaning"
              checked={this.state.formData.office_cleaning || false}
              onChange={this.onChangeInput}
            />
            <Form.Checkbox
              label="Building and Facilites Assistance"
              name="facilities_assistance"
              checked={this.state.formData.facilities_assistance || false}
              onChange={this.onChangeInput}
            />
            <Form.Button primary disabled={this.state.displaySuccess}>
              Submit
            </Form.Button>
          </Form>
        )}
        {this.state.displaySuccess && (
          <Message success>
            <Message.Header>Request Sent</Message.Header>
            <p>
              An OfficeLuv representative will reach out to you with next steps.
            </p>
          </Message>
        )}
      </div>
    );
  }
}

QuoteRequestForm.propTypes = {
  style: PropTypes.object,
};

export function mapStateToProps(state) {
  return {};
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doRequestServiceQuote,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  QuoteRequestForm
);
