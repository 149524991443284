export function productCategoryAsSuggestion(cat) {
  return {
    id: cat.id,
    name: cat.name,
    model: 'ProductCategory',
  };
}

export const mapLocationParentCategoriesIds = (locationParentCategories) => {
  return locationParentCategories.map(
    (locationParentCategory) => locationParentCategory.product_category_id
  );
};
