import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputQuantity from '../forms/input-quantity.js';
import SupplyLevelIcon from '../../imgs/icon-supply-levels--dark-gray.svg';
import { showProductModal } from '../../actions/product-actions.js';
import {
  submitUpdateLocationListProduct,
  submitDeleteLocationListProduct,
} from '../../actions/location-list-product-actions.js';
import './supply-item-in-stock.css';

export class SupplyItemInStock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inStockQuantity: props.item.quantity || 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        inStockQuantity: nextProps.item.quantity || 0,
      })
    );
  }

  handleChangeInStockQuantity = (inStockQuantity) => {
    let newQuantity = Math.max(inStockQuantity, 0);
    this.setState(
      Object.assign({}, this.state, { inStockQuantity: newQuantity })
    );

    if (newQuantity !== this.props.item.quantity) {
      this.props.actions.submitUpdateLocationListProduct({
        id: this.props.item.id,
        quantity: newQuantity,
      });
    }
  };

  render() {
    return (
      <div className="supply-item-in-stock">
        <div className="supply-item-in-stock-form-container">
          <p className="item-in-stock-subheader">Your Inventory</p>
          <div className="item-in-stock-form flex-wrapper">
            <div className="in-stock-icon">
              <img src={SupplyLevelIcon} alt="OfficeLuv Supply Level" />
            </div>
            <div className="in-stock-quantity-input">
              <InputQuantity
                value={this.state.inStockQuantity}
                readOnly={!this.props.role.can_manage_location_lists}
                label=""
                onChange={this.handleChangeInStockQuantity}
              />
            </div>
          </div>
          <div className="mobile-details">
            <span className="item-in-stock-details">
              Keep in Stock&nbsp;{this.props.item.min.toLocaleString()}
            </span>
          </div>
        </div>
        <div className="item-in-stock-details desktop-details">
          <div className="site-h4 minimum-in-stock-header">
            <span className="light-gray-text">
              Keep in Stock: {this.props.item.min.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

SupplyItemInStock.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    location_list_id: PropTypes.number.isRequired,
    product_id: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      sku_brand: PropTypes.string.isRequired,
      unit_purchase: PropTypes.string.isRequired,
      stock_per_purchase: PropTypes.number.isRequired,
      unit_stock: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  role: PropTypes.shape({
    can_manage_location_lists: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    role: state.auth.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showProductModal,
        submitUpdateLocationListProduct,
        submitDeleteLocationListProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return prev.auth.role === next.auth.role;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SupplyItemInStock);
