import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { formatBytesSize } from '../../lib/formatters.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/icon.min.css';
import './preview.css';

export default class AttachmentPreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
    };
  }

  componentDidMount() {
    this.parseAttachment(this.props.item);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.item && props.item === this.state.item) return;
    this.setState(() => {
      return { item: props.item };
    });
    this.parseAttachment(props.item);
  }

  parseAttachment = (file) => {
    let result = {
      file: file,
      size: file.size,
      name: file.name,
      type: file.type,
    };
    let reader = new FileReader();
    reader.onload = (e) => {
      result.url = e.target.result;
      this.setState(
        Object.assign({}, this.state, {
          processed: result,
        })
      );
    };
    reader.readAsDataURL(file);
    return result;
  };

  handleFileNameOverflow = () => {
    let name = this.state.item.name;
    if (name.length < 24) {
      return name;
    }
    let suffix = this.getSuffix();
    return `${name.slice(0, 20)}...${suffix}`;
  };

  calculateFileSize = () => {
    return formatBytesSize(this.state.item.size);
  };

  getSuffix = () => {
    let suffix = this.state.item.name.split('.');
    return suffix.length > 1 ? suffix[suffix.length - 1] : '';
  };

  render() {
    return (
      <div className="attachment-preview-wrapper flex-wrapper">
        <div className="attachment-preview image">
          {this.state.processed &&
            this.state.processed.url &&
            this.state.processed.type.match(/image/) && (
              <img
                src={this.state.processed.url}
                height={100}
                width={100}
                title={this.state.item.name}
                alt={this.state.item.name}
              />
            )}
          {this.state.processed && !this.state.processed.type.match(/image/) && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox={'0 0 100 100'}
              width={100}
              height={100}
              style={{ background: 'lightgrey' }}
              title={this.state.item.name}
            >
              <text
                fontSize={20}
                fill="white"
                x={50}
                textAnchor="middle"
                y="55%"
              >
                {this.getSuffix()}
              </text>
            </svg>
          )}
        </div>
        <div className="attachment-preview meta-data flex-wrapper">
          <p className="file-name">{this.handleFileNameOverflow()}</p>
          <p className="file-size">{this.calculateFileSize()}</p>
          {this.props.onRemove && (
            <Button
              icon
              size="mini"
              compact
              basic
              onClick={this.props.onRemove.bind(
                this.props.onRemove,
                this.props.item
              )}
              labelPosition="left"
            >
              <Icon name="remove" />
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  }
}

AttachmentPreview.propTypes = {
  onRemove: PropTypes.func,
  item: PropTypes.instanceOf(File).isRequired,
};
