import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Table } from 'semantic-ui-react';
import { Style } from 'victory';
import { formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/table.min.css';
import './budget-code-grouped-spend-table.css';

export default class BudgetCodeGroupedSpendTable extends React.PureComponent {
  color = (idx) => {
    let scale = Style.getColorScale(this.props.colorScale);
    let mod = idx % scale.length;
    return scale[mod];
  };

  formatData = () => {
    return this.props.data.reduce((acc, val, idx) => {
      if (idx === this.props.maxGroups) {
        return acc.concat({
          name: this.props.maxLabel,
          line_items_submitted_price: val.line_items_submitted_price,
          line_items_submitted_count: val.line_items_submitted_count,
        });
      }
      if (idx > this.props.maxGroups) {
        acc[this.props.maxGroups].line_items_submitted_price +=
          val.line_items_submitted_price;
        acc[this.props.maxGroups].line_items_submitted_count +=
          val.line_items_submitted_count;
        return acc;
      }
      return acc.concat(val);
    }, []);
  };

  renderRow = (row, idx) => {
    let editFxn = (this.props.onClickEditBudgetCode || function () {}).bind(
      null,
      row
    );
    return (
      <Table.Row key={idx}>
        {this.props.editing && (
          <Table.Cell collapsing>
            <Button size="mini" compact icon onClick={editFxn} title="Edit">
              <Icon name="pencil" />
            </Button>
          </Table.Cell>
        )}
        <Table.Cell
          className={'intro--budget-insights-' + (idx === 0 ? 'line-item' : '')}
        >
          <span
            style={{
              margin: '0 1rem 0 0',
              height: '1rem',
              width: '1rem',
              borderRadius: '100%',
              background: this.color(idx),
              backgroundColor: this.color(idx),
              display: 'inline-block',
              verticalAlign: 'text-bottom',
            }}
          ></span>
          {this.props.editing && !row.active && (
            <Label color="red" horizontal size="mini">
              Archived
            </Label>
          )}
          <Link to={`/insights/budget-codes/${row.id}`}>{row.name}</Link>
        </Table.Cell>
        <Table.Cell textAlign="right">
          {formatCentAmount(row.line_items_submitted_price)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {row.line_items_submitted_count.toLocaleString()}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderFooter = () => {
    if (!this.props.displayFooter) return null;
    return (
      <Table.Footer fullWidth>
        <Table.Row>
          {this.props.editing && <Table.HeaderCell />}
          <Table.HeaderCell>
            <Button
              primary
              size="mini"
              compact
              onClick={this.props.onClickAddBudgetCode}
            >
              Add Category
            </Button>
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
            <Button
              floated="right"
              size="mini"
              compact
              onClick={this.props.onClickEditBudgetCodes}
              secondary
            >
              {this.props.editing ? 'View' : 'Edit'} Categories
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  };

  render() {
    return (
      <Table
        compact
        basic="very"
        className="budget-code-grouped-spend-table"
        definition={this.props.editing}
      >
        <Table.Header>
          <Table.Row>
            {this.props.editing && <Table.HeaderCell />}
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Spend</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Items</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.formatData().map(this.renderRow)}</Table.Body>
        {this.renderFooter()}
      </Table>
    );
  }
}

BudgetCodeGroupedSpendTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      line_items_submitted_price: PropTypes.number.isRequired,
      line_items_submitted_count: PropTypes.number.isRequired,
    })
  ).isRequired,
  maxGroups: PropTypes.number.isRequired,
  maxLabel: PropTypes.string.isRequired,
  colorScale: PropTypes.string.isRequired,
  displayFooter: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  onClickEditBudgetCodes: PropTypes.func,
  onClickAddBudgetCode: PropTypes.func,
  onClickEditBudgetCode: PropTypes.func,
};

BudgetCodeGroupedSpendTable.defaultProps = {
  maxGroups: 80,
  maxLabel: 'Other',
  colorScale: 'qualitative',
  displayFooter: true,
  editing: false,
};
