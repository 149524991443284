import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import 'semantic-ui-css/components/placeholder.min.css';
import './item.css';

export default class ProductItemLoading extends React.PureComponent {
  render() {
    return (
      <div className="product-item">
        <Placeholder>
          <Placeholder.Image square style={{ width: '144px' }} />
        </Placeholder>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </div>
    );
  }
}
