import fetch from './hmac-fetch.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

// Ref: https://www.algolia.com/doc/api-reference/search-api-parameters/
export default function searchProductsAtLocation(id, params) {
  return fetch(`${ADMIN_V1_URL}/locations/${id}/products/search`, {
    method: 'POST',
    headers: HEADERS_JSON,
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('Submitting product search at location');
    }
    return response.json();
  });
}
