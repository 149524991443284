import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import {
  Button,
  Table,
  Header,
  Form,
  Pagination,
  Message,
} from 'semantic-ui-react';
import {
  doCloseEmployeeStipends,
  doPostEmployeeStipends,
  doDeleteEmployeeStipend,
} from '../../actions/employee-stipend-actions.js';
import { formatCentAmount, formatDate } from '../../lib/formatters.js';
import { REGEX_EMAIL } from '../../strings.js';
import { processCSV } from '../../helpers/csv-processing-helper.js';
import './modal-common.css';
import Loader from '../loader.js';

const PAGINATION_CONFIG = { limit: 10 };

export class ModalEmployeeStipends extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 500,
      emailString: '',
      newEmployeeStipends: [],
      activePage: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ activePage: 1 });
    }
  }

  paginatedItems() {
    const upper = this.state.activePage * PAGINATION_CONFIG.limit;
    const lower = upper - PAGINATION_CONFIG.limit;

    return this.props.items.slice(lower, upper);
  }

  handlePageChange = (_, data) => {
    this.setState({ activePage: data.activePage });
  };

  handleClickRemove = (stipendId) => {
    if (
      window.confirm('Do you want to remove the stipend from this employee?')
    ) {
      this.props.actions.doDeleteEmployeeStipend(
        stipendId,
        this.props.customerId
      );
    }
  };

  onRequestClose = () => {
    this.props.actions.doCloseEmployeeStipends();
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  renderEmployeeStipends = () => {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <div>
        <Table basic className="location-team-container">
          <Table.Body>
            <Table.Row className="batch-actions-row">
              <Table.Cell width={5}>Employee Name</Table.Cell>
              <Table.Cell width={5}>Employee Email</Table.Cell>
              <Table.Cell width={4}>Added On</Table.Cell>
              <Table.Cell width={3}>Stipend Remaining</Table.Cell>
              <Table.Cell width={1}></Table.Cell>
            </Table.Row>

            {this.paginatedItems().map(this.renderEmployeeStipend)}
          </Table.Body>
        </Table>

        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <Pagination
            defaultActivePage={1}
            firstItem={null}
            lastItem={null}
            onPageChange={this.handlePageChange}
            totalPages={Math.ceil(
              this.props.items.length / PAGINATION_CONFIG.limit
            )}
          />
        </div>
      </div>
    );
  };

  renderEmployeeStipend = (employeeStipend) => {
    return (
      <Table.Row key={employeeStipend.id} className="batch-actions-row">
        <Table.Cell width={5}>{employeeStipend.employee_name}</Table.Cell>
        <Table.Cell width={5}>{employeeStipend.employee_email}</Table.Cell>
        <Table.Cell width={4}>
          {formatDate(employeeStipend.created_at)}
        </Table.Cell>
        <Table.Cell width={3}>
          {formatCentAmount(employeeStipend.stipend_remaining)}
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            style={{ border: 'none', padding: '0.25rem', boxShadow: 'none' }}
            icon="trash alternate outline"
            compact
            size="tiny"
            basic
            onClick={this.handleClickRemove.bind(this, employeeStipend.id)}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  parseEmailsInput(value) {
    return value
      .split(',')
      .map((e) => {
        return { email: e.trim(), stipend_id: this.props.open.id };
      })
      .filter((e) => !!e.email && e.email.match(REGEX_EMAIL))
      .map((e) => {
        return {
          email: e.email.match(REGEX_EMAIL)[0],
          stipend_id: this.props.open.id,
        };
      });
  }

  isDisabled = () => {
    return this.props.isRequesting || !this.state.newEmployeeStipends.length;
  };

  handleChangeEmails = (e, { value }) => {
    const newEmployeeStipends = this.parseEmailsInput(value);
    this.setState(() => {
      return {
        emailString: value,
        newEmployeeStipends,
      };
    });
  };

  /**
   * Handle change event for CSV file input
   *
   * @access public
   * @param {object} event
   */
  handleChangeCSV = (event) => {
    processCSV(event.target, this.setCSVResult);
  };

  setCSVResult = (result) => {
    if (result.emails) {
      const emails = result.emails;
      delete result.emails;

      this.setState(
        Object.assign({}, this.state, {
          ...result,
          newEmployeeStipends: emails.map((email) => {
            return { email: email, stipend_id: this.props.open.id };
          }),
        })
      );
    } else {
      this.setState(Object.assign({}, this.state, result));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.actions.doPostEmployeeStipends({
      items: this.state.newEmployeeStipends,
      id: this.props.customerId,
      stipend_id: this.props.open.id,
    });
    this.setState(() => {
      return {
        emailString: '',
        newEmployeeStipends: [],
      };
    });
  };

  renderNewEmployeeStipends = () => {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Field width={8} className="">
            <Form.TextArea
              label="Assign Stipend"
              rows={2}
              placeholder="Enter emails separated by commas"
              onChange={this.handleChangeEmails}
              value={this.state.emailString}
            />
          </Form.Field>

          {window.FileReader && (
            <Form.Field width={8}>
              <Form.Input
                label="or Upload Emails via CSV"
                onChange={this.handleChangeCSV}
                icon="file alternate outline"
                accept="text/csv,.csv"
                error={this.state.errorCSV}
                type="file"
              />

              <Message>
                <p>
                  Be sure to upload your CSV file with the word <b>email</b> at
                  the top of your column list.
                </p>
              </Message>
            </Form.Field>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Button
            width={8}
            fluid
            primary
            disabled={this.isDisabled()}
            loading={this.props.isPosting}>
            Assign Stipend
          </Form.Button>
        </Form.Group>
      </Form>
    );
  };

  render() {
    if (!this.props.open || !this.props.open.id) {
      return null;
    }

    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={true}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal large',
          afterOpen: 'modal-after-open large',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Employee Stipends Modal">
        <Header as="h3" style={{ marginBottom: '1.5rem' }}>
          {this.props.open.name}
        </Header>

        {this.renderNewEmployeeStipends()}
        {this.props.isRequesting && <Loader />}
        {!this.props.isRequesting && this.renderEmployeeStipends()}
      </Modal>
    );
  }
}

ModalEmployeeStipends.propTypes = {
  open: PropTypes.shape({
    id: PropTypes.number,
    items: PropTypes.array,
  }).isRequired,
  isRequesting: PropTypes.bool,
  isPosting: PropTypes.bool,
  actions: PropTypes.shape({
    doCloseEmployeeStipends: PropTypes.func.isRequired,
    doPostEmployeeStipends: PropTypes.func.isRequired,
  }).isRequired,
  customerId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    open: state.employeeStipends.open,
    items: state.employeeStipends.items,
    customerId: state.locations.open.customer_id,
    isRequesting: state.employeeStipends.isRequesting,
    isPosting: state.employeeStipends.isPosting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCloseEmployeeStipends,
        doPostEmployeeStipends,
        doDeleteEmployeeStipend,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employeeStipends.open === next.employeeStipends.open &&
    prev.employeeStipends.items === next.employeeStipends.items &&
    prev.locations.open === next.locations.open &&
    prev.employeeStipends.isRequesting === next.employeeStipends.isRequesting &&
    prev.employeeStipends.isPosting === next.employeeStipends.isPosting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalEmployeeStipends);
