import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Segment } from 'semantic-ui-react';
import { formatCentAmount } from '../../lib/formatters.js';
import OrdersProductRow from './orders-product-row.js';
import * as requisitionHelpers from '../../helpers/requisition-helpers';
import './item.css';

const MAX_PRODUCT_LENGTH = 5;

export class RequisitionItem extends React.PureComponent {
  reduceItemsQuantity(acc, val) {
    return acc + val.quantity;
  }

  cartQuantity = (items) => {
    return (items || this.props.item.product_requisitions || []).reduce(
      this.reduceItemsQuantity,
      0
    );
  };

  renderStatus() {
    if (
      requisitionHelpers.isEditable(
        this.props.item,
        this.props.user,
        this.props.userType
      )
    ) {
      return (
        <Table.Cell textAlign="right">
          <Link to={`/supplies/orders/${this.props.item.id}/edit`}>
            Edit Order
          </Link>{' '}
          |&nbsp;
          <Link to={`/supplies/orders/${this.props.item.id}`}>
            View Order Details
          </Link>
        </Table.Cell>
      );
    }
    if (requisitionHelpers.isProcessing(this.props.item)) {
      return (
        <Table.Cell textAlign="right">
          Processing |&nbsp;
          <Link to={`/supplies/orders/${this.props.item.id}`}>
            View Order Details
          </Link>
        </Table.Cell>
      );
    }
    return (
      <Table.Cell textAlign="right">
        <Link to={`/supplies/orders/${this.props.item.id}`}>
          View Order Details
        </Link>
      </Table.Cell>
    );
  }

  renderItem = (item, i) => {
    return (
      <OrdersProductRow
        item={item}
        key={item.id || i}
        showShippingDetails={false}
      />
    );
  };

  render() {
    const cartQuantity = this.cartQuantity();
    const product_reqs_preview = this.props.item.product_requisitions.slice(
      0,
      MAX_PRODUCT_LENGTH
    );
    return (
      <div className="requisition-item">
        <Segment attached="top" clearing>
          <Table basic compact="very" unstackable style={{ margin: 0 }}>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <h5>{requisitionHelpers.displayName(this.props.item)}</h5>
                </Table.Cell>
                {this.renderStatus()}
              </Table.Row>
            </Table.Body>
          </Table>
          <Segment basic style={{ padding: 0, margin: 0 }}>
            <Table basic="very" compact="very" className="order-summary-items">
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing style={{ fontWeight: 'bold' }}>
                    {formatCentAmount(this.props.item.total_price)}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {cartQuantity.toLocaleString()}{' '}
                    {cartQuantity > 1 ? 'Items' : 'Item'}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <em className="light-gray-text">
                      {requisitionHelpers.requesterText(this.props.item)}
                      &nbsp;
                      {requisitionHelpers.displayUserName(
                        this.props.item.requester,
                        this.props.item.requester_type
                      )}
                    </em>
                  </Table.Cell>

                  <Table.Cell collapsing>
                    <em className="light-gray-text">
                      {this.props.item.proposer && (
                        <Fragment>
                          {requisitionHelpers.proposerText(this.props.item)}
                          &nbsp;
                          {requisitionHelpers.displayUserName(
                            this.props.item.proposer,
                            this.props.item.proposer_type
                          )}
                        </Fragment>
                      )}
                    </em>
                  </Table.Cell>
                  <Table.Cell textAlign="right"></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Segment>
        <Segment attached="bottom">
          <Table basic>
            <Table.Body>{product_reqs_preview.map(this.renderItem)}</Table.Body>
            {this.props.item.product_requisitions.length >
              MAX_PRODUCT_LENGTH && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="16" textAlign="right">
                    <Link to={`/supplies/orders/${this.props.item.id}`}>
                      {this.cartQuantity() -
                        this.cartQuantity(product_reqs_preview)}{' '}
                      more items. See Full Details
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Segment>
      </div>
    );
  }
}

RequisitionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string,
    location_id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    total_price: PropTypes.number.isRequired,
    expected_at_start: PropTypes.string,
    expected_at_end: PropTypes.string,
    shipmentIds: PropTypes.arrayOf(PropTypes.number),
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  userType: PropTypes.oneOf(['employee', 'staff', 'Employee', 'Staff'])
    .isRequired,
};

function mapStateToProps(state) {
  const user =
    state.employees.user.id > 0 ? state.employees.user : state.staffs.user;

  return {
    user,
    userType: state.auth.actor.type,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.staffs.user === next.staffs.user &&
    prev.auth.actor === next.auth.actor
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  RequisitionItem
);
