import log from '../lib/log.js';
import fetch from '../lib/hmac-fetch.js';
import {
  trackFetchError,
  trackCatalogItemRequestCreate,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';
import { first } from '../helpers/array-helpers.js';

/**
 * Create catalog item request
 *
 * @access public
 * @returns {function} redux thunk
 */
export function doCatalogItemRequest(data) {
  return (_dispatch, getState) => {
    const logger = log('[requestItem]');
    const { locations, employees } = getState();
    const location = locations.open;
    const user = employees.user;
    let item = data.text;

    let body = {
      text: data.text,
      url: first(item.match(/\bhttps?:\/\/\S+/gi)),
      employee_id: user.id,
      location_id: location.id,
    };

    body._request = data._request || requestID();
    trackCatalogItemRequestCreate(body);
    return fetch(
      `${ADMIN_V1_URL}/locations/${location.id}/catalog_item_requests`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': body._request,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create catalog item request',
          });
        }
      })
      .catch((error) => {
        trackFetchError(error);
        logger.error('error', error);
      });
  };
}
