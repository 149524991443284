import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import brandPropType from '../../propTypes/brand.js';
import { openProductBrand } from '../../actions/product-brand-actions.js';
import './promoted-brands.css';

export class PromotedBrandsDescription extends React.PureComponent {
  handleClick = () => {
    this.props.actions.openProductBrand(this.props.brand);
  };

  render() {
    return (
      <div className="promoted-brands__description">
        {this.props.brand.description}
        <div className="promoted-brands__fade">
          <Button
            onClick={this.handleClick}
            size="mini"
            className="tertiary promoted-brands__more">
            read more
          </Button>
        </div>
      </div>
    );
  }
}

PromotedBrandsDescription.propTypes = {
  actions: PropTypes.shape({
    openProductBrand: PropTypes.func.isRequired,
  }).isRequired,
  brand: brandPropType,
};
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openProductBrand,
      },
      dispatch
    ),
  };
}

export function areStatesEqual() {
  return true;
}

export default connect(null, mapDispatchToProps, null, { areStatesEqual })(
  PromotedBrandsDescription
);
