import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { encodeAsUrlHash, formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/menu.min.css';
import './budget-code-link.css';

export class VendorLink extends React.PureComponent {
  render() {
    const id = encodeAsUrlHash(this.props.vendor.name);
    const active = window.location.pathname.match(id);
    return (
      <Menu.Item active={active}>
        <Link to={`/insights/vendors/${id}`} className="primary">
          {this.props.vendor.name}
        </Link>
        <p>{formatCentAmount(this.props.vendor.total_spend)} of spend</p>
      </Menu.Item>
    );
  }
}

VendorLink.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total_spend: PropTypes.number.isRequired,
  }).isRequired,
};

export default VendorLink;
