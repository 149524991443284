import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import { Segment, Table, Header, Input } from 'semantic-ui-react';
import { SORT_BY_ID } from '../../strings.js';
import SettingsTeamRow from './settings-team-row.js';
import TeamBatchActions from './team-batch-actions.js';
import { FILTER_GET_INDEX_REQUEST } from '../../ducks/customer-roles.js';

export class SettingsTeamList extends React.PureComponent {
  state = {
    searchValue: '',
    queryResults: [],
    selected: {},
  };

  onSelectEmployee = (employee) => {
    this.setState((prevState) => {
      const reducedEmployees = this.props.employees.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
      }, {});
      let selected = {
        ...reducedEmployees,
        ...prevState.selected,
      };
      selected[employee.id] = !prevState.selected[employee.id];
      return { selected };
    });
  };

  searchResults = (searchValue) => {
    if (!searchValue.trim()) {
      return this.props.employees;
    }
    return new Fuse(this.props.employees, {
      keys: ['first_name', 'last_name', 'email', 'title'],
      threshold: 0.4,
    })
      .search(searchValue)
      .map((match) => match.item.id);
  };

  onSearchChange = (e, { value }) => {
    const queryResults = this.searchResults(value);
    this.setState(() => {
      return {
        searchValue: value,
        queryResults,
      };
    });
  };

  onRemoveSuccess = (responseBody) => {
    if (!this.state.searchValue.length) return;
    if (!responseBody || !responseBody.id) return;

    this.setState((prevState) => {
      let selected = { ...prevState.selected };
      selected[responseBody.id] = false;
      return {
        selected,
      };
    });
  };

  onSelectAllInView = (value) => {
    let selected = this.props.employees.map((e) => e.id);
    if (this.state.searchValue.length) {
      selected = this.state.queryResults;
    }
    selected = selected.reduce((acc, id) => {
      acc[id] = value;
      return acc;
    }, {});
    this.setState({ selected });
  };

  queryResultEmployees = () => {
    return this.state.queryResults
      .map((id) => {
        const employee = this.props.employees.filter(
          (employee) => employee.id === id
        )[0];
        return employee;
      })
      .filter((employee) => employee !== undefined);
  };

  renderEmployee = (employee) => {
    return (
      <SettingsTeamRow
        key={employee.id}
        employee={employee}
        onCheckboxChange={this.onSelectEmployee}
        checkboxChecked={this.state.selected[employee.id]}
      />
    );
  };

  render() {
    return (
      <Segment
        basic
        loading={this.props.pageIsLoading}
        style={{ minHeight: '100vh' }} // prevents sudden page jumps when typing a search phrase
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Header as="h4" style={{ margin: 0 }}>
            Current Team
          </Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              icon="search"
              onChange={this.onSearchChange}
              value={this.state.searchValue}
              placeholder="Search your team..."
            />
          </div>
        </div>
        <Table basic className="location-team-container">
          <Table.Body>
            <TeamBatchActions
              employeeIds={Object.keys(this.state.selected).filter(
                (id) => this.state.selected[id]
              )}
              employeesInView={
                this.state.searchValue.length
                  ? this.queryResultEmployees()
                  : this.props.employees
              }
              onSelectAll={this.onSelectAllInView}
              onRemoveSuccess={this.onRemoveSuccess}
            />
            {this.state.searchValue.length > 0 &&
              this.queryResultEmployees().map(this.renderEmployee)}
            {this.state.searchValue.length === 0 && (
              <Fragment>
                {this.props.employees.map(this.renderEmployee)}
              </Fragment>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

SettingsTeamList.propTypes = {
  pageIsLoading: PropTypes.bool.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    invitation_accepted_at: PropTypes.string,
  }).isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invitation_accepted_at: PropTypes.string,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  const employee = state.employees.user;
  const pendingInvitation = state.employees.items
    .filter((a) => !a.invitation_accepted_at)
    .sort((a, b) => (a.invitation_sent_at > b.invitation_sent_at ? -1 : 1));
  const sortedEmployees = pendingInvitation.concat(
    state.employees.items
      .filter((a) => {
        return (
          a.invitation_accepted_at &&
          a.id !== state.employees.user.id &&
          a.locations.map((l) => l.id).indexOf(state.locations.open.id) > -1
        );
      })
      .sort(SORT_BY_ID)
  );
  const pageIsLoading = !!state.customerRoles.requesting.filter(
    FILTER_GET_INDEX_REQUEST
  ).length;
  return {
    employee,
    employees: sortedEmployees,
    pageIsLoading,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.employees.items === next.employees.items &&
    prev.employees.user === next.employees.user &&
    prev.locations.open === next.locations.open &&
    prev.customerRoles.requesting === next.customerRoles.requesting
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  SettingsTeamList
);
