import * as types from './action-types';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError } from './action-helpers.js';
import { doSignIn } from './auth-actions.js';
import { submitUpdateEmployee, doGetEmployeeSelf } from './employee-actions.js';
import { ADMIN_VI_URL_PASSWORDS } from '../strings.js';

export const postWithToken = (credentials) => {
  return {
    type: types.GET_WITH_TOKEN,
    credentials,
  };
};

const errorWithToken = (data) => {
  return {
    type: types.ERROR_WITH_TOKEN,
    data,
  };
};

const successfulWithToken = (data) => {
  return {
    type: types.SUCCESS_WITH_TOKEN,
    data,
  };
};

export const postUserDetails = (details) => {
  return {
    type: types.POST_USER_DETAILS,
    details,
  };
};

const successfulUserDetails = (data) => {
  return {
    type: types.SUCCESS_USER_DETAILS,
    data,
  };
};

const errorUserDetails = (data) => {
  return {
    type: types.ERROR_USER_DETAILS,
    data,
  };
};

export const postUserRole = (data) => {
  return {
    type: types.POST_USER_ROLE,
    data,
  };
};

export const successUserRole = (data) => {
  return {
    type: types.SUCCESS_USER_ROLE,
    data,
  };
};

export const errorUserRole = (data) => {
  return {
    type: types.ERROR_USER_ROLE,
    data,
  };
};

export function submitUserRole(details) {
  return (dispatch) => {
    dispatch(postUserRole(details));
    return submitUpdateEmployee(details, { bubble: true })(dispatch)
      .then((body) => {
        dispatch(successUserRole(body));
      })
      .catch((error) => {
        dispatch(errorUserRole(error));
      });
  };
}

export function submitUserDetails(details) {
  return (dispatch, getState) => {
    dispatch(postUserDetails(details));
    const { onboarding } = getState();
    details.invitation_token = onboarding.credentials.token;
    return fetch(`${ADMIN_VI_URL_PASSWORDS}/employees/accept_invitation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: details,
            message: 'Accept invitation',
          });
        }
        return response.json();
      })
      .then((body) => {
        let data = { email: details.email, password: details.password };
        let options = { bubble: true };
        doSignIn(
          data,
          options
        )(dispatch)
          .then(() => {
            doGetEmployeeSelf()(dispatch, getState);
          })
          .then(() => {
            dispatch(successfulUserDetails(body || {}));
          })
          .catch((error) => {
            dispatch(errorUserDetails(error));
            trackFetchError(error);
          });
      })
      .catch((error) => {
        dispatch(errorUserDetails(error));
        trackFetchError(error);
      });
  };
}

export function doGetWithToken(credentials) {
  return (dispatch) => {
    dispatch(postWithToken(credentials));
    return fetch(`${ADMIN_VI_URL_PASSWORDS}/employees/find_by_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ invitation_token: credentials.token }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: credentials,
            message: 'Get invitation',
          });
        }
        return response.json();
      })
      .then((body) => dispatch(successfulWithToken(body || {})))
      .catch((error) => {
        dispatch(errorWithToken(error));
        trackFetchError(error);
      });
  };
}
