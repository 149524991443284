import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Header, Icon, Segment, Table } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import { encodeAsUrlHash } from '../../lib/formatters.js';
import TextWithAnchors from '../text/text-with-anchors.js';
import moment from 'moment';
import {
  formatDate,
  formatDateTime,
  formatCentAmount,
} from '../../lib/formatters.js';
import { openDataViewRange } from '../../actions/data-view-actions.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/table.min.css';
import './external-vendor-invoice.css';

export class ExternalVendorInvoice extends React.PureComponent {
  isPlaceholder = () => {
    return (
      !this.props.isRequesting &&
      this.props.invoice.external_vendor_invoice_line_items &&
      this.props.invoice.external_vendor_invoice_line_items.length === 0
    );
  };

  redirectToVendorInsights = () => {
    let startDate = moment(this.props.invoice.transaction_date).startOf(
      'month'
    );
    let endDate = moment(startDate).endOf('month');
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
    browserHistory.push(
      `/insights/vendors/${encodeAsUrlHash(this.props.invoice.vendor_name)}`
    );
  };

  renderLineItem = (lineItem) => {
    const budgetCode = this.props.customerBudgetCodes.filter(
      (bc) => bc.id === lineItem.customer_budget_code_id
    )[0];
    return (
      <Table.Row key={lineItem.id}>
        <Table.Cell>{lineItem.name}</Table.Cell>
        <Table.Cell>
          {budgetCode && (
            <Link to={`/insights/budget-codes/${budgetCode.id}`}>
              {budgetCode.name}
            </Link>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right">{lineItem.quantity}</Table.Cell>
        <Table.Cell textAlign="right">
          {formatCentAmount(lineItem.unit_price)}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderLineItems = () => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Item</Table.HeaderCell>
            <Table.HeaderCell width={4}>Category</Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="right">
              Quantity
            </Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="right">
              Unit Price
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {this.props.invoice.external_vendor_invoice_line_items &&
            this.props.invoice.external_vendor_invoice_line_items.map(
              this.renderLineItem
            )}
        </Table.Body>
      </Table>
    );
  };

  renderSummary = () => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Invoice/Order Number</Table.HeaderCell>
            <Table.HeaderCell width={4}>Transaction Date</Table.HeaderCell>
            <Table.HeaderCell width={6}>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{this.props.invoice.invoice_number}</Table.Cell>
            <Table.Cell>
              {formatDate(this.props.invoice.transaction_date)}
            </Table.Cell>
            <Table.Cell>
              <TextWithAnchors text={this.props.invoice.description} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderAttachment = (attachment) => {
    if (attachment.content_type.match(/image/)) {
      return (
        <img
          src={attachment.original}
          key={attachment.key}
          width="100%"
          alt={attachment.filename}
        />
      );
    }
    return (
      <object
        data={attachment.original}
        key={attachment.key}
        width="100%"
        style={{ height: '50vh' }}
      >
        {attachment.filename}
      </object>
    );
  };

  renderPlaceholder = () => {
    return (
      <Segment
        className="external-vendor-invoice-summary"
        placeholder
        attached="top"
      >
        <Header as="h4" icon>
          <Icon name="alternate file outline" />
          Invoice uploaded {formatDateTime(this.props.invoice.created_at)}
          <Header.Subheader>
            The data is being processed and will be available shortly.
          </Header.Subheader>
        </Header>
        {(this.props.invoice.images_attachments || []).map(
          this.renderAttachment
        )}
      </Segment>
    );
  };

  render() {
    if (this.isPlaceholder()) {
      return this.renderPlaceholder();
    }
    return (
      <Segment
        attached="top"
        loading={this.props.isRequesting}
        className="external-vendor-invoice"
      >
        <Button
          className="tertiary"
          style={{ float: 'right', marginTop: '0.5rem' }}
          onClick={this.redirectToVendorInsights}
        >
          View Insights
        </Button>
        <h3 className="section-header">
          Invoice from {this.props.invoice.vendor_name}
        </h3>
        {this.props.invoice.id > 0 && this.renderSummary()}
        {this.props.invoice.id > 0 && this.renderLineItems()}
        <Table style={{ border: 'none' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ fontWeight: 'bold' }}>Total</Table.Cell>
              <Table.Cell textAlign="right" style={{ fontWeight: 'bold' }}>
                {formatCentAmount(this.props.invoice.total)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {(this.props.invoice.images_attachments || []).map(
          this.renderAttachment
        )}
      </Segment>
    );
  }
}

ExternalVendorInvoice.propTypes = {
  actions: PropTypes.shape({
    openDataViewRange: PropTypes.func.isRequired,
  }).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    invoice_number: PropTypes.string.isRequired,
    transaction_date: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    vendor_name: PropTypes.string.isRequired,
    external_vendor_invoice_line_items: PropTypes.arrayOf(
      PropTypes.shape({
        customer_budget_code_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        unit_price: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export function mapStateToProps(state, ownProps) {
  return {
    customerBudgetCodes: state.customerBudgetCodes.items,
    isRequesting: !!state.externalVendorInvoices.requesting.filter(
      (evi) => evi.id === ownProps.invoice.id
    ).length,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openDataViewRange,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.customerBudgetCodes.items === next.customerBudgetCodes.items &&
    prev.externalVendorInvoices.requesting ===
      next.externalVendorInvoices.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ExternalVendorInvoice);
