import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import { trackNewSchedReqClick } from '../../lib/analytics.js';
import './calendar-month-day.css';

export class CalendarMonthDay extends React.PureComponent {
  renderItem = (item) => {
    if (item.state === 'cancelled') {
      return (
        <Icon
          name="cancel"
          size="small"
          className="calendar-month-day__icon"
          style={helpers.agendaItemStyle(item)}
          key={`${item.resource.id}-cancelled`}
        />
      );
    }
    if (item.state === 'actionNeeded') {
      return (
        <div key={`${item.resource.id}-actionNeeded`}>
          <Icon size="small" name="check circle outline" color="grey" />
        </div>
      );
    }
    return (
      <div
        className="calendar-month-day__dot"
        style={helpers.agendaItemStyle(item)}
        key={`${item.resource.id}-confirmed`}></div>
    );
  };

  render() {
    if (!this.props.isValidDate) return null;

    return (
      <div className="calendar-month-day" title="Show this date's agenda">
        {moment(this.props.date).isAfter(moment().add(6, 'days')) && (
          <Link
            to={`/orders/scheduled/new${helpers.getQueryParams(
              this.props.date
            )}&from_calendar=true`}
            title="Start a new scheduled order on this date"
            className="calendar-month-day__add"
            onClick={trackNewSchedReqClick}>
            <Icon name="add circle" />
          </Link>
        )}
        {this.props.agendaItems.map(this.renderItem)}
      </div>
    );
  }
}

CalendarMonthDay.propTypes = {
  date: PropTypes.object,
  agendaItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.object,
      color: PropTypes.string,
      state: PropTypes.oneOf([
        'actionNeeded',
        'pending',
        'confirmed',
        'cancelled',
      ]),
      link: PropTypes.shape({
        path: PropTypes.string,
        text: PropTypes.string,
      }),
      resource: PropTypes.shape({
        id: PropTypes.number,
      }),
    })
  ).isRequired,
};

CalendarMonthDay.defaultProps = {
  agendaItems: [],
};

const FILTER_ACTION_NEEDED = (item) => item.state !== 'actionNeeded';

export function mapStateToProps(state, ownProps) {
  const isValidDate = moment(ownProps.date)
    .endOf('day')
    .isBetween(moment().startOf('day'), moment().endOf('day').add(3, 'months'));
  let agendaItems = [];
  if (isValidDate) {
    agendaItems = helpers
      .agendaItemsForDate(state.scheduledRequisitions.items, ownProps.date)
      .filter(FILTER_ACTION_NEEDED);
  }
  return {
    agendaItems,
    isValidDate,
  };
}

export function areStatesEqual(prev, next) {
  return prev.scheduledRequisitions.items === next.scheduledRequisitions.items;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  CalendarMonthDay
);
