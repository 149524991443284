import React from 'react';
import PropTypes from 'prop-types';
import './input-textarea.css';

class InputTextArea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  onValueChange = (e) => {
    const value = this.refs.input ? this.refs.input.value : e.target.value;
    this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    return (
      <label>
        {this.props.label !== undefined ? this.props.label : 'Input'}
        <textarea
          ref="input"
          type={this.props.type || 'text'}
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder || ''}
          className={this.props.className}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          required={this.props.required}
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          onChange={this.onValueChange}
        />
      </label>
    );
  }
}

InputTextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default InputTextArea;
