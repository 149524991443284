import fetch from '../lib/hmac-fetch';
import { ADMIN_V1_URL } from '../strings.js';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from '../actions/action-helpers.js';
import { allFeatureTourSteps } from '../components/onboarding/config.js';
export const ERROR_TIMEOUT = 5000;

// Action Types
export const GET_FEATURE_TOURS = 'GET_FEATURE_TOURS';
export const SUCCESS_GET_FEATURE_TOURS = 'SUCCESS_GET_FEATURE_TOURS';
export const ERROR_GET_FEATURE_TOURS = 'ERROR_GET_FEATURE_TOURS';

export const OPEN_FEATURE_TOUR = 'OPEN_FEATURE_TOUR';
export const CLOSE_FEATURE_TOUR = 'CLOSE_FEATURE_TOUR';

export const UNSET_FEATURE_TOURS_RESPONSE = 'UNSET_FEATURE_TOURS_RESPONSE';
export const STAGE_FEATURE_TOUR = 'STAGE_FEATURE_TOUR';
export const UNSTAGE_FEATURE_TOUR = 'UNSTAGE_FEATURE_TOUR';

// Helpers
export const FILTER_IS_REQUESTING = (response) =>
  response.type === GET_FEATURE_TOURS;

// Reducer
export const empty = {
  items: {},
  open: {
    id: 0,
  },
  staged: {
    id: 0,
  },
  meta: {
    cursor: 0,
    next_cursor: 0,
    prev_cursor: 0,
    total: 0,
  },
  requesting: [],
  responses: [],
};

const initialTour = Object.keys(allFeatureTourSteps).reduce(function (
  acc,
  val
) {
  if (acc.name) return acc;
  if (window.location.search.match(val)) {
    return { ...acc, name: val };
  }
  return acc;
},
empty.open);

export const initial = Object.assign({}, empty, {
  open: initialTour,
});

export default (state = initial, action) => {
  switch (action.type) {
    case GET_FEATURE_TOURS:
      return {
        ...state,
        requesting: state.requesting.concat(action),
      };
    case SUCCESS_GET_FEATURE_TOURS:
      return {
        ...state,
        items: Object.assign(
          {},
          state.items,
          action.data.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {})
        ),
        open: {
          ...state.open,
          ...(action.data.items.filter((s) => s.id === state.open.id)[0] ||
            action.data.items.filter((s) => s.name === state.open.name)[0]),
        },
        staged: {
          ...state.staged,
          ...action.data.items.filter((s) => s.id === state.staged.id)[0],
        },
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
        meta: action.data.meta,
      };
    case ERROR_GET_FEATURE_TOURS:
      return {
        ...state,
        requesting: state.requesting.filter(
          (req) => req.data._request !== action.data._request
        ),
        responses: state.responses.concat(action),
      };
    case UNSET_FEATURE_TOURS_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(
          (req) => req.data._request !== action.data._request
        ),
      };
    case OPEN_FEATURE_TOUR:
      return {
        ...state,
        open:
          Object.values(state.items).filter(
            (item) => item.id === action.data.id
          )[0] ||
          Object.values(state.items).filter(
            (item) => item.name === action.data.name
          )[0] ||
          action.data,
      };
    case CLOSE_FEATURE_TOUR:
      return {
        ...state,
        open: empty.open,
      };
    default:
      return state;
  }
};

// Action Creators
export const getFeatureTours = (data) => ({ data, type: GET_FEATURE_TOURS });
export const successGetFeatureTours = (data) => ({
  data,
  type: SUCCESS_GET_FEATURE_TOURS,
});
export const errorGetFeatureTours = (data) => ({
  data,
  type: ERROR_GET_FEATURE_TOURS,
});

export const unsetFeatureToursResponse = (data) => ({
  data,
  type: UNSET_FEATURE_TOURS_RESPONSE,
});
export const openFeatureTour = (data) => ({
  data,
  type: OPEN_FEATURE_TOUR,
});
export const closeFeatureTour = (data) => ({
  data: data || {},
  type: CLOSE_FEATURE_TOUR,
});

/// THUNKS
export const doAndUnsetResponse = (actionCreator) => {
  return (data) => {
    return (dispatch) => {
      dispatch(actionCreator(data));
      setTimeout(() => {
        dispatch(unsetFeatureToursResponse(data));
      }, ERROR_TIMEOUT);
    };
  };
};

export const doGetFeatureTours = (data = {}) => {
  return (dispatch) => {
    data._request = data._request || requestID();
    dispatch(getFeatureTours(data));
    return fetch(`${ADMIN_V1_URL}/feature_tours`, {
      method: 'GET',
      headers: {
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get feature tours' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          doAndUnsetResponse(successGetFeatureTours)({
            items: body.data,
            meta: body.meta,
            _request: data._request,
          })
        );
      })
      .catch((error) => {
        dispatch(
          doAndUnsetResponse(errorGetFeatureTours)({
            ...error,
            _request: data._request,
          })
        );
        trackFetchError(error);
      });
  };
};
