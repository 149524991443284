import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import StipendEditForm from '../../components/stipends/edit-form.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeStipendEditModal } from '../../ducks/stipends.js';
import './modal-common.css';

export class ModalStipendEdit extends React.PureComponent {
  onRequestClose = () => {
    this.props.actions.closeStipendEditModal();
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.actions.doPostStipendEdit({
      items: this.state.newEmployeeStipends,
      id: this.props.customerId,
      stipend_id: this.props.open.id,
    });
  };

  render() {
    if (!this.props.edit.stipend_id) {
      return null;
    }

    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={true}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        scrolling={false}
        className={{
          base: 'modal',
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Stipend Edit Modal"
      >
        <StipendEditForm
          stipend={this.props.stipends[this.props.edit.stipend_id]}
        />
      </Modal>
    );
  }
}

ModalStipendEdit.propTypes = {
  edit: PropTypes.shape({ stipend_id: PropTypes.number }).isRequired,
  stipends: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    closeStipendEditModal: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    edit: state.stipends.edit,
    stipends: state.stipends.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeStipendEditModal,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStipendEdit);
