import * as types from '../actions/action-types.js';

export const empty = {
  items: [],
  updating: [],
  creating: [],
  open: { id: 0 },
  requesting: [],
  errors: [],
};
export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        requesting: state.requesting.concat({
          _request: types.GET_PRODUCT_CATEGORIES,
        }),
      });
    case types.SUCCESS_GET_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        items: action.data,
        open: {
          ...state.open,
          ...(action.data.filter((t) => t.id === state.open.id)[0] || {}),
        },
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PRODUCT_CATEGORIES;
        }),
      });
    case types.ERROR_GET_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_PRODUCT_CATEGORIES;
        }),
        errors: state.errors.concat({
          _request: types.GET_PRODUCT_CATEGORIES,
          data: action.data,
        }),
      });
    case types.GET_PRODUCT_CATEGORY:
    case types.GET_CATEGORY_PRODUCTS:
    case types.GET_POPULAR_CATEGORY_PRODUCTS:
    case types.GET_LOCATION_POPULAR_CATEGORY_PRODUCTS:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_GET_PRODUCT_CATEGORY:
      return Object.assign({}, state, {
        items: state.items
          .filter((c) => c.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
        requesting: state.requesting.filter((r) => {
          return r._request !== action.data._request;
        }),
      });
    case types.SUCCESS_GET_CATEGORY_PRODUCTS:
      items = action.data.products.map((r) => r.id);
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== action.data._request;
        }),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, {
                products: (state.open.products || [])
                  .filter((r) => items.indexOf(r.id) < 0)
                  .concat(action.data.products),
              })
            : state.open,
        items: state.items.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, {
            products: (c.products || [])
              .filter((r) => items.indexOf(r.id) < 0)
              .concat(action.data.products),
          });
        }),
      });
    case types.SUCCESS_POPULAR_CATEGORY_PRODUCTS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== action.data._request;
        }),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, {
                popular_products: action.data.popular_products,
              })
            : state.open,
        items: state.items.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, {
            popular_products: action.data.popular_products,
          });
        }),
      });
    case types.SUCCESS_LOCATION_POPULAR_CATEGORY_PRODUCTS:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== action.data._request;
        }),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, {
                location_popular_products:
                  action.data.location_popular_products,
              })
            : state.open,
        items: state.items.map((c) => {
          if (c.id !== action.data.id) return c;
          return Object.assign({}, c, {
            location_popular_products: action.data.location_popular_products,
          });
        }),
      });
    case types.ERROR_GET_PRODUCT_CATEGORY:
    case types.ERROR_GET_CATEGORY_PRODUCTS:
    case types.ERROR_POPULAR_CATEGORY_PRODUCTS:
    case types.ERROR_LOCATION_POPULAR_CATEGORY_PRODUCTS:
      return Object.assign({}, state, {
        errors: state.errors.concat(action.data),
        requesting: state.requesting.filter((r) => {
          return r._request !== action.data._request;
        }),
      });
    case types.OPEN_PRODUCT_CATEGORY:
      return Object.assign({}, state, {
        open:
          state.items.filter((t) => t.id === action.data.id)[0] || empty.open,
      });
    case types.CLOSE_PRODUCT_CATEGORY:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
