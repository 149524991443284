import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import BudgetCodeProductsRow from './budget-code-products-row.js';
import Loader from '../loader.js';
import DownArrowIcon from '../../imgs/arrow-down.png';
import { formatShortDate } from '../../lib/formatters.js';
import { trackInterfaceBudgetCodeTableSort } from '../../lib/analytics.js';
import './budget-code-products.css';

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_line_item_budget_code_dates';
const ASC = 'asc';
const DESC = 'desc';
const PRODUCT_NAME = 'product_name';
const SUBMITTED_COUNT = 'item_quantity';
const SUBMITTED_PRICE = 'total_spend';
const SUBMITTED_DATE = 'submitted_date';
const VENDOR_NAME = 'vendor_name';
const FILTER_EXTERNAL = (product) => !!product.vendor_name;

export class BudgetCodeProducts extends React.PureComponent {
  constructor(props) {
    super(props);
    const products = Object.values(this._buildData(props.dataViews));
    this.state = {
      products,
      externalProducts: products.filter(FILTER_EXTERNAL),
      sortDirection: DESC,
      sortAttribute: SUBMITTED_PRICE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const products = Object.values(this._buildData(nextProps.dataViews));
    this.setState((prevState) => ({
      products,
      externalProducts: products.filter(FILTER_EXTERNAL),
      sortDirection: prevState.sortDirection || DESC,
      sortAttribute: prevState.sortAttribute || SUBMITTED_PRICE,
    }));
    this.sortProducts(this.state.sortAttribute, this.state.sortDirection);
  }

  componentDidUpdate(prevProps) {
    if (this.props.budgetCode.name !== prevProps.budgetCode.name) {
      this.sortProducts(this.state.sortAttribute, this.state.sortDirection);
    }
  }

  defaultFilter = (row) => {
    return row.budget_code_id === this.props.budgetCode.id;
  };

  _buildData(dataViews) {
    if (!dataViews.length) return [];

    const budgetCodeProducts = dataViews
      .reduce((acc, dv) => {
        return acc.concat(dv.data);
      }, [])
      .filter(this.props.dataFilter || this.defaultFilter);
    return budgetCodeProducts.reduce(this._productReducer, {});
  }

  _buildReducedProductsData(products) {
    let aggregateProductData = this._reduceProducts(products);
    return this._sortProducts(Object.values(aggregateProductData));
  }

  _buildReducedLocationProducts(dataViews) {
    return [].concat.apply(
      [],
      dataViews.map((d) => this._reduceDataViews(d))
    );
  }

  _reduceDataViews(dataView) {
    return dataView.data.reduce(this._dvDataReducer, []);
  }

  _reduceProducts(products) {
    return products.reduce(this._productReducer, {});
  }

  _sortProducts(products) {
    return this._genericSort(ASC, SUBMITTED_COUNT, products);
  }

  _productReducer = (acc, product) => {
    let productId = product.product_id || product.product_name;
    if (!acc[productId]) {
      acc[productId] = {
        product_id: productId,
        product_name: product.product_name,
        submitted_date: product.submitted_date,
        item_quantity: product.item_quantity,
        total_spend: product.total_spend,
        vendor_name: product.vendor_name,
        location_id: product.location_id,
        customer_purchase_id: product.customer_purchase_id,
      };
    } else {
      if (acc[productId].submitted_date < product.submitted_date) {
        acc[productId].submitted_date = product.submitted_date;
        acc[productId].customer_purchase_id = product.customer_purchase_id;
        acc[productId].location_id = product.location_id;
      }
      acc[productId].item_quantity += product.item_quantity;
      acc[productId].total_spend += product.total_spend;
    }
    return acc;
  };

  _dvDataReducer = (acc, val) => {
    if (val.budget_code_id === this.props.budgetCode.id) {
      acc.push(val);
    }
    return acc;
  };

  _genericSort = (direction, attribute, objects) => {
    return objects
      .sort((a, b) => {
        if (a[attribute] === null) {
          return 1;
        }
        if (b[attribute] === null) {
          return -1;
        }
        return (
          (a[attribute] > b[attribute] ? 1 : -1) * (direction === DESC ? -1 : 1)
        );
      })
      .concat([]);
  };

  sortProducts = (attribute, direction = null) => {
    if (!direction) {
      if (this.state.sortAttribute === attribute) {
        direction = this.state.sortDirection === DESC ? ASC : DESC;
      } else {
        direction = this.state.sortDirection;
      }
    }
    this.setState((prevState) => ({
      products: this._genericSort(direction, attribute, prevState.products),
      sortDirection: direction,
      sortAttribute: attribute,
    }));
    trackInterfaceBudgetCodeTableSort({ value: attribute });
  };

  renderProductRow(product, index) {
    return <BudgetCodeProductsRow key={index} productData={product} />;
  }

  renderSortArrow(attribute) {
    if (this.state.sortAttribute === attribute) {
      return (
        <img
          src={DownArrowIcon}
          alt="sort-arrow"
          className={classnames('sort-arrow', {
            up: this.state.sortDirection === ASC,
          })}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="budget-code-products">
        {this.state.products.length === 0 && (
          <h5 className="header">
            <span className="row-one">
              No Items Ordered for {this.props.budgetCode.name}
            </span>
            <span className="row-two">
              Between {formatShortDate(this.props.startDate)} and{' '}
              {formatShortDate(this.props.endDate)}
            </span>
          </h5>
        )}
        {this.state.products.length > 0 && (
          <div className="header-row">
            <div className="product-row flex-wrapper">
              <button
                className="product-details-header name bold-text"
                onClick={this.sortProducts.bind(this, PRODUCT_NAME, null)}
              >
                <span>Product</span>
                {this.renderSortArrow(PRODUCT_NAME)}
              </button>

              <button
                className="product-details-header vendor_name bold-text"
                onClick={this.sortProducts.bind(this, VENDOR_NAME)}
              >
                {!!this.state.externalProducts.length && <span>Vendor</span>}
                {this.renderSortArrow(VENDOR_NAME)}
              </button>

              <button
                className="product-details-header count bold-text"
                onClick={this.sortProducts.bind(this, SUBMITTED_COUNT, null)}
              >
                <span>Quantity</span>
                {this.renderSortArrow(SUBMITTED_COUNT)}
              </button>
              <button
                className="product-details-header price bold-text"
                onClick={this.sortProducts.bind(this, SUBMITTED_PRICE, null)}
              >
                <span>Spend</span>
                {this.renderSortArrow(SUBMITTED_PRICE)}
              </button>
              <button
                className="product-details-header submitted-date bold-text"
                onClick={this.sortProducts.bind(this, SUBMITTED_DATE, null)}
              >
                <span>Last Ordered</span>
                {this.renderSortArrow(SUBMITTED_DATE)}
              </button>
            </div>
          </div>
        )}
        {this.state.products.map(this.renderProductRow)}
        {this.props.isRequesting && <Loader />}
      </div>
    );
  }
}

BudgetCodeProducts.propTypes = {
  isRequesting: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  budgetCode: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          location_id: PropTypes.number,
          requisition_id: PropTypes.number,
          product_name: PropTypes.string,
          product_id: PropTypes.number,
          budget_code_id: PropTypes.number,
          budget_code_name: PropTypes.string,
          budget_code_code: PropTypes.string,
          submitted_date: PropTypes.string,
          item_quantity: PropTypes.number,
          total_spend: PropTypes.number,
          customer_purchase_id: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

function mapStateToProps(state, props) {
  const locations = props.locations.map((l) => l.id);
  const start = props.startDate.toJSON();
  const end = props.endDate.toJSON();
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === start &&
        d.end === end &&
        locations.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === start &&
          d.end === end &&
          locations.indexOf(d.topic.id) > -1
      ).length > 0,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.requesting === next.dataViews.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(BudgetCodeProducts);
