import React from 'react';

import { connect } from 'react-redux';
import { Header, Segment, Table } from 'semantic-ui-react';
import InvoiceItem from './item-row.js';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/table.min.css';
import './item-list.css';

export class InvoiceItemList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortedItems: this.removeDuplicates(
        [].slice.call(props.items).sort(props.sorter || this.defaultSort)
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        sortedItems: this.removeDuplicates(
          [].slice
            .call(nextProps.items)
            .sort(nextProps.sorter || this.defaultSort)
        ),
      })
    );
  }

  removeDuplicates = (items) => {
    let seen = [];
    let duplicates = [];
    let toremove = [];

    items.forEach((i) => {
      if (seen.includes(i.invoice_number)) {
        duplicates.push(i);
      } else {
        seen.push(i.invoice_number);
      }
    });

    duplicates.forEach((d) => {
      let pair = items.filter((i) => i.invoice_number === d.invoice_number);
      pair.sort((a, b) => {
        return a.updated_at > b.updated_at ? 1 : -1;
      });
      toremove.push(pair[0]);
    });

    items = items.filter((i) => !toremove.includes(i));

    return items;
  };

  defaultSort = (a, b) => {
    return a.created_at < b.created_at ? 1 : -1;
  };

  renderItem = (item) => {
    return <InvoiceItem item={item} key={item.id} />;
  };

  render() {
    let empty = this.state.sortedItems.length < 1;
    return (
      <div className="invoice-item-list">
        <Header as="h2" attached="top">
          Invoices
        </Header>
        <Segment attached="bottom">
          <Table
            compact
            basic
            attached={empty ? true : 'bottom'}
            className="invoices">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell singleLine>Invoice #</Table.HeaderCell>
                <Table.HeaderCell>PDF</Table.HeaderCell>
                <Table.HeaderCell singleLine>Amount Due</Table.HeaderCell>
                <Table.HeaderCell singleLine>Invoice Date</Table.HeaderCell>
                <Table.HeaderCell singleLine>Due Date</Table.HeaderCell>
                <Table.HeaderCell singleLine>Payment</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.sortedItems.map(this.renderItem)}
            </Table.Body>
            <Table.Footer>
              <Table.Row></Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
        {empty && (
          <Segment
            attached="bottom"
            placeholder
            loading={this.props.isRequesting}>
            <Header textAlign="center">No invoices for this office.</Header>
          </Segment>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  if (!state.invoices) return { items: [] };
  return {
    items: state.invoices.items.filter(
      props.filter ||
        (() => {
          return true;
        })
    ),
    isRequesting: state.invoices.isRequesting,
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return prev.invoices.items === next.invoices.items;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InvoiceItemList);
