import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import OfficeluvCategoryContainer from '../components/supplies/officeluv-category-container.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import { openFeatureTour } from '../ducks/feature-tours.js';
import { hasSeenFeatureTour } from '../helpers/feature-tour-helpers.js';
import BannerItems from '../components/banners/banner-items.js';
import './officeluv-catalog.css';

class OfficeLuvCatalogRoute extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Product Catalog.', 'Marketplace');

    if (this.props.displayFeatureTour) {
      this.props.actions.openFeatureTour({ name: 'initial_intro_enabled' });
    }
  }

  render() {
    return (
      <div className="officeluv-catalog">
        <MountAtPageTop />
        <AuthCheck />
        <NavBarAttendant />
        <BannerItems />
        <OfficeluvCategoryContainer />
      </div>
    );
  }
}

OfficeLuvCatalogRoute = setTitle(() => {
  return 'Officeluv Catalog';
})(OfficeLuvCatalogRoute);

function mapStateToProps(state) {
  return {
    displayFeatureTour: !hasSeenFeatureTour(
      'initial_intro_enabled',
      state.featureTours.items,
      state.featureTourAttempts.summary
    ),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openFeatureTour,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.featureTours.items === next.featureTours.items &&
    prev.featureTourAttempts.summary === next.featureTourAttempts.summary
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeLuvCatalogRoute);
