import React from 'react';
import Modal from 'react-modal';
import OnboardingStep from '../onboarding/step.js';
import OnboardingDeliveryInfoForm from '../onboarding/delivery-info-form.js';
import image from '../../imgs/onboarding/delivery.jpg';
import './modal-common.css';
import './modal-delivery-info.css';

export default class ModalDeliveryInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  onRequestClose = () => {
    this.setState({ isOpen: false });
  };

  handleCloseModal = () => {
    this.onRequestClose();
  };

  handleSubmit = () => {};

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!this.state.isOpen}
        onRequestClose={this.onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal modal-delivery-info',
          afterOpen: 'modal-after-open',
          beforeClose: 'modal-before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        contentLabel="Delivery Info Modal"
      >
        <OnboardingStep backgroundImage={`url(${image})`}>
          <OnboardingDeliveryInfoForm
            onSubmit={this.handleSubmit}
            submitButtonText="Update Delivery Info"
          />
        </OnboardingStep>
      </Modal>
    );
  }
}
