import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Header } from 'semantic-ui-react';
import { formatCentAmount, formatDate } from '../../lib/formatters.js';

const EmployeeStipendDetails = (props) => {
  if (!props.employeeStipends) return null;

  if (props.employeeStipends.length === 0)
    return (
      <div className="card" style={{ marginBottom: '2rem', marginTop: '2rem' }}>
        <Header as="h2">{props.header}</Header>
        <h4>None remaining</h4>
      </div>
    );

  return (
    <div className="card" style={{ marginBottom: '2rem', marginTop: '2rem' }}>
      <Header as="h2">{props.header}</Header>
      <Table basic="very" collapsing compact>
        {props.employeeStipends.map((stipend) => {
          return (
            <Table.Body>
              <hr />
              <Table.Row>
                <Table.Cell>
                  <strong>Stipend Name</strong>
                </Table.Cell>
                <Table.Cell>{stipend.stipend_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Available</strong>
                </Table.Cell>
                <Table.Cell>
                  {formatCentAmount(stipend.stipend_remaining)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Initial Amount</strong>
                </Table.Cell>
                <Table.Cell>{formatCentAmount(stipend.amount)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Expires</strong>
                </Table.Cell>
                <Table.Cell>{formatDate(stipend.expires_at)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          );
        })}
      </Table>
    </div>
  );
};

EmployeeStipendDetails.propTypes = {
  employeeStipends: PropTypes.object.isRequired,
  header: PropTypes.string,
};

EmployeeStipendDetails.defaultProps = {
  header: 'Your Stipend Details',
};

export function mapStateToProps(state) {
  const sourceEmployeeStipends = state.employees.user.employee_stipends;
  let employeeStipends = null;
  if (sourceEmployeeStipends) {
    employeeStipends = sourceEmployeeStipends;
  }
  return {
    employeeStipends: employeeStipends,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.auth.role === next.auth.role && prev.carts.open === next.carts.open
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  EmployeeStipendDetails
);
