import ReactGA from 'react-ga';
// import Cookies                 from 'js-cookie';
import {
  trackFetchError,
  trackEmployeeAvatarUpated,
  trackEmployeeUpated,
  trackEmployeeInvited,
  trackEmployeeUnInvited,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import * as types from './action-types';
import fetch from '../lib/hmac-fetch.js';
import { successGetCustomers } from './customer-actions.js';
import { enqueueConfirmation } from './confirmations-actions.js';
import { successLocations } from './locations-actions.js';
import {
  ADMIN_VI_URL_INVITES,
  ENV_STRING,
  HEADERS_JSON,
  ADMIN_V1_URL,
} from '../strings';

export const postUpdateEmployee = (data) => {
  return {
    type: types.UPDATE_EMPLOYEE,
    data,
  };
};

const getLocationEmployees = (data) => {
  return {
    type: types.GET_LOCATION_EMPLOYEES,
    data,
  };
};

export const successEmployees = (data) => {
  return {
    type: types.SUCCESS_EMPLOYEES,
    data,
  };
};

export const errorEmployees = (data) => {
  return {
    type: types.ERROR_EMPLOYEES,
    data,
  };
};

export const successUpdateEmployee = (data, update) => {
  return {
    type: types.SUCCESS_UPDATE_EMPLOYEE,
    data,
    update,
  };
};

const errorUpdateEmployee = (data) => {
  return {
    type: types.ERROR_UPDATE_EMPLOYEE,
    data,
  };
};

export const postUpdateAvatar = (data) => {
  return {
    type: types.UPDATE_AVATAR,
    data,
  };
};

const successUpdateAvatar = (data) => {
  return {
    type: types.SUCCESS_UPDATE_AVATAR,
    data,
  };
};

const errorUpdateAvatar = (data) => {
  return {
    type: types.ERROR_UPDATE_AVATAR,
    data,
  };
};

const getEmployeSelf = () => {
  return {
    type: types.GET_EMPLOYEE_SELF,
  };
};

export const successEmployeeSelf = (data) => {
  ReactGA.set({
    dimension3: 'office_manager', // set user role dimension
    dimension5: `employee-${data.id}-${ENV_STRING}`, // set user remote id
  });
  return {
    type: types.SUCCESS_EMPLOYEE_SELF,
    data,
  };
};

const errorEmployeeSelf = (data) => {
  return {
    type: types.ERROR_EMPLOYEE_SELF,
    data,
  };
};

const postInviteEmployees = (data) => {
  return {
    type: types.POST_INVITE_EMPLOYEES,
    data,
  };
};

const postInviteEmployee = (data) => {
  return {
    type: types.POST_INVITE_EMPLOYEE,
    data,
  };
};

const successInviteEmployee = (data) => {
  return {
    type: types.SUCCESS_INVITE_EMPLOYEE,
    data,
  };
};

const errorInviteEmployee = (data) => {
  return {
    type: types.ERROR_INVITE_EMPLOYEE,
    data,
  };
};

const successUninviteEmployee = (data) => {
  return {
    type: types.SUCCESS_UNINVITE_EMPLOYEE,
    data,
  };
};

const getEmployee = (data) => {
  return {
    type: types.GET_EMPLOYEE,
    data,
  };
};

const errorGetEmployee = (data) => {
  return {
    type: types.ERROR_GET_EMPLOYEE,
    data,
  };
};

export const openEmployee = (data) => ({ data, type: types.OPEN_EMPLOYEE });
export const closeEmployee = () => ({ data: {}, type: types.CLOSE_EMPLOYEE });

export const getEmployeeStripeAccount = (data) => ({
  data,
  type: types.GET_EMPLOYEE_STRIPE_ACCOUNT,
});

export const successGetEmployeeStripeAccount = (data) => ({
  data,
  type: types.SUCCESS_GET_EMPLOYEE_STRIPE_ACCOUNT,
});

export const errorGetEmployeeStripeAccount = (data) => ({
  data,
  type: types.ERROR_GET_EMPLOYEE_STRIPE_ACCOUNT,
});

export const getEmployeeRecurlyAccount = (data) => ({
  data,
  type: types.GET_EMPLOYEE_RECURLY_ACCOUNT,
});

export const successGetEmployeeRecurlyAccount = (data) => ({
  data,
  type: types.SUCCESS_GET_EMPLOYEE_RECURLY_ACCOUNT,
});

export const errorGetEmployeeRecurlyAccount = (data) => ({
  data,
  type: types.ERROR_GET_EMPLOYEE_RECURLY_ACCOUNT,
});

// thunks

export function doGetEmployee(employee) {
  return (dispatch, getState) => {
    dispatch(getEmployee(employee));
    return fetch(`${ADMIN_V1_URL}/employees/${employee.id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: employee,
            message: 'Get employee ' + employee.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        const { employees } = getState();
        const extant = employees.items.filter(
          (t) => t.id === employee.id
        ).length;
        if (extant) {
          dispatch(successUpdateEmployee(body));
        } else {
          dispatch(successInviteEmployee(body));
        }
      })
      .catch((error) => {
        dispatch(errorGetEmployee(error));
        trackFetchError(error);
      });
  };
}

export function doInviteEmployees(employees) {
  return (dispatch, getState) => {
    dispatch(postInviteEmployees(employees));
    return Promise.all(
      employees.map((d) => {
        return doInviteEmployee(d)(dispatch, getState);
      })
    );
  };
}

export function doInviteEmployee(data) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    data.locations = data.locations || [locations.open.id];
    data.customer_id = data.customer_id || locations.open.customer_id;
    dispatch(postInviteEmployee(data));
    return fetch(`${ADMIN_VI_URL_INVITES}/invite`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Invite employee ' + data.email,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successInviteEmployee(
            Object.assign(body, { _request: data._request })
          )
        );
        trackEmployeeInvited(body);
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Invited ' + body.email,
          })
        );
      })
      .catch((error) => {
        dispatch(errorInviteEmployee(error));
        trackFetchError(error);
      });
  };
}

export function doUninviteEmployee(data, options = { bubble: false }) {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    const { locations } = getState();
    data.locations = data.location ? [data.location.id] : [locations.open.id];
    dispatch(postUpdateEmployee(data));
    return fetch(`${ADMIN_VI_URL_INVITES}/uninvite`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Uninvite employee ' + data.email,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successUninviteEmployee(
            Object.assign(body, {
              _request: data._request,
              _isOpenLocation: data._isOpenLocation,
            })
          )
        );
        dispatch(
          enqueueConfirmation({
            message: `removed ${data.email} from ${
              data.location.name || 'location'
            }`,
            type: 'success',
          })
        );
        trackEmployeeUnInvited(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorUpdateEmployee(error));
        trackFetchError(error);
      });
  };
}

export function doGetEmployeeSelf(options = { bubble: false }) {
  return (dispatch, getState) => {
    const { auth } = getState();
    dispatch(getEmployeSelf());
    return fetch(`${ADMIN_V1_URL}/employees/${auth.record.id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: auth.record,
            message: 'Get self employee',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successEmployeeSelf(body || {}));
        dispatch(successGetCustomers([body.customer]));
        dispatch(successLocations(body.locations));
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorEmployeeSelf(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doGetLocationEmployees(location) {
  return (dispatch) => {
    dispatch(getLocationEmployees(location));
    return _getPagedLocationEmployees(location)
      .then((body) => {
        dispatch(successEmployees(body || []));
      })
      .catch((error) => {
        dispatch(errorEmployees(error));
        trackFetchError(error);
      });
  };
}

export function _getPagedLocationEmployees(location, paged = null, total = []) {
  const path = paged || `/locations/${location.id}/employees?limit=100`;
  return fetch(`${ADMIN_V1_URL}${path}`)
    .then((response) => {
      if (response.status !== 200) {
        throw fetchError({ response, data: {}, message: 'Get employees' });
      }
      return response.json();
    })
    .then((body) => {
      total = total.concat(body.data);
      if (body.meta && body.meta.next_cursor) {
        return _getPagedLocationEmployees(
          location,
          `/locations/${location.id}/employees?cursor=${body.meta.next_cursor}&limit=100`,
          total
        );
      }
      return total;
    });
}

export function submitUpdateEmployee(data, options = { bubble: false }) {
  return (dispatch) => {
    dispatch(postUpdateEmployee(data));
    return fetch(`${ADMIN_V1_URL}/employees/${data.id}`, {
      method: 'PATCH',
      headers: HEADERS_JSON,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update employee ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateEmployee(body || {}, data));
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'User Updated!',
          })
        );
        trackEmployeeUpated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorUpdateEmployee(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitUpdateAvatar(
  employee,
  form_data,
  options = { bubble: false }
) {
  return (dispatch) => {
    dispatch(postUpdateAvatar(employee));
    return fetch(`${ADMIN_V1_URL}/employees/${employee.id}/avatars`, {
      method: 'PUT',
      body: form_data,
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: {
              id: employee.id,
              form_data,
            },
            message: 'Update empoyee avatar',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successUpdateAvatar(body));
        trackEmployeeAvatarUpated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorUpdateAvatar(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitEmployeeRecurlyExternalAccount(
  data,
  options = { bubble: false, method: 'POST', confirm: false }
) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(postUpdateEmployee(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.id}/external_accounts/recurly`,
      {
        method: options.method || 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data.recurly),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Save Recurly account for employee ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        let external_accounts =
          (getState().employees.items.filter((e) => e.id === data.id)[0] || {})
            .external_accounts || {};
        external_accounts.recurly = body;
        let update = {
          id: data.id,
          _request: data._request,
          external_accounts: external_accounts,
          recurly_account_id: body.code,
        };
        dispatch(successUpdateEmployee(update));
        if (options.confirm) {
          dispatch(
            enqueueConfirmation({
              type: 'success',
              message: 'Payment Method Updated!',
            })
          );
        }
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (error.status === 409) {
          return error.response.json();
        }
        throw error;
      })
      .then((body) => {
        if (!body || !body.consumer_message) return body;

        dispatch(
          errorUpdateEmployee({
            ...body,
            message: body.consumer_message,
            status: 409,
            _request: data._request,
          })
        );

        return body;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(errorUpdateEmployee(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doGetEmployeeRecurlyAccount(data, options = { bubble: false }) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getEmployeeRecurlyAccount(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.id}/external_accounts/recurly`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get Recurly account for employee ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        let external_accounts =
          (getState().employees.items.filter((e) => e.id === data.id)[0] || {})
            .external_accounts || {};
        external_accounts.recurly = body;
        const employee = {
          id: data.id,
          external_accounts,
        };
        dispatch(successGetEmployeeRecurlyAccount(employee));
        if (options.bubble) return body;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(errorGetEmployeeRecurlyAccount(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function submitEmployeeStripeExternalAccount(
  data,
  options = { bubble: false, method: 'POST', confirm: false }
) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(postUpdateEmployee(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.id}/external_accounts/stripe`,
      {
        method: options.method || 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data.stripe),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Save Stripe account for employee ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        body = Object.assign({}, body, { _request: data._request });
        let external_accounts =
          (getState().employees.items.filter((e) => e.id === data.id)[0] || {})
            .external_accounts || {};
        external_accounts.stripe = body;
        let update = {
          id: data.id,
          _request: data._request,
          external_accounts: external_accounts,
          stripe_customer_id: body.code,
        };
        dispatch(successUpdateEmployee(update));
        if (options.confirm) {
          dispatch(
            enqueueConfirmation({
              type: 'success',
              message: 'Payment Method Updated!',
            })
          );
        }
        if (options.bubble) return body;
      })
      .catch((error) => {
        if (error.status === 409) {
          return error.response.json();
        }
        throw error;
      })
      .then((body) => {
        if (!body || !body.consumer_message) return body;

        dispatch(
          errorUpdateEmployee({
            ...body,
            message: body.consumer_message,
            status: 409,
            _request: data._request,
          })
        );

        return body;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(errorUpdateEmployee(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}

export function doGetEmployeeStripeAccount(data, options = { bubble: false }) {
  return (dispatch, getState) => {
    data._request = data._request || requestID();
    dispatch(getEmployeeStripeAccount(data));
    return fetch(
      `${ADMIN_V1_URL}/employees/${data.id}/external_accounts/stripe`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get Stripe account for employee ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        let external_accounts =
          (getState().employees.items.filter((e) => e.id === data.id)[0] || {})
            .external_accounts || {};
        external_accounts.stripe = body;
        const employee = {
          id: data.id,
          external_accounts,
        };
        dispatch(successGetEmployeeStripeAccount(employee));
        if (options.bubble) return body;
      })
      .catch((error) => {
        error._request = data._request;
        dispatch(errorGetEmployeeStripeAccount(error));
        trackFetchError(error);
        if (options.bubble) throw error;
      });
  };
}
