import React from 'react';
import OnSiteService from '../../imgs/Product_OnSiteService.png';
import { Header, Grid, Image, Segment, Item, Icon } from 'semantic-ui-react';
import QuoteRequestForm from './quote-request-form.js';
import './empty-all.css';

export default class CleaningsEmpty extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showScheduleAttendantForm: false,
    };
  }

  toggleScheduleAttendantForm = (e) => {
    e.preventDefault();
    this.setState(
      Object.assign({}, this.state, {
        showScheduleAttendantForm: !this.state.showScheduleAttendantForm,
      })
    );
  };

  render() {
    return (
      <Segment basic padded className="cleanings-empty">
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div className="card">
                <Header as="h4" content="On-Site Service" />
                <Header as="h2">
                  Our Attendants,
                  <br />
                  Your Office
                </Header>
                <p>
                  From inventory management to cleaning to administrative
                  support, our attendants are trained to handle just about
                  anything you need.
                </p>
                <Item.Group>
                  <Item>
                    <Item.Image size="tiny">
                      <Icon name="group" size="huge" />
                    </Item.Image>
                    <Item.Content>
                      <p>
                        <b>We're an Extension of Your Team.</b> Let us manage
                        your daily office operations, while you handle the rest.
                      </p>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Image size="tiny">
                      <Icon name="list ul" size="huge" />
                    </Item.Image>
                    <Item.Content>
                      <p>
                        <b>No two offices are the same.</b> Your services can be
                        fully customized to meet the needs of your office and
                        adapt when you make changes.
                      </p>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Image size="tiny">
                      <Icon name="handshake outline" size="huge" />
                    </Item.Image>
                    <Item.Content>
                      <p>
                        <b>Let us find your perfect match.</b> All of our
                        attendants are pre-vetted, complete a background check
                        and come with work experience relevant to the job
                        requirements.
                      </p>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </div>
            </Grid.Column>
            <Grid.Column>
              <Image size="medium" src={OnSiteService} />
              <QuoteRequestForm style={{ marginTop: '2rem' }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
