// authentication
export const POST_SIGN_IN = 'POST_SIGN_IN';
export const ERROR_SIGN_IN = 'ERROR_SIGN_IN';
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const POST_FORGOT_PASSWORD = 'POST_FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'SUCCESS_FORGOT_PASSWORD';
export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
export const ERROR_RESET_PASSWORD = 'ERROR_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN';

// application
export const NEW_APP_VERSION = 'NEW_APP_VERSION';
export const SET_ACTIVE_PATH = 'SET_ACTIVE_PATH';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const GET_APP_CONFIG = 'GET_APP_CONFIG';
export const SUCCESS_GET_APP_CONFIG = 'SUCCESS_GET_APP_CONFIG';
export const ERROR_GET_APP_CONFIG = 'ERROR_GET_APP_CONFIG';
export const SET_IN_LIMITED_VIEWING_MODE = 'SET_IN_LIMITED_VIEWING_MODE';
export const DISMISS_ANNOUNCEMENT = 'DISMISS_ANNOUNCEMENT';
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED';
export const SET_CART_OPEN = 'SET_CART_OPEN';

// onboarding
export const GET_WITH_TOKEN = 'GET_WITH_TOKEN';
export const ERROR_WITH_TOKEN = 'ERROR_WITH_TOKEN';
export const SUCCESS_WITH_TOKEN = 'SUCCESS_WITH_TOKEN';
export const POST_USER_DETAILS = 'POST_USER_DETAILS';
export const SUCCESS_USER_DETAILS = 'SUCCESS_USER_DETAILS';
export const ERROR_USER_DETAILS = 'ERROR_USER_DETAILS';
export const POST_USER_ROLE = 'POST_USER_ROLE';
export const SUCCESS_USER_ROLE = 'SUCCESS_USER_ROLE';
export const ERROR_USER_ROLE = 'ERROR_USER_ROLE';

// customers
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const SUCCESS_UPDATE_CUSTOMER = 'SUCCESS_UPDATE_CUSTOMER';
export const ERROR_UPDATE_CUSTOMER = 'ERROR_UPDATE_CUSTOMER';
export const SUCCESS_GET_CUSTOMERS = 'SUCCESS_GET_CUSTOMERS';

// locations
export const OPEN_LOCATION = 'OPEN_LOCATION';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const SUCCESS_LOCATIONS = 'SUCCESS_LOCATIONS';
export const ERROR_LOCATIONS = 'ERROR_LOCATIONS';
export const SWITCH_LOCATION = 'SWITCH_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const SUCCESS_UPDATE_LOCATION = 'SUCCESS_UPDATE_LOCATION';
export const ERROR_UPDATE_LOCATION = 'ERROR_UPDATE_LOCATION';
export const GET_LOCATION = 'GET_LOCATION';
export const ERROR_GET_LOCATION = 'ERROR_GET_LOCATION';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const SUCCESS_CREATE_LOCATION = 'SUCCESS_CREATE_LOCATION';
export const ERROR_CREATE_LOCATION = 'ERROR_CREATE_LOCATION';
export const UNSET_LOCATION_RESPONSE = 'UNSET_LOCATION_RESPONSE';

// cleanings
export const GET_CLEANINGS = 'GET_CLEANINGS';
export const SUCCESS_CLEANINGS = 'SUCCESS_CLEANINGS';
export const ERROR_GET_CLEANINGS = 'ERROR_GET_CLEANINGS';
export const GET_CLEANING = 'GET_CLEANING';
export const SUCCESS_CREATE_CLEANING = 'SUCCESS_CREATE_CLEANING';
export const ERROR_GET_CLEANING = 'ERROR_GET_CLEANING';
export const SUCCESS_UPDATE_CLEANING = 'SUCCESS_UPDATE_CLEANING';
export const CREATE_CLEANING_COMMENT = 'CREATE_CLEANING_COMMENT';
export const SUCCESS_CREATE_CLEANING_COMMENT =
  'SUCCESS_CREATE_CLEANING_COMMENT';
export const ERROR_CREATE_CLEANING_COMMENT = 'ERROR_CREATE_CLEANING_COMMENT';
export const OPEN_CLEANING = 'OPEN_CLEANING';
export const CLOSE_CLEANING = 'CLOSE_CLEANING';

// checkin
export const GET_CHECKIN = 'GET_CHECKIN';
export const SUCCESS_GET_CHECKIN = 'SUCCESS_GET_CHECKIN';
export const ERROR_GET_CHECKIN = 'ERROR_GET_CHECKIN';

// checkin_expectations
export const GET_CHECKIN_EXPECTATIONS = 'GET_CHECKIN_EXPECTATIONS';
export const SUCCESS_GET_CHECKIN_EXPECTATIONS =
  'SUCCESS_GET_CHECKIN_EXPECTATIONS';
export const ERROR_GET_CHECKIN_EXPECTATIONS = 'ERROR_GET_CHECKIN_EXPECTATIONS';

// tasks
export const GET_TASKS = 'GET_TASKS';
export const SUCCESS_GET_TASKS = 'SUCCESS_GET_TASKS';
export const ERROR_GET_TASKS = 'ERROR_GET_TASKS';
export const POST_CREATE_TASK = 'POST_CREATE_TASK';
export const SUCCESS_CREATE_TASK = 'SUCCESS_CREATE_TASK';
export const ERROR_CREATE_TASK = 'ERROR_CREATE_TASK';
export const POST_UPDATE_TASK = 'POST_UPDATE_TASK';
export const SUCCESS_UPDATE_TASK = 'SUCCESS_UPDATE_TASK';
export const ERROR_UPDATE_TASK = 'ERROR_UPDATE_TASK';
export const POST_DELETE_TASK = 'POST_DELETE_TASK';
export const SUCCESS_DELETE_TASK = 'SUCCESS_DELETE_TASK';
export const ERROR_DELETE_TASK = 'ERROR_DELETE_TASK';
export const SHOW_CREATE_TASK_MODAL = 'SHOW_CREATE_TASK_MODAL';
export const CLOSE_CREATE_TASK_MODAL = 'CLOSE_CREATE_TASK_MODAL';
export const SHOW_TASK_MODAL = 'SHOW_TASK_MODAL';
export const CLOSE_TASK_MODAL = 'CLOSE_TASK_MODAL';
export const SHOW_TASK_MODAL_CONFIRMATION_DELETE =
  'SHOW_TASK_MODAL_CONFIRMATION_DELETE';
export const CLOSE_TASK_MODAL_CONFIRMATION_DELETE =
  'CLOSE_TASK_MODAL_CONFIRMATION_DELETE';

// reviews
export const POST_CREATE_REVIEW = 'POST_CREATE_REVIEW';
export const SUCCESS_CREATE_REVIEW = 'SUCCESS_CREATE_REVIEW';
export const ERROR_CREATE_REVIEW = 'ERROR_CREATE_REVIEW';
export const POST_UPDATE_REVIEW = 'POST_UPDATE_REVIEW';
export const SUCCESS_UPDATE_REVIEW = 'SUCCESS_UPDATE_REVIEW';
export const ERROR_UPDATE_REVIEW = 'ERROR_UPDATE_REVIEW';

// staff
export const GET_STAFF_SELF = 'GET_STAFF_SELF';
export const ERROR_STAFF_SELF = 'ERROR_STAFF_SELF';
export const SUCCESS_STAFF_SELF = 'SUCCESS_STAFF_SELF';
export const STAFF_CLOCKED_OUT = 'STAFF_CLOCKED_OUT';

// employee
export const GET_EMPLOYEE_SELF = 'GET_EMPLOYEE_SELF';
export const ERROR_EMPLOYEE_SELF = 'ERROR_EMPLOYEE_SELF';
export const SUCCESS_EMPLOYEE_SELF = 'SUCCESS_EMPLOYEE_SELF';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const ERROR_UPDATE_EMPLOYEE = 'ERROR_UPDATE_EMPLOYEE';
export const ERROR_UPDATE_AVATAR = 'ERROR_UPDATE_AVATAR';
export const SUCCESS_UPDATE_EMPLOYEE = 'SUCCESS_UPDATE_EMPLOYEE';
export const SUCCESS_UPDATE_AVATAR = 'SUCCESS_UPDATE_AVATAR';
export const GET_LOCATION_EMPLOYEES = 'GET_LOCATION_EMPLOYEES';
export const ERROR_EMPLOYEES = 'ERROR_EMPLOYEES';
export const SUCCESS_EMPLOYEES = 'SUCCESS_EMPLOYEES';
export const POST_INVITE_EMPLOYEE = 'POST_INVITE_EMPLOYEE';
export const ERROR_INVITE_EMPLOYEE = 'ERROR_INVITE_EMPLOYEE';
export const SUCCESS_INVITE_EMPLOYEE = 'SUCCESS_INVITE_EMPLOYEE';
export const SUCCESS_UNINVITE_EMPLOYEE = 'SUCCESS_UNINVITE_EMPLOYEE';
export const POST_INVITE_EMPLOYEES = 'POST_INVITE_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const ERROR_GET_EMPLOYEE = 'ERROR_GET_EMPLOYEE';
export const OPEN_EMPLOYEE = 'OPEN_EMPLOYEE';
export const CLOSE_EMPLOYEE = 'CLOSE_EMPLOYEE';
export const GET_EMPLOYEE_STRIPE_ACCOUNT = 'GET_EMPLOYEE_STRIPE_ACCOUNT';
export const SUCCESS_GET_EMPLOYEE_STRIPE_ACCOUNT =
  'SUCCESS_GET_EMPLOYEE_STRIPE_ACCOUNT';
export const ERROR_GET_EMPLOYEE_STRIPE_ACCOUNT =
  'ERROR_GET_EMPLOYEE_STRIPE_ACCOUNT';

export const GET_EMPLOYEE_RECURLY_ACCOUNT = 'GET_EMPLOYEE_RECURLY_ACCOUNT';
export const SUCCESS_GET_EMPLOYEE_RECURLY_ACCOUNT =
  'SUCCESS_GET_EMPLOYEE_RECURLY_ACCOUNT';
export const ERROR_GET_EMPLOYEE_RECURLY_ACCOUNT =
  'ERROR_GET_EMPLOYEE_RECURLY_ACCOUNT';

// confirmations
export const ENQUEUE_CONFIRMATION = 'ENQUEUE_CONFIRMATION';
export const DISMISS_CONFIRMATION = 'DISMISS_CONFIRMATION';

// invoices
export const GET_INVOICES = 'GET_INVOICES';
export const ERROR_GET_INVOICES = 'ERROR_GET_INVOICES';
export const SUCCESS_GET_INVOICES = 'SUCCESS_GET_INVOICES';
export const OPEN_INVOICE = 'OPEN_INVOICE';
export const CLOSE_INVOICE = 'CLOSE_INVOICE';
export const GET_BILLING_ADDRESSES = 'GET_BILLING_ADDRESSES';
export const SUCCESS_GET_BILLING_ADDRESSES = 'SUCCESS_GET_BILLING_ADDRESSES';
export const ERROR_GET_BILLING_ADDRESSES = 'ERROR_GET_BILLING_ADDRESSES';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const ERROR_UPDATE_BILLING_ADDRESS = 'ERROR_UPDATE_BILLING_ADDRESS';
export const SUCCESS_UPDATE_BILLING_ADDRESS = 'SUCCESS_UPDATE_BILLING_ADDRESS';
export const DO_GET_INVOICE = 'DO_GET_INVOICE';
export const SUCCESS_DO_GET_INVOICE = 'SUCCESS_DO_GET_INVOICE';
export const ERROR_DO_GET_INVOICE = 'ERROR_DO_GET_INVOICE';

// products/catalog
export const OPEN_PRODUCT = 'OPEN_PRODUCT';
export const CLOSE_PRODUCT = 'CLOSE_PRODUCT';
export const SET_INITIAL_PRODUCT_MODAL_TAB = 'SET_INITIAL_PRODUCT_MODAL_TAB';
export const UNSET_INITIAL_PRODUCT_MODAL_TAB =
  'UNSET_INITIAL_PRODUCT_MODAL_TAB';
export const GET_PRODUCTS_SUGGESTIONS = 'GET_PRODUCTS_SUGGESTIONS';
export const SUCCESS_GET_PRODUCTS_SUGGESTIONS =
  'SUCCESS_GET_PRODUCTS_SUGGESTIONS';
export const ERROR_GET_PRODUCTS_SUGGESTIONS = 'ERROR_GET_PRODUCTS_SUGGESTIONS';
export const CREATE_PRODUCTS_SEARCH = 'CREATE_PRODUCTS_SEARCH';
export const CREATE_CATALOG_SEARCH = 'CREATE_CATALOG_SEARCH';
export const SUCCESS_CREATE_CATALOG_SEARCH = 'SUCCESS_CREATE_CATALOG_SEARCH';
export const ERROR_CREATE_CATALOG_SEARCH = 'ERROR_CREATE_CATALOG_SEARCH';
export const GET_CATALOG_SEARCHES = 'GET_CATALOG_SEARCHES';
export const SUCCESS_GET_CATALOG_SEARCHES = 'SUCCESS_GET_CATALOG_SEARCHES';
export const ERROR_GET_CATALOG_SEARCHES = 'ERROR_GET_CATALOG_SEARCHES';
export const GET_LOCATION_CACHED_PRODUCT = 'GET_LOCATION_CACHED_PRODUCT';
export const SUCCESS_GET_LOCATION_CACHED_PRODUCT =
  'SUCCESS_GET_LOCATION_CACHED_PRODUCT';
export const ERROR_GET_LOCATION_CACHED_PRODUCT =
  'ERROR_GET_LOCATION_CACHED_PRODUCT';
export const SET_PRODUCT_QUERY = 'SET_PRODUCT_QUERY';
export const CLEAR_PRODUCT_FILTERS = 'CLEAR_PRODUCT_FILTERS';
export const CLEAR_PRODUCT_QUERY_RESULTS = 'CLEAR_PRODUCT_QUERY_RESULTS';
export const GET_PRODUCT_BRANDS = 'GET_PRODUCT_BRANDS';
export const SUCCESS_GET_PRODUCT_BRANDS = 'SUCCESS_GET_PRODUCT_BRANDS';
export const ERROR_GET_PRODUCT_BRANDS = 'ERROR_GET_PRODUCT_BRANDS';
export const OPEN_PRODUCT_BRAND = 'OPEN_PRODUCT_BRAND';
export const CLOSE_PRODUCT_BRAND = 'CLOSE_PRODUCT_BRAND';
export const GET_PROMOTED_BRANDS = 'GET_PROMOTED_BRANDS';
export const SUCCESS_GET_PROMOTED_BRANDS = 'SUCCESS_GET_PROMOTED_BRANDS';
export const ERROR_GET_PROMOTED_BRANDS = 'ERROR_GET_PROMOTED_BRANDS';
export const SET_PRODUCT_SORT = 'SET_PRODUCT_SORT';
export const SUCCESS_GET_LOCATION_PRODUCT_QUERY_RESULTS =
  'SUCCESS_GET_LOCATION_PRODUCT_QUERY_RESULTS';

// product_categories/catalog
export const OPEN_PRODUCT_CATEGORY = 'OPEN_PRODUCT_CATEGORY';
export const CLOSE_PRODUCT_CATEGORY = 'CLOSE_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const SUCCESS_GET_PRODUCT_CATEGORIES = 'SUCCESS_GET_PRODUCT_CATEGORIES';
export const ERROR_GET_PRODUCT_CATEGORIES = 'ERROR_GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const SUCCESS_GET_PRODUCT_CATEGORY = 'SUCCESS_GET_PRODUCT_CATEGORY';
export const ERROR_GET_PRODUCT_CATEGORY = 'ERROR_GET_PRODUCT_CATEGORY';
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS';
export const SUCCESS_GET_CATEGORY_PRODUCTS = 'SUCCESS_GET_CATEGORY_PRODUCTS';
export const ERROR_GET_CATEGORY_PRODUCTS = 'ERROR_GET_CATEGORY_PRODUCTS';
export const GET_POPULAR_CATEGORY_PRODUCTS = 'GET_POPULAR_CATEGORY_PRODUCTS';
export const SUCCESS_POPULAR_CATEGORY_PRODUCTS =
  'SUCCESS_POPULAR_CATEGORY_PRODUCTS';
export const ERROR_POPULAR_CATEGORY_PRODUCTS =
  'ERROR_POPULAR_CATEGORY_PRODUCTS';
export const GET_LOCATION_POPULAR_CATEGORY_PRODUCTS =
  'GET_LOCATION_POPULAR_CATEGORY_PRODUCTS';
export const SUCCESS_LOCATION_POPULAR_CATEGORY_PRODUCTS =
  'SUCCESS_LOCATION_POPULAR_CATEGORY_PRODUCTS';
export const ERROR_LOCATION_POPULAR_CATEGORY_PRODUCTS =
  'ERROR_LOCATION_POPULAR_CATEGORY_PRODUCTS';

// requisitions/catalog
export const GET_REQUISITIONS = 'GET_REQUISITIONS';
export const SUCCESS_REQUISITIONS = 'SUCCESS_REQUISITIONS';
export const ERROR_REQUISITIONS = 'ERROR_REQUISITIONS';
export const GET_REQUISITION = 'GET_REQUISITION';
export const SUCCESS_REQUISITION = 'SUCCESS_REQUISITION';
export const ERROR_REQUISITION = 'ERROR_REQUISITION';
export const CREATE_REQUISITION = 'CREATE_REQUISITION';
export const SUCCESS_CREATE_REQUISITION = 'SUCCESS_CREATE_REQUISITION';
export const ERROR_CREATE_REQUISITION = 'ERROR_CREATE_REQUISITION';
export const UPDATE_REQUISITION = 'UPDATE_REQUISITION';
export const SUCCESS_UPDATE_REQUISITION = 'SUCCESS_UPDATE_REQUISITION';
export const ERROR_UPDATE_REQUISITION = 'ERROR_UPDATE_REQUISITION';
export const OPEN_REQUISITION = 'OPEN_REQUISITION';
export const CLOSE_REQUISITION = 'CLOSE_REQUISITION';
export const POST_CHECKOUT = 'POST_CHECKOUT';
export const SUCCESS_CHECKOUT = 'SUCCESS_CHECKOUT';
export const ERROR_CHECKOUT = 'ERROR_CHECKOUT';
export const POST_APPROVAL = 'POST_APPROVAL';
export const POST_EDIT_REQUISITION = 'POST_EDIT_REQUISITION';
export const SUCCESS_EDIT_REQUISITION = 'SUCCESS_EDIT_REQUISITION';
export const ERROR_EDIT_REQUISITION = 'ERROR_EDIT_REQUISITION';
export const SET_EDIT_REQUISITION = 'SET_EDIT_REQUISITION';
export const UNSET_EDIT_REQUISITION = 'UNSET_EDIT_REQUISITION';
export const UPDATE_EDIT_REQUISITION_TAX = 'UPDATE_EDIT_REQUISITION_TAX';
export const SUCCESS_UPDATE_EDIT_REQUISITION_TAX =
  'SUCCESS_UPDATE_EDIT_REQUISITION_TAX';
export const UPDATE_PRODUCT_ON_EDITING_REQUISITION =
  'UPDATE_PRODUCT_ON_EDITING_REQUISITION';
export const ADD_PRODUCT_ON_EDITING_REQUISITION =
  'ADD_PRODUCT_ON_EDITING_REQUISITION';
export const UPDATE_REQUISITION_SHIPPING_PRICE =
  'UPDATE_REQUISITION_SHIPPING_PRICE';
export const SUCCESS_UPDATE_REQUISITION_SHIPPING_PRICE =
  'SUCCESS_UPDATE_REQUISITION_SHIPPING_PRICE';
export const ERROR_UPDATE_REQUISITION_SHIPPING_PRICE =
  'ERROR_UPDATE_REQUISITION_SHIPPING_PRICE';

// product requisition/catalog
export const CREATE_PRODUCT_REQUISITION = 'CREATE_PRODUCT_REQUISITION';
export const SUCCESS_CREATE_PRODUCT_REQUISITION =
  'SUCCESS_CREATE_PRODUCT_REQUISITION';
export const ERROR_CREATE_PRODUCT_REQUISITION =
  'ERROR_CREATE_PRODUCT_REQUISITION';
export const UPDATE_PRODUCT_REQUISITION = 'UPDATE_PRODUCT_REQUISITION';
export const SUCCESS_UPDATE_PRODUCT_REQUISITION =
  'SUCCESS_UPDATE_PRODUCT_REQUISITION';
export const ERROR_UPDATE_PRODUCT_REQUISITION =
  'ERROR_UPDATE_PRODUCT_REQUISITION';
export const DELETE_PRODUCT_REQUISITION = 'DELETE_PRODUCT_REQUISITION';
export const SUCCESS_DELETE_PRODUCT_REQUISITION =
  'SUCCESS_DELETE_PRODUCT_REQUISITION';
export const ERROR_DELETE_PRODUCT_REQUISITION =
  'ERROR_DELETE_PRODUCT_REQUISITION';
export const CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE =
  'CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE';
export const SUCCESS_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE =
  'SUCCESS_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE';
export const ERROR_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE =
  'ERROR_CREATE_PRODUCT_REQUISITION_CUSTOMER_BUDGET_CODE';

// location list/catalog
export const GET_LOCATION_LISTS = 'GET_LOCATION_LISTS';
export const SUCCESS_LOCATION_LISTS = 'SUCCESS_LOCATION_LISTS';
export const ERROR_LOCATION_LISTS = 'ERROR_LOCATION_LISTS';
export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
export const SUCCESS_LOCATION_LIST = 'SUCCESS_LOCATION_LIST';
export const ERROR_LOCATION_LIST = 'ERROR_LOCATION_LIST';
export const CREATE_LOCATION_LIST = 'CREATE_LOCATION_LIST';
export const SUCCESS_CREATE_LOCATION_LIST = 'SUCCESS_CREATE_LOCATION_LIST';
export const ERROR_CREATE_LOCATION_LIST = 'ERROR_CREATE_LOCATION_LIST';
export const UPDATE_LOCATION_LIST = 'UPDATE_LOCATION_LIST';
export const SUCCESS_UPDATE_LOCATION_LIST = 'SUCCESS_UPDATE_LOCATION_LIST';
export const ERROR_UPDATE_LOCATION_LIST = 'ERROR_UPDATE_LOCATION_LIST';
export const DELETE_LOCATION_LIST = 'DELETE_LOCATION_LIST';
export const SUCCESS_DELETE_LOCATION_LIST = 'SUCCESS_DELETE_LOCATION_LIST';
export const ERROR_DELETE_LOCATION_LIST = 'ERROR_DELETE_LOCATION_LIST';
export const OPEN_LOCATION_LIST = 'OPEN_LOCATION_LIST';
export const CLOSE_LOCATION_LIST = 'CLOSE_LOCATION_LIST';
export const OPEN_LOCATION_LIST_CREATE_MODAL =
  'OPEN_LOCATION_LIST_CREATE_MODAL';
export const CLOSE_LOCATION_LIST_CREATE_MODAL =
  'CLOSE_LOCATION_LIST_CREATE_MODAL';
export const OPEN_LOCATION_LIST_EDIT_MODAL = 'OPEN_LOCATION_LIST_EDIT_MODAL';
export const CLOSE_LOCATION_LIST_EDIT_MODAL = 'CLOSE_LOCATION_LIST_EDIT_MODAL';

// location list products/catalog
export const GET_LOCATION_LIST_PRODUCTS = 'GET_LOCATION_LIST_PRODUCTS';
export const SUCCESS_LOCATION_LIST_PRODUCTS = 'SUCCESS_LOCATION_LIST_PRODUCTS';
export const ERROR_LOCATION_LIST_PRODUCTS = 'ERROR_LOCATION_LIST_PRODUCTS';
export const GET_LOCATION_LIST_PRODUCT = 'GET_LOCATION_LIST_PRODUCT';
export const SUCCESS_LOCATION_LIST_PRODUCT = 'SUCCESS_LOCATION_LIST_PRODUCT';
export const ERROR_LOCATION_LIST_PRODUCT = 'ERROR_LOCATION_LIST_PRODUCT';
export const CREATE_LOCATION_LIST_PRODUCT = 'CREATE_LOCATION_LIST_PRODUCT';
export const SUCCESS_CREATE_LOCATION_LIST_PRODUCT =
  'SUCCESS_CREATE_LOCATION_LIST_PRODUCT';
export const ERROR_CREATE_LOCATION_LIST_PRODUCT =
  'ERROR_CREATE_LOCATION_LIST_PRODUCT';
export const UPDATE_LOCATION_LIST_PRODUCT = 'UPDATE_LOCATION_LIST_PRODUCT';
export const SUCCESS_UPDATE_LOCATION_LIST_PRODUCT =
  'SUCCESS_UPDATE_LOCATION_LIST_PRODUCT';
export const ERROR_UPDATE_LOCATION_LIST_PRODUCT =
  'ERROR_UPDATE_LOCATION_LIST_PRODUCT';
export const DELETE_LOCATION_LIST_PRODUCT = 'DELETE_LOCATION_LIST_PRODUCT';
export const SUCCESS_DELETE_LOCATION_LIST_PRODUCT =
  'SUCCESS_DELETE_LOCATION_LIST_PRODUCT';
export const ERROR_DELETE_LOCATION_LIST_PRODUCT =
  'ERROR_DELETE_LOCATION_LIST_PRODUCT';
export const CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE =
  'CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE';
export const SUCCESS_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE =
  'SUCCESS_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE';
export const ERROR_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE =
  'ERROR_CREATE_LOCATION_LIST_PRODUCT_CUSTOMER_BUDGET_CODE';

// location parent product categories
export const GET_LOCATION_PARENT_PRODUCT_CATEGORIES =
  'GET_LOCATION_PARENT_PRODUCT_CATEGORIES';
export const SUCCESS_GET_LOCATION_PARENT_PRODUCT_CATEGORIES =
  'SUCCESS_GET_LOCATION_PARENT_PRODUCT_CATEGORIES';
export const ERROR_GET_LOCATION_PARENT_PRODUCT_CATEGORIES =
  'ERROR_GET_LOCATION_PARENT_PRODUCT_CATEGORIES';

// customer budget codes
export const GET_CUSTOMER_BUDGET_CODES = 'GET_CUSTOMER_BUDGET_CODES';
export const SUCCESS_GET_CUSTOMER_BUDGET_CODES =
  'SUCCESS_GET_CUSTOMER_BUDGET_CODES';
export const ERROR_GET_CUSTOMER_BUDGET_CODES =
  'ERROR_GET_CUSTOMER_BUDGET_CODES';
export const GET_CUSTOMER_BUDGET_CODE = 'GET_CUSTOMER_BUDGET_CODE';
export const SUCCESS_GET_CUSTOMER_BUDGET_CODE =
  'SUCCESS_GET_CUSTOMER_BUDGET_CODE';
export const ERROR_GET_CUSTOMER_BUDGET_CODE = 'ERROR_GET_CUSTOMER_BUDGET_CODE';
export const CREATE_CUSTOMER_BUDGET_CODE = 'CREATE_CUSTOMER_BUDGET_CODE';
export const SUCCESS_CREATE_CUSTOMER_BUDGET_CODE =
  'SUCCESS_CREATE_CUSTOMER_BUDGET_CODE';
export const ERROR_CREATE_CUSTOMER_BUDGET_CODE =
  'ERROR_CREATE_CUSTOMER_BUDGET_CODE';
export const UPDATE_CUSTOMER_BUDGET_CODE = 'UPDATE_CUSTOMER_BUDGET_CODE';
export const SUCCESS_UPDATE_CUSTOMER_BUDGET_CODE =
  'SUCCESS_UPDATE_CUSTOMER_BUDGET_CODE';
export const ERROR_UPDATE_CUSTOMER_BUDGET_CODE =
  'ERROR_UPDATE_CUSTOMER_BUDGET_CODE';
export const OPEN_CUSTOMER_BUDGET_CODE = 'OPEN_CUSTOMER_BUDGET_CODE';
export const CLOSE_CUSTOMER_BUDGET_CODE = 'CLOSE_CUSTOMER_BUDGET_CODE';
export const OPEN_CUSTOMER_BUDGET_CODE_CREATE_MODAL =
  'OPEN_CUSTOMER_BUDGET_CODE_CREATE_MODAL';
export const CLOSE_CUSTOMER_BUDGET_CODE_CREATE_MODAL =
  'CLOSE_CUSTOMER_BUDGET_CODE_CREATE_MODAL';

// data views
export const GET_DATA_VIEW = 'GET_DATA_VIEW';
export const SUCCESS_DATA_VIEW = 'SUCCESS_DATA_VIEW';
export const ERROR_DATA_VIEW = 'ERROR_DATA_VIEW';
export const OPEN_DATA_VIEW_RANGE = 'OPEN_DATA_VIEW_RANGE';
export const CLOSE_DATA_VIEW_LOCATIONS = 'CLOSE_DATA_VIEW_LOCATIONS';
export const OPEN_DATA_VIEW_LOCATIONS = 'OPEN_DATA_VIEW_LOCATIONS';
export const CLOSE_DATA_VIEW_RANGE = 'CLOSE_DATA_VIEW_RANGE';

// scheduled requisitions
export const GET_SCHEDULED_REQUISITIONS = 'GET_SCHEDULED_REQUISITIONS';
export const SUCCESS_SCHEDULED_REQUISITIONS = 'SUCCESS_SCHEDULED_REQUISITIONS';
export const ERROR_SCHEDULED_REQUISITIONS = 'ERROR_SCHEDULED_REQUISITIONS';
export const GET_SCHEDULED_REQUISITION = 'GET_SCHEDULED_REQUISITION';
export const SUCCESS_GET_SCHEDULED_REQUISITION =
  'SUCCESS_GET_SCHEDULED_REQUISITION';
export const ERROR_GET_SCHEDULED_REQUISITION =
  'ERROR_GET_SCHEDULED_REQUISITION';
export const UPDATE_SCHEDULED_REQUISITION = 'UPDATE_SCHEDULED_REQUISITION';
export const SUCCESS_UPDATE_SCHEDULED_REQUISITION =
  'SUCCESS_UPDATE_SCHEDULED_REQUISITION';
export const ERROR_UPDATE_SCHEDULED_REQUISITION =
  'ERROR_UPDATE_SCHEDULED_REQUISITION';
export const OPEN_SCHEDULED_REQUISITION = 'OPEN_SCHEDULED_REQUISITION';
export const CLOSE_SCHEDULED_REQUISITION = 'CLOSE_SCHEDULED_REQUISITION';
export const CREATE_SCHEDULED_REQUISITION = 'CREATE_SCHEDULED_REQUISITION';
export const SUCCESS_CREATE_SCHEDULED_REQUISITION =
  'SUCCESS_CREATE_SCHEDULED_REQUISITION';
export const ERROR_CREATE_SCHEDULED_REQUISITION =
  'ERROR_CREATE_SCHEDULED_REQUISITION';
export const STAGE_SCHEDULED_REQUISITION = 'STAGE_SCHEDULED_REQUISITION';
export const UPDATE_STAGED_SCHEDULED_REQUISITION =
  'UPDATE_STAGED_SCHEDULED_REQUISITION';
export const GET_SCHEDULED_REQUISITION_TAX = 'GET_SCHEDULED_REQUISITION_TAX';
export const SUCCESS_GET_SCHEDULED_REQUISITION_TAX =
  'SUCCESS_GET_SCHEDULED_REQUISITION_TAX';
export const ERROR_GET_SCHEDULED_REQUISITION_TAX =
  'ERROR_GET_SCHEDULED_REQUISITION_TAX';

// scheduled product requisitions
export const GET_SCHEDULED_PRODUCT_REQUISITIONS =
  'GET_SCHEDULED_PRODUCT_REQUISITIONS';
export const SUCCESS_SCHEDULED_PRODUCT_REQUISITIONS =
  'SUCCESS_SCHEDULED_PRODUCT_REQUISITIONS';
export const ERROR_SCHEDULED_PRODUCT_REQUISITIONS =
  'ERROR_SCHEDULED_PRODUCT_REQUISITIONS';
export const CREATE_SCHEDULED_PRODUCT_REQUISITION =
  'CREATE_SCHEDULED_PRODUCT_REQUISITION';
export const SUCCESS_CREATE_SCHEDULED_PRODUCT_REQUISITION =
  'SUCCESS_CREATE_SCHEDULED_PRODUCT_REQUISITION';
export const ERROR_CREATE_SCHEDULED_PRODUCT_REQUISITION =
  'ERROR_CREATE_SCHEDULED_PRODUCT_REQUISITION';
export const UPDATE_SCHEDULED_PRODUCT_REQUISITION =
  'UPDATE_SCHEDULED_PRODUCT_REQUISITION';
export const SUCCESS_UPDATE_SCHEDULED_PRODUCT_REQUISITION =
  'SUCCESS_UPDATE_SCHEDULED_PRODUCT_REQUISITION';
export const ERROR_UPDATE_SCHEDULED_PRODUCT_REQUISITION =
  'ERROR_UPDATE_SCHEDULED_PRODUCT_REQUISITION';
export const DELETE_SCHEDULED_PRODUCT_REQUISITION =
  'DELETE_SCHEDULED_PRODUCT_REQUISITION';
export const SUCCESS_DELETE_SCHEDULED_PRODUCT_REQUISITION =
  'SUCCESS_DELETE_SCHEDULED_PRODUCT_REQUISITION';
export const ERROR_DELETE_SCHEDULED_PRODUCT_REQUISITION =
  'ERROR_DELETE_SCHEDULED_PRODUCT_REQUISITION';
export const OPEN_SCHEDULED_PRODUCT_REQUISITION =
  'OPEN_SCHEDULED_PRODUCT_REQUISITION';
export const CLOSE_SCHEDULED_PRODUCT_REQUISITION =
  'CLOSE_SCHEDULED_PRODUCT_REQUISITION';

// location catalog products
export const CREATE_LOCATION_CATALOG_PRODUCT =
  'CREATE_LOCATION_CATALOG_PRODUCT';
export const SUCCESS_CREATE_LOCATION_CATALOG_PRODUCT =
  'SUCCESS_CREATE_LOCATION_CATALOG_PRODUCT';
export const ERROR_CREATE_LOCATION_CATALOG_PRODUCT =
  'ERROR_CREATE_LOCATION_CATALOG_PRODUCT';
export const DELETE_LOCATION_CATALOG_PRODUCT =
  'DELETE_LOCATION_CATALOG_PRODUCT';
export const SUCCESS_DELETE_LOCATION_CATALOG_PRODUCT =
  'SUCCESS_DELETE_LOCATION_CATALOG_PRODUCT';
export const ERROR_DELETE_LOCATION_CATALOG_PRODUCT =
  'ERROR_DELETE_LOCATION_CATALOG_PRODUCT';
export const OPEN_LOCATION_CATALOG_PRODUCT = 'OPEN_LOCATION_CATALOG_PRODUCT';
export const CLOSE_LOCATION_CATALOG_PRODUCT = 'CLOSE_LOCATION_CATALOG_PRODUCT';

// external vendor invoices
export const CREATE_EXTERNAL_VENDOR_INVOICE = 'CREATE_EXTERNAL_VENDOR_INVOICE';
export const SUCCESS_CREATE_EXTERNAL_VENDOR_INVOICE =
  'SUCCESS_CREATE_EXTERNAL_VENDOR_INVOICE';
export const ERROR_CREATE_EXTERNAL_VENDOR_INVOICE =
  'ERROR_CREATE_EXTERNAL_VENDOR_INVOICE';
export const UPDATE_EXTERNAL_VENDOR_INVOICE = 'UPDATE_EXTERNAL_VENDOR_INVOICE';
export const SUCCESS_UPDATE_EXTERNAL_VENDOR_INVOICE =
  'SUCCESS_UPDATE_EXTERNAL_VENDOR_INVOICE';
export const ERROR_UPDATE_EXTERNAL_VENDOR_INVOICE =
  'ERROR_UPDATE_EXTERNAL_VENDOR_INVOICE';
export const DELETE_EXTERNAL_VENDOR_INVOICE = 'DELETE_EXTERNAL_VENDOR_INVOICE';
export const SUCCESS_DELETE_EXTERNAL_VENDOR_INVOICE =
  'SUCCESS_DELETE_EXTERNAL_VENDOR_INVOICE';
export const ERROR_DELETE_EXTERNAL_VENDOR_INVOICE =
  'ERROR_DELETE_EXTERNAL_VENDOR_INVOICE';
export const OPEN_EXTERNAL_VENDOR_INVOICE = 'OPEN_EXTERNAL_VENDOR_INVOICE';
export const CLOSE_EXTERNAL_VENDOR_INVOICE = 'CLOSE_EXTERNAL_VENDOR_INVOICE';
export const GET_EXTERNAL_VENDOR_INVOICE = 'GET_EXTERNAL_VENDOR_INVOICE';
export const SUCCESS_GET_EXTERNAL_VENDOR_INVOICE =
  'SUCCESS_GET_EXTERNAL_VENDOR_INVOICE';
export const ERROR_GET_EXTERNAL_VENDOR_INVOICE =
  'ERROR_GET_EXTERNAL_VENDOR_INVOICE';
export const GET_EXTERNAL_VENDOR_INVOICES = 'GET_EXTERNAL_VENDOR_INVOICES';
export const SUCCESS_GET_EXTERNAL_VENDOR_INVOICES =
  'SUCCESS_GET_EXTERNAL_VENDOR_INVOICES';
export const ERROR_GET_EXTERNAL_VENDOR_INVOICES =
  'ERROR_GET_EXTERNAL_VENDOR_INVOICES';

// credits
export const GET_CREDIT = 'GET_CREDIT';
export const SUCCESS_GET_CREDIT = 'SUCCESS_GET_CREDIT';
export const ERROR_GET_CREDIT = 'ERROR_GET_CREDIT';
export const GET_CREDITS = 'GET_CREDITS';
export const SUCCESS_GET_CREDITS = 'SUCCESS_GET_CREDITS';
export const ERROR_GET_CREDITS = 'ERROR_GET_CREDITS';
export const UPDATE_CREDIT = 'UPDATE_CREDIT';
export const SUCCESS_UPDATE_CREDIT = 'SUCCESS_UPDATE_CREDIT';
export const ERROR_UPDATE_CREDIT = 'ERROR_UPDATE_CREDIT';
export const SUCCESS_DELETE_CREDIT = 'SUCCESS_DELETE_CREDIT';

// employee stipends
export const GET_EMPLOYEE_STIPENDS = 'GET_EMPLOYEE_STIPENDS';
export const SUCCESS_EMPLOYEE_STIPENDS = 'SUCCESS_EMPLOYEE_STIPENDS';
export const ERROR_EMPLOYEE_STIPENDS = 'ERROR_EMPLOYEE_STIPENDS';
export const OPEN_EMPLOYEE_STIPENDS = 'OPEN_EMPLOYEE_STIPENDS';
export const CLOSE_EMPLOYEE_STIPENDS = 'CLOSE_EMPLOYEE_STIPENDS';
export const POST_EMPLOYEE_STIPENDS = 'POST_EMPLOYEE_STIPENDS';
export const POST_EMPLOYEE_STIPEND = 'POST_EMPLOYEE_STIPEND';
export const SUCCESS_POST_EMPLOYEE_STIPEND = 'SUCCESS_POST_EMPLOYEE_STIPEND';
export const ERROR_POST_EMPLOYEE_STIPEND = 'ERROR_POST_EMPLOYEE_STIPEND';
export const DELETE_EMPLOYEE_STIPEND = 'DELETE_EMPLOYEE_STIPEND';
export const SUCCESS_DELETE_EMPLOYEE_STIPEND =
  'SUCCESS_DELETE_EMPLOYEE_STIPEND';
export const ERROR_DELETE_EMPLOYEE_STIPEND = 'ERROR_DELETE_EMPLOYEE_STIPEND';

// calendar view
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
