import React from 'react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import TodaysOrdersSection from '../components/supplies/todays-orders-section.js';
import SupplyLevels from '../components/supplies/supply-levels.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';

export class SuppliesSupplyLevels extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Inventory Lists.', 'Marketplace');
  }

  render() {
    return (
      <div className="office-attendant-cleaning-schedule">
        <AuthCheck />
        <MountAtPageTop />
        <NavBarAttendant />
        <TodaysOrdersSection />
        <div className="supply-levels variable-width-column">
          <SupplyLevels />
        </div>
      </div>
    );
  }
}

export default setTitle((props) => {
  return 'Supply & Grocery Lists';
})(SuppliesSupplyLevels);
