import React from 'react';

import PropTypes from 'prop-types';
import './input.css';

class InputEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
    this.state = {
      value: props.value || '',
    };
  }

  onValueChange() {
    this.props.onChange(this.refs.input.value);
    this.setState({ value: this.refs.input.value });
  }

  render() {
    return (
      <label>
        {this.props.label !== undefined ? this.props.label : 'Email'}
        <input
          ref="input"
          type="email"
          name={this.props.name}
          placeholder="myemail@company.com"
          value={this.props.value}
          onChange={this.onValueChange}
        />
      </label>
    );
  }
}

InputEmail.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default InputEmail;
