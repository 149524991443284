import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../lib/history.js';
import { openCleaningDetails } from '../../actions/cleaning-actions.js';
import InputSelect from '../forms/input-select.js';
import { dateDayOf, formatDate } from '../../lib/formatters.js';
import { trackInterfaceOpenCleaningDetails } from '../../lib/analytics.js';
import {
  FILTER_CLEANINGS_FUTURE,
  FILTER_CLEANINGS_IN_DAY_OF,
} from '../../strings.js';
import './details-date-select.css';

export class CleaningDetailsDateSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: dateDayOf(
        props.open.start_datetime || this.props.startDate || Date.now()
      ).toJSON(),
      options: this.buildOptions(props.cleanings),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      Object.assign({}, this.state, {
        value: dateDayOf(
          props.open.start_datetime || this.props.startDate || Date.now()
        ).toJSON(),
        options: this.buildOptions(props.cleanings),
      })
    );
  }

  buildOptions(cleanings) {
    const dates = Object.keys(cleanings.reduce(this.reduceToDate, {}));
    const todayValue = dateDayOf(this.props.startDate || Date.now()).toJSON();
    let options = dates.map((k) => {
      return {
        label: formatDate(k),
        value: k,
      };
    });
    if (dates[0] !== todayValue) {
      options.unshift({
        label: formatDate(this.props.startDate || Date.now()),
        value: todayValue,
      });
    }
    return options;
  }

  /**
   * Reduce function for cleanings by day
   *
   * @access public
   * @param {object} acc with days as keys
   * @param {object} val cleaning
   * @returns {object}
   */
  reduceToDate(acc, val) {
    const toTheDay = dateDayOf(val.start_datetime).toJSON();
    (acc[toTheDay] = acc[toTheDay] || []).push(val);
    return acc;
  }

  _onSelect = (date) => {
    const item = this.props.cleanings.filter(
      FILTER_CLEANINGS_IN_DAY_OF(date)
    )[0];
    trackInterfaceOpenCleaningDetails(item || { id: 0 });
    if (item) {
      return this.props.actions.openCleaningDetails(item);
    }
    browserHistory.push('/office-attendant/shift-details');
  };

  render() {
    return (
      <div>
        <span className="cleaning-details-date-select select">
          <InputSelect
            label=""
            hidePrompt={true}
            name="shiftDetailsDateSelect"
            value={this.state.value}
            onChange={this._onSelect}
            options={this.state.options}
          />
        </span>
      </div>
    );
  }
}

CleaningDetailsDateSelect.propTypes = {
  actions: PropTypes.shape({
    openCleaningDetails: PropTypes.func.isRequired,
  }).isRequired,
  open: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date),
  cleanings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_datetime: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function mapStateToProps(state) {
  return {
    open: state.cleanings.open,
    cleanings: state.cleanings.items
      .filter(FILTER_CLEANINGS_FUTURE)
      .sort((a, b) => (a.start_datetime > b.start_datetime ? 1 : -1)),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningDetails,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CleaningDetailsDateSelect);
