import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confirmation from './confirmation.js';
import './confirmations.css';

export class Confirmations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
    };
  }

  renderItem = (item) => {
    return <Confirmation key={item.id} item={item} />;
  };

  render() {
    if (!this.props.items[0]) return null;
    return (
      <div className="confirmations">
        {this.props.items.map(this.renderItem)}
      </div>
    );
  }
}

Confirmations.propTypes = {
  limit: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

function mapStateToProps(state, props) {
  return {
    items: state.confirmations.queue.slice(0, props.limit || 5),
  };
}

function mapDispatchToProps() {
  return {};
}

function areStatesEqual(prev, next) {
  return prev.confirmations.queue === next.confirmations.queue;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(Confirmations);
