import React from 'react';
import ForgotPasswordForm from '../components/forgotpassword.js';
import setTitle from '../decorators/set-title.js';

export class ForgotPasswordRoute extends React.Component {
  render() {
    return <ForgotPasswordForm />;
  }
}

ForgotPasswordRoute = setTitle((props) => {
  return 'Forgot Password';
})(ForgotPasswordRoute);

export default ForgotPasswordRoute;
