import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductSortItem from './product-sort-item.js';
import {
  setProductSort,
  doGetLocationProductQueryResults,
} from '../../actions/product-query-actions.js';
import { SORT_OPTIONS } from '../../strings.js';
import './product-sort.css';

export class ProductSort extends React.PureComponent {
  setActiveSort = (attr) => {
    const defaultDescending = SORT_OPTIONS.filter(
      (option) => option.label === attr
    )[0].defaultDescending;
    this.props.actions.setProductSort({
      attribute: attr,
      descending:
        this.props.attribute === attr
          ? !this.props.descending
          : defaultDescending,
    });
    this.props.actions.doGetLocationProductQueryResults();
  };
  renderItems() {
    return SORT_OPTIONS.map((attr, i) => (
      <ProductSortItem
        key={i}
        label={attr.label}
        activeLabel={this.props.attribute}
        descending={this.props.descending}
        onSelect={this.setActiveSort}
      />
    ));
  }
  render() {
    return (
      <div className="product-sort">
        <strong>Sort by:</strong>
        {this.renderItems()}
      </div>
    );
  }
}

ProductSort.propTypes = {
  actions: PropTypes.shape({
    setProductSort: PropTypes.func.isRequired,
    doGetLocationProductQueryResults: PropTypes.func.isRequired,
  }).isRequired,
  attribute: PropTypes.string.isRequired,
  descending: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    attribute: state.productQuery.sort.attribute,
    descending: state.productQuery.sort.descending,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductSort,
        doGetLocationProductQueryResults,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productQuery.sort.attribute === next.productQuery.sort.attribute &&
    prev.productQuery.sort.descending === next.productQuery.sort.descending
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductSort);
