import React from 'react';
import PropTypes from 'prop-types';

export default class ClickOut extends React.PureComponent {
  componentDidMount() {
    if (this.props.isOpen) {
      window.document.body.addEventListener('click', this.handleBodyClick);
    } else {
      window.document.body.removeEventListener('click', this.handleBodyClick);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen) {
      window.document.body.addEventListener('click', this.handleBodyClick);
    } else {
      window.document.body.removeEventListener('click', this.handleBodyClick);
    }
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('click', this.handleBodyClick);
  }

  handleClickOpen = (isOpen) => {
    if (isOpen) {
      window.document.body.addEventListener('click', this.handleBodyClick);
    } else {
      window.document.body.removeEventListener('click', this.handleBodyClick);
    }

    this.props.onChange(isOpen);
  };

  handleBodyClick = (e) => {
    const depth = 10;
    let iter = 0;
    let node = e.target;

    let regex = new RegExp(this.props.wrappingClassNames.join('|'), 'i');

    while (iter < depth) {
      if (!node || node === document.body) break;
      if (node.className.match && node.className.match(regex)) {
        return;
      }
      node = node.parentNode;
      iter++;
    }

    this.handleClickOpen(!this.props.isOpen);
  };

  render() {
    return null;
  }
}

ClickOut.propTypes = {
  onChange: PropTypes.func.isRequired,
  wrappingClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
};
