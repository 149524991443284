import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import GroupedSpendTable from './vendor-grouped-spend-table.js';
import GroupedSpendPie from './grouped-spend-pie.js';
import { encodeAsUrlHash } from '../../lib/formatters.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './spend-by-vendor-pie-table.css';

const DUMMY_DATA = [
  { id: 0, name: 'Catering Vendor', line_items_submitted_price: 112345 },
  { id: 1, name: 'On-Site Yoga', line_items_submitted_price: 28754 },
  { id: 2, name: 'Motivational Speaker', line_items_submitted_price: 28754 },
  { id: 3, name: 'Off-site Parking Lot', line_items_submitted_price: 33398 },
];

export class SpendByVendorPieTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spendByVendor: this._buildSpend(props.dataViews, props.vendors),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => {
      return {
        spendByVendor: this._buildSpend(props.dataViews, props.vendors),
      };
    });
  }

  handleClickPlaceholder = (e) => {
    e.preventDefault();
    browserHistory.push('/insights/vendor-invoices');
  };

  _buildSpend(dataViews, vendors) {
    const initial = vendors.reduce((acc, val) => {
      acc[val] = {
        name: val,
        id: encodeAsUrlHash(val),
        line_items_submitted_price: 0,
        line_items_submitted_count: 0,
      };
      return acc;
    }, {});
    const sums = dataViews.reduce((dacc, dv) => {
      return dv.data.reduce((acc, val) => {
        if (!val.vendor_name || !acc[val.vendor_name]) return acc;

        acc[val.vendor_name].line_items_submitted_count +=
          +val.customer_purchase_line_items_count;
        acc[val.vendor_name].line_items_submitted_price +=
          +val.customer_purchase_line_items_total_spend;
        return acc;
      }, dacc);
    }, initial);
    return Object.keys(sums)
      .map((k) => {
        return sums[k];
      })
      .filter((s) => !!s.name)
      .sort((a, b) => {
        return a.line_items_submitted_price > b.line_items_submitted_price
          ? -1
          : 1;
      });
  }

  renderEmpty = () => {
    return (
      <div className="spend-by-vendor-pie-table empty hide-when-printing">
        <Header attached="top" as="h2" className="borderless">
          Spend by Vendor
        </Header>
        <Segment attached="bottom" className="borderless">
          <div className="pie-wrapper">
            <GroupedSpendPie
              colorScale="green"
              data={DUMMY_DATA}
              displayTotal={false}
            />
          </div>
          <div className="table-wrapper">
            <Segment placeholder>
              <Header icon>
                <Icon name="file alternate outline" />
                Complete your expense reports in a flash.
              </Header>
              <Button
                icon
                labelPosition="left"
                as="a"
                href="/insights/vendor-invoices"
                onClick={this.handleClickPlaceholder}
                style={{ backgroundColor: this.props.brandColor || '#00a98c' }}
                color="green"
                primary>
                <Icon name="file outline" />
                Upload Invoices
              </Button>
            </Segment>
          </div>
        </Segment>
      </div>
    );
  };

  render() {
    const empty =
      this.props.vendors.length <= 1 && this.props.vendors[0] === 'OfficeLuv';
    if (empty && !this.props.isRequesting) return this.renderEmpty();
    return (
      <div className="spend-by-vendor-pie-table">
        <Header attached="top" as="h3" className="borderless">
          Spend by Vendor
        </Header>
        <Segment
          loading={this.props.isRequesting}
          attached="bottom"
          className="borderless">
          <div className="pie-wrapper">
            <GroupedSpendPie data={this.state.spendByVendor} />
          </div>
          <div className="table-wrapper">
            <GroupedSpendTable data={this.state.spendByVendor} />
          </div>
        </Segment>
      </div>
    );
  }
}

SpendByVendorPieTable.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataViews: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          vendor_name: PropTypes.string,
          location_id: PropTypes.number.isRequired,
          customer_purchase_line_items_total_spend: PropTypes.number,
          customer_purchase_line_items_count: PropTypes.number.isRequired,
          submitted_date: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  isRequesting: PropTypes.bool,
  brandColor: PropTypes.string,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

const DATA_TOPIC_MODEL = 'location';
const DATA_VIEW = 'customer_purchase_vendor_dates';
function reduceByVendorName(acc, val) {
  if (acc.indexOf(val.vendor_name) < 0) {
    acc.push(val.vendor_name);
  }
  return acc;
}
function mapStateToProps(state) {
  const locations = state.dataViews.openLocations.length
    ? state.dataViews.openLocations
    : [state.locations.open];
  const locationIds = locations.map((l) => l.id);
  const startDate = state.dataViews.openRange.startDate;
  const endDate = state.dataViews.openRange.endDate;
  return {
    dataViews: state.dataViews.items.filter(
      (d) =>
        d.view === DATA_VIEW &&
        d.topic.model === DATA_TOPIC_MODEL &&
        d.start === startDate.toJSON() &&
        d.end === endDate.toJSON() &&
        locationIds.indexOf(d.topic.id) > -1
    ),
    isRequesting:
      state.dataViews.requesting.filter(
        (d) =>
          d.view === DATA_VIEW &&
          d.topic.model === DATA_TOPIC_MODEL &&
          d.start === startDate.toJSON() &&
          d.end === endDate.toJSON() &&
          locationIds.indexOf(d.topic.id) > -1
      ).length > 0,
    vendors: state.externalVendorInvoices.items
      .reduce(reduceByVendorName, [])
      .concat(['OfficeLuv']),
    brandColor: state.locations.open.brand_color,
    locations,
    startDate,
    endDate,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.dataViews.openRange === next.dataViews.openRange &&
    prev.dataViews.openLocations === next.dataViews.openLocations &&
    prev.dataViews.items === next.dataViews.items &&
    prev.dataViews.isRequesting === next.dataViews.isRequesting &&
    prev.locations.open === next.locations.open &&
    prev.externalVendorInvoices.items === next.externalVendorInvoices.items
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  SpendByVendorPieTable
);
