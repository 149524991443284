import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../../lib/history.js';
import { Link } from 'react-router-dom';
import { Menu, Accordion, Icon } from 'semantic-ui-react';
import classnames from 'classnames';
import cookies from '../../lib/encrypted-cookies';
import {
  AUTH_COOKIE,
  OFFICE_DETAILS_TOP_LINK,
  OFFICE_DETAILS_LINKS,
  SUPPLY_TOP_LEVEL_LINK,
  CONTACT_TOP_LEVEL_LINK,
  SUPPLY_LINKS_WITH_FAVORITES,
  SUPPLY_LINKS_WITH_LEVELS,
  ATTENDANT_TOP_LEVEL_LINK,
  USER_LINKS,
  BUDGET_LINKS,
  BUDGET_TOP_LEVEL_LINK,
  USER_TOP_LINK,
  TIMELINE_VIEW_COOKIE,
  NAV_SHOW_COOKIE,
} from '../../strings.js';
import { CUSTOMER_ROLE_PROPTYPES } from '../../helpers/customer-role-helpers.js';
import NavLogo from './../../imgs/ol-logo--teal.svg';
import NavbarSideItem from './navbar-side-item.js';
import LimitedCatalogToggle from '../limited-catalog-toggle.js';
import NavLocationSwitch from './nav-location-switch.js';
import NavCoupa from './nav-coupa.js';
import { ENV } from '../../strings.js';
import { formatTitleCase } from '../../lib/formatters.js';
import 'semantic-ui-css/components/accordion.min.css';
import './navbar-side.css';

const COLLAPSED_WIDTH = '60px';
const EXPANDED_WIDTH = '250px';

const TOTANGO_SERVICE_ID = ENV.match(/PROD/) ? 'SP-29236-01' : 'test';

export class NavbarSide extends React.PureComponent {
  state = {
    activeIndex: null,
    activePath: null,
    activeMenu: null,
    expanded: false,
    expandedPushContent: false,
    initialized: false,
  };

  componentDidMount() {
    this.setActivePath(window.location);
    this.pathListener = browserHistory.listen(this.setActivePath);
    if (this.isOnboarding()) return;

    if (this.shouldInitialize()) this.initialize();
    window.totango = {};
    window.totango.track = () => {
      console.log('Totango client not yet initialized');
    };
  }

  componentDidUpdate(prevProps) {
    /*** If the nav items change while nav is expanded,
        re-evaluate the open dropdown ***/
    if (
      this.state.expanded &&
      this.navSchema(prevProps).length !== this.navSchema().length
    ) {
      const activeIndex = this.findActiveIndex(window.location.pathname);
      this.setState({ activeIndex: activeIndex });
    }

    this.evaluateInit();
    this.evaluateDeinit();

    if (prevProps.openLocation.name !== this.props.openLocation.name) {
      this.initTotango();
    }
  }

  initTotango() {
    window.totango_options = {
      service_id: TOTANGO_SERVICE_ID,
      user: {
        id: this.props.user.id,
        name: `${this.props.user.first_name} ${this.props.user.last_name}`,
        email: this.props.user.email,
        role: this.props.role.name,
        location: this.props.openLocation.id,
        customer: this.props.user.customer_id,
        created_at: this.props.user.created_at,
        attendant_services: this.props.attendantServices,
      },
      account: {
        id: this.props.openLocation.id,
        name: this.props.openLocation.name,
      },

      module: 'Login',
    };
    (function () {
      let tracker_name = window.totango_options.tracker_name || 'totango';
      window.totango_tmp_stack = [];
      window[tracker_name] = {
        go: function () {
          return -1;
        },
        setAccountAttributes: function () {},
        identify: function () {},
        track: function (t, o, n, a) {
          window.totango_tmp_stack.push({
            activity: t,
            module: o,
            org: n,
            user: a,
          });
          return -1;
        },
      };
      let e = document.createElement('script');
      e.type = 'text/javascript';
      e.async = true;
      e.src =
        ('https:' === document.location.protocol ? 'https://' : 'http://') +
        'tracker.totango.com/totango3.js';
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(e, s);
    })();
  }

  isOnboarding() {
    return !!window.location.pathname.match(/onboarding/);
  }

  evaluateInit() {
    if (this.state.initialized) return;
    if (this.isOnboarding()) return;
    if (!this.shouldInitialize()) return;

    this.initialize();
  }

  shouldInitialize = () => {
    if (!cookies.get(AUTH_COOKIE)) return false;
    if (!this.props.openLocation || !this.props.openLocation.id) return false;
    return true;
  };

  initialize() {
    let mediaQuery = window.matchMedia('(max-width: 450px)');
    if (!mediaQuery.matches) {
      if (cookies.get(NAV_SHOW_COOKIE)) {
        this.expandPushContent();
      } else {
        this.collapsePushContent();
      }
    }
    this.setState({ initialized: true });
  }

  evaluateDeinit() {
    if (this.isOnboarding() && this.state.initialized) {
      this.deinit();
    }
  }

  deinit() {
    this.mapElements('#page-wrap', (el) => {
      el.style.marginLeft = 0;
    });
    this.setState({ initialized: false });
  }

  setActivePath = (windowLocation) => {
    this.setState({ activePath: windowLocation.pathname });
  };

  findActiveIndex = (pathname) => {
    const navSchema = this.navSchema();

    const indexes = navSchema
      .map((navItem, index) => {
        const { subMenu, topLink } = navItem;
        const filtered = Object.keys(subMenu || [])
          .concat([topLink.path])
          .filter((subMenuPath) => {
            return pathname.match(subMenuPath);
          });
        if (filtered.length) return index;
        return null;
      })
      .filter((x) => x !== null);
    return indexes.length ? indexes[0] : null;
  };

  componentWillUnmount() {
    this.pathListener();
  }

  navSchema(prevProps = null) {
    const {
      supplyLevelsAccess,
      canScheduleOrders,
      userIsStaff,
      attendantServices,
      role,
    } = prevProps || this.props;

    let supplyLinks = supplyLevelsAccess
      ? SUPPLY_LINKS_WITH_LEVELS
      : SUPPLY_LINKS_WITH_FAVORITES;
    if (!canScheduleOrders || !role.can_manage_all_requisitions) {
      supplyLinks = { ...supplyLinks };
      delete supplyLinks['/calendar'];
    }
    if (
      role.can_manage_all_requisitions &&
      canScheduleOrders &&
      cookies.getJSON(TIMELINE_VIEW_COOKIE)
    ) {
      supplyLinks = Object.keys(supplyLinks).reduce((acc, item) => {
        if (item.match('/calendar')) {
          acc['/orders/scheduled'] =
            supplyLinks[item] || supplyLinks['/calendar'];
        } else {
          acc[item] = supplyLinks[item];
        }
        return acc;
      }, {});
    }
    const base = [];
    base.push({
      iconName: 'shopping cart',
      topLink: SUPPLY_TOP_LEVEL_LINK,
      subMenu: supplyLinks,
      customClass: 'marketplace-past-orders',
      key: 'supply',
    });
    if (!userIsStaff && role.can_manage_insights)
      base.push({
        iconName: 'chart bar outline',
        topLink: BUDGET_TOP_LEVEL_LINK,
        subMenu: BUDGET_LINKS,
        customClass: 'intro--budget-insights',
        key: 'budget',
      });
    if (!userIsStaff && attendantServices && role.can_manage_cleanings)
      base.push({
        iconName: 'check square outline',
        topLink: ATTENDANT_TOP_LEVEL_LINK,
        key: 'attendant',
      });

    let settingsTopLink = USER_TOP_LINK;
    let settingsLinks = USER_LINKS;
    let officeLinks = OFFICE_DETAILS_LINKS;
    if (!userIsStaff) {
      officeLinks = Object.keys(OFFICE_DETAILS_LINKS).reduce((acc, item) => {
        if (!OFFICE_DETAILS_LINKS[item].permissions) {
          acc[item] = OFFICE_DETAILS_LINKS[item];
          return acc;
        }
        if (
          OFFICE_DETAILS_LINKS[item].permissions.filter((p) => role[p]).length
        ) {
          acc[item] = OFFICE_DETAILS_LINKS[item];
        }
        return acc;
      }, {});
      settingsTopLink = OFFICE_DETAILS_TOP_LINK;
      settingsLinks = {
        ...officeLinks,
        ...USER_LINKS,
      };
    }
    if (!userIsStaff)
      base.push({
        iconName: 'setting',
        topLink: settingsTopLink,
        subMenu: settingsLinks,
        customClass: 'intro--settings',
        key: 'settings',
      });
    if (userIsStaff)
      base.push({
        iconName: 'setting',
        topLink: { label: 'Settings', path: '/sign-out' },
        subMenu: USER_LINKS,
        customClass: 'intro--settings',
        key: 'settings',
      });
    base.push({
      iconName: 'user',
      topLink: CONTACT_TOP_LEVEL_LINK,
      key: 'contact',
    });
    if (
      role.can_manage_location_catalog &&
      this.props.openLocation.pref_enable_limited_catalog &&
      this.state.activePath &&
      this.state.activePath.match(/catalog|supplies\/levels/)
    ) {
      base.push({
        topLink: {
          path: false,
          label: `Full Catalog: ${
            this.props.inLimitedViewingMode ? 'Off' : 'On'
          }`,
        },
        customIcon: (
          <LimitedCatalogToggle pathname={this.state.activePath || ''} />
        ),
        key: 'limited catalog',
      });
    }
    return base;
  }

  mapElements = (selector, fn) => {
    return Array.prototype.slice
      .call(document.querySelectorAll(selector))
      .map(fn);
  };

  expandSimple = () => {
    this.mapElements('#side-nav', (el) => {
      el.style.width = EXPANDED_WIDTH;
    });
    this.setState(() => ({
      expanded: true,
      activeIndex: this.findActiveIndex(window.location.pathname),
    }));
  };

  collapseSimple = () => {
    this.mapElements('#side-nav', (el) => {
      el.style.width = COLLAPSED_WIDTH;
    });
    this.setState(() => ({
      expanded: false,
      activeIndex: null,
    }));
  };

  expandPushContent = () => {
    this.mapElements('#side-nav', (el) => {
      el.style.width = EXPANDED_WIDTH;
    });
    this.mapElements('#page-wrap', (el) => {
      el.style.marginLeft = EXPANDED_WIDTH;
    });
    this.setState(() => ({
      expanded: true,
      expandedPushContent: true,
      activeIndex: this.findActiveIndex(window.location.pathname),
    }));
    cookies.set(NAV_SHOW_COOKIE, true, { expires: 100 });
  };

  collapsePushContent = () => {
    this.mapElements('#side-nav', (el) => {
      el.style.width = COLLAPSED_WIDTH;
    });
    this.mapElements('#page-wrap', (el) => {
      el.style.marginLeft = COLLAPSED_WIDTH;
    });
    this.setState(() => ({
      expanded: false,
      expandedPushContent: false,
      activeIndex: null,
    }));
    cookies.remove(NAV_SHOW_COOKIE);
  };

  collapseMobile = () => {
    this.mapElements('#side-nav', (el) => {
      el.style.width = COLLAPSED_WIDTH;
    });
    this.mapElements('#page-wrap', (el) => {
      el.style.marginLeft = 0;
    });
    this.setState(() => ({
      expanded: false,
      expandedPushContent: false,
      activeIndex: null,
    }));
  };

  handleClickExpanded = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? null : index;

    this.setState({ activeIndex: newIndex });
  };

  renderMenuItem(navItem, index) {
    const { expanded, activeIndex, activePath } = this.state;
    return (
      <NavbarSideItem
        {...navItem}
        expanded={expanded}
        activeIndex={activeIndex}
        currentIndex={index}
        activePath={activePath}
        onClick={this.handleClickExpanded}
        onMouseEnter={this.expandSimple}
        key={navItem.key}
      />
    );
  }

  renderLogo(opt) {
    if (
      !this.props.openLocation.avatar_attachment ||
      !this.props.openLocation.avatar_attachment.medium
    ) {
      return (
        <img
          src={NavLogo}
          alt="OfficeLuv"
          width={opt.width}
          height={opt.height}
        />
      );
    }
    return (
      <img
        src={this.props.openLocation.avatar_attachment.medium}
        alt={this.props.openLocation.name}
        style={{
          marginLeft: this.state.expanded ? '1rem' : '',
        }}
        width={opt.width}
        height={opt.height}
      />
    );
  }

  render() {
    if (!this.shouldInitialize()) return null;
    if (this.isOnboarding()) return null;

    const { expanded, expandedPushContent } = this.state;
    return (
      <div className="navbar-side__wrap-all">
        <div
          className={classnames('navbar-mobile__background', { expanded })}
          onClick={this.collapseMobile}></div>
        <div className="navbar-mobile">
          <button onClick={this.expandSimple}>
            <Icon name="sidebar" />
          </button>
          {this.renderLogo({ width: 30, height: 30 })}
        </div>

        <nav
          className={classnames('navbar-side desktop hide-when-printing', {
            expanded,
            expandedPushContent,
          })}
          id="side-nav"
          onMouseLeave={
            expanded && !expandedPushContent ? this.collapseSimple : () => {}
          }>
          <div className="navbar-side__top">
            <div className="navbar-side__header">
              <Link to="/" className="navbar-side__logo">
                {this.renderLogo({ width: 60, height: 60 })}
              </Link>
              <div
                className={classnames(
                  'navbar-side__greeting navbar-side__expandable',
                  {
                    expanded: expanded,
                  }
                )}>
                {/* <button className="desktop" onClick={this.collapsePushContent}>
                  <Icon name="angle left" title="minimize" />
                </button> */}
                <button className="mobile" onClick={this.collapseMobile}>
                  <Icon name="angle left" />
                </button>
                <div className="hello">
                  Hello,{' '}
                  <span>
                    {formatTitleCase(
                      this.props.user.first_name || this.props.user.name
                    )}
                  </span>
                </div>
              </div>
            </div>
            <NavCoupa expanded={expanded} />
            <NavLocationSwitch
              expanded={expanded}
              onMouseEnter={this.expandSimple}
            />
            <Menu
              borderless
              inverted
              className="navbar-side__menu"
              id="navbar-side-menu">
              <Accordion inverted className="navbar-side__accordion">
                {this.navSchema().map((item, i) =>
                  this.renderMenuItem(item, i)
                )}
              </Accordion>
            </Menu>
          </div>
          <div className="navbar-side__bottom">
            <button
              className="desktop"
              onClick={
                expanded ? this.collapsePushContent : this.expandPushContent
              }
              title={expanded ? 'minimize' : 'expand'}>
              <Icon
                name={expanded ? 'angle double left' : 'angle double right'}
                className="navbar-side__toggle"
              />
            </button>

            {expanded && !expandedPushContent && (
              <button
                className="navbar-side__lock desktop"
                onClick={this.expandPushContent}>
                Keep&nbsp;Open
              </button>
            )}
          </div>
        </nav>
      </div>
    );
  }
}

NavbarSide.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
  }),
  userIsStaff: PropTypes.bool,
  attendantServices: PropTypes.bool,
  supplyLevelsAccess: PropTypes.bool,
  canScheduleOrders: PropTypes.bool,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  inLimitedViewingMode: PropTypes.bool,
  didOnboard: PropTypes.bool,
  role: CUSTOMER_ROLE_PROPTYPES,
};

export function mapStateToProps(state) {
  return {
    user: state.auth.record,
    userIsStaff: state.auth.actor.type === 'Staff',
    attendantServices:
      (state.locations.open.services || '').match(/attendant/) !== null,
    canScheduleOrders: state.locations.open.pref_enable_scheduled_requisitions,
    supplyLevelsAccess:
      state.locations.open.pref_enable_location_list_stock_levels,
    openLocation: state.locations.open,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    role: state.auth.role,
    didOnboard: state.onboarding.initialized,
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.onboarding.initialized === next.onboarding.initialized &&
    prev.auth === next.auth
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  NavbarSide
);
