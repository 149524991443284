import React from 'react';
import { Menu } from 'semantic-ui-react';
import ProductCategoryFilter from './product-category-filter.js';
import ProductBrandFilter from './product-brand-filter.js';
import ProductVendorMinimumFilter from './product-vendor-minimum-filter.js';
import ProductShippingFilter from './product-shipping-filter.js';
import ProductStockStatusFilter from './product-stock-status-filter.js';
import ProductRestrictedCatalogFilter from './product-restricted-catalog-filter.js';
import 'semantic-ui-css/components/menu.min.css';
import './product-filters.css';

export default class ProductFilters extends React.PureComponent {
  render() {
    return (
      <Menu className="product-filters" stackable>
        <Menu.Item>
          <ProductCategoryFilter />
        </Menu.Item>
        <Menu.Item>
          <ProductBrandFilter />
        </Menu.Item>
        <Menu.Item>
          <ProductShippingFilter />
        </Menu.Item>
        <Menu.Item>
          <ProductVendorMinimumFilter />
        </Menu.Item>
        <Menu.Item>
          <ProductStockStatusFilter />
        </Menu.Item>
        <Menu.Item>
          <ProductRestrictedCatalogFilter />
        </Menu.Item>
      </Menu>
    );
  }
}
