import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history.js';
import { bindActionCreators } from 'redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import CleaningReportsList from '../components/cleanings/reports-list.js';
import CleaningReport from '../components/cleanings/report.js';
import Loader from '../components/loader.js';
import {
  openCleaningReport,
  closeCleaning,
} from '../actions/cleaning-actions.js';
import setTitle from '../decorators/set-title.js';
import './office-attendant-cleaning-report.css';

export class OfficeAttendantCleaningReportRoute extends React.PureComponent {
  componentDidMount() {
    this.props.actions.openCleaningReport({
      id: parseInt(this.props.match.params.id, 10),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions.openCleaningReport({
        id: parseInt(this.props.match.params.id, 10),
      });
    }
    if (!this.props.isRequesting && !this.props.cleaning.start_datetime) {
      return browserHistory.push('/office-attendant/shift-reports');
    }
  }

  componentWillUnmount() {
    this.props.actions.closeCleaning();
  }

  render() {
    return (
      <div className="">
        <AuthCheck permissions={{ can_manage_cleanings: true }} />
        <NavBarAttendant />
        <div className="variable-width-column office-attendant-cleaning-report">
          <CleaningReportsList />
          {this.props.cleaning &&
            !!this.props.cleaning.id &&
            !!this.props.cleaning.start_datetime && <CleaningReport />}
          {this.props.isRequesting &&
            (!this.props.cleaning || !this.props.cleaning.start_datetime) && (
              <Loader />
            )}
        </div>
      </div>
    );
  }
}

OfficeAttendantCleaningReportRoute = setTitle((props) => {
  return 'Office Attendant Shift Report #' + props.match.params.id;
})(OfficeAttendantCleaningReportRoute);

OfficeAttendantCleaningReportRoute.propTypes = {
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_datetime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }),
  isRequesting: PropTypes.bool,
};

export function mapStateToProps(state) {
  return {
    cleaning: state.cleanings.open,
    isRequesting: state.cleanings.isRequesting,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningReport,
        closeCleaning,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfficeAttendantCleaningReportRoute);
