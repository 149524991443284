import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import 'semantic-ui-css/components/list.min.css';
import './calendar-agenda-item.css';

export class CalendarAgendaItem extends React.PureComponent {
  renderIcon() {
    if (this.props.item.state === 'cancelled') {
      return (
        <Icon
          name="cancel"
          size="small"
          className="calendar-agenda-item__icon"
          style={helpers.agendaItemStyle(this.props.item)}
        />
      );
    } else if (this.props.item.state !== 'actionNeeded') {
      return (
        <div
          className="calendar-agenda-item__icon"
          style={helpers.agendaItemStyle(this.props.item)}></div>
      );
    }
  }

  render() {
    return (
      <div className="calendar-agenda-item">
        <div className="calendar-agenda-item__left">
          {this.renderIcon()}
          <div>
            {this.props.item.title}
            <span className="calendar-agenda-item__subtitle">
              {this.props.item.subtitle}
            </span>
          </div>
        </div>
        {this.props.item.link && (
          <div className="calendar-agenda-item__right">
            <Button size="tiny" secondary>
              <Link to={this.props.item.link.path}>
                {this.props.item.link.text}
              </Link>
            </Button>
          </div>
        )}
      </div>
    );
  }
}

CalendarAgendaItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.object,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    state: PropTypes.oneOf([
      'actionNeeded',
      'pending',
      'confirmed',
      'cancelled',
    ]),
    link: PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default CalendarAgendaItem;
