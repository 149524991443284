import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox } from 'semantic-ui-react';
import { setProductQuery } from '../../actions/product-query-actions.js';

export class ProductRestrictedCatalogFilter extends React.PureComponent {
  onCheckboxChange = (_, data) => {
    this.props.actions.setProductQuery({
      filters: {
        restrictedCatalog: data.checked,
      },
    });
  };

  render() {
    if (this.props.inLimitedViewingMode) return null;
    if (!this.props.canManageLocationCatalog) return null;

    return (
      <div className="product-filter">
        <Checkbox
          label="Restricted catalog"
          checked={this.props.restrictedCatalog}
          onChange={this.onCheckboxChange}
        />
      </div>
    );
  }
}

ProductRestrictedCatalogFilter.propTypes = {
  actions: PropTypes.shape({
    setProductQuery: PropTypes.func.isRequired,
  }),
  restrictedCatalog: PropTypes.bool.isRequired,
  inLimitedViewingMode: PropTypes.bool.isRequired,
  canManageLocationCatalog: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    restrictedCatalog: state.productQuery.filters.restrictedCatalog,
    inLimitedViewingMode: state.application.inLimitedViewingMode,
    canManageLocationCatalog: state.auth.role.can_manage_location_catalog,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setProductQuery,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.productQuery.filters.restrictedCatalog ===
      next.productQuery.filters.restrictedCatalog &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ProductRestrictedCatalogFilter);
