import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from './input-label.js';
import './input.css';

class InputText extends React.PureComponent {
  onValueChange = (e) => {
    const value = this.refs.input ? this.refs.input.value : e.target.value;
    this.props.onChange(value);
  };

  render() {
    return (
      <InputLabel
        text={this.props.label}
        required={this.props.required}
        inputValue={this.props.value}
      >
        <input
          ref="input"
          type={this.props.type || 'text'}
          name={this.props.name}
          placeholder={this.props.placeholder || ''}
          value={this.props.value}
          onBlur={this.props.onBlur}
          className={this.props.className}
          onFocus={this.props.onFocus}
          required={this.props.required}
          autoFocus={this.props.autoFocus}
          onChange={this.onValueChange}
          disabled={this.props.disabled}
        />
      </InputLabel>
    );
  }
}

InputText.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputText;
