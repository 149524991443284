import Push from 'push.js';
import {
  trackNotificationSent,
  trackNotificationClosed,
  trackNotificationPermissionDenied,
  trackNotificationPermissionGranted,
} from '../lib/analytics.js';
const TIMEOUT = 5000;

// silence notifications for first 10seconds, to allow for updates
let catchUp = true;
setTimeout(() => {
  catchUp = false;
}, 10 * 1000);

// options: { link, body, tag }
export const notify = (title, opt) => {
  if (catchUp || !Push.Permission.has()) return;
  opt = opt || {};
  Push.create(
    title,
    Object.assign(opt, {
      icon: '/ologo.png',
      timeout: TIMEOUT,
    })
  );
  trackNotificationSent({ title });
};

export const close = (tag) => {
  Push.close(tag);
  trackNotificationClosed({ tag });
};

export const askPermission = (opt) => {
  if (Push.Permission.has()) return;
  opt = opt || {};
  let onGrant = opt.onGrant || function () {};
  let onDeny = opt.onDeny || function () {};
  setTimeout(() => {
    Push.Permission.request(
      function () {
        onGrant();
        trackNotificationPermissionGranted();
      },
      function () {
        onDeny();
        trackNotificationPermissionDenied();
      }
    );
  }, opt.timeout || 0);
};

export const Pushjs = Push;
export default notify;
