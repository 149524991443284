import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Segment, Table } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import OrdersProductRow from './orders-product-row.js';
import ItemDetailsTotals from './item-details-totals.js';
import { openDataViewRange } from '../../actions/data-view-actions.js';
import { submitProductRequisition } from '../../actions/requisition-actions.js';
import { closeFeatureTour } from '../../ducks/feature-tours.js';
import * as requisitionHelpers from '../../helpers/requisition-helpers';
import { PRODUCT_IS_IN_STOCK } from '../../flags.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/table.min.css';
import './item-details.css';
function sorter(sortColumn, sortDirection) {
  if (sortDirection === 'descending') return descendingSorter(sortColumn);
  return ascendingSorter(sortColumn);
}
function ascendingSorter(sortColumn) {
  return function (a, b) {
    switch (sortColumn) {
      case 'item':
        return (a.product || {}).name < (b.product || {}).name ? -1 : 1;
      case 'quantity':
        return a.quantity < b.quantity ? -1 : 1;
      case 'price':
        return a.price < b.price ? -1 : 1;
      case 'budget':
      default:
        return (a.customer_budget_code || {}).name <
          (b.customer_budget_code || {}).name
          ? -1
          : 1;
    }
  };
}
function descendingSorter(sortColumn) {
  return function (a, b) {
    switch (sortColumn) {
      case 'item':
        return (a.product || {}).name > (b.product || {}).name ? -1 : 1;
      case 'quantity':
        return a.quantity > b.quantity ? -1 : 1;
      case 'price':
        return a.price > b.price ? -1 : 1;
      case 'budget':
      default:
        return (a.customer_budget_code || {}).name >
          (b.customer_budget_code || {}).name
          ? -1
          : 1;
    }
  };
}

export class RequisitionItemDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.buildState(props, {
      sortColumn: 'budget',
      sortDirection: 'ascending',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, this.buildState(nextProps, this.state))
    );
  }

  buildState = (props, newState) => {
    if (!props.item.product_requisitions) {
      return { ...newState, sorted_product_requisitions: [] };
    }
    return {
      ...newState,
      sorted_product_requisitions: props.item.product_requisitions.sort(
        sorter(newState.sortColumn, newState.sortDirection)
      ),
    };
  };

  redirectToBudgetCode = (budgetCodeId) => {
    let startDate = moment(this.props.item.submitted_at).startOf('month');
    let endDate = moment(startDate).endOf('month');
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
    browserHistory.push(`/insights/budget-codes/${budgetCodeId}`);
  };

  redirectToInsights = () => {
    let startDate = moment(this.props.item.submitted_at).startOf('month');
    let endDate = moment(startDate).endOf('month');
    this.props.actions.openDataViewRange({
      startDate,
      endDate,
    });
    this.props.actions.closeFeatureTour();
    browserHistory.push('/insights/budgets');
  };

  findShipment = (productReq) => {
    const collect = [];
    this.props.shipments
      .filter((s) => {
        return this.props.item.shipmentIds.filter((sId) => sId === s.id).length;
      })
      .map((shipment) => {
        const found_ppo = shipment.product_purchase_orders.filter(
          (ppo) => ppo.product_requisition_id === productReq.id
        );
        if (found_ppo.length) {
          collect.push(shipment);
        }
        return shipment;
      });
    return collect[0];
  };

  handleSort = (col) => {
    if (col !== this.state.sortColumn) {
      return this.setState(
        this.buildState(this.props, {
          sortColumn: col,
          sortDirection: 'ascending',
        })
      );
    }
    this.setState(
      this.buildState(this.props, {
        sortColumn: this.state.sortColumn,
        sortDirection:
          this.state.sortDirection === 'ascending' ? 'descending' : 'ascending',
      })
    );
  };

  addToCart = () => {
    this.props.itemsNotInCart.map((pr) => {
      this.props.actions.submitProductRequisition({
        quantity: pr.quantity,
        price: pr.product.price,
        product_id: pr.product.id,
        requisition_id: this.props.cart.id,
      });
      return null;
    });
  };

  renderCartButtonText() {
    if (this.props.itemsNotInCart.length === 0) {
      return 'All Items in Cart';
    }
    if (this.props.cartAddPending) {
      return 'Adding...';
    }
    return 'Copy All Items to Cart';
  }

  renderItem = (item, i) => {
    return (
      <OrdersProductRow
        item={item}
        key={item.id || i}
        shipment={this.findShipment(item)}
        showShippingDetails={true}
        showInstructions={true}
      />
    );
  };

  renderGroupedData = () => {
    const column = this.state.sortColumn;
    const direction = this.state.sortDirection;
    return (
      <Table basic sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              colSpan="2"
              sorted={column === 'item' ? direction : null}
              onClick={this.handleSort.bind(this, 'item')}
            >
              Item
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'budget' ? direction : null}
              onClick={this.handleSort.bind(this, 'budget')}
            >
              Category
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              colSpan="2"
              sorted={column === 'quantity' ? direction : null}
              onClick={this.handleSort.bind(this, 'quantity')}
            >
              Qty
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'price' ? direction : null}
              onClick={this.handleSort.bind(this, 'price')}
            >
              Price
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.sorted_product_requisitions.map(this.renderItem)}
        </Table.Body>
      </Table>
    );
  };

  render() {
    return (
      <Segment attached="top" style={{ marginBottom: '2rem' }}>
        <div className="requisition-item-actions">
          <h3 className="section-header show-when-printing">Ordered Items</h3>
          <Table
            compact
            basic="very"
            className="hide-when-printing"
            style={{ margin: '0' }}
          >
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Button
                    secondary
                    size="mini"
                    className="hide-when-printing"
                    onClick={this.addToCart}
                    disabled={
                      this.props.itemsNotInCart.length === 0 ||
                      this.props.cartAddPending
                    }
                  >
                    {this.renderCartButtonText()}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {this.props.canAccessBudgetCodes && (
                    <Link
                      to={`/supplies/orders/${this.props.item.id}/budget-codes`}
                      className="hide-when-printing primary"
                    >
                      Assign Categories
                    </Link>
                  )}
                </Table.Cell>
                <div className="intro--budget-insights-view-link-in-app">
                  <Table.Cell textAlign="right">
                    {this.props.canAccessInsights && (
                      <Button
                        size="tiny"
                        className="link-to-budget-code hide-when-printing tertiary"
                        onClick={this.redirectToInsights}
                      >
                        View Insights &raquo;
                      </Button>
                    )}
                  </Table.Cell>
                </div>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className="requisition-item-products">
          {this.renderGroupedData()}
          <ItemDetailsTotals item={this.props.item} />
        </div>
      </Segment>
    );
  }
}

RequisitionItemDetails.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    submitted_at: PropTypes.string.isRequired,
    total_price: PropTypes.number.isRequired,
    shipmentIds: PropTypes.arrayOf(PropTypes.number),
    product_requisitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        customer_budget_code: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
  itemsNotInCart: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  cartAddPending: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    openDataViewRange: PropTypes.func.isRequired,
    submitProductRequisition: PropTypes.func.isRequired,
    closeFeatureTour: PropTypes.func.isRequired,
  }).isRequired,
  canAccessBudgetCodes: PropTypes.bool,
  canAccessInsights: PropTypes.bool,
};

function inStockItems(pr) {
  return PRODUCT_IS_IN_STOCK(pr.product);
}

function mapStateToProps(state, props) {
  const cart = state.carts.open;
  const itemsNotInCart = requisitionHelpers
    .itemsNotInBoth(cart.product_requisitions, props.item.product_requisitions)
    .filter(inStockItems);
  const shipmentIds = props.item.shipmentIds || [];
  const shipments = shipmentIds
    .map((sId) => state.shipments.items[sId])
    .filter((item) => item !== undefined)
    .sort((a, b) => (a.expected_at_end > b.expected_at_end ? -1 : 1));
  return {
    itemsNotInCart,
    shipments,
    cart,
    cartAddPending: state.requisitions.creating.length > 0,
    openLocation: state.locations.open,
    canAccessBudgetCodes:
      state.locations.open.pref_enable_custom_budget_codes &&
      state.auth.role.can_manage_insights,
    canAccessInsights: state.auth.role.can_manage_insights,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openDataViewRange,
        submitProductRequisition,
        closeFeatureTour,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.requisitions.open === next.requisitions.open &&
    prev.shipments.items === next.shipments.items &&
    prev.requisitions.creating === next.requisitions.creating &&
    prev.locations.open === next.locations.open &&
    prev.carts.open === next.carts.open &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(RequisitionItemDetails);
