import React from 'react';
import PropTypes from 'prop-types';
import TextWithAnchors from '../../text/text-with-anchors.js';
import { formatDate, formatDateTime } from '../../../lib/formatters.js';
import star from '../../../imgs/star-full.svg';
import './item.css';

export default class CleaningReviewItem extends React.PureComponent {
  renderStars = () => {
    let elements = [];
    let i = 0;
    while (i < this.props.item.stars) {
      elements.push(this.renderStar(i));
      i++;
    }
    return elements;
  };

  renderStar(iter) {
    return (
      <div key={iter} className="cleaning-review-star">
        <img
          className="cleaning-review-star-img"
          alt="Review star"
          width="24"
          height="24"
          src={star}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="cleaning-review">
        <div className="cleaning-review-stars">{this.renderStars()}</div>
        {this.props.item.comment && (
          <div className="cleaning-review-comment">
            &ldquo;
            <TextWithAnchors text={this.props.item.comment} />
            &rdquo;
          </div>
        )}
        <div
          className="cleaning-review-time"
          title={formatDateTime(this.props.item.updated_at)}>
          Rated {formatDate(this.props.item.updated_at)}
        </div>
      </div>
    );
  }
}

CleaningReviewItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    comment: PropTypes.string,
    employee_id: PropTypes.number,
    customer_id: PropTypes.number,
    stars: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};
