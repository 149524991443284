import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Menu, Accordion, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import 'semantic-ui-css/components/accordion.min.css';
import './navbar-side.css';

const NavbarSideItem = (props) => {
  const {
    expanded,
    activePath,
    activeIndex,
    currentIndex,
    iconName,
    topLink,
    subMenu,
    customClass,
    initials,
    customIcon,
    customId,
  } = props;

  const renderSubMenu = (link, data, i) => {
    let pageIsActive;
    if (activePath && link) {
      pageIsActive = activePath && link && activePath.match(link);
      if (activePath.match(/calendar/) && link.match(/orders\/scheduled/)) {
        pageIsActive = true;
      }
    }
    return (
      <Menu.Item
        as={Link}
        to={link}
        key={i}
        className={classnames('navbar-side__expandable', {
          'navbar-side__current-page': pageIsActive,
          expanded: expanded,
        })}
      >
        {data.label}
      </Menu.Item>
    );
  };

  const findActiveDirectory = (pathList) => {
    return !!pathList.filter((path) => {
      if (activePath) {
        if (activePath.match(/calendar/) && path.match(/orders\/scheduled/))
          return true;
        return activePath.match(path);
      }
      return false;
    })[0];
  };

  const classNameOptions = () => {
    let base = {
      'navbar-side__menu-item--expanded': expanded,
      'navbar-side__menu-item--collapsed': !expanded,
      'navbar-side__menu-item--current-page': activePath === topLink.path,
      'navbar-side__menu-item--current-section': findActiveDirectory(
        Object.keys(subMenu || []).concat([topLink.path])
      ),
    };
    if (customClass) base[customClass] = true;
    return base;
  };

  return (
    <div
      className={classnames('navbar-side__menu-item', classNameOptions())}
      id={customId || ''}
    >
      <Accordion.Title
        active={activeIndex === currentIndex}
        index={currentIndex}
        onClick={
          expanded && Object.keys(subMenu).length > 0 ? props.onClick : () => {}
        }
      >
        {topLink.path && (
          <Link
            to={topLink.path}
            className="navbar-side__menu-icon"
            onMouseEnter={props.onMouseEnter}
          >
            {iconName && <Icon name={iconName} />}
            {initials && (
              <span className="navbar-side__initials-wrap">
                <span className="navbar-side__initials">{initials}</span>
              </span>
            )}
            {customIcon}
          </Link>
        )}
        {!topLink.path && (
          <span
            className="navbar-side__menu-icon"
            onMouseEnter={props.onMouseEnter}
          >
            {iconName && <Icon name={iconName} />}
            {initials && (
              <span className="navbar-side__initials-wrap">
                <span className="navbar-side__initials">{initials}</span>
              </span>
            )}
            {customIcon}
          </span>
        )}
        <span className="navbar-side__menu-item-title">
          {Object.keys(subMenu).length === 0 && (
            <Link to={topLink.path}>{topLink.label}</Link>
          )}
          {Object.keys(subMenu).length > 0 && topLink.label}
          {Object.keys(subMenu).length > 0 && (
            <Icon
              className="navbar-side__caret"
              name={activeIndex === currentIndex ? 'caret down' : 'caret left'}
            />
          )}
        </span>
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex === currentIndex}
        className="navbar-side__submenu"
      >
        {subMenu &&
          Object.keys(subMenu).map((link, i) =>
            renderSubMenu(link, props.subMenu[link], i)
          )}
      </Accordion.Content>
    </div>
  );
};

NavbarSideItem.propTypes = {
  expanded: PropTypes.bool,
  activePath: PropTypes.string,
  activeIndex: PropTypes.number,
  index: PropTypes.number,
  iconName: PropTypes.string,
  initials: PropTypes.string,
  topLink: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  subMenu: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  customId: PropTypes.string,
  customIcon: PropTypes.object,
};

NavbarSideItem.defaultProps = {
  subMenu: {},
  activeIndex: null,
  customIcon: null,
};

export default NavbarSideItem;
