import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'semantic-ui-react';
import 'intersection-observer';
import IntersectionVisible from 'react-intersection-visible';
import { formatProductImageURL } from '../../lib/formatters.js';
import 'semantic-ui-css/components/placeholder.min.css';
import './item-image.css';

export default class ItemImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  renderHidden() {
    const item = this.props.item || { processed: {} };
    return (
      <IntersectionVisible onShow={() => this.setState({ visible: true })}>
        <div className="product-item-image">
          <Placeholder>
            <Placeholder.Image
              square
              style={{
                width: this.props.width || 144,
                height: this.props.height || 144,
              }}
              title={this.props.title || item.file_file_name}
              alt={this.props.alt || item.file_file_name}
            />
          </Placeholder>
        </div>
      </IntersectionVisible>
    );
  }

  render() {
    if (!this.state.visible) return this.renderHidden();
    const item = this.props.item || { processed: {} };
    const size = this.props.size || 'medium';
    return (
      <div className="product-item-image">
        <img
          src={
            this.props.product
              ? formatProductImageURL(this.props.product, size)
              : item.processed[size]
          }
          height={this.props.height || 144}
          width={this.props.width || 144}
          title={this.props.title || item.file_file_name}
          alt={this.props.alt || item.file_file_name}
        />
      </div>
    );
  }
}

ItemImage.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.string,
  alt: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    file_file_name: PropTypes.string.isRequired,
    processed: PropTypes.shape({
      medium: PropTypes.string,
      original: PropTypes.string,
      thumb: PropTypes.string,
    }).isRequired,
  }),
};
