import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import InputQuantity from '../forms/input-quantity.js';
import InputSelect from '../forms/input-select.js';
import ModalProductRecurringReqList from './modal-product-recurring-req-list.js';
import * as helpers from '../../helpers/scheduled-requisition-helpers.js';
import { submitUpdateScheduledRequisition } from '../../actions/scheduled-requisition-actions.js';
import { updateStagedScheduledProductRequisition } from '../../actions/scheduled-product-requisition-actions.js';
import { closeProductModal } from '../../actions/product-actions.js';
import './modal-product-recurring-req-form.css';

export class ModalProductRecurringReqForm extends React.PureComponent {
  constructor(props) {
    super(props);
    let spr = this.openScheduledProductRequisition();
    this.state = {
      quantity: spr ? spr.quantity : 0,
      scheduled_requisition_id: this.isCreatingScheduledOrder()
        ? 'new'
        : props.openScheduledRequisition.id,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let id = nextProps.openScheduledRequisition.id;
    if (this.isCreatingScheduledOrder()) id = 'new';
    this.setState((prevState) => ({
      scheduled_requisition_id: id || prevState.scheduled_requisition_id,
    }));
  }

  buildOptions = () => {
    if (this.isCreatingScheduledOrder()) {
      return [
        {
          label: '-- New Recurring Order --',
          value: 'new',
        },
      ];
    }
    if (this.props.openScheduledRequisition.id) {
      const sr = this.props.openScheduledRequisition;
      return [
        {
          label: `${sr.name}, ${helpers.humanInterval(sr)}`,
          value: sr.id,
        },
      ];
    }
    return (this.props.nextSourceOccurrences || []).map((sr) => {
      return {
        label: `${sr.name}, ${helpers.humanInterval(sr)}`,
        value: sr.id,
      };
    });
  };

  inScheduledRequisitions = () => {
    return this.props.nextSourceOccurrences.filter((sr) => {
      return (
        sr.scheduled_product_requisitions.filter(
          (spr) => spr.product_id === this.props.product.id
        ).length > 0
      );
    });
  };

  outScheduledRequisitions = () => {
    return this.props.nextSourceOccurrences.filter((sr) => {
      return (
        sr.scheduled_product_requisitions.filter(
          (spr) => spr.product_id === this.props.product.id
        ).length < 1
      );
    });
  };

  isInScheduledRequisition = () => {
    let sr;
    if (this.state.scheduled_requisition_id === 'new') {
      sr = this.props.stagedScheduledRequisition;
    } else if (this.props.isEditingScheduledOrder) {
      sr = this.props.stagedScheduledRequisition;
    } else {
      sr = this.selectedScheduledReq();
    }
    if (!sr) return false;

    return !!sr.scheduled_product_requisitions.filter(
      (spr) => spr.product_id === this.props.product.id
    ).length;
  };

  selectedScheduledReq = () => {
    return this.props.nextSourceOccurrences.filter(
      (sr) => sr.id === this.state.scheduled_requisition_id
    )[0];
  };

  openScheduledProductRequisition = () => {
    return (
      this.props.openScheduledRequisition.scheduled_product_requisitions || []
    ).filter((spr) => {
      return spr.product_id === this.props.product.id;
    })[0];
  };

  handleChangeQuantity = (qty) => {
    this.setState(() => {
      return {
        quantity: Math.max(qty, 0),
      };
    });
  };

  handleChangeReq = (scheduled_requisition_id) => {
    if (this.props.openScheduledRequisition.id) {
      return;
    }
    if (scheduled_requisition_id === 'new') {
      this.setState(() => ({
        scheduled_requisition_id: 'new',
      }));
      return;
    }
    this.setState(() => ({
      scheduled_requisition_id: parseInt(scheduled_requisition_id, 10),
    }));
  };

  isCreatingScheduledOrder = () => {
    return !!window.location.pathname.match('orders/scheduled/new');
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;

    if (
      this.props.isEditingScheduledOrder ||
      this.state.scheduled_requisition_id === 'new'
    ) {
      return this.props.actions.updateStagedScheduledProductRequisition({
        product_id: this.props.product.id,
        price: this.props.product.price,
        product: this.props.product,
        quantity: this.state.quantity,
      });
    }

    let sreq;
    let sprs;
    if (this.props.openScheduledRequisition.id) {
      sreq = this.props.openScheduledRequisition;
    } else {
      sreq = this.selectedScheduledReq();
    }

    if (this.isInScheduledRequisition()) {
      sprs = sreq.scheduled_product_requisitions.map((spr) => {
        if (spr.product_id === this.props.product.id) {
          return {
            ...spr,
            quantity: this.state.quantity,
          };
        } else {
          return spr;
        }
      });
    } else {
      sprs = sreq.scheduled_product_requisitions.concat({
        product_id: this.props.product.id,
        scheduled_requisition_id: sreq.id,
        quantity: this.state.quantity,
      });
    }
    this.props.actions.submitUpdateScheduledRequisition({
      id: this.state.scheduled_requisition_id,
      scheduled_product_requisitions_attributes: helpers.constructSPRAttributes(
        sprs,
        '1'
      ),
    });
  };

  handleClickNew = () => {
    this.props.actions.closeProductModal();
  };

  isDisabled = () => {
    if (!this.props.role.can_manage_all_requisitions) return true;
    return (
      !(
        this.state.scheduled_requisition_id ||
        this.props.openScheduledRequisition.id
      ) ||
      !this.state.quantity ||
      this.isInScheduledRequisition()
    );
  };

  formatButtonText = () => {
    if (this.isInScheduledRequisition()) {
      return 'Already In Order';
    }
    return 'Add to Order';
  };

  formatHeaderText = () => {
    return 'Add to Recurring Order';
  };

  render() {
    const scheduledReqsIn = this.inScheduledRequisitions();
    return (
      <div className="product-scheduled-action">
        {scheduledReqsIn.length > 0 && (
          <ModalProductRecurringReqList
            product={this.props.product}
            scheduled_requisitions={scheduledReqsIn}
          />
        )}
        <div className="modal-product-recurring-req-form">
          <h5 className="ui header">{this.formatHeaderText()}</h5>
          <form className="flex-wrapper input-row" onSubmit={this.handleSubmit}>
            <div className="recurring-req-select">
              <InputSelect
                label=""
                disabled={!this.props.role.can_manage_all_requisitions}
                onChange={this.handleChangeReq}
                value={this.state.scheduled_requisition_id}
                options={this.buildOptions()}
              />
            </div>
            <div className="recurring-req-quantity">
              <InputQuantity
                label="Quantity"
                readOnly={!this.props.role.can_manage_all_requisitions}
                value={this.state.quantity}
                min={0}
                onChange={this.handleChangeQuantity}
              />
            </div>
            <div className="recurring-req-submit">
              <Button
                primary
                size="small"
                disabled={this.isDisabled()}
                className="submit"
              >
                {this.formatButtonText()}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ModalProductRecurringReqForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    closeProductModal: PropTypes.func.isRequired,
    submitUpdateScheduledRequisition: PropTypes.func.isRequired,
  }).isRequired,
  openScheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scheduled_requisition_id: PropTypes.number,
  }).isRequired,
  nextSourceOccurrences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      rrule: PropTypes.string.isRequired,
      scheduled_product_requisitions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
          product_id: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  role: PropTypes.shape({
    can_manage_all_requisitions: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  const stagedScheduledRequisition = state.scheduledRequisitions.staged[0];
  const openScheduledRequisition = state.scheduledRequisitions.open;
  return {
    nextSourceOccurrences: helpers.nextSourceOccurrences(
      state.scheduledRequisitions.items
    ),
    openScheduledRequisition,
    stagedScheduledRequisition,
    isEditingScheduledOrder:
      openScheduledRequisition.id > 0 &&
      stagedScheduledRequisition.id === openScheduledRequisition.id,
    role: state.auth.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateScheduledRequisition,
        closeProductModal,
        updateStagedScheduledProductRequisition,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.scheduledRequisitions.items === next.scheduledRequisitions.items &&
    prev.scheduledRequisitions.open === next.scheduledRequisitions.open &&
    prev.scheduledRequisitions.staged === next.scheduledRequisitions.staged &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductRecurringReqForm);
