import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table } from 'semantic-ui-react';
import { formatDateYear, formatCentAmount } from '../../lib/formatters.js';
import { trackInterfaceDownloadInvoice } from '../../lib/analytics.js';
import { doOpenInvoice } from '../../actions/invoices-actions.js';

export class InvoiceItemRow extends React.PureComponent {
  formatState(state) {
    switch (state) {
      case 'past_due':
      case 'failed':
        return 'Overdue';
      case 'collected':
        return 'Collected';
      default:
        return '';
    }
  }

  _onViewClick = () => {
    this.props.actions.doOpenInvoice(this.props.item);
  };

  _onSaveClick = () => {
    trackInterfaceDownloadInvoice(this.props.item);
  };

  getInvoiceNumber = () => {
    return (
      this.props.item.invoice_number ||
      this.props.item.recurly_id ||
      this.props.item.quickbooks_id ||
      this.props.item.stripe_id
    );
  };

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.getInvoiceNumber()}</Table.Cell>
        <Table.Cell singleLine>
          <Button className="tertiary" onClick={this._onViewClick}>
            View
          </Button>
        </Table.Cell>
        <Table.Cell>
          {formatCentAmount(this.props.item.total_due_amount)}
        </Table.Cell>
        <Table.Cell>
          {this.props.item.quickbooks_id &&
            formatDateYear(this.props.item.created_at)}
          {this.props.item.stripe_id &&
            formatDateYear(this.props.item.created_at)}
          {this.props.item.recurly_id &&
            formatDateYear(
              new Date(this.props.item.created_at).toJSON().slice(0, 10)
            )}
        </Table.Cell>
        <Table.Cell>
          {this.props.item.quickbooks_id &&
            formatDateYear(
              this.props.item.due_date || this.props.item.created_at
            )}
          {this.props.item.stripe_id &&
            formatDateYear(
              this.props.item.due_date || this.props.item.created_at
            )}
          {this.props.item.recurly_id &&
            formatDateYear(
              new Date(this.props.item.due_date || this.props.item.created_at)
                .toJSON()
                .slice(0, 10)
            )}
        </Table.Cell>
        <Table.Cell className={'invoice-state ' + this.props.item.state}>
          {!this.props.item.state.match(/collected/i) &&
            this.formatState(this.props.item.state)}
          {this.props.item.state.match(/collected/i) &&
            this.props.item.quickbooks_id &&
            formatDateYear(
              this.props.item.transaction_date || this.props.item.updated_at
            )}
          {this.props.item.state.match(/collected/i) &&
            this.props.item.stripe_id &&
            formatDateYear(
              this.props.item.transaction_date || this.props.item.updated_at
            )}
          {this.props.item.state.match(/collected/i) &&
            this.props.item.recurly_id &&
            formatDateYear(
              new Date(
                this.props.item.transaction_date || this.props.item.updated_at
              )
                .toJSON()
                .slice(0, 10)
            )}
        </Table.Cell>
      </Table.Row>
    );
  }
}
// TODO: These time hacks ^^ are because Recurly
// displays dates in UTC when generating the PDF

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doOpenInvoice,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceItemRow);
