import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import browserHistory from '../../lib/history.js';
import { doGetAllProductCategorySubsets } from '../../actions/product-category-actions.js';
import ProductListRowLoading from '../products/item-list-row-loading.js';
import ProductListRow from '../products/item-list-row.js';
import ProductItem from '../products/item.js';
import { SEE_ALL_CATEGORY_THRESHOLD } from '../../strings.js';
import './officeluv-category-catalog.css';
import {
  filterInLimitedCatalog,
  mergePopularProducts,
} from '../../helpers/product-helpers.js';

export class OfficeluvCategoryCatalog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      childCategoriesHaveProducts: false,
    };
  }

  componentDidMount() {
    if (!this.props.category.products || !this.props.category.products.length) {
      this.props.actions.doGetAllProductCategorySubsets(this.props.category);
    }
    if (
      this.props.childCategories.length &&
      !this.props.childCategories[0].products
    ) {
      this.props.childCategories.map((c) => {
        return this.props.actions.doGetAllProductCategorySubsets(c);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category.id !== this.props.category.id) {
      if (
        !this.props.category.products ||
        !this.props.category.products.length
      ) {
        this.props.actions.doGetAllProductCategorySubsets(this.props.category);
      }
      if (
        this.props.childCategories.length &&
        !this.props.childCategories[0].products
      ) {
        this.props.childCategories.map((c) => {
          return this.props.actions.doGetAllProductCategorySubsets(c);
        });
      }
    } else if (prevProps.location_id !== this.props.location_id) {
      this.props.actions.doGetAllProductCategorySubsets(this.props.category);
      this.props.childCategories.map((c) => {
        return this.props.actions.doGetAllProductCategorySubsets(c);
      });
    }
  }

  _handleClickViewAll = (category, suffix) => {
    if (suffix === 'all' || suffix === 'popular') {
      return browserHistory.push(
        '/supplies/catalog-search?category=' + category.id
      );
    }
    browserHistory.push(
      '/supplies/catalog/' + category.id + '/' + (suffix || '')
    );
  };

  _filterChildCategoryWithProducts(cat) {
    return cat.products && cat.products.length;
  }

  renderChildCategory = (item) => {
    let products = item.products ? [...item.products] : [];

    this.setState((state) => {
      return {
        ...state,
        childCategoriesHaveProducts:
          state.childCategoriesHaveProducts || !!products.length,
      };
    });

    if (this.props.inLimitedViewingMode) {
      products = filterInLimitedCatalog(products, this.props.inCatalogCache);
    }

    if (!this.props.isRequesting && products && products.length) {
      return (
        <div className="category-catalog-child category-row" key={item.id}>
          <div className="category-catalog-child-row-header">
            <h4>{item.name}</h4>
            {!!products && products.length > SEE_ALL_CATEGORY_THRESHOLD && (
              <Link to={`/supplies/catalog-search?category=${item.id}`}>
                See All
              </Link>
            )}
          </div>
          {(this.props.isRequesting || !products) && <ProductListRowLoading />}
          {products && !!products.length && (
            <ProductListRow
              onClickViewAll={this._handleClickViewAll.bind(this, item, 'all')}
              items={products}
              maxDisplay={SEE_ALL_CATEGORY_THRESHOLD}
              key={item.id}
            />
          )}
        </div>
      );
    }
  };

  renderItem = (item) => {
    return <ProductItem key={item.id} item={item} canFavorite={true} />;
  };

  render() {
    const popularProducts = mergePopularProducts(
      this.props.category.popular_products,
      this.props.category.products
    );

    return (
      <div className="officeluv-category-catalog">
        {this.props.category.location_popular_products &&
          this.props.category.location_popular_products.length > 0 && (
            <div className="category-catalog-your category-row">
              <h4>Your {this.props.category.name}</h4>
              {!!this.props.category.location_popular_products &&
                this.props.category.location_popular_products.length >
                  SEE_ALL_CATEGORY_THRESHOLD && (
                  <Link
                    to={`/supplies/catalog/${this.props.category.id}/location-popular`}>
                    See All
                  </Link>
                )}
              <ProductListRow
                onClickViewAll={this._handleClickViewAll.bind(
                  this,
                  this.props.category,
                  'location-popular'
                )}
                items={this.props.category.location_popular_products || []}
                maxDisplay={SEE_ALL_CATEGORY_THRESHOLD}
              />
            </div>
          )}
        {popularProducts && popularProducts.length > 0 && (
          <div className="category-catalog-popular category-row">
            <h4>Top {this.props.category.name}</h4>
            {!!popularProducts &&
              popularProducts.length > SEE_ALL_CATEGORY_THRESHOLD && (
                <Link
                  to={`/supplies/catalog-search?category=${this.props.category.id}`}>
                  See All
                </Link>
              )}
            <ProductListRow
              onClickViewAll={this._handleClickViewAll.bind(
                this,
                this.props.category,
                'popular'
              )}
              items={popularProducts}
              maxDisplay={SEE_ALL_CATEGORY_THRESHOLD}
              key={`popular-products-${this.props.category.id}`}
            />
          </div>
        )}
        <div className="category-row">
          <h4>{this.props.category.name}</h4>
          {!!this.props.category.products &&
            this.props.category.products.length >
              SEE_ALL_CATEGORY_THRESHOLD && (
              <Link
                to={`/supplies/catalog-search?category=${this.props.category.id}`}>
                See All
              </Link>
            )}
          {this.props.childCategories.length === 0 && (
            <div className="flex-wrapper category-subset">
              {(this.props.category.products || []).map(this.renderItem)}
            </div>
          )}
          {this.props.childCategories.length > 0 && (
            <ProductListRow
              onClickViewAll={this._handleClickViewAll.bind(
                this,
                this.props.category,
                'all'
              )}
              items={this.props.category.products || []}
              maxDisplay={SEE_ALL_CATEGORY_THRESHOLD}
              key={`child-products-${this.props.category.id}`}
            />
          )}
        </div>
        {this.props.childCategories
          .filter(this._filterChildCategoryWithProducts)
          .map(this.renderChildCategory)}
        {this.props.category.products &&
          !this.props.category.products.length &&
          !this.state.childCategoriesHaveProducts && (
            <div>
              No {this.props.category.name} products are enabled for your
              office.
            </div>
          )}
      </div>
    );
  }
}

OfficeluvCategoryCatalog.propTypes = {
  actions: PropTypes.shape({
    doGetAllProductCategorySubsets: PropTypes.func.isRequired,
  }).isRequired,
  childCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      products: PropTypes.array,
    })
  ).isRequired,
  location_id: PropTypes.number,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    products: PropTypes.array,
    popular_products: PropTypes.array,
    location_popular_products: PropTypes.array,
  }).isRequired,
};

export function mapStateToProps(state) {
  let category = { ...state.productCategories.open };
  const inLimitedViewingMode = state.application.inLimitedViewingMode;
  const in_catalog = state.products.in_catalog;
  if (inLimitedViewingMode) {
    category = {
      ...category,
      location_popular_products: filterInLimitedCatalog(
        category.location_popular_products || [],
        in_catalog
      ),
      popular_products: filterInLimitedCatalog(
        category.popular_products || [],
        in_catalog
      ),
      products: filterInLimitedCatalog(category.products || [], in_catalog),
    };
  }
  return {
    category,
    childCategories: state.productCategories.items.filter((c) => {
      return c.parent_id === state.productCategories.open.id;
    }),
    inCatalogCache: state.products.in_catalog,
    inLimitedViewingMode,
    location_id: state.locations.open.id,
    isRequesting: !!state.productCategories.requesting.length,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doGetAllProductCategorySubsets,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.productCategories.open === next.productCategories.open &&
    prev.productCategories.items === next.productCategories.items &&
    prev.productCategories.requesting === next.productCategories.requesting &&
    prev.locations.open.id === next.locations.open.id &&
    prev.products.in_catalog === next.products.in_catalog &&
    prev.application.inLimitedViewingMode ===
      next.application.inLimitedViewingMode
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeluvCategoryCatalog);
