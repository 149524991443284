import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import EditRequisitionForm from './edit-requisition-form.js';
import EditRequisitionProducts from './edit-requisition-products.js';
import ModalProductSearch from '../modals/modal-product-search.js';
import { CartPropType } from '../../helpers/cart-helpers.js';
import './checkout-page.css';

export class EditRequisition extends React.PureComponent {
  renderErrors() {
    if (!this.props.requisition._optimized_product_requisitions) return null;
    if (
      this.props.requisition._optimized_product_requisitions.unmet_minimums
        .length > 0
    ) {
      return (
        <Message
          negative
          content="You must meet your cart minimums to update your order"
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="checkout-page">
        <div className="variable-width-column flex-wrapper centered">
          <div>
            <ModalProductSearch resultsPlaceholderSubhead="You can make changes up until we start fulfilling your order" />
            <EditRequisitionProducts requisition={this.props.requisition} />
          </div>
          <div className="checkout-page__col-right">
            <h2>Edit Order</h2>
            <EditRequisitionForm
              requisition={this.props.requisition}
              isRequesting={this.props.isRequesting}
            />
            {this.renderErrors()}
            <div style={{ marginTop: '2rem', marginLeft: '.75rem' }}>
              <Link
                to={`/supplies/orders/${this.props.requisition.id}`}
                className="navigate-to-recurring-requisitions primary"
              >
                &laquo; Discard edits
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditRequisition.propTypes = {
  requisition: CartPropType.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default EditRequisition;
