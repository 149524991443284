import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import Loader from '../components/loader.js';
import CleaningDetailsListEmpty from '../components/cleanings/cleaning-details-empty-state.js';
import CleaningsEmpty from '../components/cleanings/empty-all.js';
import { openCleaningDetails } from '../actions/cleaning-actions.js';
import setTitle from '../decorators/set-title.js';
import { FILTER_CLEANINGS_IN_DAY_OF } from '../strings.js';
import './office-tasks.css';

export class OfficeAttendantCleaningDetailsRoute extends React.PureComponent {
  componentDidMount() {
    const prevCleaning = this.props.cleanings[0];
    if (prevCleaning) {
      this.props.actions.openCleaningDetails(prevCleaning);
    }
  }

  componentDidUpdate() {
    const prevCleaning = this.props.cleanings[0];
    if (prevCleaning) {
      this.props.actions.openCleaningDetails(prevCleaning);
    }
  }

  render() {
    return (
      <div className="office-attendant-cleaning-schedule">
        <AuthCheck permissions={{ can_manage_cleanings: true }} />
        <NavBarAttendant />
        <div className="variable-width-column">
          {this.props.isRequesting && <Loader />}
          {!this.props.cleanings.length && this.props.hasCleanings && (
            <div className="empty-shift fixed-width-column">
              <CleaningDetailsListEmpty />
            </div>
          )}
          {!this.props.isRequesting && !this.props.hasCleanings && (
            <CleaningsEmpty />
          )}
        </div>
      </div>
    );
  }
}

OfficeAttendantCleaningDetailsRoute.propTypes = {
  cleanings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  hasCleanings: PropTypes.bool,
  isRequesting: PropTypes.bool,
};

OfficeAttendantCleaningDetailsRoute = setTitle(() => {
  return 'Office Attendant Shift Details';
})(OfficeAttendantCleaningDetailsRoute);

export function mapStateToProps(state) {
  let futureCleanings = state.cleanings.items
    .filter(FILTER_CLEANINGS_IN_DAY_OF(Date.now()))
    .sort((a, b) => (a.start_datetime > b.start_datetime ? 1 : -1));
  return {
    cleanings: futureCleanings,
    hasCleanings: state.cleanings.items.length > 0,
    isRequesting: state.cleanings.isRequesting,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openCleaningDetails,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.cleanings.items === next.cleanings.items &&
    prev.cleanings.isRequesting === next.cleanings.isRequesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(OfficeAttendantCleaningDetailsRoute);
