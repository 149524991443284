import React from 'react';
import NavBarAttendant from '../components/nav/navbar-attendant.js';
import AuthCheck from '../components/auth-check.js';
import Orders from '../components/supplies/orders.js';
import CartFlex from '../components/requisitions/cart-flex.js';
import MountAtPageTop from '../components/utility/mount-at-page-top.js';
import setTitle from '../decorators/set-title.js';
import SubNavSuppliesOrders from '../components/nav/subnav-supplies-orders.js';
import './supplies-orders.css';

export class SuppliesOrders extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Past Orders.', 'Marketplace');
  }

  render() {
    return (
      <div className="supplies-orders-route">
        <AuthCheck />
        <MountAtPageTop />
        <NavBarAttendant />
        <SubNavSuppliesOrders />
        <div className="supplies-orders">
          <div className="variable-width-column flex-wrapper">
            <div className="orders-box">
              <Orders />
            </div>
            <CartFlex canSwitchCarts={true} />
          </div>
        </div>
      </div>
    );
  }
}

SuppliesOrders = setTitle((props) => {
  return 'Your Orders';
})(SuppliesOrders);

export default SuppliesOrders;
