import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import InputText from '../forms/input-text.js';
import { doCatalogItemRequest } from '../../actions/catalog-item-request-actions.js';
import './request-item-form.css';
const RESET_TIMEOUT = 1000 * 10;

export class RequestItemForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isSubmitted: false,
    };
  }

  componentWillUnmount() {
    if (this._timeoutReset) window.clearTimeout(this._timeoutReset);
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (!this.state.name) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        isSubmitted: true,
      })
    );
    this.props.actions.doCatalogItemRequest({
      text: this.state.name,
    });
    if (this.props.onSubmit) this.props.onSubmit();
    this._timeoutReset = window.setTimeout(this.resetForm, RESET_TIMEOUT);
  };

  handleChangeName = (name) => {
    this.setState(
      Object.assign({}, this.state, {
        name,
      })
    );
  };

  resetForm = () => {
    this.setState(
      Object.assign({}, this.state, {
        name: '',
        isSubmitted: false,
      })
    );
    if (this.props.onReset) this.props.onReset();
  };

  render() {
    return (
      <form className="request-item-form" onSubmit={this.handleSubmit}>
        {!this.state.isSubmitted && (
          <div className="request-item-form-inner">
            <InputText
              value={this.state.name}
              onChange={this.handleChangeName}
              className="request-item-input-name"
              placeholder="Case of 24 Bounty Paper Towels"
              autoFocus={this.props.autoFocus}
              label="Product description or link to product"
              name="product-name"
            />
            <Button primary size="mini" className="request-item-submit">
              Request Item
            </Button>
          </div>
        )}
        {this.state.isSubmitted && (
          <div className="request-item-form-submitted brand-bg-color-override">
            We&rsquo;ll start working on this right now and will follow up
            shortly to let you know when it will be in stock.
          </div>
        )}
      </form>
    );
  }
}

RequestItemForm.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  autoFocus: PropTypes.bool,
  openCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    doCatalogItemRequest: PropTypes.func.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    openCategory: state.productCategories.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doCatalogItemRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestItemForm);
