import * as types from '../actions/action-types.js';

export const empty = {
  items: [],
  requesting: [],
  errors: [],
};

export const initial = Object.assign({}, empty, {});

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        requesting: state.requesting.concat({
          _request: types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
        }),
      });
    case types.SUCCESS_GET_LOCATION_PARENT_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        items: action.data,
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES;
        }),
      });
    case types.ERROR_GET_LOCATION_PARENT_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter((r) => {
          return r._request !== types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES;
        }),
        errors: state.errors.concat({
          _request: types.GET_LOCATION_PARENT_PRODUCT_CATEGORIES,
          data: action.data,
        }),
      });
    default:
      return state;
  }
};
