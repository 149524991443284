import React from 'react';
import PropTypes from 'prop-types';
import CleaningStaffsList from '../staffs/cleaning-staffs-list.js';
import TaskItemsList from './task-items-list.js';
import CommentsList from './comments-list.js';
import CommentForm from './comment-form.js';
import Loader from '../loader.js';
import './cleaning-details.css';

export default class CleaningDetails extends React.PureComponent {
  render() {
    return (
      <div className="cleaning-details">
        {!this.props.isRequesting && (
          <div className="variable-width-column">
            <div className="cleaning-header-container">
              <div className="cleaning-details-cleaning-staff flex-wrapper">
                <CleaningStaffsList cleaning={this.props.item} />
              </div>
            </div>
            <div className="cleaning-details-container flex-wrapper">
              <div className="shift-notes flex-item">
                <CommentsList
                  comments={this.props.item.comments}
                  date={this.props.item.start_datetime}
                />
                <CommentForm cleaning={this.props.item} />
              </div>
              <div className="flex-item cleaning-details-tasks-container">
                <TaskItemsList editable={true} cleaning={this.props.item} />
              </div>
            </div>
          </div>
        )}
        {this.props.isRequesting && <Loader />}
      </div>
    );
  }
}

CleaningDetails.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    task_list_id: PropTypes.number,
    day: PropTypes.string,
    add_task: PropTypes.string,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    checkins: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        staff_id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        cleaner_note: PropTypes.string,
        checkout_time: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};
