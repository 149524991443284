import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Menu, Header, Icon, Segment } from 'semantic-ui-react';
import InputQuantity from '../forms/input-quantity.js';
import {
  submitLocationListProduct,
  submitUpdateLocationListProduct,
  submitDeleteLocationListProduct,
} from '../../actions/location-list-product-actions.js';
import {
  trackInterfaceFavoriteProduct,
  trackInterfaceUnFavoriteProduct,
} from '../../lib/analytics.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './modal-product-location-list-form.css';
import heartIcon from '../../imgs/ol-logo-heart.js';
import heartIconOutline from '../../imgs/ol-logo-heart--outline.svg';

export class ModalProductLocationListForm extends React.PureComponent {
  constructor(props) {
    super(props);
    let location_list_product = this.filterLocationListProduct(
      props.location_list_products,
      props.openLocationList.id
    );
    this.state = {
      minimumQuantity: this.minimumQuantity(location_list_product),
      locationListId: props.openLocationList.id,
      location_list_product,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState((prevState) => {
      let location_list_product = this.filterLocationListProduct(
        nextProps.location_list_products,
        prevState.locationListId || nextProps.openLocationList.id
      );
      return {
        minimumQuantity: this.minimumQuantity(location_list_product),
        locationListId:
          prevState.locationListId || nextProps.openLocationList.id,
        location_list_product,
      };
    });
  }

  isInLocationList = () => {
    return !!this.state.location_list_product;
  };

  minimumQuantity = (location_list_product) => {
    return (location_list_product || { min: 1 }).min;
  };

  changedStockQuantity = () => {
    return (
      this.isInLocationList() &&
      this.minimumQuantity(this.state.location_list_product) !==
        this.state.minimumQuantity
    );
  };

  formatButtonText = () => {
    if (this.isInLocationList()) {
      return 'Save Changes';
    }
    return 'Add to Inventory List';
  };

  filterLocationListProduct(llps, llid) {
    return llps.filter((llp) => llp.location_list_id === llid)[0];
  }

  isDisabled = () => {
    if (!this.props.role.can_manage_location_lists) return true;

    if (this.changedStockQuantity()) {
      return false;
    }
    if (!this.isInLocationList() && this.state.minimumQuantity) {
      return false;
    }
    return true;
  };

  handleChangeLocationList = (e, { id }) => {
    this.setState(() => {
      let location_list_product = this.filterLocationListProduct(
        this.props.location_list_products,
        id
      );
      return {
        locationListId: id,
        location_list_product,
        minimumQuantity: this.minimumQuantity(location_list_product),
      };
    });
  };

  handleChangeMinimumQuantity = (minimumQuantity) => {
    this.setState(() => {
      return {
        minimumQuantity: Math.max(minimumQuantity, 0),
      };
    });
  };

  handleAddToLocationList = () => {
    if (this.isDisabled()) {
      return;
    }
    if (this.changedStockQuantity()) {
      this.props.actions.submitUpdateLocationListProduct({
        id: this.state.location_list_product.id,
        location_list_id: this.state.locationListId,
        product_id: this.props.product.id,
        min: this.state.minimumQuantity,
        max: this.state.minimumQuantity,
      });
      return;
    }
    this.props.actions.submitLocationListProduct({
      location_list_id: this.state.locationListId,
      product_id: this.props.product.id,
      min: this.state.minimumQuantity,
      max: this.state.minimumQuantity,
    });
    trackInterfaceFavoriteProduct(this.props.product);
  };

  handleClickRemove = () => {
    if (!this.state.location_list_product) {
      return;
    }
    this.props.actions.submitDeleteLocationListProduct({
      id: this.state.location_list_product.id,
      location_list_id: this.state.locationListId,
      product_id: this.props.product.id,
    });
    trackInterfaceUnFavoriteProduct(this.props.product);
  };

  getActiveLocationList = () => {
    return (
      this.props.locationLists.filter(
        (ll) => ll.id === this.state.locationListId
      )[0] || {}
    );
  };

  renderLocationListMenuItem = (ll) => {
    return (
      <Menu.Item
        key={ll.id}
        id={ll.id}
        content={
          <div>
            <img
              src={
                this.filterLocationListProduct(
                  this.props.location_list_products,
                  ll.id
                )
                  ? heartIcon({ color: this.props.brandColor })
                  : heartIconOutline
              }
              className="product-item__favorite hvr-grow"
              alt="favorite product"
            />
            {ll.name}
          </div>
        }
        active={this.state.locationListId === ll.id}
        onClick={this.handleChangeLocationList}
      />
    );
  };

  renderFavoritesForm = () => {
    let className = '';
    if (this.props.locationLists.length > 1) {
      className = 'multiple-lists';
    }
    if (this.isInLocationList()) {
      return (
        <Segment placeholder className={'favorites-form in-list ' + className}>
          <Header icon>
            <Icon name="heart" color="green" />
            This product is a favorite in {this.getActiveLocationList().name}.
          </Header>
          <Button className="tertiary" onClick={this.handleClickRemove}>
            Remove from list
          </Button>
        </Segment>
      );
    }
    return (
      <Segment placeholder className={'favorites-form ' + className}>
        <Header icon>
          <Icon name="heart" color="green outline" />
          Adding to your favorites keeps this product front and center for your
          team.
        </Header>
        <Button primary onClick={this.handleAddToLocationList}>
          Add to List
        </Button>
      </Segment>
    );
  };

  renderSupplyLevelsForm = () => {
    return (
      <div className="product-minimum-quantity">
        <InputQuantity
          readOnly={!this.props.role.can_manage_location_lists}
          min={0}
          value={this.state.minimumQuantity}
          label="Minimum # to keep in stock"
          onChange={this.handleChangeMinimumQuantity}
        />
        <div className="delete-location-list-product">
          {this.isInLocationList() && (
            <Button
              onClick={this.handleClickRemove}
              size="mini"
              className="tertiary"
              disabled={!this.props.role.can_manage_location_lists}>
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="product-stock-action">
        <div className="product-stock-action-input flex-wrapper">
          {this.props.locationLists.length > 1 && (
            <Menu pointing secondary vertical>
              {this.props.locationLists.map(this.renderLocationListMenuItem)}
            </Menu>
          )}
          {this.props.canRecordSupplyLevels && this.renderSupplyLevelsForm()}
          {!this.props.canRecordSupplyLevels && this.renderFavoritesForm()}
        </div>
        {this.props.canRecordSupplyLevels && (
          <div className="product-stock-action-controls">
            <Button
              primary
              disabled={this.isDisabled()}
              onClick={this.handleAddToLocationList}>
              {this.formatButtonText()}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ModalProductLocationListForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    unit_purchase: PropTypes.string,
    stock_per_purchase: PropTypes.number,
    unit_stock: PropTypes.string,
    brand: PropTypes.string,
  }).isRequired,
  locationList: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  locationLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  location_list_products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      product_id: PropTypes.number.isRequired,
      location_list_id: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    submitDeleteLocationListProduct: PropTypes.func.isRequired,
    submitUpdateLocationListProduct: PropTypes.func.isRequired,
    submitLocationListProduct: PropTypes.func.isRequired,
  }).isRequired,
  canRecordSupplyLevels: PropTypes.bool,
  role: PropTypes.shape({
    can_manage_location_lists: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    canRecordSupplyLevels:
      state.locations.open.pref_enable_location_list_stock_levels,
    locationLists: state.locationLists.items,
    openLocationList: state.locationLists.open,
    location_list_products: (state.locationListProducts.items || []).filter(
      (llp) => llp.product_id === props.product.id
    ),
    role: state.auth.role,
    brandColor: state.locations.open.brand_color,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationListProduct,
        submitUpdateLocationListProduct,
        submitDeleteLocationListProduct,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locationListProducts.items === next.locationListProducts.items &&
    prev.locations.open.pref_enable_location_list_stock_levels ===
      next.locations.open.pref_enable_location_list_stock_levels &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(ModalProductLocationListForm);
