import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import browserHistory from '../../lib/history.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/label.min.css';
import './external-vendor-invoice-upload.css';

export class ExternalVendorInvoiceUpload extends React.PureComponent {
  buildLink = () => {
    let body = `Hi!\n\rHere is an invoice (attached) I would like uploaded into our Unified Insights at our ${this.props.location.name} location.`;
    let email = 'support@officeluv.com';
    if (this.props.location.account_manager) {
      email = this.props.location.account_manager.email;
    }
    let subject = 'Unified Insights Upload';
    return `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  handleClick = (e) => {
    e.preventDefault();
    browserHistory.push('/insights/vendor-invoices');
  };

  render() {
    return (
      <Segment
        className="external-vendor-invoice-upload"
        style={{ borderTop: '1px solid #535662' }}>
        <Header as="h2">Complete your expense reports in a flash.</Header>
        <Header.Subheader>
          Upload any vendor invoice to OfficeLuv and have it automatically
          uploaded into your Insights and view holistic spend.
        </Header.Subheader>
        <Button
          icon
          labelPosition="left"
          as="a"
          href="/insights/vendor-invoices"
          onClick={this.handleClick}
          color="green"
          primary>
          <Icon name="file outline" />
          Upload Invoices
        </Button>
      </Segment>
    );
  }
}

ExternalVendorInvoiceUpload.propTypes = {
  location: PropTypes.PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    account_manager: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
  };
}

function areStatesEqual(prev, next) {
  return prev.locations.open === next.locations.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  ExternalVendorInvoiceUpload
);
