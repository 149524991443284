import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import InputText from '../forms/input-text.js';
import InputTextArea from '../forms/input-textarea.js';
import InputRadio from '../forms/input-radio.js';
import { formatDate } from '../../lib/formatters.js';
import {
  submitTask,
  submitUpdateTask,
  closeCreateTaskModal,
  closeTaskModal,
  showTaskModalConfirmationDelete,
} from '../../actions/task-actions.js';
import './task-form.css';

export class TaskForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: props.item.title || '',
      note: props.item.note || '',
      area: props.item.area || '',
      recurring: props.item.recurring || false,
      completed: props.item.completed || false,
      cleaning_id: props.item.cleaning_id,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      Object.assign({}, this.state, {
        title: nextProps.item.title || '',
        note: nextProps.item.note || '',
        area: nextProps.item.area || '',
        recurring: nextProps.item.recurring,
        completed: nextProps.item.completed,
        cleaning_id: nextProps.item.cleaning_id,
      })
    );
  }

  recurringChoices = () => {
    return [
      {
        value: false,
        label: 'Do This ' + formatDate(this.props.cleaning.start_datetime),
        selected: this.state.recurring === false ? true : false,
      },
      {
        value: true,
        label: 'Do this everyday',
        sublabel: 'Check coffee, stock supplies, etc...',
        selected: this.state.recurring === true ? true : false,
      },
    ];
  };

  handleChangeTitle = (title) => {
    this.setState(Object.assign({}, this.state, { title }));
  };

  handleChangeNote = (note) => {
    this.setState(Object.assign({}, this.state, { note }));
  };

  handleChangeArea = (area) => {
    this.setState(Object.assign({}, this.state, { area }));
  };

  handleChangeRecurring = (recurring) => {
    this.setState(Object.assign({}, this.state, { recurring }));
  };

  handleSubmitTask = (e) => {
    e.preventDefault();
    this.props.actions.submitTask(this.state);
    this.props.actions.closeCreateTaskModal();
  };

  handleSubmitEditTask = (e) => {
    e.preventDefault();
    let updateData = Object.assign({}, this.state, { id: this.props.item.id });
    this.props.actions.submitUpdateTask(updateData);
    this.props.actions.closeTaskModal();
  };

  handleSubmitDeleteTask = (e) => {
    e.preventDefault();
    this.props.actions.showTaskModalConfirmationDelete({
      id: this.props.item.id,
    });
  };

  hasTitleChanged = () => {
    return this.props.item.title.trim() === this.state.title.trim();
  };

  hasNoteChanged = () => {
    let cleaningNote = this.props.item.note ? this.props.item.note.trim() : '';
    return cleaningNote === this.state.note.trim();
  };

  hasAreaChanged = () => {
    let cleaningArea = this.props.item.area ? this.props.item.area.trim() : '';
    return cleaningArea === this.state.area.trim();
  };

  isCreateDisabled = () => {
    return !this.state.title || !this.state.cleaning_id;
  };

  isUpdateDisabled = () => {
    return (
      this.hasTitleChanged() &&
      this.hasNoteChanged() &&
      this.hasAreaChanged() &&
      this.props.item.recurring === this.state.recurring
    );
  };

  render() {
    return (
      <form className="cleaning-task-form">
        <InputText
          className="cleaning-task-form-input task-title"
          label="What needs to be done?*"
          placeholder="Stock supplies, tidy kitchen, etc..."
          autoFocus={true}
          value={this.state.title}
          onChange={this.handleChangeTitle}
        />
        <InputText
          className="cleaning-task-form-input area"
          label="Where in the office?"
          placeholder="e.g. Executive conference room"
          value={this.state.area}
          onChange={this.handleChangeArea}
        />
        <InputTextArea
          className="cleaning-task-form-input note-area"
          label="Any details for the office attendant?"
          placeholder="The paper towels need to be restocked and the trash needs to be emptied."
          value={this.state.note}
          onChange={this.handleChangeNote}
        />
        <div
          className={classnames('cleaning-task-form-radio-container', {
            recurring: this.state.recurring,
          })}
        >
          <InputRadio
            className="cleaning-task-form-radio"
            label=""
            value={this.state.recurring}
            onChange={this.handleChangeRecurring}
            options={this.recurringChoices()}
          />
        </div>
        {this.props.item.id === 'new' && (
          <Button
            primary
            disabled={this.isCreateDisabled()}
            onClick={this.handleSubmitTask}
          >
            Add To&ndash;Do
          </Button>
        )}
        {this.props.item.id && this.props.item.id !== 'new' && (
          <Button.Group>
            <Button
              primary
              disabled={this.isUpdateDisabled()}
              onClick={this.handleSubmitEditTask}
            >
              Save Changes
            </Button>
            <Button.Or />
            <Button onClick={this.handleSubmitDeleteTask}>
              Remove To&ndash;Do
            </Button>
          </Button.Group>
        )}
      </form>
    );
  }
}

TaskForm.propTypes = {
  cleaning: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_datetime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cleaning_id: PropTypes.number.isRequired,
    title: PropTypes.string,
    note: PropTypes.string,
    area: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    cleaning: state.cleanings.items.filter(
      (c) => c.id === props.item.cleaning_id
    )[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitTask,
        submitUpdateTask,
        closeCreateTaskModal,
        closeTaskModal,
        showTaskModalConfirmationDelete,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
