import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Header } from 'semantic-ui-react';
import {
  stageStipend,
  unstageStipend,
  FILTER_IS_LOADING,
  FILTER_IS_UPDATE_SUCCESS,
} from '../ducks/stipends.js';
import AuthCheck from '../components/auth-check.js';
import StipendCreateForm from '../components/stipends/create-form.js';
import StipendsList from '../components/stipends/list.js';

export class EmployeeStipends extends React.PureComponent {
  componentDidMount() {
    window.totango.track('Viewed Employee Stipends.', 'Settings');
  }

  render() {
    return (
      <Segment
        basic
        className="employee-stipends"
        loading={this.props.isLoading}>
        <AuthCheck permissions={{ can_manage_employees_stipends: true }} />
        <Fragment>
          <Header as="h1" content={'Stipends'} />
          <StipendCreateForm
            customer={this.props.customer}
            stipend={this.props.stagedStipend}
            isLoading={this.props.isLoading}
          />
          <StipendsList />
        </Fragment>
      </Segment>
    );
  }
}

EmployeeStipends.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isUpdateSuccess: PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    stagedStipend: state.stipends.staged,
    isLoading:
      state.stipends.requesting.filter(FILTER_IS_LOADING).length > 0 ||
      !state.customers.open.name,
    isUpdateSuccess:
      state.stipends.responses.filter(FILTER_IS_UPDATE_SUCCESS).length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        stageStipend,
        unstageStipend,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.stipends.staged === next.stipends.staged &&
    prev.customers.open === next.customers.open &&
    prev.stipends.requesting === next.stipends.requesting &&
    prev.stipends.responses === next.stipends.responses
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EmployeeStipends);
