import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Header,
  Icon,
  Input,
  Segment,
  Message,
} from 'semantic-ui-react';
import AttachmentPreviewList from '../attachments/preview-list.js';
import { doUploadExternalVendorInvoice } from '../../actions/external-vendor-invoice-actions.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/label.min.css';
import './upload-attachment.css';

export class EVIUploadAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      displaySuccess: false,
      _request: Math.random().toString(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextPropsHasError =
      nextProps.errors.filter((err) => {
        return err.data._request.match(this.state._request);
      }).length > 0;
    if (
      this.props.isUploading &&
      !nextProps.isUploading &&
      !nextPropsHasError
    ) {
      return this.setState(() => {
        return {
          displaySuccess: true,
          displayError: false,
          attachments: [],
        };
      });
    }
    if (this.props.isUploading && !nextProps.isUploading && nextPropsHasError) {
      this.setState(() => {
        return {
          displaySuccess: false,
          displayError: true,
          attachments: [],
        };
      });
    }
  }

  handleFileChange = (e) => {
    let files = e.target.files;
    if (!files[0]) return;
    this.setState(() => {
      return {
        attachments: Array.prototype.slice.call(files),
        displaySuccess: false,
        displayError: false,
      };
    });
  };

  handleRemoveAttachment = (attachment) => {
    this.setState((prev) => {
      return {
        attachments: prev.attachments.filter((a) => a.name !== attachment.name),
      };
    });
  };

  handleClickUpload = () => {
    this.state.attachments.map((attachment) => {
      return this.props.actions.doUploadExternalVendorInvoice(
        {
          location_id: this.props.location.id,
          vendor_name: '[pending]',
          description: '[pending]',
          invoice_number: new Date().toJSON(),
          transaction_date: new Date().toJSON(),
          _request: this.state._request + Math.random(),
        },
        attachment
      );
    });
  };

  renderUploadText = () => {
    if (this.state.attachments.length === 1) {
      return 'Upload File';
    }
    return `Upload ${this.state.attachments.length} Files`;
  };

  renderLoadingMessage = () => {
    return (
      <Message attached="bottom">
        <Message.Header>Just one second</Message.Header>
        Uploading your files...
      </Message>
    );
  };

  renderSuccessMessage = () => {
    return (
      <Message attached="bottom" success>
        <Message.Header>Success!</Message.Header>
        Your files have been uploaded and the data will be{' '}
        <Link to="/insights/budgets">available as insights</Link> shortly.
      </Message>
    );
  };

  renderErrorMessage = () => {
    return (
      <Message attached="bottom" error>
        <Message.Header>Whoops!</Message.Header>
        One or more of your files failed to upload.
      </Message>
    );
  };

  render() {
    const attached =
      this.props.isUploading ||
      this.state.displaySuccess ||
      this.state.displayError;
    return (
      <div className="evi-upload-attachment">
        <Header as="h3" attached="top">
          Upload an Invoice
        </Header>
        <Segment
          attached={attached ? 'true' : 'bottom'}
          placeholder
          loading={this.props.isUploading}>
          <Header icon>
            <Icon name="file alternate outline" />
            Select PDF or image files to upload.
          </Header>
          <Input
            onChange={this.handleFileChange}
            icon="file alternate outline"
            accept="image/*,.pdf"
            multiple
            type="file"
          />
          <AttachmentPreviewList
            items={this.state.attachments}
            onRemove={this.handleRemoveAttachment}
          />
          <hr />
          {this.state.attachments.length > 0 && (
            <Button size="big" primary compact onClick={this.handleClickUpload}>
              {this.renderUploadText()}
            </Button>
          )}
        </Segment>
        {this.props.isUploading && this.renderLoadingMessage()}
        {this.state.displaySuccess && this.renderSuccessMessage()}
        {this.state.displayError && this.renderErrorMessage()}
      </div>
    );
  }
}

EVIUploadAttachment.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    account_manager: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isUploading: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    doUploadExternalVendorInvoice: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    isUploading: state.externalVendorInvoices.creating.length > 0,
    errors: state.externalVendorInvoices.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        doUploadExternalVendorInvoice,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.externalVendorInvoices.errors === next.externalVendorInvoices.errors &&
    prev.externalVendorInvoices.creating ===
      next.externalVendorInvoices.creating &&
    prev.externalVendorInvoices.items === next.externalVendorInvoices.items
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EVIUploadAttachment);
