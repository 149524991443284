import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import 'semantic-ui-css/components/form.min.css';
import { Segment, Button } from 'semantic-ui-react';
import classnames from 'classnames';
import { COUNTRY_CODES } from '../strings.js';
import './credit-card-information.css';

const stripePromise = loadStripe('pk_live_1ikdlvrbtDotw2dWnKOBSNfX00aWQA8XWo');

const StripeBillingInfoFormInner = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useState({
    errors: {},
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    address1: props.address1,
    address2: props.address2,
    city: props.city,
    state: props.state,
    country: props.country || 'US',
    postalCode: props.postalCode,
  });

  const handleFieldChange = (name) => {
    return (e) => {
      let newState = { ...state };
      newState[name] = e.target.value;
      setState(() => {
        return newState;
      });
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: `${state.firstName} ${state.lastName}`,
        email: state.email,
        address: {
          line1: state.address1,
          line2: state.address2,
          city: state.city,
          state: state.state,
          country: state.country,
          postal_code: state.postalCode,
        },
      },
    });

    if (!error) {
      setState((prev) => {
        return {
          ...prev,
          errors: {},
        };
      });
      props.onToken(paymentMethod.id);
      console.log(paymentMethod.id);
    }
    if (error) {
      setState((prev) => {
        return {
          ...prev,
          errors: error,
        };
      });
      console.log('[error]', error);
    }
  };

  return (
    <Segment basic className="cc-form" loading={props.loading}>
      <form
        className={classnames('ui form', {
          loading: props.loading,
        })}
        onSubmit={handleSubmit}>
        <div className="cc-form__row">
          <div className="cc-form__field col_6">
            <label>First Name</label>

            <input
              type="text"
              placeholder="First Name"
              autoComplete="cc-given-name"
              onChange={handleFieldChange('firstName')}
              value={state.firstName}
            />
          </div>
          <div className="cc-form__field col_6">
            <label>Last Name</label>

            <input
              type="text"
              placeholder="Last Name"
              autoComplete="cc-family-name"
              onChange={handleFieldChange('lastName')}
              value={state.lastName}
            />
          </div>
        </div>
        <div className="cc-form__row">
          <div className="cc-form__field col_6">
            <label>Email</label>

            <input
              type="text"
              placeholder="Email"
              autoComplete="cc-email"
              onChange={handleFieldChange('email')}
              value={state.email}
            />
          </div>
        </div>
        <div className="cc-form__row">
          <div className="cc-form__field col_12" style={{ flex: '2.11' }}>
            <label>Address</label>

            <input
              type="text"
              placeholder="Street Address"
              onChange={handleFieldChange('address1')}
              value={state.address1}
            />
          </div>
          <div className="cc-form__field col_4" style={{ flex: '1' }}>
            <label>Suite/Apt</label>

            <input
              type="text"
              placeholder="Suite/#"
              onChange={handleFieldChange('address2')}
              value={state.address2}
            />
          </div>
        </div>
        <div className="cc-form__row">
          <div className="cc-form__field col_8" style={{ flex: '2' }}>
            <label>City</label>

            <input
              type="text"
              placeholder="Street Address"
              onChange={handleFieldChange('city')}
              value={state.city}
            />
          </div>
          <div className="cc-form__field col_4" style={{ flex: '2' }}>
            <label>State/Region</label>

            <input
              type="text"
              placeholder="State"
              onChange={handleFieldChange('state')}
              value={state.state}
            />
          </div>
          <div className="cc-form__field col_4" style={{ flex: '2' }}>
            <label>Country</label>

            <select
              placeholder="Country"
              onChange={handleFieldChange('country')}
              value={state.country}>
              {COUNTRY_CODES.map((country) => (
                <option key={country.key} value={country.value}>
                  {country.text}
                </option>
              ))}
            </select>
          </div>
          <div className="cc-form__field col_4" style={{ flex: '2' }}>
            <label>Postal Code</label>

            <input
              type="text"
              placeholder="Zip"
              onChange={handleFieldChange('postalCode')}
              value={state.postalCode}
            />
          </div>
        </div>

        <label>Credit Card</label>
        <div className="stripe-card">
          <CardElement />
        </div>

        {state.errors && state.errors.message && (
          <div className="cc-form__error-message cc-form__error-message--top">
            Submission failed: {state.errors.message}
          </div>
        )}

        {props.children}
        {props.renderSubmitButton && (
          <div className="cc-form__row">
            <div className="cc-form__field col_12">
              <Button primary>Save</Button>
              {props.renderCancelButton && (
                <Button
                  className="tertiary"
                  type="button"
                  floated="right"
                  onClick={props.onCancel}>
                  Cancel
                </Button>
              )}
            </div>
          </div>
        )}
      </form>
    </Segment>
  );
};

StripeBillingInfoFormInner.defaultProps = {
  loading: false,
  renderSubmitButton: true,
  onSubmit: function () {},
  onToken: function () {},
  renderCancelButton: false,
  onCancel: function () {},
};

const CreditCardInformation = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeBillingInfoFormInner {...props} />
    </Elements>
  );
};

export default CreditCardInformation;
