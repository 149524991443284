import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/menu.min.css';
import './budget-code-link.css';

export class BudgetCodeLink extends React.PureComponent {
  render() {
    return (
      <Menu.Item active={this.props.active}>
        <Link
          to={`/insights/budget-codes/${this.props.budgetCode.id}`}
          className="primary"
        >
          {this.props.budgetCode.name}&nbsp;
          {!this.props.budgetCode.active && (
            <em className="light-gray-text">(Archived)</em>
          )}
        </Link>
        <p>{formatCentAmount(this.props.budgetCode.total_spend)} of spend</p>
      </Menu.Item>
    );
  }
}

BudgetCodeLink.propTypes = {
  budgetCode: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    total_spend: PropTypes.number.isRequired,
    active: PropTypes.bool,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    active: state.customerBudgetCodes.open.id === props.budgetCode.id,
  };
}

function areStatesEqual(prev, next) {
  return prev.customerBudgetCodes.open === next.customerBudgetCodes.open;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  BudgetCodeLink
);
