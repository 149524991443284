import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Input, Form, Icon } from 'semantic-ui-react';
import {
  submitUpdateLocationList,
  submitDeleteLocationList,
} from '../../actions/location-list-actions.js';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/form.min.css';
import './location-list-form-edit.css';

export class EditLocationListForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locationList: props.locationList,
    };
  }

  handleChangeName = (e, { value }) => {
    this.setState((prev) => {
      return {
        ...prev,
        locationList: {
          ...prev.locationList,
          name: value,
        },
      };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.props.actions.submitUpdateLocationList({
      id: this.props.locationList.id,
      name: this.state.locationList.name,
      location_id: this.props.locationList.location_id,
    });
    this.props.onSuccess && this.props.onSuccess();
  };

  isDisabled = () => {
    return this.state.locationList.name.length < 2;
  };

  confirmDelete = () => {
    if (
      window.confirm(
        'Do you really want to remove this list? This cannot be undone.'
      )
    ) {
      this.submitDelete();
    }
  };

  submitDelete = () => {
    this.props.actions.submitDeleteLocationList(this.props.locationList);
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit} className="location-list-form-edit">
        <Form.Field
          id="form-input-control-first-name"
          control={Input}
          required
          onChange={this.handleChangeName}
          value={this.state.locationList.name}
          label="List Name"
          placeholder="Name"
        />
        <Button primary size="small" disabled={this.isDisabled()}>
          Update List
        </Button>
        <Button
          secondary
          type="button"
          style={{ float: 'right' }}
          size="small"
          onClick={this.confirmDelete}
        >
          <Icon name="trash alternate outline" />
          Remove List
        </Button>
      </Form>
    );
  }
}

EditLocationListForm.propTypes = {
  locationList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func,
  actions: PropTypes.shape({
    submitUpdateLocationList: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateLocationList,
        submitDeleteLocationList,
      },
      dispatch
    ),
  };
}

function areStatesEqual() {
  return true;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(EditLocationListForm);
