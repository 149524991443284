import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import StepMarker from './step-marker.js';
import 'semantic-ui-css/components/form.min.css';
import './company-form.css';

import BasicLocationForm from '../basic-location-form.js';

export class OnboardingCompanyForm extends React.PureComponent {
  onSubmit = (data) => {
    this.props.onSubmit(data);
  };

  render() {
    return (
      <div className="onboarding-company-form">
        <h2
          style={{
            fontWeight: '700',
            marginBottom: '2rem',
          }}
        >
          Your Office
        </h2>
        <p
          style={{
            lineHeight: '1.4rem',
            marginBottom: '1.4rem',
          }}
        >
          If you manage more than one office, you can always add those after
          your account is created.
        </p>
        <BasicLocationForm submitText="Next" onSubmit={this.onSubmit}>
          <Form.Field width={8}>
            <StepMarker
              stepNumber={this.props.stepNumber}
              stepTotal={this.props.stepTotal}
            />
          </Form.Field>
        </BasicLocationForm>
      </div>
    );
  }
}

OnboardingCompanyForm.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

OnboardingCompanyForm.defaultProps = {
  stepTotal: 5,
  stepNumber: 2,
  onSubmit: function () {},
};

export default OnboardingCompanyForm;
