import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Header,
  Segment,
} from 'semantic-ui-react';
import CategorySpendAlertRequestForm from './category-spend-alert-request-form.js';
import { submitUpdateEmployee } from '../../actions/employee-actions.js';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './settings-employee-notifications.css';

let BUDGET_SPEND_OPTIONS = [
  { key: '0.5', value: 0.5, text: '50%' },
  { key: '0.75', value: 0.75, text: '75%' },
  { key: '0.8', value: 0.8, text: '80%' },
  { key: '0.9', value: 0.9, text: '90%' },
  { key: '0.95', value: 0.95, text: '95%' },
  { key: '1.0', value: 1.0, text: '100%' },
];

let OPTIONS = [
  {
    header: 'Orders',
    options: [
      { label: 'Order Confirmations', value: 'requisition.submitted' },
      {
        label: 'Orders that require my approval',
        value: 'requisition.approval_requested',
      },
      { label: 'My approved orders', value: 'requisition.approved' },
      {
        label: 'Substituted items in orders',
        value: 'product_requisition.substituted',
      },
    ],
  },
  {
    header: 'Recurring Orders',
    options: [
      {
        label: 'Recurring orders that require my confirmation',
        value: 'scheduled_requisition.approval_requested',
      },
      {
        label: 'Cancelled scheduled orders',
        value: 'scheduled_requisition.expired',
      },
    ],
  },
  {
    header: 'Other',
    options: [
      { label: 'Shipments that are on their way', value: 'shipment.created' },
      { label: 'Spend and ordering milestones', value: 'employee.milestone' },
      // {
      //   label: 'Schedule changes for my office attendant',
      //   value: 'checkin_expectation.created',
      // },
      // {
      //   label: 'If my office attendant calls off their shift',
      //   value: 'cleaning.shift_call_off',
      // },
    ],
  },
];

export class SettingsNotifications extends React.PureComponent {
  handleChange = (e, { checked, name }) => {
    let pref_value =
      this.props.employee.pref_event_email_notification_exclude_match;
    let negative = `^${name}$`;
    pref_value = pref_value.split('|').filter((p) => p !== negative);
    if (!checked) {
      pref_value.push(negative);
    }
    pref_value = pref_value.join('|');
    this.props.actions.submitUpdateEmployee({
      id: this.props.employee.id,
      pref_event_email_notification_exclude_match: pref_value,
    });
  };

  budgetSpendValue = () => {
    return this.props.employee
      .pref_notify_location_spend_budget_month_threshold;
  };

  handleChangeBudgetCheckbox = (e, { checked }) => {
    this.props.actions.submitUpdateEmployee({
      id: this.props.employee.id,
      pref_notify_location_spend_budget_month_threshold: checked ? 0.8 : null,
    });
  };

  handleChangeBudgetThreshold = (e, { value }) => {
    this.props.actions.submitUpdateEmployee({
      id: this.props.employee.id,
      pref_notify_location_spend_budget_month_threshold: value,
    });
  };

  renderOption = (option) => {
    return (
      <Form.Field
        control={Checkbox}
        key={option.value}
        name={option.value}
        onChange={this.handleChange}
        checked={
          !option.value.match(
            this.props.employee.pref_event_email_notification_exclude_match
          )
        }
        label={{ children: option.label }}
      />
    );
  };

  renderOptionGroup = (group, idx) => {
    return (
      <div key={idx}>
        <Header as="h4">{group.header}</Header>
        {group.options.map(this.renderOption)}
        {idx !== OPTIONS.length - 1 && <Divider />}
      </div>
    );
  };

  renderBudgetGroup = () => {
    const checked = !!this.budgetSpendValue();
    return (
      <div key="budget">
        <Header as="h4">Budgets</Header>
        <Form.Field
          control={Checkbox}
          name="pref_notify_location_spend_budget_month_threshold"
          onChange={this.handleChangeBudgetCheckbox}
          checked={checked}
          label={this.renderBudgetSpendLabel()}
        />
        <CategorySpendAlertRequestForm style={{ marginTop: '2rem' }} />
        <Divider />
      </div>
    );
  };

  renderBudgetSpendLabel = () => {
    return (
      <label>
        When office spend reaches&nbsp;
        <Dropdown
          inline
          onChange={this.handleChangeBudgetThreshold}
          disabled={!this.budgetSpendValue()}
          options={BUDGET_SPEND_OPTIONS}
          value={this.budgetSpendValue() || 0.8}
        />
        &nbsp;of monthly budget
      </label>
    );
  };

  render() {
    if (!this.props.employee.pref_event_email_notification_exclude_match) {
      return null;
    }
    return (
      <div id="user-notifications" className="card">
        <Header as="h2" attached="top">
          Notify Me About...
          <Header.Subheader>
            Selected notifications will be sent to your email address.
          </Header.Subheader>
        </Header>
        <Segment attached>
          <Form loading={this.props.isRequesting}>
            {this.props.role.can_manage_insights && this.renderBudgetGroup()}
            {OPTIONS.map(this.renderOptionGroup)}
          </Form>
        </Segment>
      </div>
    );
  }
}

SettingsNotifications.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pref_event_email_notification_exclude_match: PropTypes.string,
    pref_notify_location_spend_budget_month_threshold: PropTypes.number,
  }).isRequired,
  isRequesting: PropTypes.bool,
  actions: PropTypes.shape({
    submitUpdateEmployee: PropTypes.func.isRequired,
  }).isRequired,
  role: PropTypes.shape({
    can_manage_insights: PropTypes.bool.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    employee: state.employees.user,
    isRequesting: state.employees.updating.length > 0,
    role: state.auth.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateEmployee,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.employees.user === next.employees.user &&
    prev.employees.updating === next.employees.updating &&
    prev.auth.role === next.auth.role
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(SettingsNotifications);
