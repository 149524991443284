import * as types from './action-types.js';
import searchProducts from '../lib/search-products.js';
import {
  formatAlgoliaFilters,
  formatInternalFilters,
} from '../lib/formatters.js';
import perf from '../lib/perf.js';
import ReactGA from 'react-ga';
import { CATEGORY_FUNC, VARIABLE_EXEC } from '../strings.js';

export const setProductSort = (sortOptions) => {
  return {
    type: types.SET_PRODUCT_SORT,
    data: sortOptions,
  };
};

export const setProductQuery = (data) => {
  return {
    type: types.SET_PRODUCT_QUERY,
    data: data,
  };
};

export const clearProductFilters = () => {
  return {
    type: types.CLEAR_PRODUCT_FILTERS,
  };
};

export const clearProductQueryResults = () => {
  return {
    type: types.CLEAR_PRODUCT_QUERY_RESULTS,
  };
};

export const successGetLocationProductQueryResults = (data) => {
  return {
    type: types.SUCCESS_GET_LOCATION_PRODUCT_QUERY_RESULTS,
    data: data,
  };
};

export function doGetLocationProductQueryResults(
  localQuery = null,
  options = { autoFilter: true }
) {
  return (dispatch, getState) => {
    dispatch(setProductQuery({ isRequesting: true }));
    const PERF_NAME = 'product_search_results';
    const location = getState().locations.open;
    const query = getState().productQuery;
    const categories = getState().productCategories.items;
    const textQuery = localQuery || query.filters.text;
    let categoryIds = query.filters.categoryIds || [];
    if (
      options.autoFilter &&
      !query.filters.categoryIds.length &&
      (textQuery || '').length
    ) {
      categoryIds = categories
        .filter((cat) =>
          cat.name.toLowerCase().includes(textQuery.toLowerCase())
        )
        .map((cat) => cat.id);
      dispatch(setProductQuery({ filters: { categoryIds } }));
    }
    perf.start(PERF_NAME);
    return searchProducts(location.id, {
      query: textQuery,
      hitsPerPage: 1000,
      filters: formatAlgoliaFilters(location.vendors || [], {
        ...query.filters,
        categoryIds: categoryIds,
      }),
      internalFilters: formatInternalFilters(
        query.filters,
        location.vendors || []
      ),
      internalSort: query.sort,
      clickAnalytics: true,
    })
      .catch(() => {
        return { hits: [] };
      })
      .then((resp) => {
        perf.end(PERF_NAME);
        perf.log(PERF_NAME);
        let items = resp.hits;
        dispatch(
          successGetLocationProductQueryResults({
            results: items,
            resultsQuery: resp.query || '',
            isRequesting: false,
          })
        );
        ReactGA.timing({
          category: CATEGORY_FUNC,
          variable: VARIABLE_EXEC,
          value: perf.duration(PERF_NAME),
          label: PERF_NAME,
        });
      });
  };
}
