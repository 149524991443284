import * as types from '../actions/action-types.js';

const initialQuery = window.location.pathname.match(
  /insights\/budget-codes\/([0-9]+)/
);
export const empty = {
  items: [],
  updating: [],
  creating: [],
  open: { id: 0 },
  requesting: [],
  errors: [],
  openCreateModal: false,
};
const initialOpenId =
  (initialQuery && parseInt(initialQuery[1], 10)) || empty.open.id;
export const initial = Object.assign({}, empty, {
  open: empty.items.filter((t) => t.id === initialOpenId)[0] || {
    id: initialOpenId,
  },
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_BUDGET_CODES:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(
          Object.assign({
            _request: types.GET_CUSTOMER_BUDGET_CODES,
          }),
          action.data
        ),
      });
    case types.SUCCESS_GET_CUSTOMER_BUDGET_CODES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cbc) => cbc._request !== types.GET_CUSTOMER_BUDGET_CODES
        ),
        open: Object.assign(
          {},
          action.data.filter((t) => t.id === state.open.id)[0] || state.open
        ),
        items: action.data,
      });
    case types.ERROR_GET_CUSTOMER_BUDGET_CODES:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.GET_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        requesting: state.requesting.concat(action.data),
      });
    case types.SUCCESS_GET_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        items: state.items
          .filter((cbc) => cbc.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_GET_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.CREATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        creating: state.creating
          .filter((cbc) => cbc._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_CREATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        items: state.items.concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_CREATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        creating: state.creating.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.UPDATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((cbc) => cbc._request !== action.data._request)
          .concat(action.data),
      });
    case types.SUCCESS_UPDATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        items: state.items
          .filter((cbc) => cbc.id !== action.data.id)
          .concat(action.data),
        open:
          state.open.id === action.data.id
            ? Object.assign({}, state.open, action.data)
            : state.open,
      });
    case types.ERROR_UPDATE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        updating: state.updating.filter(
          (cbc) => cbc._request !== action.data._request
        ),
        errors: state.errors.concat(action.data),
      });
    case types.OPEN_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        open:
          state.items.filter((t) => t.id === action.data.id)[0] || empty.open,
      });
    case types.CLOSE_CUSTOMER_BUDGET_CODE:
      return Object.assign({}, state, {
        open: empty.open,
      });
    case types.OPEN_CUSTOMER_BUDGET_CODE_CREATE_MODAL:
      return Object.assign({}, state, {
        openCreateModal: true,
      });
    case types.CLOSE_CUSTOMER_BUDGET_CODE_CREATE_MODAL:
      return Object.assign({}, state, {
        openCreateModal: false,
      });
    case types.STAFF_CLOCKED_OUT:
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
