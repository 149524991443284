import * as storage from '../lib/localstorage.js';
import * as types from '../actions/action-types';
import { STORAGE_STAFF_USER } from '../strings.js';

const initialStorageUser = storage.getItem(STORAGE_STAFF_USER);
export const empty = {
  items: [],
  updating: [],
  creating: [],
  requesting: [],
  errors: [],
  user: {
    id: 0,
    lastFetch: false,
    email: '',
    name: '',
    current_checkins: [],
  },
  open: { id: 0 },
};
export const initial = Object.assign({}, empty, {
  user: initialStorageUser || empty.user,
});

export default (state = initial, action) => {
  switch (action.type) {
    case types.GET_STAFF_SELF:
      return Object.assign({}, state, {
        errors: state.errors.filter(
          (req) => req._request !== types.GET_STAFF_SELF
        ),
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_STAFF_SELF,
            },
            action.data
          )
        ),
      });
    case types.ERROR_STAFF_SELF:
      return Object.assign({}, state, {
        requesting: state.requesting.filter(
          (req) => req._request !== types.GET_STAFF_SELF
        ),
        errors: state.errors.concat(action.data),
      });
    case types.SUCCESS_STAFF_SELF:
      return Object.assign({}, state, {
        user: Object.assign({}, action.data, {
          lastFetch: new Date(),
        }),
      });
    case types.SIGN_OUT:
      return empty;
    default:
      return state;
  }
};
