import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';
import { submitLocationStripeExternalAccount } from '../../actions/locations-actions.js';
import CreditCardInformation from '../credit-card-information.js';
import 'semantic-ui-css/components/form.min.css';

export class StripeBillingInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      _request: Math.random().toString(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location._request !== this.props.location._request &&
      nextProps.location._request === this.state._request
    ) {
      this.props.onSuccess();
    }
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  handleFieldChange = (name) => {
    return (e) => {
      let newState = {};
      newState[name] = e.target.value;
      this.setState(() => {
        return newState;
      });
    };
  };

  handleTokenUpdate = (payment_method_id) => {
    let data = {
      id: this.props.location.id,
      _request: this.state._request,
      stripe: {
        payment_method_id,
      },
    };
    if (this.state.company) data.stripe.company = this.state.company;
    // if (this.state.email) data.stripe.email = this.state.email;
    this.props.actions.submitLocationStripeExternalAccount(data, {
      method: 'PATCH',
    });
    this.props.onSubmit(data);
  };

  handleTokenCreate = (payment_method_id) => {
    let data = {
      id: this.props.location.id,
      _request: this.state._request,
      stripe: {
        // email: this.state.email || this.props.employee.email,
        company: this.state.company || this.props.customer.name,
        payment_method_id,
      },
    };
    this.props.actions.submitLocationStripeExternalAccount(data);
    this.props.onSubmit(data);
  };

  render() {
    return (
      <CreditCardInformation
        loading={this.props.isRequesting}
        renderSubmitButton={false}
        onToken={
          this.props.action === 'create'
            ? this.handleTokenCreate
            : this.handleTokenUpdate
        }>
        <div className="ui form">
          <Form.Group
            style={{
              marginTop: '1rem',
            }}>
            <Form.Input
              label="Company Name"
              name="company"
              placeholder="Company name"
              onChange={this.handleFieldChange('company')}
              width={8}
            />
            {/* <Form.Input
              label="Email Address"
              name="email"
              placeholder="Email address"
              onChange={this.handleFieldChange('email')}
              width={8}
            /> */}
          </Form.Group>
          <Form.Group
            style={{
              marginTop: '2rem',
            }}>
            <Form.Button
              width={4}
              fluid
              primary
              style={{
                backgroundColor: this.props.location.brand_color || '#00a98c',
              }}>
              Save
            </Form.Button>
            <Form.Field width={8} />
            <Form.Button
              width={4}
              basic
              onClick={this.handleCancel}
              floated="right"
              size="mini">
              Cancel
            </Form.Button>
          </Form.Group>
        </div>
      </CreditCardInformation>
    );
  }
}

StripeBillingInfoForm.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  employee: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  isRequesting: PropTypes.bool,
  actions: PropTypes.shape({
    submitLocationStripeExternalAccount: PropTypes.func.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['create', 'update']).isRequired,
};

StripeBillingInfoForm.defaultProps = {
  onCancel: function () {},
  onSubmit: function () {},
  onSuccess: function () {},
};

function mapStateToProps(state) {
  return {
    location: state.locations.open,
    customer: state.customers.open,
    employee: state.employees.user,
    isRequesting: state.locations.requesting.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitLocationStripeExternalAccount,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.requesting === next.locations.requesting &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(StripeBillingInfoForm);
