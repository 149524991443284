import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from '../../lib/formatters.js';
import ScheduledRequisitionItem from './scheduled-requisition-item.js';
import { DAY_MILLIS, SORT_BY_ID, START_OF_DAY } from '../../strings.js';
import './scheduled-requisitions.css';

export class ScheduledRequisitions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupedScheduledRequisitions: this.buildState(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => {
      return {
        groupedScheduledRequisitions: this.buildState(nextProps),
      };
    });
  }

  groupDimensions(items) {
    return items.reduce(this.groupByDay, {});
  }

  groupByDay(acc, val) {
    const toTheDay = Math.round(
      new Date(val.expected_at).getTime() / DAY_MILLIS
    );
    (acc[toTheDay] = acc[toTheDay] || []).push(val);
    return acc;
  }

  buildState = (props) => {
    return this.groupDimensions(props.scheduledRequisitions);
  };

  renderItem = (scheduledRequisition, i) => {
    return (
      <ScheduledRequisitionItem
        key={i}
        scheduledRequisition={scheduledRequisition}
        isRequesting={this.props.isRequesting}
      />
    );
  };

  renderDayGroup = (key) => {
    let scheduledRequisitions = this.state.groupedScheduledRequisitions[key];
    return (
      <div className="scheduled-requisition-date" key={key}>
        <span className="scheduled-requisition-date-header site-h3">
          {formatDate(new Date(key * DAY_MILLIS))}
        </span>
        {scheduledRequisitions.sort(SORT_BY_ID).map(this.renderItem)}
      </div>
    );
  };

  render() {
    const sortedKeys = Object.keys(this.state.groupedScheduledRequisitions);
    return (
      <div className="scheduled-requisitions">
        {sortedKeys.length > 0 && (
          <div className="scheduled-requisitions-list">
            <Link to="/supplies/orders" className="scheduled-requisitions-past">
              ↑ View your past orders
            </Link>

            <h2 className="scheduled-requisitions-header">Upcoming Orders</h2>

            {sortedKeys.map(this.renderDayGroup)}
          </div>
        )}
        {!sortedKeys.length && (
          <div className="scheduled-requisitions-list empty-state">
            <div className="scheduled-requisitions">
              <div className="scheduled-requisition-date">
                <span className="scheduled-requisition-date-header site-h3">
                  Today
                </span>
                <div className="scheduled-requisition-grouped">
                  <p>You have no pending orders.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ScheduledRequisitions.propTypes = {
  scheduledRequisitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      expected_at: PropTypes.string.isRequired,
      expiration_at: PropTypes.string.isRequired,
      age_confirmation: PropTypes.bool.isRequired,
      requisition_id: PropTypes.number,
      approved_at: PropTypes.string,
      approver_id: PropTypes.integer,
      approver_type: PropTypes.string,
      instructions: PropTypes.string,
      shipping_address: PropTypes.string,
      shipping_address_number: PropTypes.string,
      shipping_business: PropTypes.string,
      shipping_care: PropTypes.string,
      shipping_city: PropTypes.string,
      shipping_name: PropTypes.string,
      shipping_state: PropTypes.string,
      shipping_zip: PropTypes.string,
    })
  ).isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

ScheduledRequisitions.defaultProps = {
  isRequesting: true,
};

const FILTER_EXPECTED_BEFORE_TODAY = (sr) => {
  return new Date(sr.expected_at) >= START_OF_DAY();
};

const FILTER_EXPIRE_AFTER_TODAY = (sr) => {
  return new Date(sr.expiration_at) >= START_OF_DAY();
};

const FILTER_EXPIRED = (sr) => {
  if (sr.approved_at) {
    return FILTER_EXPECTED_BEFORE_TODAY(sr);
  }
  return FILTER_EXPIRE_AFTER_TODAY(sr);
};

const FILTER_NON_ARCHIVED = (sr) => {
  return sr.archived === false;
};

function mapStateToProps(state) {
  return {
    scheduledRequisitions: state.scheduledRequisitions.items
      .filter(FILTER_NON_ARCHIVED)
      .filter(FILTER_EXPIRED),
  };
}

function areStatesEqual(prev, next) {
  return prev.scheduledRequisitions.items === next.scheduledRequisitions.items;
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  ScheduledRequisitions
);
