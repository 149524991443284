import { trackFetchError } from '../lib/analytics.js';
import * as types from './action-types';
import { fetchError } from './action-helpers.js';
import fetch from '../lib/hmac-fetch.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings';
import { enqueueConfirmation } from '../actions/confirmations-actions.js';

export const successEmployeeStipends = (data) => {
  return {
    type: types.SUCCESS_EMPLOYEE_STIPENDS,
    data,
  };
};

export const errorEmployeeStipends = (data) => {
  return {
    type: types.ERROR_EMPLOYEE_STIPENDS,
    data,
  };
};

export const doCloseEmployeeStipends = () => {
  return {
    type: types.CLOSE_EMPLOYEE_STIPENDS,
  };
};

const getEmployeeStipends = (data) => {
  return {
    type: types.GET_EMPLOYEE_STIPENDS,
    data,
  };
};

const openEmployeeStipends = (data) => {
  return {
    type: types.OPEN_EMPLOYEE_STIPENDS,
    data,
  };
};

const postEmployeeStipends = (data) => {
  return {
    type: types.POST_EMPLOYEE_STIPENDS,
    data,
  };
};

const postEmployeeStipend = (data) => {
  return {
    type: types.POST_EMPLOYEE_STIPEND,
    data,
  };
};

const successPostEmployeeStipend = (data) => {
  return {
    type: types.SUCCESS_POST_EMPLOYEE_STIPEND,
    data,
  };
};

const errorPostEmployeeStipend = (data) => {
  return {
    type: types.ERROR_POST_EMPLOYEE_STIPEND,
    data,
  };
};

const deleteEmployeeStipend = () => {
  return {
    type: types.DELETE_EMPLOYEE_STIPEND,
  };
};

const successDeleteEmployeeStipend = (data) => {
  return {
    type: types.SUCCESS_DELETE_EMPLOYEE_STIPEND,
    data,
  };
};

const errorDeleteEmployeeStipend = () => {
  return {
    type: types.ERROR_DELETE_EMPLOYEE_STIPEND,
  };
};

// thunks

export const doOpenEmployeeStipends = (data) => {
  return (dispatch) => {
    dispatch(openEmployeeStipends(data));
    dispatch(
      doGetEmployeeStipends({ id: data.customer_id, stipend_id: data.id })
    );
  };
};

export function doGetEmployeeStipends(data) {
  return (dispatch) => {
    dispatch(getEmployeeStipends());

    return fetch(
      `${ADMIN_V1_URL}/customers/${data.id}/employee_stipends?stipend_id=${data.stipend_id}`
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data: {},
            message: 'Get employee stipends',
          });
        }

        return response.json();
      })
      .then((body) => {
        dispatch(successEmployeeStipends(body || []));
      })
      .catch((error) => {
        dispatch(errorEmployeeStipends(error));
        trackFetchError(error);
      });
  };
}

export function doPostEmployeeStipends(data) {
  return (dispatch, getState) => {
    dispatch(postEmployeeStipends(data));
    return Promise.all(
      data.items.map((d) => {
        return doPostEmployeeStipend(d, data.id)(dispatch, getState);
      })
    );
  };
}

export function doPostEmployeeStipend(data, id) {
  return (dispatch) => {
    dispatch(postEmployeeStipend(data));
    return fetch(`${ADMIN_V1_URL}/customers/${id}/employee_stipends`, {
      method: 'POST',
      headers: {
        ...HEADERS_JSON,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data: {},
            message: 'assigning employee.',
          });
        }

        return response.json();
      })
      .then((body) => {
        dispatch(successPostEmployeeStipend(body));
        dispatch(
          doGetEmployeeStipends({ id: id, stipend_id: data.stipend_id })
        );
      })
      .catch((error) => {
        dispatch(errorPostEmployeeStipend(error));
        dispatch(
          enqueueConfirmation({
            type: 'error',
            message: `${data.email} couldn't be assigned. Please check the email and try again!`,
          })
        );
        trackFetchError(error);
      });
  };
}

export function doDeleteEmployeeStipend(stipendId, customerId) {
  return (dispatch) => {
    dispatch(deleteEmployeeStipend());
    return fetch(
      `${ADMIN_V1_URL}/customers/${customerId}/employee_stipends/${stipendId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data: {},
            message: 'deleting employee.',
          });
        }

        return { id: stipendId };
      })
      .then((body) => {
        dispatch(successDeleteEmployeeStipend(body));
        dispatch(
          enqueueConfirmation({
            type: 'success',
            message: 'Employee stipend was deleted!',
          })
        );
      })
      .catch((error) => {
        dispatch(errorDeleteEmployeeStipend());
        trackFetchError(error);
      });
  };
}
