import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch';
import { trackFetchError } from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { ADMIN_V1_URL, HEADERS_JSON } from '../strings.js';

export const getCredits = (data) => ({
  type: types.GET_CREDITS,
  data,
});

export const getCredit = (data) => ({
  type: types.GET_CREDIT,
  data,
});

export const successGetCredits = (data) => ({
  type: types.SUCCESS_GET_CREDITS,
  data,
});

export const successGetCredit = (data) => ({
  type: types.SUCCESS_GET_CREDIT,
  data,
});

export const updateCredit = (data) => ({
  type: types.UPDATE_CREDIT,
  data,
});

export const errorGetCredits = (data) => ({
  type: types.ERROR_GET_CREDITS,
  data,
});

export const errorGetCredit = (data) => ({
  type: types.ERROR_GET_CREDIT,
  data,
});

export const successUpdateCredit = (data) => {
  return {
    type: types.SUCCESS_UPDATE_CREDIT,
    data,
  };
};

export const errorUpdateCredit = (data) => {
  return {
    type: types.ERROR_UPDATE_CREDIT,
    data,
  };
};

export const successDeleteCredit = (data) => {
  return {
    type: types.SUCCESS_DELETE_CREDIT,
    data,
  };
};

export const doGetCredits = (data = {}) => {
  return (dispatch, getState) => {
    const {
      locations: {
        open: { id },
      },
    } = getState();
    data.location_id = id;
    data._request = data._request || requestID();
    dispatch(getCredits());
    return fetch(`${ADMIN_V1_URL}/locations/${data.location_id}/credits`, {
      method: 'GET',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data, message: 'Get credits' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetCredits(body.data));
      })
      .catch((error) => {
        dispatch(errorGetCredits(error));
        trackFetchError(error);
      });
  };
};

export const doGetCredit = (data) => {
  return (dispatch, getState) => {
    const {
      locations: {
        open: { id },
      },
    } = getState();
    data.location_id = id;
    data._request = data._request || requestID();
    dispatch(getCredit());
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/credits/${data.id}`,
      {
        method: 'GET',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Get credit ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetCredit(body.data));
      })
      .catch((error) => {
        dispatch(errorGetCredit(error));
        trackFetchError(error);
      });
  };
};

export const doUpdateCredit = (data) => {
  data._request = data._request || requestID();
  return (dispatch, getState) => {
    dispatch(getCredit(data));
    const {
      locations: {
        open: { id },
      },
    } = getState();
    return fetch(`${ADMIN_V1_URL}/locations/${id}/credits/${data.id}`, {
      method: 'PATCH',
      headers: {
        ...HEADERS_JSON,
        'X-Request-ID': data._request,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({
            response,
            data,
            message: 'Update credit ' + data.id,
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successUpdateCredit(
            Object.assign(body, {
              _request: data._request,
            })
          )
        );
      })
      .catch((error) => {
        dispatch(errorUpdateCredit(error));
        trackFetchError(error);
      });
  };
};
