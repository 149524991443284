import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import browserHistory from '../../lib/history.js';
import ProductSearch from '../products/search.js';
import Cart from '../requisitions/cart.js';
import ClickOut from '../click-out.js';
import CleaningDetailsDateSelect from '../../components/cleanings/details-date-select.js';
import Announcement from './announcement.js';
import CartIcon from './../../imgs/icon-cart.js';
import NavbarStipend from './navbar-stipend.js';
import { PRODUCT_CATEGORY } from '../../strings.js';
import { trackInterfaceToggleCart } from '../../lib/analytics.js';
import { setCartOpen } from '../../actions/application-actions.js';
import './navbar-attendant.css';

export class NavBarAttendant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
      isAttendantPage: false,
      showSupplyGroceryContent: true,
    };
    this.urlListener = browserHistory.listen(this.evaluateUrl);
  }

  componentDidMount() {
    if (window.location.href.match(/office-attendant/) != null) {
      this.setState({ isAttendantPage: true });
    }
    this.evaluateUrl(window.location);
  }

  componentWillUnmount() {
    this.urlListener();
  }

  evaluateUrl = (location) => {
    if (location.pathname.match(/settings|insights|invoices/)) {
      this.setState({ showSupplyGroceryContent: false });
    } else {
      this.setState({ showSupplyGroceryContent: true });
    }
  };

  handleSearchSubmit = (searchData) => {
    if (searchData.suggestion_type === PRODUCT_CATEGORY) {
      browserHistory.push('/supplies/catalog/' + searchData.suggestion_id);
    } else {
      browserHistory.push(
        '/supplies/catalog-search?q=' +
          encodeURIComponent(searchData.text_searched)
      );
    }
  };

  handleOpenCart = () => {
    const tempShowCart = this.state.showCart;
    this.toggleCart();
    trackInterfaceToggleCart({ state: tempShowCart ? 'close' : 'open' });
  };

  closeNavCartAndOpenRegularCart = () => {
    this.props.actions.setCartOpen(true);
    this.handleOpenCart();
  };

  toggleCart = () => {
    this.setState((previousState) => {
      return {
        showCart: !previousState.showCart,
      };
    });
  };

  render() {
    return (
      <div>
        <div
          ref="wrapper"
          className="well-container navbar-attendant hide-when-printing">
          <div className="nav-wrapper">
            <div className="nav-lower">
              {!this.state.isAttendantPage &&
                this.state.showSupplyGroceryContent && (
                  <Fragment>
                    <ProductSearch
                      onSearchSubmit={this.handleSearchSubmit}
                      placeholder="What can we help you find?"
                    />
                    <NavbarStipend />
                  </Fragment>
                )}
              {this.state.isAttendantPage && (
                <div className="nav-daily-planner">
                  <p className="daily-palnner-for">
                    <span className="hide-mobile">Daily</span> Planner For
                  </p>
                  <CleaningDetailsDateSelect />
                </div>
              )}
              {this.props.services.match(/procurement/) &&
                !this.state.isAttendantPage &&
                this.state.showSupplyGroceryContent && (
                  <button
                    className="button-cart button-with-icon brand-color-override"
                    type="button"
                    onClick={this.handleOpenCart}>
                    <p className="cart-quantity">
                      {this.props.cartQuantity.toLocaleString()}
                    </p>
                    <p className="cart-label">
                      <img
                        src={CartIcon({
                          color: this.props.location.brand_color,
                        })}
                        alt="Cart"
                        className="cart-icon"
                        height="30"
                        width="30"
                      />
                      <span>Cart</span>
                    </p>
                  </button>
                )}
              {this.state.showCart && (
                <div className="cart-container">
                  <div className="cart">
                    <Cart
                      hideable={true}
                      hideCart={this.handleOpenCart}
                      showCart={this.closeNavCartAndOpenRegularCart}
                      canSwitchCarts={true}
                      parentComponent={'fly-out cart'}
                    />
                  </div>
                </div>
              )}
              {this.state.showCart && (
                <ClickOut
                  isOpen={this.state.showCart}
                  wrappingClassNames={[
                    'cart-container',
                    'cart',
                    'modal-product',
                  ]}
                  onChange={this.handleOpenCart}
                />
              )}
            </div>
          </div>
        </div>
        <Announcement />
      </div>
    );
  }
}

NavBarAttendant.propTypes = {
  online: PropTypes.bool,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  cartQuantity: PropTypes.number.isRequired,
  services: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    online: state.application.online,
    location: state.locations.open,
    cartQuantity: (state.carts.open.product_requisitions || []).reduce(
      (acc, val) => {
        return acc + val.quantity;
      },
      0
    ),
    services: state.locations.open.services || '',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setCartOpen,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.carts.open.product_requisitions ===
      next.carts.open.product_requisitions &&
    prev.application.online === next.application.online &&
    prev.locations.open === next.locations.open
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(NavBarAttendant);
