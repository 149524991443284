import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Button } from 'semantic-ui-react';
import {
  submitDeleteTask,
  closeTaskModal,
} from '../../actions/task-actions.js';
import './modal-common.css';
import './modal-confirmation-delete-task.css';

export class ModalConfirmationDeleteTask extends React.PureComponent {
  handleCloseModal = () => {
    this.props.actions.closeTaskModal();
  };

  handleDeleteTask = () => {
    this.props.actions.submitDeleteTask(this.props.task);
    this.handleCloseModal();
  };

  render() {
    if (
      !this.props.task.id ||
      this.props.task.id === 'new' ||
      !this.props.task.confirm_delete
    ) {
      return null;
    }
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={!!this.props.task.id}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
        className={{
          base: 'modal modal-confirmation-delete modal-confirmation-delete-task',
          afterOpen: 'modal_after-open',
          beforeClose: 'modal_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay modal-confirmation-delete-overlay',
          afterOpen: 'modal-overlay_after-open',
          beforeClose: 'modal-overlay_before-close',
        }}
        style={{
          overlay: {},
          content: {
            width: 'auto',
            height: '100%',
          },
        }}
        contentLabel="Delete Task Confirmation Modal"
      >
        <button
          className="close-icon"
          type="button"
          onClick={this.handleCloseModal}
        >
          &times;
        </button>
        <div className="wrapper">
          <h2 className="modal-confirmation-delete-title site-h2">
            Are you sure you want to throw this away?
          </h2>
          <h5 className="modal-confirmation-delete-subheader">
            This To&ndash;Do will be thrown away forever.
          </h5>
          <div className="confirmation-buttons flex-wrapper">
            <Button.Group>
              <Button
                className="cancel-delete-task"
                onClick={this.handleCloseModal}
              >
                Cancel
              </Button>
              <Button.Or />
              <Button
                className="confirm-delete-task"
                color="red"
                onClick={this.handleDeleteTask}
                disabled={this.props.isRequesting}
              >
                Throw Away
              </Button>
            </Button.Group>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalConfirmationDeleteTask.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    task: state.tasks.open,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitDeleteTask,
        closeTaskModal,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalConfirmationDeleteTask);
