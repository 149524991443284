import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const BrandColorOverride = (props) => {
  if (!props.location.brand_color) return null;

  return (
    <style>
      .modal-shipment .actions-dropdown, .purchase-order-item .actions-dropdown,
      .product-stock-action .ui.menu.secondary.vertical .item.active,
      .catalog-toggle__content.activated i, .scheduled-name-form i,
      .cleaning-task-form-radio-container label:nth-child(2),
      .cleaning-task-form-radio-container.recurring label:nth-child(3),
      .nav-daily-planner .select select, .cart-sub-pref .ui.dropdown > .text,
      .product-similars-action table td a h4 .content, .product-similars-action
      .ui.list>.item .content a .header, .ui.button.secondary,
      .ui.button.tertiary, .ui.secondary.button.white-background, .ui.breadcrumb
      a, .ui.breadcrumb a:hover, .ui.checkbox input:checked:focus~.box:after,
      .ui.checkbox input:checked:focus~label:after, .ui.checkbox
      input:not([type=radio]):indeterminate:focus~.box:after, .ui.checkbox
      input:not([type=radio]):indeterminate:focus~label:after, .ui.checkbox
      input:checked~.box:after, .ui.checkbox input:checked~label:after,
      .ui.inline.dropdown, .ui.success.message, a
      {`{color: ${props.location.brand_color}}`}
      .product-stock-action i.green.icon, .brand-color-override
      {`{color: ${props.location.brand_color} !important}`}
      .brand-color-override:hover
      {`{color: ${props.location.brand_color} !important}`}
      .ui.menu.budget-code-links .item.active::after,
      .navbar-side__menu-item--current-section:after, .introjs-tooltipbuttons
      a.introjs-nextbutton, .ui.button.primary, .ui.primary.button:hover,
      .ui.primary.button:focus, .ui.secondary.button.active,
      .ui.secondary.button:hover, .ui.secondary.button:focus,
      .ui.secondary.button.white-background:hover,
      .ui.secondary.button.white-background:focus
      {`{background-color: ${props.location.brand_color}}`}
      .brand-bg-color-override
      {`{background-color: ${props.location.brand_color} !important}`}
      input[type="text"]:focus, input [type="email"]:focus, input
      [type="password"]:focus, input[type="number"]:focus,
      .ui.input>input:focus, .ui.form textarea:focus, .ui.form
      input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form
      input[type=datetime-local]:focus, .ui.form input[type=email]:focus,
      .ui.form input[type=file]:focus, .ui.form input[type=number]:focus,
      .ui.form input[type=password]:focus, .ui.form input[type=search]:focus,
      .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form
      input[type=time]:focus, .ui.form input[type=url]:focus, textarea:focus,
      .ui.secondary.button.white-background, .ui.buttons .or.page-bg:before,
      .ui.checkbox input:checked:focus~.box:before, .ui.checkbox
      input:checked:focus~label:before, .ui.checkbox
      input:not([type=radio]):indeterminate:focus~.box:before, .ui.checkbox
      input:not([type=radio]):indeterminate:focus~label:before, .calendar
      button.react-calendar__month-view__days__day.selected,
      .product-stock-action .ui.menu.secondary.vertical .item.active,
      .cleaning-task-form-radio-container label:nth-child(2),
      .cleaning-task-form-radio-container.recurring label:nth-child(3),
      .nav-daily-planner .select select, .ui.button.secondary
      {`{border-color: ${props.location.brand_color}}`}
      .brand-border-color-override
      {`{border-color: ${props.location.brand_color} !important}`}
      .calendar button.react-calendar__month-view__days__day.selected
      {`{box-shadow: inset 0 0 0 2px ${props.location.brand_color}}`}
      .ui.success.message
      {`{border-top: 1px solid ${props.location.brand_color}}`}
      .hello span
      {`{color: ${props.location.brand_color}}`}
    </style>
  );
};

BrandColorOverride.propTypes = {
  location: PropTypes.shape({
    brand_color: PropTypes.string,
  }).isRequired,
};

BrandColorOverride.defaultProps = {};

function mapStateToProps(state) {
  let location = state.locations.open;
  if (
    !location.id &&
    state.onboarding.employee &&
    state.onboarding.employee.locations
  ) {
    location = state.onboarding.employee.locations[0] || location;
  }
  return {
    location,
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.onboarding.employee === next.onboarding.employee
  );
}

export default connect(mapStateToProps, null, null, { areStatesEqual })(
  BrandColorOverride
);
