import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { formatDateTime } from '../../lib/formatters.js';
import { formatTrackingLocation } from '../../helpers/shipment-helpers.js';
import 'semantic-ui-css/components/table.min.css';

export default class TrackingUpdates extends React.PureComponent {
  renderUpdate = (update) => {
    return (
      <Table.Row key={update.datetime}>
        <Table.Cell style={{ color: '#999DAA' }}>
          {formatDateTime(update.datetime)}
        </Table.Cell>
        <Table.Cell>{update.message}</Table.Cell>
        <Table.Cell style={{ color: '#999DAA' }}>
          {formatTrackingLocation(update.tracking_location)}
        </Table.Cell>
      </Table.Row>
    );
  };

  sorter = (a, b) => {
    return a.datetime < b.datetime ? 1 : -1;
  };

  render() {
    let updates = (this.props.shipment.tracking_updates || []).sort(
      this.sorter
    );
    if (updates.length === 0) return null;
    return (
      <div className="shipment-tracking-updates">
        <Table compact basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                Tracking Updates via {this.props.shipment.carrier}
              </Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{updates.map(this.renderUpdate)}</Table.Body>
        </Table>
      </div>
    );
  }
}

TrackingUpdates.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tracking_updates: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        datetime: PropTypes.string.isRequired,
        tracking_location: PropTypes.shape({
          zip: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
        }).isRequired,
      })
    ),
  }).isRequired,
};
