import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { formatDateTime, formatEmployeeName } from '../../lib/formatters.js';
import 'semantic-ui-css/components/list.min.css';
import './approver-data.css';

export default class ApproverData extends React.PureComponent {
  displayOrderedBy = () => {
    if (!this.props.scheduledRequisition.approver.id) {
      return 'OfficeLuv';
    }
    return formatEmployeeName(this.props.scheduledRequisition.approver);
  };

  isApprovedFuture = () => {
    return new Date() < new Date(this.props.scheduledRequisition.approved_at);
  };

  isPlaceholder = (approver) => {
    if (!approver) return true;
    return approver.avatar_attachment && !approver.avatar_attachment.original;
  };

  render() {
    const sr = this.props.scheduledRequisition;
    return (
      <List horizontal>
        <List.Item>
          <List.Content>
            <List.Header className="light-gray-text">
              {this.isApprovedFuture() && 'Will be '}
              Approved
            </List.Header>
            {formatDateTime(sr.approved_at)}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header className="light-gray-text">By</List.Header>
            {this.displayOrderedBy()}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header className="light-gray-text">To Be Ordered</List.Header>
            {formatDateTime(sr.expiration_at)}
          </List.Content>
        </List.Item>
      </List>
    );
  }
}

ApproverData.propTypes = {
  scheduledRequisition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    expected_at: PropTypes.string.isRequired,
    expiration_at: PropTypes.string.isRequired,
    age_confirmation: PropTypes.bool.isRequired,
    requisition_id: PropTypes.number,
    instructions: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_number: PropTypes.string,
    shipping_business: PropTypes.string,
    shipping_care: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_state: PropTypes.string,
    shipping_zip: PropTypes.string,
    approver_id: PropTypes.number,
    approver_type: PropTypes.string,
    approver: PropTypes.shape({
      id: PropTypes.number.isRequired,
      avatar_attachment: PropTypes.shape({
        medium: PropTypes.string,
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};
