import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch';
import { fetchError } from './action-helpers.js';
import { trackFetchError, trackApplicationUpdate } from '../lib/analytics.js';
import { ADMIN_V1_URL } from '../strings.js';
import { doGetFeatureTours } from '../ducks/feature-tours.js';
import { doGetBannerItems } from '../ducks/banner-items.js';
import { doGetFeatureTourAttempts } from '../ducks/feature-tour-attempts.js';
import {
  doGetPunchoutSession,
  doUpdatePunchoutSession,
} from '../ducks/punchout-sessions.js';
import { doGetEmployeeSelf } from './employee-actions.js';
import { doInitializeLocation } from './locations-actions.js';
import { doGetStaffSelf } from './staff-actions.js';
import { findStaffLocation } from '../helpers/staff-helpers.js';
import { doGetProductStockNotifications } from '../ducks/product-stock-notifications.js';
import cookies from '../lib/encrypted-cookies.js';
import { CART_SHOW_COOKIE } from '../strings.js';

// Actions
export const getAppConfig = () => {
  return {
    type: types.GET_APP_CONFIG,
  };
};

export const successGetAppConfig = (data) => {
  return {
    type: types.SUCCESS_GET_APP_CONFIG,
    data,
  };
};

export const errorGetAppConfig = (data) => {
  return {
    type: types.ERROR_GET_APP_CONFIG,
    data,
  };
};

export const doSetAppVersion = (version) => {
  return {
    type: types.NEW_APP_VERSION,
    data: version,
  };
};

export const setActivePath = (activePath) => {
  return {
    type: types.SET_ACTIVE_PATH,
    data: {
      activePath,
    },
  };
};

export const setOnlineStatus = (online) => {
  return {
    type: types.SET_ONLINE_STATUS,
    data: {
      online,
    },
  };
};

export const setCartOpen = (bool) => {
  return (dispatch) => {
    if (bool) {
      cookies.set(CART_SHOW_COOKIE, true, { expires: 100 });
    } else {
      cookies.remove(CART_SHOW_COOKIE);
    }
    dispatch({
      type: types.SET_CART_OPEN,
      data: bool,
    });
  };
};

export const setAppInitialized = (data) => ({
  type: types.SET_APP_INITIALIZED,
  data,
});

export const setInLimitedViewingModeByLocation = (location) => {
  return (dispatch, getState) => {
    let bool = false;
    if (location.pref_enable_limited_catalog) {
      bool = !getState().auth.role.can_manage_location_catalog;
    }
    return dispatch(setInLimitedViewingMode(bool));
  };
};

export const setInLimitedViewingMode = (bool) => ({
  type: types.SET_IN_LIMITED_VIEWING_MODE,
  data: bool,
});

export const dismissAnnouncement = () => {
  return {
    type: types.DISMISS_ANNOUNCEMENT,
  };
};

export const doInitializeApp = () => {
  return (dispatch, getState) => {
    const { auth, locations, punchoutSessions } = getState();
    const location = locations.open;
    // dispatch app data
    dispatch(setAppInitialized(true));
    dispatch(doGetAppConfig(location.id));
    dispatch(doGetFeatureTours());
    dispatch(doGetBannerItems());
    // dispatch location data
    if (location.id) dispatch(doInitializeLocation(location));
    // dispatch employee data
    if (auth.actor.type.match(/employee/i)) {
      doGetEmployeeSelf({ bubble: true })(dispatch, getState).then(
        (employee) => {
          dispatch(
            doGetProductStockNotifications({
              employee_id: employee.id,
            })
          );
          if (!location.id && employee.locations.length) {
            dispatch(doInitializeLocation(employee.locations[0]));
          }
        }
      );
      dispatch(doGetFeatureTourAttempts({ id: auth.actor.id }));
      if (punchoutSessions.open.access_token) {
        doGetPunchoutSession(
          {
            employee_id: auth.actor.id,
            access_token: punchoutSessions.open.access_token,
          },
          { bubble: true }
        )(dispatch, getState).then((body) => {
          if (!body) return;

          dispatch(
            doUpdatePunchoutSession({
              id: punchoutSessions.open.id,
              location_id: location.id,
            })
          );
        });
      }
    }
    // dispatch staff data
    if (auth.actor.type.match(/staff/i)) {
      doGetStaffSelf({ bubble: true })(dispatch, getState).then((staff) => {
        if (!staff) return;

        const staffLoc = findStaffLocation(staff);
        if (!location.id && staffLoc) {
          dispatch(doInitializeLocation(staffLoc));
        }
      });
    }
  };
};

export function doGetAppConfig(locationId) {
  return (dispatch) => {
    dispatch(getAppConfig());
    return fetch(`${ADMIN_V1_URL}/configs/dash?location_id=${locationId}`, {
      method: 'GET',
      cache: 'reload',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw fetchError({ response, data: {}, message: 'Get app config' });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(successGetAppConfig(body.data));
      })
      .catch((error) => {
        dispatch(errorGetAppConfig(error));
        trackFetchError(error);
      });
  };
}

export function updateAppVersion(version) {
  if (window.ODEBUG) {
    window.alert(`TESTING ONLY: Updating the app version to ${version}`);
  }
  trackApplicationUpdate({ version });
  window.location.reload(true);
  return {
    type: types.NEW_APP_VERSION,
    data: version,
  };
}
