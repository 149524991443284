import React from 'react';
import PropTypes from 'prop-types';
import ExpandableText from './expandable-text.js';

export default class ExpandableTextDefault extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      buttonText: '+ Read more',
    };
  }

  onExpand = () => {
    this.setState((prevState) => {
      return {
        expanded: !prevState.expanded,
        buttonText: prevState.expanded ? '+ Read more' : '- Read less',
      };
    });
  };

  render() {
    return (
      <ExpandableText
        onExpand={this.onExpand}
        expanded={this.state.expanded}
        buttonText={this.state.buttonText}
        text={this.props.text}
        height={this.props.height}
      />
    );
  }
}

ExpandableTextDefault.propTypes = {
  height: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ExpandableTextDefault.defaultProps = {
  height: '140px',
};
