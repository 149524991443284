import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Checkbox,
  Form,
  Header,
  Segment,
  Table,
  Grid,
  Item,
  Message,
} from 'semantic-ui-react';
import { submitUpdateLocation } from '../../actions/locations-actions.js';
import 'semantic-ui-css/components/transition.min.css';
import 'semantic-ui-css/components/table.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/item.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/segment.min.css';
import './invoicing-preferences.css';

let locationGroupingOptions = [
  { key: 0, text: 'Invoice this office individually', value: false },
  { key: 1, text: 'Combine all locations on one invoice', value: true },
];
let frequencyOptions = [
  { key: 0, text: 'Invoice weekly', value: 'weekly' },
  { key: 1, text: 'Invoice monthly', value: 'monthly' },
];
let orderGroupingOptions = [
  { key: 0, text: 'Group orders each invoice period', value: 'interval' },
  {
    key: 1,
    text: 'Separate orders into separate invoices',
    value: 'requisition',
  },
];
let lineGroupingOptions = [
  { key: 0, text: 'Invoice one line per order', value: 'requisition' },
  {
    key: 1,
    text: 'Combine orders on an invoice',
    value: 'combine_requisitions',
  },
  { key: 2, text: 'Invoice one line per category', value: 'budget_code' },
  {
    key: 3,
    text: 'Invoice one line per category per order',
    value: 'requisition_budget_code',
  },
  {
    key: 4,
    text: 'Invoice one line per line item in each order',
    value: 'product',
  },
];

let requisitions = [
  {
    id: 178,
    shipping_price: 12.99,
    product_requisitions: [
      {
        id: 1,
        product: { name: 'Popcorn' },
        qty: 2,
        price: 1.99,
        customer_budget_code: { name: 'Food & Beverage' },
      },
      {
        id: 2,
        product: { name: 'Case of Bananas' },
        qty: 2,
        price: 31.99,
        customer_budget_code: { name: 'Food & Beverage' },
      },
      {
        id: 3,
        product: { name: 'Clear plastic forks' },
        qty: 1,
        price: 12.99,
        customer_budget_code: { name: 'Office Supplies' },
      },
    ],
  },
  {
    id: 1934,
    shipping_price: 0,
    product_requisitions: [
      {
        id: 11,
        product: { name: 'Cashews, Salted, 1.5 Ounce Bags' },
        qty: 2,
        price: 3.99,
        customer_budget_code: { name: 'Food & Beverage' },
      },
      {
        id: 21,
        product: { name: 'Bowls, Biodegradable, 240 count' },
        qty: 2,
        price: 50.99,
        customer_budget_code: { name: 'Kitchen & Wellness' },
      },
      {
        id: 31,
        product: { name: 'Clear plastic forks' },
        qty: 1,
        price: 12.99,
        customer_budget_code: { name: 'Office Supplies' },
      },
    ],
  },
];

function reducePriceQty(acc, val) {
  return acc + val.price * val.qty;
}

function flatten(acc, val) {
  return acc.concat(val);
}

export class InvoicingPreferences extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pref_billing_line_item_grouping:
        props.openLocation.pref_billing_line_item_grouping,
      pref_billing_invoice_grouping:
        props.openLocation.pref_billing_invoice_grouping,
      pref_billing_combine_customer_locations:
        props.openLocation.pref_billing_combine_customer_locations,
      pref_billing_interval: props.openLocation.pref_billing_interval,
    };
  }

  getInvoiceLineGroups = () => {
    if (this.state.pref_billing_invoice_grouping === 'interval') {
      return [this.getInvoiceLines(requisitions)];
    }
    return requisitions.map((r) => {
      return this.getInvoiceLines([r]);
    });
  };

  getInvoiceLines = (reqs) => {
    switch (this.state.pref_billing_line_item_grouping) {
      case 'requisition':
        return this.getInvoiceLinesByRequisition(reqs);
      case 'combine_requisitions':
        return this.getInvoiceLinesByCombined(reqs);
      case 'budget_code':
        return this.getInvoiceLinesByBudgetCode(reqs);
      case 'requisition_budget_code':
        return this.getInvoiceLinesByRequisitionBudgetCode(reqs);
      case 'product':
        return this.getInvoiceLinesByProduct(reqs);
      default:
        return [];
    }
  };

  getInvoiceLinesByRequisition = (reqs) => {
    return reqs.map((r) => {
      return {
        desc: `Order #${r.id}`,
        price:
          r.shipping_price + r.product_requisitions.reduce(reducePriceQty, 0),
        qty: 1,
      };
    });
  };

  getInvoiceLinesByCombined = (reqs) => {
    let total = reqs.reduce((acc, r) => {
      return (
        acc +
        r.shipping_price +
        r.product_requisitions.reduce(reducePriceQty, 0)
      );
    }, 0);
    return [
      {
        desc: `Orders #${reqs.map((r) => r.id).join(', ')}`,
        price: total,
        qty: 1,
      },
    ];
  };

  getInvoiceLinesByBudgetCode = (reqs) => {
    let codes = {};
    reqs.map((r) => {
      return r.product_requisitions.map((pr) => {
        let name = pr.customer_budget_code.name;
        codes[name] = codes[name] || 0;
        return (codes[name] += pr.qty * pr.price);
      });
    });
    return Object.keys(codes)
      .map((k) => {
        return {
          desc: k,
          price: codes[k],
          qty: 1,
        };
      })
      .concat(this.getInvoiceLinesByFee(reqs));
  };

  getInvoiceLinesByRequisitionBudgetCode = (reqs) => {
    let codes = {};
    reqs.map((r) => {
      return r.product_requisitions.map((pr) => {
        let name = `Order #${r.id}: ${pr.customer_budget_code.name}`;
        codes[name] = codes[name] || 0;
        return (codes[name] += pr.qty * pr.price);
      });
    });
    return Object.keys(codes)
      .map((k) => {
        return {
          desc: k,
          price: codes[k],
          qty: 1,
        };
      })
      .concat(reqs.map((r) => this.getInvoiceLinesByFee([r])))
      .reduce(flatten, []);
  };

  getInvoiceLinesByProduct = (reqs) => {
    let lines = [];
    reqs.map((req) => {
      return req.product_requisitions.map((pr) => {
        return lines.push({
          desc: `Order #${req.id}: ${pr.customer_budget_code.name}: ${pr.product.name}`,
          price: pr.price,
          qty: pr.qty,
        });
      });
    });
    return lines.concat(this.getInvoiceLinesByFee(reqs));
  };

  getInvoiceLinesByFee = (reqs) => {
    return [
      {
        desc: reqs.length === 1 ? `Order #${reqs[0].id}: Shipping` : 'Shipping',
        qty: 1,
        price: reqs.reduce((acc, val) => {
          return acc + val.shipping_price;
        }, 0),
      },
    ].filter((l) => l.price > 0);
  };

  handleChange = (e, { name, value }) => {
    this.setState(() => {
      return { [name]: value, updated: true };
    });
    this.submitPreference(name, value);
  };

  handleChangeOrderGrouping = (e, { name, checked }) => {
    let optionValue = orderGroupingOptions[checked ? 1 : 0].value;
    this.setState(() => {
      return { [name]: optionValue, updated: true };
    });
    this.submitPreference(name, optionValue);
  };

  submitPreference = (name, value) => {
    this.props.actions.submitUpdateLocation({
      id: this.props.openLocation.id,
      [name]: value,
    });
  };

  getColumnCount = () => {
    return window.innerWidth >= 780 ? 2 : 1;
  };

  renderLine = (line) => {
    return (
      <Table.Row key={line.desc}>
        <Table.Cell>{line.desc}</Table.Cell>
        <Table.Cell>{line.qty}</Table.Cell>
        <Table.Cell textAlign="right">${line.price.toFixed(2)}</Table.Cell>
      </Table.Row>
    );
  };

  renderInvoice = (group, idx) => {
    return (
      <div key={idx} className="invoicing-preferences-invoice">
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>Example Invoice #{idx + 1}</Item.Header>
              <Item.Meta>{this.props.openCustomer.name}</Item.Meta>
            </Item.Content>
          </Item>
        </Item.Group>
        <Table compact="very" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{group.map(this.renderLine)}</Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                ${group.reduce(reducePriceQty, 0).toFixed(2)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <div className="invoicing-preferences card">
        <Header as="h2" attached="top">
          Customize Your Invoice
        </Header>
        <Segment attached="bottom">
          <Grid>
            <Grid.Column width={this.getColumnCount() > 1 ? 6 : 16}>
              <Form loading={this.props.isRequesting}>
                <Form.Field
                  control={Checkbox}
                  name="pref_billing_invoice_grouping"
                  onChange={this.handleChangeOrderGrouping}
                  checked={
                    this.state.pref_billing_invoice_grouping === 'requisition'
                  }
                  label="I want each order invoiced separately."
                />
                {this.props.openLocation.pref_enable_billing_full_options && (
                  <Form.Select
                    fluid
                    label="Frequency"
                    name="pref_billing_interval"
                    value={this.state.pref_billing_interval}
                    options={frequencyOptions}
                    onChange={this.handleChange}
                    placeholder="Invoice weekly"
                  />
                )}
                <Form.Select
                  fluid
                  label="Location Options"
                  name="pref_billing_combine_customer_locations"
                  value={this.state.pref_billing_combine_customer_locations}
                  options={locationGroupingOptions}
                  onChange={this.handleChange}
                  placeholder="Invoice offices individually"
                />
                <Form.Select
                  fluid
                  label="Purchase Detail Options"
                  name="pref_billing_line_item_grouping"
                  options={lineGroupingOptions}
                  value={this.state.pref_billing_line_item_grouping}
                  onChange={this.handleChange}
                  placeholder="One line for each order"
                />
              </Form>
              {this.state.updated && (
                <Message
                  size="mini"
                  success
                  content="Changes are saved and applied on your next invoice."
                />
              )}
            </Grid.Column>
            <Grid.Column width={this.getColumnCount() > 1 ? 10 : 16}>
              {this.getInvoiceLineGroups().map(this.renderInvoice)}
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

InvoicingPreferences.propTypes = {
  isRequesting: PropTypes.bool.isRequired,
  openCustomer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  openLocation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pref_billing_invoice_grouping: PropTypes.string,
    pref_billing_line_item_grouping: PropTypes.string,
    pref_billing_combine_customer_locations: PropTypes.bool,
    pref_billing_interval: PropTypes.string,
    pref_enable_billing_full_options: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    submitUpdateLocation: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    openLocation: state.locations.open,
    openCustomer: state.customers.open,
    isRequesting: state.locations.requesting.length > 0,
    key: state.locations.open.updated_at,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitUpdateLocation,
      },
      dispatch
    ),
  };
}

function areStatesEqual(prev, next) {
  return (
    prev.locations.open === next.locations.open &&
    prev.customers.open === next.customers.open &&
    prev.locations.requesting === next.locations.requesting
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(InvoicingPreferences);
