import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { showTaskModal } from '../../actions/task-actions.js';
import checkmarkIconGreen from '../../imgs/checkmark-icon--green.svg';
import checkmarkIconGray from '../../imgs/icon-checkmark--gray.svg';
import { trackInterfaceTaskNoteToggle } from '../../lib/analytics.js';
import './task-item.css';

export class TaskItemRecurring extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTaskNote: false,
    };
  }

  handleTaskModalTrigger = (e) => {
    e.preventDefault();
    if (!this.props.editable) {
      return;
    }
    this.props.actions.showTaskModal(this.props.item);
  };

  toggleTaskNote = (e) => {
    if (this.props.editable) {
      return;
    }
    this.setState(
      Object.assign({}, this.state, {
        showTaskNote: !this.state.showTaskNote,
      })
    );
    trackInterfaceTaskNoteToggle(this.props.item);
  };

  formattedTitle = () => {
    const maxLength = 50;
    if ((this.props.item.title || '').length <= maxLength) {
      return this.props.item.title;
    }
    return this.props.item.title.slice(0, maxLength) + '...';
  };

  formattedNote = () => {
    const maxLength = 20;
    if ((this.props.item.note || '').length <= maxLength) {
      return this.props.item.note;
    }
    return this.props.item.note.slice(0, maxLength) + '...';
  };

  render() {
    return (
      <div
        className="recurring-task-item"
        onClick={this.handleTaskModalTrigger}
      >
        <div className="task-item-title">{this.formattedTitle()}</div>
        <div className="task-item-area">{this.props.item.area}</div>
        {this.props.item.note && !this.state.showTaskNote && (
          <Button
            secondary
            compact
            size="mini"
            className="borderless"
            onClick={this.toggleTaskNote}
          >
            See Details
          </Button>
        )}
        {this.state.showTaskNote && (
          <div className="task-item-note open-state">
            <div className="task=item-note light-gray-text">
              {this.formattedNote()}
            </div>
            <Button
              className="borderless"
              secondary
              size="mini"
              compact
              onClick={this.toggleTaskNote}
            >
              Hide Details
            </Button>
          </div>
        )}
        {this.props.item.completed && (
          <div className="task-item-completed is-completed">
            <img
              src={checkmarkIconGreen}
              className="main-icon"
              alt="completed-checkmark"
            />
            Completed
          </div>
        )}
        {!this.props.item.completed && (
          <div className="task-item-completed">
            <img
              src={checkmarkIconGray}
              className="main-icon"
              alt="completed-checkmark"
            />
            Not Completed
          </div>
        )}
      </div>
    );
  }
}

TaskItemRecurring.propTypes = {
  editable: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    cleaning_id: PropTypes.number.isRequired,
    note: PropTypes.string,
    area: PropTypes.string,
    completed: PropTypes.bool.isRequired,
    recurring_task_id: PropTypes.number,
    recurring: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ showTaskModal }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItemRecurring);
