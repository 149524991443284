export default function ({ color }) {
  color = color || '#00a98c';
  let svg = `
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="418.000000pt" height="418.000000pt" viewBox="0 0 418.000000 418.000000"
         preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g transform="translate(0.000000,418.000000) scale(0.100000,-0.100000)"
        fill="${color}" stroke="none">
        <path d="M1468 4040 c-258 -30 -524 -135 -728 -289 -312 -234 -508 -543 -591
        -931 -33 -152 -33 -438 0 -590 71 -333 215 -590 455 -817 387 -365 927 -498
        1445 -358 195 53 390 153 559 288 l64 50 100 -100 100 -100 -18 -34 c-14 -25
        -19 -56 -19 -119 0 -72 4 -93 25 -133 35 -67 707 -739 769 -770 37 -17 63 -22
        131 -22 76 0 91 3 139 30 61 34 121 103 146 169 24 62 17 165 -14 228 -17 33
        -130 152 -385 406 -321 320 -366 361 -410 376 -64 21 -148 20 -213 -3 l-52
        -18 -97 98 -98 98 43 53 c168 209 266 416 322 678 31 142 33 416 5 565 -41
        221 -140 455 -261 618 -241 324 -558 528 -944 607 -115 23 -355 33 -473 20z
        m302 -141 c243 -23 502 -120 690 -259 283 -209 477 -517 547 -867 25 -130 23
        -386 -5 -515 -62 -284 -182 -507 -382 -709 -286 -289 -685 -434 -1095 -399
        -412 35 -766 241 -1015 589 -105 146 -178 319 -222 525 -31 145 -30 379 1 526
        44 205 114 372 219 519 269 376 647 579 1117 600 17 0 82 -4 145 -10z m1401
        -2705 c28 -6 97 -69 376 -347 189 -188 349 -355 358 -373 8 -18 15 -45 15 -60
        0 -44 -38 -111 -76 -135 -43 -26 -116 -29 -159 -6 -16 8 -182 168 -367 354
        -292 293 -337 343 -343 376 -11 60 2 104 45 147 46 46 83 57 151 44z"/>
        <path d="M1436 3674 c-558 -101 -956 -580 -956 -1149 0 -322 117 -604 344
        -830 145 -143 325 -248 521 -301 92 -25 115 -28 290 -28 169 -1 201 2 285 23
        100 25 236 79 314 125 301 176 513 490 567 840 16 103 5 352 -19 446 -59 223
        -156 394 -311 550 -170 172 -384 282 -625 325 -109 19 -303 18 -410 -1z m484
        -108 c199 -54 410 -185 535 -332 122 -143 188 -270 237 -454 29 -108 36 -326
        14 -444 -85 -451 -432 -794 -881 -872 -132 -23 -329 -15 -458 20 -333 89 -618
        350 -732 670 -52 144 -68 254 -62 416 7 207 62 382 170 544 153 228 382 392
        638 455 120 29 128 30 294 26 123 -3 167 -8 245 -29z"/>
        </g>
        </svg>
    `;
  let blob = new Blob([svg], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
}
