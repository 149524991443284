import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { formatCentAmount } from '../../lib/formatters.js';
import 'semantic-ui-css/components/message.min.css';

export default class StipendDetails extends React.PureComponent {
  render() {
    if (this.props.requester_purchase_reason === 'employee_stipend') {
      return (
        <Message>
          <p>
            Any spend over {formatCentAmount(this.props.total_employee_stipend)}{' '}
            must be purchased on your own card.
            {this.props.employee_stipend_spent > 0 && (
              <span>
                &nbsp;So far, you have spent{' '}
                {formatCentAmount(this.props.employee_stipend_spent)}.
              </span>
            )}
          </p>
          <p>
            <strong>
              {formatCentAmount(this.props.requester_purchase_price)}
            </strong>{' '}
            will be charged to your card.
          </p>
        </Message>
      );
    }
    return null;
  }
}

StipendDetails.propTypes = {
  employee_stipend_spent: PropTypes.number,
  total_employee_stipend: PropTypes.number,
  requester_purchase_price: PropTypes.number.isRequired,
  requester_purchase_reason: PropTypes.string,
  requester_purchase_required: PropTypes.bool,
};
