import * as types from './action-types.js';
import fetch from '../lib/hmac-fetch';
import {
  trackFetchError,
  trackInterfaceOpenLocationCatalogProduct,
  trackLocationCatalogProductCreated,
  trackLocationCatalogProductCreatedAllLocations,
  trackLocationCatalogProductDeleted,
} from '../lib/analytics.js';
import { fetchError, requestID } from './action-helpers.js';
import { HEADERS_JSON, ADMIN_V1_URL } from '../strings.js';

export const createLocationCatalogProduct = (data) => ({
  type: types.CREATE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const successCreateLocationCatalogProduct = (data) => ({
  type: types.SUCCESS_CREATE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const errorCreateLocationCatalogProduct = (data) => ({
  type: types.ERROR_CREATE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const deleteLocationCatalogProduct = (data) => ({
  type: types.DELETE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const successDeleteLocationCatalogProduct = (data) => ({
  type: types.SUCCESS_DELETE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const errorDeleteLocationCatalogProduct = (data) => ({
  type: types.ERROR_DELETE_LOCATION_CATALOG_PRODUCT,
  data,
});

export const openLocationCatalogProduct = (data) => {
  trackInterfaceOpenLocationCatalogProduct(data);
  return {
    type: types.OPEN_LOCATION_CATALOG_PRODUCT,
    data,
  };
};

export const closeLocationCatalogProduct = (data) => ({
  type: types.CLOSE_LOCATION_CATALOG_PRODUCT,
  data,
});

/**
 * Create location_catalog_product
 *
 * @access public
 * @returns {Promise}
 * @param {Number} data.location_id
 * @param {Number} data.product_id
 */
export function submitLocationCatalogProduct(
  data,
  options = { bubble: false }
) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(createLocationCatalogProduct(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/location_catalog_products`,
      {
        method: 'POST',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status !== 201) {
          throw fetchError({
            response,
            data,
            message: 'Create location catalog product',
          });
        }
        return response.json();
      })
      .then((body) => {
        dispatch(
          successCreateLocationCatalogProduct(
            Object.assign(body, { _request: data._request })
          )
        );
        trackLocationCatalogProductCreated(body);
        if (options.bubble) return body;
      })
      .catch((error) => {
        dispatch(errorCreateLocationCatalogProduct(error));
        trackFetchError(error);
      });
  };
}

/**
 * Delete location_catalog_product
 *
 * @access public
 * @param {Number} data.location_id
 * @param {Number} data.product_id
 * @param {Number} data.id
 * @returns {Promise}
 */
export function submitDeleteLocationCatalogProduct(data) {
  data._request = data._request || requestID();
  return (dispatch) => {
    dispatch(deleteLocationCatalogProduct(data));
    return fetch(
      `${ADMIN_V1_URL}/locations/${data.location_id}/location_catalog_products/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          ...HEADERS_JSON,
          'X-Request-ID': data._request,
        },
      }
    )
      .then((response) => {
        if (response.status !== 204) {
          throw fetchError({
            response,
            data,
            message: 'Delete location catalog product ' + data.id,
          });
        }
      })
      .then(() => {
        dispatch(successDeleteLocationCatalogProduct(data));
        trackLocationCatalogProductDeleted(data);
      })
      .catch((error) => {
        dispatch(errorDeleteLocationCatalogProduct(error));
        trackFetchError(error);
      });
  };
}

// *
//  * Create location_catalog_product for all customer locations
//  *
//  * @access public
//  * @returns {Promise}
//  * @param {Number} data.product_id

export function submitAllLocationCatalogProduct(
  data,
  options = { bubble: false }
) {
  return (dispatch, getState) => {
    const location_ids = Object.keys(getState().locations.items);
    return Promise.all(
      location_ids.map((l_id) => {
        return submitLocationCatalogProduct(
          {
            location_id: l_id,
            product_id: data.product_id,
          },
          options
        )(dispatch);
      })
    )
      .then(trackLocationCatalogProductCreatedAllLocations)
      .catch(() => {});
  };
}

// *
//  * Delete location_catalog_product for all customer locations
//  *
//  * @access public
//  * @returns {Promise}
//  * @param {Number} data.product_id

export function submitDeleteAllLocationCatalogProduct(
  data,
  options = { bubble: false }
) {
  return (dispatch, getState) => {
    // const location_ids = getState().locations.items.map(l => l.id);
    // return Promise.all(location_ids.map(l_id => {
    //     return submitDeleteLocationCatalogProduct({
    //         location_id: l_id,
    //         product_id : data.product_id,
    //         id         : data.id,
    //     })(dispatch);
    // })).then(() => {}).catch(() => {});
  };
}
