import ReactGA from 'react-ga';
import cookies from '../lib/encrypted-cookies.js';
import { LOCATION_COOKIE } from '../strings.js';

export function locationDeliveryPrefsComplete(location) {
  if (!location.updated_at) return true;

  return (
    location.pref_fulfillment_delivery_time &&
    location.pref_fulfillment_delivery_location &&
    location.pref_fulfillment_delivery_access &&
    location.pref_fulfillment_contact
  );
}

export const setLocationCookies = (location) => {
  cookies.set(LOCATION_COOKIE, { id: location.id }, { expires: 30 });
  ReactGA.set({
    dimension1: location.customer_id, // set customer dimension
    dimension2: location.id, // set location dimension
  });
};
