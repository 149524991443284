import * as types from '../actions/action-types';

export const empty = {
  initialized: false,
  errors: [],
  creating: [],
  requesting: [],
  updating: [],
  items: [],
  open: { id: 0 },
};

export const initial = {
  ...empty,
};

export default (state = initial, action) => {
  let items = [];
  switch (action.type) {
    case types.GET_CREDITS:
      return {
        ...state,
        initialized: true,
        requesting: state.requesting.concat(
          Object.assign(
            {
              _request: types.GET_CREDITS,
            },
            action.data
          )
        ),
      };
    case types.SUCCESS_GET_CREDITS:
      items = action.data.map((credit) => credit.id);
      return {
        ...state,
        items: state.items
          .filter((evi) => items.indexOf(evi.id) < 0)
          .concat(action.data),
        open: {
          ...state.open,
          ...action.data.filter((s) => s.id === state.open.id)[0],
        },
        requesting: empty.requesting,
      };
    case types.GET_CREDIT:
      return {
        ...state,
        requesting: state.requesting.concat(action.data),
      };
    case types.SUCCESS_GET_CREDIT:
    case types.SUCCESS_UPDATE_CREDIT:
      return {
        ...state,
        open:
          state.open.id === action.data.id
            ? { ...state.open, ...action.data }
            : state.open,
        items: state.items
          .filter((item) => item.id !== action.data.id)
          .concat([action.data]),
        requesting: state.requesting.filter(
          (item) => item.id !== action.data.id
        ),
      };
    case types.SUCCESS_DELETE_CREDIT:
      return Object.assign({}, state, {
        updating: state.updating
          .filter((credit) => credit._request !== action.data._request)
          .concat(action.data),
      });
    case types.SIGN_OUT:
    case types.SWITCH_LOCATION:
      return empty;
    default:
      return state;
  }
};
