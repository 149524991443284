import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Input,
  Message,
} from 'semantic-ui-react';
import { CartPropType, isRequester } from '../../helpers/cart-helpers.js';
import {
  stageCart,
  unstageCart,
  updateStagedCart,
  doUpdateCart,
  closeCartConfig,
  FILTER_IS_UPDATE_SUCCESS,
  FILTER_IS_ERROR,
} from '../../ducks/carts.js';
import { enqueueConfirmation } from '../../actions/confirmations-actions.js';

export class CartConfigForm extends React.PureComponent {
  componentDidMount() {
    this.props.actions.stageCart(this.props.openCart);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.updateSuccess && this.props.updateSuccess) {
      this.props.actions.enqueueConfirmation({
        message: 'Cart updated',
        type: 'success',
      });
      this.props.actions.closeCartConfig();
    }
  }

  componentWillUnmount() {
    this.props.actions.unstageCart();
  }

  handleChangeField = (e, { value, name, checked }) => {
    let obj = {};
    if (name === 'is_public') {
      obj['is_public'] = checked;
      if (!checked) obj['name'] = null;
    } else {
      obj[name] = value;
    }
    this.props.actions.updateStagedCart(obj);
  };

  handleSubmit = () => {
    this.props.actions.doUpdateCart({
      id: this.props.openCart.id,
      location_id: this.props.openCart.location_id,
      name: this.props.stagedCart.name,
      is_public: this.props.stagedCart.is_public,
      _request: this.props.openCart.id,
    });
  };

  isDisabled = () =>
    this.props.stagedCart.is_public &&
    (!this.props.stagedCart.name || this.props.stagedCart.name.length < 1);

  publicInputLabel = () => {
    if (this.props.openCart.is_public) {
      return 'This cart can be edited by other users';
    }
    return 'Allow other users to view and edit this cart';
  };

  render() {
    return (
      <div>
        <Header as="h3">Cart Settings</Header>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field
            id="form-input-control-is-public"
            name="is_public"
            label={this.publicInputLabel()}
            control={Checkbox}
            checked={this.props.stagedCart.is_public}
            onChange={this.handleChangeField}
            disabled={this.props.openCart.is_public}
          />
          {this.props.stagedCart.is_public && (
            <Form.Field
              required
              id="form-input-control-name"
              name="name"
              control={Input}
              onChange={this.handleChangeField}
              value={this.props.stagedCart.name || ''}
              label="Name This Cart"
              placeholder="ex: Office Snacks"
              disabled={
                this.props.openCart.is_public &&
                !isRequester(this.props.openCart, this.props.actor)
              }
            />
          )}
          {this.props.stagedCart.is_public && !this.props.openCart.is_public && (
            <Message warning visible>
              This action cannot be undone.
            </Message>
          )}
          {this.props.updateFailure && (
            <Message error visible>
              There was a problem updating your cart. Please try again or
              contact support if the issue persists.
            </Message>
          )}
          <Button
            type="submit"
            primary
            size="small"
            disabled={this.isDisabled()}
          >
            Save
          </Button>
        </Form>
      </div>
    );
  }
}

CartConfigForm.propTypes = {
  actor: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['employee', 'staff']),
  }),
  openCart: CartPropType,
  stagedCart: CartPropType,
};

export function mapStateToProps(state) {
  return {
    actor: state.auth.actor,
    openCart: state.carts.open,
    stagedCart: state.carts.staged,
    updateSuccess:
      state.carts.responses
        .filter(FILTER_IS_UPDATE_SUCCESS)
        .filter((res) => res.data._request === state.carts.open.id).length > 0,
    updateFailure:
      state.carts.responses
        .filter(FILTER_IS_ERROR)
        .filter((res) => res.data._request === state.carts.open.id).length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        stageCart,
        unstageCart,
        updateStagedCart,
        doUpdateCart,
        closeCartConfig,
        enqueueConfirmation,
      },
      dispatch
    ),
  };
}

export function areStatesEqual(prev, next) {
  return (
    prev.carts.open === next.carts.open &&
    prev.carts.staged === next.carts.staged &&
    prev.carts.responses === next.carts.responses &&
    prev.auth.actor === next.auth.actor
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual,
})(CartConfigForm);
